/**
 * List all routes and route-related variables in one place
 */
export const installerSettingKey = 'view';

/**
 * A URL searchParam to indicate whether to display message composer with or without survey form
 */
export const HOMEOWNER_CONTACT_SURVEY = 'withSurvey';

export const LOGIN_BASE = '/login';

/**
 * Inbox app routes
 */
export const INBOX_ROUTE_KEY = 'inbox';
export const INBOX_BASE = `/:slug/:companyId/${INBOX_ROUTE_KEY}`;
export const INBOX_THREAD = `/:slug/:companyId/${INBOX_ROUTE_KEY}/:threadId`;
export const HOMEOWNER_INBOX_ROUTE_KEY = 'dashboard';

/**
 * Inbox routes for composing RESTful API calls
 */
export const INBOX_API_GET_UNREAD = `unread`; // for all user roles
export const INBOX_API_GET_THREADS = `threads`;
export const INBOX_API_GET_THREAD = `threads/:threadId`;
export const INBOX_API_GET_MESSAGES = `threads/:threadId/messages`;
export const INBOX_API_POST_MESSAGE = `threads/:threadId/messages`;
export const INBOX_API_PUT_VIEWED_BY = `threads`;

/**
 * @todo: replace the `key` with the `name` so we have a domain handle for each route model
 * Note: `name` is whatever we choose internally to call the model, so we can find/identify using our own lingo.
 */
export const HOMEOWNER_MENU_MODEL = [
  { key: '1', name: 'dashboard', display: 'Home', pathname: '/dashboard' },
  { key: '2', name: 'systemGlance', display: 'System at a glance', pathname: '/systemGlance' },
  { key: '3', name: 'solarCommunity', display: 'Solar Community', pathname: '/solarCommunity' },
  { key: '4', name: 'documents', display: 'Documents', pathname: '/documents' },
  { key: '5', name: 'faqs', display: 'FAQs', pathname: '/faq' },
  { key: '6', name: 'resources', display: 'Educational Resources', pathname: '/resources' },
  { key: '7', name: 'settings', display: 'Settings', pathname: '/settings' },
  { key: '8', name: 'contact', display: 'Contact', pathname: '/contact' },
  { key: '9', name: 'search', display: 'Search', pathname: '/search' }
];

// TODO: DRY amongst the discrete items rather than duplication...
export const liteMenu = [
  {
    display: 'Inbox',
    pathname: 'inbox',
    key: 'inbox',
    customClasses: 'inbox-counter'
  },
  {
    display: 'Team',
    key: 'team',
    pathname: 'team'
  },
  {
    display: 'Settings',
    key: 'companysettings',
    pathname: 'companysetting'
  }
];

export const fleetMenu = [
  {
    display: 'Fleet',
    key: 'fleet',
    pathname: 'fleet/dashboard'
  },
  {
    display: 'Settings',
    key: 'companysettings',
    pathname: 'companysetting'
  },
  {
    display: 'Team',
    key: 'team',
    pathname: 'team'
  }
];

/**
 * A model for our installer main navigation users that are subscribed to premium plus product
 *
 * [!] The order of the model is followed in the order of the nav
 */
export const coreMenu = [
  {
    display: 'Fleet',
    key: 'fleet',
    pathname: 'fleet/dashboard'
  },
  {
    display: 'Inbox',
    pathname: 'inbox',
    key: 'inbox',
    customClasses: 'inbox-counter'
  },
  {
    display: 'Settings',
    key: 'companysettings',
    pathname: 'companysetting'
  },
  {
    display: 'Team',
    key: 'team',
    pathname: 'team'
  },
  {
    display: 'Insights',
    key: 'insights',
    pathname: `insights?${installerSettingKey}=companyMetrics`
  },
  {
    display: 'Campaigns',
    key: 'campaigns',
    pathname: 'campaign/dashboard'
  }
];

export const productsMenu = {
  1: liteMenu,
  2: fleetMenu,
  3: coreMenu
};

export const findMenuElementByKey = (key, menuItems = coreMenu) => {
  let menuElement = {};
  if (menuItems) {
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].key === key) {
        menuElement = { ...menuItems[i], index: i };
        break;
      }
    }
  }
  return menuElement;
};
