import { combineReducers } from 'redux';

import {
  RECEIVE_HOME_OWNER_DATA,
  RECEIVE_UPDATE_HOME_OWNER_DATA,
  RECEIVE_HOME_OWNER_REFERRALS_DATA,
  RECEIVE_HOME_OWNER_SURVEY_RESULT,
  RECEIVE_HOME_OWNER_SOCIAL_SHARES,
  RECEIVE_HOME_OWNER_ENGAGEMENT_SCORE,
  RECEIVE_HOME_OWNER_ADD_USER,
  RECEIVE_HOME_OWNER_ADD_NOTE,
  RECEIVE_HOME_OWNER_GET_NOTE
} from "../../actions/homeOwner";

const addHomeOwnerNoteReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_HOME_OWNER_ADD_NOTE: 
      return data;
    
    default:
      return state;
  }
};

const getHomeOwnerNoteReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_HOME_OWNER_GET_NOTE: 
      return data;
    
    default:
      return state;
  }
};

const getHomeOwnerReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_HOME_OWNER_DATA: 
      return data;
    
    default:
      return state;
  }
};

const getUpdateHomeOwnerReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_UPDATE_HOME_OWNER_DATA:
      return data;
    default:
      return state;
  }
};

const getReferralReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_HOME_OWNER_REFERRALS_DATA:
      return data;
    default:
      return state
  }
}

const getSurveyDataReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_HOME_OWNER_SURVEY_RESULT:
      return data;
    default:
      return state
  }
}

const getSocialShareDataReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_HOME_OWNER_SOCIAL_SHARES:
      return data;
    default:
      return state
  }
}

const getEngagementScoreReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_HOME_OWNER_ENGAGEMENT_SCORE:
      return data;
    default:
      return state
  }
}

const getHomeownerAddUserReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_HOME_OWNER_ADD_USER:
      return data;
    default:
      return state
  }
}

export const reducer = combineReducers({
  getHomeOwner: getHomeOwnerReducer,
  getUpdateHomeOwner: getUpdateHomeOwnerReducer,
  getReferralData: getReferralReducer,
  getSurveyData: getSurveyDataReducer,
  getSocialShareData: getSocialShareDataReducer,
  getEngagementScore: getEngagementScoreReducer,
  getHomeownerAddUser : getHomeownerAddUserReducer,
  addHomeOwnerNote: addHomeOwnerNoteReducer,
  getHomeOwnerNote : getHomeOwnerNoteReducer
});