import { RECEIVE_POWERMETERAPI_DATA, RECEIVE_STATELISTAPI_DATA, RECEIVE_SLUGSTATUSAPI_DATA, RECEIVE_ADDCOMPANY_DATA, RECEIVE_ADMINREPORT_DATA,RECEIVE_COMPANY_SUNLIGHT_CREDENTIALS_DATA } from './../../Actions/dashboard'

export const reducerPowerMeter = (state = {} , { type, data }) => {
  switch (type) {
    case RECEIVE_POWERMETERAPI_DATA:
      return data
    default:
      return state
  }
}

export const reducerStateList = (state = {} , { type, data }) => {
  switch (type) {
    case RECEIVE_STATELISTAPI_DATA:
      return data
    default:
      return state
  }
}

export const reducerSlugStatus = (state = {} , { type, data }) => {
  switch (type) {
    case RECEIVE_SLUGSTATUSAPI_DATA:
      return data
    default:
      return state
  }
}

export const reducerAddCompany = (state = {} , { type, data }) => {
  switch (type) {
    case RECEIVE_ADDCOMPANY_DATA:
      return data
    default:
      return state
  }
}

export const reducerAdminReport = (state = {} , { type, data }) => {
  switch (type) {
    case RECEIVE_ADMINREPORT_DATA:
      return data
    default:
      return state
  }
}

export const reducerCompanySunlightCredentials = (state = {} , { type, data }) => {
  switch (type) {
    case RECEIVE_COMPANY_SUNLIGHT_CREDENTIALS_DATA:
      return data
    default:
      return state
  }
}