import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import * as moment from "moment";
import withRouter from '../../../../shared/withRouter'
import { envImageUrl, checkImageValid, getLoadingIcon } from "../setUserBackgroud"
import termsCloseIcon from "./../../../../Assets/Images/terms-close.png"

class ResetPassword extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func,
    history: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.image = "";
    this.slug = "";
    this.state = {
      email: ""
    };
  }
  componentWillMount() {
    this.props.init();
    this.slug = localStorage.getItem("slug")
  }

  componentDidMount() {
    if (this.slug) {
      envImageUrl(this.slug).then((response) => {
        if (response && !this.state.defaultLoginImage) {
          this.setState({
            defaultLoginImage: response
          })
        }
      });
    } else {
      this.setState({
        defaultLoginImage: "https://prod-17terawatts.s3.amazonaws.com/public/logo/desktopLogin_BG.jpg"
      })
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.image && nextProps.image.status === 200) {
      this.image = nextProps.image.data.logo;
    }
  }

  onClickClose = () => {
    {
      if (this.slug !== "")
        this.props.router.navigate("/" + this.slug + '/forcechangepassword')
      else
        this.props.router.navigate('/forcechangepassword')
    }

  }
  render() {
    let mobileLogo = getLoadingIcon(this.slug);
    let css = {
      "background-image": `url(${this.state.defaultLoginImage})`
    }
    return (
      <React.Fragment>
        <title>Terms And Conditions - Bodhi</title>

        <div id="test-bg" className="login-bg body-height" style={css}>
          <div id="login-container" className="h-100">
            <div className="login-box" style={{ height: "100%" }}>
              <div className="d-inline-block justify-content-end w-100 text-right">
                <div className="white-logo d-inline-block" />
              </div>
              <div className="container box-Shadow center-white-box termsCondition">
                <img src={termsCloseIcon} alt="Terms Close" className="dev-close-btn" onClick={() => this.onClickClose()} />
                <div className="forgotpassword">
                  <div className="logo-blue" style={{ 'background-image': `url(${(mobileLogo) || ''})` }} />
                  <p className="mobile-h2">Hi. I'm Bodhi.<br />
                    Your partner on the solar journey
                  </p>
                  <div>

                    <p className="terms-and-condition">
                      Please read these terms of service very carefully before registering for the 17TeraWatts DBA Bodhi web site and Service.
                      These terms of service (“Agreement”) govern you accessing content and using www.mybodhiapp.com and its affiliate’s web
                      sites and mobile applications. This Agreement between you (“you”) and 17TeraWatts, is subject to change by us as described below.
                      By clicking the “I Accept” button below, you (i) accept this Agreement; (ii) agree to be bound by these terms and conditions;
                      and (iii) have entered into a binding agreement between you and 17TeraWatts.
                    </p>
                    <ol className="terms-text">
                      <li>
                        This web site and any mobile application (collectively the “Site”) is owned and operated by 17TeraWatts, Inc. (referred to
                        as “17TeraWatts” herein). The Site and its content (“Content”) and the 17TeraWatts Bodhi service (“Service”) may only be accessed
                        in accordance with this Agreement. Any violation of the copyright in the Content or these terms and conditions may be enforced
                        by 17TeraWatts or the copyright owner to the fullest extent allowed by law.
                      </li>
                      <li>
                        You acknowledge and agree that 17TeraWatts may stop (permanently or temporarily) providing the Services (or any features within
                        the Services) to you or to users generally at 17TeraWatts’s sole discretion, without prior notice to you. You may stop using the
                        Services at any time. You do not need to specifically inform 17TeraWatts when you stop using the Services. You acknowledge and
                        agree that if 17TeraWatts disables access to your account, you may be prevented from accessing the Services, your account details
                        or any files or other materials which is contained in your account.
                      </li>
                      <li>
                        You agree not to access (or attempt to access) any of the Services by any means other than through the interface that is provided by
                        17TeraWatts, unless you have been specifically allowed to do so in a separate agreement with 17TeraWatts. You agree that you will not
                        engage in any activity that interferes with or disrupts the Services (or the servers and networks which are connected to the Services).
                        Unless you have been specifically permitted to do so in a separate agreement with 17TeraWatts, you agree that you will not reproduce,
                        duplicate, copy, sell, trade or resell the Services for any purpose. You agree that you are solely responsible for (and that 17TeraWatts
                        has no responsibility to you or to any third party for) any breach of your obligations under this Agreement and for the consequences
                        (including any loss or damage which 17TeraWatts may suffer) of any such breach.
                      </li>
                      <li>
                        Your carrier rates will apply for use of mobile applications, such as fees for text messaging and data charges. You hereby acknowledge,
                        agree, and provide consent in order for users to sync any device with your information.
                      </li>
                      <li>
                        You understand that all information (such as data files, written text, computer software, music, audio files or other sounds, photographs,
                        videos or other images) which you may have access to as part of, or through your use of the Services are the sole responsibility of the
                        person from which such materials originated, which may be you.
                      </li>
                      <li>
                        17TeraWatts reserves all rights not specifically granted herein. You shall not modify any copyright notices, proprietary legends, any
                        trademark and service mark attributions, any patent markings, and other indicia of ownership on the Content or other materials accessed
                        through the Service. The delivery of, and license to, the Content and/or access to third party materials does not transfer to you any
                        commercial or promotional use rights in the Content or any portion thereof. Any use of Content, or descriptions; any derivative use of
                        this Site or its materials; and any use of data mining, robots, or similar data gathering and extraction tools is strictly prohibited.
                        In no event shall the user frame any portion of the Site or any materials contained therein.
                      </li>
                      <li>
                        While 17TeraWatts uses reasonable efforts to include accurate and up-to-date information on the Site, 17TeraWatts makes no warranties
                        or representations as to its accuracy. 17TeraWatts assumes no liability or responsibility for any errors or representations in the
                        Content or this Site.
                      </li>
                      <li>
                        The Site may contain links to other sites on the Internet which are owned and operated by Third Party Vendors and other third parties
                        (the “External Sites”). You acknowledge that 17TeraWatts is not responsible for the availability of, or the materials located on or
                        through, any External Sites. You should contact the site administrator or webmaster for those External Sites if you have any concerns
                        regarding such links or the materials located on such External Sites.
                      </li>
                      <li>
                        You may direct 17TeraWatts to retrieve your own information maintained online by third-parties with which you have customer
                        relationships, maintain accounts or engage in financial transactions (“Account Information”). 17TeraWatts works with one or
                        more online service providers to access this Account Information. 17TeraWatts does not review the Account Information for
                        accuracy, legality or non-infringement. 17TeraWatts is not responsible for the Account Information or products and services
                        offered by or on third-party sites. 17TeraWatts cannot always foresee or anticipate technical or other difficulties which
                        may result in failure to obtain data or loss of data, personalization settings or other service interruptions. 17TeraWatts
                        cannot assume responsibility for the timeliness, accuracy, deletion, non-delivery or failure to store any of your data,
                        communications or personalization settings. For example, when displayed through the Service, Account Information is only
                        as fresh as the time shown, which reflects when the information is obtained from such sites. Such information may be more
                        up-to-date when obtained directly from the relevant sites. You can refresh your Account Information through the Service, in
                        the manner prescribed in the associated instructions.
                      </li>
                      <li>
                        If you provide any material or data related to 17TeraWatts including, but not limited to, third party data that is integrated
                        to the Service, you grant 17TeraWatts and its affiliates a perpetual, irrevocable, worldwide, royalty-free, non-exclusive,
                        sublicensable right and license to make, use, sell, sublicense, reproduce, distribute, perform, display, prepare derivative works
                        from, combine with other data, and otherwise exploit all such content and material as if it were the full owner thereof.
                        Furthermore, you grant 17TeraWatts, its affiliates, and sublicensees the right to use your name and/or user name in connection
                        with the materials or data. You represent and warrant that you have the right to provide the materials and data. You will defend,
                        indemnify and hold 17TeraWatts and its affiliates harmless from and against any claims resulting from any content or materials you
                        provide hereunder or your use of the Service.
                      </li>
                      <li>
                        At your discretion, you may provide feedback to 17TeraWatts concerning the functionality and performance of the Service from time to
                        time, including, without limitation, identifying potential errors, improvements, modifications, bug fixes, or enhancements (“Feedback”).
                        If you, through your evaluation or otherwise, suggests any Feedback, you hereby assign the ownership in all Feedback to 17TeraWatts. In
                        the event ownership in the Feedback cannot be granted to 17TeraWatts, you grant 17TeraWatts at no charge a perpetual, irrevocable,
                        royalty-free, worldwide right and license to use, reproduce, disclose, sublicense, distribute, modify, and otherwise exploit such Feedback
                        without restriction. You agree that 17TeraWatts may disclose that Feedback to any third party in any manner and you agree that 17TeraWatts
                        has the ability to sublicense all Feedback in any form to any third party without restriction.
                      </li>
                      <li>
                        The Site may contain areas in which additional terms and conditions apply. For purposes of the use of such areas, in the event of a conflict
                        between the terms and conditions of such other areas and these Terms and Conditions, the terms and conditions of the other area shall prevail.
                        17TeraWatts may at any time revise these Terms and Conditions by updating this posting. You are bound by any such revisions and should therefore
                        periodically visit this page to review the current Terms and Conditions to which you are bound.
                      </li>
                      <li>
                        You shall not transmit to 17TeraWatts or upload to this Site any Harmful Code or use or misappropriate the data on this Site for your own
                        commercial gain. “Harmful Code” shall mean any software (sometimes referred to as “viruses,” “worms,” “trojan horses,” “time bombs,” “time locks,”
                        “drop dead devices,” “traps,” “access codes,” “cancelbots” or “trap door devices”) that: (a) is intentionally designed to damage, disrupt, disable,
                        harm, impair, interfere with, intercept, expropriate or otherwise impede in any manner, any data, storage media, program, system, equipment or
                        communication, based on any event, including for example but not limited to (i) exceeding a number of copies, (ii) exceeding a number of users,
                        (iii) passage of a period of time, (iv) advancement to a particular date or other numeral, or (v) use of a certain feature; or (b) would enable an
                        unauthorized person to cause such result; or (c) would enable an unauthorized person to access another person’s information without such other
                        person’s knowledge and permission.
                      </li>
                      <li>
                        You may not use your username and password for any unauthorized purpose. By submitting information, data, passwords, usernames, PINs, other log-in
                        information, materials and other content to 17TeraWatts through the Service, you are licensing that content to 17TeraWatts for the purpose of
                        providing the Service. 17TeraWatts may use and store the content in accordance with this Agreement and our Privacy Statement. You represent that you
                        are entitled to submit it to 17TeraWatts for use for this purpose, without any obligation by 17TeraWatts to pay any fees or be subject to any
                        restrictions or limitations. By using the Service, you expressly authorize 17TeraWatts to access your Account Information maintained by identified
                        third parties, on your behalf as your agent, and you expressly authorize such third parties to disclose your information to us. 17TeraWatts will
                        submit information including usernames and passwords that you provide to log into the Site. You hereby authorize and permit 17TeraWatts to use and
                        store information submitted by you to accomplish the foregoing and to configure the Service so that it is compatible with the third party sites for
                        which you submit your information. For purposes of this Agreement and solely to provide the Account Information to you as part of the Service, you
                        grant 17TeraWatts the right to access third party sites, retrieve and use your information with the full power and authority to do and perform each
                        thing necessary in connection with such activities, as you could do in person. YOU ACKNOWLEDGE AND AGREE THAT WHEN 17TERAWATTS IS ACCESSING AND
                        RETRIEVING ACCOUNT INFORMATION FROM THIRD PARTY SITES, 17TERAWATTS IS ACTING AS YOUR AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF THE THIRD PARTY
                        THAT OPERATES THE THIRD PARTY SITE. You understand and agree that the Service is not sponsored or endorsed by any third parties accessible through
                        the Service. 17TeraWatts is not responsible for any payment processing errors or fees or other Service-related issues, including those issues that
                        may arise from inaccurate account information.
                      </li>
                      <li>
                        17TeraWatts may retain, and use, information collected in your use of the Service including third party data provided for you in connection with your
                        use of the Service. Information collected will be used to ensure the quality service over the duration of your use of the Service.
                      </li>
                      <li>
                        17TeraWatts does not knowingly collect any information from anyone who we know to be under the age of 13. If you are under the age of 18, you should
                        use this website only with the involvement of a parent or guardian and should not submit any personal information to 17TeraWatts. If 17TeraWatts
                        discovers that a person under the age of 13 has provided 17TeraWatts with any personal information, 17TeraWatts will use commercially reasonable efforts
                        to delete such person's personal information from all 17TeraWatts systems.
                      </li>
                      <li>
                        17TeraWatts may terminate your access to our Site or the Service for any reason at any time. The provisions that by their nature are designed to survive
                        termination or expiration of this Agreement shall survive termination or expiration of this Agreement or your access to our Site or Service.
                      </li>
                      <li>
                        By using this Site, you signify your assent to these terms of use and agree to comply with all applicable laws and regulations, including U.S. export and
                        re-export control laws and regulations, copyright laws and other laws regarding intellectual property. If you do not agree to these terms of use, please do
                        not use the Site. THIS AGREEMENT SHALL BE GOVERNED AND INTERPRETED BY THE INTERNAL LAWS OF THE STATE OF TEXAS WITHOUT REGARD TO THE CONFLICTS OF LAW PROVISIONS
                        THEREOF. ANY ACTION OR SUIT RELATED TO THIS AGREEMENT SHALL BE BROUGHT IN AUSTIN, TEXAS AND YOU HEREBY CONSENT TO THE EXCLUSIVE JURISDICITON OF SUCH COURTS.
                      </li>
                      <li>
                        The materials on this Site are provided with “Restricted Rights.” Use, duplication, or disclosure by the Government is subject to restrictions as set forth in
                        applicable laws and regulations. Use of the materials by the Government constitutes acknowledgment of 17TeraWatts’s proprietary rights in them.
                      </li>
                      <li>
                        THE MATERIALS, CONTENT ON THIS SITE AND SERVICES ARE PROVIDED “AS IS”, “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. 17TERAWATTS SHALL
                        HAVE NO RESPONSIBILITY OR LIABILITY FOR ANY CONTENT, MATERIALS POSTED ON THE SITE OR SERVICES. 17TERAWATTS MAKES NO GUARANTEES AS TO UPTIME OR AVAILABILITY OF THE
                        SERVICE. TO THE FULLEST EXTENT POSSIBLE PURSUANT TO THE APPLICABLE LAW, 17TERAWATTS DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT OR OTHER VIOLATION OF RIGHTS. THE SERVICE MAY BE SUBJECT TO LIMITATIONS,
                        DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. 17TERAWATTS IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR
                        OTHER DAMAGE RESULTING FROM SUCH PROBLEMS, INCLUDING INTERNET CONGESTION, VIRUS ATTACKS, AND DENIAL OF SERVICE (DOS) ATTACKS.
                      </li>
                      <li>
                        UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL 17TERAWATTS OR ITS THIRD PARTY LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL,
                        INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF DATA OR PROFIT, ARISING OUT OF THE USE, OR THE INABILITY TO USE, THE MATERIALS ON
                        THIS SITE OR THE SERVICE, EVEN IF 17TERAWATTS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY
                        REMEDY. 17TERAWATTS’S AND ITS LICENSORS’ ENTIRE AND AGGREGATE LIABILITY, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE,
                        SHALL NOT EXCEED $100, EVEN IF 17TERAWATTS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY.
                        IF YOUR USE OF MATERIALS FROM THIS SITE OR THE SERVICE RESULTS IN THE NEED FOR SERVICING, REPAIR OR CORRECTION OF EQUIPMENT OR DATA, YOU ASSUME ANY COSTS THEREOF.
                        SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                      </li>
                      <li>
                        This Agreement shall constitute the entire agreement between the parties regarding the subject matter hereof and supersede all proposals and prior discussions
                        and writings between the parties with respect thereto. No failure or delay in enforcing any right or exercising any remedy will be deemed a waiver of any right or
                        remedy. Each provision of this Agreement is a separately enforceable provision. If any provision of this Agreement is determined to be or becomes unenforceable or
                        illegal, such provision shall be reformed to the minimum extent necessary in order for this Agreement to remain in effect in accordance with its terms as modified
                        by such reformation.
                      </li>
                      <li>
                        The state or federal courts sitting in Travis County, Texas shall have exclusive jurisdiction and venue over any dispute arising out of this Agreement and sale,
                        and you hereby consent to the jurisdiction of such courts. 17TeraWatts shall not be liable to you for any delay or failure of 17TeraWatts to perform its obligations
                        hereunder if such delay or failure arises from any cause or causes beyond the reasonable control of 17TeraWatts. Such causes shall include, but are not limited to,
                        acts of God, floods, fires, loss of electricity or other utilities, labor strike, or delays by you in performing any requirements hereunder. This Agreement may not
                        be modified, supplemented, qualified, or interpreted by any trade usage or prior course of dealing not made a part of the Agreement by its express terms. The invalidity
                        or unenforceability of any provision of this Agreement shall not affect the validity of this Agreement as a whole and any such provision should be enforced by authorities,
                        and reconstructed if need be, to apply to the maximum extent allowable under applicable law. The failure by either party to enforce at any time any of the provisions of
                        this Agreement, to exercise any election or option provided herein, or to require at any time the performance of the other of any of the provisions herein will not in any
                        way be construed as a waiver of such provisions. Reasonable attorneys’ fees and costs will be awarded to the prevailing party in the event of litigation involving the
                        enforcement or interpretation of this Agreement. The section headings used herein are for convenience only and shall not be given any legal import. Any dispute, controversy
                        or claim arising under, out of or relating to this Agreement and any subsequent amendments of this Agreement, including, without limitation, its formation, validity, binding
                        effect, interpretation, performance, breach or termination, as well as non-contractual claims, shall be submitted to arbitration before the American Arbitration Association
                        (“AAA”) in accordance with the AAA Commercial Arbitration Rules. The place of arbitration shall be Austin, Texas. The language to be used in the arbitral proceedings shall be
                        English. The parties may apply to any court of competent jurisdiction for a temporary restraining order, preliminary injunction, or other interim or conservatory relief as
                        necessary, without breach of this Section and without abridgment of the powers of the arbitrator. The arbitrator may award any form of individual or equitable relief, including
                        injunctive relief. Any award will be final and conclusive to the parties and may be entered in any court of competent jurisdiction. You agree to the entry of injunctive relief
                        to stop any lawsuit or to remove you as a participant in such a suit. This Agreement does not constitute a waiver of any of your rights and remedies to pursue a claim individually
                        in binding arbitration, but not as a class action. This provision preventing you from bringing, joining or participating in class action lawsuits is an independent covenant. You
                        may opt-out of this Section by providing written notice of your decision within thirty (30) days of the date that you first use the Site.
                      </li>
                    </ol>
                    <p className="copyright">All Pages Copyright © {moment().format('YYYY')}, 17TeraWatts, Inc., All Rights Reserved.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// Decorate the form component
export default reduxForm({
  form: "resetPassword"
})(withRouter(ResetPassword));
