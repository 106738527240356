import * as config from './config';

export const checkEmail = (email) => {
  if (email !== '') {
    if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        email
      )
    ) {
      return config.INVALID_EMAIL;
    } else {
      return undefined;
    }
  } else {
    return config.EMPTY_EMAIL;
  }
};

/**
 * Standardize output for users for required form data
 *
 * @param {String} the name for the field
 * @returns {String}
 */
export const requiredFieldValidationMessage = (fieldName) => {
  return `Please enter your "${fieldName}".`;
};

/**
 * Standardize output for users for form data when it doesn't validate against rules
 *
 * @param {String} the name for the field
 * @returns {String}
 */
export const fieldValidationWarning = (fieldName) => {
  return `Please check the value for "${fieldName}".`;
};

export const passwordMatch = (newPassword, confirmPassword) => {
  if (newPassword !== confirmPassword) {
    return config.PASSWORD_NOT_MATCH;
  } else {
    return undefined;
  }
};

export const passwordValidation = (password) => {
  if (password !== '') {
    let digitCheck = /\d/g.test(password);
    let alphabetCheck = /[a-zA-Z]/g.test(password);
    if (!(digitCheck && alphabetCheck && password.length >= 12)) {
      return config.ALPHA_NUMERIC;
    } else {
      return undefined;
    }
  } else {
    return config.EMPTY_PASSWORD;
  }
};
