import React from 'react';

import { maxMessageCharCount } from '../../../../constants';
import { IComposerUsageDisplay } from './types';

export const HomeownerComposerUsageDisplay: React.FC<IComposerUsageDisplay> = (props) => {
  const { messageContent } = props;
  return (
    <>
      {messageContent && messageContent.length > maxMessageCharCount - 50 && (
        <div className="max-length-warning">
          <strong>{maxMessageCharCount - messageContent.length}</strong> characters left.
        </div>
      )}
    </>
  );
};

export default HomeownerComposerUsageDisplay;
