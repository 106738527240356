import React from "react";
import HomepageComponent from './Homepage'

class HomePageContainer extends React.Component {
  render() {
    return (
      <HomepageComponent />
    )
  }
}


export default HomePageContainer
