import { Services } from "../../../Api/services";
import * as config from "../../../shared/config";

export const updateAccountDetails = async data => {

	var url;

	if(data.role && data.role.includes('secondary')){
		url = `${config.HOME_OWNER_BASE_URL}company/${data.companyId}/homeowneruser/${data.homeownerId}`;
	} else{
		url = `${config.HOME_OWNER_BASE_URL}company/${data.companyId}/homeowner/${data.homeownerId}`;
	}

	const bodyData = {
		"phone": data.phone,
		"emailNotification": data.emailNotification,
		"smsNotification": data.smsNotification
	};

	let res = await Services.putCall(url, bodyData, true);
	return { data: res.data };
};