import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import { FailedHttpRequest } from '../classes';
import { dangerousImpureInboxMutator } from './helpers';
import { messagesDisplayOptions } from '../screens/Installer/components/inbox/constants';
import InstallerInboxApi from '../screens/Installer/api/inbox';
import { isInstaller, isHomeowner } from '../shared';
import type {
  ICreateMessageByThreadId,
  IFetchMessagesByThreadId
} from '../screens/Common/api/types';
import type { IFetchCompanyThreads } from '../screens/Installer/api/types';

import type { TInboxState, IUpdateViewedMessages } from './types';
import { TThreads } from '../types';

const initialState: TInboxState = {
  counts: {
    inbox: 0, // 'unread' count
    totalCount: 0 // total messages
  },
  user: {
    messagesDisplayOption: messagesDisplayOptions.MY_MESSAGES
  },
  queues: {
    inbox: {
      messages: {
        viewed: []
      }
    }
  },
  messageData: {
    error: undefined,
    messages: [],
    metadata: {}
  },
  threadsData: {
    currentThreadId: '', // for users with multiple threads, e.g. installer
    error: undefined,
    searchResults: [],
    threads: [],
    metadata: {}
  },
  executing: {
    updates: false,
    polling: false
  },
  sending: {
    messages: {
      status: false,
      errors: []
    }
  },
  loading: {
    messages: false,
    threads: false,
    count: false
  }
};

/**
 * thunk action creator to run Promise callback and dispatch the lifecycle actions based on the returned Promise
 */
export const fetchUnreadCount = createAsyncThunk(
  'inbox/fetchUnreadCount',
  async (args: void, thunkAPI) => {
    try {
      /**
       * Note: `fetchUnreadCount` is in `Common/api` but imported as one of the `InstallerInboxApi` methods
       */
      const res = await InstallerInboxApi.fetchUnreadCount();
      if (res.status !== 200) {
        return thunkAPI.rejectWithValue(
          new FailedHttpRequest('fetchUnreadCount', undefined, res.status)
        );
      }
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(new FailedHttpRequest('fetchUnreadCount', e));
    }
  }
);

/**
 * thunk action creator to run Promise callback and dispatch the lifecycle actions based on the returned Promise
 */
export const fetchThreadsByCompanyId = createAsyncThunk(
  'inbox/fetchCompanyThreads',
  async (params: IFetchCompanyThreads, thunkAPI) => {
    const { installerId, page, homeownerId, polling } = params;
    const fetchArgs: IFetchCompanyThreads = { page, homeownerId };

    const state: any = thunkAPI.getState();

    if (state.inboxMessages.user.messagesDisplayOption === messagesDisplayOptions.MY_MESSAGES) {
      fetchArgs.installerId = installerId;
    }

    if (polling) {
      if (state.inboxMessages?.threadsData?.threads?.length) {
        fetchArgs.page = 1; // start at page 1
        fetchArgs.limit = state.inboxMessages.threadsData.threads.length; // ensure we get all the current pages of threads
      }
    }

    try {
      const res = await InstallerInboxApi.fetchThreadsByCompanyId(fetchArgs);
      if (res.status !== 200) {
        return thunkAPI.rejectWithValue(
          new FailedHttpRequest('fetchThreadsByCompanyId', undefined, res.status)
        );
      }
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(new FailedHttpRequest('fetchThreadsByCompanyId', e));
    }
  }
);

/**
 * thunk action creator to run Promise callback and dispatch the lifecycle actions based on the returned Promise
 */
export const fetchMessagesByThreadId = createAsyncThunk(
  'inbox/fetchThreadMessages',
  async (params: IFetchMessagesByThreadId, thunkAPI) => {
    const { threadId, page, polling } = params;
    const fetchArgs: IFetchMessagesByThreadId = { threadId, page };
    try {
      if (polling) {
        const { inboxMessages } = thunkAPI.getState() as any;
        if (inboxMessages?.messageData?.messages?.length) {
          fetchArgs.page = 1; // start at page 1
          fetchArgs.limit = inboxMessages.messageData.messages.length; // ensure we get all the current pages of threads
        }
      }
      const res = await InstallerInboxApi.fetchMessagesByThreadId(fetchArgs);
      if (res.status !== 200) {
        return thunkAPI.rejectWithValue(
          new FailedHttpRequest('fetchMessagesByThreadId', undefined, res.status)
        );
      }
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(new FailedHttpRequest('fetchMessagesByThreadId', e));
    }
  }
);

/**
 * thunk action creator to run Promise callback and dispatch the lifecycle actions based on the returned Promise
 */
export const createMessageByThreadId = createAsyncThunk(
  'inbox/postThreadMessage',
  async (params: ICreateMessageByThreadId, thunkAPI) => {
    const { senderId } = params;
    try {
      /**
       * Note: `createMessageByThreadId` is in `Common/api` but imported as one of the `InstallerInboxApi` methods
       */
      const res = await InstallerInboxApi.createMessageByThreadId(params);

      if (res.status !== 201) {
        return thunkAPI.rejectWithValue(
          new FailedHttpRequest('createMessageByThreadId', undefined, res.status)
        );
      }

      /**
       * First, update threads based on addition of new message
       */
      if (isInstaller()) {
        const fetchArgs: IFetchCompanyThreads = {
          page: 1
        };

        // TODO: get the type sorted here
        const state: any = thunkAPI.getState();

        if (state.inboxMessages.user.messagesDisplayOption === messagesDisplayOptions.MY_MESSAGES) {
          fetchArgs.installerId = senderId;
        }

        await thunkAPI.dispatch(fetchThreadsByCompanyId(fetchArgs));
      }

      if (isHomeowner()) {
        const fetchArgs: IFetchCompanyThreads = {
          page: 1
        };
        await thunkAPI.dispatch(fetchThreadsByCompanyId(fetchArgs));
      }

      // Second, pass the new model to the reducer
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(new FailedHttpRequest('createMessageByThreadId', e));
    }
  }
);

/**
 * thunk action creator to run Promise callback and dispatch the lifecycle actions based on the returned Promise
 */
export const putPersistMessagesViewedById = createAsyncThunk(
  'inbox/putThreadMessages',
  async (params: IUpdateViewedMessages, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const viewedMessageQueue = state.inboxMessages.queues.inbox.messages.viewed;

    if (viewedMessageQueue.length) {
      try {
        const res = await InstallerInboxApi.putPersistMessagesViewedById({
          messageIds: viewedMessageQueue[0].messageIds,
          threadId: viewedMessageQueue[0].threadId
        });

        // TODO: true up with API
        if (res.status !== 204 && res.status !== 200) {
          return thunkAPI.rejectWithValue(
            new FailedHttpRequest('putPersistMessagesViewedById', undefined, res.status)
          );
        }

        /**
         * Updated models
         */
        return res.data;
      } catch (e) {
        return thunkAPI.rejectWithValue(new FailedHttpRequest('putPersistMessagesViewedById', e));
      }
    }
  }
);

type TMessagesDisplayOption = {
  messagesDisplayOption: number;
};

type TLoadingTrigger = {
  loading: boolean;
};

type TUpdateMessagesViewedTrigger = {
  messageIds: string[];
  threadId: string;
};

type TAssignThread = {
  threadId: string;
};

type TSearchResults = {
  searchResults: TThreads[];
};

/**
 * @todo RTK Query provides a powerful implementation, however it doesn't work in conjunction with our saga concerns
 * When do we split/fork/refactor?
 */
export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    // manage whether we are populating the store, or simply polling for updates
    assignExecutingPolling: (state, action: PayloadAction<boolean>) => {
      state.executing.polling = action.payload;
    },
    // manage the current inbox threadId
    assignCurrentThreadId: (state, action: PayloadAction<TAssignThread>) => {
      state.threadsData.currentThreadId = action.payload.threadId;
    },
    // manage whether user is viewing "My" or "All" message threads
    assignMessagesDisplayOption: (state, action: PayloadAction<TMessagesDisplayOption>) => {
      state.user.messagesDisplayOption = action.payload.messagesDisplayOption;
    },
    // manage whether the user is navigating to a new thread, controls view elements
    triggerThreadIsLoading: (state, action: PayloadAction<TLoadingTrigger>) => {
      state.loading.messages = action.payload.loading;
    },
    // update search results that come back as threads data
    updateSearchResults: (state, action: PayloadAction<TSearchResults>) => {
      state.threadsData.searchResults = action.payload.searchResults;
    },
    updateMessagesViewedById: (state, action: PayloadAction<TUpdateMessagesViewedTrigger>) => {
      // do we have an existing queue?
      if (state.queues.inbox.messages.viewed.length) {
        const matchingQueueEntry = state.queues.inbox.messages.viewed.filter(
          (viewed) => viewed.threadId === action.payload.threadId
        );
        // do we have an existing entry in our queue?
        if (matchingQueueEntry.length) {
          const diff = action.payload.messageIds.filter(
            (x) => !matchingQueueEntry[0].messageIds.includes(x)
          );
          // update the matching queue entry with new data, making sure we don't duplicate id values
          matchingQueueEntry[0].messageIds = [...matchingQueueEntry[0].messageIds].concat(diff);
        } else {
          // if no existing entry, add the new entry
          state.queues.inbox.messages.viewed.push(action.payload);
        }
      } else {
        // start with an empty queue
        state.queues.inbox.messages.viewed.push(action.payload);
      }
    }
  },
  extraReducers: (builder) => {
    /**
     * `inbox/fetchUnreadCount`
     */
    builder.addCase(fetchUnreadCount.pending, (state, action) => {
      state.loading.count = true;
    });
    builder.addCase(fetchUnreadCount.fulfilled, (state, action) => {
      state.loading.count = false;
      state.counts.inbox = action.payload.count;
      state.counts.totalCount = action.payload.totalCount;
    });
    builder.addCase(fetchUnreadCount.rejected, (state, action) => {
      state.loading.count = false;
      if (action.payload instanceof FailedHttpRequest) {
        state.threadsData.error = action.payload.getValue();
      }
    });
    /**
     * `fetchThreadsByCompanyId` reducers
     */
    builder.addCase(fetchThreadsByCompanyId.pending, (state, action) => {
      state.threadsData = state.threadsData;
      state.loading.threads = true;
    });
    builder.addCase(fetchThreadsByCompanyId.fulfilled, (state, action) => {
      if (action.payload.metadata.page === 1) {
        state.threadsData = {
          currentThreadId: state.threadsData.currentThreadId,
          threads: action.payload.threads,
          metadata: action.payload.metadata
        };
      } else {
        if (state.threadsData.threads && state.threadsData.threads.length) {
          state.threadsData = {
            currentThreadId: state.threadsData.currentThreadId,
            threads: [...state.threadsData.threads].concat(action.payload.threads),
            metadata: action.payload.metadata
          };
        } else {
          state.threadsData = action.payload;
        }
      }
      state.loading.threads = false;
    });
    builder.addCase(fetchThreadsByCompanyId.rejected, (state, action) => {
      state.loading.threads = false;
      if (action.payload instanceof FailedHttpRequest) {
        state.threadsData.error = action.payload.getValue();
      }
    });
    /**
     * `fetchMessagesByThreadId` reducers
     */
    builder.addCase(fetchMessagesByThreadId.pending, (state, action) => {
      state.loading.messages = true;
    });
    builder.addCase(fetchMessagesByThreadId.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload.metadata.page === 1) {
          state.messageData = action.payload;
        } else {
          if (state.messageData.messages && state.messageData.messages.length) {
            state.messageData = {
              messages: [...state.messageData.messages].concat(action.payload.messages),
              metadata: action.payload.metadata
            };
          } else {
            state.messageData = action.payload;
          }
        }
      }
      state.loading.messages = false;
    });
    builder.addCase(fetchMessagesByThreadId.rejected, (state, action) => {
      if (action.payload instanceof FailedHttpRequest) {
        state.messageData.error = action.payload.getValue();
      }
      state.loading.messages = false;
    });
    /**
     * `putPersistMessagesViewedById` reducers
     */
    builder.addCase(putPersistMessagesViewedById.pending, (state, action) => {
      state.executing.updates = true;
    });
    builder.addCase(putPersistMessagesViewedById.fulfilled, (state, action) => {
      const updatedModels = action.payload;
      state.executing.updates = false;

      if (updatedModels?.length) {
        dangerousImpureInboxMutator(updatedModels, state);
      }
    });
    builder.addCase(putPersistMessagesViewedById.rejected, (state, action) => {
      state.executing.updates = false;
    });
    /**
     * `createMessageByThreadId` reducers
     */
    builder.addCase(createMessageByThreadId.pending, (state, action) => {
      state.sending.messages.status = true;
    });
    builder.addCase(createMessageByThreadId.fulfilled, (state, action) => {
      const updatedModel = action.payload;

      if (!updatedModel) {
        state.sending.messages.status = false;
        return;
      }

      state.messageData.messages.push(updatedModel);
      state.sending.messages.status = false;
    });
    builder.addCase(createMessageByThreadId.rejected, (state, action) => {
      if (action.payload instanceof FailedHttpRequest) {
        state.sending.messages.errors.push(action.payload.getValue());
      }
      state.sending.messages.status = false;
    });
  }
});

export const {
  assignExecutingPolling,
  assignCurrentThreadId,
  updateSearchResults,
  assignMessagesDisplayOption,
  triggerThreadIsLoading,
  updateMessagesViewedById
} = inboxSlice.actions;

export default inboxSlice.reducer;
