import { combineReducers } from "redux";
import { RECEIVE_CREATE_CAMPAIGN,RECEIVE_GET_CAMPAIGN_DATA,
	RECEIVE_GET_CAMPAIGN_DETAIL,RECEIVE_UPDATE_CAMPAIGN } from "../actions/campaign";

const companyCreateCampaignReducer = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_CREATE_CAMPAIGN:
			return data;
		default:
			return state;
	}
};

const companyGetCampaignReducer = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_GET_CAMPAIGN_DATA:
			return data;
		default:
			return state;
	}
};

const GetCampaignDetailReducer = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_GET_CAMPAIGN_DETAIL:
			return data;
		default:
			return state;
	}
};

const updateCampaignDetailReducer = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_UPDATE_CAMPAIGN:
			return data;
		default:
			return state;
	}
};

export const reducer = combineReducers({
	companyCreateCampaignReducer,
	companyGetCampaignReducer,
	GetCampaignDetailReducer,
	updateCampaignDetailReducer
});