import React, { useState } from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

import { IComposer, INullDecorator, IComposerDecoratorComposer } from './types';

const nullDecorator = (props: INullDecorator) => <>{props.children}</>;

/**
 * Provides UI controls for generating user input content
 */
const Composer: React.FC<IComposer> = (props) => {
  const {
    placeholder,
    content,
    editHandler,
    decorator,
    decoratorComposer,
    children,
    focusedField,
    dimensions,
    focusHandler,
    disabled
  } = props;

  const Decorator = decorator || nullDecorator;
  const [editorActive, setEditorActive] = useState(false);

  /**
   * `dimensions` lets us port to somethiing else (should we deprecate use of `antd`) simply by creating an adapter
   */
  const [rows] = dimensions !== undefined ? dimensions : [2, 20];

  const composedProps: IComposerDecoratorComposer = decoratorComposer
    ? decoratorComposer()
    : {
        className: '',
        style: {}
      };

  return (
    <Decorator {...composedProps}>
      <div className={`editor ${editorActive ? 'active' : 'pending'}`}>
        <TextArea
          ref={focusedField}
          disabled={disabled}
          className="text-editor"
          rows={rows}
          placeholder={placeholder}
          // onPressEnter={() => {}} // TODO
          onChange={editHandler}
          value={content}
          onFocus={focusHandler || function () {}}
          onBlur={() => setEditorActive(false)}
        />
      </div>
      <div className="controls">{children}</div>
    </Decorator>
  );
};

export default Composer;
