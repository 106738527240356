import React, { useState, useEffect, Suspense } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import type { RootState } from '../../../../store';
import { withRouter, setHeight } from '../../../../shared';
import { loadingIcon, suspenseFallback } from '../../../../constants/components';

import EnergyTips from '../../components/energyTips';
import BodhiJourney from '../../components/shared/bodhiJourney';

const HomeownerMessages = React.lazy(() => import('../HomeownerMessages'));

import { IHomeownerDashboard } from './types';
import { WhatIsNextMessage } from '../../components/shared';
import { globalStyles } from '../../../../constants';

const initialThreadData: Record<string, any> = {};

const threadState = createSelector(
  (state: RootState) => state?.inboxMessages?.threadsData.threads?.[0],
  (thread) => thread
);

export const Dashboard: React.FC<IHomeownerDashboard> = (props) => {
  const {
    systemGlance,
    loggedInUser,
    companyJourney,
    companyDetail,
    router,
    isInstaller,
    homeOwnerId,
    homeOwnerFileList,
    installerDetails
  } = props;

  const [onNextInfo, setOnNextInfo] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const threadData = useSelector(threadState);

  const threadId = threadData && threadData?.id ? threadData.id : '';

  useEffect(() => {
    setHeight('homeOwnerWrapper', 'menuMobile');
  }, []);

  const { homeowner } = threadData ? threadData : initialThreadData;

  let parsedComponent = (
    <WhatIsNextMessage
      loggedInUser={loggedInUser}
      companyJourney={companyJourney}
      isOpen={!onNextInfo}
      systemGlance={systemGlance}
      clickHandler={() => setOnNextInfo(!onNextInfo)}
    />
  );

  if (loggedInUser && loggedInUser?.solarUnit?.dailySyncTime) {
    parsedComponent = (
      <EnergyTips
        {...props}
        isInstaller={isInstaller}
        location={router.location}
        installerDetails={props.installerDetails}
        companyDetail={companyDetail}
        homeOwnerId={homeOwnerId}
        requestHomeownerEnergyData={props.requestHomeownerEnergyData}
        receiveHomeownerEnergyData={props.receiveHomeownerEnergyData}
        energyReport={props.energyReport}
        setLoader={props.setLoader}
        loggedInUser={loggedInUser}
        systemGlance={systemGlance}
      />
    );
  }

  return (
    <>
      <title>My Dashboard - {loggedInUser && loggedInUser?.homeownerData?.companyName}</title>
      <div className="row">
        <div className="mobile-view-only">
          <BodhiJourney {...props} isInstaller={false} />
        </div>
        <div className="next-level-wrapp">
          {parsedComponent}

          {isLoading && <div className="loader" style={{color: globalStyles.colors.yellow}}>{loadingIcon}</div>}

          <Suspense fallback={suspenseFallback}>
            <HomeownerMessages
              homeOwnerId={homeOwnerId}
              loggedInUser={loggedInUser}
              companyJourney={companyJourney}
              homeOwner={homeowner}
              companyDetail={companyDetail}
              router={props.router}
              systemGlance={systemGlance}
              isInstaller={isInstaller}
              threadId={threadId}
              installerDetails={installerDetails}
              homeownerFileList={homeOwnerFileList}
              onLoad={() => setIsLoading(false)}
            />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default withRouter(Dashboard);
