import { notification } from 'antd';
import styled from 'styled-components';

export const StyledToastErrorNotification = styled.div`
  .hr {
    height: 0;
  }
  .error-container {
    display: inline-flex;
    line-height: 20px;
    margin-bottom: 5px;
    width: 100%;
    img {
      height: 16px;
      margin-right: 6px;
    }
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
    .size {
      color: #7a7a7a;
      margin-left: 5px;
    }
  }
`;

export const openNotificationWithIcon = (type, msg, title, duration, placement, style) => {
  notification[type]({
    message: title || '',
    description: msg,
    duration: typeof duration === 'number' ? duration : 3,
    placement: placement || 'topRight',
    style: style || {
      border: '1px solid lightgray',
      borderRadius: '10px'
    }
  });
};
