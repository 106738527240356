import { Progress } from "antd";
import styled from "styled-components";


const StyledFileUploadDecorator = styled.div`
    position: fixed;
    width: 415px;
    top: 20px;
    z-index:2;
    -webkit-transition: all 0.66s ease-in-out;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    padding: 13px 0;
  .text{
    position: absolute;
    top: 0;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    line-height: 30px;
    font-weight: 700;
    font-size: 14px;
  }
`
interface IFileUploadProgress  {
    fileUploadProgress: number;
}

export const FileUploadProgress: React.FC<IFileUploadProgress> = (props) => {
    const {fileUploadProgress} =props
    return(
        <StyledFileUploadDecorator>
        <Progress
          percent={fileUploadProgress}
          format={() => {
            return (
              <div className='text'> Processing files...</div>
            );
          }}
          className='file-progress-bar'
          strokeWidth={30}
          strokeColor={{ '0%': '#FFFFFF', '100%': '#FFD110' }}
        />
      </StyledFileUploadDecorator>
    )
}