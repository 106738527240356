import React from 'react';
import moment from 'moment';

import type { THomeOwnerReplyCard } from './types';
import Card from '../../../Common/Card';
import { HomeownerMessageInterface } from '.';
import { getInitialsFromName } from '../../../../shared';
import { parseHtml } from '../../../Installer/components/inbox/helpers';

export const HomeOwnerReply = (props: THomeOwnerReplyCard) => {
  const { message, propsResolver } = props;
  const homeownerInterfaceProps = propsResolver(message);

  const {
    threadId,
    loggedInUser :{homeownerData : {installerId}},
    homeOwner: { firstName, lastName }
  } = homeownerInterfaceProps;

  const handledDate = message?.createdAt && moment(message.createdAt);
  const date = message?.createdAt && handledDate.format('MMMM DD');
  const hour = message?.createdAt && handledDate.format('hh:mm a').toUpperCase();
  const homeOwnerReplyFooter = `${date}, ${hour}`;
  const userInitials = getInitialsFromName(`${firstName} ${lastName}`);

  const displayMessageInterface = message && Object.keys(homeownerInterfaceProps).length > 0;
  const parsedMessage = parseHtml(message?.message);

  return (
    <>
      <Card
        userInitials={userInitials}
        headerText={`${firstName} ${lastName}`}
        bodyText={parsedMessage}
        footerText={homeOwnerReplyFooter}
      />
      {displayMessageInterface && (
        <HomeownerMessageInterface
          installerId = {installerId}
          {...homeownerInterfaceProps}
          message={message}
          threadId={threadId}
        />
      )}
    </>
  );
};

export default HomeOwnerReply;
