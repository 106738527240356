import { Services } from "../../../Api/services";
import * as config from "../../../shared/config";

export const getFaqQuestionAndAnswer = async data => {
	const url = `${config.COMPANY_BASE_URL}company/${
		data.companyId
	}/faq`;
	
    let res = await Services.getCall(url, true);
	return { data: res.data };
};