
import { call, put } from "redux-saga/effects";
import * as toast from '../../shared/notification';

import { getCompanyById, editInstallerApi, editInstallerManagementApi, uploadExcelApi,driveUploadInS3 } from "../../screens/Admin/Api/editInstaller";
import { recieveGetCompanyByIdApiData, recieveEditInstallerApiData, receiveCSVUpload ,recieveEditInstallerManagementApiData } from "../../screens/Admin/Actions/editInstaller";
import  handleError  from "../../shared/errorHandler";

function* getCompanyByIdApi(action) {
    try {
      // do api call
      let data ;
      if(JSON.stringify(action.data) !== "{}"){
        data = yield call(getCompanyById, action.data);
      }

      yield put(recieveGetCompanyByIdApiData(data));
    } catch (e) {
      handleError(e)
    }
  }


function* csvUpload(action) {
    try {
      // do api call
      let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(uploadExcelApi, action.data);
    }

      yield put(receiveCSVUpload(data));
    } catch (e) {
      handleError(e)
    }
  }

  function* driveUploadS3(action) {
    try {
      let data ={}
      if (JSON.stringify(action.data) !== '{}') {
        toast.openNotificationWithIcon('info', 'File is uploading in S3', '');
        data = yield call(driveUploadInS3, action.data);
        toast.openNotificationWithIcon('success', 'File uploaded in S3', 'Success');
      }
    } catch (e) {
      toast.openNotificationWithIcon('error', 'File not uploaded in S3', ' Error ');
      handleError(e);
    }
  }

  function* geteditInstallerApi(action) {
    try {
      const data = yield call(editInstallerApi, action.data);
      toast.openNotificationWithIcon('success',' Updated settings saved successfully ','Success');
      yield put(recieveEditInstallerApiData(data));
    } catch (e) {
      handleError(e)
    }
  }

  function* geteditInstallerManagementApi(action) {
    try {      
      const data = yield call(editInstallerManagementApi, action.data, action.meterData);
     
      yield put(recieveEditInstallerManagementApiData(data));
    } catch (e) {
      handleError(e)
    }
  }
 
  export const adminEditCompany =  {
    getCompanyByIdApi,
    geteditInstallerApi,
    geteditInstallerManagementApi,
    csvUpload,
    driveUploadS3
  }