import * as React from "react";
import * as d3 from "d3";
import { parseJson } from '../../../shared/parseJson';
import { calcTime } from '../../../shared/calcTime';

const getColorProduction = (range, max) => {
	let colorRange = (max / 40);
	let actRange = Math.ceil(range/colorRange);
	return `hsla(51, 99%, ${actRange <= 0 ? 99 : 95 - actRange}%, 1)`;
};

var consumptionHslCode;
const getColorConsumption = (range, max) => {
	return `hsla(${consumptionHslCode || '360, 100%, 100%'}, ${range/max})`;
};

class TwoLayerDonutChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isHovered: false,
			data: null,
		};
	}

	componentWillReceiveProps(){
		const element = document.getElementsByClassName('premiumBackgroundText');
		const style = element[0] && window.getComputedStyle(element[0]);
		const premiumBackground = (style && style.getPropertyValue('color')) || '#00142D';
		this.convertHex2Hsl(this.rgb2hex(premiumBackground))
	}

	hex(x) {
		return ("0" + parseInt(x).toString(16)).slice(-2);
	}

	rgb2hex(rgb) {
		if (/^#[0-9A-F]{6}$/i.test(rgb)) return rgb;
		rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
		return "#" + this.hex(rgb[1]) + this.hex(rgb[2]) + this.hex(rgb[3]);
	}

	convertHex2Hsl(hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

		var r = parseInt(result[1], 16);
		var g = parseInt(result[2], 16);
		var b = parseInt(result[3], 16);

		r /= 255; g /= 255; b /= 255;
		var max = Math.max(r, g, b), min = Math.min(r, g, b);
		var h, s, l = (max + min) / 2;

		if (max == min) {
			h = s = 0; // achromatic
		} else {
			var d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
			switch (max) {
				case r: h = (g - b) / d + (g < b ? 6 : 0); break;
				case g: h = (b - r) / d + 2; break;
				case b: h = (r - g) / d + 4; break;
			}
			h /= 6;
		}
		s = s * 100;
		s = Math.round(s);
		l = l * 100;
		l = Math.round(l);
		h = Math.round(360 * h);
		return consumptionHslCode =  h + ', ' + s + '%, ' + l + '%';
		 
	}

	interpolateOutter = d3.interpolateRgb("#36384b", "#4992ab");
	dataOutter = [1];
	path(radius, interpolate, slice, sliceColor, index) {
		let maxLimit = this.props.systemGlance && this.props.systemGlance.solarGlance && this.props.systemGlance.solarGlance.systemSize ? Number(this.props.systemGlance.solarGlance.systemSize) : 10;
		if(this.props.maxSystemSize){
			maxLimit =this.props.maxSystemSize;
		}
		if (slice.data) {
			sliceColor = getColorProduction(slice.data.production, maxLimit);
		}
		const outerRadius = this.state.isHovered ? radius * 0.7 : radius;
		const innerRadius = radius * 0.5;
		let sliceColorOutter = "white";
		if (slice.data) {
			sliceColorOutter = getColorConsumption(slice.data.consumption, maxLimit);
		}
		const arc = d3
			.arc()
			.innerRadius(innerRadius)
			.outerRadius(outerRadius)
			.padAngle(0.0)
			.cornerRadius(2);

		let outterPie = d3
			.pie()
			.startAngle(slice.startAngle)
			.endAngle(slice.endAngle);

		const arc2 = d3
			.arc()
			.innerRadius(outerRadius * 1.05) //used for setting the gap between layers
			.outerRadius(outerRadius * 1.39)
			.padAngle(0.0001)
			.cornerRadius(0);
		return (
			<g key={index}>
				<path
					className='donut-path'
					d={arc(slice)}
					fill={sliceColor}
					data={JSON.stringify(slice.data)}
				>
					<title>
						{slice.data.stage.replace('AM','').replace('PM','')} - {slice.data.production}
					</title>
				</path>

				{outterPie(this.dataOutter).map((outterSlice, index) => {
					return (
						<path
							key={index}
							className='donut-path'
							d={arc2(outterSlice)}
							fill={sliceColorOutter}
							data={JSON.stringify(slice.data)}
						>
							<title>
								{slice.data.stage.replace('AM','').replace('PM','')} - {slice.data.consumption}
							</title>
						</path>
					);
				})}
				{this.state.isHovered && (
					<circle r={innerRadius * 0.95} fill={"white"} />
				)}
			</g>
		);
	}
	slice = (pie, dataInner,radius) => {
		let interpolateInner = d3.interpolateRgb("#eaaf79", "#bc3358");
		let timeDiff = 1;
		if(
			dataInner
			&& dataInner.length !== 0
			&& dataInner[1]
			&& dataInner[1].stage
			&& dataInner[0]
			&& dataInner[0].stage
		) {
			timeDiff = parseInt(dataInner[1].stage && dataInner[1].stage.split(':')[1]) - parseInt(dataInner[0].stage && dataInner[0].stage.split(':')[1]);
		}
		let numberOfSlice = 1440;
		if(timeDiff === 15) 
			numberOfSlice = 96;
		else if (timeDiff === 5)
			numberOfSlice = 288;
		else
			numberOfSlice = 1440;
		
		return pie.map((slice, index) => {
			let sliceColor = "white"; //color of inner layer
			const partitionRadian = 6.2831 / numberOfSlice;
			slice.startAngle = 0 + index * partitionRadian; // used for dividing the equal partition
			slice.endAngle = partitionRadian + index * partitionRadian;
			return this.path(
				radius ? radius :100,
				interpolateInner,
				slice,
				sliceColor,
				index
			);
		});
	};
	parseJson(obj) {
		let maxPeakJSON = {consumption:0};
		return obj && obj.map((json,index) => {
			if(typeof json === 'string') {
				json = JSON.parse(json);
			}

			if(this.props.calcMaxPeak){
				if(json.consumption > maxPeakJSON.consumption){
					maxPeakJSON = json;
				}
	
				if(index==obj.length-1){
					let temp = {
						peakConsumption: maxPeakJSON.consumption,
						peakTime: maxPeakJSON.stage
					}
					this.props.calcMaxPeak(temp);
				}	
			}	
			return json;
		})
	}
	setData(data) {
		let temp = [];
		let count = 0;
		let timeDiff = 1;
		let max = 0;

		if(data&& data.length>8 && data[2].stage&& data[1].stage ){

			timeDiff = ((data[2].stage*1000)-(data[1].stage*1000))/60000;
		}
		else if(
			data
			&& data.length !== 0
			&& data[0]
			&& data[0].stage
			&& data[1]
			&& data[1].stage
		) {
			timeDiff = ((data[1].stage*1000)-(data[0].stage*1000))/60000;
			
		}

		if(timeDiff === 15) {
			for(let i=0;i<24;i++) {
				for(let j=0;j<4;j++) {
					temp[count]= {
						consumption: 0,
						production: 0,
						stage: `${
							i<=12 ?
								i<10 ?
									'0'+i
								:
									i
							:
								i-12<10 ?
									'0'+i-12
								:
									i-12
						}:${
							j*15<10 ?
								'0'+j*15
							:
								j*15
						}${
							i<12 ?
								'AM'
							:
								'PM'
						}`
					};
					let index = -1;
					
					if(this.props.offset) {
						index = data.findIndex(val => calcTime(new Date(val.stage*1000), this.props.offset).getHours() === i && calcTime(new Date(val.stage*1000), this.props.offset).getMinutes() === j*15)
					}
					else
						index = data.findIndex(val => new Date(val.stage*1000).getHours() === i && new Date(val.stage*1000).getMinutes() === j*15)
					if(index !== -1) {
						temp[count].consumption = data[index].consumption;
						temp[count].production = data[index].production;
						if(max < data[index].production) {
							max = data[index].production;
						}
					}
					count++;
				}
			}
		} else if(timeDiff === 5) {
			for(let i=0;i<24;i++) {
				for(let j=0;j<12;j++) {
					temp[count]= {
						consumption: 0,
						production: 0,
						stage: `${
							i<=12 ?
								i<10 ?
									'0'+i
								:
									i
							:
								i-12<10 ?
									'0'+i-12
								:
									i-12
						}:${
							j*5<10 ?
								'0'+j*5
							:
								j
						}${
							i<12 ?
								'AM'
							:
								'PM'
						}`
					};
					let index = -1;
					
					if(this.props.offset)
						index = data.findIndex(val => calcTime(new Date(val.stage*1000), this.props.offset).getHours() === i && calcTime(new Date(val.stage*1000), this.props.offset).getMinutes() === j*5)
					else
						index = data.findIndex(val => new Date(val.stage*1000).getHours() === i && new Date(val.stage*1000).getMinutes() === j*5)
					if(index !== -1) {
						temp[count].consumption = data[index].consumption;
						temp[count].production = data[index].production;
						if(max < data[index].production) {
							max = data[index].production;
						}
					}
					count++;
				}
			}
		} else {
			for(let i=0;i<24;i++) {
				for(let j=0;j<60;j++) {
					temp[count]= {
						consumption: 0,
						production: 0,
						stage: `${
							i<=12 ?
								i<10 ?
									'0'+i
								:
									i
							:
								i-12<10 ?
									'0'+i-12
								:
									i-12
						}:${
							j<10 ?
								'0'+j
							:
								j
						}${
							i<12 ?
								'AM'
							:
								'PM'
						}`
					};
					let index = -1;
					
					if(this.props.offset)
						index = data.findIndex(val => calcTime(new Date(val.stage*1000), this.props.offset).getHours() === i && calcTime(new Date(val.stage*1000), this.props.offset).getMinutes() === j)
					else
						index = data.findIndex(val => new Date(val.stage*1000).getHours() === i && new Date(val.stage*1000).getMinutes() === j)

					if(index !== -1) {
						temp[count].consumption = data[index].consumption;
						temp[count].production = data[index].production;
						if(max < data[index].production) {
							max = data[index].production;
						}
					}
					count++;
				}
			}
		}
		
		return {temp, max};
	}
	render() {
		let energyReport = this.setData(this.props.energyReport && (this.parseJson(this.props.energyReport.historyData)|| [] ));
		let {graphSize} = this.props
		// const energyReport = dataInner;
		this.props.renderSunburst && this.props.renderSunburst(true);
		const height = (graphSize && graphSize.heigth) ?graphSize.heigth : 330;
		const width = (graphSize && graphSize.heigth) ? graphSize.width : 330;
		let poweredSum = 0;
		let isConsumption = true;
		let isCurrentDay = true;
		if( this.props.energyReport ) {
			if(
				this.props.energyReport.totalConsumption
				&& this.props.energyReport.totalConsumption > 0
				&& this.props.energyReport.totalProduction
				&& this.props.energyReport.totalProduction > 0
			) {
				poweredSum = Number((this.props.energyReport.totalProduction/this.props.energyReport.totalConsumption)*100).toFixed();
			} else if(
				(!this.props.energyReport.totalConsumption
				|| this.props.energyReport.totalConsumption === 0)
				&& this.props.energyReport.totalProduction
				&& this.props.energyReport.totalProduction > 0
			) {
				if(
					this.props.energyReport.historyData
					&& this.props.energyReport.historyData.length
					&& this.props.energyReport.historyData.length !== 0
				) {
					
					if(localStorage.getItem('energyReportDate') && new Date(localStorage.getItem('energyReportDate')).getTime() < new Date().setHours(0,0,0,0)) {
						poweredSum = energyReport.max ? Number(energyReport.max).toFixed(1) : 0;
						isCurrentDay = false;
					} else {
						if(
							typeof this.props.energyReport.historyData[this.props.energyReport.historyData.length-1] === 'string'
						) {
							const data = JSON.parse(this.props.energyReport.historyData[this.props.energyReport.historyData.length-1]);
							poweredSum = Number(data.production) !== 0 ?Number(data.production).toFixed(1) : 0;
						} else {
							poweredSum = Number(this.props.energyReport.historyData[this.props.energyReport.historyData.length-1].production) !== 0?
								Number(this.props.energyReport.historyData[this.props.energyReport.historyData.length-1].production).toFixed(1)
							: 0;
						}
					}
				}
				isConsumption = false;
			}
		}
		let pie = d3.pie()(energyReport.temp);
		
		return (
			<div className="wrapperChart">
				<div className="chartCenterData positionAbsolute" style={{fontSize:"24px"}}>
					{
						poweredSum
					}
					<span className="kw-font">{
						isConsumption?"%":' kW'
					}
					</span>
				</div>
				{
					isConsumption?
						<div className="poweredbysun"> Powered by <br/>the sun</div>
					:
						<div className="poweredbysun"> {isCurrentDay ? 'Current' : 'Max'} Solar <br/>Power</div>
				}
				
				<div className="timeContainer">
					<span className="pM12 positionAbsolute clockTime">12PM</span>
					<span className="pM3 positionAbsolute clockTime">3PM</span>
					<span className="pM6 positionAbsolute clockTime">6PM</span>
					<span className="pM9 positionAbsolute clockTime">9PM</span>
					<span className="aM12 positionAbsolute clockTime">12AM</span>
					<span className="aM3 positionAbsolute clockTime">3AM</span>
					<span className="aM6 positionAbsolute clockTime">6AM</span>
					<span className="aM9 positionAbsolute clockTime">9AM</span>
				</div>
				<svg height={height} width={width} className="donutChart">
					<g className={"graph-position "+ ((graphSize && graphSize.className)|| '')}>
						{this.slice(pie, energyReport.temp,graphSize && graphSize.radius)}
					</g>
				</svg>
			</div>
		);
	}
}

export default TwoLayerDonutChart