import { Services } from '../../../Api/services';
import * as config from '../../../shared/config';
import { openNotificationWithIcon } from '../../../shared/notification';

export const companyJourney = async data => {
    let url = `${config.HOME_OWNER_BASE_URL}company/${data.companyId}/crmkey?slug=${data.slug}&type=${data.type}`;
    if(data.toolSearch){
        url+=`&toolSearch=crmkey&fileName=${data.fileName}`;
    } else if(data.premium){
        url+=`&premium=true`;
    }
    let res = await Services.getCall(url, true);
    return { data: res.data, status: res.status };
};
export const postCompanyJourney = async data =>{
    let url = `${config.HOME_OWNER_BASE_URL}company/${data.companyId}/crmkey?slug=${data.slug}&type=${data.type}`;
    let body = data.triggerData;
    if(data.premium){
        url+=`&premium=true`;
        body['premiumData'] = data.premiumData;
    }

    let res = await Services.postCall(url,body,true);
    if(res.status===200)
        openNotificationWithIcon("success", "Data is saved", " Success ");
    return { data: res.data, status: res.status };
}

export const deletePremiumAccount = async data =>{
    let url = `${config.HOME_OWNER_BASE_URL}company/${data.companyId}/removepremium?slug=${data.slug}`;    
    let res = await Services.deleteCall(url, true);
    if(res.status===200)
        openNotificationWithIcon("success", "Data is saved", " Success ");
    return { data: res.data, status: res.status };
}

export const companyCRMData = async data =>{
    let url = `${config.HOME_OWNER_BASE_URL}company/${data.companyId}/crmData?slug=${data.slug}&toolSearch=crmkey&method=fetchKey`;    
    let res = await Services.getCall(url, true);
    return { data: res.data, status: res.status };
}