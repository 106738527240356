export const REQUEST_API_DATA = "REQUEST_API_DATA";
export const RECEIVE_API_DATA = "RECEIVE_API_DATA";

export const REQUEST_TIME_ZONE_DATA = "/screens/AddHomeOwner/data/TimeZone/REQUEST_API_DATA";
export const RECEIVE_TIME_ZONE_DATA = "/screens/AddHomeOwner/data/TimeZone/RECEIVE_API_DATA";

export const REQUEST_STATE_DATA = "/screens/AddHomeOwner/data/GET_STATE/REQUEST_STATE_DATA";
export const RECEIVE_STATE_DATA = "/screens/AddHomeOwner/data/GET_STATE/RECIVE_STATE_DATA";

export const RECEIVE_COMPANY_ID_DATA = "/screens/AddHomeOwner/data/Company_Id/RECEIVE_COMPANY_ID_DATA";
export const REQUEST_COMPANY_ID_DATA = "/screens/AddHomeOwner/data/Company_Id/REQUEST_COMPANY_ID_DATA";

export const RECEIVE_INSTALLER_DATA = "/screens/AddHomeOwner/data/Installer/RECEIVE_INSTALLER_DATA";
export const REQUEST_INSTALLER_DATA = "/screens/AddHomeOwner/data/Installer/REQUEST_INSTALLER_DATA";

export const RECEIVE_POWER_METER_DATA = "/screens/AddHomeOwner/data/Installer/RECEIVE_POWER_METER_DATA";
export const REQUEST_POWER_METER_DATA = "/screens/AddHomeOwner/data/Installer/REQUEST_POWER_METER_DATA";

export const RECEIVE_ADD_HOME_OWNER_DATA = "RECEIVE_ADD_HOME_OWNER_DATA";
export const REQUEST_ADD_HOME_OWNER_DATA = "REQUEST_ADD_HOME_OWNER_DATA";

export const REQUEST_ADD_HOME_OWNER_DATA_DUMMY = "REQUEST_ADD_HOME_OWNER_DATA_DUMMY";
export const RECEIVE_ADD_HOME_OWNER_DATA_DUMMY = "RECEIVE_ADD_HOME_OWNER_DATA_DUMMY";

export const REQUEST_CRM_TOKEN_DATA = "REQUEST_CRM_TOKEN_DATA";

export const requestApiData = data => ({ type: REQUEST_API_DATA, data });
export const receiveApiData = data => ({ type: RECEIVE_API_DATA, data });

export const requestStateData = data => ({ type: REQUEST_STATE_DATA, data });
export const receiveStateData = data => ({ type: RECEIVE_STATE_DATA, data });

export const requestTimeZoneData = () => ({ type: REQUEST_TIME_ZONE_DATA });
export const receiveTimezoneData = data => ({ type: RECEIVE_TIME_ZONE_DATA, data });

export const receiveCompanyIdData = data => ({ type: RECEIVE_COMPANY_ID_DATA, data });
export const requestCompanyIdData = data => ({ type: REQUEST_COMPANY_ID_DATA, data });

export const receiveInstallerData = data => ({ type: RECEIVE_INSTALLER_DATA, data });
export const requestInstallerData = data => ({ type: REQUEST_INSTALLER_DATA, data });

export const receivePowerMeter = data => ({ type: RECEIVE_POWER_METER_DATA, data });
export const requestPowerMeter = data => ({ type: REQUEST_POWER_METER_DATA, data });

export const receiveAddHomeOwner = data => ({ type: RECEIVE_ADD_HOME_OWNER_DATA, data });
export const requestAddHomeOwner = data => ({ type: REQUEST_ADD_HOME_OWNER_DATA, data });

export const requestAddHomeOwnerDummy = data => ({ type: REQUEST_ADD_HOME_OWNER_DATA_DUMMY, data });
export const receiveAddHomeOwnerDummy = data => ({ type: RECEIVE_ADD_HOME_OWNER_DATA_DUMMY, data });

export const requestCRMTokenData = data => ({ type: REQUEST_CRM_TOKEN_DATA, data });