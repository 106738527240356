import { Services }     from '../Api/services';


export default class BoxDocumentIntegration {

    constructor() {
        this.result = {};
        this.flag = {};
        this.slug = localStorage.getItem("slug");
    }

    async BoxFolderList(token,folderId,offset) {
        let foldersList = [];
         return new Promise((res, rej) => {
             this.filesListFolder(folderId,token,offset).then(response => {
                 if(!response || !response.item_collection || !response.item_collection.entries){
                     return res();
                 }
            
                 response.item_collection.entries.map((ele) => {
                        
                    if(ele.type == "folder"){
                        foldersList.push(ele);
                    }

                 });
                 if(response.item_collection.total_count > ((offset+1) * response.item_collection.limit)){
                    offset++;
                 } else {
                    offset = null;
                 }
                 res({folder:foldersList,nextPageToken:offset});
             }).catch(error => { });
         })
     }

     filesListFolder = async(folderId, token, offset) => {
        try {
            let url = `https://api.box.com/2.0/folders/${folderId || 0}?limit=1000&direction=DESC&sort=date&offset=${(offset || 0) * 1000}`;
            let res = await Services.getCall(url, true, "Bearer " + token);
            return res.data;
        } catch (error) {
            return;
        }
    }

    getBoxDocumentFileList = async(folderId, token) =>{
        var task = [];
        var files = await this.filesListFolder(folderId,token);
        files?.item_collection?.['entries'].map((ele) => {
            if (ele.type == "file") {
                task.push(this.getFileDetail({
                    name: ele.name,
                    id: ele.id,

                    previewUrl: `https://api.box.com/2.0/files/${ele.id}/content?access_token=${token}`
                }, token));
            }
        })

        return Promise.all(task).then(r => {
            return {
                [folderId] : r
            };
        }).catch((error) => {
            this.logger.error("Error in getBoxDocumentList -->>", error);
            return [];
        })
    }

    getFileDetail= async(file, token) =>{
        let url = `https://api.box.com/2.0/files/${file.id}?fields=name,expiring_embed_link`;
        let res = await Services.getCall(url, true, "Bearer " + token);
        let result =  res.data;
        file['url'] = result?.['expiring_embed_link']?.url;
        return file;
    }
}