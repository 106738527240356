import envConfig from '../environments';

export const INVALID_EMAIL = 'This is invalid email.';
export const PASSWORD_NOT_MATCH = 'Your password and confirmation password do not match.';
export const ALPHA_NUMERIC = 'Your password needs to be 12 alphanumeric characters or greater.';
export const EMPTY_EMAIL = 'Please enter your email first.';
export const EMPTY_PASSWORD = 'Please enter your password.';
export const EMPTY_CREDENTIAL = 'Please enter the credentials.';
export const CODE_REQUIRED = 'Invalid code entered.';
export const ADD_HOMEOWNER = 'ADD HOMEOWNER';
export const SEARCH_HOMEOWNER = 'Search homeowners';
export const TERMS_AND_CONDITION_CHECK = ' Must agree to Terms and Conditions.';

export const MONTH_ARRAY = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const FINANCIAL_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.FINANCIAL_REPORT;
export const HOME_OWNER_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.HOME_OWNER;
export const ENERGY_REPORT_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.ENERGY_REPORT;
export const ADMIN_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.ADMIN;
export const INSTALLER_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.INSTALLER;
export const INBOX_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.INBOX;
export const COMPANY_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.COMPANY;
export const REFERRAL_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.REFERRAL;
export const SURVEY_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.SURVEY;
export const SOCIAL_SHARE_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.SOCIAL_SHARE;
export const FLEET_CSV_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.FLEET_CSV;
export const { SALES_FORCE_BASE_URL, SERVICE_FUSION_BASE_URL, HUBSPOT_BASE_URL, REPORT_SYNC } =
  envConfig.envConfig;
export const HUBSPOT_OAUTH_SCOPES =
  'crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.deals.read%20crm.objects.deals.write%20crm.objects.owners.read';
export const EVERSIGN_POST_URL = envConfig.envConfig.apiGateway.BASE_URL.EVERSIGN_POST;
export const { MARKETING_URL } = envConfig.envConfig.apiGateway.BASE_URL;
export const PODIUM_CREDENTIAL = envConfig.envConfig.podium;
export const S3_17TW_BUCKET = envConfig.envConfig.BUCKET_URL;
export const S3_URL = 'https://prod-17terawatts.s3.amazonaws.com';

export const SALES_FORCE_LOGIN_URL = (clientId) => {
  return `${SALES_FORCE_BASE_URL}/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${window.location.origin}/oauth/callback`;
};
export const HUBSPOT_FORCE_LOGIN_URL = (clientId) => {
  return `${HUBSPOT_BASE_URL}/oauth/authorize?client_id=${clientId}&redirect_uri=${window.location.origin}/oauth/callback&scope=${HUBSPOT_OAUTH_SCOPES}`;
};

export const SERVICE_FUSION_LOGIN_URL = (clientId) => {
  return `${SERVICE_FUSION_BASE_URL}/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${window.location.origin}/oauth/callback`;
};

export const ZOHO_LOGIN_URL = (clientId) => {
  return `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.modules.READ&client_id=${clientId}&response_type=code&access_type=offline&redirect_uri=https://mybodhiapp.com/oauth/callback`;
};

export const PODIUM_AUTH_URL = () => {
  return `https://auth.podium.com/oauth/authorize?client_id=${PODIUM_CREDENTIAL.clientId}&redirect_uri=https%3A%2F%2Fmybodhiapp.com%2Foauth%2Fcallback&response_type=code&scope=write_messages&state=bodhiOauth`;
};
