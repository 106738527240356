
export const REQUEST_GET_HARDWARE_DATA = "REQUEST_GET_HARDWARE_DATA";
export const RECEIVE_GET_HARDWARE_DATA = "RECEIVE_GET_HARDWARE_DATA";
export const REQUEST_GET_UTILITY_RATE_PLAN_DATA = "REQUEST_GET_UTILITY_RATE_PLAN_DATA";
export const RECEIVE_GET_UTILITY_RATE_PLAN_DATA = "RECEIVE_GET_UTILITY_RATE_PLAN_DATA";
export const REQUEST_GET_UTILITY_RATE_PLAN_DATA_BY_ID = "REQUEST_GET_UTILITY_RATE_PLAN_DATA_BY_ID";
export const RECEIVE_GET_UTILITY_RATE_PLAN_DATA_BY_ID = "RECEIVE_GET_UTILITY_RATE_PLAN_DATA_BY_ID";
export const REQUEST_SET_UTILITY_RATE_PLAN_DATA_BY_ID = "REQUEST_SET_UTILITY_RATE_PLAN_DATA_BY_ID";
export const RECEIVE_SET_UTILITY_RATE_PLAN_DATA_BY_ID = "RECEIVE_SET_UTILITY_RATE_PLAN_DATA_BY_ID";

export const REQUEST_SET_UTILITY_DATA_BY_ID = "REQUEST_SET_UTILITY_DATA_BY_ID";
export const RECEIVE_SET_UTILITY_DATA_BY_ID = "RECEIVE_SET_UTILITY_DATA_BY_ID";

export const REQUEST_SET_INVERTER_BRAND = "REQUEST_SET_INVERTER_BRAND";
export const RECEIVE_SET_INVERTER_BRAND = "RECEIVE_SET_INVERTER_BRAND";
export const REQUEST_SET_MODULE_BRAND = "REQUEST_SET_MODULE_BRAND";
export const RECEIVE_SET_MODULE_BRAND = "RECEIVE_SET_MODULE_BRAND";

export const REQUEST_DELETE_MODULE_BRAND = "REQUEST_DELETE_MODULE_BRAND";
export const RECEIVE_DELETE_MODULE_BRAND = "RECEIVE_DELETE_MODULE_BRAND";

export const REQUEST_DELETE_INVERTER_BRAND = "REQUEST_DELETE_INVERTER_BRAND";
export const RECEIVE_DELETE_INVERTER_BRAND = "RECEIVE_DELETE_INVERTER_BRAND";


export const REQUEST_DELETE_RATE_PLAN = "REQUEST_DELETE_RATE_PLAN";
export const RECEIVE_DELETE_RATE_PLAN = "RECEIVE_RATE_PLAN";

export const REQUEST_SET_ELECTRIC_UTILITY= "REQUEST_SET_ELECTRIC_UTILITY";
export const RECEIVE_SET_ELECTRIC_UTILITY= "RECEIVE_SET_ELECTRIC_UTILITY";


export const REQUEST_DELETE_ELECTRIC_UTILITY= "REQUEST_DELETE_ELECTRIC_UTILITY";
export const RECEIVE_DELETE_ELECTRIC_UTILITY= "RECEIVE_DELETE_ELECTRIC_UTILITY";


export const requestSetModuleData = data => ({ type: REQUEST_SET_MODULE_BRAND, data });
export const receiveSetModuleData = data => ({ type: RECEIVE_SET_MODULE_BRAND, data });
export const requestSetInverterData = data => ({ type: REQUEST_SET_INVERTER_BRAND, data });
export const receiveSetInverterData = data => ({ type: RECEIVE_SET_INVERTER_BRAND, data });
export const requestSetUtilityRatePlanByIdData = data => ({ type: REQUEST_SET_UTILITY_RATE_PLAN_DATA_BY_ID, data });
export const receiveSetUtilityRatePlanByIdData = data => ({ type: RECEIVE_SET_UTILITY_RATE_PLAN_DATA_BY_ID, data });
export const requestGetUtilityRatePlanByIdData = data => ({ type: REQUEST_GET_UTILITY_RATE_PLAN_DATA_BY_ID, data });

export const receiveSetUtilityByIdData = data => ({ type: RECEIVE_SET_UTILITY_DATA_BY_ID, data });
export const requestSetUtilityByIdData = data => ({ type: REQUEST_SET_UTILITY_DATA_BY_ID, data });

export const receiveGetUtilityRatePlanByIdData = data => ({ type: RECEIVE_GET_UTILITY_RATE_PLAN_DATA_BY_ID, data });
export const requestGetUtilityRatePlanData = data => ({ type: REQUEST_GET_UTILITY_RATE_PLAN_DATA, data });
export const receiveGetUtilityRatePlanData = data => ({ type: RECEIVE_GET_UTILITY_RATE_PLAN_DATA, data });
export const requestGetHardwareData = data => ({ type: REQUEST_GET_HARDWARE_DATA, data });
export const receiveGetHardwareData = data => ({ type: RECEIVE_GET_HARDWARE_DATA, data });


export const requestDeleteModuleData = data => ({ type: REQUEST_DELETE_MODULE_BRAND, data });
export const receiveDeleteModuleData = data => ({ type: RECEIVE_DELETE_MODULE_BRAND, data });

export const requestDeleteInverterData = data => ({ type: REQUEST_DELETE_INVERTER_BRAND, data });
export const receiveDeleteInverterData = data => ({ type: RECEIVE_DELETE_INVERTER_BRAND, data });

export const requestDeleteRatePlan = data => ({ type: REQUEST_DELETE_RATE_PLAN, data });
export const receiveDeleteRatePlan = data => ({ type: RECEIVE_DELETE_RATE_PLAN, data });

export const requestSetElectricUtility = data => ({ type: REQUEST_SET_ELECTRIC_UTILITY, data });
export const receiveSetElectricUtility = data => ({ type: RECEIVE_SET_ELECTRIC_UTILITY, data });

export const requestDeleteElectricUtility = data => ({ type: REQUEST_DELETE_ELECTRIC_UTILITY, data });
export const receiveDeleteElectricUtility = data => ({ type: RECEIVE_DELETE_ELECTRIC_UTILITY, data });





