import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const suspenseFallback = <div className="suspenseFallback"></div>;
const setLoadingIcon = (fontSize) => <LoadingOutlined style={{ fontSize }} spin />;

// standard
const loadingIcon = setLoadingIcon(60);

export { suspenseFallback, loadingIcon, setLoadingIcon };
