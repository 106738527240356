import {
	REQUEST_PHASE_SURVEY,
	RECEIVE_PHASE_SURVEY
} from "../types/statusFeed";

export const requestStatusFeedPhaseSurvey = data => ({
	type: REQUEST_PHASE_SURVEY, data
});

export const receiveStatusFeedPhaseSurvey = data => ({
	type: RECEIVE_PHASE_SURVEY, data
});