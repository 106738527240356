// import npm packages
import React, { Component } from "react";
import { Input, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// import local files
import {
  requestAddHomeOwner,
  receiveAddHomeOwner,
  requestCRMTokenData
} from "../../actions/addHomeOwner";
import { setLoader } from '../../../Common/Loader/action';
import withRouter   from '../../../../shared/withRouter'
import { getToken } from "../../../../shared/util";
import { jwtDecode } from "../../../../shared/jwt";
import { SALES_FORCE_LOGIN_URL,  HUBSPOT_FORCE_LOGIN_URL, SERVICE_FUSION_LOGIN_URL,ZOHO_LOGIN_URL } from '../../../../shared/config';
import { openNotificationWithIcon } from '../../../../shared/notification';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const style = {
  button: {
    margin: "8px 0"
  },
  error: {
    width: "100%",
    margin: "8px",
    color: "rgb(200,0,0)",
    height: "32px"
  }
};

class AddHomeOwner extends Component {
  constructor(props) {
    super(props);
    this.emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    this.slug = "";
    this.isError = false;
    this.newInstall = false;
    this.existingInstall = false;
    this.state = {
      emailId: "",
      newInstall: false,
      existingInstall: false,
      error: "",
      errorMessage: '',
      isLoader: false,
    };
    this.renderNewInstall = this.renderNewInstall.bind(this);
    this.renderExistingInstall = this.renderExistingInstall.bind(this);
  }

  componentWillMount() {
    this.slug = localStorage.getItem("slug");
    localStorage.removeItem('pathname');
    this.companyId = this.props.router.params.companyId;
    if (
      localStorage.getItem('crmToken')
      && localStorage.getItem('isCRMToken')
    ) {
      this.getCRMTokenData(localStorage.getItem('crmToken'), this.companyId);
      localStorage.removeItem('isCRMToken');
      localStorage.removeItem('crmToken');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps !== this.props &&
      nextProps &&
      nextProps.addhomeOwner &&
      nextProps.addhomeOwner.data &&
      nextProps.addhomeOwner.data.nextPage
    ) {
      if (this.newInstall || localStorage.getItem('installerType') === 'newInstall') {
        this.newInstall = false
        // this.setState({ newInstall: false });
        localStorage.setItem('isHomeOwner', true)
        localStorage.removeItem('installerType')
        this.props.router.navigate(`/${this.slug}/${this.companyId}/newinstall`, {
            isHomeOwner: true,
            email: localStorage.getItem('homeOwnerEmail')
          });
      } else if (this.existingInstall || localStorage.getItem('installerType') === 'existingInstall') {
        this.existingInstall = false;
        localStorage.setItem('isHomeOwner', true)
        localStorage.removeItem('installerType')
        this.props.router.navigate(`/${this.slug}/${this.companyId}/existinginstall`, {
            isHomeOwner: true,
            email: localStorage.getItem('homeOwnerEmail')
        });
        
      }
    } else if (
      nextProps !== this.props &&
      nextProps &&
      nextProps.addhomeOwner &&
      nextProps.addhomeOwner.data &&
      !nextProps.addhomeOwner.data.nextPage && nextProps.addhomeOwner.data.message
    ) {
      this.isError = true;
      let errorMessage = this.state.errorMessage;
      if (errorMessage !== 'no-error') {
        errorMessage = nextProps.addhomeOwner.data.message.clientId ? nextProps.addhomeOwner.data.message.clientId : nextProps.addhomeOwner.data.message;
        if(
          nextProps.addhomeOwner.status
          && nextProps.addhomeOwner.status === 403
        ) {
          localStorage.setItem('pathname',this.props.router.location.pathname);
          errorMessage='';
          switch(nextProps.addhomeOwner.data.message.tool){
            case 'salesforce':
              window.location.replace(SALES_FORCE_LOGIN_URL(nextProps.addhomeOwner.data.message.clientId));
            break;
            case 'hubspot':
              window.location.replace(HUBSPOT_FORCE_LOGIN_URL(nextProps.addhomeOwner.data.message.clientId));
              break;
            case 'servicefusion':
              window.location.replace(SERVICE_FUSION_LOGIN_URL(nextProps.addhomeOwner.data.message.clientId));
              break;
            case'zoho':
              window.location.replace(ZOHO_LOGIN_URL(nextProps.addhomeOwner.data.message.clientId));
              break;
          }
        }
      }
      this.setState({
        error: true,
        errorMessage
      });
    }
  }

  onChangeEmail = e => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( re.test(e.target.value) ) {
    this.isError = false;
    this.setState({
      emailId: e.target.value,
    });
    }
    if(this.slug==='straightup'){
      this.setState({
        emailId: e.target.value,
      });
    }
  };

  getCRMTokenData(code, companyId) {
    this.props.setLoader({ loader: true });
    const data ={
      email: localStorage.getItem('homeOwnerEmail'),
      newInstall: !!(localStorage.getItem('installerType') === 'newInstall'),
      code,
      companyId,
    }
    this.props.requestCRMTokenData(data);

  }

  renderNewInstall() {
    if (this.state.emailId) {
      this.state.emailId = this.state.emailId.replace(/ /g,'');
      const email = encodeURIComponent(this.state.emailId);
      localStorage.setItem('installerType', 'newInstall')
      const data = {
        companyId: this.companyId,
        email: email,
        newInstall: true
      };
      this.newInstall = true
      this.setState({
        newInstall: true,
        errorMessage: ''
      });
      if (email) {
          this.props.setLoader({ loader: true })
          localStorage.setItem('homeOwnerEmail', this.state.emailId);
          this.props.receiveAddHomeOwner({});
          this.props.requestAddHomeOwner(data);
      }
    } else {
      openNotificationWithIcon('error', 'Please share the email address so we can look-up the project information.', ' Homeowner email needed');
    }
  }

  renderExistingInstall() {
    if (this.state.emailId) {
      this.state.emailId = this.state.emailId.replace(/ /g,'');
      localStorage.setItem('installerType', 'existingInstall')
      const email = encodeURIComponent(this.state.emailId);
      const data = {
        companyId: this.companyId,
        email: email,
        newInstall: false
      };
      this.existingInstall = true
      this.setState({
        existingInstall: true,
      });
      if (email) {
          this.setState({
            errorMessage: ''
          })
          localStorage.setItem('homeOwnerEmail', this.state.emailId);
          this.props.setLoader({ loader: true })
          this.props.receiveAddHomeOwner({})
          this.props.requestAddHomeOwner(data);
      }
    } else {
      openNotificationWithIcon('error', 'Please share the email address so we can look-up the project information.', ' Homeowner email needed');
    }
  }

  renderAddHomeOwner = () => {
    let loader = this.state.isLoader || this.props.loader;
    return <React.Fragment>
      <title>Add Homeowner - {this.props.companyName} - Bodhi</title>
      <div>
        <Spin
          spinning={loader}
          indicator={antIcon}
          style={{ color: "#00142D" }}
        >
          <section>
            <div className="container margin-t-73">
              <div className="create-new-account-wrapper box-Shadow">
                <div className="white-box create-new-account-box height-536">
                  <h2 className="font-weight-400 createBodhiAccount">Let's create a new Bodhi account</h2>

                  <div className="row">
                    <div className="offset-md-3 col-md-6 col-12 homeOwnerEmail">
                      <div className="form-group bodhi-acc-input">
                        <label htmlFor="exampleInputEmail1" style={{ textTransform: "inherit" }}>
                          Enter Homeowner's Email Address
                        </label>
                        <Input type="email" className="form-control height24 " id="exampleInputEmail1" aria-describedby="emailHelp" onChange={this.onChangeEmail} />
                      </div>
                      {
                        this.state.errorMessage
                          && this.state.errorMessage !== 'no-error' ?
                          <div
                            style={style.error}
                          >
                            {this.state.errorMessage}
                          </div>
                          :
                          ""
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 offset-sm-2 text-center new-install-box hori-vert-cetner" onClick={this.renderNewInstall}>
                      <a className="add-new-installer">
                       
                      </a>
                      <h4>New install</h4>
                    </div>
                    <div className="col-sm-4 mobile-view text-center">

                      <div className="or margin-auto">
                        or
                    </div>
                    </div>
                    <div onClick={this.renderExistingInstall} className="col-sm-5 text-center existing-insall-box hori-vert-cetner">
                      <a className="add-existing-installer"> 
                             
                      </a>
                      <h4>Existing install</h4>      
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Spin>
      </div>
    </React.Fragment>;
  };

  render() {
    if (getToken()) {
      return this.renderAddHomeOwner();
    } else {
      this.props.router.navigate("/");
      window.location.reload();
      return null;
    }
  }
}

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    addhomeOwner: state.addhomeOwner.homeOwner,
    loader: state.loader.loader.loader,
    companyName : state.header && state.header.getInstaller &&  state.header.getInstaller.data && state.header.getInstaller.data.name
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestAddHomeOwner,
      receiveAddHomeOwner,
      requestCRMTokenData,
      setLoader
    },
    dispatch
  );

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withRouter(AddHomeOwner));
