import { TJourneyTouch, TJourneyTouchTrigger, TJourneyTouchPhase } from '../types';

export interface IBodhiJourneyTouch {
  get: (key: string) => any; // string, boolean, array of objects, etc.
}

/**
 * Initial iteration of a domain object to represent one phase of an overall Bodhi Journey
 */
class BodhiJourneyTouch implements IBodhiJourneyTouch {
  _touch: TJourneyTouch;
  _triggers: TJourneyTouchTrigger[];
  _phase: TJourneyTouchPhase | TJourneyTouchPhase[];

  constructor(touch: TJourneyTouch) {
    this._touch = touch;
  }

  get(key: keyof TJourneyTouch) {
    return this._touch[key];
  }
}

export default BodhiJourneyTouch;
