// import npm packages
import { combineReducers } from "redux";

// import local files
import { RECEIVE_FAQ_QUESTIONS_ANSWERS } from "../../types/faq/faq";

const homeownerFaqQuestionAnswer = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_FAQ_QUESTIONS_ANSWERS:
			return data;
		default:
			return state;
	}
};

export const reducer = combineReducers({
	homeownerFaqQuestionAnswer
});