// import npm packages
import { call, put } from "redux-saga/effects";

// import local files
import { getFaqQuestionAndAnswer } from "../../screens/homeowner/api/faq";
import handleError from "../../shared/errorHandler";
import { setLoader } from "../../screens/Common/Loader/action";
import { receiveFaqQuestionAnswer } from "../../screens/homeowner/actions/faq";

function* getFaqQuestionAnswer(action) {
	try {
		const data = yield call(getFaqQuestionAndAnswer, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveFaqQuestionAnswer(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

export const homeownerFaq = {
	getFaqQuestionAnswer
};