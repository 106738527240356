import styled from 'styled-components';

interface IStyledPromoTakeover {
  backgroundImage?: string;
  variant?: string;
  className?: string;
  fullscreen?: boolean;
}

export const StyledPromoTakeover = styled.div<IStyledPromoTakeover>`
  color: ${(props) => props.theme.colors.blackMed};
  min-height: ${(props) => (props.fullscreen ? '100vh' : '100%')};
  height: auto;
  background: url('${(props) => props.backgroundImage}');
  background-repeat: no-repeat;
  background-position: 300% 50px;
  background-size: 90%;
  display: flex;
  flex-direction: column;
  padding-top: ${(props) =>
    props.fullscreen
      ? '70px'
      : '0'}; // 70px === top nav height: ensure everything is below the top nav
  &.single-cta {
    margin-top: ${(props) => (props.fullscreen ? '80px' : '0')};
    background-position: 270% 50px;
    margin-bottom: 30px;
    @media (min-width: ${(props) => props.theme.breakpoints.md.up}px) {
      background-color: ${(props) => props.theme.colors.white};
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      padding: 25px 40px;
    }
  }
  button.rounded {
    border-radius: 10px;
    height: 40px;
  }
`;

interface IStyleHeader {}

export const StyledHeader = styled.div<IStyleHeader>`
  .pl20 {
    padding-left: 20px;
  }
  .pr20 {
    padding-right: 20px;
  }
  .pt5 {
    padding-top: 5px;
  }
  h5 {
    font-size: 16px;
    color: ${(props) => props.theme.colors.blackMed};
    margin: 4px 0;
  }
  h4 {
    line-height: 22px;
    font-size: 18px;
    opacity: 0.9;
    color: ${(props) => props.theme.colors.black};
    &.mb10 {
      margin-bottom: 10px;
      padding: 0;
    }
  }
  h3 {
    font-size: 28px;
    line-height: 30px;
    color: ${(props) => props.theme.colors.blackMed};
    margin: 4px 0;
  }
  .day-of-week {
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.colors.blackMed};
  }
`;

interface IStyledCalloutBox {
  icon?: string;
  fontFamily?: string;
  className?: string;
}

export const StyledCalloutBox = styled.div<IStyledCalloutBox>`
  font-size: 14px;
  line-height: 18px;

  &.content-callout,
  &.content-callout-static {
    padding: 18px 52px 20px 20px;
    background: #fff;
  }

  &.content-callout {
    position: relative;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(176, 186, 197, 0.5) !important;
    .hidden {
      height: 0;
      overflow: hidden;
      padding: 0;
      margin: 0;
    }
    .visible {
      height: auto;
    }
  }

  &.content-callout-static {
    padding-top: 0;
    padding-bottom: 0;
  }

  button.content-callout {
    position: absolute;
    right: 8px; // need to match right caret for the monitoring CTA
    top: 20px;
    bottom: auto;
    margin: auto;
    border: 0 none;
    font-size: 20px;
    background: transparent;
    color: ${(props) => props.theme.colors.yellow};
    &:active,
    &:focus {
      outline: 0 none;
    }
  }

  &.cta-callout {
    padding: 16px 68px 20px 17px;
    position: relative;
  }

  button {
    position: absolute;
    border: 0 none;
    font-size: 20px;
    color: ${(props) => props.theme.colors.yellow};
  }

  button.cta-callout {
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;
