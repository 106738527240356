import {
	REQUEST_HOMEOWNER_COMPANY_DETAIL,
	RECEIVE_HOMEOWNER_COMPANY_DETAIL
} from "../types/resources/resources";

export const requestHomeownerCompanyDetail = data => ({
	type: REQUEST_HOMEOWNER_COMPANY_DETAIL, data
});

export const receiveHomeownerCompanyDetail = data => ({
	type: RECEIVE_HOMEOWNER_COMPANY_DETAIL, data
});