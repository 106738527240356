import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';

import withRouter from '../../../../shared/withRouter';
import { requestReferralNeighbour, requestReferralDetails } from '../../actions/referralDetails';
import ReferralLanding from './sharedPage/referralLanding';
import MonitoringLanding from './sharedPage/monitoringLanding';
import { setLoader } from '../../../Common/Loader/action';
import {
  requestPostReferralDetails,
  receivePostReferralDetails,
  requestPostAddressDetails,
  receivePostAddressDetails,
  receivePostAddressFormData
} from '../../actions/referralDetails';
import { requestHomeownerEnergyData } from '../../actions/homeOwner';
import { convertJSON2CSS } from '../../../User/components/setUserBackgroud';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

class ShareCommon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      share: '',
      isApiResponse: false
    };
  }

  componentDidMount() {
    let shareType = this.props.router.params.share;
    let referralId = this.props.router.params.referralId;
    if (shareType !== 'sunburst') {
      shareType = 'referral';
      this.props.requestReferralNeighbour({ referralId, shareType });
    }
    this.setState({ share: shareType });
    this.props.requestReferralDetails({ referralId, shareType });
    convertJSON2CSS(this.props.router.params.slug);
  }

  componentWillReceiveProps(nextProps) {
    let details = nextProps?.referralDetails?.homeownerReferrals?.data || {};

    const { router } = this.props;

    if (JSON.stringify(details) !== '{}' && !this.state.isApiResponse) {
      let companyId = details && details.companyId;
      let homeownerId = details && details.id;
      let date = router.params.date;
      let offset = router.params.offset;
      nextProps.setLoader({ loader: true });


      if (this.state.share === 'sunburst') {
        this.props.requestHomeownerEnergyData({ homeownerId, date, offset });
      }

      this.setState({
        isApiResponse: true
      });
      nextProps.setLoader({ loader: false });
    }
  }

  renderPage() {
    let { share } = this.state;
    switch (share) {
      case 'sunburst':
        return <MonitoringLanding {...this.props} />;
      default:
        return <ReferralLanding {...this.props} />;
    }
  }

  render() {
    return (
      <Spin
        spinning={this.props.loader}
        indicator={antIcon}
        style={{ color: '#04bfff' }}
        wrapperClassName="referral-loader">
        {this.renderPage()}
      </Spin>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    referralDetails: state.homeownerReferralDetails,
    loader: state.loader.loader.loader,
    energyReport: state.homeownerLoggedInUser.homeownerEnergyReportReducer.data || {}
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestReferralDetails,
      requestReferralNeighbour,
      setLoader,
      requestPostReferralDetails,
      receivePostReferralDetails,
      requestPostAddressDetails,
      receivePostAddressDetails,
      receivePostAddressFormData,
      requestHomeownerEnergyData
    },
    dispatch
  );

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(ShareCommon));
