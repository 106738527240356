import React, { useState, useRef, useEffect } from "react";
import { 
    DownloadBtn, 
    UploadBtn,
    StyledForm,
    StyledDiv,
    StyledCsvInput
} from "./uploadCsvModal.style";
import { openNotificationWithIcon } from "../../../../shared/notification"; 
import { LoadingIconComponent } from "../../../../constants/icons";


import { getFleetCsvExampleApi, uploadFleetCsvApi } from "../../api/fleet";

type TCsvModalProps = {
    companyId: number;
    mockHandleDrag?: any;
    closeModal: () => void;
};

export const UploadCsvModal: React.FC<TCsvModalProps> = (props) => {
    const { companyId, closeModal } = props;
    const [dataInCsv, setDataInCsv] = useState<string>("");
    const [dragActive, setDragActive] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    
    useEffect(() => {
        const getCsvExample = async () => {
            const response = await getFleetCsvExampleApi();
            if (response) {
                const header = atob(response.data);
                setDataInCsv(header);
            }
        }
        getCsvExample().catch(console.error)
    },[]);

    // handle drag events
    const handleDrag = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === 'dragenter' || event.type === 'dragover') {
            setDragActive(true);
        } else if (event.type === 'dragleave') {
            setDragActive(false);
        }
    };

    // handle when file is dropped
    const handleDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            // send alert if wrong file type is dropped
            if (event.dataTransfer.files[0].type !== "text/csv") {
                openNotificationWithIcon("error", "Wrong file type", "Please select a .csv file");
                return;
            };
            // one file was dropped and will be sent to the backend
            const form = document.getElementById('form') as HTMLFormElement;
            const formData = new FormData(form);
            formData.append("csvFile", event.dataTransfer.files[0]);
            setIsLoading(true);
            const uploadFleetCsv = async () => {
                const response = await uploadFleetCsvApi({
                    data: formData,
                    companyId: companyId,
                });
                if (response.status === 202) {
                    openNotificationWithIcon(
                        "success", 
                        "File uploaded", 
                        "Your file was uploaded successfully"
                    );
                    setIsLoading(false);
                    closeModal();
                } else {
                    openNotificationWithIcon(
                        "error",
                        "File upload failed",
                        "Something went wrong. Please try again."
                    );
                };
            };
            uploadFleetCsv().catch(console.error);
        }
    };

    // handle when file is selected with a click
    const handleChange = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.files && event.target.files[0]) {
            // send alert if wrong file type is selected
            if (event.target.files[0].type !== "text/csv") {
                openNotificationWithIcon("error", "Wrong file type", "Please select a .csv file");
                return;
            }
            // one file was selected and will be sent to the backend
            const form = document.getElementById('form') as HTMLFormElement;
            const formData = new FormData(form);
            formData.append("csvFile", event.target.files[0]);
            setIsLoading(true);
            const uploadFleetCsv = async () => {
                const response = await uploadFleetCsvApi({
                    data: formData,
                    companyId: companyId,
                });
                if (response.status === 202) {
                    openNotificationWithIcon(
                        "success", 
                        "File uploaded", 
                        "Your file was uploaded successfully"
                    );
                    setIsLoading(false);
                    closeModal();
                } else {
                    openNotificationWithIcon(
                        "error",
                        "File upload failed",
                        "Something went wrong. Please try again."
                    );
                };
            };
            uploadFleetCsv().catch(console.error);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };


    return (
        <StyledForm
          className='fleet-upload-form'
          id='form'
          onDragEnter={handleDrag}
          onSubmit={(event) => event.preventDefault()}
          method='put'
          data-testid='fleet-csv-form'
        >
            <StyledDiv className='fleet-csv-modal'>
                <StyledCsvInput 
                  type='file'
                  accept='.csv'
                  className='fleet-csv-upload'
                  multiple={false}
                  onChange={handleChange}
                  ref={inputRef}
                  data-testid='file-input'
                />
                <div 
                  className='fleet-drag-drop'
                  onDragEnter={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                  data-testid='file-drop'
                >
                    {!isLoading && (
                    <>
                        <span className='fleet-cloud-upload'></span>
                        <p className='drop-csv'>
                            Drag a .csv file here or browse your files to upload. This window will close automatically once your file is uploaded successfully.
                        </p>
                    </>
                    )}
                    {isLoading && (
                        <div>
                            <p>Loading...</p>
                            <LoadingIconComponent />
                        </div>
                    )}
                    
                </div>
                <DownloadBtn data-testid='download-button'>
                    <a
                      href={`data:text/csv;charset=utf-8,${escape(dataInCsv)}`}
                      download='config.csv'
                      className='download-csv'
                      data-testid='example-csv'
                    >
                        Download sample
                    </a>
                </DownloadBtn>
                <UploadBtn
                  className='fleet-csv-btn'
                  onClick={onButtonClick}
                  data-testid='upload-button'
                >
                    Upload file
                </UploadBtn>
            </StyledDiv>
        </StyledForm>
    );
};