import React from 'react';

import { bodhiLogoPng } from '../../constants/icons';
import {
  DEFAULT_USER_ALT,
  DEFAULT_BODHI_ALT,
  CUSTOMIZED_BGCOLOR_CLASS,
  CUSTOMIZED_ICON_BGCOLOR_CLASS
} from '../../constants';
import {
  DefaultDecorator,
  DefaultIconImgDecorator,
  DefaultIconImgDecoratorContainer
} from './UserIdentifier.style';
import { IUserIdentifier } from './types';
import { isValidUrl } from '../../shared';

import {ReactComponent as BodhiIcon} from "../../Assets/Images/B_Avatar.svg"; 

const customIdentifierClassname = `${CUSTOMIZED_ICON_BGCOLOR_CLASS} ${CUSTOMIZED_BGCOLOR_CLASS}`;

/**
 * Represent a user-facing identifier, e.g. initials, or a company, e.g. Bodhi
 */
const UserIdentifier: React.FC<IUserIdentifier> = (props) => {
  const { userInitials, image, children, decorator, allowInstallerCustom,displayBodhiLogo } = props;

  const Decorator = decorator || DefaultDecorator;

  const decorProps: Record<string, any> = {};

  if (!!allowInstallerCustom) {
    decorProps.className = customIdentifierClassname;
  }

  const classNames = [];
  if (!!allowInstallerCustom) {
    classNames.push(customIdentifierClassname);
  } else {
    classNames.push('default-identifier');
  }

  /**
   * Image takes precedence, userInitials may be more common
   */
  if (!image) {
    if (!!userInitials) {
      return (
        <Decorator className={classNames.join(' ')}>
          <span>{userInitials}</span>
          {children}
        </Decorator>
      );
    }
  }

  if (!!image && isValidUrl(image)) {
    return (
      <Decorator hasImage>
        <span className="user-image">
          <img src={image} alt={DEFAULT_USER_ALT} />
        </span>
        {children}
      </Decorator>
    );
  }

  return (
    
    displayBodhiLogo ? (<DefaultIconImgDecoratorContainer>
      <DefaultIconImgDecorator src={bodhiLogoPng} alt={DEFAULT_BODHI_ALT} />
      {children}
    </DefaultIconImgDecoratorContainer>) : <BodhiIcon data-testid='avatar'/>

  );
};

export default UserIdentifier;
