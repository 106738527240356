import { apiBaseUrlFactory } from './helpers';

const PRD_ENV = 'prod';
const prdApiBaseUrlFactory = apiBaseUrlFactory(PRD_ENV);

// to ensure we can (1) mock the API, and (2) unit test BASE_URL for Inbox
export const PRD_INBOX_SUBDOMAIN = '389khxfoc2';
export const PROD_FLEET_CSV_SUBDOMAIN = 'cdq1jimm9k';
export const PROD_FINANCIAL_REPORT_SUBDOMAIN = 'jboo851069';

const prod = {
  s3: {
    BUCKET: 'prod-17terawatts',
    URL: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_fPv3YgGO5',
    IMAGE_URL: 'https://s3.amazonaws.com/prod-17terawatts'
  },
  podium: {
    clientId: '60fb796a-1b11-4011-8da4-f75dd2a81d3a'
  },
  apiGateway: {
    REGION: '',
    BASE_URL: {
      HOME_OWNER: 'https://rx83wzhw50.execute-api.us-east-1.amazonaws.com/prod/',
      INBOX: prdApiBaseUrlFactory(PRD_INBOX_SUBDOMAIN),
      ADMIN: 'https://qctcwfux93.execute-api.us-east-1.amazonaws.com/prod/',
      INSTALLER: 'https://xrykc648c3.execute-api.us-east-1.amazonaws.com/prod/',
      COMPANY: 'https://2y10ztdhib.execute-api.us-east-1.amazonaws.com/prod/',
      REFERRAL: 'https://ypd9es9i08.execute-api.us-east-1.amazonaws.com/prod/',
      SOCIAL_SHARE: 'https://42832emok3.execute-api.us-east-1.amazonaws.com/prod/',
      SURVEY: 'https://0rfiiprwmd.execute-api.us-east-1.amazonaws.com/prod/',
      ENERGY_REPORT: 'https://ubdlekgu2b.execute-api.us-east-1.amazonaws.com/prod/',
      EVERSIGN_POST: 'https://olrnftij30.execute-api.us-east-1.amazonaws.com/prod/',
      MARKETING_URL: 'https://2f5x0v3igh.execute-api.us-east-1.amazonaws.com/prod/',
      FINANCIAL_REPORT: prdApiBaseUrlFactory(PROD_FINANCIAL_REPORT_SUBDOMAIN),
      FLEET_CSV: prdApiBaseUrlFactory(PROD_FLEET_CSV_SUBDOMAIN)
    }
  },
  cognito: {
    Region: 'us-east-1',
    UserPoolId: 'us-east-1_Xf57PI4Tl',
    IdentityPoolId: 'us-east-1:d03d8bc2-3cd6-406b-bc85-7620bfee80e7',
    ClientId: '3hu1rrrd79irlci1o720a8sseo'
  },
  googleDrive: {
    CLIENT_ID: '644481826587-sv0aue23nn1idqluthqiioka4damr7ms.apps.googleusercontent.com',
    API_KEY: 'AIzaSyACHzqSuUx3jrZRnT7PezSybu7mWOO0rsM'
  },
  dropbox: {
    clientId: 'iih4chsjrjei9f5',
    client_secret: '4mgda3af8urax6z'
  },
  googleMap: {
    API_KEY: 'AIzaSyCB6-bafvWwJRIi48ycKHKbWLfiJbhPt4w'
  },
  SALES_FORCE_BASE_URL: 'https://login.salesforce.com',
  SERVICE_FUSION_BASE_URL: 'https://api.servicefusion.com',
  HUBSPOT_BASE_URL: 'https://app.hubspot.com',
  BUCKET_URL: 'https://prod-17terawatts.s3.amazonaws.com/public',
  REPORT_SYNC: true,
  GA_KEY: 'UA-145073533-1',
  ANALYTICS: {
    ChurnZero: '1!aUrAz6F4NFYD4GalMNJGZ6Su6t4To-4qAMV-aizGl7gt130F'
  }
};

export default prod;
