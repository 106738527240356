import React from 'react';
import {
  Box,
  Button,
  Container,
  Stack,
  Divider,
  Footer,
  Gutter,
  Header,
  Hero,
  Illustration,
  Image,
  Link,
  Picture,
  Text
} from './Homepage.styles';
import { Divider as AntdDivider } from 'antd';
import withRouter from '../../../../shared/withRouter';
import { globalStyles } from '../../../../constants';

import type { Params, Router, NavigateFunction } from 'react-router-dom';

import blackLogo from '../../../../Assets/Images/bodhi_logo.svg';
import YellowLogo from '../../../../Assets/Images/yellow-logo.png';
import homeIllustration from '../../../../Assets/Images/hero.svg';
import homeMobileIllustration from '../../../../Assets/Images/home-mobile-illustration.png';
import { CONTENT, HERO_DESCRIPTION, HERO_TITLE, FOOTER, YEAR } from './helper';

interface Router {
  navigate: NavigateFunction;
  params: Params;
}

interface HomepageProps {
  router: Router;
}

const Homepage = (props: HomepageProps) => {
  const loginHandler = () => {
    if (props.router.params.slug) {
      props.router.navigate(`/${props.router.params.slug}/login`);
    } else {
      props.router.navigate('/login');
    }
  };

  const {
    fonts: {
      sizes: { font16, font18, font20, font24, font30, font35, font50, font60 },
      standardBold
    },
    colors: { blackMed, white, black, yellow, yellow100, red }
  } = globalStyles;

  return (
    <Stack>
      <title data-testid="homepage-title">Bodhi</title>

      <Header data-testid="homepage-header">
        <Image
          loading="lazy"
          alt="bodhi black color logo"
          width="99px"
          height="30px"
          src={blackLogo}
        />

        <Button
          data-testid="homepage-header-login-button"
          height="50px"
          width="120px"
          size={font18}
          lineHeight="22px"
          color={blackMed}
          bgColor={white}
          hoverBgColor={black}
          hoverColor={white}
          onClick={loginHandler}
        >
          Log in
        </Button>
      </Header>

      <Hero>
        <Container width="547px">
          <Text
            color={white}
            size={font60}
            media={{ size: font24, lineHeight: '30px', padding: '12px' }}
            lineHeight={font60}
            padding="28px"
          >
            {HERO_TITLE}
          </Text>

          <Image
            alt="bodhi yellow color logo"
            width="264px"
            height="80px"
            media={{ width: '132px', height: '40px' }}
            src={YellowLogo}
          />

          <Text
            color={white}
            size={font24}
            media={{ size: font16, lineHeight: '22px', padding: '12px' }}
            lineHeight={font35}
            padding="28px"
          >
            {HERO_DESCRIPTION}
          </Text>

          <Button
            data-testid="homepage-hero-login-button"
            height="60px"
            width="222px"
            media={{ width: '110px', height: '50px' }}
            size={font20}
            lineHeight="24px"
            color={blackMed}
            bgColor={yellow}
            hoverBgColor={yellow100}
            onClick={loginHandler}
          >
            Log in
          </Button>
        </Container>
        <Picture>
          <source media="(max-width:650px)" srcSet={homeMobileIllustration} />

          <Illustration
            data-testid="bodhi-home-illustration"
            alt="bodhi illustration"
            width="100%"
            height="auto"
            src={homeIllustration}
          />
        </Picture>
      </Hero>

      <Gutter gutterY="40px" />

      {CONTENT.map((information, index) => (
        <React.Fragment key={`${information.key}-section-${index}`}>
          <Box key={`${information.key}-${index}`} direction={information.styles.direction}>
            <img
              loading="lazy"
              alt={information.alt}
              width="100%"
              height="100%"
              src={information.imgSrc}
            />

            <Container align={information.styles.align}>
              <Text
                color={blackMed}
                size={font50}
                fontFamily={standardBold}
                align="left"
                media={{ size: font30, lineHeight: '40px' }}
                lineHeight={font60}
              >
                {information.title}
              </Text>

              <Text
                color={blackMed}
                size={font24}
                align="left"
                direction="initial"
                media={{ size: font16 }}
              >
                {information.description}
              </Text>
            </Container>
          </Box>

          <Gutter gutterY="40px" />
        </React.Fragment>
      ))}

      <Divider />
      <Footer data-testid="homepage-footer">
        <Text>{YEAR}</Text>
        <Container direction="row">
          <Link to={FOOTER.TERMS_OF_SERVICE_URL} color={black}>
            {FOOTER.TERMS_OF_SERVICE}
          </Link>
          <AntdDivider style={{ background: black }} type="vertical" />
          <Link to={FOOTER.PRIVACY_POLICY_URL} color={black}>
            {FOOTER.PRIVACY_POLICY}
          </Link>
        </Container>

        <Container direction="row">
          <Link
            href={FOOTER.WEBSITE_URL}
            isExternal={true}
            rel="noopener noreferrer"
            target="_blank"
            color={red}
          >
            {FOOTER.WEBSITE}
          </Link>
          <AntdDivider style={{ background: black }} type="vertical" />
          <Link
            href={FOOTER.MAIL_TO_ADDRESS}
            isExternal={true}
            rel="noopener noreferrer"
            target="_blank"
            color={red}
          >
            {FOOTER.MAIL_TO}
          </Link>
        </Container>
      </Footer>
    </Stack>
  );
};

export default withRouter(Homepage);
