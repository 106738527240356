import React from 'react';
import styled from 'styled-components';

const TooltipStyles = styled.div`
  .tt-heading-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
  }

  .tt-icon {
    width: 25px;
    height: auto;
    margin-right: 1em;
  }

  .tt-heading {
    font-size: inherit;
    line-height: inherit;
    font-family: '${(props) => props.theme.fonts.standardBold}';
  }

  .tt-body-text {
    font-size: inherit;
    line-height: inherit;
    margin: 0 0 15px 0;
    font-family: inherit;
    text-align: left;
  }

  button.btn-black {
    border-color: ${(props) => props.theme.colors.yellow};
    color: ${(props) => props.theme.colors.yellow};
    &:hover {
      color: ${(props) => props.theme.colors.blackMed};
      background-color: ${(props) => props.theme.colors.yellow};
    }
    &.wide {
      width: auto !important;
    }
  }

  button.btn-grey {
    border-color: ${(props) => props.theme.colors.blackMed};
    color: ${(props) => props.theme.colors.blackMed};
    &:hover {
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.white};
    }
    &.wide {
      width: auto !important;
    }
  }

  font-family: '${(props) => props.theme.fonts.standardRegular}';
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  min-height: auto;
  padding: ${(props) => (props.type === 'default-tooltip' ? '4px 4px 0' : '7px 15px')};
`;

// padding: ${(props) => (props.type === 'default-tooltip' ? '4px 4px 0' : '7px 15px')};

const BodhiTooltip = (props) => {
  let type = 'default-tooltip';
  if (props.type) {
    switch (props.type) {
      case 'installer':
        type = 'installer-tooltip';
      default:
        type = `default-tooltip ${props.type}`;
    }
  }

  return (
    <TooltipStyles type={type}>
      {props.heading && (
        <div className="tt-heading-wrap">
          {props.icon && <img src={props.icon} className="tt-icon" alt="an icon" />}
          <div className="tt-heading">{props.heading}</div>
        </div>
      )}
      <div className="tt-body-text">{props.text}</div>
      {props.children}
    </TooltipStyles>
  );
};

export default BodhiTooltip;
