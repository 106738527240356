// import npm packages
import React, { Component }   from "react";
import { Input}  from "antd";

import withRouter       from '../../../../shared/withRouter'
import { getToken }     from "../../../../shared/util";
const { TextArea } = Input;
class CreateCampaignForm extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    componentWillMount() {}
  
    componentWillReceiveProps(nextProps) {}  

    campaignForm(){
      return(
        <>
          <div className="col-sm-7" style={{paddingRight:'160px'}}>
            <div className="form-group mt-0">
              
              {this.props.checkBoxWrapper}

              <div className="form-group">
                <label>Post Headline</label>
                <Input type="text" className="form-control"
                  maxLength = {50}
                  placeholder="Enter in an attention grabbing headline" 
                  onChange={(e)=>this.props.updateCampaignDataState('campaignHeadline',e.target.value)}
                  value = {this.props.campaignHeadline}
                />
                <p className='length-text'>Total characters left : {50-(this.props.campaignHeadline.length||0)}</p>
              </div>

              <div className="form-group">
                <label>Email Subject</label>
                <Input 
                  type="text" 
                  maxLength = {50}
                  className="form-control"
                  placeholder="Enter in the subject line for the emails"
                  onChange={(e)=>this.props.updateCampaignDataState('campaignEmailSubject',e.target.value)}
                  value = {this.props.campaignEmailSubject}
                />
                 <p className='length-text'>Total characters left : {50-(this.props.campaignEmailSubject.length||0)}</p>
              </div>

              <div className="form-group">
                <label>Post Body</label>
                <TextArea className="form-control textareabox" rows="4"
                  maxLength = {300}
                  placeholder="Enter in a message that provides further details. 
                  This will be shown in your in-app post and email. 300 characters or less."
                  onChange={(e)=>this.props.updateCampaignDataState('campaignEmailBody',e.target.value)}
                  value = {this.props.campaignEmailBody}
                />
                <p className='length-text'>Total characters left : {300-(this.props.campaignEmailBody.length||0)}</p>
              </div>

              <div className="form-group">
                <label>CTA Button Label</label>
                <Input type="text" className="form-control"
                  maxLength = {20}
                  placeholder="Enter in a label for the Call To Action button" 
                  onChange={(e)=>this.props.updateCampaignDataState('ctaLabel',e.target.value)}
                  value = {this.props.ctaLabel}
                />
                <p className='length-text'>Total characters left : {20-(this.props.ctaLabel.length||0)}</p>
              </div>

              <div className="form-group">
                <label>CTA Link</label>
                <Input type="text" className="form-control"
                  placeholder="Enter in the URL which the Call To Action will link to" 
                  onChange={(e)=>{
                    this.props.updateCampaignDataState('ctaLink',e.target.value)
                  }}
                  value = {this.props.ctaLink}
                />
              </div>

              <div className="form-group">
                <label>SMS Body</label>
                <TextArea className="form-control textareabox" rows="3"
                  maxLength = {180}
                  placeholder="Enter in an abbreviated message that will be sent via SMS to your customers.  
                  The CTA link will be included in this message.  180 characters or less."
                  onChange={(e)=>this.props.updateCampaignDataState('campaignSMS',e.target.value)}
                  value = {this.props.campaignSMS}
                />
                <p className='length-text'>Total characters left : {180-(this.props.campaignSMS.length||0)}</p>
              </div>
            </div>
          </div>
          {this.props.previewPost()}
        </>
      )
    }

    render() {
      if (getToken()) {
        return this.campaignForm();
      } else {
        this.props.router.navigate("/");
        window.location.reload();
        return null;
      }
    }
  }

export default withRouter(CreateCampaignForm);
