import { Context, getRollbarFromContext } from '@rollbar/react';
import React, { Component } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Hoc from './HocApp';
import { getUser, withRouter } from './shared';
import Auth from './shared/Auth';

import { INBOX_BASE, INBOX_THREAD, LOGIN_BASE } from './constants/routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles/style.scss';

import DashboardCommon from './screens/Common/DashboardCommon';
import Homepage from './screens/Homepage/components/Homepage';
import AddHomeOwner from './screens/Installer/components/addHomeOwner/AddHomeOwner';
import ExistingInstall from './screens/Installer/components/addHomeOwner/ExistingInstall';
import LiteNewInstall from './screens/Installer/components/addHomeOwner/LiteNewInstall';
import NewInstall from './screens/Installer/components/addHomeOwner/NewInstall';
import HomeOwner from './screens/Installer/components/homeOwner/homeOwner';
import HomeownerLayout from './screens/Layout/Homeowner';
import InstallerLayout from './screens/Layout/Installer';
import ForceChangePassword from './screens/User/components/ForceChangePassword';
import ForgotPassword from './screens/User/components/ForgotPassword';
import SignIn from './screens/User/components/Login';
import PrivacyAndPolicy from './screens/User/components/PrivacyAndPolicy';
import ResetPassword from './screens/User/components/ResetPassword';
import TermsAndConditions from './screens/User/components/TermsAndConditions';
import SalesforceGuide from './screens/User/components/salesforceGuide';

import ExistingInstallSuccess from './screens/Installer/components/addHomeOwner/ExistingInstallSuccess';
import EmployeeAccounts from './screens/Installer/components/team/EmployeeAccounts';
import FleetDashboard from './screens/Installer/container/fleet';
import Setting from './screens/Installer/container/settings';

import Inbox from './screens/Installer/container/inbox';

import Search from './screens/Installer/components/Search';
import OAuth from './screens/Installer/components/auth';
import CompanySetting from './screens/Installer/container/companySettings/CompanySetting';

import AdminMap from './screens/Admin/components/Map/map';
import AddCompany from './screens/Admin/components/dashboard/AddCompany';
import AdminUtility from './screens/Admin/components/dashboard/Utility';
import AdminDashboard from './screens/Admin/components/dashboard/dashboard';
import AdminLayout from './screens/Layout/admin';

import ShareCommon from './screens/homeowner/components/shared/shareCommon';
import CalculatorLanding from './screens/homeowner/components/shared/sharedPage/calculatorComponent';
import SolarCommunity from './screens/homeowner/components/shared/solarCommunity';
import SystemAtGlance from './screens/homeowner/components/shared/systemAtGlance';
import Contact from './screens/homeowner/container/contact/contact';
import Documents from './screens/homeowner/container/documents';
import FAQ from './screens/homeowner/container/faq';
import ResourcesForm from './screens/homeowner/container/resources/resources';
import Settings from './screens/homeowner/container/settings';

import { PRODUCT_NAME } from './constants';
import ExistingCampaign from './screens/campaign/components/ExistingCampaign/ExistingCampaign';
import CampaignDashboard from './screens/campaign/components/dashboard/dashboard';
import CreateCampaign from './screens/campaign/components/new-campaign/create-campaign';

import envConfig from './environments';

let slug = '';

const ValidateAdminUser = ({ children }) => {
  return !getUser() ? <Navigate to={{ pathname: '/' }} /> : children;
};

class App extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.user = getUser();
  }

  componentWillMount() {
    window.scroll({ top: 0 });
    const url = window.location.pathname;
    slug = url.split('/')[1];
    const array = [
      'dropboxAuth',
      'forgotpassword',
      'resetpassword',
      'oauth',
      'terms',
      'privacy-policy',
      'login',
      'salesforce-configuration',
      'salesforce-user-guide',
      'admin'
    ];
    if (array.indexOf(slug) === -1) localStorage.setItem('slug', slug);
  }

  componentDidMount() {
    if (this.user) {
      /**
       * Update Rollbar configuration with person payload data, so the Rollbar UI can be used to identify users affected by issues
       */
      const rollbar = getRollbarFromContext(this.context).configure({
        payload: {
          person: {
            id: this.user.companyId,
            username: this.user.role,
            email: this.user.email
          }
        },
        scrubPaths: ['this.state.password']
      });
    }

    this.addRemoveColor();
  }

  componentDidUpdate() {
    this.addRemoveColor();
  }

  addRemoveColor() {
    if (
      (window.location.pathname.includes('/forgotpassword') ||
        window.location.pathname.includes('/resetpassword') ||
        window.location.pathname.includes('/terms') ||
        window.location.pathname.includes('/privacy-policy') ||
        window.location.pathname.includes('/forcechangepassword') ||
        window.location.pathname.includes(LOGIN_BASE) ||
        window.location.pathname === `/${slug}` ||
        window.location.pathname === `/${slug}/`) &&
      window.location.pathname !== '/'
    ) {
      document.body.classList.add('bg-color');
      document.body.classList.remove('homeowner-bg');
    }
  }

  get getSlug() {
    return localStorage.getItem('slug') || localStorage.getItem('token');
  }

  render() {
    window.scroll({ top: 0 });

    if (this.getSlug) {
      const user = getUser();
      const Frame =
        user && user.role && user.role.includes('homeowner') ? HomeownerLayout : InstallerLayout;
      return (
        <>
          <Auth />
          <Routes>
            <Route
              path="/admin/map"
              element={
                <ValidateAdminUser>
                  <AdminLayout component={AdminMap} />
                </ValidateAdminUser>
              }
            />
            <Route
              path="/admin/addcompany"
              element={
                <ValidateAdminUser>
                  <AdminLayout component={AddCompany} />
                </ValidateAdminUser>
              }
            />
            <Route
              path="admin/utility"
              element={
                <ValidateAdminUser>
                  <AdminLayout component={AdminUtility} />
                </ValidateAdminUser>
              }
            />
            <Route
              path="/admin/dashboard"
              element={
                <ValidateAdminUser>
                  <AdminLayout component={AdminDashboard} />
                </ValidateAdminUser>
              }
            />
            <Route
              path="/admin/:companyId/companysetting"
              element={
                <ValidateAdminUser>
                  <AdminLayout component={CompanySetting} navigation="CompanySetting" />
                </ValidateAdminUser>
              }
            />

            <Route path={`/:slug${LOGIN_BASE}`} element={<SignIn />} />
            <Route path="/:slug/terms" element={<TermsAndConditions />} />

            <Route path="/dropboxAuth" element={<Frame component={OAuth} />} />

            <Route path="/salesforce-user-guide" element={<SalesforceGuide />} />
            <Route path="/salesforce-configuration" element={<SalesforceGuide />} />

            <Route path="/oauth/callback" element={<Frame component={OAuth} />} />

            <Route path="/:slug/privacy-policy" element={<PrivacyAndPolicy />} />
            <Route path="/:slug/forcechangepassword" element={<ForceChangePassword />} />
            <Route path="/:slug/forgotpassword" element={<ForgotPassword />} />
            <Route path="/:slug/resetpassword/:email" element={<ResetPassword />} />

            <Route path="/:slug/:companyId/newinstall" element={<Frame component={NewInstall} />} />
            <Route
              path="/:slug/addhomeowner/success"
              element={<Frame component={ExistingInstallSuccess} />}
            />
            <Route
              path="/:slug/:companyId/addhomeowner"
              element={<Frame component={AddHomeOwner} />}
            />
            <Route
              path="/:slug/:companyId/existinginstall"
              element={<Frame component={ExistingInstall} />}
            />
            <Route
              path="/:slug/:companyId/litenewinstall"
              element={<Frame component={LiteNewInstall} />}
            />

            <Route
              path="/:slug/:companyId/dashboard/:id/homeowner"
              element={<Frame component={HomeOwner} />}
            />
            <Route
              path="/:slug/:companyId/dashboard"
              element={
                <Frame
                  roleBasedAccess={PRODUCT_NAME.Dashboard}
                  component={DashboardCommon}
                  navigation="MainNavigation"
                />
              }
            />
            <Route path="/:slug/:companyId/search" element={<Frame component={Search} />} />

            {/* Start: installer primary nav section begin */}
            <Route
              path="/:slug/:companyId/insights"
              element={
                <Frame roleBasedAccess={PRODUCT_NAME.Insights} component={DashboardCommon} />
              }
            />
            <Route
              path="/:slug/:companyId/companysetting"
              element={
                <Frame
                  roleBasedAccess={PRODUCT_NAME.Settings}
                  component={CompanySetting}
                  navigation="CompanySetting"
                />
              }
            />
            <Route
              path={INBOX_BASE}
              element={
                <Frame
                  component={Inbox}
                  navigation="CompanyInbox"
                  customSearch={true}
                  roleBasedAccess={PRODUCT_NAME.Inbox}
                />
              }
            />
            <Route
              path={INBOX_THREAD}
              element={
                <Frame
                  component={Inbox}
                  navigation="CompanyInbox"
                  customSearch={true}
                  roleBasedAccess={PRODUCT_NAME.Inbox}
                />
              }
            />
            <Route
              path="/:slug/:companyId/team"
              element={
                <Frame
                  roleBasedAccess={PRODUCT_NAME.Team}
                  component={EmployeeAccounts}
                  standardLayout={true}
                />
              }
            />
            {/* End: installer primary nav section end */}

            {/* home owner routes start */}
            <Route path="/:slug/dashboard" element={<Frame component={DashboardCommon} />} />
            <Route path="/:slug/faq" element={<Frame component={FAQ} />} />
            <Route path="/:slug/settings" element={<Frame component={Settings} />} />
            <Route
              path="/:slug/resources/:slide/:slideNumber"
              element={<Frame component={ResourcesForm} />}
            />
            <Route path="/:slug/resources/:slide" element={<Frame component={ResourcesForm} />} />
            <Route path="/:slug/resources" element={<Frame component={ResourcesForm} />} />
            <Route path="/:slug/contact" element={<Frame component={Contact} />} />
            <Route path="/:slug/systemGlance" element={<Frame component={SystemAtGlance} />} />
            <Route path="/:slug/solarCommunity" element={<Frame component={SolarCommunity} />} />
            <Route path="/:slug/documents" element={<Frame component={Documents} />} />

            <Route
              path="/:slug/statusFeed/:touchName"
              element={<Frame component={DashboardCommon} />}
            />

            <Route
              path="/:slug/referral/:referralId/:share/:method/:date/:offset"
              element={<ShareCommon />}
            />

            <Route
              path="/:slug/referral/:referralId/:share/:method/calculate"
              element={<CalculatorLanding />}
            />

            <Route path="/:slug/referral/:referralId/:share/:method" element={<ShareCommon />} />

            {/* home owner routes end */}

            <Route
              path="/:slug/:companyId/dashboard"
              element={
                <Frame roleBasedAccess={PRODUCT_NAME.Dashboard} component={DashboardCommon} />
              }
            />
            <Route path="/:slug/:companyId/setting" element={<Frame component={Setting} />} />
            <Route
              path="/:slug/:companyId/fleet/dashboard"
              element={
                <Frame
                  roleBasedAccess={PRODUCT_NAME.Fleet}
                  component={FleetDashboard}
                  header="fleet"
                />
              }
            />
            <Route
              path="/:slug/:companyId/campaign/create"
              element={
                <Frame roleBasedAccess={PRODUCT_NAME.Campaigns} component={CreateCampaign} />
              }
            />
            <Route
              path="/:slug/:companyId/campaign/dashboard"
              element={
                <Frame roleBasedAccess={PRODUCT_NAME.Campaigns} component={CampaignDashboard} />
              }
            />

            <Route
              path="/:slug/:companyId/campaign/:id"
              element={
                <Frame roleBasedAccess={PRODUCT_NAME.Campaigns} component={ExistingCampaign} />
              }
            />

            <Route path="*" element={<SignIn />} />
          </Routes>
        </>
      );
    }
    return (
      <>
        <Auth />
        <Routes>
          <Route
            path="/admin/dashboard"
            element={
              <ValidateAdminUser>
                <AdminLayout component={AdminDashboard} />
              </ValidateAdminUser>
            }
          />

          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/salesforce-user-guide" element={<SalesforceGuide />} />
          <Route path="/salesforce-configuration" element={<SalesforceGuide />} />
          <Route path="/resetpassword/:email" element={<ResetPassword />} />
          <Route path="/forcechangepassword" element={<ForceChangePassword />} />
          <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path={LOGIN_BASE} element={<SignIn />} />
          <Route
            path="/:slug/referral/:referralId/:share/:method/:date/:offset"
            element={<ShareCommon />}
          />
          <Route
            path="/:slug/referral/:referralId/:share/:method/calculate"
            element={<CalculatorLanding />}
          />
          <Route path="/:slug/referral/:referralId/:share/:method" element={<ShareCommon />} />
          <Route path="/:slug" element={<SignIn />} />
          <Route path="/" element={<Homepage />} />
        </Routes>
      </>
    );
  }
}

export default Hoc(withRouter(App));
