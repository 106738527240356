import * as AWS from 'aws-sdk';
import envConfig from '../../environments/index';
import { FILE_UPLOAD_STATUS } from '../Installer/components/inbox/constants';
const loaded:any = [];
export const uploadImageonS3 = (s3Path:string, mediaBody: any,sizeTotal?:number,setFileUploadProgressState?:any,setFileUploadStatusState?:any) => {
    const fileName:string = mediaBody.name;
    return new Promise((res, rej) => {
      let s3Obj = {
        params: {
          Bucket: envConfig.envConfig.s3.BUCKET,
          Key: `${s3Path}/${fileName}`,
          Body: mediaBody,
          ContentType :mediaBody.type,
          ContentDisposition:'inline' // to display the file in browser tab instead of downloading
        }
      };

      AWS.config.region = envConfig.envConfig.cognito.Region;

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: envConfig.envConfig.cognito.IdentityPoolId,
        Logins: {
            ['cognito-idp.us-east-1.amazonaws.com/' + envConfig.envConfig.cognito.UserPoolId]:localStorage.getItem('token')
        }
      });
      let s3ManagedUpload2 = new AWS.S3.ManagedUpload(s3Obj)
        .on('httpUploadProgress', (data) => {
            
            let loadedTotal = 0;
            loaded[fileName] = data.loaded;
            for (var j in loaded) {
                loadedTotal += loaded[j];
            }

            let progress = Math.round((loadedTotal / sizeTotal) * 100);
            setFileUploadProgressState && setFileUploadProgressState(progress);
        })
        .promise();

      s3ManagedUpload2
        .then(
          (result: { Location: string; }) => {
            setFileUploadStatusState && setFileUploadStatusState(fileName,FILE_UPLOAD_STATUS.IS_UPLOADED);
            res({
              path:result.Location,
              fileName:fileName,
              extension:fileName.split('.')[fileName.split('.').length-1]
            });
            return;
          },
          (err: any) => {
            setFileUploadStatusState && setFileUploadStatusState(fileName,FILE_UPLOAD_STATUS.IS_ERROR);
            setFileUploadProgressState && setFileUploadProgressState(0);
            res({
              path:null,
              fileName:fileName,
              extension:fileName.split('.')[fileName.split('.').length-1]
            });
            return;
          }
        )
        .catch((err:any) => {
          setFileUploadStatusState && setFileUploadStatusState(fileName,FILE_UPLOAD_STATUS.IS_ERROR);
          setFileUploadProgressState && setFileUploadProgressState(0);
          res({
            path:null,
            fileName:fileName,
            extension:fileName.split('.')[fileName.split('.').length-1]
          });
          return;
        });
    });
  };