
import { combineReducers } from 'redux';
import {
  RECEIVE_FAQ_LIST, RECEIVE_FAQ_POST, RECEIVE_UPDATE_INSTALLER_BY_ID,
  RECEIVE_COMPANY_DETAIL, RECEIVE_COMPANY_DETAIL_SET, RECEIVE_INVITE_EMPLOYEES,RECEIVE_UPDATE_COMPANY_DETAIL
} from "../../actions/profile";

const faqListReducer = (state = [], { type, data }) => {
  switch (type) {
    case RECEIVE_FAQ_LIST:
      return [...state, data];
    default:
      return state;
  }
};

const faqPostReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_FAQ_POST:
      return data;
    default:
      return state;
  }
};

const companyDetailReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_COMPANY_DETAIL:
      return data;
    default:
      return state;
  }
};

const setCompanyDetailReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_COMPANY_DETAIL_SET:
      return data;
    default:
      return state;
  }
};
const updateCompanyDetailReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_UPDATE_COMPANY_DETAIL:
      return data;
    default:
      return state;
  }
};

const inviteEmployeesReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_INVITE_EMPLOYEES:
      return data;
    default:
      return state;
  }
};

const updateInstallerByIdReducer = (state = [], { type, data }) => {
  switch (type) {
    case RECEIVE_UPDATE_INSTALLER_BY_ID:
      return [...state, data];
    default:
      return state;
  }
};

export const reducer = combineReducers({
  faqList: faqListReducer,
  faqPost: faqPostReducer,
  companyDetail: companyDetailReducer,
  setCompanyDetail: setCompanyDetailReducer,
  updateCompanyDetail : updateCompanyDetailReducer,
  inviteEmployees: inviteEmployeesReducer,
  updateInstallerById: updateInstallerByIdReducer
});
