// import npm packages
import { call, put } from "redux-saga/effects";

// import local files
import { getHomeownersList } from "../../screens/homeowner/api/solar";
import handleError from "../../shared/errorHandler";
import { setLoader } from "../../screens/Common/Loader/action";
import { receiveHomeownersList } from "../../screens/homeowner/actions/solar";

function* getHomeownersListSaga(action) {
	try {
		const data = yield call(getHomeownersList, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveHomeownersList(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

export const homeownerSolar = {
	getHomeownersListSaga
};