let myDynamicManifest = {
  "short_name": "Bodhi App",
  "name": "Your partner on the solar journey",
  "icons": [
    {
      "src": "favicon.ico",
      "sizes": "64x64 32x32 24x24 16x16",
      "type": "image/x-icon"
    },
    {
      "src": "bothi-mobile-logo.png",
      "type": "image/png",
      "sizes": "180x180"
    }
  ],
  "start_url": ".",
  "display": "standalone",
  "theme_color": "#000000",
  "background_color": "#ffffff"
}

export function addHomeScreenIcon(src,companyName) {
  if (src) {
    myDynamicManifest.icons[1].src = src;
    myDynamicManifest.icons[0].src = src;
    myDynamicManifest.short_name = `${companyName} App`;
  } else
    return
  const stringManifest = JSON.stringify(myDynamicManifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
}
