import React from 'react';

import { ReactComponent as ChevronDown } from '../../../../../Assets/Images/chevronDown.svg';
import { ReactComponent as ChevronUP } from '../../../../../Assets/Images/chevronUP.svg';

import { PostShareDetails } from '../../../api/promiseApi';
import { currencyFormater } from '../../../../../shared/currencyFormater';
import * as config from '../../../../../shared/config';
import { openNotificationWithIcon } from '../../../../../shared/notification';
import { MessageAttachmentType } from '../../../../../constants';

export function handleSubmitgeneric(
  formValues: Record<string, any>,
  cloudspongeContacts: any[] = [],
  props: Record<string, any>
) {
  const { loggedInUser, systemGlance, homeownersList } = props;
  const homeownerId = loggedInUser && loggedInUser?.solarUnit?.homeownerId;
  const companyId = loggedInUser && loggedInUser?.homeownerData?.companyId;
  const installerId = loggedInUser && loggedInUser?.homeownerData?.installerId;
  let referralContact = [];

  if (cloudspongeContacts.length === 0) {
    const { email, name, phone } = formValues;
    if (!name && !email && !phone) {
      openNotificationWithIcon(
        'error',
        'Name and email or phone are needed to share the sun',
        'Please try again'
      );
      return false;
    } else if (!email && !phone) {
      openNotificationWithIcon(
        'error',
        'Email or phone is needed to share the sun',
        'Please try again'
      );
      return false;
    } else if (!formValues.name) {
      openNotificationWithIcon('error', 'Name is needed to share the sun', 'Please try again');
      return false;
    }

    referralContact.push({
      name,
      email,
      phone: phone && phone.replace(/[^0-9]/g, '')
    });
  } else {
    cloudspongeContacts.forEach((item) => {
      referralContact.push({
        name: item.first_name,
        email: item.email[0].address
      });
    });
  }

  const companyPhone = loggedInUser && loggedInUser?.homeownerData?.companyPhone;
  const isLead = false;
  const projectedSaving = systemGlance && systemGlance?.solarGlance?.saving;
  const homeownerFirstname = loggedInUser && loggedInUser?.homeownerData?.firstName;
  const solarCompany = loggedInUser && loggedInUser?.homeownerData?.companyName;
  const solarFamiliesCount = homeownersList && (homeownersList.homesByzip || 0);
  const powered =
    systemGlance &&
    systemGlance?.solarGlance?.production &&
    systemGlance?.solarGlance?.consumption &&
    currencyFormater(
      (systemGlance.solarGlance.production / systemGlance.solarGlance.consumption) * 100
    );

  let markers = '';
  let mapUrl = '';

  homeownersList &&
    homeownersList?.homesByAdd?.length !== 0 &&
    homeownersList.homesByAdd.forEach((element: Record<string, any>, index: number) => {
      if (element.lat && element.lng && element.nearaddress && index < 90) {
        markers +=
          `&markers=icon:${config.S3_URL}/public/location.png%7Clabel:S%7C` +
          Number(element.lat) +
          ',' +
          Number(element.lng);
      }
    });

  if (loggedInUser) {
    const { homeownerData } = loggedInUser;
    const { lat, lng, address } = homeownerData || {};
    mapUrl =
      lat &&
      lng &&
      address &&
      `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=15&scale=1&size=600x300&maptype=roadmap&markers=icon:${config.S3_URL}/public/current-location.png%7Clabel:S%7C${lat},${lng}${markers}&key=AIzaSyCB6-bafvWwJRIi48ycKHKbWLfiJbhPt4w&format=png&visual_refresh=true`;
  }

  return {
    homeownerId,
    companyId,
    installerId,
    referralContact,
    isLead,
    projectedSaving,
    homeownerFirstname,
    solarCompany,
    solarFamiliesCount,
    powered,
    mapUrl,
    companyPhone
  };
}

export const configureShare = async (
  method: number,
  loggedInUser: Record<string, any>,
  shareName: string
) => {
  return (await PostShareDetails({
    homeownerId: loggedInUser?.solarUnit?.homeownerId,
    companyId: loggedInUser?.homeownerData?.companyId,
    method,
    share: shareName
  })) as any; // TODO: align axios Promise with whoever wrote the share module's type
};

export const launchShareWindow = (
  url: string,
  method: number,
  loggedInUser: Record<string, any>,
  shareName: string
) => {
  configureShare(method, loggedInUser, shareName).catch((e: Error) => {
    console.error('error', e.toString());
  });
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

/**
 * @todo: move this to the `shared/utils` file, where it best fits
 *
 * @param num
 * @param slug
 * @param refId
 * @returns {String}
 */
export const makeShareUrl = (num: number, slug: string, refId: string) => {
  return `${window.location.origin}/${slug}/referral/${refId}/referral/${num}`;
};

export const collapse = () => {
  return (
    <>
      <span className="text info premiumSecondaryText">Collapse</span>
      <ChevronUP />
    </>
  );
};

export const expand = () => {
  return (
    <>
      <span className="text info premiumSecondaryText">More info</span>
      <ChevronDown />
    </>
  );
};

/**
 * The following function is used to extract the information in
 * inbox API attachments. Currently used in Campaigns, HomeownerMessage
 * and Documents.
 */

interface ExtractedValues {
  image: string[];
  button: string | null;
  buttonLabel: string | null;
  video: string | null;
  pdfFiles: string[]
};

export const extractAttachmentInfo = (attachments: any) => {
  const extractedValues: ExtractedValues = {
    image: [],
    button: null,
    buttonLabel: null,
    pdfFiles:[],
    video: null
  };

  attachments.forEach((attachment: any) => {
    if (attachment.type === MessageAttachmentType.IMAGE) {
      extractedValues.image.push(attachment.url);
    } else if (attachment.type === MessageAttachmentType.BUTTON) {
      extractedValues.button = attachment.url;
      extractedValues.buttonLabel = attachment.label;
    } else if(attachment.type === MessageAttachmentType.PDF){
      extractedValues.pdfFiles.push(attachment.url);
    } else {
      extractedValues.video = attachment.url;
    }
  });

  return extractedValues;
};

export const imageIsOpen = (imageArray: string[]) => {
  if (imageArray.length > 0) {
    return true;
  }
  return false;
}