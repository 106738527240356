import { TInboxMessage } from '../../screens/Installer/container/inbox/types';
import { TInboxState, TMessageQueueRecord } from '../types';

/**
 * IMPURE FUNCTION
 *
 * 1. Accepts array of message models received from the API after an update
 * 2. Finds matching data in state for:
 *      a. queues
 *      b. threads (messages array)
 *      c. messages
 * 3. Directly mutates that state following rules of React Redux Toolkit which leverages immer within its reducers
 *
 * Sample PUT response for reference:
    {
        "id":"840544cc-447b-49d8-8e92-e6869cc49752",
        "messageIds":[
            "1399391d-f399-48f4-a735-7a71ce51e24c",
            "02e7907c-94f1-43f9-86bc-af415e588f40",
            "4780ccbd-e82b-4e2d-9d87-7c3ca6f02a87",
            "488243d0-383d-490f-8b61-c02942a47f17",
            "2a4067ad-1078-4d5a-9636-339dcee5814d",
            "78e35e05-629c-483d-8827-47a2af169e86",
            "e63a37c3-30f8-4df6-b5b3-66472cae99e1",
            "9a764fcd-238c-45f7-a9df-959ad909f217"
        ]
    }
 *
 */
const dangerousImpureInboxMutator = (updatedModels: TInboxMessage[], state: TInboxState) => {
  // update queues, popping what matches
  updatedModels.forEach((updatedModel: TInboxMessage) => {
    const modelMsgId = updatedModel.id;

    state.queues.inbox.messages.viewed.forEach((viewed: TMessageQueueRecord, index: number) => {
      if (viewed.messageIds.includes(modelMsgId)) {
        state.queues.inbox.messages.viewed[index].messageIds.splice(
          viewed.messageIds.indexOf(modelMsgId),
          1
        );
      }
      if (viewed.messageIds.length === 0) {
        // kill entire viewed queue when emptied
        state.queues.inbox.messages.viewed.splice(index, 1);
      }
    });
  });

  // update thread's messages array with new models if they exist and where we find a match
  state.threadsData?.threads?.length &&
    state.threadsData.threads.forEach((thread: Record<string, any>, threadIndex: number) => {
      const threadId = thread.id;

      updatedModels.forEach((updatedModel: TInboxMessage) => {
        const msgThreadId = updatedModel.threadId;
        const msgId = updatedModel.id;
        if (msgThreadId === threadId) {
          thread.messages.forEach((message: TInboxMessage, msgIndex: number) => {
            const threadMsgId = message.id;
            if (threadMsgId === msgId) {
              state.threadsData.threads[threadIndex].messages[msgIndex] = updatedModel;
            }
          });
        }
      });
    });

  // update messages with new models where we find a match
  state.messageData.messages.forEach((message: TInboxMessage, msgIndex: number) => {
    const msgsId = message.id;
    const msgsThreadId = message.threadId;

    updatedModels.forEach((updatedModel: TInboxMessage) => {
      const msgId = updatedModel.id;
      const msgThreadId = updatedModel.threadId;
      // case: the updateModel message's id matches the current message's id
      if (msgId === msgsId && msgThreadId === msgsThreadId) {
        state.messageData.messages[msgIndex] = updatedModel;
      }
    });
  });
};

export { dangerousImpureInboxMutator };
