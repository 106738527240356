import styled from 'styled-components';
import { globalStyles } from '../../constants';

// TODO - make it more flexible to accept the user pref style attributes
export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.greyBorder};
  border-radius: ${(props) => props.theme.borderRadius.standard};
  padding: 20px;
  margin: 20px 15px;
  background-color: ${(props) => props.theme.colors.labelborder};

  @media only screen and (min-width: ${globalStyles.breakpoints.md.up}px) {
    margin: 20px 0;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

export const HeadlineText = styled.p`
  font-size: ${(props) => props.theme.fonts.sizes.font18};
  font-weight: bold;
  letter-spacing: 0.23px;
  padding-left: 16px;
  margin: 0;
`;

export const StyledCardText = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.font14};
  letter-spacing: 0.25px;
  line-height: 20px;
  padding: 5px 0;
`;

export const StyledFooter = styled(StyledHeader)``;
