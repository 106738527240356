// import npm packages
import { combineReducers } from "redux";

// import local files
import { RECEIVE_REFERRAL_DETAILS,RECEIVE_REFERRAL_NEIGHBOUR ,RECEIVE_POST_REFERRAL_DETAILS ,RECEIVE_POST_ADDRESS_DETAILS,RECEIVE_POST_ADDRESS_FORM_DATA} from "../../types/referralDetails/referralDetails";

const homeownerReferrals = (state = {}, { type, data }) => {
    switch (type) {
        case RECEIVE_REFERRAL_DETAILS:
            return data;
        default:
            return state;
    }
};

const homeownerReferralNeighbour = (state = {}, { type, data }) => {
    switch (type) {
        case RECEIVE_REFERRAL_NEIGHBOUR:
            return data;
        default:
            return state;
    }
};

const postHomeownerReferrals = (state = {}, { type, data }) => {
    switch (type) {
        case RECEIVE_POST_REFERRAL_DETAILS:
            return data;
        default:
            return state;
    }
};

const postHomeownerAddress = (state = {}, { type, data }) => {
    switch (type) {
        case RECEIVE_POST_ADDRESS_DETAILS:
            return data;
        default:
            return state;
    }
};

const postHomeownerAddressForm = (state = {}, { type, data }) => {
    switch (type) {
        case RECEIVE_POST_ADDRESS_FORM_DATA:
            return data;
        default:
            return state;
    }
};
export const reducer = combineReducers({
    homeownerReferrals,
    postHomeownerReferrals,
    postHomeownerAddress,
    homeownerReferralNeighbour,
    postHomeownerAddressForm
});