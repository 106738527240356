import React from 'react';
import { routeFactory, withRouter } from '../../../../shared';
import { Button } from '../../../Common';
import { ButtonDecorator, Container, Text } from './MessageThreadEmptyState.styles';
import { CENTER_PANE_NO_MESSAGE_DISPLAY_CONTENT } from './constants';
import { IRouter } from '../../../../types';

interface IMessageThreadEmptyState {
  router: IRouter;
}

const MessageThreadEmptyState = (props: IMessageThreadEmptyState) => {
  const { router } = props;
  const {
    params: { slug, companyId }
  } = router;
  const toUrl = routeFactory(slug, companyId,  (window as any).$productSuite === 1 ? 'litenewinstall' : 'addhomeowner');

  return (
    <Container>
      <Text>{CENTER_PANE_NO_MESSAGE_DISPLAY_CONTENT}</Text>
      <Button
        decorator={ButtonDecorator}
        allowInstallerCustom={false}
        clickHandler={() => {
          router.navigate(toUrl);
        }}>
        + Add homeowner
      </Button>
    </Container>
  );
};

export default withRouter(MessageThreadEmptyState);
