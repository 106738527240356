
import { RECEIVE_SET_UTILITY_DATA_BY_ID, RECEIVE_SET_UTILITY_RATE_PLAN_DATA_BY_ID, RECEIVE_GET_UTILITY_RATE_PLAN_DATA_BY_ID, RECEIVE_GET_UTILITY_RATE_PLAN_DATA, RECEIVE_SET_MODULE_BRAND, RECEIVE_GET_HARDWARE_DATA, RECEIVE_SET_INVERTER_BRAND, RECEIVE_DELETE_MODULE_BRAND, RECEIVE_DELETE_INVERTER_BRAND, RECEIVE_DELETE_RATE_PLAN, RECEIVE_SET_ELECTRIC_UTILITY, RECEIVE_DELETE_ELECTRIC_UTILITY} from "../../Actions/setting";
import { combineReducers } from 'redux';

const getHardwareDataReducer =  (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_GET_HARDWARE_DATA:
      return data;
    default:
      return state;
  }
};



const getUtilityAndRatePlanReducer =  (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_GET_UTILITY_RATE_PLAN_DATA:
      return data;
    default:
      return state;
  }
};

const getUtilityAndRatePlanByIdReducer =  (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_GET_UTILITY_RATE_PLAN_DATA_BY_ID:
      return data;
    default:
      return state;
  }
};

const setUtilityAndRatePlanByIdReducer =  (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_SET_UTILITY_RATE_PLAN_DATA_BY_ID:
      return data;
    default:
      return state;
  }
};

const setUtilityByIdReducer =  (state = [], { type, data }) => {
  switch (type) {
    case RECEIVE_SET_UTILITY_DATA_BY_ID:
      return [...state, data];
    default:
      return state;
  }
};


const setInveterReducer =  (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_SET_INVERTER_BRAND:
      return data;
    default:
      return state;
  }
};

const setModuleReducer =  (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_SET_MODULE_BRAND:
      return data;
    default:
      return state;
  }
};

const deleteModuleReducer =  (state = [], { type, data }) => {
  switch (type) {
    case RECEIVE_DELETE_MODULE_BRAND:
      return  [...state, data];
    default:
      return state;
  }
};

const deleteInverterReducer =  (state = [], { type, data }) => {
  switch (type) {
    case RECEIVE_DELETE_INVERTER_BRAND:
      return [...state, data];
    default:
      return state;
  }
};

const deleteRatePlanReducer =  (state = [], { type, data }) => {
  switch (type) {
    case RECEIVE_DELETE_RATE_PLAN:
      return [...state, data];
    default:
      return state;
  }
};

const setElectricUtilityReducer =  (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_SET_ELECTRIC_UTILITY:
      return data;
    default:
      return state;
  }
};


const deleteElectricUtilityReducer =  (state = [], { type, data }) => {
  switch (type) {
    case RECEIVE_DELETE_ELECTRIC_UTILITY:
      return data;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  getHardwareData: getHardwareDataReducer,
  setInveter : setInveterReducer,
  setModule : setModuleReducer,
  getUtilityAndRatePlan : getUtilityAndRatePlanReducer,
  getUtilityAndRatePlanById : getUtilityAndRatePlanByIdReducer,
  setUtilityAndRatePlanById : setUtilityAndRatePlanByIdReducer,
  deleteModule : deleteModuleReducer,
  deleteInverter : deleteInverterReducer,
  setUtilityById : setUtilityByIdReducer,
  deleteRatePlan : deleteRatePlanReducer,
  setElectricUtility: setElectricUtilityReducer,
  deleteElectricUtility: deleteElectricUtilityReducer,
})