import React from 'react';
import styled from 'styled-components';

import { IBodhiButton } from './types';

const DefaultDecorator = styled.button`
  padding: 10px;
  font-size: ${(props) => props.theme.fonts.sizes.font14};
  width: 150px;
  height: 40px;
  border-radius: ${(props) => props.theme.borderRadius.standard} !important;
  border: 1px solid ${(props) => props.theme.colors.greyMed};
  background-color: ${(props) => props.theme.colors.yellow};
  color: ${(props) => props.theme.colors.white};

  &:hover {
    opacity: 0.8;
  }
`;

/**
 * The atomic button component for the app
 *
 * Draft of requirements which are WIP and ongoing:
 *
 * Note: Does not leverage `antd` as we don't need it
 *
 * 1. Works for all cases, e.g. submit forms, onClick for routing
 * 2. Supports accessibility/aria concerns
 * 3. Accepts decorator for styling
 * 4. Supports (through this or delegate) allowing Installer style customization
 *
 * @param {Object} props<IBodhiButton>
 * @returns React.FC
 */
export const Button: React.FC<IBodhiButton> = (props) => {
  const {
    type,
    className,
    children,
    disabled,
    clickHandler,
    decorator,
    allowInstallerCustom = true
  } = props;

  const Decorator = decorator || DefaultDecorator;
  const disable = disabled === undefined ? false : disabled;

  let classes: string[] = [];
  if (allowInstallerCustom) {
    classes.push('premiumButtonText', 'premiumButtonFill');
  }
  if (className && className.length) {
    classes = classes.concat(className.split(' '));
  }

  return (
    <Decorator
      className={classes.join(' ')}
      type={type || 'button'}
      disabled={disable}
      onClick={clickHandler}>
      {children}
    </Decorator>
  );
};

export default Button;
