import React, { Component } from 'react';

import { Layout, Menu, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { installerSettingKey } from '../../../../constants/routes';
import { requestCompanyDetail } from '../../actions/profile';
import { setMetricsType } from '../../actions/dashboard';
import withRouter from '../../../../shared/withRouter';
import { jwtDecode, getToken, routeFactory, resetInstallerClasses } from '../../../../shared';
import { StyledSiderMenuSub } from '.';
import JourneyPhaseMenu from '../../container/companySettings/JourneyPhase';
import TemplatePhaseMenu from '../../container/companySettings/TemplatePhase';

import { ReactComponent as MessageTemplate_Icon } from '../../../../Assets/Images/messageTemplate_icon.svg';
import { ReactComponent as Fleet_Icon } from '../../../../Assets/Images/fleetAlerts_icon.svg';
import { ReactComponent as CompInfo_Icon } from '../../../../Assets/Images/compInfo_Icon.svg';
import { ReactComponent as Content_Icon } from '../../../../Assets/Images/content_icon.svg';
import { ReactComponent as EmployeeAcct_Icon } from '../../../../Assets/Images/employeeAccount_icon.svg';
import { ReactComponent as Hardware_Icon } from '../../../../Assets/Images/hardware_icon.svg';
import { ReactComponent as Integration_Icon } from '../../../../Assets/Images/integration_icon.svg';
import { ReactComponent as PlanDetail_Icon } from '../../../../Assets/Images/planDetail_icon.svg';
import { ReactComponent as Utility_Icon } from '../../../../Assets/Images/utility_icon.svg';
import { ReactComponent as Journey_Icon } from '../../../../Assets/Images/bodhijourney_icon.svg';

const { Sider } = Layout;
const { SubMenu } = Menu;

const subMenu1 = [
  {
    display: 'Educational resources',
    key: 'isEdResource'
  },
  {
    display: 'Referrals',
    key: 'isReferralMessage'
  },
  {
    display: 'FAQs',
    key: 'isFAQ'
  },
  {
    display: 'More info',
    key: 'isPhaseInfo'
  }
];

const liteSubMenuOptions = [
  {
    display: 'Company information',
    key: 'isCompany'
  },
  {
    display: 'Features & branding',
    key: 'isPlan'
  }
];

const fleetSubMenuOptions = [
  {
    display: 'Company information',
    key: 'isCompany'
  },
  {
    display: 'Integrations',
    key: 'isIntegrations'
  },
  {
    display: 'Fleet alerts',
    key: 'isSystemNotification'
  }
]

const coreSubMenuOptions = [
  ...liteSubMenuOptions,
  {
    display: 'Integrations',
    key: 'isIntegrations'
  },
  {
    display: 'Hardware',
    key: 'isHardware'
  },
  {
    display: 'Utilities',
    key: 'isUtilities'
  },
  {
    display: 'Fleet alerts',
    key: 'isSystemNotification'
  }
];

class SiderMenuForm extends Component {
  constructor(props) {
    super(props);

    const [searchParams] = this.props.router.searchParams;
    const view = searchParams.get('view');

    this.state = {
      siderActive: view || 'isCompany',
      siderOptionActive: view || 'isCompany'
    };
    this.slug = this.props.router.params.slug;
  }

  componentWillMount() {
    const token = getToken();
    this.installerData = jwtDecode(token);
    this.admin = !this.installerData.role;
    this.companyId = this.props.router.params.companyId || this.installerData.companyId;
    this.props.requestCompanyDetail({ companyId: this.companyId, admin: this.admin, slug:this.slug });
  }

  componentWillReceiveProps(nextProps) {
    /**
     * @todo: verify this change: `.match.path` to `.router.location.pathname`
     */
    if (!nextProps.router.location.pathname.includes('companysetting')) {
      this.setState({ siderActive: '', siderOptionActive: '' });
    }
  }

  componentDidMount() {
    if (this.installerData && this.installerData.role === 'installer') {
      resetInstallerClasses();
    }
  }

  redirect = (data) => {
    window.scroll({ top: 0 });
    this.props.router.navigate(
      routeFactory(
        this.slug,
        this.companyId,
        `companysetting?${installerSettingKey}=${data.companySettingState}`
      ),
      { state: data }
    );
  };

  renderResourceImg(item) {
    switch (item) {
      case 'isCompany':
        return <CompInfo_Icon className="sider-icon" />;
      case 'isPlan':
        return <PlanDetail_Icon className="sider-icon" />;
      case 'isIntegrations':
        return <Integration_Icon className="sider-icon" />;
      case 'isHardware':
        return <Hardware_Icon className="sider-icon" />;
      case 'isUtilities':
        return <Utility_Icon className="sider-icon utilityIcon" />;
      case 'content':
        return <Content_Icon className="sider-icon" />;
      case 'isJourney':
        return <Journey_Icon className="sider-icon" />;

      case 'isTemplate':
        return <MessageTemplate_Icon className="sider-icon" />;
      case 'isSystemNotification':
        return <Fleet_Icon className="sider-icon" />;
    }
  }

  renderSubMenu(){
    switch(window.$productSuite){
      case 1:
        return liteSubMenuOptions;
      case 2:
        return fleetSubMenuOptions;
      default:
        return coreSubMenuOptions;
    }
  }

  render() {
    const { installerData } = this.props;
    const settingMenuOption = this.renderSubMenu();
    if (this.installerData.role && !this.installerData.role.includes('admin')) {
      return <></>;
    }

    return (
      <StyledSiderMenuSub>
        <Sider trigger={null} className="bodhi-sider" width="251">
          <Menu
            theme="light"
            className="left-menu"
            mode="inline"
            inlineIndent="28"
            collapsed="true"
            selectedKeys={[this.state.siderOptionActive]}>
            {settingMenuOption.map((ele, index) => {
              if (
                ele.key === 'isSystemNotification' &&
                (!installerData.data || !installerData.data.isFleet)
              )
                return null;
              return (
                <Menu.Item
                  className={this.state.siderOptionActive === ele.key ? 'siderActive' : ''}
                  key={ele.key}
                  onClick={() => {
                    this.setState({ siderActive: ele.key, siderOptionActive: ele.key });
                    this.redirect({ companySettingState: ele.key });
                  }}>
                  {this.renderResourceImg(ele.key)}
                  <div>{ele.display}</div>
                </Menu.Item>
              );
            })}

            {window.$productSuite !== 2 && <SubMenu
              className="content-menu"
              key="sub1"
              title={
                <>
                  {this.renderResourceImg('content')}
                  <div>Content</div>
                </>
              }>
              {subMenu1.map((ele, i) => {
                if(window.$productSuite !== 3 && ele.key=== 'isPhaseInfo'){
                  return null;
                }
                return (
                  <Menu.Item
                    key={ele.key}
                    className={`shiftRight ${
                      this.state.siderOptionActive === ele.key ? 'siderActive' : ''
                    }`}
                    onMouseOut={() => this.setState({ hoverSider: '' })}
                    onMouseOver={() => this.setState({ hoverSider: 'content' })}
                    onClick={() => {
                      this.setState({ siderActive: 'content', siderOptionActive: ele.key });
                      this.redirect({ companySettingState: ele.key });
                    }}>
                    {ele.display}
                  </Menu.Item>
                );
              })}
            </SubMenu>}

            {window.$productSuite === 3 && this.renderCompanyJourneyMenu()}
            {window.$productSuite === 1 && this.renderCompanyTemplateMenu()}
          </Menu>
        </Sider>
      </StyledSiderMenuSub>
    );
  }

  renderCompanyTemplateMenu() {
    return (
      <SubMenu
        className="content-menu journeys-menu-parent"
        key="sub2"
        title={
          <>
            {this.renderResourceImg('isTemplate')}
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ siderActive: '' });
                this.redirect({ companySettingState: 'templateInformation' });
              }}>
              Message templates
            </div>
          </>
        }
        onMouseOut={() => this.setState({ hoverSider: '' })}
        onMouseOver={() => this.setState({ hoverSider: '' })}
        onTitleClick={() => this.setState({ siderActive: 'isTemplate' })}>
        <TemplatePhaseMenu
          {...this.props}
          siderOptionActive={this.state.siderOptionActive}
          updateStateFromProps={this.updateStateFromProps}
          redirect={this.redirect}
        />
      </SubMenu>
    );
  }

  renderCompanyJourneyMenu() {
    return (
      <SubMenu
        className="content-menu journeys-menu-parent"
        key="sub2"
        title={this.addJourney()}
        onMouseOut={() => this.setState({ hoverSider: '' })}
        onMouseOver={() => this.setState({ hoverSider: '' })}
        onTitleClick={() => this.setState({ siderActive: 'isJourney' })}>
        <JourneyPhaseMenu
          {...this.props}
          siderOptionActive={this.state.siderOptionActive}
          updateStateFromProps={this.updateStateFromProps}
          redirect={this.redirect}
        />
      </SubMenu>
    );
  }

  updateStateFromProps = (data) => {
    this.setState(data);
  };

  addJourney = () => {
    return (
      <>
        {this.renderResourceImg('isJourney')}
        <>
          <div>Bodhi journeys</div>
          <Tooltip
            overlayClassName="black-tooltip"
            onMouseOut={() => this.setState({ hoverSider: '' })}
            onMouseOver={() => this.setState({ hoverSider: '' })}
            title="Create new Bodhi journey">
            <span
              className="add-icon"
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ siderActive: '' });
                this.redirect({ companySettingState: 'addJourney', editJourneyData: 'empty' });
              }}>
              +
            </span>
          </Tooltip>
        </>
      </>
    );
  };
}

const mapStatetoProps = (state) => {
  return {
    installerData: state.header.getInstaller,
    companyData: state.setting.companyDetail.data || {},
    updateJourney:
      state.companyBodhiJourney &&
      state.companyBodhiJourney.updateBodhiJourneyReducer &&
      state.companyBodhiJourney.updateBodhiJourneyReducer,
    companyJourney:
      (state.companyBodhiJourney &&
        state.companyBodhiJourney.bodhiJourneyReducer &&
        state.companyBodhiJourney.bodhiJourneyReducer.data) ||
      {}
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestCompanyDetail,
      setMetricsType
    },
    dispatch
  );

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(SiderMenuForm));
