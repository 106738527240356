// import npm packages
import React, { Component } from 'react';
import * as moment from 'moment';
import { reducer } from 'aws-cognito-redux-saga';
import UserAvatar from 'react-user-avatar';

import { Input, Form, Select, Tooltip, Modal, Switch } from 'antd';


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withRouter from '../../../../shared/withRouter';
import GoogleDriveSync from '../../../../shared/GoogleDriveSync';
import SharePointSync from '../../../../shared/SharePointSync';
import DropBoxSync from '../../../../shared/DropBoxSync';
import { getToken } from '../../../../shared/util';
import { jwtDecode } from '../../../../shared/jwt';
import { documentFilter } from '../../../../shared/DocumentFilter';
import { setLoader } from '../../../Common/Loader/action';
import UploadDocument from '../../../Common/UploadDocument';
import { HomeowerAreaChart } from '../../../Common/DonutChart/AreaChart';
import { AddHardware } from '../companySetting/AddCompanyHardware';
import {
  requestEgaugePowerMeter,
  receiveHuaweiId,
  requestHuaweiId
} from './../../actions/testMeterConnection';
import {
  requestHomeOwner,
  requestUpdateHomeOwner,
  receiveUpdateHomeOwner
} from './../../actions/homeOwner';
import { requestEditUpdateAccount } from '../../../homeowner/actions/settings';
import { openNotificationWithIcon } from '../../../../shared/notification';
import { monitoringSystemUrl } from '../../../../shared/fleet';
import { ReactComponent as CaretIcon } from '../../../../Assets/Images/path-2.svg';
import { filterHomeownerTouches, activityLogFilter } from '../../../Common/FilterTouchHistory';
import {
  signatureIconSmSvg,
  addIconSvg
} from '../../../../constants/icons';
import {EversignIntegration} from "../../eversign/EversignIntegration"
import {EversignPrepareDocument} from "../../eversign/PrepareDocument"

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;



class MonitoringScreenPage extends Component {
  constructor(props) {
    super(props);
    this.slug = '';
    this.GoogleDriveSync = new GoogleDriveSync();
    this.SharePointSync = new SharePointSync();
    this.DropBoxSync = new DropBoxSync();
    this.id = null;
    this.state = {
      powerMeter: [],
      homeownerData: null,
      eversignRequestPopUp: false,
      isTestMeterClicked: false,
      isMonitoringEdit: true,
      isforgotPassword: false,
      addPopUp: false,
      isCloudImages: false
    };
    this.meterUrl = {};
  }

  componentWillMount() {
    let token = getToken();
    this.decodedToken = jwtDecode(token);
    this.props.requestEgaugePowerMeter({});
    this.companyId = this.props.router.params.companyId;
    this.slug = localStorage.getItem('slug');
  }

  componentDidUpdate(prevProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      let data = {
        companyId: this.companyId,
        homeOwnerId: this.props.homeOwnerId
      };
      this.props.receiveUpdateHomeOwner({});
      this.props.requestEgaugePowerMeter({});
      this.props.requestHomeOwner(data);
      this.props.setLoader({ loader: false });
    }
  }

  componentDidMount() {
    this.props.setLoader({ loader: false });
  }

  componentWillReceiveProps(nextProps) {
    const { loggedInUser, companyId, homeOwnerFileList } = nextProps;
    let powerMeter = [];
    if (this.state.isforgotPassword) {
      this.setState({ isforgotPassword: false });
      openNotificationWithIcon('success', 'The new password was sent successfully.', 'Success');
    }

    if (!this.state.huaweiMeterId && nextProps.huaweiMeterId) {
      this.setState({
        huaweiMeterId: nextProps.huaweiMeterId,
        meterconId: nextProps.huaweiMeterId
      });
    }

    if (homeOwnerFileList && homeOwnerFileList.documentData && !this.state.homeOwnerFileList) {
      this.setState({ homeOwnerFileList: true }, () => {
        this.displayDriveFiles(homeOwnerFileList.documentData);
      });
    }

    this.setState({ homeownerData: nextProps.loggedInUser });
    this.meterUrl = {
      1: nextProps.loggedInUser.solarUnit && (nextProps.loggedInUser.solarUnit.meterUrl || ''),
      2: 'monitoring.solaredge.com',
      3: 'api.enphaseenergy.com',
      4: 'data.sense.com',
      5: 'apsystemsema.com',
      6: 'solarweb.com',
      7: nextProps.loggedInUser.solarUnit && (nextProps.loggedInUser.solarUnit.meterUrl || '')
    };

    if (
      nextProps.homeOwnerData.getUpdateHomeOwner &&
      nextProps.homeOwnerData.getUpdateHomeOwner.status &&
      nextProps.homeOwnerData.getUpdateHomeOwner.status === 200
    ) {
      // clear update api response from reducer
      nextProps.receiveUpdateHomeOwner({});
      // reset old data and get new data
      // nextProps.resetData()
    }

    if (
      !this.state.meterType &&
      nextProps.companyId?.data?.powerMeter?.length &&
      loggedInUser.solarUnit &&
      (loggedInUser.solarUnit.meterType || loggedInUser.solarUnit.meterId)
    ) {
      const { meterId, meterUrl, meterconId } = loggedInUser.solarUnit;
      powerMeter = nextProps.companyId.data.powerMeter.filter((item) => item.meterId === meterId);
      this.setState({ powerMeter, meterType: meterId, meterUrl, meterconId });
    }
  }

  filterTouchName(touchHistory) {
    const {
      companyJourney,
      additionalUser,
      eversignDetails,
      homeOwner: { getSurveyData }
    } = this.props;
    const arr3 = [
      ...(touchHistory || []),
      ...(eversignDetails || []),
      ...(additionalUser || []),
      ...((getSurveyData.data && getSurveyData.data.activityFeed) || [])
    ];
    let tempData = [];
    if (arr3.length !== 0) {
      tempData = arr3
        .map((data, index) => {
          if (data) {
            data = typeof data == 'string' ? JSON.parse(data) : data;
            data['index'] = index;
            return data;
          }
        })
        .filter((data) => {
          if (
            data &&
            data.createdAt &&
            (data.rating ||
              data.message ||
              data.userName ||
              data.fileName ||
              data.isActive === true ||
              data.fileMissing === true)
          ) {
            return true;
          } else {
            return false;
          }
        })
        .sort((touch1, touch2) => {
          if (Number(touch2.createdAt) > Number(touch1.createdAt)) return 1;
          else {
            if (Number(touch2.createdAt) === Number(touch1.createdAt)) {
              let lowerTouchName2 =
                (companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')] &&
                  companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')].newTouchNo) ||
                0;
              let lowerTouchName1 =
                (companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')] &&
                  companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')].newTouchNo) ||
                0;
              if (Number(touch2.phase) > Number(touch1.phase)) {
                return 1;
              } else if (
                Number(touch2.phase) === Number(touch1.phase) &&
                Number(lowerTouchName2) > Number(lowerTouchName1)
              ) {
                return 1;
              }
            }
          }
          return -1;
        });
    }
    return tempData;
  }

  testConnection = (fetchMeterId) => {
    let meterUrl = this.state.meterUrl;
    let meterId = this.state.meterID;
    let meterconId = this.state.huaweiMeterId || this.state.meterconId;
    let meterKey = this.state.meterKey;
    let identifier = this.state.identifier;

    let {
      loggedInUser: { solarUnit }
    } = this.props;

    if (!meterKey || meterKey == '')
      meterKey = this.state.powerMeter[0] && this.state.powerMeter[0]['key'];

    if (!identifier) identifier = this.state.powerMeter[0] && this.state.powerMeter[0].identifier;

    if (!meterId) meterId = solarUnit && solarUnit.meterId;

    if (!meterconId) meterconId = solarUnit && solarUnit.meterconId;

    if (!meterUrl)
      meterUrl = solarUnit && solarUnit.meterUrl ? solarUnit.meterUrl : this.meterUrl[meterId];

    if (fetchMeterId && meterId && meterUrl) {
      this.props.receiveHuaweiId({});
      this.props.setLoader({ loader: true });
      this.setState({ huaweiMeterId: null });
      this.props.requestHuaweiId({
        meterUrl: meterUrl,
        meterID: meterId,
        fetchMeterId: fetchMeterId,
        key: meterKey,
        identifier: identifier
      });
    } else if (meterconId && meterId && meterUrl) {
      this.props.setLoader({ loader: true });
      this.props.requestEgaugePowerMeter({
        meterUrl: meterUrl,
        meterID: meterId,
        meterconId: meterconId,
        key: meterKey,
        identifier: identifier
      });
    } else {
      openNotificationWithIcon(
        'error',
        'Please enter meter information to sync data',
        'Need Meter Details'
      );
    }

    if (!this.props.loader) {
      this.setState({
        isTestMeterClicked: false
      });
    }
  };

  changeState = (data) => {
    this.setState(data);
  };

  triggerForgotPassword = () => {
    let { homeownerData } = this.state;
    this.setState(
      {
        isforgotPassword: true
      },
      () => {
        this.props.forgotPassword(homeownerData.homeownerData.email);
      }
    );
  };

  updateMonitoringCredentials = () => {
    let { meterConnectionStatus, companyId} = this.props;

    if (
      meterConnectionStatus &&
      (meterConnectionStatus.consumption || meterConnectionStatus.production)
    ) {
      let { homeownerData } = this.state;

      if(window.$productSuite == 2){
        const meterId = this.state.meterID || homeownerData.solarUnit.meterId;
        const meterType = companyId?.data?.powerMeter.filter((item) => item.meterId == meterId)[0];
        var data = {
          homeOwnerId: homeownerData.homeownerData.id,
          companyId: homeownerData.homeownerData.companyId,
          saveMonitoringCredentials: true,
          bodyData :{
            monitoring_system : meterType?.name,
            monitoring_system_id : this.state.meterconId || homeownerData.solarUnit.meterconId,
            monitoring_system_url : this.state.meterUrl || homeownerData.solarUnit.meterUrl,
          }
        }
      } else {
        var data = {
          companyId: homeownerData.homeownerData.companyId,
          homeOwnerId: homeownerData.homeownerData.id,
          saveMonitoringCredentials: true,
          bodyData: {
            firstName: homeownerData.homeownerData.firstName,
            email: homeownerData.homeownerData.email,
            solarUnit: {
              update: true,
              meterUrl: this.state.meterUrl,
              meterId: this.state.meterID,
              meterconId: this.state.huaweiMeterId || this.state.meterconId
            }
          }
        };
      }
      
      this.setState({ isMonitoringEdit: true });
      this.props.requestUpdateHomeOwner(data);
    } else {
      openNotificationWithIcon(
        'error',
        'Please recheck the meter details and contact your success manager.',
        'No Meter Connection Found'
      );
    }
  };

  updateNotification = (noticationKey,notificationValue) => {
    let loggedInUser = this.state.homeownerData;
    this.props.setLoader({ loader: true });

    this.props.requestEditUpdateAccount({
      companyId: loggedInUser.homeownerData.companyId,
      homeownerId: loggedInUser.homeownerData.id,
      phone: loggedInUser.homeownerData.phone,
      [noticationKey]:notificationValue
    });
  };

  updateTouchHistory = () => {
    let { homeownerData } = this.state;
    let currentTime = new Date().getTime();
    let touchHistory = [
      {
        createdAt: currentTime,
        phase: 1,
        isActive: true,
        index: 0,
        touchName: 'Sign into Bodhi and welcome'
      }
    ];
    let data = {
      companyId: homeownerData.homeownerData.companyId,
      homeOwnerId: homeownerData.homeownerData.id,
      saveMonitoringCredentials: true,
      bodyData: {
        firstName: homeownerData.homeownerData.firstName,
        email: homeownerData.homeownerData.email,
        solarUnit: {
          touchHistory,
          meterId: null,
          dailySyncTime: null,
          update: true,
          touchName: 'Sign into Bodhi and welcome',
          phase: 1,
          updated: currentTime
        }
      }
    };
    this.props.requestUpdateHomeOwner(data);
  };

  renderMonitoringBox(loggedInUser, companyId) {

    let isPowerDataSync = loggedInUser.solarUnit && loggedInUser.solarUnit.dailySyncTime;
    const renderPowerMeter =
      companyId && companyId.data
        ? companyId.data.powerMeter.map((item) => (
            <Option value={item.meterId} key={item.meterId}>
              {item.name}
            </Option>
          ))
        : '';
    return (
      <div className="monitoring-profile-box row m-0">
        <h2 className="w-100">
          Monitoring System
          {this.state.isMonitoringEdit ? (
            <span
              onClick={() => this.changeState({ isMonitoringEdit: false })}
              className="edit-link inactive">
              Edit
            </span>
          ) : (
            <span onClick={() => this.updateMonitoringCredentials()} className="edit-link active">
              Save
            </span>
          )}
        </h2>
        <div
          className={
            'monitioring-detail-box' + (isPowerDataSync ? ' col-md-3 d-block' : ' col-md-12')
          }>
          <div className="meter-type">
            <p className="form-group caret-icon">Meter</p>
            <FormItem hasFeedback>
              <Select
                suffixIcon={<CaretIcon />}
                className="form-control"
                name="monitorType"
                id="exampleFormControlSelect1"
                disabled={this.state.isMonitoringEdit}
                value={
                  this.state.meterType ||
                  (this.state.powerMeter &&
                    this.state.powerMeter[0] &&
                    this.state.powerMeter[0].meterId)
                }
                onChange={(e) => {
                  this.setState(
                    {
                      meterType: e,
                      meterID: e,
                      meterUrl: this.meterUrl[e],
                      isTestMeterClicked: true
                    },
                    () => {
                      this.props &&
                        this.props.companyId &&
                        this.props.companyId.data &&
                        this.props.companyId.data.powerMeter.forEach((val) => {
                          if (val.meterId == this.state.meterID) {
                            this.setState({
                              meterKey: val.key,
                              identifier: val.identifier
                            });
                          }
                        });
                    }
                  );
                }}>
                {renderPowerMeter}
              </Select>
            </FormItem>
          </div>
          <div>
            <p className="form-group">
              Monitoring API URL
              {monitoringSystemUrl(
                this.state.meterType ||
                  (this.state.powerMeter &&
                    this.state.powerMeter[0] &&
                    this.state.powerMeter[0].meterId),
                {
                  meterconId: this.state.meterconId,
                  meterUrl:
                    this.state.meterUrl || this.state.meterUrl == ''
                      ? this.state.meterUrl
                      : this.meterUrl[loggedInUser.solarUnit && loggedInUser.solarUnit.meterId]
                }
              )}
            </p>
            <FormItem hasFeedback>
              {
                <Input
                  type="text"
                  className="form-control"
                  id="exampleInputPassword1"
                  value={
                    this.state.meterUrl || this.state.meterUrl == ''
                      ? this.state.meterUrl
                      : this.meterUrl[loggedInUser.solarUnit && loggedInUser.solarUnit.meterId]
                  }
                  onChange={(e) =>
                    this.setState({ meterUrl: e.target.value, isTestMeterClicked: true })
                  }
                  disabled={this.state.isMonitoringEdit}
                />
              }
            </FormItem>
          </div>
          <div className="meter-id">
            <p className="form-group">Device ID</p>
            <FormItem hasFeedback>
              <Input
                type="text"
                className="form-control"
                value={this.state.huaweiMeterId || this.state.meterconId}
                id="exampleInputPassword1"
                onChange={(e) =>
                  this.setState({
                    meterId: e.target.value.replace(/ /g, ''),
                    meterconId: e.target.value,
                    isTestMeterClicked: true
                  })
                }
                disabled={
                  this.state.isMonitoringEdit ||
                  this.state.meterType == 7 ||
                  this.state.meterType == 8
                }
              />
            </FormItem>
            {(this.state.meterType == 7 || this.state.meterType == 8) && this.state.meterUrl && (
              <a
                style={{ color: '#ff6f47' }}
                className="d-block mt-2"
                onClick={(e) => {
                  e.stopPropagation();
                  this.testConnection(this.state.meterType);
                }}>
                Fetch meterId
              </a>
            )}
          </div>
          <div>
            <p className="form-group">Status</p>
            {this.props.meterConnectionStatus &&
              this.props.meterConnectionStatus.hasOwnProperty('production') &&
              (this.props.meterConnectionStatus.production ||
                this.props.meterConnectionStatus.consumption) && (
                <p style={{ color: '#14b014', marginTop: '10px' }}>Online</p>
              )}
            {this.props.meterConnectionStatus &&
              this.props.meterConnectionStatus.hasOwnProperty('production') &&
              !this.props.meterConnectionStatus.consumption &&
              !this.props.meterConnectionStatus.production && (
                <p style={{ color: '#ff6f47', marginTop: '10px' }}>Offline</p>
              )}
          </div>
          <button
            type="button"
            className="btn btn-secondary test-meter-link"
            onClick={() => this.testConnection()}>
            Check data connection
          </button>
        </div>
        {isPowerDataSync && (
          <div className=" areachart col-md-7 offset-md-2">
            <HomeowerAreaChart {...this.props} />
          </div>
        )}
      </div>
    );
  }

  groupByMonth = (activityOrder) => {
    let bymonth = {};
    activityOrder &&
      activityOrder.map((value) => {
        value = typeof value === 'string' ? JSON.parse(value) : value;
        let d = moment(parseInt(value['createdAt'])).format('MMMM YYYY');
        bymonth[d] = bymonth[d] || [];
        bymonth[d].push(value);
      });
    return bymonth;
  };

  renderDashboard = () => {
    const {
      homeOwner: { getSurveyData },
      companyId,
      cloudImages,
      systemGlance,
      loggedInUser,
      homeOwnerFileList,
      homeOwnerNotes,
      companyJourney
    } = this.props;

    let surveyActivity = (getSurveyData.data && getSurveyData.data.activityFeed) || [];

    let deviceMagicData =
      loggedInUser.homeownerData &&
      loggedInUser.homeownerData.magicFile &&
      loggedInUser.homeownerData.magicFile.map((ele) =>
        typeof ele === 'string' ? JSON.parse(ele) : ele
      );

    let activityOrder = window.$productSuite == 2 ? loggedInUser?.solarUnit?.activityLog :
      loggedInUser &&
      loggedInUser.solarUnit &&
      loggedInUser.solarUnit.touchHistory &&
      companyJourney &&
      activityLogFilter(
        loggedInUser.solarUnit.touchHistory,
        [],
        'activityFeed',
        companyJourney,
        deviceMagicData,
        loggedInUser.solarUnit.activityLog,
        surveyActivity,
        homeOwnerNotes
      );

    let groupByMonth = this.groupByMonth(activityOrder);

    let filterTouchOrder =
      loggedInUser &&
      loggedInUser.solarUnit &&
      loggedInUser.solarUnit.touchHistory &&
      companyJourney &&
      filterHomeownerTouches(
        loggedInUser.solarUnit.touchHistory,
        [],
        'notifyTime',
        companyJourney
      )[0];
    let activationDate = filterTouchOrder && parseInt(filterTouchOrder.createdAt);

    let phaseName =
      filterTouchOrder?.phaseName &&
      loggedInUser?.solarUnit?.activeJourney?.journeyPhase[
        filterTouchOrder.phaseName.replace(/ /g, '').toLowerCase()
      ] &&
      loggedInUser.solarUnit.activeJourney.journeyPhase[
        filterTouchOrder.phaseName.replace(/ /g, '').toLowerCase()
      ].name;

    return (
      <>
        {this.state.eversignRequestPopUp && this.renderSignMessage()}
        {this.state.prepareDocumentPopUp && this.renderPrepareDocument()}
        <div className="monitoring">
          <section className="profile-detail-wrapper">
            <div>
              <div className="white-box box-Shadow">
                {loggedInUser.homeownerData &&
                window.$productSuite !== 1 &&
                  !loggedInUser.homeownerData.isProjectTrack &&
                  this.renderMonitoringBox(loggedInUser, companyId)}
                <div className="notification-doc-box">
                  <div className="row m-0">
                    <div className="col-sm-7 pl-4">
                      <div className="d-flex">
                        <h2>Activity Feed</h2>    
                      </div>
                      <div className="mt-3 activity-log">
                        {groupByMonth &&
                          this.renderActivty(groupByMonth, companyJourney)}
                      </div>
                    </div>

                    {
                      window.$productSuite !== 2 &&
                      <div className="col-sm-5 installer-document-box">
                        <h2>Latest Notifications</h2>
                        <div className="notify-box seprator-bottom pb-2">
                          <div>
                            <p>Phase</p>
                            <p>
                              {filterTouchOrder &&
                                `${phaseName || filterTouchOrder.phaseName} - ${moment(
                                  filterTouchOrder.createdAt
                                ).format('MMM DD, YYYY')}`}
                            </p>
                          </div>
                          <div>
                            <p> Days in phase</p>
                            <p>{activationDate && moment().diff(moment(activationDate), 'days')}</p>
                          </div>
                          <div>
                            <p>Last notification date</p>
                            <p>
                              {activityOrder &&
                                activityOrder[0] &&
                                moment(parseInt(activityOrder[0].createdAt)).format('MMM DD, YYYY')}
                            </p>
                          </div>
                          <div>
                            <p>Notification preference </p>
                          </div>
                          <Form
                            initialValues={{
                              emailNotification: loggedInUser.homeownerData.emailNotification,
                              smsNotification: loggedInUser.homeownerData.smsNotification
                            }}>
                            <div className="form-group notification-btn homeowner margb0">
                              {
                                <FormItem name="smsNotification" valuePropName="checked">
                                  <Switch
                                    onChange={(checked) => {
                                      this.updateNotification('smsNotification', checked);
                                    }}
                                  />
                                </FormItem>
                              }
                              <label className="paddl20 text-left notification-lable-text">
                                Text
                              </label>
                            </div>
                            <div className="form-group notification-btn homeowner paddl10">
                              {
                                <FormItem name="emailNotification" valuePropName="checked">
                                  <Switch
                                    onChange={(checked) => {
                                      this.updateNotification('emailNotification', checked);
                                    }}
                                  />
                                </FormItem>
                              }
                              <label className="paddl20 text-left notification-lable-text">
                                Email
                              </label>
                            </div>
                          </Form>
                          <div>
                            <p>Trigger password change</p>
                            <button
                              className="btn btn-primary forgot-password"
                              onClick={() => {
                                this.triggerForgotPassword();
                              }}>
                              New password
                            </button>
                          </div>
                          {this.decodedToken.email.includes('17terawatts') && <div>
                            <p>Reset Bodhi Journey</p>
                            <button className="btn btn-primary forgot-password" onClick={() => {
                              this.updateTouchHistory();
                            }}>Reset</button>
                          </div>}
                        </div>

                        <div>
                          <h2 className="mt-4">Documents</h2>
                          <div className="doc-content">
                            {this.props.companyId.data &&
                              this.props.companyId.data.document &&
                              this.props.companyId.data.document[0] &&
                              this.slug != 'ivee' &&
                              (this.props.companyId.data.document[0].documentId == 1 ||
                                this.props.companyId.data.document[0].documentId == 7 || 
                                this.props.companyId.data.document[0].documentId == 15 || 
                                this.props.companyId.data.document[0].documentId == 2) && (
                                <UploadDocument
                                  isEdit={true}
                                  removeFIleState={this.state.removeFIleState}
                                  updateDocumentId={this.updateDocumentId}
                                  setDocuement={(doc) => {
                                    this.setDocuement(doc);
                                  }}
                                  checkFileMissing={(val, missingFiles) => {
                                    this.checkFileMissing(val, missingFiles);
                                  }}
                                  {...this.props}
                                />
                              )}
                            <ul>
                              {cloudImages &&
                                homeOwnerFileList &&
                                homeOwnerFileList.documentData &&
                                homeOwnerFileList.documentData.source !== 'netsuite' &&
                                this.renderFiles(this.arrangeFiles(cloudImages))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  };

  renderActivty(groupByMonth, companyJourney) {
    const sortByMonth = Object.keys(groupByMonth).sort((a, b) =>
      moment(a, 'MMMM YYYY').isBefore(moment(b, 'MMMM YYYY')) ? 1 : -1,
    );
    return sortByMonth.map((month, i) => {
      return groupByMonth[month].map((touchNameData, index) => {
        if (
          index == 0 &&
          touchNameData &&
          touchNameData.touchName &&
          touchNameData.fileMissing &&
          touchNameData.fileMissing !== 'false'
        ) {
          touchNameData.touchName = touchNameData.touchName + 'filemissing';
        }
        let touchData =
          companyJourney &&
          touchNameData &&
          touchNameData.touchName &&
          companyJourney[touchNameData.touchName.toLowerCase().replace(/ /g, '')] &&
          companyJourney[touchNameData.touchName.toLowerCase().replace(/ /g, '')];
        return this.displayTouchJourney(touchNameData, touchData, index === 0 ? month : null);
      });
    });
  }

  edResourceType(type) {
    switch (type) {
      case 'EdResource1':
        return 'Solar 101';
      case 'EdResource2':
        return 'Getting your home ready';
      case 'EdResource3':
        return 'Your solar equipment';
      case 'EdResource4':
        return 'Your monitoring system';
    }
  }

  fleetAlertType(type) {
    switch (type * 1) {
      case 1:
        return 'No data yellow';
      case 2:
        return 'No data red';
      case 3:
        return 'No production yellow';
      case 4:
        return 'No production red';
      case 5:
        return 'Low production yellow';
      case 6:
        return 'Low production red';
    }
  }

  displayTouchJourney(content, touchData, month) {
    if (!content || content.fileMissing == 'true' || content.fileMissing == true) return;
    var notificationType;
    var notificationDetail;
    var time = content.createdAt;

    if (content.fileUrl) {
      notificationType = 'Document Signed';
      notificationDetail = content.fileName;
    } else if (content.event && content.event.includes('fleetAlert')) {
      notificationType = this.fleetAlertType(content.type);
      notificationDetail = ' condition detected';
    } else if (content.event && content.event.includes('EdResource')) {
      notificationType = `Educational resource sent for `;
      notificationDetail = this.edResourceType(content.event);
    } else if (window.$productSuite === 3 && touchData && content.touchName && content.fileMissing != 'true') {
      notificationType = `Notification sent for Touch ${touchData.newTouchNo}:`;
      notificationDetail = `${touchData.display}`;
    } else if(window.$productSuite === 1 && touchData && content.touchName && content.fileMissing != 'true'){
      notificationType = `Notification sent for Template:`;
      notificationDetail = `${touchData.display}`;
    }
     else if (content.userName) {
      notificationType = 'Additional User Added:';
      notificationDetail = content.userName;
    } else if (content.rating && (content.phaseId == 10 || content.phaseId == 9)) {
      notificationType = `NPS survey response provided with score of ${content.rating} and feedback:`;
      notificationDetail = `${content.message || 'N/A'}`;
    } else if (content.rating) {
      notificationType = `Survey Submit for Phase ${content.phaseId}:`;
      notificationDetail = `Rating - ${content.rating}`;
    } else if (content.magicId) {
      notificationType = `Notification sent for Device Magic End of Day:`;
      notificationDetail = `Report Id - ${content.magicId}`;
    } else if (content.note) {
      const imageSyle = {
        backgroundImage: content.photo ? `url(` + content.photo + `)` : ''
      };
      return (
        <div>
          {month && <strong className="month">{month}</strong>}
          <div className="box-Shadow">
            {content.photo ? (
              <div className="dashboard-profile-image" style={imageSyle}></div>
            ) : (
              <UserAvatar
                className="dashboard-profile-image premiumIconBackGround"
                size="40"
                name={content.name || 'Installer'}
              />
            )}
            <div className="note-log">
              <strong className="log">{content.name}</strong>
              <strong className="log mt-0">
                {moment(parseInt(time)).format('MMM DD, YYYY h:mmA')}
              </strong>
              <p className="log mt-0">
                {' '}
                {content.note} {notificationDetail}
              </p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        {month && <strong className="month">{month}</strong>}
        <strong className="log">{moment(parseInt(time)).format('MMM DD, YYYY h:mmA')}</strong>
        <p className="log">
          {' '}
          {notificationType} {notificationDetail}
        </p>
      </div>
    );
  }

  setDocuement = (doc) => {
    if (doc && doc.key) {
      this.setState({
        removeFIleState: false,
        isSharedDrive: doc.isSharedDrive,
        files: doc.files,
        documentId: doc,
        isSynced: false
      });
    } else {
      this.setState({
        isSynced: false,
        documentId: {
          source: '',
          key: ''
        }
      });
    }
  };

  checkFileMissing = (val, missingFiles) => {
    this.setState({
      isFileMissing: val,
      missingFiles
    });
  };

  updateDocumentId = async () => {
    this.props.setLoader({ loader: true });
    let sharepointPublicFile =
      this.state.documentId &&
      this.state.documentId.source &&
      this.state.documentId.source === 'sharepoint' &&
      this.state.documentId.key &&
      this.state.documentId.files &&
      (await this.SharePointSync.makeFolderPublic(
        this.state.documentId.files,
        this.props.companyId.data.document[0].token
      ));

    if (sharepointPublicFile && sharepointPublicFile.length > 0) {
      sharepointPublicFile = {
        isSharedDrive: true,
        key: this.state.documentId.key,
        source: this.state.documentId.source,
        files: { [this.state.documentId.key]: sharepointPublicFile }
      };
    } else if (this.state.isFileMissing && this.state.missingFiles) {
      this.props.setLoader({ loader: false });
      openNotificationWithIcon(
        'error',
        `${this.state.missingFiles.join()} files is missing.Please add them.`,
        'Error'
      );
      return null;
    } else {
      sharepointPublicFile = null;
    }

    if(this.state.documentId?.files){
      this.props.setImages(this.state.documentId.files);
    }
    let body = {
      saveMonitoringCredentials: true,
      bodyData: {
        installerId: this.props.loggedInUser.homeownerData.installerId,
        document: sharepointPublicFile || this.state.documentId
      },
      homeOwnerId: this.props.loggedInUser.homeownerData.id,
      companyId: this.props.loggedInUser.homeownerData.companyId
    };
    !this.state.isSynced &&
      this.state.documentId &&
      this.state.documentId.key &&
      this.state.documentId.source === 'google' &&
      this.GoogleDriveSync.makeFolderPublic(
        this.state.documentId.key,
        this.state.isSharedDrive,
        this.state.files
      );
    this.setState({ removeFIleState: true }, () => {
      this.setState({ removeFIleState: false });
    });
    this.props.requestUpdateHomeOwner(body);
  };

  renderSignMessage = () => {
    const { docValues } = this.state;
    const { loggedInUser,installerDetails} = this.props;
    return (
      <EversignIntegration
        homeownerData={loggedInUser}
        eversignPopUp={true}
        documentName={docValues.name}
        documentURL={docValues.url}
        setLoader= {this.props.setLoader}
        homeownerId = {loggedInUser.homeownerData.id}
        slug = {this.slug}
        setEversignModel = {
          (obj)=>this.setState(obj)
        }
        setDisplayFullScreenLoaderState = {
          (value)=> this.props.setLoader({loader:value})
        }
        installerDetail= {installerDetails}
      />
    )
  }

  renderPrepareDocument=()=>{
    const { loggedInUser} = this.props;
    return (
      loggedInUser?.homeownerData && this.state.prepareDocumentPopUp &&
      <EversignPrepareDocument
        embedded_claim_url={this.state.embedded_claim_url}
        prepareDocumentVisible={this.state.prepareDocumentPopUp}
        setEversignModel={
          (obj)=>this.setState(obj)
        }
        fetchLatestInboxMessage ={()=>{
          this.props.requestGetEversignData({
            homeownerId: this.props.router.params.id,
            companyId: this.companyId
          });
        }}
      />
    )
  }

  render() {
    if (getToken()) {
      return this.renderDashboard();
    } else {
      this.props.router.navigate('/');
      window.location.reload();
      return null;
    }
  }

  initializeDropbox() {
    let {
      loggedInUser: { homeownerData },
      homeOwnerFileList: { documentData }
    } = this.props;
    if (
      homeownerData &&
      documentData.source === 'dropbox' &&
      homeownerData.dropboxToken &&
      !this.state.isSyncCalled
    ) {
      this.setState({ isSyncCalled: true }, () => {
        this.props.setLoader({ loader: true });
        this.DropBoxSync.initializeDropbox(homeownerData.dropboxToken).then(async (res) => {
          if (res && documentData && documentData.key) {
            this.props.setLoader({ loader: false });
            this.DropBoxSync.getDropBoxFilesList(documentData.key, documentData.filter)
              .then((response) => {
                if (response) {
                  this.props.setImages(response.files);
                  this.setState({ dropboxFile: response.previewUrls });
                }
              })
              .catch((error) => {});
          }
        });
      });
    }
  }

  displayDriveFiles = (documentData) => {
    if (!documentData) {
      return;
    }
    let driveData = documentData.driveData;
    let temp =
      this.props.companyId.data &&
      this.props.companyId.data.document[0] &&
      this.props.companyId.data.document[0].token;
    if (temp && documentData && documentData.key && documentData.source == 'sharepoint') {
      let obj = JSON.parse(JSON.stringify(documentData));
      this.SharePointSync.listFiles(obj.key.replace(/#/g, '/'), null, null, temp, true).then(
        (response) => {
          if (response) {
            this.props.setImages(response.fileList);
            this.setState({
              isCloudImages: true,
              files: this.arrangeFiles(response.fileList),
              documentId: {
                key: documentData.key,
                source: documentData.source
              }
            });
          }
        }
      );
    } else if (
      driveData &&
      JSON.stringify(driveData) != '{}' &&
      documentData.source != 'sharepoint'
    ) {
      this.props.setLoader({ loader: false });
      let files = [];
      if (documentData.source === 'solarNexus') {
        driveData[documentData.key] &&
          driveData[documentData.key].map((ele) => {
            files.push({
              name: ele['document']['file_name'],
              public_url: ele['document']['url']
            });
          });
      }

      if (documentData.source === 'zoho') {
        driveData[documentData.key] &&
          driveData[documentData.key].map((ele) => {
            if (ele['$link_url'])
              files.push({
                name: ele['File_Name'],
                public_url: ele['$link_url']
              });
          });
      } else if (documentData.source === 'teamwork') {
        driveData[documentData.key] &&
          driveData[documentData.key].map((ele) => {
            if (ele['previewUrl'])
              files.push({
                name: ele['name'],
                public_url: ele['previewUrl']
              });
          });
      }

      this.props.setImages(files.length > 0 ? { [documentData.key]: files } : driveData);
      this.setState({
        isCloudImages: false,
        files: this.arrangeFiles(files.length > 0 ? { [documentData.key]: files } : driveData),
        documentId: {
          key: documentData.key,
          source: documentData.source
        }
      });
    } else if (documentData.source === 'dropbox') {
      this.initializeDropbox();
    } else if (this.props.documentTab) {
      this.props.setLoader({ loader: false });
      this.setState({
        isCloudImages: false,
        files: this.arrangeFiles(this.props.cloudImages),
        documentId: {
          key: documentData.key,
          source: documentData.source
        },
        loader: false
      });
    }
  };

  handleCancel = (e) => {
    this.setState({ requestDocumentVisible: false });
  };

  renderFiles = (files) => {
    return (
      <React.Fragment>
        {files && files.nonCollapsible && this.nonCollapsible(files.nonCollapsible)}
        {files && files.collapsible && this.collapsible(files.collapsible)}
      </React.Fragment>
    );
  };

  isEverSignValid(fileName) {
    if (fileName && /pdf/i.test(fileName.toLowerCase())) return true;
  }

  // render files
  nonCollapsible(files) {
    const { previewUrls } = this.state;
    const {
      eversignDetails,
      homeOwnerFileList: { documentData }
    } = this.props;
    return Object.keys(files).map((file) => {
      let f = documentData.filter;
      return (
        files[file] &&
        files[file].map((val, index) => {
          if (documentData && documentData.source === 'serviceFusion') {
            if (f && !documentFilter(f, val.name)) return;
            if (val.doc_type === 'IMG')
              val.url =
                val.previewUrl = `https://servicefusion.s3.amazonaws.com/images/estimates/${val.file_location}`;
            else
              val.url =
                val.previewUrl = `https://servicefusion.s3.amazonaws.com/userdocs/6794/${val.file_location}`;
          } else if (
            documentData &&
            (documentData.source === 'solarNexus' ||
              documentData.source === 'teamwork' ||
              documentData.source === 'zoho' ||
              documentData.source === 'monday')
          ) {
            if (f && !documentFilter(f, val.name)) return;
            val.url = val.previewUrl = val.public_url;
          } else if (documentData && documentData.source === 'salesforce') {
            val.name = val.fileName;
            if (f && !documentFilter(f, val.name)) return;
            val.url = val.previewUrl = val.downloadURL;
          } else if (documentData && documentData.source === 'sharepoint') {
            var anchorTagClass = 'message';
          } else if (index === 0 && !files[file][0].id && !val.file_location && !files[file][0].url) return '';

          if (f && val.name && !documentFilter(f, val.name)){
            return;
          }

          return (
            <li key={val.id}>
              {(val && val.name && val.name.indexOf('Signed') > -1) ||
              (eversignDetails &&
                eversignDetails.filter((ele) => {
                  return (
                    ele.isHomeSigned &&
                    ele.fileOriginUrl ==
                      (val.previewUrl ? val.previewUrl : (val.url || previewUrls?.[val.id]) || '')
                  );
                })[0]) ? (
                <Tooltip
                  overlayClassName="black-tooltip"
                  placement="top"
                  title="This document signing is complete.">
                  <i
                    className="fa fa-check-circle"
                    style={{ fontSize: '22px', color: '#2e8540' }}
                    aria-hidden="true"
                  />{' '}
                </Tooltip>
              ) : (
                this.isEverSignValid(val.name) && (
                  <Tooltip
                    overlayClassName="black-tooltip"
                    placement="top"
                    title="Request Signature">
                    <img
                      style={{ cursor: 'pointer' }}
                      src={signatureIconSmSvg}
                      onClick={() => {
                        this.setState({
                          eversignRequestPopUp: true,
                          docValues: {
                            ...val,
                            url: val.previewUrl || previewUrls?.[val.id] || val.url ||''
                          }
                        });
                      }}
                      alt="Request Signature"
                    />
                  </Tooltip>
                )
              )}
              <a
                style={{ marginLeft: '10px' }}
                className={anchorTagClass}
                href={val.url ? val.url : (previewUrls && previewUrls[val.id]) || '#'}
                target="_blank">
                {val && val.name}
              </a>
            </li>
          );
        })
      );
    });
  }

  // render sub folder
  collapsible(files) {
    const { dropboxFile } = this.state;
    const {
      eversignDetails,
      homeOwnerFileList: { documentData }
    } = this.props;
    return Object.keys(this.sortFolder(files)).map((file) => {
      if (files && files[file] && files[file].length < 2 && files[file][0] && !files[file][0].id) {
        return (
          <li key={file} className="file-error">
            {files[file][0]}
          </li>
        );
      }
      return (
        <li key={file}>
          <strong>{files[file][0] && !files[file][0].id ? files[file][0] : ''}</strong>

          {files[file]
            .sort((file1, file2) => {
              const name1 = file1 && file1.name && (file1.name.toLowerCase() || null);
              const name2 = file2 && file2.name && (file2.name.toLowerCase() || null);
              return name1 && name2 && name1 < name2 ? -1 : name1 && name2 && name1 > name2 ? 1 : 0;
            })
            .map((val, index) => {
              if (index === 0 && !files[file][0].id) return '';
              if (documentData && documentData.source === 'sharepoint') {
                var anchorTagClass = 'message';
              }

              if (documentData && documentData.source === 'dropbox') {
                val.url =
                  dropboxFile &&
                  dropboxFile.find((ele) => {
                    return val.path_display === ele.filePath;
                  })['previewUrl'];
              }

              return (
                <div className="file" key={val.id}>
                  {(val && val.name.indexOf('Signed') > -1) ||
                  (eversignDetails &&
                    eversignDetails.filter((ele) => {
                      return (
                        ele.isHomeSigned &&
                        (val.previewUrl ? val.previewUrl : (val.url || previewUrls?.[val.id]) || '')
                      );
                    })[0]) ? (
                    <Tooltip
                      overlayClassName="black-tooltip"
                      placement="top"
                      title="This document signing is complete.">
                      <i
                        className="fa fa-check-circle"
                        style={{ fontSize: '22px', color: '#2e8540' }}
                        aria-hidden="true"
                      />{' '}
                    </Tooltip>
                  ) : (
                    this.isEverSignValid(val.name) && (
                      <Tooltip
                        overlayClassName="black-tooltip"
                        placement="top"
                        title="Request Signature">
                        <img
                          style={{ cursor: 'pointer' }}
                          src={signatureIconSmSvg}
                          onClick={() => {
                            this.setState({
                              eversignRequestPopUp: true,
                              docValues: { ...val, url: val.previewUrl ? val.previewUrl : '' }
                            });
                          }}
                          alt="Request Signature"
                        />
                      </Tooltip>
                    )
                  )}
                  <a
                    className={anchorTagClass}
                    style={{ marginLeft: '10px' }}
                    href={val.url ? val.url : ''}
                    target="_blank">
                    {val && val.name}
                  </a>
                </div>
              );
            })}
        </li>
      );
    });
  }

  // sort folder by name
  sortFolder(files) {
    const temp = {};
    const tempArray = [];
    Object.keys(files).forEach((file) => {
      if (files[file][0] && !files[file][0].id) {
        tempArray.push(files[file][0]);
      } else {
        temp[file] = files[file];
      }
    });
    tempArray
      .sort((val1, val2) => {
        return val1 && val2 && val1.toLowerCase() < val2.toLowerCase()
          ? -1
          : val1 && val2 && val1.toLowerCase() > val2.toLowerCase()
          ? 1
          : 0;
      })
      .forEach((val) => {
        Object.keys(files).forEach((file) => {
          if (files[file][0] && !files[file][0].id && files[file][0] === val) {
            temp[file] = files[file];
          }
        });
      });
    return temp;
  }

  arrangeFiles(files) {
    const temp = {
      collapsible: {},
      nonCollapsible: {}
    };
    const tempArray = [];
    Object.keys(files).forEach((file) => {
      if (
        files[file][0] &&
        (files[file][0].file_location || files[file][0].publicURL || files[file][0].url)
      ) {
        temp.nonCollapsible[file] = files[file];
      } else if (files[file][0] && files[file][0].public_url) {
        temp.nonCollapsible[file] = files[file];
      } else if (files[file][0] && !files[file][0].id) {
        tempArray.push(files[file][0]);
      } else {
        temp.nonCollapsible[file] = files[file];
      }
    });
    tempArray
      .sort((val1, val2) => {
        return val1 && val2 && val1.toLowerCase() < val2.toLowerCase()
          ? -1
          : val1 && val2 && val1.toLowerCase() > val2.toLowerCase()
          ? 1
          : 0;
      })
      .forEach((val) => {
        Object.keys(files).forEach((file) => {
          if (files[file][0] && !files[file][0].id && files[file][0] === val) {
            temp.collapsible[file] = files[file];
          }
        });
      });
    return temp;
  }


}

const mapStatetoProps = (state) => {
  return {
    companyId: state.addhomeOwner.companyId,
    companyData: state.setting.companyDetail.data,
    installerDetails: state.header.getInstaller && state.header.getInstaller.data,
    meterConnectionStatus:
      state.installerMeterConnection && state.installerMeterConnection.installerMeterConnection,
    huaweiMeterId: state.installerMeterConnection && state.installerMeterConnection.huaweiMeterId,
    cloudImages:
      JSON.stringify(state.cloudImages.cloudImages) !== '{}' ? state.cloudImages.cloudImages : {}
  };
};

const mapDispatchToProps = (dispatch) => {
  const boundActions = bindActionCreators(
    {
      requestUpdateHomeOwner,
      requestEditUpdateAccount,
      requestHomeOwner,
      receiveUpdateHomeOwner,
      setLoader,
      requestHuaweiId,
      requestHuaweiId,
      receiveHuaweiId,
      requestEgaugePowerMeter
    },
    dispatch
  );
  return {
    forgotPassword: (username) => {
      dispatch(reducer.forgotPassword(username));
    },
    ...boundActions
  };
};
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(MonitoringScreenPage));
