import React, { Suspense, useState, useEffect } from 'react';
import moment from 'moment';

import { DISPLAY_DATE_FORMAT, ParticipantType } from '../../../../constants';
import { IHomeownerMessageInterface } from './types';
import { IIntersectionElement } from '../../../Common/types';
import { IntersectionElement } from '../../../Common';

import { suspenseFallback } from '../../../../constants/components';

const HomeownerMessageComposer = React.lazy(() => import('./HomeownerMessageComposer'));
const defaultWasUnread = '';

/**
 * Render Homeowner Messages, provide standard interface
 *
 * @params {Object} props
 * @return React FC
 */
export const HomeownerMessageInterface: React.FC<IHomeownerMessageInterface> = (props) => {
  const {
    children,
    wasRead,
    unreadHandler,
    readObserver,
    message,
    displayComposer,
    threadId,
    homeOwner,
    messageTriggersFetch,
    fetchMessagesHandler,
    hideComposer,
    installerId
  } = props;
  /**
   * Tracks message, if it was unread, to convert to was read
   */
  const [idWasRead, setIdWasRead] = useState<string>(defaultWasUnread);
  /**
   * Tracks message, if it is last in payload, and we have more, to fetch next payload
   */
  const [loaderWasViewed, setLoaderWasViewed] = useState<boolean>(false);
  const [activeMessage, setActiveMessage] = useState<boolean>(false);

  useEffect(() => {
    if (unreadHandler && !wasRead && idWasRead) {
      unreadHandler(idWasRead);
    }
  }, [idWasRead]);

  /**
   * handles setting activeMessage to true based on displayComposer
   * this allows for the most recent message to display the composer initially
   */
  useEffect(() => {
    if (displayComposer) {
      setActiveMessage(true);
    }
  }, [displayComposer]);

  /**
   * handles the onClick event for the message to allow composer to be displayed
   * in older messages
   *
   * @todo: for inbox messages, convert selector to constant, so they can't co-vary
   */
  useEffect(() => {
    const feedContainer = document.querySelector('.next-level-wrapp');
    if (feedContainer) {
      feedContainer[activeMessage ? 'addEventListener' : 'removeEventListener'](
        'click',
        handleClickOutside
      );
    }
    return () => {
      if (feedContainer) {
        feedContainer.removeEventListener('click', handleClickOutside);
      }
    };
  }, [activeMessage]);

  const messageId = message.id;
  const MessageWasReadObserver = readObserver as React.FC<IIntersectionElement>;

  const handleReply = () => {
    setActiveMessage(true);
  };

  const handleClickOutside = (event: any) => {
    /**
     * @todo: convert `homeowner-interface-composer` selector to constant so it can't co-vary
     */
    if (event.target.closest('.homeowner-interface-composer')) {
      return;
    }
    setActiveMessage(false);
  };

  const isHomeownerSender = message && message.senderType === ParticipantType.HOMEOWNER;

  return (
    <>
      {wasRead === false && idWasRead === defaultWasUnread && !!unreadHandler && (
        <MessageWasReadObserver
          elementId={messageId}
          height={1}
          handleIsIntersecting={(wasRead: boolean) => {
            if (wasRead && !idWasRead) {
              setIdWasRead(messageId);
            }
          }}
        />
      )}
      {messageTriggersFetch && !loaderWasViewed && !!fetchMessagesHandler && (
        <IntersectionElement
          elementId={`${messageId}-${threadId}`}
          height={1}
          handleIsIntersecting={(viewed: boolean) => {
            if (viewed && !loaderWasViewed) {
              setLoaderWasViewed(true);
              fetchMessagesHandler();
            }
          }}
        />
      )}
      {children}
      {!isHomeownerSender && (
        <span className="contact-wrapperdate-box">
          {moment(message.createdAt).format(DISPLAY_DATE_FORMAT)}
        </span>
      )}
      {!activeMessage && !isHomeownerSender && hideComposer !== false && (
        <span className="pull-right">
          <a className="status-feed-reply premiumLinks" onClick={handleReply}>
            Reply
          </a>
        </span>
      )}
      {activeMessage && !isHomeownerSender && hideComposer !== false && (
        <div className="homeowner-message-interface">
          <div className="homeowner-interface-composer">
            <Suspense fallback={suspenseFallback}>
              <HomeownerMessageComposer
                senderId={Number(message.recipientId)}
                recipientId={message.senderType == ParticipantType.COMPANY ? Number(installerId) :  Number(message.senderId)}
                threadId={threadId}
              />
            </Suspense>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeownerMessageInterface;
