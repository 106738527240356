//  import npm packages
import React, { Component } from "react";

// import local files
import envConfig from '../../environments/index'
import { jwtDecode } from "../../shared/jwt";
import { getToken } from "../../shared/util";
import initializeS3 from '../../shared/initilizeS3';

class LogoUpload extends Component {
    state = {
        imageName: "",
        imageFile: "",
        response: "",
        desktopUrl: this.props.photoUrl ? this.props.photoUrl : undefined,
        loader: false,
    };

    uploadImage = async () => {
        this.uploadImageonS3()
        // if (this.state.imageName.length !== 0) {
        //     this.loader = true;
        //     const { auth, data } = this.props;
        //     let expTime = auth && auth.info && auth.info.idToken ? auth.info.idToken.payload.exp : 0
        //     if (Date.now() > expTime) {
        //         let email = data ? data.email : ""
        //         let refreshToken = auth && auth.info && JSON.stringify(auth.info) !== '{}' ? auth.info.refreshToken : { token: "" }
        //         let res = await updateRefreshToken(email, refreshToken)
        //         if (res) {
        //             this.uploadImageonS3()
        //         }
        //     } else {
        //         this.uploadImageonS3()
        //     }
        // }
    };

    uploadImageonS3 = () => {
        this.props.setLoader({loader: true})
        //SetS3Config("my-test-bucket-amplify", "protected");
        let token = getToken();
        let installerData = jwtDecode(token);
        let installerId = installerData.installerId;
        let slug = localStorage.getItem("slug");
        let oldFileName = this.upload.files[0].name.split(".")
        let extension = oldFileName[oldFileName.length - 1]
        let fileName = `${slug}_${installerId}_${new Date().getTime()}.${extension}`
        let s3 = initializeS3();
        s3.upload({
            // ACL : "public-read",
            Bucket: envConfig.envConfig.s3.BUCKET,
            Key: `public/installerImage/${fileName}`,
            Body: this.upload.files[0],
            //ContentType: "image/*"
        })
        .promise()
        .then(result => {
            this.upload = null;
            this.props.setLoader({loader: false});
            this.props.getSource(result.Location);
            // this.props.requestUpdateInstallerById(data);
        })
        .catch(err => {
            this.props.setLoader({loader: false});
            this.setState({ response: `Cannot upload file: ${err}` });
        });
    }
    render() {
        return (
            <div className="upload-input" >
                {/* { this.props.photoUrl ? <img alt="17terawatts" src={ this.props.photoUrl } style={ { width: "140px", height: "140px", marginTop: "8px", display: "block" } } /> : null }  */}
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }}
                    ref={ref => (this.upload = ref)}
                    onChange={e =>
                        this.setState({
                            imageFile: this.upload.files[0],
                            imageName: this.upload.files[0] ? this.upload.files[0].name : ""
                        }, () => {
                            this.uploadImage()
                        })
                        
                    }
                />
                
                <button
                    type="button"
                    onClick={e => {
                        this.upload.value = null;
                        this.upload.click();
                    }}
                    className="btn bg-white"
                >
                    EDIT PHOTO
                </button>
                {this.state.response && <div>{this.state.response}</div>}
            </div>
        );
    }
}

export default LogoUpload;