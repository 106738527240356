import moment from 'moment';

export const replaceStatusFeedText = (
  text,
  systemGlance,
  loggedInUser,
  customTouchData,
  urlParam,
  touchData
) => {
  let { homeownerData, solarUnit } = loggedInUser;
  let { solarGlance, phaseGlance } = systemGlance;
  let crmMapping =
    solarUnit.crmMapping && typeof solarUnit.crmMapping === 'string'
      ? JSON.parse(solarUnit.crmMapping)
      : solarUnit.crmMapping;
  text = text
    .replace(/#FIRST_NAME/g, homeownerData && homeownerData.firstName)
    .replace(/#LAST_NAME/g, homeownerData && homeownerData.lastName)
    .replace(/#ADDRESS/g, calculateAddress(homeownerData && homeownerData.address))
    .replace(/#SYSTEM_SIZE/g, solarUnit && solarUnit.systemSize)
    .replace(/#MODULE_MANUFACTURER/g, solarGlance && solarGlance.moduleName)
    .replace(/#MODULE_MODEL/g, solarUnit && solarUnit.moduleModel)
    .replace(/#AHJ/g, (solarUnit && solarUnit.ahj) || 'AHJ')
    .replace(
      /#INSTALL_DATE/,
      (solarUnit.installationDate &&
        replaceDate(solarUnit.installationDate, homeownerData.offset)) ||
        (touchData && touchData.touchDate && replaceDate(touchData.touchDate)) ||
        'future'
    )
    .replace(/#SYSTEM_PRICE/g, `$${solarUnit && solarUnit.systemPrice}`)
    .replace(/#MODULE_COUNT/g, solarUnit && solarUnit.moduleCount)
    .replace(/#INVERTER_MANUFACTURER/g, solarGlance && solarGlance.inverterName)
    .replace(/#UTILITY/g, solarGlance && (solarGlance.utilityLabel || solarGlance.utilityName))
    .replace(/#MONITORING_MANUFACTURER/g, solarGlance && solarGlance.meterName)
    .replace(/#HOMEOWNER/g, homeownerData && homeownerData.firstName)
    .replace(/#SOLARCOMPANY\b/g, solarGlance && solarGlance.companyName)
    .replace(/#PM_NAME/g, (homeownerData && homeownerData.installerName) || 'Project Manager')
    .replace(/#REFERRALAMOUNT/g, `$${phaseGlance && phaseGlance.referralAmount}`)
    .replace(/#REFERRAL_AMOUNT/g, `$${phaseGlance && phaseGlance.referralAmount}`)
    .replace(
      /#PHASE_CUTOFF_DAYS/g,
      phaseGlance && phaseCutoffDays(customTouchData && customTouchData.phaseName, phaseGlance)
    )
    .replace(/#SOLARCOMPANY_WEBSITE/g, solarGlance && solarGlance.companyUrl)
    .replace(/#REFERRAL_MESSAGE/g, phaseGlance && phaseGlance.ReferralMessage)
    .replace(/#PM_EMAIL/g, homeownerData && homeownerData.installerEmail)
    .replace(/#PM_NUMBER/g, homeownerData && homeownerData.installerPhone)
    .replace(/#ANNUAL_SAVINGS/g, `$${solarUnit && solarUnit.saving}`)
    .replace(/#LINK/g, getLink(customTouchData, urlParam))
    .replace(/#AVERAGE_OFFSET/g, calculateOffset(solarUnit));

  if (crmMapping) {
    for (var i in crmMapping) {
      let key = '#' + i;
      let regex = new RegExp(key, 'g');
      text = text.replace(regex, replaceDate(crmMapping[i]));
    }
  }

  return text;
};

function replaceDate(crmValue, timezone) {
  if (!crmValue) return '';
  let checkNan = isNaN(crmValue) ? crmValue : crmValue.length < 10 ? crmValue : parseInt(crmValue);
  let momentValue = moment.utc(checkNan).format('MMMM DD');
  let value = momentValue !== 'Invalid date' ? momentValue : crmValue;
  return value;
}

function getLink(customTouchData, urlParam) {
  if (customTouchData && customTouchData.touchName)
    return `${window.location.origin}/${urlParam.slug}/${customTouchData.touchName}`;
  else return '';
}

function calculateOffset(solarUnit) {
  if (solarUnit && solarUnit.annualProduction && solarUnit.annualConsumption)
    return `${parseInt((solarUnit.annualProduction * 100) / solarUnit.annualConsumption)}%`;
  else return '';
}

function phaseCutoffDays(phaseName, phaseGlance) {
  switch (phaseName) {
    case 'Design':
      return phaseGlance['DesignPhaseCutOff'] || 'some days';
    case 'Permit':
      return phaseGlance['PermittingPhaseCutOff'] || 'some days';
    case 'The Build':
      return phaseGlance['BuildPhaseCutOff'] || 'some days';
    case 'Inspection':
      return phaseGlance['InspectionPhaseCutOff'] || 'some days';
    case 'Powering Up':
      return phaseGlance['PoweringUpPhaseCutOff'] || 'some days';
    default:
      return 'some days';
  }
}

function calculateAddress(data) {
  if (data) {
    return `${data.address1} ${data.address2} ${data.city}, ${data.state}, ${data.zipCode}`;
  }
}
