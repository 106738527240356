import { IJourneyModel, IJourneyModelWithValue, TBodhiSuiteModel } from './types';

import globalStyles from './globalStyles';
import { findMenuElementByKey, coreMenu, installerSettingKey } from './routes';

const installerRole = 'installer';
const installerAdminRole = 'installer-admin';
const adminRole = 'admin';
const homeownerRole = 'homeowner';

/**
 * constants for configuring global search in the nav
 */
const SEARCH_FILTER_KEY = 'filter';
const SEARCH_KEY = 'search';
const SEARCH_FILTER_TYPE_INBOX = 'inbox';
const SEARCH_KEY_INBOX_HOMEOWNER = 'inbox';
const SEARCH_CHAR_THRESHOLD = 3;

/**
 * Installer role-specific constants
 */
const INSTALLER_POLL_INTERVAL = 3000;
const HOMEOWNER_POLL_INTERVAL = 5000;

/**
 * The duration in msecs indicating an intersection element is "viewed"
 */
export const INTERSECT_VIEW_DURATION = 250;

/**
 * The shared defaults for number of elements per page for inbox threads
 */
export const THREADS_DEFAULT_LIMIT = 15;
export const MESSAGES_DEFAULT_LIMIT = 15;

export const DISPLAY_DATE_FORMAT = 'MMM DD, h:mm A';

export const DEFAULT_USER_ALT = 'An image representing a company or related entity';
export const DEFAULT_BODHI_ALT = 'An image of the Bodhi logo';

/**
 * How we signal and identify that a given classname should enable CSS override via the installer's company settings
 */
export const CUSTOMIZED_BGCOLOR_CLASS = 'premiumBackground';
export const CUSTOMIZED_LINKCOLOR_CLASS = 'premiumLinks';
export const CUSTOMIZED_LINKBGCOLOR_CLASS = 'premiumLinks bgColor';
export const CUSTOMIZED_ICON_BGCOLOR_CLASS = 'premiumSecondaryText';

/**
 * Allows us to find and inspect the style HTML node where the homeowner css is written
 */
export const HOMEOWNER_CUSTOM_CSS_ID = 'homeowner-custom-css';

/**
 * How we signal from the root (body) that the homeowner has a scrolled state
 */
export const HOMEOWNER_MOBILE_SCROLL = 'homeowner-mobile-scroll';

export const HUBSPOT_FORM_CONFIG = {
  region: 'na1',
  portalId: '4854223',
  formId: '76e5064b-f74e-4313-bd03-078726112b61'
};

// 0 is reserved for fallback component
export enum MessageType {
  REPLY_FROM_HOMEOWNER = 0,
  REPLY = 1,
  ACCOUNT_CREATED = 2,
  NPS_SURVEY_1 = 3,
  NPS_SURVEY_2 = 4,
  NPS_RESPONSES_FEEDBACK = 5,
  TOUCH = 6,
  EDUCATIONAL = 7,
  PERFORMANCE_MONTHLY = 8,
  ALERT_GOOD_DAY = 9,
  ALERT_SYSTEM = 10,
  FIELD_APP = 11,
  ESIGNATURE = 12,
  FIRST_LOGIN = 13,
  SURVEY_RESPONSE = 14,
  CAMPAIGN = 15
}

export enum MessageAttachmentType {
  IMAGE = 1,
  BUTTON = 2,
  VIDEO = 3,
  PDF = 4,
  DOCUMENT = 5
}

export enum ParticipantType {
  HOMEOWNER = 1,
  INSTALLER = 2,
  COMPANY = 3
}

export const messageChannelIdentifiers = {
  email: 1,
  sms: 2,
  app: 3,
  note: 4
};

/**
 * Start defining a canonical abstraction/model that we can iterate on to move to a value object
 *
 * @todo reconcile this with the array of values used in BodhiJourney.js, some of which are slightly different
 *
 * {key} what we call property, not user facing
 * {value} how we evaluate/define equivalance
 * {label} user facing property data
 * {phase} a means of ordering properties
 */
const defaultJourneyModel: IJourneyModelWithValue[] = [
  { key: 'design', value: 'design', label: 'Design', phase: 1 },
  { key: 'permit', value: 'permit', label: 'Permit', phase: 2 },
  { key: 'thebuild', value: 'thebuild', label: 'The Build', phase: 3 },
  { key: 'inspection', value: 'inspection', label: 'Inspection', phase: 4 },
  { key: 'poweringup', value: 'poweringup', label: 'Powering Up', phase: 5 },
  { key: 'service', value: 'service', label: 'Service', phase: 6 }
];


/**
 * Provides a mechanism to process the model such that the model values are the keys
 *
 * @returns {Object}
 */
const resolveJourneyValues = () => {
  const model: Record<string, IJourneyModel> = {};
  defaultJourneyModel.forEach((phase: IJourneyModelWithValue) => {
    const val: string = phase.value as string;
    model[val as keyof IJourneyModelWithValue] = {
      label: phase.label,
      phase: phase.phase,
      key: phase.key
    };
  });
  return model;
};

const appFontOptions = [
  {
    key: 'arial',
    label: 'Arial'
  },
  {
    key: 'cabin',
    label: 'Cabin'
  },
  // {
  //   "key": "futura",
  //   "label": "Futura STD"
  // },
  {
    key: 'gothic',
    label: 'Century Gothic'
  },
  {
    key: 'harmonia',
    label: 'Harmonia'
  },
  {
    key: 'kulim',
    label: 'Kulim'
  },
  {
    key: 'lato',
    label: 'Lato'
  },
  {
    key: 'montserrat',
    label: 'Montserrat'
  },
  {
    key: 'muli',
    label: 'Muli'
  },
  {
    key: 'poppins',
    label: 'Poppins'
  },
  {
    key: 'proxima',
    label: 'Proxima Nova'
  },
  {
    key: 'questrial',
    label: 'Questrial'
  },
  {
    key: 'raleway',
    label: 'Raleway'
  },
  {
    key: 'roboto',
    label: 'Roboto'
  },
  {
    key: 'rubik',
    label: 'Rubik'
  },
  {
    key: 'source',
    label: 'Source Sans Pro'
  }
];

const CORE = ['Dashboard', 'Inbox', 'Settings', 'Team', 'Insights', 'Campaigns'];

const FLEET = ['Dashboard', 'Fleet', 'Settings', 'Team'];

const PLUS = [...CORE, ...FLEET];

const products = {
  1: CORE,
  2: FLEET,
  3: PLUS
};

const PRODUCT_NAME = {
  Fleet: 'Fleet',
  Inbox: 'Inbox',
  Settings: 'Settings',
  Team: 'Team',
  Insights: 'Insights',
  Campaigns: 'Campaigns',
  Dashboard: 'Dashboard'
};

const AUTOMATED_MESSAGE_TYPE = [
  MessageType.ACCOUNT_CREATED,
  MessageType.NPS_SURVEY_1,
  MessageType.NPS_SURVEY_2,
  MessageType.NPS_RESPONSES_FEEDBACK,
  MessageType.TOUCH,
  MessageType.EDUCATIONAL,
  MessageType.PERFORMANCE_MONTHLY,
  MessageType.ALERT_GOOD_DAY,
  MessageType.ALERT_SYSTEM,
  MessageType.FIELD_APP
];

const LITE_TEMPLATE_MENU: any = [
  {
    display: 'Journey templates',
    key: 'phaseTemplate',
    option: []
  },
  {
    display: 'Custom templates',
    key: 'customTemplate',
    option: []
  }
];

const PRODUCT_FEATURE_LIST: TBodhiSuiteModel[] = [
  {
    display: 'Bodhi lite',
    id: 1
  },
  {
    display: 'Bodhi fleet',
    id: 2
  },
  {
    display: 'Bodhi core',
    id: 3
  }
];

// maximum length of an inbox message
const maxMessageCharCount = 300;
const maxTemplateSubjectCharCount = 50;
const maxTotalAttachmentSize = 25;
const maxPdfSize = 10;
const LITE_TOOLTIP_TEXT = `Character limit for messages is ${maxMessageCharCount} characters and ${maxTemplateSubjectCharCount} characters for template title.`;

const SUNLIGHT_STATUS_DETAIL = {
  1: { status: 'error', name: 'Upload error',text:'',isButton:false },
  2: { status: 'success', name: 'Awaiting approval',text:'',isButton:false },
  3: { status: 'error', name: 'Approval request failed',text:'Request for approval is failed',isButton:false },
  4: { status: 'success', name: 'Complete',text:'' ,isButton:false},
  5: { status: 'success', name: 'Complete',text:'',isButton:false },
  6: { status: 'success', name: 'Complete',text:'',isButton:false },
  7: { status: 'error', name: 'Needs attention',text:'Please provide final design with address, module/inverter, and system production',isButton:true },
  8: { status: 'error', name: 'Needs attention',text:'Please provide (1) photo of the completed installation',isButton:true },
  9: { status: 'error', name: 'Needs attention',text:'Please provide an installation agreement signed by the borrower',isButton:true },
  10: { status: 'error', name: 'Needs attention',text:"Please provide a new, signed installation agreement with the borrower's legal name",isButton:true },
  11: { status: 'success', name: 'Complete',text:'',isButton:false },
  13: { status: 'error', name:'Needs attention',text:'Please provide PTO/Proof of Interconnection',isButton:true}
};

const SUNLIGHT_MILESTONES = {
  0: 'M0 - Notice to proceed',
  1: 'M1 - Installation',
  2: 'M2 - Permission to operate'
}

export {
  installerSettingKey,
  appFontOptions,
  globalStyles,
  coreMenu,
  installerRole,
  installerAdminRole,
  adminRole,
  homeownerRole,
  SEARCH_FILTER_KEY,
  SEARCH_FILTER_TYPE_INBOX,
  SEARCH_KEY_INBOX_HOMEOWNER,
  SEARCH_KEY,
  SEARCH_CHAR_THRESHOLD,
  INSTALLER_POLL_INTERVAL,
  HOMEOWNER_POLL_INTERVAL,
  findMenuElementByKey,
  defaultJourneyModel,
  resolveJourneyValues,
  products,
  PRODUCT_NAME,
  AUTOMATED_MESSAGE_TYPE,
  LITE_TEMPLATE_MENU,
  PRODUCT_FEATURE_LIST,
  maxMessageCharCount,
  maxTemplateSubjectCharCount,
  LITE_TOOLTIP_TEXT,
  maxTotalAttachmentSize,
  maxPdfSize,
  SUNLIGHT_STATUS_DETAIL,
  SUNLIGHT_MILESTONES
};
