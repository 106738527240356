// import npm packages
import { all, fork } from 'redux-saga/effects';
import { takeLatest } from "redux-saga/effects";
import { sagas } from 'aws-cognito-redux-saga';

// import local files
// import installer files
// import sagas
import { addHomeOwner } from './Installer/addHomeOwner.saga';
import { header } from './Installer/header.saga';
import { user } from './Installer/user.saga';
import { profile } from './Installer/profile.saga';
import { dashboard } from './Installer/dashboard.saga';
import { homeOwner } from './Installer/HomeOwner.saga';
import { installerMeterConnection } from './Installer/testConnection.saga';
import { companyBodhiJourney } from "./Installer/companyJourney.saga";
import { fleet } from "./Installer/fleet.saga"
import { companyCampaign }     from "./Campaign/campaign.saga"

import { adminData }       from './Admin/addCompany.saga'
import { adminSetting }    from './Admin/adminSetting.saga'
import { adminHeader}      from "./Admin/adminHeader.saga"; 
import {adminEditCompany}  from './Admin/adminEditCompany.saga'


// import types

import {
  REQUEST_COMPANY_JOURNEY,
  REQUEST_COMPANY_CRMDATA,
  ADD_COMPANY_JOURNEY
} from "../screens/Installer/actions/companyJourney"

import {
  REQUEST_COMPANY_FLEETDATA,
} from "../screens/Installer/actions/fleet"

import {
  REQUEST_CREATE_CAMPAIGN,
  REQUEST_GET_CAMPAIGN_DATA,
  REQUEST_GET_CAMPAIGN_DETAIL,
  REQUEST_UPDATE_CAMPAIGN
} from "../screens/campaign/actions/campaign"

import {
  REQUEST_ADD_HOME_OWNER_DATA_DUMMY,
  REQUEST_API_DATA,
  REQUEST_TIME_ZONE_DATA,
  REQUEST_STATE_DATA,
  REQUEST_COMPANY_ID_DATA,
  REQUEST_POWER_METER_DATA,
  REQUEST_INSTALLER_DATA,
  REQUEST_ADD_HOME_OWNER_DATA,
  REQUEST_CRM_TOKEN_DATA
} from "../screens/Installer/actions/addHomeOwner";

import {
  REQUEST_SLUG_DATA,
  REQUEST_SLUG_VERIFICATION,
  REQUEST_EMAIL_COMPANY_STATUS
} from './../screens/User/data/action'
import {
  REQUEST_SEARCH_DATA,
  REQUEST_INSTALLER_DATA_BY_ID,
  REQUEST_HOMEOWNER_DATA_BY_ID
} from "../screens/Installer/actions/header";
import {
  REQUEST_FAQ_LIST,
  REQUEST_FAQ_POST,
  REQUEST_COMPANY_DETAIL,
  REQUEST_COMPANY_DETAIL_SET,
  REQUEST_INVITE_EMPLOYEES,
  REQUEST_UPDATE_INSTALLER_BY_ID,
  REQUEST_UPDATE_LOGGEDIN_INSTALLER_BY_ID,
  REQUEST_UPDATE_COMPANY_DETAIL
} from "../screens/Installer/actions/profile";

import {
  REQUEST_HOME_OWNER_DATA,
  REQUEST_UPDATE_HOME_OWNER_DATA,
  REQUEST_DELETE_HOME_OWNER_DATA,
  REQUEST_HOME_OWNER_REFERRALS_DATA,
  REQUEST_HOME_OWNER_SOCIAL_SHARES,
  REQUEST_HOME_OWNER_SURVEY_RESULT,
  REQUEST_HOME_OWNER_ENGAGEMENT_SCORE,
  REQUEST_HOME_OWNER_ADD_USER,
  REQUEST_HOME_OWNER_ADD_NOTE,
  REQUEST_HOME_OWNER_UPDATE_NOTE,
  REQUEST_HOME_OWNER_DELETE_NOTE,
  REQUEST_HOME_OWNER_GET_NOTE
} from '../screens/Installer/actions/homeOwner';
import {
  REQUEST_REFERRAL_DATA_CHART,
  REQUEST_REFERRALS_COUNT,
  REQUEST_SURVEY_COUNT,
  REQUEST_SOCIAL_SHARES_COUNT,
  REQUEST_SOCIAL_SHARES_DATA,
  REQUEST_ACTIVE_ACCOUNT_COUNT,
  REQUEST_DOWNLOAD_REFERRAL,
  REQUEST_SURVEY_DATA,
  REQUEST_DELAYED_PROJECT_DATA,
  REQUEST_DOWNLOAD_SURVEY,
  REQUEST_DOWNLOAD_SOCIAL_SHARE,
  REQUEST_FINANCIAL_COUNT,
  REQUEST_FLEET_ALERT_COUNT
} from '../screens/Installer/actions/dashboard'
import {
  REQUEST_EGAUGE_POWER_METER,
  REQUEST_HUAWEI_ID
} from "../screens/Installer/actions/testMeterConnection";

// import homeowner files
// import sagas
import {
  statusFeedPhaseSurvey
} from './Homeowner/statusFeed.saga';
import {
  homeownerDashboard
} from "./Homeowner/dashboard.saga";
import {
  homeownerFaq
} from "./Homeowner/faq.saga";
import {
  homeownerUser
} from "./Homeowner/user.saga";
import {
  homeownerSettings
} from "./Homeowner/settings.saga";
import {
  homeownerResources
} from "./Homeowner/resources.saga";
import {
  homeownerContact
} from "./Homeowner/contact.saga";
import {
  homeownerReferral
} from "./Homeowner/referralDetails.saga";
import {
  homeownerSolar
} from './Homeowner/solar.saga';
import {
  homeownerEversign
} from './Homeowner/eversign.saga';
import {
  homeownerForgetPassword
} from './Homeowner/forgotpassword.saga';

// import types
import {
  REQUEST_PHASE_SURVEY
} from '../screens/homeowner/types/statusFeed';
import {
  REQUEST_HOMEOWNER_SYSTEM_GLANCE,
  REQUEST_HOMEOWNER_CAMPAIGN
} from "../screens/homeowner/types/dashboard/dashboard";
import {
  REQUEST_FAQ_QUESTIONS_ANSWERS
} from "../screens/homeowner/types/faq/faq";
import {
  REQUEST_LOGGED_IN_HOMEOWNER,
  REQUEST_HOMEOWNER_ENERGY_REPORT,
  REQUEST_HOMEOWNER_PAST_ENERGY_REPORT,
  REQUEST_HOMEOWNER_INSTALLER_NOTIFIY,
  REQUEST_HOMEOWNER_SPECIAL_CARDDATA,
  REQUEST_UPDATE_HOMEOWNER,
  REQUEST_DEVICE_MAGIC,
  REQUEST_HOMEOWNER_DOCUMENT
} from '../screens/homeowner/types/homeowner';
import {
  REQUEST_EDIT_UPDATE_ACCOUNT
} from '../screens/homeowner/types/settings/settings';
import {
  REQUEST_HOMEOWNER_COMPANY_DETAIL
} from '../screens/homeowner/types/resources/resources';
import {
  REQUEST_INSTALLER_DETAILS,
  REQUEST_POST_CONTACT_DATA
} from '../screens/homeowner/types/contact/contact';
import {
  REQUEST_REFERRAL_DETAILS,
  REQUEST_POST_REFERRAL_DETAILS,
  REQUEST_POST_ADDRESS_DETAILS,
  REQUEST_REFERRAL_NEIGHBOUR
} from '../screens/homeowner/types/referralDetails/referralDetails';
import {
  REQUEST_HOMEOWNERS_LIST
} from '../screens/homeowner/types/solar';
import {
  REQUEST_CREATE_EVERSIGN_DOCUMENT,
  REQUEST_GET_EVERSIGN_DATA
} from '../screens/homeowner/types/eversign/eversign';
import { REQUEST_POST_FORGOT_PASSWORD } from '../screens/homeowner/types/forgotPassword/forgotPassword';

import {REQUEST_COMPANY_SEARCH_DATA,REQUEST_ADMIN_COMPANY__DATA,
  REQUEST_ADMIN_HOMEOWNER_SEARCH} from "./../screens/Admin/Actions/header"

import { 
  REQUEST_POWERMETERAPI_DATA, 
  REQUEST_ADDCOMPANY_DATA, 
  REQUEST_STATELISTAPI_DATA, 
  REQUEST_SLUGSTATUSAPI_DATA, 
  REQUEST_ADMINREPORT_DATA,
  REQUEST_COMPANY_SUNLIGHT_CREDENTIALS_DATA
      } from "./../screens/Admin/Actions/dashboard";

import {
  REQUEST_DRIVEUPLOAD_S3,
  REQUEST_EDITINSTALLERAPI_DATA,
  REQUEST_EDITINSTALLERAPI_MANAGEMENT_DATA
} from "./../screens/Admin/Actions/editInstaller";

import { REQUEST_SET_UTILITY_DATA_BY_ID, 
        REQUEST_SET_UTILITY_RATE_PLAN_DATA_BY_ID, 
        REQUEST_GET_UTILITY_RATE_PLAN_DATA_BY_ID, 
        REQUEST_GET_UTILITY_RATE_PLAN_DATA, 
        REQUEST_GET_HARDWARE_DATA, REQUEST_SET_INVERTER_BRAND, REQUEST_SET_MODULE_BRAND, 
        REQUEST_DELETE_MODULE_BRAND, REQUEST_DELETE_INVERTER_BRAND, REQUEST_DELETE_RATE_PLAN, 
        REQUEST_SET_ELECTRIC_UTILITY, REQUEST_DELETE_ELECTRIC_UTILITY} from "./../screens/Admin/Actions/setting";

export default function* root() {
  yield all([fork(sagas)])
  // *************************** START INSTALLER PORTAL SAGA ******************************** //

  yield takeLatest(REQUEST_SEARCH_DATA, header.search);
  yield takeLatest(REQUEST_INSTALLER_DATA_BY_ID, header.getInstaller);
  yield takeLatest(REQUEST_HOMEOWNER_DATA_BY_ID, header.getHomeOwner);

  yield takeLatest(REQUEST_API_DATA, addHomeOwner.addExistingInstall);
  yield takeLatest(REQUEST_TIME_ZONE_DATA, addHomeOwner.timeZone);
  yield takeLatest(REQUEST_STATE_DATA, addHomeOwner.getStateData);
  yield takeLatest(REQUEST_POWER_METER_DATA, addHomeOwner.getPowerMeter);
  yield takeLatest(REQUEST_COMPANY_ID_DATA, addHomeOwner.getCompanyId);
  yield takeLatest(REQUEST_INSTALLER_DATA, addHomeOwner.getInstaller);
  yield takeLatest(REQUEST_ADD_HOME_OWNER_DATA, addHomeOwner.getAddHomeOwner);
  yield takeLatest(REQUEST_ADD_HOME_OWNER_DATA_DUMMY, addHomeOwner.getInstallerDummy);
  yield takeLatest(REQUEST_CRM_TOKEN_DATA, addHomeOwner.getCRMTokenData);

  yield takeLatest(REQUEST_SLUG_DATA, user.getSlugImage);
  yield takeLatest(REQUEST_SLUG_VERIFICATION, user.getslugVerification);
  yield takeLatest(REQUEST_EMAIL_COMPANY_STATUS,user.getEmailCompanyStatus);

  yield takeLatest(REQUEST_FAQ_LIST, profile.getFaqList);
  yield takeLatest(REQUEST_FAQ_POST, profile.getFaqPost);
  yield takeLatest(REQUEST_COMPANY_DETAIL, profile.getCompanyDetail);
  yield takeLatest(REQUEST_UPDATE_COMPANY_DETAIL, profile.updateCompanyDetail);
  yield takeLatest(REQUEST_COMPANY_DETAIL_SET, profile.setCompanyDetail);
  yield takeLatest(REQUEST_INVITE_EMPLOYEES, profile.setinviteEmployees);
  yield takeLatest(REQUEST_UPDATE_INSTALLER_BY_ID, profile.updateInstallerById);
  yield takeLatest(REQUEST_UPDATE_LOGGEDIN_INSTALLER_BY_ID, profile.updateLoggedInInstallerById)

  yield takeLatest(REQUEST_HOME_OWNER_DATA, homeOwner.getHomeOwner);
  yield takeLatest(REQUEST_UPDATE_HOME_OWNER_DATA, homeOwner.getUpdateHomeOwner);
  yield takeLatest(REQUEST_DELETE_HOME_OWNER_DATA, homeOwner.getDeleteHomeOwner);
  yield takeLatest(REQUEST_HOME_OWNER_REFERRALS_DATA, homeOwner.getReferralCount);
  yield takeLatest(REQUEST_HOME_OWNER_SURVEY_RESULT, homeOwner.getSurveyCount);
  yield takeLatest(REQUEST_HOME_OWNER_SOCIAL_SHARES, homeOwner.getSocialShareCount);
  yield takeLatest(REQUEST_HOME_OWNER_ENGAGEMENT_SCORE, homeOwner.getEngagementScore);
  yield takeLatest(REQUEST_HOME_OWNER_ADD_USER,homeOwner.getHomeownerAddUser);
  yield takeLatest(REQUEST_HOME_OWNER_ADD_NOTE,homeOwner.getHomeownerAddNote);
  yield takeLatest(REQUEST_HOME_OWNER_GET_NOTE,homeOwner.getHomeownerGetNote);
  yield takeLatest(REQUEST_HOME_OWNER_UPDATE_NOTE,homeOwner.updateHomeownerNote);
  yield takeLatest(REQUEST_HOME_OWNER_DELETE_NOTE,homeOwner.deleteHomeownerNote);
  


  yield takeLatest(REQUEST_REFERRALS_COUNT, dashboard.getReferralCount);
  yield takeLatest(REQUEST_REFERRAL_DATA_CHART, dashboard.getReferralChartData);
  yield takeLatest(REQUEST_DOWNLOAD_REFERRAL, dashboard.getDownloadReferral);
  yield takeLatest(REQUEST_SURVEY_COUNT, dashboard.getSurveyCount);
  yield takeLatest(REQUEST_SURVEY_DATA, dashboard.getSurveyDataResult);
  yield takeLatest(REQUEST_DOWNLOAD_SURVEY, dashboard.getDownloadSurvey);
  yield takeLatest(REQUEST_SOCIAL_SHARES_COUNT, dashboard.getSocialShareCount);
  yield takeLatest(REQUEST_SOCIAL_SHARES_DATA, dashboard.getSocialShareData)
  yield takeLatest(REQUEST_DOWNLOAD_SOCIAL_SHARE, dashboard.getDownloadSocialShare);
  yield takeLatest(REQUEST_ACTIVE_ACCOUNT_COUNT, dashboard.getActiveAccountCount);
  yield takeLatest(REQUEST_FINANCIAL_COUNT, dashboard.getFinancialCount);
  yield takeLatest(REQUEST_FLEET_ALERT_COUNT,dashboard.getFleetAlertCount);
  yield takeLatest(REQUEST_DELAYED_PROJECT_DATA, dashboard.getDelayedProjectResult);
  yield takeLatest(REQUEST_EGAUGE_POWER_METER, installerMeterConnection.getEgaugePowerMeter);
  yield takeLatest(REQUEST_HUAWEI_ID, installerMeterConnection.getHuaweiId);


  // *************************** END INSTALLER PORTAL SAGA ******************************** //



  // *************************** START HOMEOWNER PORTAL SAGA ******************************** //

  // homeowner system glance
  yield takeLatest(REQUEST_HOMEOWNER_SYSTEM_GLANCE, homeownerDashboard.getHomeownerSystemGlance);

  // homeowner fqws
  yield takeLatest(REQUEST_FAQ_QUESTIONS_ANSWERS, homeownerFaq.getFaqQuestionAnswer);

  // homeowner logged-in user
  yield takeLatest(REQUEST_LOGGED_IN_HOMEOWNER, homeownerUser.getHomeownerByIdSaga);

  // homeowner logged-in user
  yield takeLatest(REQUEST_EDIT_UPDATE_ACCOUNT, homeownerSettings.editUpdateAccount);

  // homeowner Energy report
  yield takeLatest(REQUEST_HOMEOWNER_ENERGY_REPORT, homeownerUser.getHomeownerEnergyReportSaga);
  yield takeLatest(REQUEST_HOMEOWNER_PAST_ENERGY_REPORT, homeownerUser.getHomeownerPastEnergyReportSaga);
  

  yield takeLatest(REQUEST_DEVICE_MAGIC,homeownerUser.getHomeownerDeviceMagic)

  yield takeLatest(REQUEST_HOMEOWNER_DOCUMENT,homeownerUser.getHomeownerDocumentSaga);

  //get data for special card
  yield takeLatest(REQUEST_HOMEOWNER_SPECIAL_CARDDATA,homeownerUser.getHomeownerSpecialCardDataSaga)

  yield takeLatest(REQUEST_UPDATE_HOMEOWNER,homeownerUser.getUpdateHomeOwnerSaga)
  // homeowner Resouces Company Detail
  yield takeLatest(REQUEST_HOMEOWNER_COMPANY_DETAIL, homeownerResources.getHomeownerCompanyDetail);

  // homeowner Installer Details
  yield takeLatest(REQUEST_INSTALLER_DETAILS, homeownerContact.getInstallerDetails);

  // homeowner Post Contact Details
  yield takeLatest(REQUEST_POST_CONTACT_DATA, homeownerContact.postContactDetails);

  // homeowner Referral Details
  yield takeLatest(REQUEST_REFERRAL_DETAILS, homeownerReferral.getReferralDetails);

  yield takeLatest(REQUEST_REFERRAL_NEIGHBOUR, homeownerReferral.getReferralNeighbour);  
  yield takeLatest(REQUEST_POST_REFERRAL_DETAILS, homeownerReferral.postReferralDetails);
  yield takeLatest(REQUEST_POST_ADDRESS_DETAILS, homeownerReferral.postAddressDetails);
  // homeowner solar
  yield takeLatest(REQUEST_HOMEOWNERS_LIST, homeownerSolar.getHomeownersListSaga);

  // status feed phase survey
  yield takeLatest(REQUEST_PHASE_SURVEY, statusFeedPhaseSurvey.postStatusFeedPhaseSurvey);
  // homeowner eversign
  yield takeLatest(REQUEST_CREATE_EVERSIGN_DOCUMENT, homeownerEversign.getCreateEversignDocument);

  yield takeLatest(REQUEST_GET_EVERSIGN_DATA, homeownerEversign.getEversignData);

  //home owner forgot password

  yield takeLatest(REQUEST_POST_FORGOT_PASSWORD, homeownerForgetPassword.postForgotPassword);

  //  notify installer for homeowner 1st login
  yield takeLatest(REQUEST_HOMEOWNER_INSTALLER_NOTIFIY,homeownerContact.notifyInstaller)

  // *************************** END HOMEOWNER PORTAL SAGA ******************************** //
  // bodhi company journey
  yield takeLatest(REQUEST_COMPANY_JOURNEY,companyBodhiJourney.getCompanyJourney);
  yield takeLatest(REQUEST_COMPANY_CRMDATA,companyBodhiJourney.getCompanyCRMData)
  yield takeLatest(ADD_COMPANY_JOURNEY,companyBodhiJourney.addCompanyJourney);

  // ****************************** START COMPANY SAGA *************************
  yield takeLatest(REQUEST_GET_CAMPAIGN_DATA,companyCampaign.getCampaignData);
  yield takeLatest(REQUEST_CREATE_CAMPAIGN,companyCampaign.postCampaignData);
  yield takeLatest(REQUEST_GET_CAMPAIGN_DETAIL,companyCampaign.getCampaignDetail);
  yield takeLatest(REQUEST_UPDATE_CAMPAIGN,companyCampaign.updateCampaignData);
  yield takeLatest(REQUEST_HOMEOWNER_CAMPAIGN,homeownerDashboard.homeownerCampaignData);
 // ****************************** END COMPANY SAGA *************************


  // ****************************** START ADMIN SAGA *************************
  yield takeLatest(REQUEST_POWERMETERAPI_DATA,   adminData.getPowerMetersAPI);
  yield takeLatest(REQUEST_STATELISTAPI_DATA,    adminData.getStateListAPI);
  yield takeLatest(REQUEST_COMPANY_SUNLIGHT_CREDENTIALS_DATA, adminData.getCompanySunlightCrednetialsAPI)
  yield takeLatest(REQUEST_SLUGSTATUSAPI_DATA,   adminData.getSlugStatusAPI);
  yield takeLatest(REQUEST_ADMINREPORT_DATA, adminData.adminReport);
  yield takeLatest(REQUEST_ADDCOMPANY_DATA, adminData.addCompanyAPI);

  // yield takeLatest(REQUEST_GETCOMPANYBYIDAPI_DATA, adminEditCompany.getCompanyByIdApi);
  yield takeLatest(REQUEST_DRIVEUPLOAD_S3,adminEditCompany.driveUploadS3)
  yield takeLatest(REQUEST_EDITINSTALLERAPI_DATA, adminEditCompany.geteditInstallerApi);
  yield takeLatest(REQUEST_EDITINSTALLERAPI_MANAGEMENT_DATA, adminEditCompany.geteditInstallerManagementApi);
  // yield takeLatest(REQUEST_UPLOAD_CSV, adminEditCompany.csvUpload);

  yield takeLatest(REQUEST_GET_HARDWARE_DATA, adminSetting.harwareSaga);
  yield takeLatest(REQUEST_SET_INVERTER_BRAND, adminSetting.setInverterBrand);
  yield takeLatest(REQUEST_SET_MODULE_BRAND, adminSetting.setModuleBrand);
  yield takeLatest(REQUEST_GET_UTILITY_RATE_PLAN_DATA, adminSetting.getUtilityRatePlanSaga);
  yield takeLatest(REQUEST_GET_UTILITY_RATE_PLAN_DATA_BY_ID, adminSetting.getUtilityRatePlanByIdSaga);
  yield takeLatest(REQUEST_SET_UTILITY_RATE_PLAN_DATA_BY_ID, adminSetting.setUtilityRatePlanByIdSaga);
  yield takeLatest(REQUEST_DELETE_MODULE_BRAND, adminSetting.deleteModuleBrand);
  yield takeLatest(REQUEST_SET_UTILITY_DATA_BY_ID, adminSetting.setUtilityByIdSaga);

  yield takeLatest(REQUEST_DELETE_INVERTER_BRAND, adminSetting.deleteInverterBrand);
  yield takeLatest(REQUEST_DELETE_RATE_PLAN, adminSetting.deleteRatePlan);

  yield takeLatest(REQUEST_SET_ELECTRIC_UTILITY, adminSetting.setElectricUtility);


  yield takeLatest(REQUEST_DELETE_ELECTRIC_UTILITY, adminSetting.deleteElectricUtility);

  yield takeLatest(REQUEST_COMPANY_SEARCH_DATA,adminHeader.search);
  yield takeLatest(REQUEST_ADMIN_COMPANY__DATA,adminHeader.listCompany);
  yield takeLatest(REQUEST_ADMIN_HOMEOWNER_SEARCH,adminHeader.searchHomeowner)

  // ****************************** END ADMIN SAGA *************************

  yield takeLatest(REQUEST_COMPANY_FLEETDATA,fleet.getCompanyFleetList);
  }
