import { Services } from "../../../Api/services";
import * as config from './../../../shared/config'
export const getEgaugeConnection = async data => {
    let url = `${config.ENERGY_REPORT_BASE_URL}company/testconnection`;

    if(!data.meterID){
        return {
            data :{ data:{}} 
        }
    } else if(data.meterID==7){
        url = `https://got88umi5h.execute-api.ap-northeast-1.amazonaws.com/prod/powermeters/huawei/connection`;
    } else if(data.meterID == 8){
        url = 'https://4t2tbwjzob.execute-api.us-east-1.amazonaws.com/prod/powermeters/sma/connection';
    }
    let res = await Services.postCall(url,data, false);
	return { data: res };
};

export const getHuaweiMeterId = async data =>{
    let url;
    switch(data.meterID){
        case 7:
            url = `https://got88umi5h.execute-api.ap-northeast-1.amazonaws.com/prod/powermeters/huawei/${data.meterID}`;
        break;
        case 8:
            url = `https://4t2tbwjzob.execute-api.us-east-1.amazonaws.com/prod/powermeters/sma/deviceid`;
        break;
    }
    if(!data.meterUrl){
       return null;
    }
    let res = await Services.postCall(url,data, false);
	return { data: res };
}