export const REQUEST_CREATE_CAMPAIGN   = "REQUEST_CREATE_CAMPAIGN";
export const RECEIVE_CREATE_CAMPAIGN   = "RECEIVE_CREATE_CAMPAIGN";
export const REQUEST_GET_CAMPAIGN_DATA = "REQUEST_GET_CAMPAIGN_DATA";
export const RECEIVE_GET_CAMPAIGN_DATA = "RECEIVE_GET_CAMPAIGN_DATA";
export const REQUEST_UPDATE_CAMPAIGN   = "REQUEST_UPDATE_CAMPAIGN";
export const RECEIVE_UPDATE_CAMPAIGN   = "RECEIVE_UPDATE_CAMPAIGN";
export const REQUEST_GET_CAMPAIGN_DETAIL = "REQUEST_GET_CAMPAIGN_DETAIL";
export const RECEIVE_GET_CAMPAIGN_DETAIL = "RECEIVE_GET_CAMPAIGN_DETAIL";

export const requestCreateCampaign = data => ({
	type: REQUEST_CREATE_CAMPAIGN, data
});

export const receiveCreateCampaign = data => ({
	type: RECEIVE_CREATE_CAMPAIGN, data
});

export const requestGetCampaignData = data => ({
	type: REQUEST_GET_CAMPAIGN_DATA, data
});

export const receiveGetCampaignData = data => ({
	type: RECEIVE_GET_CAMPAIGN_DATA, data
});

export const requestGetCampaignDetail = data => ({
	type: REQUEST_GET_CAMPAIGN_DETAIL, data
});

export const receiveGetCampaignDetail = data => ({
	type: RECEIVE_GET_CAMPAIGN_DETAIL, data
});

export const requestUpdateCampaign = data => ({
	type: REQUEST_UPDATE_CAMPAIGN, data
});

export const receiveUpdateCampaign = data => ({
	type: RECEIVE_UPDATE_CAMPAIGN, data
});
