import {
	REQUEST_FAQ_QUESTIONS_ANSWERS,
	RECEIVE_FAQ_QUESTIONS_ANSWERS
} from "../types/faq/faq";

export const requestFaqQuestionAnswer = data => ({
	type: REQUEST_FAQ_QUESTIONS_ANSWERS, data
});

export const receiveFaqQuestionAnswer = data => ({
	type: RECEIVE_FAQ_QUESTIONS_ANSWERS, data
});