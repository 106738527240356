export const REQUEST_SLUG_DATA = "/screens/Dashboard/data/SEARCH/REQUEST_SLUG_API_DATA";
export const RECEIVE_SLUG_DATA = "/screens/Dashboard/data/SEARCH/RECEIVE_SLUG_API_DATA";

export const REQUEST_SLUG_VERIFICATION = "/screens/Dashboard/data/SEARCH/REQUEST_SLUG_API_VERIFICATION";
export const RECEIVE_SLUG_VERIFICATION = "/screens/Dashboard/data/SEARCH/RECEIVE_SLUG_API_VERIFICATION";

export const REQUEST_EMAIL_COMPANY_STATUS = "REQUEST_EMAIL_COMPANY_STATUS";
export const RECEIVE_EMAIL_COMPANY_STATUS = "RECEIVE_EMAIL_COMPANY_STATUS";


export const requestSlugData = data => ({ type: REQUEST_SLUG_DATA, data });
export const receiveSlugData = data => ({ type: RECEIVE_SLUG_DATA, data });

export const requestSlugVerification = data => ({ type: REQUEST_SLUG_VERIFICATION, data });
export const receiveSlugVerification = data => ({ type: RECEIVE_SLUG_VERIFICATION, data });

export const requestEmailCompanyStatus =  data =>  ({ type: REQUEST_EMAIL_COMPANY_STATUS, data });
export const receiveEmailCompanyStatus =  data =>  ({ type: RECEIVE_EMAIL_COMPANY_STATUS, data });