import React, { Component } from 'react';
import { Select, DatePicker, Input, Popover } from 'antd';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { requestInstallerData, requestCompanyIdData } from '../../actions/addHomeOwner';
import withRouter from '../../../../shared/withRouter';
import { jwtDecode } from '../../../../shared/jwt';
import { monitoringSystemData } from '../../../../shared/fleet';
import leftArrowActiveIcon from '../../../../Assets/Images/leftArrow_active.svg';
import rightArrowActiveIcon, {
  ReactComponent as Select_Icon
} from '../../../../Assets/Images/rightArrow_active.svg';
import { ReactComponent as CaretIcon } from '../../../../Assets/Images/path-2.svg';
import { GreyStyledButton, StyledModal } from './companyMetrics.style';
import { UploadCsvModal } from './uploadCSVModal';
import { SUNLIGHT_STATUS_DETAIL } from '../../../../constants/index';
import {sunlightMileStoneName} from "../../../Common/SunlightMileStoneName"

const { Option } = Select;

const metricDataKeys = {
  sunlightfinancial: {
    Name: {
      key: `data."firstName"`,
      hideFilterBlock: true,
      showSortOrder : 'display'
    },
    Milestone: {
      key: `data."milestone" `,
      filterKey: 'milestone',
      filterType: 'List',
      filterList: [
        { id: 0, name: 'M0 - Notice to proceed' },
        { id: 1, name: 'M1 - Installation' },
        { id: 2, name: 'M2 - Permission to operate' }
      ]
    },
    'Days in current milestone': {
      key: `data."createdAt"`,
      hideFilterBlock: true,
      showSortOrder : 'display'
    },
    'Date of last activity': {
      key: `data."updatedAt"`,
      hideFilterBlock: true,
      showSortOrder : 'display'
    },
    Status: {
      key : `data."eventType"`,
      filterKey: '"eventType"::varchar',
      filterType: 'List',
      filterList: [
        { id: '1', name: 'Upload error' },
        { id: '2', name: 'Awaiting approval' },
        { id: '3', name: 'Approval request failed' },
        { id: "'4','5','6','11'",name:'Complete'},
        {id: "'7','8','9','10','13'", name:'Needs attention'}

      ]
    }
  },
  fleethomeowners:{
    Name: {
      key: `"Name"`,
      filterKey: 'ho.name',
      filterType: 'Input'
    },
    'Address': {
      key: `"Address"`,
      hideFilterBlock : true,
      showSortOrder: 'display'
    },
    'Email':{
      key:'"Email"',
      filterKey:'email_address'
    },
    'Monitoring system': {
      key: `"Monitoring system"`,
      filterKey: `pm.id`,
      filterType: 'List',
      filterList: [
        { id: 5, name: 'APsystems' },
        { id: 1, name: 'eGauge' },
        { id: 3, name: 'Enphase' },
        { id: 6, name: 'Fronius' },
        { id: 2, name: 'Solar Edge' },
        { id: 4, name: 'Sense' }
      ]
    },
    'Meter ID': {
      key: `"Meter ID"`,
      filterKey: `monitoring_system_id`,
    },
    'Last data sync': {
      key: `"Last data sync"`,
      showSortOrder: 'display',
      displayFormat : 'moment'
    },
    'Activation date':{
      key: `"Activation date"`,
      hideFilterBlock : true,
      showSortOrder: 'display'
    }
  },
  fleet: {
    Name: {
      key: `Name`,
      filterKey: 'Name'
    },
    'Street address': {
      // TODO
      key: `Street address`,
      filterKey: 'Street address',
      filterType: 'Input'
    },
    'Alert severity': {
      key: 'Alert severity',
      filterKey: 'Alert severity',
      filterType: 'List',
      filterList: [
        { id: 'Yellow', name: 'Yellow' },
        { id: 'Red', name: 'Red' }
      ]
    },
    Status: {
      key: 'Status',
      filterKey: 'Status',
      filterType: 'List',
      filterList: [
        { id: 'No data', name: 'No data' },
        { id: 'No production', name: 'No production' },
        { id: 'Low production', name: 'Low production' }
      ]
    },
    'Monitoring system': {
      key: `Monitoring system`,
      filterKey: `Monitoring system`,
      filterType: 'List',
      filterList: [
        { id: 5, name: 'APsystems' },
        { id: 1, name: 'eGauge' },
        { id: 3, name: 'Enphase' },
        { id: 6, name: 'Fronius' },
        { id: 2, name: 'Solar Edge' },
        { id: 4, name: 'Sense' }
      ]
    },
    'Todays production': {
      hideFilterBlock: true
    },
    'Yesterdays production': {
      hideFilterBlock: true
    },
    'Last data sync': {
      key: `dailySyncTime`,
      filterKey: 'Last data sync'
    },
    'Meter ID': {
      key: `Meter ID`,
      filterKey: 'Meter ID'
    },
  },
  companies: {
    Company: {},
    'Active Accounts': {},
    Referrals: {},
    'Average Survey Score': {},
    'Last account created': {},
    'Latest CRM Sync': {}
  },
  campaign: {
    campaignId: {
      key: `"headline"`,
      filterKey: 'headline'
    },
    filters: {
      key: `"headline"`,
      filterKey: 'headline'
    },
    'campaign Name': {
      key: `"headline"`,
      filterKey: 'headline'
    },
    Creator: {
      key: `"installerId"`,
      filterKey: '"installerId"',
      filterType: 'List',
      filterList: []
    },
    'Date Created': {
      key: `"createdAt"`,
      filterKey: `"createdAt"`,
      filterType: 'DatePicker',
      displayFormat: 'moment'
    },
    'Date Launched': {
      key: `"launchedDate"`,
      filterKey: `"launchedDate"`,
      filterType: 'DatePicker',
      displayFormat: 'moment'
    },
    status: {
      key: `"status"`,
      filterKey: '"status"',
      filterType: 'List',
      filterList: [
        { id: '1', name: 'Sent' },
        { id: '2', name: 'Scheduled' },
        { id: '3', name: 'Draft' }
      ]
    },
    'Total Recipients': {
      key: `"totalRecipients"`,
      filterKey: '"totalRecipients"',
      filterType: 'InputNumber'
    }
  },
  'social shares': {
    Homeowner: {
      key: `"homeowners"."firstName"`,
      filterKey: 'function."name"'
    },
    Email: {
      key: `"homeowners"."email"`,
      filterKey: `"homeowners"."email"`
    },
    'Date Shared': {
      key: `social_share."created"`,
      filterKey: `social_share."created"`,
      filterType: 'DatePicker'
    },
    Media: {
      key: `ssl.name`,
      filterKey: 'social_share.method',
      filterType: 'List',
      filterList: [
        { id: 1, name: 'Facebook' },
        { id: 2, name: 'Twitter' },
        { id: 3, name: 'SMS' },
        { id: 4, name: 'whatsApp' }
      ]
    },
    'Page Shared': {
      key: `share`,
      filterKey: 'share'
    }
  },
  referrals: {
    'Referral name': {
      key: 'name',
      filterKey: 'name'
    },
    'Referral email': {
      key: `referrals.email`,
      filterKey: 'referrals.email'
    },
    'Referral number': {
      key: 'referrals.phone',
      filterKey: 'referrals.phone',
      filterType: 'InputNumber'
    },
    'Referred by': {
      key: `"firstName"`,
      filterKey: 'function."name"'
    },
    'Date referred': {
      key: `referrals."created"`,
      filterKey: 'referrals."created"',
      filterType: 'DatePicker'
    }
  },
  surveys: {
    Homeowner: {
      key: `"Homeowner"`,
      filterKey: 'function."name"'
    },
    Email: {
     hideFilterBlock:true
    },
    'NPS survey': {
      filterKey: `data."NPS survey"`,
      key: '"NPS survey"',
      filterType: 'List',
      filterList: [
        { id: 'Yes', name: 'Yes' },
        { id: 'No', name: 'No' }
      ]
    },

    Phase: {
      key: 'survey.phase',
      filterKey: 'hs."phaseId"',
      filterType: 'List',
      filterList: [
        { id: 1, name: 'Design' },
        { id: 2, name: 'Permit' },
        { id: 3, name: 'The Build' },
        { id: 4, name: 'Inspection' },
        { id: 5, name: 'Final' },
        { id: 6, name: 'Solar System' },
        { id: 7, name: 'company_name' },
        { id: 8, name: 'Bodhi App' }
      ]
    },
    'Survey score': {
      key: '"Survey score"',
      filterKey: 'rating',
      filterType: 'InputNumber'
    },
    Date: {
      key: `"creationTime"`,
      filterKey: 'hs."created"',
      filterType: 'DatePicker'
    },
    'Project manager': {
      key: 'installers.name',
      filterKey: 'installers.id',
      filterType: 'List'
    }
  },
  'active accounts': {
    Name: {
      key: `"Name"`,
      filterKey: 'function."name"'
    },
    Email: {
      key: `data."Email"`,
      filterKey: `homeowners.email`,
      filterType: 'Input'
    },
    'Phone number': {
      key: `Phone number`,
      hideFilterBlock: true,
      showSortOrder : 'display'
    },
    Address: {
      hideFilterBlock: true
    },
    'Days without response': {
      key: `"Days without response"`,
      filterKey: 'data."Days without response"',
      filterType: 'InputNumber'
    },
    'Project manager': {
      key: `"Project manager"`,
      filterKey: `"installers".id`,
      filterType: 'List'
    },
    'Date created': {
      key: `"creationTime"`,
      filterKey: `homeowners."created"`,
      filterType: 'DatePicker'
    },
    'Meter Id': {
      hideFilterBlock: true
    },
    Meter: {
      // TODO
      key: `"Meter"`,
      filterKey: `su."meterId"`,
      filterType: 'List',
      filterList: [
        { id: 1, name: 'eGauge' },
        { id: 3, name: 'Enphase' },
        { id: 2, name: 'Solar Edge' },
        { id: 4, name: 'Sense' },
        { id: 5, name: 'APsystems' },
        { id: 6, name: 'Fronius' }
      ]
    },
    Utility: {
      hideFilterBlock: true
    },
    'Utility rate plan': {
      hideFilterBlock: true
    },
    Phase: {
      key: `"Phase"`,
      filterKey: `data."Phase"`,
      filterType: 'List',
      filterList: [
        { id: '1', name: 'Design' },
        { id: '2', name: 'Permit' },
        { id: '3', name: 'The Build' },
        { id: '4', name: 'Inspection' },
        { id: '5', name: 'Powering Up' },
        { id: '6', name: 'Service' },
        { id: '8', name: 'Powered Up' }
      ]
    },
    'Last notification': {
      key: `"lastNotifyTime"`,
      filterKey: `su."updated"`,
      filterType: 'DatePicker'
    },
    Salesperson: {
      key: `"Salesperson"`,
      filterKey: `"homeowners"."salePersonId"`,
      filterType: 'List'
    },
    'Solar sync': {
      hideFilterBlock: true
    },
    'History sync': {
      hideFilterBlock: true
    },
    Default: {
      key: '',
      filterKey: ''
    }
  }
};

class CompanyMetrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCoulmnDisplay: false,
      currentShow: null,
      filterValue: null,
      stateMetricFilterParam: {},
      metricColumnParam: [
        'homeownerId',
        'threadId',
        'Days without response',
        'Name',
        'Phase',
        'Last notification',
        'Email',
        'Phone number',
        'isDisable',
        'Project manager'
      ],
      isShown: false,
      isVisible: true
    };
  }

  handleClick = () => {
    this.setState({
      isShown: !this.state.isShown
    });
  };

  handleCancel = () => {
    this.setState({
      isVisible: !this.state.isVisible
    });
  };

  closeModal = () => {
    this.setState({
      isShown: !this.state.isShown
    });
  };

  componentWillMount() {
    const token = localStorage.getItem('token');
    this.companyId = this.props.router.params.companyId;
    this.decoded = jwtDecode(token);
    if (
      this.decoded.role &&
      (!this.props.getInstaller || JSON.stringify(this.props.getInstaller) === '{}')
    ) {
      this.props.requestInstallerData(this.companyId);
      // this.props.requestCompanyIdData(this.companyId);
    }
    this.slug = this.props.router.params.slug;
  }

  componentDidMount() {
    this.props.getmetricRef && this.props.getmetricRef(this);
  }

  componentWillReceiveProps(nextProps) {
    metricDataKeys.surveys.Phase.filterList[6].name = nextProps.companyName;
    metricDataKeys.campaign.Creator.filterList =
      metricDataKeys.surveys['Project manager'].filterList =
      metricDataKeys['active accounts']['Project manager'].filterList =
      metricDataKeys['active accounts'].Salesperson.filterList =
        nextProps.getInstaller.data && nextProps.getInstaller.data;
  }

  displayMetricsName(metricsType) {
    switch (metricsType) {
      case 'referrals':
        return 'Referrals';

      case 'surveys':
        return 'Surveys';

      case 'social shares':
        return 'Social shares';

      case 'active accounts':
        return 'Active homeowners';

      case 'fleethomeowners':
        return 'Fleet monitoring';

      case 'companies':
        return 'Companies';

      case 'fleet':
        return 'Fleet monitoring';

      case 'sunlightfinancial':
        return 'Active Sunlight Financial homeowners';
    }
  }

  render() {
    const {
      email,
      metricsData,
      metricsType,
      metricsParam,
      changeMetricQueryParam,
      metricFilterParam,
      metricsHeader
    } = this.props;
    const { metricColumnParam, stateMetricFilterParam } = this.state;

    if (!metricsData || !metricsData[0]) {
      if (metricsHeader) {
        const homeownerId_Index = metricsHeader.indexOf('homeownerId');
        if (homeownerId_Index != -1) metricsHeader.splice(homeownerId_Index, 1);

        const companyId_Index = metricsHeader.indexOf('companyId');
        if (companyId_Index != -1) metricsHeader.splice(companyId_Index, 1);

        const isDisable_Index = metricsHeader.indexOf('isDisable');
        if (isDisable_Index != -1) metricsHeader.splice(isDisable_Index, 1);

        const threadId_Index = metricsHeader.indexOf('threadId');
        if (threadId_Index != -1) metricsHeader.splice(threadId_Index, 1);
      }
      return this.emptyResponse(metricsData);
    }

    const tableHeader = Object.keys(metricsData[0]);
    const homeownerId_Index = tableHeader.indexOf('homeownerId');
    if (homeownerId_Index != -1) tableHeader.splice(homeownerId_Index, 1);

    const threadId_Index = tableHeader.indexOf('threadId');
    if (threadId_Index != -1) tableHeader.splice(threadId_Index, 1);

    const companyId_Index = tableHeader.indexOf('companyId');
    if (companyId_Index != -1) tableHeader.splice(companyId_Index, 1);

    const isDisable_Index = tableHeader.indexOf('isDisable');
    if (isDisable_Index != -1) tableHeader.splice(isDisable_Index, 1);

    const campaignId_Index = tableHeader.indexOf('campaignId');
    const campaignFilter_Index = tableHeader.indexOf('filters');
    if (campaignFilter_Index !== -1) {
      tableHeader.splice(campaignFilter_Index, 1);
      tableHeader.splice(campaignId_Index, 1);
    }

    const dataSize = (metricsParam.skip + 1) * metricsParam.limit;
    const previousButtonClass = metricsParam.skip != 0;
    const nextButtonClass = !!(
      metricsParam.count > dataSize && metricsData.length >= metricsParam.limit
    );

    const activeJourney_Index = tableHeader.indexOf('activeJourney');
    if (activeJourney_Index != -1) tableHeader.splice(activeJourney_Index, 1);

    return (
      <div className="metrics-table-box">
        <div className="table-box-header box-Shadow" onClick={this.displaySelectionColumn}>
          <h2 className="table-type">
            {metricsType == 'campaign'
              ? 'Marketing Campaigns'
              : this.displayMetricsName(metricsType)}
          </h2>
          {metricsType == 'campaign' && (
            <div
              className="btn btn-primary mt-2 ml-3"
              onClick={() => {
                this.props.router.navigate(`/${this.slug}/${this.companyId}/campaign/create`);
              }}>
              Add new campaign
            </div>
          )}
          {metricsType == 'active accounts' &&
            window.$productSuite !== 1 &&
            this.selectAccountOption(metricsType, email, metricColumnParam)}
          {metricsType == 'fleet' && this.renderSwitch()}
          {window.$productSuite === 2 && (
            <div className="fleet-modal">
              <GreyStyledButton className="fleet-upload-btn" onClick={this.handleClick}>
                Import systems
              </GreyStyledButton>
              <StyledModal
                title="Import systems"
                open={this.state.isShown}
                onCancel={this.handleClick}
                footer={null}>
                <UploadCsvModal companyId={this.companyId} closeModal={this.closeModal} />
              </StyledModal>
            </div>
          )}
        </div>

        <div className="table-responsive box-Shadow">
          <table className="metrics-table">
            <tbody>
              <tr>
                {this.renderTableHeader(
                  tableHeader,
                  metricsParam.sort,
                  changeMetricQueryParam,
                  metricsType,
                  stateMetricFilterParam,
                  metricFilterParam
                )}
              </tr>
              {metricsData.map((ele, i) => {
                return (
                  <tr key={`${ele.Name}_${i}`} className="metrics-row">
                    {metricsType === 'campaign'
                      ? this.renderCampaignData({ ...ele })
                      : this.renderTableData({ ...ele })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {!this.props.filterHide &&
          !this.props.hidePagination &&
          this.renderPaginationBox(
            previousButtonClass,
            nextButtonClass,
            metricsParam,
            changeMetricQueryParam
          )}
      </div>
    );
  }

  renderSwitch() {
    return (
      <div className="fleet-viewType" id="radios">
        <input
          id="rad1"
          type="radio"
          name="radioBtn"
          onChange={() => this.props.changeState({ mapView: false })}
        />
        <label className={`labels ${!this.state.mapView ? 'checked' : ''}`} htmlFor="rad1">
          List view
        </label>

        <input
          id="rad2"
          type="radio"
          name="radioBtn"
          onChange={() => this.props.changeState({ mapView: true })}
        />
        <label className={`labels ${this.state.mapView ? 'checked' : ''}`} htmlFor="rad2">
          Map view
        </label>
        <div id="bckgrnd" />
      </div>
    );
  }

  selectAccountOption = (metricsType, email, metricColumnParam) => {
    return (
      <div className="column-header btn" onClick={this.displaySelectionColumn}>
        Choose columns
        {this.state.isCoulmnDisplay && (
          <div className="column-box">
            <div className="drop-up-arrow" />
            {Object.keys(metricDataKeys[metricsType]).map((ele, index) => {
              const temp = metricDataKeys[metricsType][ele];
              const isSelected =
                metricColumnParam.indexOf(ele) != -1 ? 'btn-primary' : 'btn-secondary';
              return (
                <div
                  key={index}
                  className={`column-name btn ${isSelected}`}
                  onClick={this.markColumnValue}
                  data-id={ele}
                  data-index={index}>
                  {ele}
                </div>
              );
            })}
            <div
              className="column-name btn link-Color hover-link"
              onClick={() => {
                this.setState({ isCoulmnDisplay: false });
                this.props.changeMetricQueryParam('param', metricColumnParam);
              }}>
              Apply columns
            </div>
          </div>
        )}
      </div>
    );
  };

  emptyResponse = (metricsData) => {
    const {
      installerData,
      email,
      metricsType,
      metricsParam,
      changeMetricQueryParam,
      metricFilterParam,
      metricsHeader
    } = this.props;
    const { metricColumnParam, stateMetricFilterParam } = this.state;
    const count = parseInt(metricsParam.count);

    const hideAddButton =
      installerData && installerData.formSetting && installerData.formSetting.hideAddButton;
    const pageNumber = metricsParam.skip;
    const newHomeownerFormLink = window.$productSuite === 3 ? 'addhomeowner' : 'litenewinstall';

    return (
      <div className="metrics-table-box">
        <div className="table-box-header box-Shadow" onClick={this.displaySelectionColumn}>
          <h2 className="table-type">
            {metricsType == 'campaign'
              ? 'Marketing Campaigns'
              : this.displayMetricsName(metricsType)}
          </h2>
          {metricsType === 'campaign' && (
            <div
              className="btn btn-primary mt-2 ml-3"
              onClick={() => {
                this.props.router.navigate(`/${this.slug}/${this.companyId}/campaign/create`);
              }}>
              Add new campaign
            </div>
          )}
          {window.$productSuite === 2 && (
            <div className="fleet-modal">
              <GreyStyledButton className="fleet-upload-btn" onClick={this.handleClick}>
                Import systems
              </GreyStyledButton>
              <StyledModal
                title="Import systems"
                open={this.state.isShown}
                onCancel={this.handleClick}
                footer={null}>
                <UploadCsvModal companyId={this.companyId} closeModal={this.closeModal} />
              </StyledModal>
            </div>
          )}
        </div>

        {count === 0 && (
          <div className="table-responsive box-Shadow">
            <div className="data-empty">
              <table className="metrics-table">
                <tbody>
                  <tr>
                    <th className="table-header">
                      {metricsType === 'active accounts' && (
                        <>
                          <div>There are no active accounts.</div>
                          {!hideAddButton && (
                            <button
                              style={{ padding: '0 19px' }}
                              className="btn btn-primary mt-3 auto-width"
                              onClick={() => {
                                this.props.router.navigate(
                                  `/${this.slug}/${this.companyId}/${newHomeownerFormLink}`
                                );
                              }}>
                              {' '}
                              + Add homeowner
                            </button>
                          )}
                        </>
                      )}
                      {metricsType === 'referrals' && 'There are no referrals.'}
                      {metricsType === 'fleethomeowners' && 'There are no active accounts.'}
                      {metricsType === 'surveys' && 'There are no survey results.'}
                      {metricsType === 'social shares' && 'There are no social shares.'}
                      {metricsType === 'campaign' && 'There are no campaigns.'}
                      {metricsType === 'fleet' && 'There are no active alerts in your fleet.'}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        {count > 0 && (
          <div className="table-responsive box-Shadow">
            <table className="metrics-table">
              <tbody>
                <tr>
                  {metricsHeader &&
                    this.renderTableHeader(
                      metricsHeader,
                      metricsParam.sort,
                      changeMetricQueryParam,
                      metricsType,
                      stateMetricFilterParam,
                      metricFilterParam
                    )}
                </tr>
              </tbody>
            </table>
            {metricsData && metricsData.length === 0 && (
              <div className="data-empty">No results for this filter</div>
            )}
            {!metricsData && <div className="data-empty">Loading...</div>}
          </div>
        )}
      </div>
    );
  };

  hideTableHeader(metricDataKeys, metricsType, ele) {
    if (!metricDataKeys[metricsType] || !metricDataKeys[metricsType][ele]) return true;
  }

  hideFilterBlock(metricDataKeys, metricsType, ele) {
    const hideFilterBlock =
      (metricDataKeys[metricsType] &&
        metricDataKeys[metricsType][ele] &&
        metricDataKeys[metricsType][ele].hideFilterBlock) ||
      this.props.filterHide;

    if(hideFilterBlock && metricDataKeys?.[metricsType]?.[ele]?.showSortOrder){
      return false;
    }
    return hideFilterBlock;
  }

  renderTableHeader = (
    data,
    sortParam,
    changeMetricQueryParam,
    metricsType,
    stateMetricFilterParam,
    metricFilterParam
  ) => {
    sortParam = sortParam || {};
    return data.map(
      (ele) =>
        !this.hideTableHeader(metricDataKeys, metricsType, ele) && (
          <th className="table-header seprator-right" key={ele}>
            <div className="header-title">{ele}</div>
            {!this.hideFilterBlock(metricDataKeys, metricsType, ele) && (
              <div className="filter-sort-icon">
                <img
                  src={require(`./../../../../Assets/Images/DownArrow_${
                    metricDataKeys[metricsType][ele] &&
                    metricDataKeys[metricsType][ele].key == Object.keys(sortParam)[0] &&
                    Object.values(sortParam)[0] == 'asc'
                      ? 'active'
                      : 'inactive'
                  }.svg`)}
                  className="sort-icon"
                  alt="search"
                  onClick={() =>
                    changeMetricQueryParam('sort', {
                      [metricDataKeys[metricsType][ele].key]: 'asc'
                    })
                  }
                />
                <img
                  src={require(`./../../../../Assets/Images/UpArrow_${
                    metricDataKeys[metricsType][ele] &&
                    metricDataKeys[metricsType][ele].key == Object.keys(sortParam)[0] &&
                    Object.values(sortParam)[0] == 'desc'
                      ? 'active'
                      : 'inactive'
                  }.svg`)}
                  className="sort-icon"
                  alt="search"
                  onClick={() =>
                    changeMetricQueryParam('sort', {
                      [metricDataKeys[metricsType][ele].key]: 'desc'
                    })
                  }
                />
                {
                  ! metricDataKeys?.[metricsType]?.[ele]?.showSortOrder && 
                  <div className="filter-block">
                  <img
                    alt="search"
                    src={require(`./../../../../Assets/Images/filter_${
                      metricFilterParam.hasOwnProperty(
                        metricDataKeys[metricsType][ele] &&
                          metricDataKeys[metricsType][ele].filterKey
                      )
                        ? 'active'
                        : 'inactive'
                    }.svg`)}
                    className="filter-icon"
                    onClick={(e) => {
                      this.displayFilterBox(metricDataKeys[metricsType][ele], e);
                    }}
                  />
                  {this.state.currentShow === metricDataKeys[metricsType][ele] && (
                    <div className="filter-box">
                      <div className="drop-up-arrow" />
                      {this.renderInputType(
                        metricDataKeys[metricsType][ele],
                        stateMetricFilterParam[metricDataKeys[metricsType][ele].filterKey],
                        metricFilterParam[metricDataKeys[metricsType][ele].filterKey],
                        metricDataKeys[metricsType][ele].filterList
                      )}
                      {metricFilterParam.hasOwnProperty(
                        metricDataKeys[metricsType][ele].filterKey
                      ) && (
                        <button
                          className="filter-button cancel"
                          onClick={(e) => {
                            this.updatePropFilterValue(
                              metricDataKeys[metricsType][ele].filterKey,
                              'cancel'
                            );
                          }}>
                          Clear
                        </button>
                      )}
                      <button
                        className="filter-button"
                        onClick={(e) => {
                          this.updatePropFilterValue(
                            metricDataKeys[metricsType][ele].filterKey,
                            'apply'
                          );
                        }}>
                        Apply
                      </button>
                    </div>
                  )}
                </div>
                }
                
              </div>
            )}
          </th>
        )
    );
  };

  renderInputType(filterOption, stateValue, propValue, filterList) {
    const name = filterOption.filterKey;
    const inputType = filterOption.filterType;
    const timeFormat = filterOption.timeFormat ? 1000 : 1;
    const value = stateValue || stateValue == '' ? stateValue : propValue;

    switch (inputType) {
      case 'InputNumber':
        return (
          <>
            <div className="header">Number Filter</div>
            <Input
              type="number"
              className="form-control input"
              pattern="[0-9]*"
              value={value}
              name={name}
              onChange={(e) => {
                this.changeQueryParam(inputType, name, e.target.value * 1);
              }}
            />
          </>
        );

      case 'DatePicker':
        return (
          <>
            <div className="header">To</div>
            <DatePicker
              className="form-control input"
              name={name}
              value={value && value.startDate && moment(value.startDate * timeFormat)}
              format="YYYY/MM/DD"
              onChange={(date) => {
                this.changeQueryParam(inputType, name, {
                  startDate: date.startOf('day').utc().valueOf() / timeFormat
                });
              }}
            />
            <div className="header">From</div>
            <DatePicker
              className="form-control input"
              name={name}
              value={value && value.endDate && moment(value.endDate * timeFormat)}
              onChange={(date) => {
                this.changeQueryParam(inputType, name, {
                  endDate: date.endOf('day').utc().valueOf() / timeFormat
                });
              }}
            />
          </>
        );

      case 'List':
        return (
          <>
            <div className="header">Select Any</div>
            <Select
              suffixIcon={<CaretIcon />}
              mode="multiple"
              style={{ width: '100%', padding: ' 0 10px', display: 'flow-root' }}
              placeholder="Click....."
              value={value}
              className="list-box"
              onChange={(v) => {
                this.changeQueryParam(inputType, name, v);
              }}>
              {filterList &&
                filterList.map((ele, i) => {
                  return (
                    <Option key={i} value={ele.id}>
                      {' '}
                      {ele.name ? ele.name : ele.email}
                    </Option>
                  );
                })}
            </Select>
          </>
        );

      default:
        return (
          <>
            <div className="header">Text Filter</div>
            <Input
              className="form-control input"
              value={value}
              name={name}
              onChange={(e) => {
                const v = e.target.value;
                this.changeQueryParam(inputType, name, v.toLowerCase());
              }}
            />
          </>
        );
    }
  }

  renderTableData = (data) => {
    const { metricsHeader, metricsType } = this.props;
    const { isDisable } = data;
    const { homeownerId } = data;
    const { threadId } = data;
    const { companyId } = data;
    const { activeJourney } = data;
    delete data.isDisable;
    delete data.homeownerId;
    delete data.threadId;
    delete data.companyId;
    delete data.activeJourney;
    const values = Object.values(data);
    const keys = Object.keys(data);
    const homeowner_link =
      keys.indexOf('Homeowner') != -1
        ? keys.indexOf('Homeowner')
        : keys.indexOf('Name') != -1
        ? keys.indexOf('Name')
        : keys.indexOf('Referred by');
    const number_index =
      keys.indexOf('Phone number') != -1
        ? keys.indexOf('Phone number')
        : keys.indexOf('Referral number');
    const companyId_index = keys.indexOf('Company') != -1 ? keys.indexOf('Company') : null;
    const phase_index = keys.indexOf('Phase') != -1 ? keys.indexOf('Phase') : null;
    const days_without_response_index =
      keys.indexOf('Days without response') != -1 ? keys.indexOf('Days without response') : null;
    const alert_index =
      keys.indexOf('Alert severity') != -1 ? keys.indexOf('Alert severity') : null;
    const monitoring_system =
      keys.indexOf('Monitoring system') != -1 ? keys.indexOf('Monitoring system') : null;

    const financial_status = keys.indexOf('Status') != -1 ? keys.indexOf('Status') : null;
    const financial_statusName = keys.indexOf('Milestone') != -1 ? keys.indexOf('Milestone') : null;

    return values.map((ele, index) => {

      if (metricDataKeys[this.props.metricsType][keys[index]]?.displayFormat === 'moment') {
        return (
          <td key={index} className="metrics-data">
            {ele && moment(parseInt(ele)).format('MMM DD, YYYY')}
          </td>
        );
      }

      ele = number_index != 1 && index == number_index && ele ? this.maskContactNumber(ele) : ele;
      if (homeowner_link == index && !isDisable) {
        let homeowner_link_url;
        if(threadId){
          homeowner_link_url = `${window.location.origin}/${this.slug}/${this.companyId}/inbox/${threadId || homeownerId}`;
        } else {
          homeowner_link_url = `${window.location.origin}/${this.slug}/${this.companyId}/dashboard/${homeownerId}/homeowner`;
        }
        return (
          <td className="metrics-data" data-key={`${ele.toString()}${index}`}>
            <a
              href={homeowner_link_url}
              target="_blank"
              rel="noreferrer">
              {ele}
            </a>
          </td>
        );
      }
      if (companyId_index == index) {
        return (
          <td data-key={index} className="metrics-data">
            <a
              href={`${window.location.origin}/admin/${companyId}/companysetting`}
              target="_blank"
              rel="noreferrer">
              {ele}
            </a>
          </td>
        );
      }
      if (phase_index == index && ele) {
        return (
          <td data-key={index} className="metrics-data">
            {this.displayPhaseName(ele, activeJourney || 'default')}
          </td>
        );
      }
      if (index === alert_index) {
        return (
          <td className="metrics-data" data-key={ele}>
            <div className={'alert_severity ' + ele}></div>
          </td>
        );
      }
      if (metricsType.includes("fleet") && index === monitoring_system) {
        return <td data-key={ele}>{monitoringSystemData(ele, data)}</td>;
      }
      if (metricsType == "sunlightfinancial" && index === financial_status) {
        return <td data-key={ele}>{this.sunlightMileStoneStatus(ele)}</td>;
      }
      if(index === financial_statusName){
        return <td data-key={ele}>{sunlightMileStoneName(parseInt(ele))}</td>;
      }
      if (index === days_without_response_index) {
        return (
          <td data-key={index} className="metrics-data">
            {!ele || ele < 1 ? 0 : ele}
          </td>
        );
      }
      if (
        !metricsHeader ||
        (Array.isArray(metricsHeader) && metricsHeader && metricsHeader.indexOf(keys[index]) != -1)
      )
        return (
          <td className="metrics-data" data-key={`${index}`}>
            {ele}
          </td>
        );
    });
  };

  sunlightMileStoneStatus = (value) => {
    const sunlightMileStoneStatus = SUNLIGHT_STATUS_DETAIL[parseInt(value)];
    if(!sunlightMileStoneStatus){
      return;
    }

    if (sunlightMileStoneStatus.status === 'error') {
      return (
        <td className="metrics-data error" data-key={`${value}`}>
          <i className="fa fa-exclamation-circle fa-icon" aria-hidden="true" />
          {sunlightMileStoneStatus.name}
        </td>
      );
    }
    return (
      <td className="metrics-data success" data-key={`${value}`}>
        <i className="fa fa-check-circle fa-icon" aria-hidden="true" />
        {sunlightMileStoneStatus.name}
      </td>
    );
  };

  displayPhaseName = (phaseValue, activeJourney) => {
    let phaseName = '';
    switch (phaseValue) {
      case 1:
        phaseName = 'Design';
        break;
      case 2:
        phaseName = 'Permit';
        break;
      case 3:
        phaseName = 'The Build';
        break;
      case 4:
        phaseName = 'Inspection';
        break;
      case 5:
        phaseName = 'Powering Up';
        break;
      case 8:
        phaseName = 'Powered Up';
        break;
      default:
        phaseName = 'Service';
    }

    const key = phaseName.toLowerCase().replace(/ /g, '');
    const journey = this.props.companyData && this.props.companyData.journey;
    const journeyDetail =
      journey &&
      journey.filter((ele) => {
        return activeJourney == ele.s3Key;
      })[0];

    if (journeyDetail && journeyDetail.journeyPhase && journeyDetail.journeyPhase[key]) {
      return journeyDetail.journeyPhase[key].name || phaseName;
    }
    return phaseName;
  };

  renderCampaignData = (data) => {
    const { campaignId } = data;
    const campaignStatus = data.status;

    const content = (
      <div className="action-option">
        <div
          className="action-name"
          onClick={() => {
            if (this.companyId) {
              this.props.campaignDashboardAction('delete', campaignId);
            }
          }}>
          Delete
        </div>
        <div
          className="action-name"
          onClick={() => {
            if (this.companyId) {
              this.props.campaignDashboardAction('clone', campaignId);
            }
          }}>
          Clone
        </div>
      </div>
    );

    // delete campaignObject Data
    delete data.filters;
    delete data.campaignId;

    const values = Object.values(data);
    const keys = Object.keys(data);
    const campaign_index =
      keys.indexOf('campaign Name') != -1 ? keys.indexOf('campaign Name') : null;
    const status_index = keys.indexOf('status') != -1 ? keys.indexOf('status') : null;
    return values.map((ele, index) => {
      if (metricDataKeys[this.props.metricsType][keys[index]].displayFormat === 'moment') {
        return (
          <td key={index} className="metrics-data">
            {ele && moment(parseInt(ele)).format('MMM DD, YYYY')}
          </td>
        );
      }
      if (campaign_index == index && ele && campaignId) {
        return (
          <td key={index} className="metrics-data">
            <a
              target="_blank"
              className="link-Color"
              onClick={() => {
                this.props.campaignLink(campaignStatus, campaignId);
              }}>
              {ele}
            </a>
          </td>
        );
      }
      if (status_index === index && ele) {
        return (
          <React.Fragment key={index}>
            <td className="metrics-data">
              <a
                target="_blank"
                onClick={() => {
                  this.props.campaignLink(campaignStatus, campaignId);
                }}>
                {this.props.campaignStatus(ele)}
              </a>

              <span className="text-right">
                <Popover
                  overlayClassName="campaign-status-action"
                  placement="rightBottom"
                  trigger="click"
                  content={content}>
                  <span className="action-btn">...</span>
                </Popover>
              </span>
            </td>
          </React.Fragment>
        );
      }
      return (
        <td key={index} className="metrics-data">
          {ele}
        </td>
      );
    });
  };

  renderPaginationBox = (
    previousButtonClass,
    nextButtonClass,
    metricsParam,
    changeMetricQueryParam
  ) => {
    const { metricsData } = this.props;
    const onlyDisplayCount = window.location.pathname.includes('fleet');

    return (
      <div className="pagination-box">
        {onlyDisplayCount ? (
          <div className="pagination-link-button">
            {/* <div className="page-number">{(metricsData && metricsData.length) || 0} alerts</div> */}
          </div>
        ) : (
          <>
            <div className="pagination-link-button">
              <div
                className={`link-button ${previousButtonClass}`}
                onClick={(e) => {
                  if (!e.target.className.includes('inactive')) changeMetricQueryParam('skip', -1);
                }}>
                {previousButtonClass && <img src={leftArrowActiveIcon} alt="search" />}
              </div>

              <div className="page-number">{this.displayCount(metricsParam)}</div>

              <div
                className={`link-button ${nextButtonClass}`}
                onClick={(e) => {
                  if (!e.target.className.includes('inactive')) changeMetricQueryParam('skip', 1);
                }}>
                {nextButtonClass && (
                  <img
                    src={rightArrowActiveIcon}
                    alt="search"
                    className="rightIcon rightArrow_active"
                  />
                )}
              </div>
            </div>
            <div className="pagination-limit-button">
              <Select
                suffixIcon={<CaretIcon />}
                value={metricsParam.limit ? metricsParam.limit.toString() : '25'}
                className="pagination-option"
                onChange={this.handlePaginationChange}>
                <Option value="25">25 per page</Option>
                <Option value="50">50 per page</Option>
                <Option value="100">100 per page</Option>
              </Select>
            </div>
          </>
        )}
      </div>
    );
  };

  renderSelectIcon() {
    return <img src={rightArrowActiveIcon} alt="search" className="rightIcon rightArrow_active" />;
  }

  displayCount(metricsParam) {
    const temp = metricsParam.count < 2 ? 'result' : 'results';
    const skip = metricsParam.skip ? metricsParam.skip + 1 : 1;
    const endText = `of ${metricsParam.count} ${temp}`;
    const startCount = metricsParam.skip ? metricsParam.limit * metricsParam.skip : 1;
    const endCount = skip * metricsParam.limit;
    return `${startCount}-${
      endCount > metricsParam.count ? metricsParam.count : endCount
    } ${endText}`;
  }

  displaySelectionColumn = (e) => {
    e.stopPropagation();
    if (e.target.className == 'column-header btn') {
      this.setState({ isCoulmnDisplay: !this.state.isCoulmnDisplay });
    } else if (!e.target.className.includes('column-name')) {
      this.setState({ isCoulmnDisplay: false });
    }
  };

  markColumnValue = (e) => {
    let { metricColumnParam } = this.state;
    const value = e.target.getAttribute('data-id');
    const operation = e.target.className.includes('btn-primary');
    if (value == 'Default')
      metricColumnParam = [
        'homeownerId',
        'threadId',
        'Days without response',
        'Name',
        'Phase',
        'Last notification',
        'Email',
        'Phone number',
        'Address',
        'isDisable'
      ];
    else if (operation == true) {
      const i = metricColumnParam.indexOf(value);
      metricColumnParam.splice(i, 1);
    } else {
      metricColumnParam.push(value);
    }
    this.setState({ metricColumnParam });
  };

  handlePaginationChange = (value) => {
    this.props.changeMetricQueryParam('limit', value);
  };

  displayFilterBox = (value, event) => {
    if (event) event.stopPropagation();
    this.setState({ currentShow: this.state.currentShow ? null : value });
  };

  changeQueryParam = (inputType, key, value) => {
    const stateObj = this.state.stateMetricFilterParam;
    let obj;

    switch (inputType) {
      case 'DatePicker':
        obj = { [key]: value };
        if (stateObj[key]) {
          const temp = Object.assign(stateObj[key], obj[key]);
          stateObj[key] = temp;
        } else Object.assign(stateObj, obj);
        break;
      case 'InputNumber':
        value *= 1;
      default:
        obj = {
          [key]: value
        };
        Object.assign(stateObj, obj);
        break;
    }

    if (key) {
      this.setState({ stateMetricFilterParam: stateObj });
    }
  };

  updatePropFilterValue = (id, operation) => {
    const { stateMetricFilterParam } = this.state;
    let { metricFilterParam, metricsType } = this.props;

    switch (operation) {
      case 'apply':
        Object.assign(metricFilterParam, stateMetricFilterParam);
        this.props.changeMetricQueryParam('filter', metricFilterParam);
        this.displayFilterBox();
        break;

      case 'cancel':
        delete stateMetricFilterParam[id];
        if (!metricFilterParam) {
          metricFilterParam = {};
        } else {
          delete metricFilterParam[id];
        }
        Object.assign(stateMetricFilterParam, metricFilterParam);
        this.props.changeMetricQueryParam('filter', metricFilterParam);
        this.displayFilterBox();
    }
  };

  maskContactNumber(number) {
    const {
      searchData: { data }
    } = this.props;
    return (
      <NumberFormat
        type="tel"
        value={number}
        format={data && data.formSetting && data.formSetting.phone}
        disabled
        className="border-0"
      />
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    companyData: state.addhomeOwner.companyId && state.addhomeOwner.companyId.data,
    getInstaller: state.addhomeOwner.getInstaller,
    installerData: state.header.getInstaller.data || {}
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestInstallerData,
      requestCompanyIdData
    },
    dispatch
  );

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(CompanyMetrics));
