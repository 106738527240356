export const REQUEST_COMPANY_FLEETDATA = "REQUEST_COMPANY_FLEETDATA";
export const RECEIVE_COMPANY_FLEETDATA = "RECEIVE_COMPANY_FLEETDATA";

export const requestCompanyFleetData = data => ({
	type: REQUEST_COMPANY_FLEETDATA, data
});

export const receiveCompanyFleetData = data => ({
	type: RECEIVE_COMPANY_FLEETDATA, data
});
