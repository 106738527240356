import { Services } from '../../../Api/services';
import * as config from '../../../shared/config';

export const searchApi = async (data) => {
  const url = `${config.HOME_OWNER_BASE_URL}company/${data.companyId}/homeowner?search=${data.value}&installerId=${data.installerId}`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const getInstallerByIdApi = async (data) => {
  const url =
    config.INSTALLER_BASE_URL + 'company/' + data.companyId + '/installers/' + data.installerId;
  let res = await Services.getCall(url, true);
  window.$productSuite = res.data?.productSuite;
  return { data: res.data, status: res.status };
};

export const getHomeOwnerByIdApi = async (data) => {
  const url = config.HOME_OWNER_BASE_URL + data.companyId + '/homeowner/' + data.installerId;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};
