import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import {salesforceGuide} from './salesforceGuide'

const mapStatetoProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
  }, dispatch)

)

export default connect(mapStatetoProps, mapDispatchToProps)(salesforceGuide)
