import styled, { keyframes } from 'styled-components';

const StyledMessageComposerControls = styled.div`
  img.tooltip-info {
    cursor: pointer;
    height: auto;
    width: 16px;
    padding-left: 3px;
    padding-bottom: 1px;
  }

  .max-length-warning {
    width: 100%;
    display: inline-flex;
    align-items: flex-end;
    height: 100%;
    justify-content: flex-end;
    padding-right: 12px;
    padding-bottom: 5px;
    color: ${(props) => props.theme.colors.ltgrey4};
    .composer-warning-text {
      color: ${(props) => props.theme.colors.orange};
    }
  }

  .pad-right {
    padding-right: 4px;
  }

  .buttons {
    button {
      float: right;
      width: 100px !important;
      font-family: ${(props) => props.theme.fonts.standardBold};
      border: 1px solid ${(props) => props.theme.colors.yellow};
      border-radius: ${(props) => props.theme.borderRadius.standard};
      &:disabled {
        border-color: ${(props) => props.theme.colors.greyMed};
        color: ${(props) => props.theme.colors.ltgrey4};
        &:hover {
          cursor: not-allowed;
        }
      }
      &.submit-send {
        background-color: ${(props) => props.theme.colors.yellow};
        color: ${(props) => props.theme.colors.blackMed};
        &:hover {
          background-color: ${(props) => props.theme.colors.white};
        }
        &:disabled {
          background-color: ${(props) => props.theme.colors.greyMed};
          color: ${(props) => props.theme.colors.ltgrey4};
        }
      }
    }
  }
`;

const StyledAddTemplate = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 15px;
  margin-right: 23px;
  color: #7a7a7a;
  .add-template {
    width: 20px;
    margin-right: 10px;
  }
  .file-upload {
    position: absolute;
    opacity: 0;
    width: 130px;
    cursor: pointer;
    left: 0;
  }
`;

const StyledImagePreviewBlock = styled.div`
  display: inline-flex;
  &.composer {
    display: block;
  }
  .image-preview {
    width: 50px;
    height: 50px;
    background-size: cover;
    margin-right: 20px;
    padding: 5px;
    position: relative;
    margin-bottom: 7px;
    float: left;
    &.pdf {
      padding: 5px;
      float: left;
      margin-right: 0;
    }
    &.zoom:hover {
      transform: scale(1.5);
    }
  }
  .white-text {
    color: white;
  }
`;

const StyledPdfFileName = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: inherit;
  line-height: 50px;
  float: right;
  max-width: 150px;
`;

const StyledFileRemove = styled.a`
  height: 20px;
  width: 20px;
  text-align: center;
  right: -7px;
  position: absolute;
  top: -7px;
  border-radius: 50%;
  background-color: #d9d9d9;
`;

const StyledPdfPreviewBlock = styled.div`
  background: rgb(244, 247, 250);
  border-radius: 4px;
  padding-right: 7px;
  max-width: 200px;
  width: auto;
  margin-right: 10px;
  position: relative;
  margin-top: 10px;
  display: flex;
  float: left;
`;

const StyledLoaderAnimation = styled.img`
  height: 20px;
  width: 20px;
  text-align: center;
  right: -7px;
  position: absolute;
  top: -7px;
  &.animation {
    animation-name: ${keyframes`
    100% { -moz-transform: rotate(360deg); }
    100% { -webkit-transform: rotate(360deg); }
    `};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
`;

export {
  StyledAddTemplate,
  StyledFileRemove,
  StyledImagePreviewBlock,
  StyledLoaderAnimation,
  StyledMessageComposerControls,
  StyledPdfFileName,
  StyledPdfPreviewBlock
};
