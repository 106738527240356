import {
	REQUEST_CREATE_EVERSIGN_DOCUMENT,
	RECEIVE_CREATE_EVERSIGN_DOCUMENT,
	REQUEST_GET_EVERSIGN_DATA,
	RECEIVE_GET_EVERSIGN_DATA
} from "../types/eversign/eversign";

export const requestCreateEversignDocument = data => ({
	type: REQUEST_CREATE_EVERSIGN_DOCUMENT, data
});

export const receiveCreateEversignDocument = data => ({
	type: RECEIVE_CREATE_EVERSIGN_DOCUMENT, data
});

export const requestGetEversignData = data => ({
	type: REQUEST_GET_EVERSIGN_DATA, data
});

export const receiveGetEversignData = data => ({
	type: RECEIVE_GET_EVERSIGN_DATA, data
});