import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserAvatar from 'react-user-avatar';

import { SearchGlobal } from '../../../Common';
import Footer from '../footer';

import * as config from '../../../../shared/config';
import {
  withRouter,
  getToken,
  jwtDecode,
  resetInstallerClasses,
  clearUserClassnames
} from '../../../../shared';
import GoogleDriveSync from '../../../../shared/GoogleDriveSync';
import { updateRefreshToken } from '../../../../shared/updateRefreshToken';
import { convertJSON2CSS } from '../../../User/components/setUserBackgroud';

import { settingsIconSvg, logoutIconSvg, alertIconSvg } from '../../../../constants/icons';

class HeaderComponent extends React.Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.installerData = {};
    this.option = [];
    this.data = {};
    this.GoogleDriveSync = new GoogleDriveSync();
    this.image = '';

    this.state = {
      active: false,
      isMount: false,
      searchID: '',
      firstName: '',
      isProfileCreated: false,
      settingLink: '',
      companySettingLink: '',
      companySettingMobLink: '',
      isShow: false
    };
    this.onSelect = this.onSelect.bind(this);
  }

  get token() {
    return getToken();
  }

  componentDidUpdate(prevProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      let { isShow, active, searchID, firstName } = this.state;
      if (isShow || active) {
        isShow = false;
        active = false;
      }
      if (
        !this.props.router.location.pathname.includes('/homeowner') &&
        (searchID !== '' || firstName !== '')
      ) {
        searchID = '';
        firstName = '';
      }
      this.setState({
        isShow,
        active,
        searchID,
        firstName
      });
      document.body.classList.remove('overflowHidden');
    }
  }

  checkTokenInterval() {
    const token = getToken();
    const expTime =
      this.installerData && this.installerData.exp ? Number(`${this.installerData.exp}000`) : 0;
    const remainingTime = expTime - new Date().getTime() - 60000;
    if (!expTime || new Date().getTime() > expTime || remainingTime < 1) {
      this.signOut();
    } else {
      const timeInterval = localStorage.getItem('timeInterval');
      if (timeInterval) {
        clearInterval(timeInterval);
      }
      this.countdown = setInterval(() => {
        this.refreshToken(token);
      }, remainingTime);
      localStorage.setItem('timeInterval', this.countdown);
    }
  }

  async componentDidMount() {
    const token = getToken();
    this.installerData = jwtDecode(token);
    this.companyId = this.props.router.params.companyId || this.installerData.companyId;
    this.slug = this.props.router.params.slug || this.installerData.slug;

    this.data = {
      companyId: this.companyId,
      installerId: this.installerData.installerId || 1
    };
    this.slug = this.props.router.params.slug || this.installerData.slug;
    this.checkTokenInterval();
    await convertJSON2CSS(this.slug, 'installer',this.installerData.email);

    this.props.requestCompanyDetail({
      companyId: this.companyId,
      slug:this.slug
    });

    if (token) this.props.requestInstallerDataById(this.data);
  }

  componentWillReceiveProps(nextProps, nextstate) {
    const { installerData, router } = nextProps;
    if (installerData && installerData?.data) {
      if (
        !installerData.data.isProfileCreated &&
        router.location.pathname !== `/${this.slug}/${installerData.data.companyId}/setting`
      ) {
        router.navigate(`/${this.slug}/${installerData.data.companyId}/setting`);
      }
      this.setState({
        isProfileCreated: installerData.data.isProfileCreated,
        settingLink: `/${this.slug}/${installerData.data.companyId}/setting`,
        companySettingLink: `/${this.slug}/${installerData.data.companyId}/dashboard`,
        companySettingMobLink: `/${this.slug}/${installerData.data.companyId}/companysetting`,
        installerId: installerData.data.id
      });
      
      if(window.$productSuite===1 && !nextProps.companyJourney[0] && !this.state.fetchCompanyJourney){
        this.setState({fetchCompanyJourney:true},()=>{
          const data = {
            toolSearch: true,
            type:'companyJourney',
            fileName:'default',
            companyId: this.companyId,
            slug:this.slug
          }
          this.props.requestCompanyJourney(data);
        })
      }

    }

    if (nextProps?.image?.status === 200 && nextProps !== this.props) {
      this.image = nextProps?.image?.data?.logo;
    }
  }

  onSelect = (option) => {
    const { installerData, router } = this.props;
    const { firstName, id: searchID } = JSON.parse(option.value);
    this.setState(
      {
        searchID,
        firstName
      },
      () => {
        this.props.receiveSearchData({});
        localStorage.removeItem('pathname');
        installerData && installerData?.data?.isProfileCreated
          ? firstName &&
            router.navigate(`/${this.slug}/${this.companyId}/dashboard/${searchID}/homeowner`, {
              firstName
            })
          : this.state.settingLink && router.navigate(this.state.settingLink);
      }
    );
  };

  refreshToken = async () => {
    const { auth, installerData } = this.props;
    if (installerData && installerData.data && installerData.data.email) {
      await updateRefreshToken(installerData.data.email, auth.info.refreshToken);
    }
  };

  signOut = () => {
    const slug = localStorage.getItem('slug');
    this.props.router.navigate(`/${slug}`);
    const timeInterval = localStorage.getItem('timeInterval');
    if (timeInterval) {
      clearInterval(timeInterval);
    }
    localStorage.clear();
    localStorage.setItem('slug', slug);
    localStorage.setItem('token', '');
    this.GoogleDriveSync.handleSignoutClick();
    this.props.signOut();
    document.body.classList.add('bg-color');
    resetInstallerClasses();
  };

  coloapsable = () => {
    this.setState({
      active: !this.state.active,
      isMount: !this.state.isMount,
      isShow: !this.state.isShow
    });
    document.body.classList.toggle('overflowHidden');
  };

  renderInstallerInital(installerData) {
    let photo = installerData?.data?.photo;
    if (photo && photo.includes('installer-avatar')) {
      photo = null;
    }
    const installerName = installerData?.data?.name || installerData?.data?.companyName;
    return photo ? (
      <img className="dashboard-profile-image" src={photo} alt="profile-pic" />
    ) : (
      <UserAvatar
        className="dashboard-profile-image premiumIconBackGround"
        size="40"
        name={installerName || 'Installer'}
      />
    );
  }

  render() {
    const { installerData, router } = this.props;

    const newHomeownerFormLink = window.$productSuite === 3 ? 'addhomeowner' : 'litenewinstall';

    if (!installerData || !installerData.data) {
      return <></>;
    }

    const hideAddButton = installerData?.data?.formSetting?.hideAddButton;

    if (!this.token) {
      return null;
    }

    return (
      <>
        <div className="nav-header-wrapper dashboard-nav-wrapper position-fixed">
          {!this.installerData.companyId && (
            <div className="admin-view">
              Admin View
              <i
                className="close-icon fa fa-times"
                aria-hidden="true"
                onClick={() => {
                  this.props.setMetricsType(null);
                  clearUserClassnames();
                  router.navigate(`/admin/${this.companyId}/companysetting`);
                }}
              />
            </div>
          )}
          <nav id="installerHeader" className="navbar navbar-expand-lg navbar-light bg-light">
            {this.installerData.role &&
              !this.installerData.role.includes('admin') &&
              (installerData?.data?.isProfileCreated ? (
                <a
                  className="navbar-brand default"
                  onClick={() => {
                    this.props.setMetricsType(null);
                    router.navigate(`/${this.slug}/${this.companyId}/dashboard`);
                  }}
                />
              ) : (
                <a className="navbar-brand default" />
              ))}
            <button
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.coloapsable.bind(this)}
              className={`navbar-toggler ${this.state.active ? 'cross-icon' : ''}`}>
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={
                this.state.isShow ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'
              }
              id="navbarSupportedContent">
              <div className="nav-item dashboar-search">
                <SearchGlobal handleSelectResult={this.onSelect} />
              </div>

              <ul className="mr-auto display-none"> </ul>
              <div className="my-2 my-lg-0">
                <ul className="navbar-nav">
                  {!hideAddButton && window.$productSuite !==2 && (
                    <li
                      className="nav-item nav-add-link"
                      onClick={() => {
                        installerData?.data?.isProfileCreated && installerData?.data?.id
                          ? router.navigate(
                              `/${this.slug}/${this.companyId}/${newHomeownerFormLink}`
                            )
                          : this.state.settingLink && router.navigate(this.state.settingLink);
                      }}>
                      <a className="nav-link addhomeowner">
                        + <span>Add homeowner </span>
                      </a>
                    </li>
                  )}

                  <li className="nav-item dropdown">
                    <a
                      className={`nav-link dropdown-toggle ${
                        !this.installerData.companyId ? 'disabled' : ''
                      }`}
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-disabled
                      onClick={() => {
                        this.setState({
                          isShow: !this.state.isShow
                        });
                      }}>
                      {this.renderInstallerInital(installerData)}
                      <span className="dashboard-profile-name">
                        {installerData?.data?.companyName || ''}
                      </span>
                    </a>
                    <div
                      className={`dropdown-menu box-Shadow ${this.state.isShow ? 'show' : ''}`}
                      aria-labelledby="navbarDropdown">
                      <div className="drop-up-arrow" />
                      <div className="media">
                        <div>
                          <h5 className="mt-0">
                            <a>{installerData?.data?.name || ''}</a>
                          </h5>
                          <a>{installerData?.data?.email || ''}</a>
                        </div>
                      </div>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          installerData?.data &&
                            this.state.settingLink &&
                            router.navigate(this.state.settingLink);
                        }}>
                        <span>Profile settings</span>
                      </a>

                      <a className="dropdown-item" onClick={this.signOut}>
                        <span>Log out</span>
                      </a>
                    </div>
                  </li>
                </ul>

                <div className="row">
                  <div className="menu-mobile col-12">
                    <div className="media">
                      <h5>MENU OPTIONS</h5>
                      {this.renderInstallerInital(installerData)}
                      <div className="media-body">
                        <h5 className="mt-0 mb-0">
                          <a className="font-weight-400 font-size18">
                            {installerData?.data?.name || ''}
                          </a>
                        </h5>
                        <a className="font-weight-300 ">{installerData?.data?.email || ''}</a>
                      </div>
                    </div>
                    <div className="row margt60 setting-mob">
                      <a
                        className="setting dropdown-item font-weight-400 font-size18"
                        onClick={() => {
                          installerData &&
                            this.state.settingLink &&
                            router.navigate(this.state.settingLink);
                          const currentState = this.state.active;
                          this.setState({ active: !currentState, isShow: false });
                          document.body.classList.remove('overflowHidden');
                        }}>
                        <span className="drop-icon">
                          <img src={settingsIconSvg} alt="setting-icon" />
                        </span>
                        <span className="setting-gray">Personal Settings</span>
                      </a>
                    </div>
                    <div className="row margt60 setting-mob logout ">
                      <a
                        className="dropdown-item font-weight-400 font-size18"
                        onClick={this.signOut}>
                        <span className="drop-icon">
                          <img src={logoutIconSvg} alt="logout-icon" />
                        </span>
                        <span className="setting-gray"> Log out</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <Footer {...this.props} />
      </>
    );
  }
}

export default withRouter(HeaderComponent);
