import { Services } from "../../../Api/services";
import * as config from "../../../shared/config";

export const getReferralDetailsApi = async data => {
    const url = `${config.REFERRAL_BASE_URL}referral/${
        data.referralId
        }?page=${data.shareType}`;

    let res = await Services.getCall(url, false);
    return { data: res.data };
};

export const getReferralNeighbourApi = async data => {
    let url = `${config.REFERRAL_BASE_URL}referral/${data.referralId}/neighbour?page=${data.shareType}`;
    if(data.lat && data.lng){
        url += `&lat=${data.lat}&lng=${data.lng}`;
      }
    let res = await Services.getCall(url, false);
    return { data: res.data };
};

export const postReferralDetailsApi = async data => {
    const url = `${config.REFERRAL_BASE_URL}referral/company/${
        data.companyId
        }/homeowner/${data.homeownerId}`;

    var bodyData = {
        "installerId": data.installerId,
        "method": data.method,
        'referralContact' : data.referralContact,
        "phone": data.phone,
        "name" : data.name,
        "email" : data.email,
        "isLead": data.isLead,
        "shared": data.share,
        "projected_saving": data.projectedSaving,
        "homeowner_firstname": data.homeownerFirstname,
        "solar_company": data.solarCompany,
        "solar_families_count": data.solarFamiliesCount ? data.solarFamiliesCount : data.totalNeighbour,
        "powered": data.powered,
        "map_url": data.mapUrl,
        "base_url": data.baseUrl,
        "companyPhone": data.companyPhone,
        "address": data.address,
        "homeSize": data.homeSize
    }

    let res = await Services.postCall(url, bodyData, false);
    return { data: res };
};

export const postAddressDetailsApi = async data => {
    const url = `${config.ENERGY_REPORT_BASE_URL}homeowner/oncor`;
    var bodyData = {
        "path": data.path
    }
    let res = await Services.postCall(url, bodyData, false);
    return { data: res };
};