// import npm packages
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Slider, Input } from 'antd';
import withRouter from '../../../../shared/withRouter';
import { openNotificationWithIcon } from '../../../../shared/notification';
import { setLoader } from '../../../Common/Loader/action';
import {
  requestStatusFeedPhaseSurvey,
  receiveStatusFeedPhaseSurvey
} from '../../actions/statusFeed';

const FormItem = Form.Item;
const { TextArea } = Input;

class SurveyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 3,
      finalRating: 3
    };
    this.slug = this.props.slug;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.statusFeedPhaseSurvey &&
      nextProps.statusFeedPhaseSurvey.status &&
      nextProps.statusFeedPhaseSurvey.status === 200
    ) {
      this.setState({ rating: 3 });
      openNotificationWithIcon('success', 'Survey submitted successfully', 'Success', 5);
      // this.state.finalRating < 3 ?
      // nextProps.router.navigate(`/${this.slug}/dashboard`) // this line would jump the status feed back to the top. We can uncomment if we want
      // : nextProps.router.navigate(`/${this.slug}/solarCommunity`)
      nextProps.receiveStatusFeedPhaseSurvey({});
    }
  }

  // submit slider form
  handleSubmit = (values, phase) => {
    const { loggedInUser } = this.props;
    const homeownerData = loggedInUser && loggedInUser.homeownerData;
    const installerId = homeownerData && homeownerData.installerId;

    const tempData = [];
    if (phase && phase.survey) {
      let totalRating = 0;
      phase.survey.forEach((data) => {
        totalRating += values[`rating${data.phaseId}`];
        tempData.push({
          phaseId: data.phaseId,
          rating: values[`rating${data.phaseId}`],
          installerId
        });
      });
      this.setState({
        finalRating: totalRating / phase.survey.length
      });
    }
    this.props.setLoader({ loader: true });
    this.props.receiveStatusFeedPhaseSurvey({});
    this.props.requestStatusFeedPhaseSurvey({
      companyId: homeownerData.companyId,
      homeownerId: homeownerData.id,
      phase: tempData
    });
  };

  // render phase surve html
  renderPhaseServe(phase) {
    const { loggedInUser, customTouchData } = this.props;
    const { rating } = this.state;
    return (
      <div className="paddall25">
        <div className="phase-survey-title">{`How has your 
       ${
         window.$productSuite === 3
           ? `through ${customTouchData}` && customTouchData.get('phaseName')
           : ''
       }
       experience  been so far?`}</div>
        <Form onFinish={(values) => this.handleSubmit(values, phase)}>
          {phase &&
            phase.survey &&
            phase.survey.length !== 0 &&
            phase.survey.map((survey) => (
              <React.Fragment key={survey.phaseId}>
                <div className="text-left">
                  <FormItem name={`rating${survey.phaseId}`} initialValue={3}>
                    <Slider
                      displayName="slider-survey"
                      className="premiumLinks"
                      disabled={this.props.isInstaller}
                      min={1}
                      max={5}
                      onChange={(val) => {
                        this.setState({ rating: val, phaseId: survey.phaseId });
                      }}
                    />
                  </FormItem>
                </div>
                <ul className="ratingSlider" style={{ fontSize: 'small' }}>
                  <li>
                    {rating === 1 ? <span className="premiumLinks">Poor</span> : <span>Poor</span>}
                  </li>
                  <li className="text-center">
                    {rating === 2 ? (
                      <span className="dots premiumLinks" />
                    ) : (
                      <span className="dots" />
                    )}
                  </li>
                  <li className="text-center">
                    {rating === 3 ? (
                      <span className="premiumLinks">Neutral</span>
                    ) : (
                      <span>Neutral</span>
                    )}
                  </li>
                  <li className="text-center">
                    {rating === 4 ? (
                      <span className="dots premiumLinks" />
                    ) : (
                      <span className="dots" />
                    )}
                  </li>
                  <li className="text-right">
                    {rating === 5 ? (
                      <span className="premiumLinks">Love It</span>
                    ) : (
                      <span>Love It</span>
                    )}
                  </li>
                </ul>
              </React.Fragment>
            ))}
          <div className="text-center">
            <button
              className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn"
              disabled={this.props.isInstaller ? 'disabled' : ''}>
              Submit
            </button>
          </div>
        </Form>
      </div>
    );
  }

  renderPhaseServe2(phase) {
    const { loggedInUser } = this.props;
    return (
      <div className="paddall25">
        <div className="phase-survey-title">Thanks! Please share any additional feedback</div>
        <Form onFinish={(values) => this.handleSubmit(values)}>
          {phase &&
            phase.survey &&
            phase.survey.length !== 0 &&
            phase.survey.map((survey) => (
              <React.Fragment key={survey.phaseId}>
                <div className="text-left">
                  <FormItem
                    name="feedback"
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[{ required: true }]}>
                    <TextArea
                      className="status-feed-message textarea"
                      placeholder="Type feedback here..."
                      rows={6}
                    />
                  </FormItem>
                </div>
              </React.Fragment>
            ))}
          <div className="text-center">
            <button
              className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn"
              disabled={this.props.isInstaller ? 'disabled' : ''}>
              Submit
            </button>
          </div>
        </Form>
      </div>
    );
  }

  render() {
    return <React.Fragment>{this.renderScreen()}</React.Fragment>;
  }

  renderScreen() {
    if (this.state.isFeedback) {
      return this.renderPhaseServe2(this.props.homeownerPhase);
    } else {
      return this.renderPhaseServe(this.props.homeownerPhase);
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    loader: state.loader.loader.loader,
    statusFeedPhaseSurvey: state.statusFeedPhaseSurvey.statusFeedPhaseSurvey || {}
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestStatusFeedPhaseSurvey,
      receiveStatusFeedPhaseSurvey,
      setLoader
    },
    dispatch
  );

// export default withRouter(connect((SurveyForm)))
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(SurveyForm));
