import {
	REQUEST_HOMEOWNERS_LIST,
	RECEIVE_HOMEOWNERS_LIST
} from "../types/solar";

export const requestHomeownersList = data => ({
	type: REQUEST_HOMEOWNERS_LIST, data
});

export const receiveHomeownersList = data => ({
	type: RECEIVE_HOMEOWNERS_LIST, data
});