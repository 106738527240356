import React from 'react';

import { Button } from '../../../Common';
import { DefaultHomeownerDecorator } from './HomeownerButton.styles';

/**
 * @todo: merge this in with IBodhiButton
 */
interface IHomeownerButton {
  type?: 'submit' | 'reset' | 'button';
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  clickHandler?: (event: any) => any;
  decorator?: React.FunctionComponent<any>;
  allowInstallerCustom?: boolean;
}

export const HomeownerButton: React.FC<IHomeownerButton> = (props) => {
  const {
    className,
    children,
    disabled,
    clickHandler,
    decorator,
    type,
    allowInstallerCustom = true
  } = props;

  const Decorator = decorator || DefaultHomeownerDecorator;
  const disable = disabled === undefined ? false : disabled;

  // let classes = ['btn', 'yellow-btn'];
  let classes: string[] = [];
  if (allowInstallerCustom) {
    // `Button` provides these already with the same prop
    // classes.push('premiumButtonText', 'premiumButtonFill');
  }
  classes = classes.concat(className.split(' '));

  return (
    <Button
      type={type || 'button'}
      decorator={Decorator}
      allowInstallerCustom={allowInstallerCustom}
      className={classes.join(' ')}
      disabled={disable}
      clickHandler={clickHandler}>
      {children}
    </Button>
  );
};
