import { connect } from 'react-redux'
import { reducer } from 'aws-cognito-redux-saga'
import TermsAndConditionComponent from './TermsAndCondition'

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    image: state.user.slugImage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    init: () => {
      dispatch(reducer.init())
    }
  }
}

export default connect(mapStatetoProps, mapDispatchToProps)(
  TermsAndConditionComponent
)
