
export const REQUEST_FAQ_LIST = "/screens/Profile/data/REQUEST_FAQ_LIST";
export const RECEIVE_FAQ_LIST = "/screens/Profile/data/RECEIVE_FAQ_LIST";

export const REQUEST_FAQ_POST = "/screens/Profile/data/REQUEST_FAQ_POST";
export const RECEIVE_FAQ_POST = "/screens/Profile/data/RECEIVE_FAQ_POST";

export const REQUEST_COMPANY_DETAIL = "/screens/Profile/data/REQUEST_COMPANY_DETAIL";
export const RECEIVE_COMPANY_DETAIL = "/screens/Profile/data/RECEIVE_COMPANY_DETAIL";

export const REQUEST_COMPANY_DETAIL_SET = "/screens/Profile/data/REQUEST_COMPANY_DETAIL_SET";
export const RECEIVE_COMPANY_DETAIL_SET = "/screens/Profile/data/RECEIVE_COMPANY_DETAIL_SET";

export const REQUEST_INVITE_EMPLOYEES = "/screens/Profile/data/REQUEST_INVITE_EMPLOYEES";
export const RECEIVE_INVITE_EMPLOYEES = "/screens/Profile/data/RECEIVE_INVITE_EMPLOYEES";

export const REQUEST_UPDATE_COMPANY_DETAIL = "/screens/Profile/data/REQUEST_UPDATE_COMPANY_DETAIL";
export const RECEIVE_UPDATE_COMPANY_DETAIL = "/screens/Profile/data/RECEIVE_UPDATE_COMPANY_DETAIL";

export const REQUEST_UPDATE_LOGGEDIN_INSTALLER_BY_ID = "/screens/Profile/data/REQUEST_UPDATE_LOGGEDIN_INSTALLER_BY_ID";
export const REQUEST_UPDATE_INSTALLER_BY_ID = "/screens/Profile/data/REQUEST_UPDATE_INSTALLER_BY_ID";
export const RECEIVE_UPDATE_INSTALLER_BY_ID = "/screens/Profile/data/RECEIVE_UPDATE_INSTALLER_BY_ID";

export const requestFaqList = data => ({ type: REQUEST_FAQ_LIST, data });
export const receiveFaqList = data => ({ type: RECEIVE_FAQ_LIST, data });

export const requestCompanyDetailSet = data => ({ type: REQUEST_COMPANY_DETAIL_SET, data });
export const receiveCompanyDetailSet = data => ({ type: RECEIVE_COMPANY_DETAIL_SET, data });

export const requestCompanyDetail = data => ({ type: REQUEST_COMPANY_DETAIL, data });
export const receiveCompanyDetail = data => ({ type: RECEIVE_COMPANY_DETAIL, data });

export const requestFaqPost = data => ({ type: REQUEST_FAQ_POST, data });
export const receiveFaqPost = data => ({ type: RECEIVE_FAQ_POST, data });

export const requestInviteEmployes = data => ({ type: REQUEST_INVITE_EMPLOYEES, data });
export const receiveInviteEmployes = data => ({ type: RECEIVE_INVITE_EMPLOYEES, data });

export const requestUpdateLoggedInInstallerById = data => ({ type: REQUEST_UPDATE_LOGGEDIN_INSTALLER_BY_ID, data });
export const requestUpdateInstallerById = data => ({ type: REQUEST_UPDATE_INSTALLER_BY_ID, data });
export const receiveUpdateInstallerById = data => ({ type: RECEIVE_UPDATE_INSTALLER_BY_ID, data });

export const requestUpdateCompanyDetail = data => ({ type: REQUEST_UPDATE_COMPANY_DETAIL, data });
export const receiveUpdateCompanyDetail = data => ({ type: RECEIVE_UPDATE_COMPANY_DETAIL, data });