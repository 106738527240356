import React, { Component } from 'react';

import ShowImageFromDrive from '../../../Common/ShowImageFromDrive';
import { MONTH_ARRAY } from '../../../../shared/config';
import { currencyFormater } from '../../../../shared/currencyFormater';
import { capitalize } from '../../../../shared/capitalize';
import { setHeight } from '../../../../shared/setHeight';

import { ReactComponent as ElectricIcon } from '../../../../Assets/Images/electric-icon.svg';
import { ReactComponent as SolarIcon } from '../../../../Assets/Images/solar-icon.svg';
import { ReactComponent as SunIcon } from '../../../../Assets/Images/sun-icon.svg';
import { ReactComponent as GlanceZeroStateIconSvg } from '../../../../Assets/Images/SAGlance_zeroState.svg';

import HomeownerShare from './HomeownerShare';

class SystemAtGlance extends Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps() {
    setHeight('homeOwnerWrapper', 'menuMobile');
  }

  getMeterUrl(meterID, meterUrl) {
    this.meterUrl = {
      2: 'monitoring.solaredge.com',
      3: 'api.enphaseenergy.com',
      4: 'data.sense.com',
      5: 'apsystemsema.com',
      6: 'solarweb.com'
    };
    switch (meterID) {
      case 1:
      case 7:
        return meterUrl || '';
      case 2:
        return 'https://monitoring.solaredge.com/';
      case 3:
        return 'https://api.enphaseenergy.com/';
      case 4:
        return 'https://home.sense.com/';
      case 5:
        return 'https://apsystemsema.com/';
      case 6:
        return 'https://www.solarweb.com/';
    }
  }

  renderEmptySystemGlance(companyName) {
    return (
      <div className="button-wrapper btn-box text-center">
        <p className="page-title">YOUR SYSTEM AT A GLANCE</p>
        <GlanceZeroStateIconSvg style={{ margin: '20px 0' }} />
        <p className="m-auto">
          When {companyName} adds your solar
          <br /> panel information, you will find it here.
        </p>
      </div>
    );
  }

  render() {
    const { systemGlance, loggedInUser } = this.props;

    // if all information for glance is not present hide

    let glaceBoxBlockClass = 0;
    const solarGlance = systemGlance?.solarGlance;
    const renderGlanceSlide =
      solarGlance?.moduleCount ||
      solarGlance?.moduleName ||
      solarGlance?.saving ||
      (solarGlance?.production && solarGlance?.consumption);

    if (systemGlance.solarGlance && !renderGlanceSlide) {
      return this.renderEmptySystemGlance(loggedInUser?.homeownerData?.companyName);
    }

    let activationDate = null;
    if (systemGlance && systemGlance?.solarGlance?.phaseName === '6') {
      if (systemGlance?.solarGlance?.activationDate) {
        if (typeof systemGlance.solarGlance.activationDate === 'string')
          activationDate = Number(systemGlance.solarGlance.activationDate);
        else activationDate = systemGlance.solarGlance.activationDate;
      } else if (loggedInUser && loggedInUser?.homeownerData?.created) {
        if (typeof loggedInUser.homeownerData.created === 'string')
          activationDate = Number(loggedInUser.homeownerData.created);
        else activationDate = loggedInUser.homeownerData.created;
      }
    }

    this.slug = localStorage.getItem('slug');
    let meterUrl = '';

    const avgOffset =
      systemGlance &&
      systemGlance?.solarGlance?.production &&
      systemGlance?.solarGlance?.consumption;

    const isProjectTrack = loggedInUser && loggedInUser?.homeownerData?.isProjectTrack;

    if (loggedInUser && loggedInUser?.solarUnit?.meterId) {
      meterUrl = this.getMeterUrl(
        parseInt(loggedInUser.solarUnit.meterId),
        loggedInUser.solarUnit.meterUrl
      );

      if (meterUrl && !meterUrl.includes('http')) {
        meterUrl = `http://${meterUrl}`;
      }
    }

    if (avgOffset) {
      glaceBoxBlockClass++;
    }
    if (systemGlance?.solarGlance?.systemSize) {
      glaceBoxBlockClass++;
    }
    if (systemGlance?.solarGlance?.moduleCount) {
      glaceBoxBlockClass++;
    }

    return (
      <>
        <p className="page-title">YOUR SYSTEM AT A GLANCE</p>
        <div className="referral-wrap slider-wrapper">
          <ShowImageFromDrive
            {...this.props}
            isOpen
            filterByName=""
            style={{ marginBottom: '80px' }}
          />
          <div className="row text-center system-glance-box">
            {systemGlance?.solarGlance?.systemSize && (
              <div className={`col-${12 / glaceBoxBlockClass}`}>
                <div className="icon">
                  <ElectricIcon />
                </div>
                <div className="kw">{systemGlance?.solarGlance?.systemSize} kW</div>
                <div className="desc">Size</div>
              </div>
            )}

            {systemGlance?.solarGlance?.moduleCount && (
              <div className={`col-${12 / glaceBoxBlockClass}`}>
                <div className="icon">
                  <SolarIcon />
                </div>
                <div className="kw">{systemGlance?.solarGlance?.moduleCount}</div>
                <div className="desc">Panels</div>
              </div>
            )}

            {avgOffset != 0 && avgOffset && (
              <div className={`col-${12 / glaceBoxBlockClass}`}>
                <div className="icon">
                  <SunIcon />
                </div>
                <div className="kw">
                  {`${currencyFormater(
                    (systemGlance.solarGlance.production / systemGlance.solarGlance.consumption) *
                      100
                  )}%`}
                </div>
                <div className="desc">Avg offset</div>
              </div>
            )}
          </div>

          {systemGlance?.solarGlance?.saving ? (
            <div className="white-box padd15 text-center saving-box paddt10">
              <div className="annual-saving-text">Your annual savings</div>
              <div className="total-saving premiumLinks">
                ${currencyFormater(systemGlance.solarGlance.saving)}
              </div>
            </div>
          ) : ''}

          <div className="white-box padd15 text-center comapny-detail-box">
            <div className="detail-box  w-100">
              <span className="pull-left">Your solar company:</span>
              <span className="pull-right">
                <a
                  className="premiumLinks"
                  target="_blank"
                  href={systemGlance && systemGlance?.solarGlance?.companyUrl}
                  rel="noreferrer">
                  {systemGlance &&
                    systemGlance?.solarGlance?.companyName &&
                    capitalize(systemGlance.solarGlance.companyName)}
                </a>
              </span>
            </div>

            {(systemGlance?.solarGlance?.moduleName || systemGlance?.solarGlance?.moduleModel) && (
              <div className="detail-box  w-100">
                <span className="pull-left">Panels: </span>
                <span className="pull-right">
                  {`${capitalize(systemGlance.solarGlance.moduleName)} 
                                           ${capitalize(systemGlance.solarGlance.moduleModel)}
                         `}
                </span>
              </div>
            )}

            {systemGlance?.solarGlance?.inverterName && (
              <div className="detail-box  w-100">
                <span className="pull-left">Inverter: </span>
                <span className="pull-right">
                  {capitalize(systemGlance.solarGlance.inverterName)}
                </span>
              </div>
            )}

            {!isProjectTrack && window.$productSuite !== 1 && (
              <div className="detail-box  w-100">
                <span className="pull-left">Monitoring: </span>
                <a href={meterUrl} target="_blank" className="premiumLinks" rel="noreferrer">
                  <span className="pull-right">
                    {systemGlance &&
                      systemGlance?.solarGlance?.meterName &&
                      capitalize(systemGlance.solarGlance.meterName)}
                  </span>
                </a>
              </div>
            )}

            {!!activationDate && (
              <div className="detail-box  w-100">
                <span className="pull-left">Activation Date: </span>
                <span className="pull-right">
                  {`${MONTH_ARRAY[new Date(activationDate).getMonth()]}
                                        ${new Date(activationDate).getDate()},
                                        ${new Date(activationDate).getFullYear()}`}
                </span>
              </div>
            )}
          </div>

          <HomeownerShare
            loggedInUser={loggedInUser}
            isInstaller={this.props.isInstaller}
            shareName="glance"
            isDefaultShow
          />
        </div>
      </>
    );
  }
}

export default SystemAtGlance;
