// import npm packages
import { call, put } from "redux-saga/effects";

// import local files
import { getReferralDetailsApi,getReferralNeighbourApi, postReferralDetailsApi,postAddressDetailsApi } from "../../screens/homeowner/api/referralDetails";
import handleError from "../../shared/errorHandler";
import { setLoader } from "../../screens/Common/Loader/action";
import { receiveReferralDetails, receivePostReferralDetails,receivePostAddressDetails,receiveReferralNeighbour } from "../../screens/homeowner/actions/referralDetails";
function* getReferralDetails(action) {
	try {
		const data = yield call(getReferralDetailsApi, action.data);
		yield put(receiveReferralDetails(data));
	} catch (e) {
		if(e && e.response && e.response.status === 302){
			window.location = e.response.data.url;
			return
		}
		 yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* postReferralDetails(action) {
	try {
		const data = yield call(postReferralDetailsApi, action.data);
		yield put(receivePostReferralDetails(data));
	} catch (e) {
		if (action && action.data && action.data.isLead) {
			yield put(setLoader({ loader: false }));
			handleError(e);
		}
		else {
			yield put(setLoader({ loader: false }));
			yield put(receivePostReferralDetails(e && e.response && e.response.status));
		}
	}
}

function* postAddressDetails(action) {
	try {
		const data = yield call(postAddressDetailsApi, action.data);
		yield put(receivePostAddressDetails(data));
	} catch (e) {
		
		if (action && action.data && action.data.isLead) {
			handleError(e);
		}
		else {
			yield put(receivePostAddressDetails(e && e.response && e.response.status));
		}
	}
}

function* getReferralNeighbour(action) {
	try {
		const data = yield call(getReferralNeighbourApi, action.data);
		yield put(receiveReferralNeighbour(data));
	} catch (e) {
		if(e && e.response && e.response.status === 302){
			window.location = e.response.data.url;
			return
		}
		 yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

export const homeownerReferral = {
	getReferralNeighbour,
	getReferralDetails,
	postReferralDetails,
	postAddressDetails
};