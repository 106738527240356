import { call, put } from 'redux-saga/effects';
import handleError   from '../../shared/errorHandler';
import { setLoader } from '../../screens/Common/Loader/action';
import { getCompanyFleetListApi } from '../../screens/Installer/api/fleet';
import { receiveCompanyFleetData } from "../../screens/Installer/actions/fleet"


function* getCompanyFleetList(action) {
    try {
        const data = yield call(getCompanyFleetListApi, action.data);
        yield put(setLoader({loader: false}));
        yield put(receiveCompanyFleetData(data));
    } catch (e) {
        yield put(setLoader({ loader: false }));
        handleError(e);
    }
}

export const fleet = {
    getCompanyFleetList
}

