import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { meterStatusNotification } from '../../Installer/api/dashboard';

import { getToken, getUser, jwtDecode, withRouter } from '../../../shared';
import { REPORT_SYNC } from '../../../shared/config';
import { openNotificationWithIcon } from '../../../shared/notification';
import LandingPage from '../../Common/LandingPage';
import { setLoader } from '../../Common/Loader/action';
import { setImages } from '../../Common/ShowImageFromDrive/action';
import { requestCompanyJourney } from '../../Installer/actions/companyJourney';
import { envAppIcon, getLoadingIcon } from '../../User/components/setUserBackgroud';
import { requestHomeownerSystemGlance } from '../../homeowner/actions/dashboard';
import { receiveGetEversignData } from '../../homeowner/actions/eversign'; // TODO: not sure if we keep this or not as we pass down all props from here
import {
  receiveHomeownerEnergyData,
  requestHomeownerEnergyData,
  requestHomeownerInstallerNotify,
  requestHomewnerDocument,
  requestLoggedInHomeownerAction,
  requestUpdateHomeOwner
} from '../../homeowner/actions/homeOwner';
import {
  receivePostReferralDetails,
  requestPostReferralDetails
} from '../../homeowner/actions/referralDetails';
import { requestHomeownerCompanyDetail } from '../../homeowner/actions/resources';
import { requestHomeownersList } from '../../homeowner/actions/solar';
import OnBoarding from '../../homeowner/components/onBoarding/onBoarding';
import BodhiJourney from '../../homeowner/components/shared/bodhiJourney';
import SolarCommunity from '../../homeowner/components/shared/solarCommunity';
import SystemAtGlance from '../../homeowner/components/shared/systemAtGlance';
import Header from '../../homeowner/container/header';
import envConfig from '../../../environments'
import { requestInstallerDetails } from '../../homeowner/actions/contact';
import EnphaseDeprecationPromo from '../../homeowner/components/shared/EnphaseDeprecationPromo';

const getArrowDecorator = (show) => {
  if (show) {
    return (
      <span className="up-arrow" style={{ display: 'block' }}>
        <i className="fa fa-angle-up" data-unicode="f106" />
      </span>
    );
  }
  return (
    <span className="down-arrow" style={{ display: 'block' }}>
      <i className="fa fa-angle-down" data-unicode="f107" />
    </span>
  );
};

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

class HomeownerLayout extends Component {
  constructor(props) {
    super(props);
    this.slug = '';
    this.enphaseList = []; // TEMPORARY FOR ENPHASE DEPRECATION
    this.getttingEnphaseList = false; // TEMPORARY FOR ENPHASE DEPRECATION
    this.state = {
      displayCalloutContent: 'visible', // TEMPORARY FOR ENPHASE DEPRECATION
      documentId: {},
      isSyncCalled: false,
      meterStatusNotification: false,
      isPopUpVisible: false,
      sysGlanceShow: false,
      shareSunShow: true,
      isReferralCommunity: false,
      isEnergyFlow: false,
      isApiResponse: false,
      width: window.innerWidth,
      isNewUser: localStorage.getItem('isNewUser') && localStorage.getItem('isNewUser') === 'true'
    };

    this.homeownerTracked = false;
  }

  setEnphaseList(res) {
    this.enphaseList = res;
  }

  hitApi(homeownerId) {
    let companyId = this.homeownerData.companyId;
    let {
      energyReport,
      loggedInUser,
      systemGlance: { solarGlance }
    } = this.props;

    const installerId = loggedInUser && loggedInUser?.homeownerData?.installerId;

    // /**
    //  * Ensure we don't make multiple calls once we have data
    //  */
    // if (!this.getttingEnphaseList) {
    //   this.getttingEnphaseList = true;
    //   fetchs3File('https://prod-17terawatts.s3.amazonaws.com/public/enphaseList.json').then(
    //     (s3Result) => {
    //       this.setEnphaseList(s3Result.data);
    //     }
    //   );
    // }

    if (solarGlance && JSON.stringify(energyReport) != '{}') {
      return;
    }

    this.props.requestHomeownerSystemGlance({
      companyId,
      homeownerId
    });

    this.props.requestHomewnerDocument({
      companyId,
      homeownerId
    });

    this.props.requestHomeownersList({
      companyId,
      homeownerId
    });

    this.props.requestHomeownerEnergyData({
      homeownerId,
      sync: REPORT_SYNC,
      requestMonthHistory: true
    });

    if (loggedInUser && loggedInUser?.solarUnit?.dailySyncTime) {
      setTimeout(() => {
        this.props.requestHomeownerEnergyData({
          homeownerId,
          sync: false,
          requestMonthHistory: false
        });
      }, 15000);
    }

    if (loggedInUser && loggedInUser?.homeownerData?.installerId) {
      this.props.requestInstallerDetails({
        companyId,
        installerId
      });
    }
  }

  componentDidMount() {
    const { router } = this.props;

    document.body.classList.add('homeowner-bg');
    document.body.classList.remove('bg-color');

    this.slug = router.params.slug || localStorage.getItem('slug');

    envAppIcon(this.slug).then((headTag) => {
      this.setState({ headTag });
    });

    const token = getToken();
    if (token) {
      this.homeownerData = jwtDecode(token);
      this.props.setLoader({ loader: true });
      this.props.requestLoggedInHomeownerAction(this.homeownerData);
    }
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  googleDriveInvoker(documentData) {
    const driveData = documentData?.driveData;
    const docKey = documentData.key;
    if (driveData && Object.keys(driveData).length) {
      let files = [];
      if (documentData.source === 'solarNexus') {
        driveData[docKey] &&
          driveData[docKey].map((ele) => {
            files.push({
              name: ele.document.file_name,
              public_url: ele.document.url
            });
          });
      } else if (documentData.source === 'zoho') {
        driveData[docKey] &&
          driveData[docKey].map((ele) => {
            if (ele['$link_url'])
              files.push({
                name: ele.File_Name,
                public_url: ele['$link_url']
              });
          });
      } else if (documentData.source === 'teamwork') {
        driveData[docKey] &&
          driveData[docKey].map((ele) => {
            if (ele.previewUrl)
              files.push({
                name: ele.name,
                public_url: ele.previewUrl
              });
          });
      }

      this.props.setLoader({ loader: false });
      this.props.setImages(files.length > 0 ? { [documentData.key]: files } : driveData);
      this.setState({
        documentId: {
          key: documentData.key,
          source: documentData.source
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps?.homeOwnerFileList?.documentData && !this.state.homeOwnerFileList) {
      this.setState({ homeOwnerFileList: true }, () => {
        this.googleDriveInvoker(nextProps.homeOwnerFileList.documentData);
      });
    }
    if (nextProps.premiumSetting && Object.keys(nextProps.premiumSetting).length > 1) {
      document.body.classList.add(nextProps.premiumSetting.font);
    }

    if (
      this.homeownerData &&
      !this.homeownerData.role.includes('secondary') &&
      nextProps.loggedInUser?.homeownerData?.id &&
      !this.state.homeownerApiResponse
    ) {
      this.setState({ homeownerApiResponse: true }, () =>
        this.hitApi(this.homeownerData.homeownerId)
      );
    }

    if (nextProps.loggedInUser?.homeownerData?.id && !this.state.isApiResponse) {
      if (this.homeownerData && this.homeownerData.role.includes('secondary')) {
        this.hitApi(nextProps.loggedInUser.homeownerData.id);
      }

      this.homeownerData.toolSearch = true;
      this.homeownerData.fileName =
        nextProps.loggedInUser.solarUnit?.activeJourney?.s3Key || 'default';
      this.props.requestCompanyJourney(this.homeownerData);

      this.setState({
        isApiResponse: true,
        isPopUpVisible: true
      });
      nextProps.setLoader({ loader: false });
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  updateLocalStorage = (status, onboardMessage, isLast) => {
    let {
      loggedInUser: { homeownerData }
    } = this.props;
    if (!status) {
      if (!onboardMessage || onboardMessage === '') {
        openNotificationWithIcon(
          'error',
          `We'll pass it on to your project manager.`,
          'Please enter a message'
        );
        return null;
      }
      this.homeownerData.onboardMessage = onboardMessage;
      this.homeownerData.isProjectTrack = homeownerData.isProjectTrack;

      const body = {
        onboardMessage: onboardMessage,
        isProjectTrack: homeownerData.isProjectTrack,
        homeownerName: `${homeownerData.firstName} ${homeownerData.lastName}`,
        name: homeownerData?.installerName?.split(' ')[0],
        slug: this.slug,
        email: homeownerData.installerEmail,
        homeowner_email: homeownerData.email,
        homeownerId: homeownerData.id
      };

      this.props.requestHomeownerInstallerNotify(body);
    }
    if (!isLast) {
      localStorage.removeItem('isNewUser');
      this.setState({ isNewUser: false });
    }
  };

  /**
   * @returns {Boolean}
   */
  meetsPromoCriteria() {
    const { loggedInUser } = this.props;
    if (
      loggedInUser &&
      loggedInUser?.solarUnit?.dailySyncTime &&
      loggedInUser?.solarUnit?.meterId == 3 &&
      this.enphaseList.length &&
      this.enphaseList.indexOf(this.slug) != -1
    ) {
      return true;
    }
    return false;
  }

  getTakeoverComponent(showCallout = false) {
    const { loggedInUser, router } = this.props;
    const stateSetter = this.setState.bind(this);
    const contentVisibility = this.state.displayCalloutContent === 'hidden' ? 'visible' : 'hidden';

    return (
      <EnphaseDeprecationPromo
        showCallout={showCallout}
        contentVisibility={contentVisibility}
        loggedInUser={loggedInUser}
        stateSetter={stateSetter}
        router={router}
      />
    );
  }

  renderHomeownerDashboard = () => {
    const { systemGlance, loggedInUser, component, router } = this.props;
    const MyComponent = component;
    const slideNumber = router.params.slideNumber;
    const meetsPromoCriteria = this.meetsPromoCriteria();
    const installerName = loggedInUser?.homeownerData?.installerName || 'your installer';
    const solarGlance = systemGlance?.solarGlance;
    const renderGlanceSlide =
      solarGlance?.moduleCount ||
      solarGlance?.moduleName ||
      solarGlance?.saving ||
      (solarGlance?.production && solarGlance?.consumption);

    return (
      <>
        {this.state.isPopUpVisible && this.renderPopUp(installerName)}

        <section className="two-box-wrapper marg-two-box portal-intro-wrapp">
          <div>
            <div className={'container margin-t-73 ' + (slideNumber ? 'margin-t-73-new' : '')}>
              <div
                className={
                  'create-new-account-wrapper ' +
                  (slideNumber ? 'create-new-account-wrapper-new' : '')
                }>
                <div className="homeowner-layout row">
                  <div className="col-md-6 desktop-view-only">
                    <div className="progress-wrapp">
                      {meetsPromoCriteria === true && this.getTakeoverComponent()}
                      {meetsPromoCriteria === false && (
                        <BodhiJourney {...this.props} isInstaller={false} />
                      )}
                      <div className="whtie-heading-accordion">
                        <div id="accordion">
                          <div className="card">
                            <div className="card-header" id="headingTwo">
                              <h5 className="mb-0">
                                <a
                                  href="#"
                                  className="up-arra dashboard-heading mb-0"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="true"
                                  aria-controls="collapseTwo"
                                  onClick={() => {
                                    this.setState({
                                      shareSunShow: !this.state.shareSunShow
                                    });
                                  }}>
                                  <span>SHARE THE SUN</span>
                                  <span
                                    className="arrow-wrap pull-right"
                                    style={{ display: 'block' }}>
                                    {getArrowDecorator(this.state.shareSunShow)}
                                  </span>
                                </a>
                              </h5>
                            </div>
                            <div
                              id="collapseTwo"
                              className={`collapse ${this.state.shareSunShow ? 'show' : ''}`}
                              aria-labelledby="headingTwo"
                              data-parent="#accordion">
                              <SolarCommunity {...this.props} />
                            </div>
                          </div>
                          {renderGlanceSlide && (
                            <div className="card margb30">
                              <div className="card-header" id="headingOne">
                                <h5 className="mb-0">
                                  <a
                                    href="#"
                                    className="up-arra collapsed dashboard-heading mb-0"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                    onClick={() => {
                                      this.setState({
                                        sysGlanceShow: !this.state.sysGlanceShow
                                      });
                                    }}>
                                    <span className="ho-setting-acc-heading">
                                      YOUR SYSTEM AT A GLANCE
                                    </span>
                                    <span className="arrow-wrap pull-right">
                                      {getArrowDecorator(this.state.sysGlanceShow)}
                                    </span>
                                  </a>
                                </h5>
                              </div>
                              <div
                                id="collapseOne"
                                className={`card-body collapse paddt0 paddb0 ${
                                  this.state.sysGlanceShow ? 'show' : ''
                                }`}
                                aria-labelledby="headingOne"
                                data-parent="#accordion">
                                <SystemAtGlance
                                  systemGlance={systemGlance}
                                  loggedInUser={loggedInUser}
                                  {...this.props}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6" style={{paddingLeft: '0', paddingRight: '0'}}> */}
                  <div className={'col-md-6 ' + (slideNumber ? 'resources-wrapp' : '')}>
                    <MyComponent
                      meetsPromoCriteria={meetsPromoCriteria}
                      {...this.props}
                      {...this.params}
                      homeOwnerId={loggedInUser?.homeownerData?.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  renderPopUp = (installerName) => {
    const { loggedInUser } = this.props;
    const location = window.location;
    const url = new URL(location);
    const action = url.searchParams.get('action');
    if (!action || !loggedInUser.homeownerData || !loggedInUser.homeownerData.id) return '';
    else if (!this.state.meterStatusNotification) {
      this.setState(
        {
          meterStatusNotification: true
        },
        () => {
          meterStatusNotification({
            homeownerId: loggedInUser.homeownerData.id,
            action: action,
            companyId: loggedInUser.homeownerData.companyId
          });
        }
      );
    }

    return (
      <div>
        <Modal open={true} className="thank-you popUp" footer={null}>
          <h2>
            Thanks for attempting to debug and isolate the performance issue with your system.
          </h2>
          <p>
            We're letting {installerName} know you've taken these steps and to follow up with you
          </p>
          <button
            className="btn thanks-btn"
            onClick={() => {
              window.history.pushState({}, '', window.location.href.split('?')[0]);
              this.setState({
                isPopUpVisible: false
              });
            }}>
            DASHBOARD
          </button>
        </Modal>
      </div>
    );
  };

  renderHeader = () => {
    if (localStorage.getItem('isNewUser') != 'true') {
      return (
        <header>
          <Header {...this.props} />
        </header>
      );
    }
  };

  render() {
    /**
     * Ensure that no API calls are attempted without a sessioned user
     */
    if (!getUser() || localStorage.getItem('isLogout') === 'true') {
      return <Navigate to={{ pathname: '/' }} />;
    }

    const { router, systemGlance, loader, loggedInUser, energyReport } = this.props;

    const slideNumber = router.params.slideNumber;

    const loadingIcon = this?.homeownerData?.role.includes('secondary')
      ? getLoadingIcon(this.slug)
      : systemGlance && systemGlance?.solarGlance?.logo;

    const iconUrl = this.state.headTag;

    return (
      <>
        <Helmet>
          <link rel="shortcut icon" href={iconUrl} />
          <script src="https://api.cloudsponge.com/widget/fj1g7kuwAfk01wOrYttfBw.js" />
          <meta name="apple-mobile-web-app-title" content={this.companyName || 'Bodhi'} />
          <link rel="apple-touch-icon" href={iconUrl} sizes="72x72" />
        </Helmet>
        <Spin spinning={loader} indicator={antIcon} style={{ color: '#04bfff' }}>
          {slideNumber && this.state.width <= 767.98 ? '' : this.renderHeader()}
          <div>
            {JSON.stringify(loggedInUser) === '{}' || JSON.stringify(energyReport) === '{}' ? (
              <LandingPage
                loadingIcon={loadingIcon}
                role={this.homeownerData && this?.homeownerData?.role}
              />
            ) : localStorage.getItem('isNewUser') === 'true' ? (
              <OnBoarding
                slug={this.slug}
                updateLocalStorage={this.updateLocalStorage}
                {...this.props}
              />
            ) : (
              this.renderHomeownerDashboard()
            )}
          </div>
        </Spin>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    companyJourney:
      (state?.companyBodhiJourney?.bodhiJourneyReducer?.data &&
        state?.companyBodhiJourney?.bodhiJourneyReducer.data[0]) ||
      {},
    resourceData:
      state?.companyBodhiJourney?.bodhiJourneyReducer?.data &&
      state?.companyBodhiJourney?.bodhiJourneyReducer?.data[2],
    additionalUser: state.homeOwner?.getHomeownerAddUser?.data,
    systemGlance: state.homeownerDashboard.homeownerSystemGlance.data || {},
    loggedInUser: state.homeownerLoggedInUser.homeownerLoggedInUserReducer.data || {},
    loader: state.loader.loader.loader,
    energyReport: state.homeownerLoggedInUser.homeownerEnergyReportReducer.data || {},
    installerDetails: state.homeownerContact.homeownerInstallerDetails.data,
    cloudImages: Object.keys(state.cloudImages.cloudImages).length
      ? state.cloudImages.cloudImages
      : {},
    homeownersList: state.homeownersList.homeownersList.data || {},
    referralDetails: state.homeownerReferralDetails,
    homeOwnerFileList: state.homeownerLoggedInUser.getHomeownerDocumentReducer.data || {},
    companyDetail:
      state.homeownerResources?.homeownerResoucesCompanyDetail?.data?.companyDetail || {}
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestHomeownerSystemGlance,
      requestLoggedInHomeownerAction,
      requestHomeownerEnergyData,
      receiveHomeownerEnergyData,
      setLoader,
      setImages,
      requestPostReferralDetails,
      receivePostReferralDetails,
      requestHomeownersList,
      requestHomeownerCompanyDetail,
      receiveGetEversignData, // TODO: not sure if we keep this or not, as we pass all props down from here...
      requestHomeownerInstallerNotify,
      requestUpdateHomeOwner,
      requestCompanyJourney,
      requestHomewnerDocument,
      requestInstallerDetails
    },
    dispatch
  );

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(HomeownerLayout));
