import {
  addClass,
  removeClass,
  resetInstallerSpecificClasses,
  resetHomeownerSpecificClasses,
  resetAllUserClasses,
  setUserClassnames,
  clearUserClassnames
} from './removeClass';

import { adminRole, installerRole, installerAdminRole, homeownerRole } from '../constants';

import {
  routeFactory,
  routeWithParams,
  resolveRoute,
  getToken,
  setToken,
  deriveUsageStateFromUrl,
  getHomeownerRouteFromModel,
  isHomeownerRouteByName
} from './util';
import {
  createNonUniqueId,
  standardCountFormat,
  getInitialsFromName,
  isValidUrl,
  processTouchNameForCollation,
  userIsUsingInbox,
  withThrottle
} from './helpers';
import { jwtDecode } from './jwt';
import withRouter from './withRouter';
import { requiredFieldValidationMessage } from './validators';
import { setHeight } from './setHeight';
import { capitalize } from './capitalize';

/**
 * Either calls a function or subscribes it to be called when the document is ready for it
 * encapsulating Installer-specific listeners that run when everything (including this layout) is ready
 *
 * @param {Function} fn
 * @return void
 */
export const subscribeToDocumentReady = (fn) => {
  if (document.readyState !== 'loading') {
    if (typeof fn === 'function') {
      fn();
    }
    return;
  }
  document.addEventListener('DOMContentLoaded', fn);
};

/**
 * A helper method to encapsulate Journey name processing for creating file in s3
 *
 * Addresses "Characters to avoid" in AWS s3:
 * @see https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
 *
 * @param {String} the textual journey name
 * @returns {String} the processed name used for the s3 file
 */
const journeyNameFactory = (journeyName) => {
  return journeyName.replace(/[^a-z0-9]/gi, '').toLowerCase();
};

/**
 * A canonical mechanism to determine an admin user, which can't be done from route parameters
 *
 * @param {Object} an instance of the `router` provided via `withRouter`
 * @returns {Boolean}
 */
const isAdminUser = (router) => {
  if (jwtDecode(getToken()).slug !== adminRole) {
    return false;
  }
  return (
    router.location.pathname.indexOf('/admin/') === 0 &&
    Number(router.params.companyId) > 0 &&
    !router.params.slug
  );
};

/**
 * Encapsulate the decoding of locally stored token data to derive user data
 *
 * @returns {null|Object}
 */
const getUser = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  }
  const userData = jwtDecode(token);
  return userData.email && userData.exp ? userData : null;
};

/**
 * A canonical mechanism for identifying an installer
 *
 * @returns boolean
 */
export const isInstaller = () => {
  const userTokenData = getUser();
  if (
    (userTokenData.role === installerRole || userTokenData.role === installerAdminRole) &&
    userTokenData.companyId &&
    userTokenData.installerId
  ) {
    return true;
  }
  return false;
};

/**
 * A canonical mechanism for identifying a homeowner
 *
 * @returns boolean
 */
export const isHomeowner = () => {
  const userTokenData = getUser();
  if (userTokenData.role === homeownerRole && !userTokenData.installerId) {
    return true;
  }
  return false;
};

export {
  processTouchNameForCollation,
  journeyNameFactory,
  isAdminUser,
  jwtDecode,
  getUser,
  getToken,
  setToken,
  addClass,
  removeClass,
  resetInstallerSpecificClasses as resetInstallerClasses,
  resetHomeownerSpecificClasses as resetHomeownerClasses,
  resetAllUserClasses,
  setUserClassnames,
  clearUserClassnames,
  createNonUniqueId,
  routeFactory,
  routeWithParams,
  resolveRoute,
  getHomeownerRouteFromModel,
  isHomeownerRouteByName,
  setHeight,
  withRouter,
  requiredFieldValidationMessage,
  deriveUsageStateFromUrl,
  standardCountFormat,
  capitalize,
  getInitialsFromName,
  isValidUrl,
  userIsUsingInbox,
  withThrottle
};
