
import { RECEIVE_COMPANY_SEARCH_DATA,RECEIVE_ADMIN_COMPANY__DATA,
  RECEIVE_ADMIN_HOMEOWNER_SEARCH } from "../../Actions/header";
import { combineReducers } from 'redux';

const searchReducer =  (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_COMPANY_SEARCH_DATA:
      return data;
    default:
      return state;
  }
};



const listCompanyReducer =  (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_ADMIN_COMPANY__DATA:
      return data;
    default:
      return state;
  }
};

const adminListHomeowner = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_ADMIN_HOMEOWNER_SEARCH:
      return data;
    default:
      return state;
  }
};


export const reducer = combineReducers({
  searchData: searchReducer,
  adminListHomeowner,
  listCompany: listCompanyReducer
})