export const handleCognitoError = (type, errorMessage = '') => {
    let msg = "";
    switch(type) {
        case "InvalidParameterException" : 
        case "ResourceNotFoundException" : 
        case "TooManyRequestsException" : 
        case "IncompleteSignature" : 
        case "InternalFailure" : 
        case "InvalidAction" : 
        case "InvalidQueryParameter" : 
        case "MalformedQueryString" : 
        case "ThrottlingException" : 
        case "ValidationError" : 
        case "MissingAction" : 
        case "InvalidClientTokenId" : 
        case "InternalErrorException" : msg = "Something went wrong"; break;

        case "NotAuthorizedException" : msg = errorMessage || "Incorrect password."; break;

        case "PasswordResetRequiredException" : msg = ""; break;
        
        case "InvalidParameterCombination" : msg = "Parameters that must not be used together were used together."; break;

        case "InvalidParameterValue" : msg = "An invalid or out-of-range value was supplied for the input parameter."; break;

        case "UserNotConfirmedException" : msg = "User is not confirmed successfully."; break;
        
        case "MissingAuthenticationToken" : msg = "Token is required."; break;
        
        case "RequestExpired" : msg = "Request time expired. Please try again"; break;
        
        case "ServiceUnavailable" : msg = "Service Unavailable. Please try again later."; break;
        
        case "MissingParameter" : msg = "A required parameter for the specified action is not supplied."; break;

        case "UserNotFoundException" : msg = "User is not found." ; break;

        case "AccessDeniedException" : msg = "You do not have sufficient access to perform this action." ; break;

        case "CodeMismatchException" : msg = "Invalid verification code provided, please try again." ; break;

        case "LimitExceededException" : msg = "Attempt limit exceeded, please try after some time." ; break;

        default: msg = "Something went wrong."; break;

    }
    return msg;
}