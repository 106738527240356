import { call, put } from "redux-saga/effects";
import { createCampaign, 
	getCampaignById,
	updateCampaign,
    getSavedCampaignsData }         from "../../screens/campaign/api/campaign";
import handleError                  from "../../shared/errorHandler";
import { setLoader }                from "../../screens/Common/Loader/action";
import {receiveCreateCampaign,  
	receiveUpdateCampaign,
	receiveGetCampaignDetail,
    receiveGetCampaignData }        from "../../screens/campaign/actions/campaign";

function* postCampaignData(action) {
	try {
		const data = yield call(createCampaign, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveCreateCampaign(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* getCampaignData(action) {
	try {
		const data = yield call(getSavedCampaignsData, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveGetCampaignData(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* getCampaignDetail(action) {
	try {
		const data = yield call(getCampaignById, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveGetCampaignDetail(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* updateCampaignData(action) {
	try {
		const data = yield call(updateCampaign, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveUpdateCampaign(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

export const companyCampaign = {
	getCampaignData,
	postCampaignData,
	getCampaignDetail,
	updateCampaignData
};