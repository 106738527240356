import React, { useState, useRef }from 'react';
import styled, { css, keyframes } from 'styled-components';

const TooltipTarget = styled.button`
    background: none;
    padding: 0;
    cursor: inherit;
    margin: -1px;
    display: flex;
    z-index: 4;
    border: 0.8px solid ${(props) => props.theme.colors.greyMed};
    border-radius: 50% !important;
    box-shadow: 0 2px 5px 0 rgba(176, 186, 197, 0.5) !important;
    box-sizing: border-box;
    
`;

const TooltipBox = styled.span<{position: string}>`
    position: relative;
    background-color: ${(props) => props.theme.colors.greyMed};
    color: ${(props) => props.theme.colors.ltblue2};
    text-align: center;
    border-radius: 4px;
    padding: 10px 8px;
    font-size: ${(props) => props.theme.fonts.sizes.font12};
    line-height: 14px;
    letter-spacing: 0;
     
    // styling for arrow of tooltip
    &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 1px;
        border-width: 5px;
        border-style: solid;
        border-color: ${(props) => props.theme.colors.greyMed} transparent transparent transparent;
        left: calc(50% - 4.5px);
        top: 100%;

    }

    ${({position}) => {
        switch (position) {
            case "bottom":
                return css`
                    &:after {
                        border-color: transparent transparent ${(props) => props.theme.colors.greyMed} transparent;
                        top: unset;
                        bottom: 100%;
                        width: 1px;
                        left: calc(50% - 5px);
                    }
                `;
            case "left":
                return css`
                    &:after {
                        border-color: transparent transparent transparent ${(props) => props.theme.colors.greyMed};
                        left: 100%;
                        top: calc(50% - 5px);
                    }
                `;
            case "right":
                return css`
                    &:after {
                        border-color: transparent ${(props) => props.theme.colors.greyMed} transparent transparent;
                        right: 100%;
                        left: unset;
                        top: calc(50% - 5px);
                    }
                `;
            default:
                return css``;
        }
    }}
`;

const TooltipWrapper = styled.div`
    
    display: inline-flex;
    width: fit-content;
    height: fit-content;
    z-index: 5;
    right: 38px;
    
`;

const CenterContainer = styled.div<{position: string}>`
    position: absolute;
    width: 200px;
    margin-left: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    bottom: calc(100% + 5px);
    pointer-events: none;

    ${({position}) => {
        switch (position) {
            case "bottom":
                return css`
                    bottom: unset !important;
                    top: calc(100% + 5px);
                `;
            case "left":
                return css`
                    margin-right: 0;
                    width: 100%;
                    left: unset;
                    top: 50%;
                    right: calc(100% + 5px);
                    width: max-content;
                `;
            case "right":
                return css`
                    margin-left: 0;
                    width: 100%;
                    top: 50%;
                    left: calc(100% + 5px);
                    width: max-content;
                `;
            default:
                return css`
                    bottom: calc(100% + 5px);
                `;
        }
    }}
`;

export interface TooltipProps {
    text: string;
    target: any;
    position: string;
    children?: React.ReactNode;
    
}

export const Tooltip: React.FC<TooltipProps> = ({text, target, position}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const showTooltip = isHovered;

    return (
        <TooltipWrapper className="tooltip-wrapper" data-testid="tooltip-card">
            <TooltipTarget className="tooltip-target" data-testid="tooltip-target"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >{target}</TooltipTarget>
            {showTooltip && (
                <CenterContainer className="tooltip-center-container" data-testid="tooltip-center-container" position={position}>
                    <TooltipBox className="tooltip-box" data-testid="tooltip-box" position={position}>{text}</TooltipBox>
                </CenterContainer>
            )}
        </TooltipWrapper>
    );
};