import styled from 'styled-components';

import { globalStyles } from '../../../../constants';

const mdDown = globalStyles.breakpoints.lg.up - 1;
const { yellow, blackMed } = globalStyles.colors;

export const StyledSiderMenu = styled.div`
  &::-webkit-scrollbar {
    width: 0px;
  }

  .ant-layout-sider-trigger {
    background: inherit;
  }

  overflow: auto;
  position: fixed;
  left: 0;
  top: 0px;
  bottom: 0px;
  z-index: 3;
  max-width: 101px;
  background-color: ${blackMed};

  .ant-menu-item {
    display: inline-flex;
  }

  .ant-menu-item > span > div,
  .ant-menu-submenu span > div {
    padding-left: 5px;
    display: inline-flex;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    margin: 0;
  }

  .add-icon {
    position: absolute;
    right: 35px;
    width: 25px;
    text-align: center;
    z-index: 9999 !important;
    cursor: pointer;
    font-size: 20px;
  }

  .journey-menu {
    li ul li {
      margin-top: 8px !important;
      margin-bottom: 0 !important;
    }
  }

  // sider css
  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: initial !important;
    color: #d8d8d8 !important;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: #000 !important;
  }

  ul li.ant-menu-submenu-open > div:hover {
    background-color: rgba(0, 20, 45, 0.3) !important;
  }

  ul li.ant-menu-submenu-active:not(.ant-menu-submenu-open):hover {
    background-color: rgba(0, 20, 45, 0.3) !important;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    display: inline-flex;
    margin-bottom: 8px;
  }

  .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 1;
  }

  .journey-menu.siderActive .ant-menu-submenu-title {
    margin-bottom: 6px !important;
    padding-top: 6px !important;
  }

  .journey-menu.siderActive .ant-menu-submenu-title,
  .ant-menu-dark .ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-group-title:hover,
  .ant-menu-submenu-active:hover,
  .ant-menu-dark .ant-menu-item > a:hover {
    color: #000 !important;
  }

  ul.ant-menu.left-menu.journey-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical {
    width: 100%;
  }

  // sider css
  li.ant-menu-item.siderActive.ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: ${yellow} !important;
  }

  @media (max-width: ${mdDown}px) {
    display: none;
  }
`;
