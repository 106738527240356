import React, { Suspense } from 'react';

const ShowImageFromDriveAdapter = React.lazy(
  () => import('../../../Common/Adapters/ShowImageFromDriveAdapter')
);

import { BodhiJourney } from '../../../../classes';
import { TRouteModel } from '../../../../constants/types';
import {
  HomeownerScanifly,
  PhaseMessageContent,
  HomeownerButton,
  UserDetail,
  MessageStatus,
  HomeownerShare,
  HomeownerMessageInterface
} from './';
import { suspenseFallback } from '../../../../constants/components';
import { getHomeownerRouteFromModel } from '../../../../shared';
import { IHomeownerMessageTouch } from './types';
import { TJourneyTouchPhase } from '../../../../types';
import SurveyPage from '../../container/statusFeed/survey';
import surveyType from '../../container/shared/survey.json';
/**
 * Provides rendering mechanics for a host of touch-related concerns
 *
 * @see https://17terawatts.atlassian.net/browse/BODHI-1381
 * For touches, use `requestHomewnerDocument` (is this the homeownerFileList prop?) response which contain URL  of documents,
 * `requestCompanyJourney` response which contain metaData info of touches
 * & inbox api to determine which touch it is and display the images in UI
 */
export const HomeownerMessageTouch: React.FC<IHomeownerMessageTouch> = (props) => {
  const { message, propsResolver } = props;
  const homeownerInterfaceProps =
    message && typeof propsResolver === 'function' ? propsResolver(message) : {};
  const { companyJourney, systemGlance, loggedInUser, isInstaller, router, wasRead, threadId } =
    homeownerInterfaceProps;

  const { sender, messageType } = message;
  const userDetails = {
    name: sender.name,
    title: sender.title,
    photo: sender.photo,
    messageType
  };

  const displayMessageInterface = message && Object.keys(homeownerInterfaceProps).length > 0;

  const homeownerJourney = new BodhiJourney(companyJourney);
  const dyId = Math.floor(100000 + Math.random() * 900000);

  const slug = router?.params?.slug;

  if (!message.touchName) {
    return null;
  }

  /**
   * Retrieves the touch specific to this message from the interface wrapped around the `companyJourney`
   * API response, if that touch exists.
   */
  const touchDetail = homeownerJourney.getTouchByName(message.touchName);
  if (!touchDetail) {
    return null;
  }

  /**
   * This is pretty gross, part of attempt to get everything to render.
   * This pulls the phase data
   */
  const resolvePhaseData: any = touchDetail.get('phase');
  let phase: TJourneyTouchPhase = {};
  if (resolvePhaseData) {
    if (Array.isArray(resolvePhaseData) && resolvePhaseData.length) {
      phase = resolvePhaseData[0];
    }
    if (!Array.isArray(resolvePhaseData)) {
      phase = resolvePhaseData;
    }
  }

  if (Object.keys(phase).length === 0) {
    return null;
  }
  
  /**
   * Conditions to disable Composer
   */
  if (displayMessageInterface && !phase.survey) {
    homeownerInterfaceProps.hideComposer = true;
  } else {
    homeownerInterfaceProps.hideComposer = false;
  }

  return (
    <div className="white-box-secondary margb15 spacingMobile">
      <div className="paddall25 pdding-left-zero pdding-right-zero">
        <UserDetail isCampaign={false} dyId={String(dyId)} userDetails={userDetails}>
          <MessageStatus unread={!wasRead} allowInstallerCustom={true} />
        </UserDetail>
        <HomeownerShare
          dyId={dyId}
          loggedInUser={loggedInUser}
          isInstaller={isInstaller}
          shareName="glance"
          isDefaultShow={false}
        />
        <Suspense fallback={suspenseFallback}>
          {phase && phase.content && (
            <PhaseMessageContent
              content= {message.message}
              customTouchData={touchDetail}
              touchData={message.createdAt} // [!] TODO: Verify this...
              systemGlance={systemGlance}
              loggedInUser={loggedInUser}
              urlParams={router?.params}
            />
          )}

          {phase && loggedInUser?.solarUnit?.scaniflyUrl && phase.isScanifly && (
            <HomeownerScanifly
              scaniflyUrl={loggedInUser.solarUnit && loggedInUser.solarUnit.scaniflyUrl}
            />
          )}

          {phase && (phase.isImages || phase.isLayout) && (
            <ShowImageFromDriveAdapter
              isOpen={phase.isImages || false}
              filterByName={phase.isLayout ? 'layout' || 'site plan' : ''}
              style={{ marginBottom: '80px' }}
            />
          )}
          {phase && phase.isButton && (
            <div className="text-center">
              <HomeownerButton
                className="btn yellow-btn premiumButtonFill premiumButtonText documents-button-status-feed align-center"
                disabled={!!isInstaller}
                clickHandler={(e) => {
                  e.stopPropagation();
                  if (slug) {
                    const routeModel = getHomeownerRouteFromModel(
                      'key',
                      phase.buttonUrl
                    ) as TRouteModel;
                    router.navigate(`/${slug}${routeModel.pathname || '/documents'}`);
                  }
                }}>
                {phase.buttonName || 'Click Here!'}
              </HomeownerButton>
              <br />
            </div>
          )}
          {phase && phase.survey && (
            <SurveyPage
              slug={slug}
              customTouchData={touchDetail}
              homeownerPhase={surveyType[String(phase.survey) as keyof typeof surveyType]}
              loggedInUser={loggedInUser}
            />
          )}
        </Suspense>
        <br />
        <br />
        {displayMessageInterface && (
          <HomeownerMessageInterface
            {...homeownerInterfaceProps}
            message={message}
            threadId={threadId}
            installerId = {loggedInUser?.homeownerData?.installerId}
          />
        )}
      </div>
    </div>
  );
};

export default HomeownerMessageTouch;
