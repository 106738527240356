import styled from 'styled-components';
import { Modal } from 'antd';

export const GreyStyledButton = styled.button`
    padding: 10px;
    font-size: ${(props) => props.theme.fonts.sizes.font14};
    width: 150px;
    height: 40px;
    border-radius: ${(props) => props.theme.borderRadius.standard} !important;
    border: 1px solid ${(props) => props.theme.colors.greyMed};
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};
    float: right;
    margin: 10px 20px 10px 0px;

    :hover {
        background-color: ${(props) => props.theme.colors.greyMed};
    }
`;

export const StyledModal = styled(Modal)`
    top: 19%;
    height: 375px !important;
    width: 590px !important;
    box-sizing: border-box;
    .ant-modal-wrap {
        border: 1px solid ${(props) => props.theme.colors.labelborder};
        background-color: ${(props) => props.theme.colors.white};
        border-radius: ${(props) => props.theme.borderRadius.rad08};
        
    }
    .ant-modal-title {
        color: ${(props) => props.theme.colors.standardWhite};
        align-self: center;
        color: ${(props) => props.theme.colors.black};
        font-size: ${(props) => props.theme.fonts.sizes.font24};
        text-align: center;
        letter-spacing: 0;
        line-height: 29px;
        padding-top: 50px;
    }
    .ant-modal-header {
        justify-content: space-between;
        border-bottom: none;
        padding: 0;
        border-top-left-radius: ${(props) => props.theme.borderRadius.rad08};
        border-top-right-radius: ${(props) => props.theme.borderRadius.rad08};
    }
    .ant-modal-content {
        margin: auto;
        border-radius: ${(props) => props.theme.borderRadius.rad08};
        height: 375px;
    }
    .ant-modal-body {
        border: none;
        align-items: center;
    }
    .ant-modal-close-x {
        color: ${(props) => props.theme.colors.black};
    }
`;