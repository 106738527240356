import { Services } from '../../../Api/services';
import * as config from '../../../shared/config';

export const PostShareDetails = async (data) => {
  const url = `${config.SOCIAL_SHARE_BASE_URL}company/${data.companyId}/homeowner/${data.homeownerId}/share`;

  const bodyData = {
    method: data.method,
    share: data.share
  };
  return await Services.postCall(url, bodyData, false);
};
