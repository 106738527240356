import React, { useState, useEffect } from 'react';
import { Select, AutoComplete } from 'antd';

const { Option } = Select;

import { Decorator } from './SearchInterface.style';
import { ISearchInterface, ISearch, TSearchResponse } from '../types';
import { TInboxApiResponse } from '../../Installer/components/Sider/types';
import { adminSearchHomeowner, searchApi as adminSearchApi } from '../../Admin/Api/header';
import { searchApi as installerSearchApi, searchHomeownersByQuery } from '../../Installer/api';
import { SEARCH_CHAR_THRESHOLD, SEARCH_KEY_INBOX_HOMEOWNER } from '../../../constants';
import { searchIconSvg } from '../../../constants/icons';
import { VariableResolutionException } from '../../../exceptions';

/**
 * Valid contextual search mechanisms
 *
 * Type => corresponding GET function
 */
const searchMethods: Record<string, any> = {
  admin: {
    homeowner: adminSearchHomeowner,
    company: adminSearchApi
  },
  installer: {
    homeowner: installerSearchApi,
    [SEARCH_KEY_INBOX_HOMEOWNER]: searchHomeownersByQuery
  }
};

/**
 * A fallback search results factory, e.g. homeowner results
 */
const defaultFactory = (item: Record<string, any>, index?: number): React.ReactNode => {
  return (
    <Option key={item.id} value={JSON.stringify(item)}>
      {`${item.firstName} ${item.lastName} - ${item.email}`}
    </Option>
  );
};

const nullResults: TSearchResponse = { data: [] };
const nullSearchTerm = '';

/**
 * The interface for implementing different contextual search functionalities
 */
export const SearchInterface: React.FC<ISearchInterface> = (props) => {
  const { searchIcon, resultFactory, dataFilterFn, handleSelectResult, config, placeholder } =
    props;

  const { scope, type, companyId, installerId } = config;

  const searchMethod = searchMethods?.[scope]?.[type];

  if (typeof searchMethod !== 'function') {
    throw new VariableResolutionException(
      `SearchInterface failed to resolve 'searchMethod' - given "${scope}" and "${type}"`
    );
  }

  const [searchResults, setSearchResults] = useState<TSearchResponse>(nullResults);
  const [searchTerm, setSearchTerm] = useState<string>(nullSearchTerm);

  return (
    <Search
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      searchIcon={searchIcon || searchIconSvg}
      placeholder={`${placeholder ? placeholder : 'Search ' + String(type)}`}
      handleSelectResult={handleSelectResult}
      setSearchResults={setSearchResults}
      resultFactory={resultFactory || defaultFactory}
      searchResults={searchResults}
      searchMethod={searchMethod}
      type={String(type)}
      companyId={Number(companyId)}
      installerId={Number(installerId)}
      dataFilterFn={dataFilterFn}
    />
  );
};

/**
 * The concern for handling a fetch from an API endpoint, and displaying results
 */
const Search: React.FC<ISearch> = (props) => {
  const {
    type,
    searchIcon,
    handleSelectResult,
    searchMethod,
    searchResults,
    setSearchResults,
    resultFactory,
    dataFilterFn,
    placeholder,
    companyId,
    installerId,
    searchTerm,
    setSearchTerm
  } = props;

  const searchHandler = async (value: any) => {
    /**
     * It appears `antd` autocomplete sends an object with an empty array on the first keypress, so...
     */
    if (
      typeof value === 'object' &&
      value.data &&
      Array.isArray(value.data) &&
      !value.data.length
    ) {
      return;
    }
    value = encodeURIComponent(value);
    if (value.length < SEARCH_CHAR_THRESHOLD) {
      if (value.length === 0) {
        setSearchResults(nullResults);
      }
      return;
    }

    let args;

    switch (type) {
      case 'homeowner':
        args = {
          companyId,
          installerId,
          value
        };
        break;
      case 'inbox':
        args = {
          search: value
        };
        break;
      default:
        args = value;
    }

    let results;
    try {
      results = await searchMethod(args);
    } catch (e) {
      /**
       * @todo: Solve for browser back button in case of installer mobile search, which doesn't pass companyId, installerId
       */
      console.error(`There was a problem with the request: ${args.toString()} - ${e.toString()}`);
      // Temporary mitigation for a problem where the component isn't re-rendered...
      // window.location.reload();
      return;
    }

    if (results && results.data) {
      setSearchResults(results);
    }
  };

  /**
   * A "MVP" implementation that works
   *
   * @todo: clean this up, make it work right
   */
  useEffect(() => {
    if (dataFilterFn) {
      if (searchTerm.length >= SEARCH_CHAR_THRESHOLD) {
        const results: TInboxApiResponse = searchResults.data as TInboxApiResponse;
        (results || searchTerm) && dataFilterFn(results, searchTerm);
      } else {
        dataFilterFn({}, searchTerm);
      }
    }
  }, [searchTerm, searchResults]);

  return (
    <Decorator>
      <form className="search-interface">
        <div className={`unified-search-ctrl ${type}`}>
          <div className="searchImageIcon">
            <img src={searchIcon} alt={`A graphic representing searching for ${type}`} />
          </div>
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={false}
            onSelect={(value: any, option: any) => {
              typeof handleSelectResult === 'function' && handleSelectResult(option, type);
            }}
            onSearch={(value: string) => {
              setSearchTerm(value);
              searchHandler(value);
            }}
            placeholder={placeholder}
            onFocus={() => {
              setSearchTerm(nullSearchTerm);
              searchHandler(nullResults);
            }}
            value={searchTerm}>
            {!dataFilterFn && searchResults?.data.length && searchResults.data.map(resultFactory)}
            {!dataFilterFn && searchResults?.data.length === 0 && (
              <Option value={false}>No results found</Option>
            )}
          </AutoComplete>
        </div>
      </form>
    </Decorator>
  );
};

export default SearchInterface;
