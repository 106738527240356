import React from 'react';
import { useSelector } from 'react-redux';

import { SearchInterface, SearchFilterInterface } from '../';
import { SearchBackgroundDecor } from './SearchInterface.style';

import { SEARCH_FILTER_KEY, SEARCH_KEY_INBOX_HOMEOWNER } from '../../../constants';
import type { RootState } from '../../../store';
import { TSearchConfig } from '../../../store/types';
import { ISearchGlobal, ISearchBgDecor } from '../types';

const BackgroundDecorator: React.FC<ISearchBgDecor> = (props) => {
  const { type } = props.config;
  const position = type === SEARCH_KEY_INBOX_HOMEOWNER ? 'fixed' : '';

  return <SearchBackgroundDecor position={position}></SearchBackgroundDecor>;
};

/**
 * An implementation of SearchInterface for the global search in the header, and for an installer mobile route
 */
export const SearchGlobal: React.FC<ISearchGlobal> = (props) => {
  const { handleSelectResult, resultFactory, placeholder } = props;
  const configuredSearch: TSearchConfig = useSelector((state: RootState) => state.searchInterface);

  const { scope, type, companyId, installerId, mechanism, dataToFilter, dataFilterFn } =
    configuredSearch;

  if (mechanism === SEARCH_FILTER_KEY && !!dataToFilter && typeof dataFilterFn === 'function') {
    return (
      <div className="search-wrap installer">
        <BackgroundDecorator config={{ scope, type, mechanism }} />
        <SearchFilterInterface config={configuredSearch} placeholder={placeholder} />
      </div>
    );
  }

  return (
    <div className="search-wrap installer">
      <BackgroundDecorator config={{ scope, type, mechanism }} />
      <SearchInterface
        config={{
          scope,
          type,
          companyId,
          installerId
        }}
        placeholder={placeholder}
        dataFilterFn={dataFilterFn}
        resultFactory={resultFactory}
        handleSelectResult={handleSelectResult}
      />
    </div>
  );
};

export default SearchGlobal;
