import React, { useState } from 'react';
import { Modal, Button } from 'antd';

export type IThemeType = 'light' | 'dark';
interface IDarkModal {
  closePopUp:()=>void;
  headerText: string;
  subText:string;
  footerType?:string;
  width?:number;
  theme:IThemeType;
  setPhaseTemplate:()=>void;
  setInboxMessage:()=>void;
}

const templateUpgrade = (theme:string) =>{
  return (
    <>
      <a className={`btn btn-secondary ${theme}-btn`} href='https://support.bodhi.solar/knowledge' target='_blank'>
      Learn more
      </a>
      <a className="btn btn-primary" href='https://meetings.hubspot.com/yana-myaskovskaya' target='_blank'>
      Contact us
      </a>
    </>
  )
}

const installerWelcome = (closePopUp: any) => {
  return (
    <Button
      key="button2"
      className="btn btn-primary"
      onClick={() => {
        closePopUp();
      }}
    >
      OK
    </Button>
  )
}

const defaultFooter = (closePopUp:any) =>{
  return(
    <>
    <Button
            key="button1"
            className="btn btn-secondary dark-theme-btn"
            onClick={() => {
              closePopUp();
            }}
          >
            Button 1
          </Button>
          <Button
            key="button2"
            className="btn btn-primary"
            onClick={() => {
              closePopUp();
            }}
          >
            Button 2
          </Button>
    </>
  )
}




export const DarkModal: React.FC<IDarkModal> = (props) => {
  const {
    closePopUp,
    headerText,
    subText,
    footerType,
    width,
    theme,
    setPhaseTemplate,
    setInboxMessage
  } = props;
  const [popUpOpen, setPopUpOpen] = useState<boolean>(true);

  const templateConfirmation = () => {
    return (
      <>
        <Button
          key="button1"
          className={`btn btn-secondary ${theme}-theme-btn`}
          onClick={() => {
            setInboxMessage();
            closePopUp();
          }}
        >
          Send message only
        </Button>
        <Button
          key="button2"
          className="btn btn-primary"
          onClick={() => {
            setPhaseTemplate();
            closePopUp();
          }}
        >
          Send and advance
        </Button>
      </>
    )
  }

  const renderFooterType = (footerType:string) =>{
    switch(footerType){
      case 'templateUpgrade':
        return templateUpgrade(theme);

      case 'templateConfirmation':
        return templateConfirmation();

      case 'installerWelcome':
        return installerWelcome(closePopUp);

      default:
        return defaultFooter(closePopUp);
    }
  }
  return (
    <Modal
      open={popUpOpen}
      width = {width || 550}
      wrapClassName={`${theme}-theme popUp`}
      closable={false}
      onCancel={() => {
        setPopUpOpen(false);
        closePopUp();
      }}

      // differernt footer can be added on basis of footerType value
      footer={[
        renderFooterType(footerType)
      ]}
    >
      <h2 className="header">{headerText}</h2>
      <p className='mt-4'>{subText}</p>
    </Modal>
  );
};

