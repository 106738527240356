//import npm packages
import React, { Component } from 'react';

import yellowCheckIcon from "./../../../../Assets/Images/check-yellow.svg"

export default class Success extends Component {
  constructor(props) {
    super(props);
    this.slug = "";
  }

  componentWillMount() {
    this.slug = localStorage.getItem("slug");
  }
  render() {
    return (
      <React.Fragment>
        <title>Homeowner Added - Bodhi</title>
        <div>
          <section>
            <div className="container">
              <div className="success-account-wrapper">
                <div className="white-box success-account-account-box text-center">
                  <div className="tick-box text-center">
                    <img src={yellowCheckIcon} alt='check-success.png' />
                  </div>
                  <h3>SUCCESS!</h3>
                  <p>{this.props.name}'s Bodhi account was created! <br />
                    An email with login instructions was sent to {this.props.email}</p>
                  <div className="footer-btn" onClick={() => {
                    this.props.setMetricsType(null);
                    this.props.goToDashboard();
                    }}>
                    <form >
                      <button className="btn  btn-primary">DONE</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    )
  }
}