import { useState, useEffect } from 'react';
import {
  getHomeownerEnergyReport,
  getHomeownerFinancialReport,
  getHomeownerPastEnergyReport
} from '../../../homeowner/api/homeOwner';
import { fetchHomeownerDocumentsList, fetchHomeownerSatisfactionScore, getHomeOwnerApi, getHomeownerGetNoteData } from '../../api/homeOwner';
import { IHomeownerEversign, IHomeownerFinancial, ILiteHomeownerDocuments, INote } from '../inbox/types';
import {
  IHomeownerDataResponse,
  IPastEnergyReportResponse,
  IEnergyReportResponse,
  ISurveyScoreResponse
} from './types';
import { getSavedEversignData } from '../../../homeowner/api/eversign';

export function useHomeownerData(
  homeownerId: number,
  companyId: number,
  homeownerDataRequest:boolean
): [IHomeownerDataResponse | null, Error | null] {
  const [data, setData] = useState<IHomeownerDataResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {

    if (homeownerId) {
      const fetchHomeownerData = async () => {
        try {
          const response = await getHomeOwnerApi({
            companyId,
            homeOwnerId: homeownerId
          });
          setData(response.data);
        } catch (error) {
          setError(error);
        }
      };
      fetchHomeownerData();
    }

    return () => {
      setError(null);
    };
  }, [homeownerId,homeownerDataRequest]);

  return [data, error];
};

export function useHomeownerPastEnergyReport(
  homeownerId: number
): IPastEnergyReportResponse | null {
  const [data, setData] = useState<IPastEnergyReportResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    if (homeownerId) {
      const getPastEnergyReport = async () => {
        try {
          const response = await getHomeownerPastEnergyReport({
            homeownerId
          });
          setData(response.data);
        } catch (error) {
          setError(error);
        }
      };
      getPastEnergyReport();
    }
  }, [homeownerId]);
  return data;
}

export function useHomeownerEnergyReport(homeownerId: number): IEnergyReportResponse | null {
  const [data, setData] = useState<IEnergyReportResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    if (homeownerId) {
      const getEnergyReport = async () => {
        try {
          const response = await getHomeownerEnergyReport({
            homeownerId,
            sync: true
          });
          setData(response.data);
        } catch (error) {
          setError(error);
        }
      };
      getEnergyReport();
    }
  }, [homeownerId]);
  return data;
}

export function useHomeownerNotes(homeownerId: number,noteRequest:boolean): INote[] | null {
  const [data, setData] = useState<INote[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    if (homeownerId) {
      const getHomeownerNotes = async () => {
        try {
          const response = await getHomeownerGetNoteData({
            homeownerId
          });
          setData(response.data);
        } catch (error) {
          setError(error);
        }
      };
      getHomeownerNotes();
    }
  }, [noteRequest,homeownerId]);

  return data;
}


export function useHomeownerSatisfactionScore(homeownerId: number,companyId:number): ISurveyScoreResponse {
  const [data, setData] = useState<ISurveyScoreResponse>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (homeownerId && companyId) {
      const getHomeownerSatisfactionScore = async () => {
        try {
          const response = await fetchHomeownerSatisfactionScore({homeownerId,companyId});
          setData(response);
        } catch (error) {
          setError(error);
        }
      };
      getHomeownerSatisfactionScore();
    }
  }, [homeownerId]);
  return data;
}

export function useHomeownerDocuments(homeownerId: number,companyId:number,fetchHomeownerDocuments:boolean): ILiteHomeownerDocuments[] {
  const [data, setData] = useState<ILiteHomeownerDocuments[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    if (homeownerId) {
      const getHomeownerDocumentsList = async () => {
        try {
          const response = await fetchHomeownerDocumentsList({homeownerId,companyId});
          response?.sort((file1:ILiteHomeownerDocuments,file2:ILiteHomeownerDocuments)=>new Date(file2.uploadedAt).getTime()-new Date(file1.uploadedAt).getTime());
          setData(response);
        } catch (error) {
          setError(error);
        }
      };
      getHomeownerDocumentsList();
    }
  }, [homeownerId,fetchHomeownerDocuments]);
  return data;
}

export function useHomeownerEversign(homeownerId: number,companyId:number,fetcHomeownerEversign:boolean): IHomeownerEversign[] {
  const [data, setData] = useState<IHomeownerEversign[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    if (homeownerId) {
      const getSavedEversignDataList = async () => {
        try {
          const response = await getSavedEversignData({homeownerId,companyId});
          setData(response.data);
        } catch (error) {
          setError(error);
        }
      };
      getSavedEversignDataList();
    }
  }, [homeownerId,fetcHomeownerEversign]);
  return data;
}

export function useHomeownerFinancialReport(homeownerId:number,isSunlight:boolean):IHomeownerFinancial[]{
  const [data, setData] = useState<IHomeownerFinancial[] | null>(null);
  const [error, setError] = useState<Error | null>(null);  
  useEffect(() => {
    if (homeownerId && isSunlight) {
      const getSavedEversignDataList = async () => {
        try {
          const response = await getHomeownerFinancialReport({homeownerId});
          setData(response.data);
        } catch (error) {
          setError(error);
        }
      };
      getSavedEversignDataList();
    }
  }, [homeownerId]);
  return data;

}