import React from 'react';
import PropTypes from 'prop-types';
import envConfig from '../../environments/index';
import { config } from 'aws-cognito-redux-saga';
class Auth extends React.Component {
  static propTypes = {
    getUser: PropTypes.func
  }

  componentWillMount() {
    config.config.set(envConfig.envConfig.cognito)
    this.props.getUser()
  }

  render() {
    return null
  }
}

export default Auth
