import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';

import { Counter } from '../../../Common';
import { suspenseFallback } from '../../../../constants/components';
import type { RootState } from '../../../../store';
import {
  homeOwnerCommActiveSvg,
  homeOwnerCommInactivePng,
  homeOwnerSearchActiveSvg,
  homeOwnerSearchInactiveSvg,
  homeOwnerDashActiveSvg,
  homeOwnerDashInactivePng
} from '../../../../constants/icons';

import {
  CountDecoratorStandard,
  CountDecoratorJumbo
} from '../../../homeowner/components/footer/decorators.style';

import { IHomeownerFooter } from './types';

export const HomeownerFooter: React.FC<IHomeownerFooter> = (props) => {
  const { clickHandler, setIcons, currentActive } = props;

  const counts = useSelector((state: RootState) => state.inboxMessages.counts);

  return (
    <div className="footer-17">
      <span className="unread-wrapper">
        <img
          src={currentActive === 'dashboard' ? homeOwnerDashActiveSvg : homeOwnerDashInactivePng}
          onClick={() => {
            clickHandler(
              {
                isSlidesReset: true,
                isDashboard: true,
                select: 'dashboard'
              },
              () => {
                setIcons();
              }
            );
          }}
          alt="Dashboard"
        />
        <Suspense fallback={suspenseFallback}>
          <Counter
            number={counts.inbox || 0}
            decorator={counts.inbox > 99 ? CountDecoratorJumbo : CountDecoratorStandard}
          />
        </Suspense>
      </span>
      <img
        src={
          currentActive === 'systemGlance' ? homeOwnerSearchActiveSvg : homeOwnerSearchInactiveSvg
        }
        onClick={() => {
          clickHandler(
            {
              isSlidesReset: true,
              isDashboard: false,
              select: 'systemGlance'
            },
            () => {
              setIcons();
            }
          );
        }}
        alt="systemGlance"
      />
      <img
        src={currentActive === 'solarCommunity' ? homeOwnerCommActiveSvg : homeOwnerCommInactivePng}
        style={{ width: '32px' }}
        onClick={() => {
          clickHandler(
            {
              isSlidesReset: true,
              isDashboard: false,
              select: 'solarCommunity'
            },
            () => {
              setIcons();
            }
          );
        }}
        alt="solarCommunity"
      />
    </div>
  );
};

export default HomeownerFooter;
