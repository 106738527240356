import React from 'react';

import { UserIdentifier, HomeownerIdentifierDecorator } from '../../../Common';
import { ReactComponent as ShareIcon } from '../../../../Assets/Images/outline-share-24-px.svg';
import { IUserDetail } from './types';
import { IUserIdentifier } from '../../../Common/types';
import { getParsedUserDetails } from '../../../Installer/components/inbox/helpers';

export const UserDetail: React.FC<IUserDetail> = (props) => {
  const { dyId, isCampaign, children, userDetails } = props;

  const { name, photo, title, messageType } = userDetails;
  const { userPhoto: parsedUserPhoto, userInitials } = getParsedUserDetails(
    name,
    photo,
    messageType,
    'homeowner'
  );

  const userIdProps: IUserIdentifier = {
    decorator: HomeownerIdentifierDecorator,
    allowInstallerCustom: true,
    userInitials,
    image: parsedUserPhoto,
    displayBodhiLogo : false
  };

  return (
    <div className="media share-comments">
      <UserIdentifier {...userIdProps}>{children}</UserIdentifier>

      {isCampaign === false && (
        <div className="share-link status-feed-share">
          <a
            href="#"
            data-bs-toggle="collapse"
            data-bs-target={`#collapseShareIcons-status-feed-${dyId}`}
            aria-expanded="true"
            aria-controls={`collapseShareIcons-status-feed-${dyId}`}>
            <ShareIcon />
          </a>
        </div>
      )}
      <div className="media-body">
        <h5 className="mt-0">{name}</h5>
        <p>{title}</p>
      </div>
    </div>
  );
};

export default UserDetail;
