import styled from 'styled-components';

import { globalStyles } from '../../../../constants';
import { StyledSiderMenu } from './';

const { greyLight, greyBorder, blackMed } = globalStyles.colors;
const { standardBold } = globalStyles.fonts;

export const StyledSiderMenuSub = styled(StyledSiderMenu)`
  height: 100vh;
  width: 250px;
  .ant-menu {
    background-color: ${greyLight};
  }
  border-right: 1px solid ${greyBorder};
  .ant-menu-inline {
    border-right: inherit;
  }
  max-width: initial !important;
  left: 100px !important;
  top: 80px !important;
  bottom: auto !important;
  background-color: ${greyLight} !important;
  padding-top: 20px;
  li.ant-menu-item,
  .ant-menu-submenu-selected {
    color: ${blackMed};
    line-height: 20px;
    font-size: 14px;
  }
  .ant-menu-inline .ant-menu-item {
    height: 38px;
    &:hover {
      background-color: rgba(0, 20, 45, 0.3) !important;
      color: ${blackMed} !important;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent; // for nested menu, 'visited' state
  }
  .ant-menu-inline .ant-menu-submenu {
    margin-bottom: 8px;
  }
  .ant-menu-submenu-active {
    background-color: initial !important;
  }
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: rgba(0, 0, 0, 0.85);
  }

  li.ant-menu-item.siderActive.ant-menu-item-selected {
    background-color: rgba(0, 20, 45, 0.3) !important;
    color: ${blackMed} !important;
    font-family: '${standardBold}';
  }
  .ant-menu-inline .ant-menu-item::after {
    display: none;
  }
  svg.sider-icon {
    margin-right: 12px;
    height: 20px !important;
    margin-top: 0;
    width: 20px;
    &.utilityIcon {
      margin-top: -5px;
    }
  }
`;
