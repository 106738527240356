import React, { Component } from 'react';
import { Form, Input, Button, Tooltip } from 'antd';
import Slider from 'react-slick';
import * as moment from 'moment';
import UserAvatar from 'react-user-avatar';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import AdminMap from '../../../Admin/components/Map/map';
import { checkIsMobile } from '../../../../shared/checkIsMobile';
import { capitalize } from '../../../../shared/capitalize';
import { generateTimeBasedGreeting } from '../../../../shared/generateGreetings';
import { convertJSON2CSS } from '../../../User/components/setUserBackgroud';
import { PostShareDetails } from '../../api/promiseApi';
import { ReactComponent as Group_of_people_Icon } from '../../../../Assets/Images/group_of_people_yellow.svg';
import { ReactComponent as Saving_Icon } from '../../../../Assets/Images/savings_icon.svg';
import { ReactComponent as SUN_Icon } from '../../../../Assets/Images/sun-icon.svg';
import { ReactComponent as Electric_Icon } from '../../../../Assets/Images/electric-icon.svg';
import { ReactComponent as Solar_Icon } from '../../../../Assets/Images/solar-icon.svg';
import { ReactComponent as Arrow_Icon } from '../../../../Assets/Images/arrow_right.svg';
import { ReactComponent as Dot1_Icon } from '../../../../Assets/Images/carouselDot1.svg';
import { ReactComponent as Dot2_Icon } from '../../../../Assets/Images/carouselDot2.svg';
import { ReactComponent as Dot3_Icon } from '../../../../Assets/Images/carouselDot3.svg';
import { ReactComponent as Dot4_Icon } from '../../../../Assets/Images/carouselDot4.svg';
import { ReactComponent as Link_Icon } from '../../../../Assets/Images/link_icon.svg';
import facebook from '../../../../Assets/Images/facebook.svg';
import twitter from '../../../../Assets/Images/twitter.svg';
import arrow from '../../../../Assets/Images/arrow.svg';

const FormItem = Form.Item;
const { TextArea } = Input;

class OnBoarding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slideNumber: 0,
      onboardMessage: null
    };
    this.next = this.next.bind(this);
    this.location = {};
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  componentDidMount() {
    const { loggedInUser: { homeownerData } } = this.props;
    convertJSON2CSS(this.slug, '',homeownerData?.email);
  }

  beforeClick(method) {
    const {
      loggedInUser: { homeownerData, solarUnit }
    } = this.props;

    const bodyData = {
      homeownerId: solarUnit?.homeownerId,
      companyId: homeownerData?.companyId,
      method,
      share: 'referral'
    };
    return PostShareDetails(bodyData);
  }

  headerText(screenId, installerTitle) {
    switch (screenId) {
      case 2:
        return {
          text1: 'Here’s what we’re building',
          text2: `This system will harness the sun to power your home.`
        };
      case 3:
        return {
          text1: 'Stay in the loop',
          text2: `Get real time updates about what’s happening with your project. Send your ${
            installerTitle || 'project manager'
          } a quick hello message.`
        };
      case 4:
        return {
          text1: 'Share the sun',
          text2: `Invite a friend to also go solar and grow your community's impact.`
        };
    }
  }

  updateState = () => {
    if (!this.state.zoom) this.setState({ zoom: true });
  };

  renderMap(solarUnit, renderGlanceSlide) {
    if (!this.state.zoom) {
      return (
        <AdminMap
          updateState={this.updateState}
          completeMap
          {...this.location}
          onBoarding
          zoom={checkIsMobile() ? 17 : 20}
        />
      );
    }
    if (!renderGlanceSlide) {
      return this.renderOnboard3();
    }
    return this.renderOnboard2(solarUnit);
  }

  renderOnboardHeader(screenId, installerTitle) {
    const headerData = this.headerText(screenId, installerTitle);
    return (
      <>
        <img className="company-icon" src={this.mobileIcon} />
        <p className="title">{headerData.text1}</p>
        <p className={`sub-title ${screenId === 2 ? 'space' : ''}`}>{headerData.text2}</p>
      </>
    );
  }

  referralTypeText(systemGlance) {
    const {
      loggedInUser: { homeownerData }
    } = this.props;
    let companyName = homeownerData?.companyName;

    if (companyName?.charAt(companyName.length - 1) === '.') {
      companyName = companyName.replace(/.$/, '');
    }

    switch (systemGlance.solarGlance.referralType) {
      case 2:
        if (systemGlance.solarGlance.referralAmount) {
          return {
            1: ' GET ',
            2: `Invite friends, family, and neighbors to go solar! Get $${systemGlance.solarGlance.referralAmount} when a friend goes solar with ${companyName}.`,
            3: ` Click the button below to text them your personalized referral page.`
          };
        }
        return {
          1: '',
          2: `Invite a friend to go solar with ${companyName}.`,
          3: ` Click the button below to text a friend your personalized referral page.`
        };

      default:
        if (systemGlance.solarGlance.referralAmount) {
          return {
            1: ' GIVE YOUR FRIEND ',
            2: `Invite friends, family, and neighbors to go solar! Gift yourself and a friend $${systemGlance.solarGlance.referralAmount} each when they go solar with ${companyName}.`,
            3: ` Click the button below to text a friend your personalized referral page.`
          };
        }
        return {
          1: '',
          2: `Invite a friend to go solar with ${companyName}.`,
          3: ` Click the button below to text a friend your personalized referral page.`
        };
    }
  }

  renderOnboard4(systemGlance, homeownerData) {
    const { referralId } = homeownerData;
    const referralText = (systemGlance && this.referralTypeText(systemGlance)) || {};
    return (
      <div className="onboard-block">
        {this.renderOnboardHeader(4)}
        <div className="content-block border white-box-secondary">
          <div className="media mb-0">
            <div className="dashboard-profile-image" style={{ width: '55px' }}>
              <div className="profile-image" style={{ height: '42px' }}>
                <Group_of_people_Icon style={{ height: '100%' }} />
              </div>
            </div>

            <div className="media-body">
              <h5 className={`mb-2 ${systemGlance.solarGlance.referralAmount ? '' : 'mt-2'}`}>
                SHARE THE SUN.{referralText[1]}{' '}
                {systemGlance.solarGlance.referralAmount
                  ? `$${systemGlance.solarGlance.referralAmount}`
                  : ''}
              </h5>
              <p className="desktop-view">{referralText[2]}</p>
            </div>
          </div>
          {checkIsMobile()
            ? this.shareSunMobile(referralId, referralText[2] + referralText[3])
            : this.shareSunDesktop(referralId)}

          <div className="row m-0 onboard-step">
            <div className="skip-button">
              <p
                className="skip-text"
                onClick={() => {
                  this.props.updateLocalStorage(
                    this.state.changeMessage,
                    this.state.onboardMessage
                  );
                }}>
                Skip
              </p>
              <div className="footer-button">
                <Dot4_Icon />
              </div>

              <div
                onClick={() => {
                  this.props.updateLocalStorage(
                    this.state.changeMessage,
                    this.state.onboardMessage
                  );
                }}
                className="footer-button next-icon mt-0">
                <Arrow_Icon />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  shareSunMobile(referralId, referralText) {
    return (
      <>
        <span className="text">{referralText}</span>
        <div className="text-center">
          <a
            onClick={() =>
              this.setMessageHref(
                `sms:?&body=Hey, I've gone solar and thought you might be interested. This is a link to my solar community. Check it out!${window.location.origin}/${this.slug}/referral/${referralId}/referral/3`,
                3
              )
            }
            href="#"
            className="message">
            <button
              style={{ padding: '0 35px' }}
              className="btn premiumButtonFill premiumButtonText documents-button-status-feed auto-width">
              Invite via text
            </button>
          </a>
        </div>
      </>
    );
  }

  setMessageHref(url, method) {
    this.beforeClick(method)
      .then((res) => {})
      .catch((e) => {
        console.log('error', e.toString());
      });
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  shareSunDesktop(referralId) {
    const referralUrl = `${window.location.origin}/${this.slug}/referral/${referralId}/referral/3`;
    return (
      <div className="share-via-wrapper border-0 share-sun">
        <div className="heading-share"> Invite via </div>
        <ul id="share-media-box" className="white-box share-via-wrapper">
          <li>
            <FacebookShareButton
              beforeOnClick={() => this.beforeClick(1)}
              quote="Hey, I've gone solar and thought you might be interested. This is a link to my solar community. Check it out!"
              url={`${window.location.origin}/${this.slug}/referral/${referralId}/referral/1`}>
              <a
                // href="#"
                className="facebook">
                <img src={facebook} alt="facebook.svg" width="25" />
              </a>
            </FacebookShareButton>
          </li>

          <li>
            <TwitterShareButton
              beforeOnClick={() => this.beforeClick(2)}
              title="Hey, I've gone solar and thought you might be interested. This is a link to my solar community. Check it out!"
              url={`${window.location.origin}/${this.slug}/referral/${referralId}/referral/2`}>
              <a href="#" className="facebook">
                <img src={twitter} alt="twitter.svg" width="25" />
              </a>
            </TwitterShareButton>
          </li>
        </ul>

        <div className="heading-share"> Share your unique link </div>
        <Input
          name="identifier"
          type="text"
          className="referral-link"
          value={referralUrl}
          disabled
          style={{ cursor: 'pointer' }}
          onClick={() => navigator.clipboard.writeText(referralUrl)}
        />
        <Tooltip
          overlayClassName="black-tooltip"
          title="Link copied!"
          trigger="click"
          placement="top">
          <div className="img-block premiumIcon bgColor">
            <div className="copy-icon" onClick={() => navigator.clipboard.writeText(referralUrl)}>
              <Link_Icon />
            </div>
          </div>
        </Tooltip>
      </div>
    );
  }

  renderOnboard3 = () => {
    const {
      loggedInUser: { homeownerData }
    } = this.props;
    const titleValue = homeownerData && homeownerData.companyName;
    let photo = homeownerData && homeownerData.photo;
    if (photo && photo.includes('installer-avatar')) {
      photo = null;
    }
    const installerName =
      (homeownerData && homeownerData.installerName && capitalize(homeownerData.installerName)) ||
      (titleValue && capitalize(titleValue));

    const imageSyle = {
      backgroundImage: photo ? `url(${photo})` : ''
    };
    return (
      <div className="onboard-block">
        {this.renderOnboardHeader(3, homeownerData.installerTitle)}
        <div className="content-block border white-box-secondary">
          <div className="media">
            {photo ? (
              <div className="dashboard-profile-image" style={imageSyle} />
            ) : (
              <UserAvatar
                className="dashboard-profile-image premiumIconBackGround"
                size="40"
                name={installerName || 'Installer'}
              />
            )}

            <div className="media-body">
              <h5 className="mt-0">{homeownerData.installerName}</h5>
              <p>{homeownerData.installerTitle}</p>
            </div>
          </div>
          <span className="welcome-text">
            Hey
            {homeownerData.installerName
              ? `, I'm ${homeownerData.installerName}`
              : homeownerData.firstName}
            . 👋 I'll be your focal point from {homeownerData.companyName} during your solar
            journey. Say hello by replying to this message. I'll send you an update on the status of
            your project soon.
          </span>

          <TextArea
            className="textarea pm-message"
            placeholder="Type message here..."
            disabled={this.state.changeMessage}
            value={this.state.onboardMessage}
            onChange={(e) => this.setState({ onboardMessage: e.target.value })}
            rows={3}
          />
          <span className="pull-right btn-margin-top">
            <button
              className={`status-feed-send premiumLinks ${this.state.changeMessage && 'sent'}`}
              type="submit"
              disabled={this.state.changeMessage}
              onClick={(e) => {
                e.preventDefault();
                const { onboardMessage } = this.state;
                if (onboardMessage || onboardMessage !== '') {
                  setTimeout(() => {
                    this.next();
                  }, 900);
                }
                this.setState({ changeMessage: true });
                this.props.updateLocalStorage(false, this.state.onboardMessage, true);
              }}>
              {this.state.changeMessage ? 'Sent' : 'Send'}
              {this.state.changeMessage && <i className="fa fa-check sent" aria-hidden="true" />}
            </button>
          </span>

          <div className="row m-0 onboard-step">
            <div className="skip-button">
              <p
                className="skip-text"
                onClick={() => {
                  this.props.updateLocalStorage(
                    this.state.changeMessage,
                    this.state.onboardMessage
                  );
                }}>
                Skip
              </p>
              <div className="footer-button">
                <Dot3_Icon />
              </div>
              <Arrow_Icon className="footer-button next-icon" onClick={this.next} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderOnboard2(solarUnit) {
    return (
      <div className="onboard-block">
        {this.renderOnboardHeader(2)}
        <div className="content-block">
          <div className="row m-0 ">
            {solarUnit.saving ? (
              <div className={`glance-col ${this.dynamicBootstrapColumnSize('saving')}`}>
                <div className="icon">
                  <div className="glance-icon">
                    <Saving_Icon />
                  </div>
                </div>
                <div className="glance-text">
                  ${solarUnit.saving} <p> Annual savings</p>
                </div>
              </div>
            ) : (
              ''
            )}
            {(solarUnit.production && solarUnit.consumption) ? (
              <div
                className={`glance-col ${solarUnit.saving ? 'col-md-6' : 'col-md-12'} 'col-12' `}>
                <div className={`icon ${solarUnit.saving || checkIsMobile() ? '' : 'ml-auto'}`}>
                  <div className="glance-icon">
                    <SUN_Icon />
                  </div>
                </div>
                <div className="glance-text">
                  {Math.round((solarUnit.production * 100) / solarUnit.consumption)}%{' '}
                  <p> Powered by the sun</p>
                </div>
              </div>
            ) : ''}

            {solarUnit?.systemSize && (
              <div className={`glance-col ${this.dynamicBootstrapColumnSize('systemSize', false)}`}>
                <div className="icon">
                  <div className="glance-icon">
                    <Electric_Icon />
                  </div>
                </div>
                <div className="glance-text">
                  {solarUnit.systemSize} kW <p> System size</p>
                </div>
              </div>
            )}

            {solarUnit.moduleCount && (
              <div
                className={`glance-col panel ${this.dynamicBootstrapColumnSize(
                  'moduleCount',
                  true
                )}`}>
                <div className="icon">
                  <div style={{ height: '21px' }} className="glance-icon">
                    <Solar_Icon style={{ width: '40px' }} />
                  </div>
                </div>
                <div style={{ 'margin-left': '17px' }} className="glance-text">
                  {solarUnit.moduleCount} Panels{' '}
                  <p> {solarUnit.moduleName || solarUnit.moduleModel}</p>
                </div>
              </div>
            )}
          </div>
          <div className="row m-0 onboard-step">
            <div className="skip-button">
              <p
                className="skip-text"
                onClick={() => {
                  this.props.updateLocalStorage(
                    this.state.changeMessage,
                    this.state.onboardMessage
                  );
                }}>
                Skip
              </p>
              <div className="footer-button">
                <Dot2_Icon />
              </div>
              <Arrow_Icon className="footer-button next-icon" onClick={this.next} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  dynamicBootstrapColumnSize(fieldName, checkBorderRight) {
    const {
      loggedInUser: { solarUnit }
    } = this.props;
    let isMoreField;
    let slideRequiredField = ['systemSize', 'moduleCount', 'saving', 'production', 'consumption'];
    slideRequiredField = slideRequiredField.filter((item) => item !== fieldName);

    slideRequiredField = slideRequiredField.filter((element) => !solarUnit[element]);

    if (slideRequiredField.length == 2 && checkBorderRight && (!slideRequiredField.includes('production') || !slideRequiredField.includes('consumption'))) {
      return 'col-12 col-md-12';
    }
    if (slideRequiredField.length == 5) {
      return 'col-12 col-md-12';
    }
    return 'col-12 col-md-6 border-right';
  }

  renderOnboard1(homeownerData, loadingIcon) {
    return (
      <>
        <div className="d-inline-block justify-content-end w-100 text-right">
          <div className="white-logo mobie-image d-inline-block" />
        </div>
        <img className="onboard-logo" src={loadingIcon} />
        <h2 className="say-hello-to">
          {generateTimeBasedGreeting()}, {homeownerData.firstName}!{' '}
        </h2>
        <p className="onboard-text">
          I’m Bodhi. I work with {homeownerData.companyName} to support you in your solar journey.
        </p>
        <Button
          className="begin-button yellow-btn premiumButtonFill premiumButtonText"
          onClick={this.next}>
          Begin
        </Button>
        <div className="row m-0 onboard-step">
          <div className="skip-button margin">
            <div className="footer-button">
              <Dot1_Icon />
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      loggedInUser: { homeownerData, solarUnit },
      systemGlance,
      homeownersList: { homesByAdd }
    } = this.props;
    const solarGlance = systemGlance?.solarGlance;
    const settings = {
      adaptiveHeight: false,
      dots: false,
      infinite: false,
      speed: 1500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      easing: 'ease',
      beforeChange: (prevIndex, nextIndex) => {
        if (prevIndex == 3 && nextIndex == 0) {
          this.props.updateLocalStorage(this.state.changeMessage, this.state.onboardMessage);
        } else {
          this.setState({ slideNumber: nextIndex });
        }
      }
    };
    if (!homeownerData) return <></>;
    if (this.state.onboardMessage === null) {
      this.setState({
        onboardMessage: `Hi ${
          homeownerData.installerName ? homeownerData.installerName.split(' ')[0] : 'there'
        }, I’ve just logged into Bodhi for the first time!`
      });
    }

    const loadingIcon = solarGlance?.logo;
    this.mobileIcon = solarGlance?.icon;

    const { address } = homeownerData;
    this.slug = this.props.slug;
    this.onBoardAdd = homesByAdd;
    this.location = {
      onboardLat:
        homeownerData.lat -
        (checkIsMobile()
          ? this.state.slideNumber == 3
            ? 0.008
            : 0.0005
          : this.state.slideNumber == 3
          ? 0.001885
          : 0.000085),
      onboardLng:
        homeownerData.lng -
        (checkIsMobile()
          ? this.state.slideNumber == 3
            ? 0.004
            : 0.00009
          : this.state.slideNumber == 3
          ? 0.00619
          : 0.00019),
      onboardlat: homeownerData.lat * 1,
      onboardlng: homeownerData.lng * 1,
      onboardAddress: `${address.address1} ${address.address2 || ''}`,
      zoom: this.state.slideNumber == 3 ? (checkIsMobile() ? 15 : 17) : checkIsMobile() ? 18 : 21
    };
    const renderGlanceSlide =
      solarUnit &&
      (solarUnit?.moduleCount ||
        solarUnit?.systemSize ||
        solarUnit?.moduleName ||
        solarUnit?.saving ||
        (solarUnit?.production && solarUnit?.consumption));
    return (
      <div className="onboard">
        <div className="overlay">
          {this.state.slideNumber != 3 && this.state.zoom && (
            <AdminMap
              {...this.location}
              onBoardAdd={this.state.slideNumber == 3 ? this.onBoardAdd : null}
              overlay
              onBoarding
            />
          )}
          {this.state.slideNumber == 3 && (
            <AdminMap
              {...this.location}
              onBoardAdd={this.state.slideNumber == 3 ? this.onBoardAdd : null}
              overlay
              onBoarding
              bounce
              completeMap
              zoomOut
            />
          )}
        </div>
        <div className="flow-chart-wrapper">
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            <div className="onboarding-rectangle onboard1">
              {this.renderOnboard1(homeownerData, loadingIcon)}
            </div>

            <div className={`onboarding-rectangle ${this.state.zoom ? '' : 'onboard2'} `}>
              {this.state.slideNumber == 1 && this.state.zoom ? (
                <>
                  <img src={arrow} className="button-logo next" onClick={this.next} />
                  <img src={arrow} className="button-logo prev" onClick={this.previous} />
                  {systemGlance.solarGlance &&
                    this.renderMap(systemGlance.solarGlance, renderGlanceSlide)}
                </>
              ) : (
                this.state.slideNumber == 1 &&
                systemGlance.solarGlance &&
                this.renderMap(systemGlance.solarGlance, renderGlanceSlide)
              )}
            </div>

            {renderGlanceSlide && (
              <div className="onboarding-rectangle onboard3">
                <img src={arrow} className="button-logo next" onClick={this.next} />
                <img src={arrow} className="button-logo prev" onClick={this.previous} />
                {this.renderOnboard3()}
              </div>
            )}

            <div className="onboarding-rectangle onboard4">
              <img src={arrow} className="button-logo prev" onClick={this.previous} />
              <img
                src={arrow}
                className="button-logo next"
                onClick={() => {
                  this.props.updateLocalStorage(
                    this.state.changeMessage,
                    this.state.onboardMessage
                  );
                }}
              />
              {systemGlance.solarGlance && this.renderOnboard4(systemGlance, homeownerData)}
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

export default OnBoarding;
