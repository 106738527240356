import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { ThemeProvider as GlobalCssVars } from 'styled-components';

import store from './store';
import { bodhiTheme } from './styles/bodhiTheme';

const runtimeEnv = process.env.CUSTOM_ENV;

const rollbarConfig = {
  accessToken: 'd1364c7c883a4a7e813aa6f295b6dbde',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: runtimeEnv === 'production', // report issues in prd environment only
  payload: {
    environment: runtimeEnv || 'development'
  }
};

export default function Hoc(HocComponent) {
  return class extends Component {
    render() {
      return (
        <RollbarProvider config={rollbarConfig}>
          <ErrorBoundary>
            <Provider store={store}>
              <Router>
                <GlobalCssVars theme={bodhiTheme}>
                  <HocComponent />
                </GlobalCssVars>
              </Router>
            </Provider>
          </ErrorBoundary>
        </RollbarProvider>
      );
    }
  };
}
