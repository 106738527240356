import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { SEARCH_KEY, SEARCH_FILTER_KEY } from '../constants';
import { TSearchConfig } from './types';

const initialState: TSearchConfig = {
  installerId: undefined,
  companyId: undefined,
  dataToFilter: undefined,
  clearSearchFn: undefined,
  clearFilterFn: undefined,
  dataFilterFn: undefined,
  type: 'homeowner',
  scope: 'installer',
  mechanism: 'search'
};

export const searchInterfaceSlice = createSlice({
  name: 'searchInterface',
  initialState,
  reducers: {
    resetInstallerGlobalSearch: (state) => {
      Object.assign(state, initialState);
    },
    configureGlobalSearch: (state, action: PayloadAction<TSearchConfig>) => {
      state.scope = action.payload.scope;
      state.type = action.payload.type;
      state.installerId = action.payload.installerId;
      state.companyId = action.payload.companyId;
      state.mechanism = action.payload.mechanism;
      state.dataToFilter =
        action.payload.mechanism === SEARCH_FILTER_KEY && action.payload.dataToFilter
          ? action.payload.dataToFilter
          : undefined;
      state.clearFilterFn =
        action.payload.mechanism === SEARCH_FILTER_KEY && action.payload.clearFilterFn
          ? action.payload.clearFilterFn
          : undefined;
      state.clearSearchFn = action.payload.clearSearchFn ? action.payload.clearSearchFn : undefined;
      state.dataFilterFn = action.payload.dataFilterFn ? action.payload.dataFilterFn : undefined;
    }
  }
});

export const { configureGlobalSearch, resetInstallerGlobalSearch } = searchInterfaceSlice.actions;

export default searchInterfaceSlice.reducer;
