import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import type { RootState } from '../../../../store';
import { suspenseFallback } from '../../../../constants/components';
import { getInitialsFromName } from '../../../../shared/helpers';
import { Counter, HomeownerIdentifierDecorator } from '../../../Common';

import { contactIconSvg, settingsIconSvg, logoutIconSvg } from '../../../../constants/icons';
import { IHeaderDropDownMenu } from './types';

const UserIdentifier = lazy(() => import('../../../Common/UserIdentifier'));

const InboxCountDecorator = styled.span`
  &.count {
    left: 90%;
    top: -1px;
    padding: 3px 0 0 0;
    font-size: 12px;
    min-width: 19px;
    height: 19px;
    line-height: 14px;
    border-radius: ${(props) => props.theme.borderRadius.round};
    background-color: ${(props) => props.theme.colors.orange};
  }
`;

const InboxJumboCountDecorator = styled(InboxCountDecorator)`
  &.count {
    left: 37px;
    top: -2px;
    border-radius: ${(props) => props.theme.borderRadius.faint};
    padding-left: 2px;
    padding-right: 2px;
  }
`;

/*
  .count {
    position: absolute;
    color: white;
    left: 35%;
    top: 5%;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    width: auto;
    padding: 0 4px;
    height: 18px;
    background-color: rgb(255, 98, 67);
    margin-top: 2px;
    border-radius: ${(props) => props.theme.borderRadius.round};
    text-align: center;
    transform: translate(-50%, -50%);
    &.zero {
      display: none;
    }
  }
*/

/**
 * Homeowner top right desktop identity component, plus dropdown menu container
 */
export const HeaderDropDownMenu: React.FC<IHeaderDropDownMenu> = (props) => {
  const {
    router,
    image,
    handleClick,
    companyName,
    isShow,
    slug,
    installerName,
    installerTitle,
    firstName,
    lastName,
    handleSignOut
  } = props;

  const inboxState = useSelector((state: RootState) => state.inboxMessages);
  const msgCount = inboxState.counts.inbox || 0;
  const countDecorator = msgCount > 99 ? InboxJumboCountDecorator : InboxCountDecorator;

  return (
    <>
      <a
        className="nav-link dropdown-toggle pad-left"
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={(e) => {
          handleClick(!isShow);
        }}>
        <Suspense fallback={suspenseFallback}>
          {!!firstName && !!lastName && (
            <UserIdentifier
              image={image}
              userInitials={getInitialsFromName(`${firstName}${lastName}`)}
              decorator={HomeownerIdentifierDecorator}>
              <Counter
                allowInstallerCustom={true}
                number={inboxState.counts.inbox || 0}
                decorator={countDecorator}
              />
            </UserIdentifier>
          )}
        </Suspense>
        <span className="dashboard-profile-name">{companyName}</span>
      </a>
      <div
        className={`dropdown-menu box-Shadow homeowner ${isShow ? 'show' : ''}`}
        aria-labelledby="navbarDropdown">
        <div className="drop-up-arrow" />

        <a
          className="dropdown-item homeowner"
          onClick={() => {
            router.navigate(`/${slug}/contact`);
          }}>
          <span className="drop-icon homeowner">
            <img src={contactIconSvg} alt="contact-icon" />
          </span>
          <span className="dropHeader premiumLinkHover">Contact {companyName}</span>
          <div className="text">{installerTitle}</div>
          <div className="text">{installerName}</div>
        </a>

        <a
          className="dropdown-item homeowner"
          onClick={() => {
            router.navigate(`/${slug}/settings`);
          }}>
          <span className="drop-icon">
            <img src={settingsIconSvg} alt="setting-icon" />
          </span>
          <span className="dropHeader premiumLinkHover">Change settings</span>
        </a>

        <a className="dropdown-item homeowner" onClick={handleSignOut}>
          <span className="drop-icon">
            <img src={logoutIconSvg} alt="logout-icon" />
          </span>
          <span className="dropHeader premiumLinkHover">Logout</span>
        </a>
      </div>
    </>
  );
};

export default HeaderDropDownMenu;
