import { combineReducers } from 'redux';
import { reducerPowerMeter,reducerStateList,reducerSlugStatus,reducerAddCompany,reducerAdminReport,reducerCompanySunlightCredentials } from './dashboard';
import {reducerGetCompanyById, reducerEditInstaller, reducerEditInstallerManagment, uploadCSVReducer} from './editInstaller'


const dataReducer = combineReducers({
	powerMeter: reducerPowerMeter,
	stateList: reducerStateList,
	slugStatus: reducerSlugStatus,
	addCompany: reducerAddCompany,
	getCompanyById: reducerGetCompanyById,
	editInstaller: reducerEditInstaller,
	EditInstallerManagment:reducerEditInstallerManagment,
	uploadCSV :uploadCSVReducer,
	adminReport :reducerAdminReport,
	companySunlightCredentials:reducerCompanySunlightCredentials

})


export const reducer = combineReducers({
	data : dataReducer
});