import * as moment  from "moment";
import { Services } from '../../../Api/services';
import * as config  from '../../../shared/config';

export const getCompanyFleetListApi = async data => {
  const url = `${config.ENERGY_REPORT_BASE_URL}company/${data.companyId}/fleetReport?productSuite=${window.$productSuite}`;
  let res   = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const getFleetCsvExampleApi = async data => {
  const url = `${config.FLEET_CSV_BASE_URL}docs/config`;
  const res   = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const uploadFleetCsvApi = async data => {
  const url = `${config.FLEET_CSV_BASE_URL}fleet/${data.companyId}/config`;
  const res   = await Services.putCall(url, data.data, true);
  return { data: res.data, status: res.status };
}