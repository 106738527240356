import { RECEIVE_GETCOMPANYBYIDAPI_DATA, RECEIVE_UPLOAD_CSV,RECEIVE_EDITINSTALLERAPI_DATA, RECEIVE_EDITINSTALLERAPI_MANAGEMENT_DATA } from './../../Actions/editInstaller'

export const reducerGetCompanyById = (state = {} , { type, data }) => {
  switch (type) {
    case RECEIVE_GETCOMPANYBYIDAPI_DATA:
      return data
    default:
      return state
  }
}

export const reducerEditInstaller = (state = {} , { type, data }) => {
  switch (type) {
    case RECEIVE_EDITINSTALLERAPI_DATA:
      return data
    default:
      return state
  }
}

export const reducerEditInstallerManagment = (state = {} , { type, data }) => {

  switch (type) {
    case RECEIVE_EDITINSTALLERAPI_MANAGEMENT_DATA:
      return data
    default:
      return state
  }
}

export const uploadCSVReducer = (state = {} , { type, data }) => {

  switch (type) {
    case RECEIVE_UPLOAD_CSV:
      return data
    default:
      return state
  }
}
