
import { combineReducers } from 'redux';
import {
  SET_LOADER
} from "./action";

const loader = (state = { loader: false}, { type, data }) => {
  switch (type) {
    case SET_LOADER:
      return data;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  loader: loader,
})
