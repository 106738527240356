// import npm packages
import React from 'react';

// import local files
import bodhiLogoIcon from './../../Assets/Images/bodhi_logo.svg'
import cloudIcon     from './../../Assets/Images/cloud.svg'

class ErrorPage extends React.Component {
  render() {
    return (
      <section className="error_page_container">
        <div className="error_page_container-logo container-fluid">
          <img src={bodhiLogoIcon} alt="" />
        </div>
        <div className=" container">
          <div className="row">
            <div className="col-sm-6 error_page_container-404image d-flex justify-content-end">
              <div className="error_page_container-cloud_wrap">
                <img src={cloudIcon} alt="" />
              </div>
            </div>
            <div className="col-sm-6  error_page_container-content">
              <h4>Something went wrong.</h4>
              <h2>We Are Sorry</h2>
              <h4>Let's try that again.</h4>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ErrorPage;
