// import npm packages
import React, { Component } from 'react';
import { Spin, Switch } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserAvatar from 'react-user-avatar';
import styled from 'styled-components';

import { setLoader } from '../../../Common/Loader/action';
import withRouter from '../../../../shared/withRouter';
import { getToken } from '../../../../shared/util';
import { checkIsVideo } from '../../../../shared/checkIsMobile';
import { requestGetCampaignDetail } from '../../actions/campaign';
import { getCampaignRecipents } from '../../api/campaign';
import { createCampaignFilterQuery, campaignFilterOption } from '../new-campaign/campaignFilter';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const CampaignStyles = styled.section`
  margin: 150px 0 150px 0;
  .back-btn {
    color: ${(props) => props.theme.colors.labelcolor};
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 36px;
    display: block;
    cursor: pointer;
  }
  h1 {
    color: ${(props) => props.theme.colors.blackMed};
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
  }
  .camp-dash-content-box {
    padding-right: 120px;
    margin-bottom: 20px;
    .text {
      min-width: 60px;
    }
    .camp-detail-box {
      padding-left: 100px;
      h2 {
        padding-left: 16px;
        margin-bottom: 30px;
        color: ${(props) => props.theme.colors.blackMed};
        font-size: 30px;
        line-height: 40px;
        font-weight: 800;
        width: 100%;
      }
      .name-adddres-box {
        .detail-box {
          p {
            margin: 0;
            padding: 0;
            font-size: 16px;
            line-height: 22px;
            color: ${(props) => props.theme.colors.grey};
            font-weight: 600;
          }
        }
      }
    }
  }
  .personal-detail-box {
    .detail-box {
      margin-bottom: 20px;
      p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 15px;
        color: ${(props) => props.theme.colors.grey};
        &:nth-child(1) {
          font-weight: 600;
          margin-bottom: 15px;
        }
        &:nth-child(2) {
          font-weight: 400;
          div {
            line-height: 20px;
          }
        }
      }
    }
    position: relative;
    padding-left: 30px;
    min-height: 125px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 125px;
      width: 1px;
      background: ${(props) => props.theme.colors.grey4};
      content: '';
    }
  }
  .media {
    padding: 20px 25px;
    h5 {
      font-size: 1.125rem;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 0;
      line-height: 21px;
      margin-bottom: 5px;
      text-align: left;
      text-transform: capitalize;
    }
    p {
      font-size: 0.875rem;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 5px;
      font-weight: 400;
      text-align: left;
    }
    .profile-image {
      height: 100%;
      width: 100%;
    }
    .media-body {
      padding-left: 15px;
    }
  }
`;

class ExistingCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isApi: false
    };
  }

  componentWillMount() {
    this.slug = localStorage.getItem('slug');
    this.companyId = this.props.router.params.companyId;
  }

  componentDidMount() {
    this.slug = localStorage.getItem('slug');
    let obj = {
      companyId: this.companyId,
      campaignId: this.props.router.params.id
    };
    this.props.setLoader({ loader: true });
    this.props.requestGetCampaignDetail(obj);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.campaignData && !this.state.isApi) {
      let filterData =
        (nextProps.campaignData.filters && JSON.parse(nextProps.campaignData.filters)) || [];
      let companyId = nextProps.campaignData.companyId;
      let obj = {
        filterData: createCampaignFilterQuery(filterData, companyId),
        parameter: '',
        campaignId: this.props.router.params.id,
        companyId
      };
      getCampaignRecipents(obj).then((r) => {
        this.setState({ temp: r });
      });

      this.props.setLoader({ loader: false });
      this.setState({ isApi: true });
    }
  }

  renderCampaignDashboard = () => {
    let { campaignData, loader } = this.props;
    return (
      <React.Fragment>
        <title>Campaign - Bodhi</title>
        <div>
          <Spin spinning={loader} indicator={antIcon} style={{ color: '#04bfff' }}>
            <CampaignStyles>
              <div className="container">
                <div
                  className="back-btn"
                  onClick={() => {
                    this.props.router.navigate(
                      `/${this.slug}/${this.companyId}/campaign/dashboard`
                    );
                  }}
                >
                  {'<< Back'}
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <div className="camp-dash-content-box">
                      <div className="row camp-detail-box">
                        <h2>{campaignData && campaignData.headline}</h2>
                        {this.renderCampaignFilter()}
                      </div>
                    </div>
                    {this.renderCampaignRecipents()}
                  </div>
                  {this.renderCampaignEmail()}
                </div>
              </div>
            </CampaignStyles>
          </Spin>
        </div>
      </React.Fragment>
    );
  };

  renderCampaignRecipents() {
    return (
      <div className="table-responsive box-Shadow" style={{ background: 'white' }}>
        <table className="table metrics-table">
          <thead>
            <tr className="metrics-row">{this.renderTableHeader()}</tr>
          </thead>
          <tbody>
            {this.state.temp &&
              this.state.temp.map((ele, i) => {
                return (
                  <tr className="metrics-row" key={i}>
                    {this.renderTableData({ ...ele })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }

  renderTableHeader = (data) => {
    data = ['Name', 'Email', 'In App Click', 'SMS'];
    return data.map((ele) => (
      <th scope="col">
        <span className="th-content">
          <span className="th-heading">{ele}</span>
          <div className="filter-sort-icon"></div>
        </span>
      </th>
    ));
  };

  renderTableData(data) {
    let { campaignData } = this.props;
    let campaignChannel = campaignData && campaignData.channel && JSON.parse(campaignData.channel);
    return (
      <>
        <td className="metrics-data">
          <a
            href={`${window.location.origin}/${this.slug}/${campaignData.companyId}/dashboard/${data['homeownerId']}/homeowner`}
          >
            {data.name}
          </a>
        </td>
        <td className="metrics-data">
          {(campaignChannel && campaignChannel.email && this.emailActivity(data.track)) || '-'}
        </td>
        <td className="metrics-data">{(data.track && data.track.appClick) || '-'}</td>
        <td className="metrics-data">
          {(campaignChannel &&
            campaignChannel.sms &&
            this.smsActivity(data.track && data.track.smsDelievered)) ||
            '-'}
        </td>
      </>
    );
  }

  smsActivity(smsData) {
    if (!smsData) return 'Bounced';
    else return 'Delivered';
  }

  emailActivity(emailData) {
    if (!emailData) return '-';
    else if (emailData.emailClick) return 'Clicked';
    else if (emailData.emailOpen) return 'Opened';
    else if (!emailData.emailDelievered) return 'Bounced';
    else return 'Delivered';
  }

  renderInstallerInital(campaignData) {
    let photo = campaignData && campaignData.installerPhoto;
    if (photo && photo.includes('installer-avatar')) {
      photo = null;
    }

    let installerName = campaignData && campaignData.name;
    return photo ? (
      <img className="profile-image" src={photo} alt="" />
    ) : (
      <UserAvatar
        className="profile-image premiumIconBackGround"
        size="40"
        name={installerName || 'Installer'}
      />
    );
  }

  renderCampaignEmail() {
    let { campaignData, installerData } = this.props;
    let CTA = campaignData && campaignData.button && JSON.parse(campaignData.button);
    let email = campaignData && campaignData.email && JSON.parse(campaignData.email);
    return (
      <div className="col-md-4">
        <div className="white-box mt-4 box-Shadow">
          <div className="media">
            <div className="dashboard-profile-image">
              {this.renderInstallerInital(campaignData)}
            </div>

            <div className="media-body">
              <h5 className="mt-0">{campaignData && campaignData.name}</h5>
              <p>{(campaignData && campaignData.title) || 'Project Manager'}</p>
            </div>
          </div>
          {campaignData &&
            campaignData.photo &&
            campaignData.photo.length > 0 &&
            (checkIsVideo(campaignData.photo[0])
              ? this.VideoPreviewBox(campaignData.photo[0])
              : this.ImagePreviewBox(campaignData.photo[0]))}
          <div className="campaignPreview left-content-box">
            <h3>{campaignData && campaignData.headline} </h3>
            <p>{email && email.body}</p>
            <a href={CTA && CTA.link} target="_blank" className="get-button btn btn-primary">
              {CTA && CTA.label}
            </a>
          </div>
        </div>
      </div>
    );
  }

  VideoPreviewBox(fileUrl) {
    let extension = fileUrl.split('.')[fileUrl.split('.').length - 1];
    return (
      <video className="hut-img" controls>
        <src src={fileUrl} type={`video/mp4`} />
      </video>
    );
  }

  ImagePreviewBox(fileUrl) {
    return fileUrl && <img className="hut-img" src={fileUrl} />;
  }

  renderCampaignFilter() {
    let { campaignData } = this.props;
    let channel = (campaignData && campaignData.channel && JSON.parse(campaignData.channel)) || {};
    let filters = campaignData && campaignData.filters && JSON.parse(campaignData.filters);
    return (
      <>
        <div className="col-md-5 col-xs name-adddres-box">
          <div className="detail-box">
            <p>Channels </p>
            <div className="on-off-case">
              <div className="onoffswitch">
                <Switch
                  disabled={true}
                  checked={channel.email}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                />
              </div>
              <div className="text ml-2">Email</div>
            </div>
            <div className="on-off-case">
              <div className="onoffswitch">
                <Switch
                  disabled={true}
                  checked={channel.sms}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                />
              </div>
              <div className="text ml-2">Text</div>
            </div>

            <div className="on-off-case">
              <div className="onoffswitch">
                <Switch
                  disabled={true}
                  checked={channel.app}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                />
              </div>
              <div className="text ml-2">In App</div>
            </div>
          </div>
        </div>
        <div className="col-md-7 col-xs personal-detail-box">
          <div className="detail-box">
            <p>Filters Used </p>
            <p>{this.renderFilters(filters)}</p>
          </div>
        </div>
      </>
    );
  }

  renderFilters(data) {
    return (
      data &&
      data.map((ele) => {
        if (
          campaignFilterOption[ele.parameter] &&
          campaignFilterOption[ele.parameter].phatomMapping
        ) {
          ele.phantomValue = [];
          ele.value.forEach((element) => {
            ele.phantomValue.push(campaignFilterOption[ele.parameter].phatomMapping[element]);
          });
        }
        let value = (ele.phantomValue && ele.phantomValue.join(' or ')) || ele.value.join(' or ');
        return (
          <>
            <div>{`${ele.parameter} ${ele.condition || 'Equals'} ${value}`}</div>
            <br />
          </>
        );
      })
    );
  }

  render() {
    if (getToken()) {
      return this.renderCampaignDashboard();
    } else {
      this.props.router.navigate('/');
      window.location.reload();
      return null;
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    loader: state.loader.loader.loader,
    installerData:
      (state.header.getInstaller && state.header.getInstaller && state.header.getInstaller.data) ||
      {},
    campaignData:
      state.companyCampaign &&
      state.companyCampaign.GetCampaignDetailReducer &&
      state.companyCampaign.GetCampaignDetailReducer.data
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLoader,
      requestGetCampaignDetail
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(ExistingCampaign));
