export const calcTime = (date, offset, isTimeStamp = false) => {

    // create Date object for current location
    let d = date;
    if(isTimeStamp) {
        d = new Date(date);
    }
    
    // convert to msec
    // add local time zone offset
    // get UTC time in msec
    let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    
    // create new Date object for different city
    // using supplied offset
    let nd = new Date(utc + (3600000*offset));
    
    // return time as a string
    return nd;

}