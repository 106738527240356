import React, { Component } from 'react';
import { Menu } from 'antd';

import { resolveJourneyValues } from '../../../../constants';

const { SubMenu } = Menu;

export default class JourneyPhaseMenu extends Component {
  constructor(props) {
    super(props);
    this.companyId = this.props.router.params.companyId;
    this.state = {};
    this.journeyPhase = resolveJourneyValues();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.router.location.pathname.includes('companysetting')) {
      this.setState({ siderActive: '', siderOptionActive: '' });
    }
  }

  renderJourneyPhase() {
    let {
      companyData: { companyDetail }
    } = this.props;
    let journey = [];

    if (companyDetail && companyDetail.journey) {
      journey = JSON.parse(JSON.stringify(companyDetail.journey));
    }

    journey = this.formatJourney(journey);

    return (
      <>
        {journey.map((ele, index) => {
          return (
            <Menu key={index} theme="light" className="left-menu journey-menu" mode="inline">
              <SubMenu
                className={`journey-menu ${
                  !this.props.siderOptionActive && this.state.siderOption === ele.s3Key
                    ? 'siderActive'
                    : ''
                }`}
                key={`${index}`}
                title={ele.journeyName}
                onTitleClick={() => {
                  this.props.redirect({
                    companySettingState: 'addJourney',
                    editJourneyData: ele,
                    journeyIndex: index
                  });
                  this.setState({ siderOption: ele.s3Key });
                  this.props.updateStateFromProps({
                    siderActive: 'isJourney',
                    siderOptionActive: ''
                  });
                }}
              >
                {this.subMenu(ele, index)}
              </SubMenu>
            </Menu>
          );
        })}
      </>
    );
  }

  subMenu = (ele, index) => {
    return Object.keys(ele.journeyPhase).map((element, i) => {
      return (
        <Menu.Item
          key={`${index}-${i}`}
          className={`shiftRight ${
            !this.props.siderOptionActive &&
            this.state.siderOptionActive == element &&
            this.state.siderOption == ele.s3Key
              ? 'siderActive'
              : ''
          }`}
          onClick={() => {
            this.props.redirect({
              journeyData: ele,
              companySettingState: this.journeyPhase[element.toLowerCase()].label,
              journeyKey: ele.journeyKey
            });
            this.setState({ siderOptionActive: element });
            this.props.updateStateFromProps({ siderActive: 'isJourney', siderOptionActive: '' });
          }}
        >
          {(ele.journeyPhase && ele.journeyPhase[element] && ele.journeyPhase[element].name) ||
            this.journeyPhase[element].label}
        </Menu.Item>
      );
    });
  };

  render() {
    return this.renderJourneyPhase();
  }

  formatJourney = (journey) => {
    let temp = journey.map((ele) => {
      if (typeof ele === 'string') {
        return (ele = JSON.parse(ele));
      } else return ele;
    });
    return temp;
  };
}
