import styled from 'styled-components';

/**
 * Provides styles for the inner section of the Threads preview pane
 */
export const InboxDecorator = styled.div`
  margin: 80px 0 0 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  &.installer {
    max-width: calc(100% - 250px);
    margin-left: 250px;
  }
`;
