import {
  IChronHandler,
  IChronHandlerArg,
  TChronHandlerFn,
  TChronHandlerOptions,
  TChronConfig,
  IChron
} from '../types';
import { UnrecognizedTypeException } from '../exceptions';

import { createNonUniqueId } from '../shared/helpers';

class Handler implements IChronHandler {
  _chronId: string;
  _errors: any[] = [];
  _fn: TChronHandlerFn;
  _options: TChronHandlerOptions;

  get id() {
    return this._chronId;
  }

  get fn() {
    return this._fn;
  }

  get errors() {
    return this._errors;
  }

  logError(error: any) {
    this._errors.push(error);
  }

  constructor(id: string, handler: IChronHandlerArg) {
    this._chronId = id;
    this._fn = handler.fn;
    this._options = handler.options || {};
  }
}

export { Handler };

/**
 * Handles cyclic/time-based executions for React.FC type contexts, providing an interface for start, pause, and clear for teardown in components
 *
 * @todo: RTK provides polling via RTK Query, once we can move to that store configuration, we can deprecate Chron
 */
class Chron implements IChron {
  _interval: number;
  _handlers: IChronHandler[] = [];
  _handlerIdentities: string[] = [];
  _paused = false;
  _execInstance: number;
  _counter: number = 0;
  _errorCount: number = 0;
  _startTime: number = 0;
  _errThreshold: number = 2;

  constructor(config: TChronConfig) {
    const { interval, eventHandlers, start, errThreshold } = config;
    this._interval = interval;
    if (typeof errThreshold === 'number') {
      this._errThreshold = errThreshold;
    }
    if (Array.isArray(eventHandlers)) {
      this._handlers = eventHandlers.map((handler) => {
        const _chronId = Chron.assignId();
        const spawn = new Handler(_chronId, handler);
        this._handlerIdentities.push(_chronId);
        return spawn;
      }, this);
    } else if (
      !Array.isArray(eventHandlers) &&
      typeof eventHandlers === 'object' &&
      typeof eventHandlers.fn === 'function'
    ) {
      const _chronId = Chron.assignId();
      this._handlers = [new Handler(_chronId, eventHandlers)];
      this._handlerIdentities.push(_chronId);
    } else {
      throw new UnrecognizedTypeException(
        `Chron eventHandlers is not a recognized type - given ${eventHandlers}`
      );
    }

    this.command = this.command.bind(this);
    if (start) {
      this.start();
    }
  }

  /**
   * Leverage a simple identification process for event handlders called from Chron
   */
  static assignId() {
    return createNonUniqueId();
  }

  get errThreshold() {
    return this._errThreshold;
  }

  /**
   * Provide a handle on the interval in case we need to ascertain cadence
   */
  get interval() {
    return this._interval;
  }

  /**
   * Provide a counter on how many cycles of executions have run
   */
  get counter() {
    return this._counter;
  }

  /**
   * Provide a counter on how many errors have been caught
   */
  get errors() {
    return this._errorCount;
  }

  /**
   * Accepts an identifier from a handler, and an optional parameter to report the error
   *
   * @param {String} an identifer
   * @param {Boolean} a signal whether or not to take action
   */
  public logHandlerError(id: string, error: any, report: boolean) {
    if (this._handlerIdentities.includes(id)) {
      const handler: IChronHandler = this._handlers.filter((id) => handler.id)[0];
      handler.logError(error);
      if (report) {
        this._errorCount += 1;
      }
    }
  }

  /**
   * Retrieve any Chron related data from operations
   *
   * @param {String} the identifier assigned to the handler
   * @returns {Handler} the instance of the Handler object
   */
  getHandler(id: string) {
    if (!this._handlerIdentities.includes(id)) {
      return undefined;
    }
    return this._handlers.filter((handler) => handler.id === id)[0];
  }

  private command() {
    if (this._execInstance && !this._paused) {
      /**
       * Execute each handler passed to the Chron
       * Pass `this` to expose the Chron interface as argument to the execution context
       * Pass `handler` to expose the API for the handler, e.g. errors, etc.
       */
      this._handlers.map((handler: IChronHandler) => {
        try {
          handler.fn(this, handler);
        } catch (e) {
          this._errorCount += 1;
          if (this._errorCount > this._errThreshold) {
            this.pause();
          }
          console.error(
            `Chron handler error count of '${this._errorCount}' exceeded error threshold of '${this.errThreshold}' - pausing execution: ${e}`
          );
        }
      });
      this._counter += 1;
    }
  }

  start() {
    /**
     * Ensure that API values are up to date to avoid any confusion
     */
    this._paused = false;
    if (!this._execInstance) {
      if (this._handlers.length && this._handlerIdentities.length) {
        this._startTime = Date.now();
        this._execInstance = window.setInterval(this.command, this._interval);
        return true;
      } else {
        console.error(`Cannot start handler, no inputs.`);
      }
    }
    return false;
  }

  /**
   * For teardown, clear further execution to stop running assigned handlers
   *
   * Ensure that API values are up to date to avoid any confusion
   */
  clear() {
    this._paused = true;
    this._handlers = [];
    this._handlerIdentities = [];
    if (this._execInstance) {
      window.clearInterval(this._execInstance);
    }
  }

  restart() {
    this._paused = false;
  }

  /**
   * Skip any given number of iterations of execution
   */
  pause() {
    this._paused = true;
  }

  /**
   * Evaluate whether the Chron instance is still executing
   *
   * @returns bool
   */
  get running() {
    return !this._paused;
  }
}

export default Chron;
