import React, { Children } from 'react';
import { Button } from 'antd';
import { IRouter } from '../../../../types';
import { StyledCalloutBox } from './shared.style';

interface ICalloutBox {
  children?: React.ReactNode;
  icon?: string;
  link?: string;
  className?: string;
  contentClassName?: string;
  eventHandler?: (event: React.MouseEvent) => void;
}

/**
 * @todo: improve "variant" support for decorating different types of CalloutBox
 * Currently, we are using className to handle variation, and it should be more explicit
 */
const CalloutBox: React.FC<ICalloutBox> = (props) => {
  const { children, className, eventHandler, contentClassName } = props;

  const arrowClass = contentClassName === 'visible' ? 'fa-chevron-up' : 'fa-chevron-down';
  const childElements = Children.toArray(children);
  const useButtonCtrl = className !== 'content-callout-static';

  return (
    <StyledCalloutBox className={className}>
      {useButtonCtrl && (
        <Button className={className} onClick={eventHandler}>
          <i className={`fa ${arrowClass}`} aria-hidden="true"></i>
        </Button>
      )}

      {childElements.map((child, index) => {
        if (index === 0) {
          {
            return <React.Fragment key={`parent-${index}`}>{child}</React.Fragment>;
          }
        } else {
          return (
            <div className={contentClassName} key={`child-${index}`}>
              {child}
            </div>
          );
        }
      })}
    </StyledCalloutBox>
  );
};

export default CalloutBox;
