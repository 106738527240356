import {
	REQUEST_INSTALLER_DETAILS,
	RECEIVE_INSTALLER_DETAILS,
	REQUEST_POST_CONTACT_DATA,
	RECEIVE_POST_CONTACT_DATA
} from "../types/contact/contact";

export const requestInstallerDetails = data => ({
	type: REQUEST_INSTALLER_DETAILS, data
});

export const receiveInstallerDetails = data => ({
	type: RECEIVE_INSTALLER_DETAILS, data
});

export const requestPostContactData = data => ({
	type: REQUEST_POST_CONTACT_DATA, data
});

export const receivePostContactData = data => ({
	type: RECEIVE_POST_CONTACT_DATA, data
});