import { Services } from '../../../Api/services';
import * as config from '../../../shared/config';

export const getHomeownerInstallerDetails = async (data) => {
  const url = `${config.INSTALLER_BASE_URL}company/${data.companyId}/installers/${data.installerId}`;

  const res = await Services.getCall(url, true);
  return { data: res.data };
};

export const postHomeownerContactDetails = async (data) => {
  const url = `${config.SURVEY_BASE_URL}company/${data.companyId}/homeowner/${data.homeownerId}/contact`;

  if (data.touchName) {
    data.touchName = data.touchName.trim();
  }

  const res = await Services.postCall(url, data, true);
  return { data: res.data };
};

export const postInstallerNotify = async (data) => {
  const url = `${config.HOME_OWNER_BASE_URL}homeowner/${data.homeownerId}/notifyinstaller`;
  const res = await Services.postCall(url, data, true);
  return;
};
