// import npm packages
import { combineReducers } from "redux";

// import local files
import { RECEIVE_GET_EVERSIGN_DATA, RECEIVE_CREATE_EVERSIGN_DOCUMENT } from "../../types/eversign/eversign";

const homeownerCreateEversignDocument = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_CREATE_EVERSIGN_DOCUMENT:
			return data;
		default:
			return state;
	}
};

const homeownerGetEversignDocument = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_GET_EVERSIGN_DATA:
			return data;
		default:
			return state;
	}
};

export const reducer = combineReducers({
	homeownerCreateEversignDocument,
	homeownerGetEversignDocument
});