import React, { Component } from 'react';
import { Progress } from 'antd';
import * as moment from 'moment';
import Slider from 'react-slick';

import { withRouter, getToken } from '../../../../shared';
import { convertDate } from '../../../../shared/convertDate';
import { calcTime } from '../../../../shared/calcTime';
import { parseJson } from '../../../../shared/parseJson';
import { currencyFormater } from '../../../../shared/currencyFormater';
import { parseDateTime } from '../../../../shared/parseDateTime';

class MobileEnergyTips extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: convertDate(calcTime(new Date(), -5)),
      offset: null
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.loggedInUser &&
      nextProps.loggedInUser.homeownerData &&
      nextProps.loggedInUser.homeownerData.offset &&
      (!this.state.selectedDate || !this.state.offset)
    ) {
      this.setState({
        selectedDate: convertDate(
          calcTime(new Date(), nextProps.loggedInUser.homeownerData.offset)
        ),
        offset: nextProps.loggedInUser.homeownerData.offset
      });
    }
  }

  //  get last 10 days
  last10Days() {
    const dates = [];
    const NUM_OF_DAYS = 5;
    for (let i = 0; i < NUM_OF_DAYS; i++) {
      const date = moment();
      date.subtract(i, 'day').format('DD-MM-YYYY');
      dates.push({
        label: date.format('dd-DD'),
        month: date.format('MMMM'),
        value: date.format('YYYY-MM-DD'),
        date: date.toDate()
      });
    }
    return dates.reverse();
  }

  renderDashboard = () => {
    const { peakData } = this.props;
    let isConsumption = true;
    if (
      this.props.energyReport &&
      !this.props.energyReport.totalConsumption &&
      this.props.energyReport.totalProduction
    ) {
      isConsumption = false;
    }
    return (
      <div className="slider-wrapper">
        <div className="mobile-app-calender">
          <p className="text-center DE-Flow">DAILY ENERGY FLOW</p>
          <div className="row mobile-summary">
            <div className="col-sm-6 paddl0 paddr0 col-6">
              <h4 className="ho-setting-h4">Solar production</h4>
              <p className="ho-setting-month-style margt0 margb0">Saved this month</p>
              <div className="price ho-setting-yellow-value">
                $
                {this.props.energyReport.totalSaving
                  ? currencyFormater(this.props.energyReport.totalSaving)
                  : 0}
              </div>
            </div>
            {isConsumption ? (
              <div className="col-sm-6 home-consumption paddl0 paddr0 col-6">
                <h4 className="ho-setting-h4">Home consumption</h4>
                <p className="ho-setting-month-style margt0 margb0">Today's peak</p>
                <div className="price ho-setting-blue-value premiumBackgroundText">
                  {peakData && peakData.peakConsumption && peakData.peakConsumption.toFixed(1)}
                  <span className="ho-setting-blue-value-pm">kW</span>–
                  {peakData &&
                    peakData.peakTime &&
                    (parseDateTime(peakData.peakTime * 1000)
                      .replace('AM', '')
                      .replace('PM', '') ||
                      '00:00')}
                  <span className="ho-setting-blue-value-pm" style={{ marginLeft: '-5px' }}>
                    {peakData && peakData.peakTime
                      ? parseDateTime(peakData.peakTime * 1000)
                          .toLowerCase()
                          .includes('am')
                        ? 'AM'
                        : 'PM'
                      : 'AM'}
                  </span>
                </div>
              </div>
            ) : (
              <div className="col-sm-6 home-consumption paddl0 paddr0 col-6">
                <h4 className="ho-setting-h4" />
                <p className="ho-setting-month-style margt0 margb0">Today's production</p>
                <div className="price ho-setting-blue-value premiumBackgroundText production-unit">
                  {this.props.energyReport && this.props.energyReport.totalProduction.toFixed(1)}
                  <span className="ho-setting-blue-value-pm"> kWh</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderMobileDates() {
    const { monthHistory } = this.props;
    let element = document.getElementsByClassName('premiumBackgroundText');
    let style = element[0] && window.getComputedStyle(element[0]);
    const consumptionColor = (style && style.getPropertyValue('color')) || '#00142D';

    element = document.getElementsByClassName('premiumLinks');
    style = element[0] && window.getComputedStyle(element[0]);
    const premiumLink = (style && style.getPropertyValue('color')) || '#FF6F47';

    const settings = {
      rtl: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      swipe: false,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 0
          }
        }
      ]
    };
    let isConsumption = true;
    if (
      this.props.energyReport &&
      !this.props.energyReport.totalConsumption &&
      this.props.energyReport.totalProduction
    ) {
      isConsumption = false;
    }
    return (
      <>
        <div className="month-name mobile-view-only">
          {moment(this.state.selectedDate).format('MMMM YYYY')}
        </div>

        <div dir="rtl" className="container mobile-view-only">
          <Slider
            ref={(c) => (this.slider = c)}
            swipeable={false}
            emulateTouch
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            {...settings}
          >
            {this.last10Days().map((date, index) => (
              <div
                className={
                  this.state.selectedDate === date.value
                    ? 'mobile-app-calender-date active premiumLinks'
                    : 'mobile-app-calender-date'
                }
                onClick={() => {
                  this.setState({
                    selectedDate: date.value
                  });
                  localStorage.setItem('energyReportDate', date.date.toDateString());
                  this.props.receiveHomeownerEnergyData({ data: { monthlyHistory: [] } });
                  this.props.setLoader({ loader: true });
                  this.props.setDate(date.date);
                  this.props.requestHomeownerEnergyData({
                    sync: false,
                    date: date.value,
                    homeownerId: this.props.homeOwnerId,
                    offset: this.state.offset
                  });
                }}
              >
                <div className="day">{date.label.split('-')[0]}</div>
                <Progress
                  trailColor={
                    this.state.selectedDate === date.value
                      ? premiumLink
                      : isConsumption && consumptionColor
                  }
                  strokeColor={index === 9 ? 'grey' : '#FFCC00'}
                  type="circle"
                  className={
                    index === 9
                      ? 'testing123100'
                      : `testing123${monthHistory && this.calcPercentage(monthHistory[date.value])}`
                  }
                  percent={
                    index === 9
                      ? 100
                      : monthHistory &&
                        this.calcPercentage(monthHistory[date.value], this.props.maxProd)
                  }
                  width={46}
                  format={() => {
                    return <span>{date.label.split('-')[1]}</span>;
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  }

  calcPercentage = (data, maxProd) => {
    const { energyReport } = this.props;
    if (!data || !data.totalProduction) return 0;
    if (data.totalConsumption) {
      const prod = data.totalProduction / energyReport.interval;
      const cons = data.totalConsumption / energyReport.consumptionInterval;
      const value = parseInt(Math.abs(prod * 100) / Math.abs(cons));
      return value;
    }
    if (data.totalProduction) {
      const value = parseInt(Math.abs(data.totalProduction * 100) / Math.abs(maxProd));
      return value;
    }
  };

  render() {
    if (getToken()) {
      if (this.props.renderMobileDates) return this.renderMobileDates();
      return this.renderDashboard();
    }
    this.props.router.navigate('/');
    return null;
  }
}

export default withRouter(MobileEnergyTips);
