const startMarkerSignalText = 'This is the beginning of your message thread';

import { ParticipantType, messageChannelIdentifiers } from '../../../../../constants';

const userScrollUpTargetElementId = 'viewTriggerNextPayload';
const userScrollDownTargetElementId = 'viewTriggerScrollDownToLatest';
const userScrollDownLeftNavElementId = 'viewTriggerScrollDownLeftNav';

const messageMaxWidth = '440px';
const messageMinWidth = '340px';

export const messageThreadLoadingTime = 1000;

/**
 * For (string) date comparisons, and ensuring format changes are standardized
 */
const messageTimestampFormat = 'MMM D, YYYY';

export const messagesDisplayOptions = {
  ALL_MESSAGES: 0,
  MY_MESSAGES: 1,
  SEARCH_MESSAGES: 2
};

export const INBOX_MESSAGE_CLASSNAME = 'blurbs-message-wrapper';


const LEFT_PANE_NO_MESSAGE_DISPLAY_CONTENT = 'There are no messages to display';

const CENTER_PANE_NO_MESSAGE_DISPLAY_CONTENT =
  'Your homeowner messages (text, email and notes) will appear here. Add a homeowner to get started.';

const FILE_UPLOAD_STATUS = {
  IS_DELETEABLE: 1,
  IS_UPLOADING: 2,
  IS_UPLOADED: 3,
  IS_ERROR: 4
};

export {
  messageTimestampFormat,
  startMarkerSignalText,
  userScrollUpTargetElementId,
  userScrollDownTargetElementId,
  userScrollDownLeftNavElementId,
  messageMaxWidth,
  messageMinWidth,
  ParticipantType,
  messageChannelIdentifiers,
  LEFT_PANE_NO_MESSAGE_DISPLAY_CONTENT,
  CENTER_PANE_NO_MESSAGE_DISPLAY_CONTENT,
  FILE_UPLOAD_STATUS
};
