import { Services } from "../../../Api/services";
import * as config from "../../../shared/config";


export const postHomeownerForgotPassword = async data => {
	const url = `${config.HOME_OWNER_BASE_URL}homeowner/resendinvitation`;

	const bodyData = {
		"email": data
	};
	
    let res = await Services.postCall(url, bodyData, true);
	return { data: res.data };
};