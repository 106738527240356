import * as React from 'react';
import { AreaChart } from 'react-charts-d3';
import moment from 'moment';

const defaultAxisConfig = {
  showLegend: false,
  showXAxis: false,
  showXAxisLabel: false,
  xLabel: 'X Axis',
  xLabelPosition: 'right',
  showYAxis: true,
  showYAxisLabel: false,
  yLabel: '',
  yLabelPosition: 'top'
};

export class HomeowerAreaChart extends React.Component {
  constructor(props) {
    super(props);
    this.previousOneDayDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    this.previousTwoDayDate = moment().subtract(2, 'days').format('YYYY-MM-DD');
    this.state = {
      renderChart: false
    };
    /**
     * The following props can be passed, optionally, to all for easier customization
     * axisConfig: Object
     * chartHeight: Number
     * customAxisHeight: string
     * customSpanAxisHeight: string
     * customSpanPadding: string
     * customAxisFontSize: string
     * zeroStatePosition: string
     * zeroStateMarginTop: string
     */
  }

  shouldComponentUpdate() {
    if (!this.state.renderChart) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * TODO: turn the div into a styled div using styled-components.
   * This will allow us to pass in props rather than use inline styles
   */
  displayZeroState = () => {
    return (
      <div
        className="empty-chart"
        style={{
          position: this.props.zeroStatePosition || '',
          marginTop: this.props.zeroStateMarginTop || ''
        }}
        data-testid="empty-chart">
        <i className="fa fa-bar-chart" aria-hidden="true"></i>
        <p style={{}}>There is no production data to display.</p>
      </div>
    );
  };

  render() {
    let { pastData, energyReport } = this.props;

    if (!pastData || !energyReport || !energyReport.interval) {
      return <></>;
    } else if (!pastData[0]  && !energyReport?.totalProduction) {
      return this.displayZeroState();
    };

    let previousOneDayData = pastData.filter((energyData)=>{
      return energyData.date == this.previousOneDayDate;
    })[0];

    let previousTwoDayData = pastData.filter((energyData)=>{
      return energyData.date == this.previousTwoDayDate;
    })[0];

    let dataBucketedByDay = {
      [moment().format('YYYY-MM-DD')]: (energyReport.historyData && energyReport.historyData) || [],
      [this.previousOneDayDate]: (previousOneDayData?.['historyData']) || [],
      [this.previousTwoDayDate]: (previousTwoDayData?.['historyData']) || []
    };

    let productionInterval = energyReport.interval;

    const chartDateGroupingLabels = [];

    const prodData = [
      previousTwoDayData?.['totalProduction'],
      previousOneDayData?.['totalProduction'],
      energyReport.totalProduction
    ];

    /**
     * Object in array wrapper to match expected data shape by chart lib
     */
    const data = [
      {
        values: []
      }
    ];

    let i = 0;

    let chartJsonData = [];

    let date = null;

    /**
     * Establish the 3 data points for the y-axis
     */
    let highestProductionMeasure = Number.MIN_SAFE_INTEGER;
    let lowestProductionMeasure = Number.MAX_SAFE_INTEGER;
    let middleProductionMeasure = 0;

    /**
     * For each of the 3 time periods...
     */
    for (let day in dataBucketedByDay) {
      // format dates for display in the chart in x-axis
      chartDateGroupingLabels.push(
        moment(day).format('MMM DD') === moment().format('MMM DD')
          ? 'Today'
          : moment(day).format('MMM DD')
      );
      // for the third iteration (presumably because there are 3 x-axis elements), assign the oldest moment
      if (i === 2) date = day;
      if (dataBucketedByDay[day] instanceof Array) {
        // eslint-disable-next-line no-loop-func
        chartJsonData[i] = dataBucketedByDay[day].map((val) => {
          let obj = typeof val === 'string' ? JSON.parse(val) : val;
          highestProductionMeasure =
            Number(obj.production) > highestProductionMeasure
              ? Number(obj.production)
              : highestProductionMeasure;
          lowestProductionMeasure =
            Number(obj.production) < lowestProductionMeasure
              ? Number(obj.production)
              : lowestProductionMeasure;
          return {
            x: obj.stage,
            y: obj.production,
            z: moment(obj.stage * 1000).format('YYYY-MM-DD hh:mm')
          };
        });
      }
      i++;
    }

    middleProductionMeasure = (highestProductionMeasure + lowestProductionMeasure) / 2;

    let timeSeriesPlaceholder = [];

    let diff = 300;

    // for the latest period, e.g. 'Today'....
    if (chartJsonData[0] instanceof Array && chartJsonData[0].length > 1) {
      // ...set an actual value for `diff` based on measurement deltas between first 2 values of `stage` time value
      diff = chartJsonData[0][1].x - chartJsonData[0][0].x;
    }

    // create an array of length 259500/diff with empty production values and intervals comprised of increments using diff value
    for (let i = 0; i < 259500 / diff; i++) {
      timeSeriesPlaceholder.push({
        x: moment(date).valueOf() / 1000 + i * diff,
        y: 0
      });
    }

    /**
     * If we are missing data for today, provide use placeholder model to structure presentation consistently
     */
    if (chartJsonData[0] && chartJsonData[0].length === 0) {
      chartJsonData[0] = timeSeriesPlaceholder;
    }

    this.setState({ renderChart: true });

    /**
     * Order the data by time interval
     */
    data[0].values = [
      ...chartJsonData[0],
      ...chartJsonData[1],
      ...chartJsonData[2],
      ...timeSeriesPlaceholder
    ].sort((v2, v1) => {
      if (v1.x > v2.x) {
        return -1;
      }
      return 1;
    });

    return (
      <div className="areachart-wrapper" data-testid="area-chart">
        <div className="custom-axis" style={{ height: this.props.customAxisHeight || '' }}>
          <span
            style={{
              height: this.props.customSpanAxisHeight || '',
              paddingTop: this.props.customSpanPadding || '',
              fontSize: this.props.customAxisFontSize || ''
            }}>
            {Math.round(highestProductionMeasure * 10) / 10}
            <br />
            {'kW'}
          </span>
          <span
            style={{
              height: this.props.customSpanAxisHeight || '',
              paddingTop: this.props.customSpanPadding || '',
              fontSize: this.props.customAxisFontSize || ''
            }}>
            {`${Math.round(middleProductionMeasure * 10) / 10}`}
            <br />
            {'kW'}
          </span>
          <span
            style={{
              height: this.props.customSpanAxisHeight || '',
              paddingTop: this.props.customSpanPadding || '',
              fontSize: this.props.customAxisFontSize || ''
            }}>
            {Math.round(lowestProductionMeasure * 10) / 10}
            <br />
            {'kW'}
          </span>
        </div>
        <div className="chart-outer">
          <AreaChart
            data={data}
            useColorScale={true}
            colorScale={{ from: '#FFD110', to: '#FFD110' }}
            showGrid={false}
            fluid={true}
            height={this.props.chartHeight || 300}
            strokeWidth={2}
            showLegend={false}
            axisConfig={this.props.axisConfig || defaultAxisConfig}
          />
          <div className="xAxisWrap">
            {chartDateGroupingLabels.reverse().map((val, i) => {
              return (
                <div className="innerChild" key={i} style={{ fontSize: this.props.customDateFontSize || '' }}>
                  <div>{val}</div>
                  <div className="prod" style={{ fontSize: this.props.customAxisFontSize || '' }}>
                    {((prodData[i] ? Math.abs(prodData[i]) : 0) / productionInterval).toFixed(1)}{' '}
                    {'kWh'}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
