import React from 'react';
import { Modal, Button } from 'antd';
import { ReactComponent as Delete_icon } from '../../Assets/Images/trash_yellow.svg';

export class DeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popUp: true
    };
  }

  render() {
    let { popUp } = this.state;
    return (
      <Modal
        open={popUp}
        wrapClassName="popUp"
        closable={false}
        cancelText="Cancel"
        okText="Yes, delete"
        onCancel={() => {
          this.setState({ popUp: false });
          this.props.deletePopUpProp('cancel');
        }}
        onOk={() => {
          this.props.deletePopUpProp('ok');
        }}
        footer={[
          <Button
            key="cancelCase"
            className="btn btn-secondary"
            onClick={() => {
              this.setState({ popUp: false });
              this.props.deletePopUpProp('cancel');
            }}
          >
            Cancel
          </Button>,
          <Button
            key="deleteCase"
            className="btn btn-primary"
            onClick={() => {
              this.props.deletePopUpProp('ok');
            }}
          >
            Yes, delete
          </Button>
        ]}
      >
        <Delete_icon className="delete-icon" />
        <h3 className="header">{this.props.headerText}</h3>
        <p>{this.props.subText}</p>
      </Modal>
    );
  }
}

export { DeleteModal as default };
