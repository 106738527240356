// import npm packages
import { combineReducers } from "redux";

// import local files
import {
    RECEIVE_HOMEOWNERS_LIST,
} from "../../types/solar";

const homeownersList = (state = {}, { type, data }) => {
    switch (type) {
        case RECEIVE_HOMEOWNERS_LIST:
            return data;
        default:
            return state;
    }
};

export const reducer = combineReducers({
    homeownersList
});