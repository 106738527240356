import React from 'react';
import styled from 'styled-components';

const BodhiPopoverStyles = styled.div`
  font-family: '${(props) => props.theme.fonts.standardRegular}';
  position: relative;
  .po-heading-wrap {
    display: flex;
    flex-direction: row;
  }
  .po-icon {
    margin-right: 10px;
    height: 25px;
  }
  button.po-icon-close {
    position: absolute;
    border: 0 none;
    padding: 0;
    background-color: transparent;
    right: 0;
    top: 0;
    width: 15px;
    height: 15px;
  }
  .po-heading {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 15px;
    padding-top: 5px;
    font-family: '${(props) => props.theme.fonts.standardBold}';
  }
  .po-body-text {
    font-size: 12px;
    font-family: inherit;
  }
`;

const BodhiPopover = (props) => {
  let type = 'default-popover';
  if (props.type) {
    switch (props.type) {
      case 'installer':
        type = 'installer-popover';
      default:
        type = `default-popover ${props.type}`;
    }
  }

  return (
    <BodhiPopoverStyles type={type}>
      {props.heading && (
        <div className="po-heading-wrap">
          {props.icon && <img src={props.icon} className="po-icon" alt="an icon" />}
          <div className="po-heading">{props.heading}</div>
          {props.showClose === true && (
            <button
              onClick={(e) => {
                if (typeof props.callback === 'function') {
                  props.callback(e);
                }
              }}
              className="po-icon-close"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          )}
        </div>
      )}
      <div className="po-body-text">{props.text}</div>
      {props.children}
    </BodhiPopoverStyles>
  );
};

export default BodhiPopover;
