import React from 'react';
import styled from 'styled-components';

const RibbonStyles = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  font-family: 'HarmoniaSansProCyrBold';
  position: relative;
  top: 80px;
  width: 100%;
  height: 30px;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  padding: 0 25px;
  background-color: ${(props) => props.theme.colors.yellow};
  .close-icon {
    float: right;
    line-height: 30px;
    margin-right: 10px;
    cursor: pointer;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md.up - 1}px) {
    height: 50px;
  }
`;

export const Ribbon = (props) => <RibbonStyles>{props.children}</RibbonStyles>;

export default Ribbon;
