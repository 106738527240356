import React from 'react';
import moment from 'moment';

import { deriveUsageStateFromUrl } from '../../../../shared';
import { filterHomeownerTouches } from '../../../Common/FilterTouchHistory';
import { systemDetailsHelper } from './';
import { collapse, expand } from './helpers';
import { IWhatIsNextMessage } from './types';
import { replaceStatusFeedText } from '../../container/statusFeed/replaceText';

import { TSystemGlance, TLoggedInUser, TTouchData } from './types';

const whatsNextTextHelper = (
  content: string,
  systemGlance: TSystemGlance,
  loggedInUser: TLoggedInUser,
  customTouchData: TTouchData
) => {
  return (
    content &&
    replaceStatusFeedText(
      content,
      systemGlance,
      loggedInUser,
      customTouchData,
      deriveUsageStateFromUrl(),
      customTouchData
    )
  );
};

/**
 * Render content for What's Next for the homeowner, based on latest status
 *
 * @params {Object} props
 * @return React FC
 */
export const WhatIsNextMessage: React.FC<IWhatIsNextMessage> = (props) => {
  const { clickHandler, loggedInUser, companyJourney, systemGlance, isOpen } = props;

  const apiData = (loggedInUser && loggedInUser.solarUnit) || {};
  const formSetting = loggedInUser?.homeownerData?.formSetting;
  const filterData =
    apiData.touchHistory &&
    filterHomeownerTouches(apiData.touchHistory, [], '', companyJourney, [])[0];

  const touchName = (filterData && filterData.touchName) || '';

  const customTouchData =
    companyJourney && filterData && companyJourney[touchName.toLowerCase().replace(/ /g, '')];

  let whatsNextContent = customTouchData && customTouchData.whatsNext;
  let whatsNextTitle = customTouchData && customTouchData.whatsNextTitle;

  return (
    <div className="white-box margb35 border-r0 border0">
      <div className="premiumBackground premiumSecondaryText margb20 border-r0 padding-22">
        <div className="heading-small">WHAT'S NEXT?</div>
        <h2 className="dashboard-shedule-heading">{whatsNextTitle}</h2>
        <p className="dashboard-shedule-text">
          {whatsNextTextHelper(whatsNextContent, systemGlance, loggedInUser, customTouchData)}
        </p>
        <p />

        <div className="dashboar-info-box">
          <span className="text">
            {loggedInUser.homeownerData &&
              moment(filterData && filterData.createdAt)
                .utcOffset(loggedInUser.homeownerData.offset * 60)
                .format('MMM DD, h:mm a')}
          </span>
          {
             !formSetting?.hideMoreInfo && (window as any).$productSuite === 3 && <span className="float-right">
              <a
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseDetails"
                aria-expanded="true"
                aria-controls="collapseDetails"
                onClick={clickHandler}>
                {!isOpen ? collapse() : expand()}
              </a>
            </span>
          }
          
        </div>
      </div>
      <div id="collapseDetails" className="more-detail-wrapper border-r0 collapse">
        <h3>More details</h3>
        {systemDetailsHelper({
          phase: filterData && filterData.phase,
          loggedInUser,
          systemGlance
        })
          .split('\n')
          .map((item: string, idx: number) => {
            return (
              <span key={idx}>
                {item}
                <br />
              </span>
            );
          })}
      </div>
    </div>
  );
};

export default WhatIsNextMessage;
