import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { state } from 'aws-cognito-redux-saga';
import { EyeTwoTone } from '@ant-design/icons';
import withRouter from './../../../../shared/withRouter';
import * as validate from './../../../../shared/validators';
import * as config from './../../../../shared/config';
import { handleCognitoError } from './../../../../shared/handleCognitoError';
import { envImageUrl, checkImageValid, convertJSON2CSS, getLoadingIcon } from '../setUserBackgroud';
import './../style.css';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const style = {
  error: {
    width: '80%',
    color: 'rgb(200,0,0)'
    // margin: "8px",
  }
};

class ForceChangePassword extends Component {
  static propTypes = {
    history: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    completeNewPassword: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.newPassword = '';
    this.loader = false;
    this.confirmPassword = '';
    this.slug = '';
    this.authError = undefined;
    this.image = '';
    this.isChecked = true;
    this.state = {
      isLoaded: false,
      code: '',
      password: '',
      newPassword: ''
    };
  }
  componentWillMount() {
    this.loader = false;
    this.slug = localStorage.getItem('slug') || this.props.router.params.slug;
    convertJSON2CSS(this.slug).then((r) => {
      this.companyName = r;
    });
    this.props.init();
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.image && nextProps.image.status === 200) {
      this.image = nextProps.image.data.logo;
    }
  }
  onChange = (e) => (this.isChecked = e.target.checked);

  changePassword = (values) => {
    let newPasswordError = validate.passwordValidation(this.newPassword);
    let email =
      this.props.router.location && this.props.router.location.state
        ? this.props.router.location.state.email
        : localStorage.getItem('emailForceChangePassword') || '';
    let confirmPasswordError = validate.passwordValidation(this.confirmPassword);
    if (this.newPassword !== '' && this.confirmPassword !== '') {
      if (!this.error) {
        if (!newPasswordError) {
          if (!confirmPasswordError) {
            if (this.isChecked) {
              if (email !== '') {
                this.loader = true;
                localStorage.setItem('isNewUser', true);
                this.props.completeNewPassword(email, this.state.password);
                // window.location.reload();
              } else {
              }
            } else {
              this.loader = false;
              this.authError = { message: config.TERMS_AND_CONDITION_CHECK };
            }
          } else {
            this.authError = { message: confirmPasswordError };
          }
        } else {
          this.authError = { message: newPasswordError };
        }
      } else {
        this.authError = { message: this.error };
        this.error = null;
      }
    } else {
      this.authError = { message: config.EMPTY_PASSWORD };
    }
    this.forceUpdate();
  };
  componentWillUpdate() {
    this.error = validate.passwordMatch(this.newPassword, this.confirmPassword);
  }

  componentDidMount() {
    if (this.slug) {
      envImageUrl(this.slug).then((response) => {
        if (response && !this.state.defaultLoginImage) {
          this.setState({
            defaultLoginImage: response
          });
        }
      });
    } else {
      this.setState({
        defaultLoginImage:
          'https://prod-17terawatts.s3.amazonaws.com/public/logo/desktopLogin_BG.jpg'
      });
    }
  }

  componentDidUpdate() {
    if (this.props.auth.error) {
      this.loader = false;
      this.authError = this.props.auth.error;
    }
  }
  onChangePassword = (e) => {
    this.newPassword = e.target.value.trim();
    this.setState({ password: e.target.value.trim() });
  };
  onChangeNewPassword = (e) => {
    this.confirmPassword = e.target.value.trim();
    this.setState({ newPassword: e.target.value.trim() });
  };

  termsAndCondition = () => {
    if (this.slug) this.props.router.navigate('/' + this.slug + '/terms');
    else this.props.router.navigate('/terms');
  };
  renderChangePassword() {
    const { handleSubmit, image } = this.props;
    let css = {
      'background-image': `url(${this.state.defaultLoginImage})`
    };
    return (
      <React.Fragment>
        <title>{this.companyName || 'Bodhi'} - Change Password</title>
        <Spin spinning={this.loader} indicator={antIcon} style={{ color: 'white' }}>
          <div id="test-bg" className="login-bg body-height" style={css}>
            <div id="login-container" className="h-100">
              <div className="login-box ">
                <div className="d-inline-block justify-content-end w-100 text-right">
                  <div className="white-logo d-inline-block" />
                </div>
                <div className="box-Shadow center-white-box" id="reset-password-cont">
                  <div className="forgotpassword">
                    <div
                      className="logo-blue"
                      style={{
                        'background-image': `url(${
                          (image && image.data && image.data.mobileLogo) ||
                          getLoadingIcon(this.slug)
                        })`
                      }}
                    />
                    <p className="mobile-h2">
                      Hi. I'm Bodhi.
                      <br />
                      Your partner on the solar journey
                    </p>
                    <form action="reset-error.html">
                      <div className="form-group">
                        <small>
                          Please set a new password that is 12 or more alphanumeric characters.
                        </small>
                        <Input.Password
                          className="form-control"
                          id="exampleInputPassword1"
                          type="password"
                          name="password"
                          iconRender={() => <EyeTwoTone />}
                          placeholder="New Password"
                          onChange={this.onChangePassword}
                        />
                      </div>

                      <div className="form-group">
                        <Input.Password
                          className="form-control"
                          id="exampleInputPassword1"
                          type="password"
                          name="confirmPassword"
                          iconRender={() => <EyeTwoTone />}
                          placeholder="Confirm Password"
                          onChange={this.onChangeNewPassword}
                        />
                      </div>
                      {this.authError ? (
                        <div style={style.error}>
                          {JSON.stringify(this.authError) !== '{}'
                            ? this.authError.code
                              ? handleCognitoError(this.authError.code)
                              : this.authError.message
                            : null}
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        className="login-remeber form-group col-md-12"
                        style={{ padding: 0, marginTop: '35px' }}
                      >
                        <div className="check-wrapper">
                          <label className="container">Remember Me</label>
                          <input type="checkbox" defaultChecked={true} />
                          <span className="checkmark premiumLinks bgColor" />
                        </div>
                      </div>

                      <div className="login-remeber form-group col-md-12" style={{ padding: 0 }}>
                        <div className="check-wrapper">
                          <label className="container">
                            I agree to{' '}
                            <span
                              onClick={() => this.termsAndCondition()}
                              className="terms-and-condition-check premiumLinks"
                            >
                              Terms and Conditions{' '}
                            </span>
                          </label>
                          <input type="checkbox" defaultChecked={true} />
                          <span className="checkmark premiumLinks bgColor" />
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          onClick={handleSubmit(this.changePassword)}
                          className="btn yellow-btn premiumButtonFill premiumButtonText"
                        >
                          Log in
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }

  renderSignIn() {
    this.props.router.navigate('/login');
    window.location.reload();
    return null;
  }

  render() {
    const { auth } = this.props;
    if (this.props.router.location.state && this.props.router.location.state.isRefresh) {
      this.setState({ isLoaded: true });
      this.props.router.navigate(this.props.router.location.state.email, {
        replace: true
      });
    }

    return (!this.state.isLoaded &&
      this.props.router.location.state &&
      !this.props.router.location.state.isRefresh) ||
      auth.hasChangedPassword === state.AUTH_SUCCESS ||
      !localStorage.getItem('emailForceChangePassword')
      ? this.renderSignIn()
      : this.renderChangePassword();
  }
}

export default withRouter(
  reduxForm({
    form: 'forceChangePassword'
  })(ForceChangePassword)
);
