import React from 'react';
import {
  HeadlineText,
  StyledCard,
  StyledCardText,
  StyledFooter,
  StyledHeader
} from '../../shared/styles/Card.style';
import { TCard } from './types';
import UserIdentifier from './UserIdentifier';

const Card = (props: TCard) => {
  const { userInitials, avatar, headerText, bodyText, footerText } = props;

  return (
    <StyledCard data-testid="card-container">
      <StyledHeader>
        <UserIdentifier userInitials={userInitials} image={avatar} />
        <HeadlineText>{headerText}</HeadlineText>
      </StyledHeader>
      <StyledCardText>{bodyText}</StyledCardText>
      {footerText && (
        <StyledFooter data-testid="card-footer">
          <StyledCardText>{footerText}</StyledCardText>
        </StyledFooter>
      )}
    </StyledCard>
  );
};

export default Card;
