import styled from 'styled-components';

import { HOMEOWNER_MOBILE_SCROLL } from '../../../../constants';
import { DefaultCounterDecorator } from '../../../Common';

export const CountDecoratorJumbo = styled(DefaultCounterDecorator)`
  &.count {
    left: 36px;
    top: 4px;
    font-size: 12px;
    line-height: 14px;
    border-radius: ${(props) => props.theme.borderRadius.faint};
    background-color: ${(props) => props.theme.colors.orange};
    padding: 1px 2px 0;
    width: auto;
    height: 15px;
  }
`;

export const CountDecoratorStandard = styled(DefaultCounterDecorator)`
  &.count {
    left: 31px;
    top: 4px;
    font-size: 12px;
    line-height: 14px;
    border-radius: ${(props) => props.theme.borderRadius.round};
    background-color: ${(props) => props.theme.colors.orange};
    padding: 1px 0 0 0;
    width: auto;
    min-width: 15px;
    height: 15px;
  }
`;

export const HomeownerFooterDecorator = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.lg.up - 1}px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    bottom: 0;
    background: ${(props) => props.theme.colors.white};
    width: 100%;
    height: 75px;
    position: fixed;
    box-shadow: 0px 21px 67px rgba(0, 0, 0, 0.8) !important;
    z-index: 998;
    border-top: 1px solid ${(props) => props.theme.colors.ltgrey3};
    color: ${(props) => props.theme.colors.ltgrey5};
    .mob-menu-untether {
      position: relative;
      &.compose {
        .compose-message-cta {
          position: absolute;
          left: -135px;
          top: -90px;
          .tethered {
            display: inline-block;
          }
          .untethered {
            display: none;
          }
        }
      }
    }
    body.${HOMEOWNER_MOBILE_SCROLL}
      div.mob-menu-untether.compose
      button.compose-message-cta
      div.menu-text {
      display: none;
    }
    .mob-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      min-width: 33%;
      > span {
        width: 100%;
        text-align: center;
        margin-top: 5px;
        font-size: 12px;
        font-weight: 300;
      }
      .counter-wrap {
        position: relative;
      }
    }
  }
`;
