import { messageIsUnreadByUser } from '../screens/Installer/components/inbox/helpers';
import { TInboxMessage, TInboxParticipant, TViewedBy, TMessageType } from '../types';

export interface IBodhiMessageClass extends TInboxMessage {
  wasReadByUser: (userId: number) => boolean;
}

/**
 * Initial iteration of a domain object to represent A Bodhi Message
 */
class Message implements IBodhiMessageClass {
  _message: TInboxMessage;

  constructor(message: TInboxMessage) {
    this._message = message;
  }

  /**
   * Accepts a userId and references `viewedBy`
   *
   * @param userId
   * @returns Boolean
   */
  wasReadByUser(userId: number): boolean {
    return !messageIsUnreadByUser(this, String(userId));
  }

  get message(): string {
    return this._message.message;
  }

  get messageType(): number {
    return this._message.messageType;
  }

  get senderId(): string {
    return this._message.senderId;
  }

  get channel(): TMessageType {
    return this._message.channel;
  }

  get createdAt(): string {
    return this._message.createdAt;
  }

  get attachments(): any[] {
    return this._message.attachments || [];
  }

  get isDeleted(): boolean {
    return this._message.isDeleted || false;
  }

  get subject(): string | undefined {
    return this._message.subject;
  }

  get id(): string {
    return this._message.id;
  }

  get threadId(): string {
    return this._message.threadId;
  }

  get senderType(): number {
    return this._message.senderType;
  }

  get sender(): TInboxParticipant {
    return this._message.sender;
  }

  get recipientId(): string {
    return this._message.recipientId;
  }

  get recipientType(): number {
    return this._message.recipientType;
  }

  get recipient(): TInboxParticipant {
    return this._message.recipient;
  }

  get viewedBy(): TViewedBy[] {
    return this._message.viewedBy;
  }

  get updatedAt(): string {
    return this._message.updatedAt;
  }

  get touchName(): string | undefined {
    return this._message.touchName;
  }

  /**
   * @todo: These are deprecated, but they are still coming in from the API
   */
  get viewedByIds() {
    return this._message.viewedByIds;
  }
}

export default Message;
