import moment from 'moment';

import { retrieveLatestMessage } from '../../../components/inbox/helpers';
import { TInboxLUT } from '../../../components/inbox/types';
import {
  TInboxMessage,
  TMessagesLUT,
  TInboxPreview,
  TComponentModelData,
  TComponentModel
} from '../types';
import type { TThreads } from '../../../../../types';

import { ParticipantType } from '../../../components/inbox/constants';
import type { IBodhiMessageClass } from '../../../../../classes/Message';
import { IBodhiMessageThread } from '../../../../../classes/Thread';
import { ComponentType } from '../constants';
import MessageThreadEmptyState from '../../../components/inbox/MessageThreadEmptyState';
import MessageThread from '../../../components/inbox/MessageThread';
import IntersectionElement from '../../../../Common/IntersectionElement';

type TMapIterables = TInboxMessage & TInboxPreview;
type TIterableTypes = TInboxMessage[] | TInboxPreview[];
type TIterableMaps = TMessagesLUT | TInboxLUT;

type KeysOfUnion<T> = T extends T ? keyof T : never;
type TUnionKeys = KeysOfUnion<TMapIterables>;

type TIterableItem = Record<'key' | 'value', any>;
/**
 * Takes the iterable and returns a key and a value to adapt the iterables to a spec
 */
type TMapAdapter = (iterable: TMapIterables, count?: number) => TIterableItem;

/**
 * A mechanism to produce a lookup table by a string-y key
 *
 * @param {Array}
 * @param {String | Number}
 * @param {Function} optional adapter for creating the Map
 * @returns {Map}
 */
export function IterableMap(
  iterables: TIterableTypes,
  key: TUnionKeys,
  adapter?: TMapAdapter
): TIterableMaps {
  const iterableMap = new Map();

  if (iterables) {
    iterables.forEach((iterable: TMapIterables, count: number) => {
      const k = key as keyof TMapIterables;
      if (iterable[k]) {
        const results: TIterableItem = adapter
          ? adapter({ ...iterable }, count)
          : { key: iterable[k], value: { ...iterable } };
        iterableMap.set(results.key, results.value);
      }
    });
  }
  return iterableMap;
}

/**
 * Helper function to iterate over threads, and retrieve the most recent message(s) from each.
 * Gets the most recent message from each of the threads, to determine which threads are most recent.
 *
 * Returns most recent message from each thread
 * sorted in descending order based on `retrieveLatestMessage`
 *
 * @param {Object} props
 * @returns {Array} number of results in array is equal to the number argument
 */
export const getMostRecentMessagesFromThreads = (
  threads: IBodhiMessageThread[],
  options?: Record<'assignThreadId', boolean>
) => {
  if (!threads || !threads.length) {
    console.error(
      `Request made to 'getMostRecentMessagesFromThreads' but no threads provided - given ${threads}.`
    );
    return null;
  }

  const messages: IBodhiMessageClass[] = [];
  if (threads?.length) {
    threads.forEach((thread: TThreads) => {
      const latestMsg = { ...retrieveLatestMessage(thread.messages) };
      if (options?.assignThreadId) {
        latestMsg.threadId = thread.id;
      }
      messages.push(latestMsg);
    });
  }

  return messages;
};

/**
 * Iterates over an array of threads and flattens each using a specific key
 *
 * @param threads
 * @param key
 * @returns {Array}
 */
export const flattenThreadsForDatum = (threads: TThreads[], key: string) => {
  const resultsThreadDatums: any[] | [] = threads.reduce(
    (accThreadDatums: any[], thread: TThreads) => {
      const datum: any = thread[key as keyof TThreads];
      if (datum) {
        accThreadDatums.push(datum);
      }
      return accThreadDatums;
    },
    []
  );
  return resultsThreadDatums;
};

/**
 * Iterates over an array of messages and flattens each using a specific key
 *
 * @param threads
 * @param key
 * @returns {Array}
 */
export const flattenMessagesForDatum = (messages: IBodhiMessageClass[], key: string) => {
  const resultsMessageDatums: any[] | [] = messages.reduce(
    (accMessageDatums: any[], message: IBodhiMessageClass) => {
      const datum: any = message[key as keyof IBodhiMessageClass];
      if (datum) {
        accMessageDatums.push(datum);
      }
      return accMessageDatums;
    },
    []
  );
  return resultsMessageDatums;
};

/**
 * A canonical threads sort mechanism that UI concerns can use to ensure cohesion across components
 *
 * @param sortedThreads
 */
export const retrieveSortedThreads = (threads: TThreads[]) => {
  const sortedThreads = [...threads];
  /**
   * thread meta data is based on the updated date unless that's null then it's created
   */
  sortedThreads.sort((a: any, b: any) => {
    const msgA = retrieveLatestMessage(a.messages);
    const msgB = retrieveLatestMessage(b.messages);

    const dateA = Number(moment(msgA.createdAt).format('x'));
    const dateB = Number(moment(msgB.createdAt).format('x'));

    if (dateB === dateA) {
      // resolve equal timestamps with alpha comparison by homeowner last name
      return b.homeowner.lastName - a.homeowner.lastName;
    }

    // sort in descending order
    return dateB - dateA;
  });
  return sortedThreads;
};

/**
 * Checks the messages until we find a sender or a recipient that is a homeowner, and then grabs the corresponding id to use for `homeownerId`
 * @param {Array} IBodhiMessageClass[]
 * @returns {Number|undefined}
 */
export const retrieveHomeownerIdFromMessages = (messages: IBodhiMessageClass[]) => {
  if (!messages || !messages.length) {
    return undefined;
  }
  for (let i = 0; i < messages.length; i += 1) {
    if (messages[i].sender.type === ParticipantType.HOMEOWNER) {
      return Number(messages[i].sender.id);
    }
    if (messages[i].recipient.type === ParticipantType.HOMEOWNER) {
      return Number(messages[i].recipient.id);
    }
  }
  return undefined;
};

/**
 * @description Determines the component type to render based on the messages and loading state.
 * @param {number} size - data size.
 * @param {boolean} loading - Boolean indicating whether data is still being loaded
 * @returns {ComponentType} - The type of component to render based on the current state
 */
export const getResolvedType = (size: number, loading: boolean) => {
  let componentType = ComponentType.MESSAGE_THREAD_COMPONENT;
  if (loading === false && size === 0) {
    componentType = ComponentType.MESSAGE_EMPTY_STATE_COMPONENT;
  }
  return componentType;
};

/**
 * @description Resolves the props to be passed to the component based on the messages, loading, and data.
 * @param {number} size - data size.
 * @param {boolean} loading - A boolean flag indicating if the messages are currently being loaded.
 * @param {TComponentModelData} data - Additional data to be passed to the component.
 * @returns {Object|null} - Returns an object containing the props to be passed to the component, or null if no props are required.
 */
export const propsResolver = (size: number, loading: boolean, data: TComponentModelData) => {
  const resolvedType = getResolvedType(size, loading);
  let resolveProps = null;
  switch (resolvedType) {
    case ComponentType.MESSAGE_EMPTY_STATE_COMPONENT:
      resolveProps = null;
      break;
    default:
      resolveProps = { ...data };
  }
  return resolveProps;
};

/**
 * @description Generates a component model object based on the input messages, loading state, and data.
 * @param {number} size - data size.
 * @param {boolean} loading - The loading state to be used for generating the component model.
 * @param {TComponentModelData} data - The data to be used for generating the component model.
 * @return {TComponentModel} - The component model object containing the functional component, props, and children.
 */
export const componentModel = (
  size: number,
  loading: boolean,
  data: TComponentModelData
): TComponentModel => {
  const resolvedType = getResolvedType(size, loading);
  const props = propsResolver(size, loading, data);
  let FC = null;
  let children = [];

  switch (resolvedType) {
    case ComponentType.MESSAGE_EMPTY_STATE_COMPONENT:
      FC = MessageThreadEmptyState;
      break;
    default: {
      FC = MessageThread;
      children.push({
        props: { elementId: data.elementId },
        fc: IntersectionElement
      });
    }
  }

  return {
    fc: FC,
    props,
    children
  };
};
