import React from 'react';

import {
  Navigate,
  useLocation,
  useNavigate
} from 'react-router-dom'

import DropBoxSync from '../../../../shared/DropBoxSync'
import { getCode } from '../../../../shared/getAccessToken';
import { openNotificationWithIcon } from '../../../../shared/notification';

const drpBxSync = new DropBoxSync()

const dropBoxFacade = async (code) => {
  return await drpBxSync.doAuth(code)
}

let requests = 0

const Auth = props => {

  let location = useLocation()
  let navigate = useNavigate()

  const code = location.hash || location.search
  const obj = location.hash.includes('error') ? false : getCode(code)

  if (requests === 0) {
    requests += 1
    if (obj && obj.code && location.pathname.includes('dropboxAuth')) {
      const codeHandled = dropBoxFacade(obj.code);
    } else if (obj.code) {
      localStorage.setItem('crmToken' ,obj.code);
      localStorage.setItem('isCRMToken', true);
    } else {
      openNotificationWithIcon('error', 'Not Authorized To Access Dropbox Account.', 'Error'); 
    }

    if (localStorage.getItem('pathname')) {
      return <Navigate
          to={{ pathname: localStorage.getItem('pathname') }}
          state={{
            isHomeOwner: localStorage.getItem('isHomeOwner') && localStorage.getItem('isHomeOwner') === 'true' ? true : false,
            email: localStorage.getItem('homeOwnerEmail'),
            isSuccess: true
          }}
          replace={ true }
        />
    }
  }

  return null
}

export default Auth
