import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reducer } from 'aws-cognito-redux-saga';

import HeaderComponent from './Header';
import {
  requestSearchData,
  requestInstallerDataById,
  receiveSearchData
} from '../../actions/header';
import { requestSlugData } from '../../../User/data/action';
import { setMetricsType } from '../../actions/dashboard';
import {  requestCompanyJourney } from '../../actions/companyJourney';

import { requestCompanyDetail } from '../../actions/profile';

const mapStatetoProps = (state) => {
  return {
    auth: state.auth,
    searchData: state.header.searchData,
    installerData: state.header.getInstaller,
    companyName: state.user.slugImage,
    companyJourney:
            (state.companyBodhiJourney &&
                state.companyBodhiJourney.bodhiJourneyReducer &&
                state.companyBodhiJourney.bodhiJourneyReducer.data) ||
            {}
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestSearchData,
      requestCompanyDetail,
      receiveSearchData,
      signOut: () => dispatch(reducer.signOut()),
      requestInstallerDataById,
      requestSlugData,
      setMetricsType,
      requestCompanyJourney
    },
    dispatch
  );
export default connect(mapStatetoProps, mapDispatchToProps)(HeaderComponent);
