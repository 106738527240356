import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { INTERSECT_VIEW_DURATION } from '../../constants';
import { IIntersectionElement } from './types';

/**
 * Provide observer and outcomes for element intersection with viewport
 */
const Intersecting = (ref: React.RefObject<HTMLElement>, options?: Record<string, any>) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observed = useRef(null);

  const observerConfig = {
    threshold: 1.0,
    ...options
  };

  useEffect(() => {
    observed.current = new IntersectionObserver(
      ([entry]) => setIsIntersecting(entry.isIntersecting),
      observerConfig
    );
  }, []);

  useEffect(() => {
    if (observed.current) {
      observed.current.observe(ref.current);
    }
    return () => {
      if (observed.current) {
        observed.current.disconnect();
      }
    };
  }, [ref]);

  return isIntersecting;
};

const IntersectionDecorator = styled.div<{ height: number; id?: string }>`
  margin: 1px 0;
  height: ${(props) => props.height}px;

  ${(props) =>
    props.id
      ? `body #${props.id}.hidden { display: none !important; }`
      : `body #${props.id}.hidden { display: initial !important; }`}
`;

/**
 * Deploy an HTML node which can provide Intersection Observer's observation
 */
const IntersectionElement: React.FC<IIntersectionElement> = (props) => {
  const { config, elementId, height, styles, handleIsIntersecting } = props;
  let throttle = 200;

  // read values from config, if provided
  if (!!config?.intersectThreshold) {
    throttle = config.intersectThreshold;
  }

  const [sampleTime, setSampleTime] = useState(Date.now() - throttle);

  const elementRef = useRef(null);

  const onScreen = handleIsIntersecting && elementRef ? Intersecting(elementRef) : undefined;

  useEffect(() => {
    let mounted = true;
    if (onScreen) {
      setTimeout(() => {
        if (mounted) {
          if (Date.now() > sampleTime + throttle) {
            setSampleTime(Date.now());
            if (handleIsIntersecting) {
              handleIsIntersecting(onScreen);
            }
          }
        }
      }, INTERSECT_VIEW_DURATION);
    }
    return () => {
      mounted = false;
    };
  }, [onScreen]);

  let adhocProps = {};
  let className: Record<string, boolean> = {};
  if (styles) {
    adhocProps = Object.assign(adhocProps, styles);
  }
  if (!!config?.hideable) {
    // config the element to be hideable, e.g. to be able to turn it off programmatically by hiding it
    className.hidden = true;
  }
  return (
    <IntersectionDecorator
      data-testid="intersect-el"
      ref={elementRef}
      height={height || 1}
      id={elementId}
      className={Object.keys(className).join(' ')}
      {...adhocProps}
    />
  );
};

export default IntersectionElement;
