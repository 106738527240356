import React, { Component, Suspense } from 'react';
import { Spin, Form, Collapse, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';

import { installerSettingKey } from '../../../../constants/routes';
import { withRouter, getToken, jwtDecode, isAdminUser } from '../../../../shared';

import { setLoader } from '../../../Common/Loader/action';

import {
  requestCompanyDetailSet,
  requestCompanyDetail,
  receiveCompanyDetail,
  requestUpdateLoggedInInstallerById
} from '../../actions/profile';

import { receiveCompanyCRMData } from '../../actions/companyJourney';

import {
  engageScoreIconPng,
  shareReferIconSvg,
  surveysIconPng,
  fingerTouchIconPng,
  chevronUpIconSvg,
  chevronDownIconSvg,
  settingsIconSvg
} from './../../../../constants/icons';

const loadingIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const PlanDetail = React.lazy(() => import('../../components/companySetting/PlanDetail'));
const SystemNotifications = React.lazy(() =>
  import('../../components/companySetting/SystemNotifications')
);
const ReferralMessage = React.lazy(() => import('../../components/companySetting/ReferralMessage'));
const DetailedPhaseInfo = React.lazy(() =>
  import('../../components/companySetting/DetailedPhaseInfo')
);
const FAQCompanySetting = React.lazy(() =>
  import('../../components/companySetting/FAQCompanySetting')
);
const BodhiJourney = React.lazy(() => import('../../components/companySetting/BodhiJourney'));

const BodhiLiteTemplate = React.lazy(() => import('../../components/companySetting/BodhiLiteTemplate'));
const TemplateInformation = React.lazy(() => import('../../components/companySetting/TemplateInformation'));

const LandingPage = React.lazy(() => import('../../../Common/LandingPage'));
const MultipleJourney = React.lazy(() => import('../../components/companySetting/MultipleJourney'));
const IntegrationDetail = React.lazy(() =>
  import('../../components/companySetting/IntegrationInformation')
);
const CompanyDetail = React.lazy(() =>
  import('../../components/companySetting/CompanyInformation')
);
const EdRerource = React.lazy(() => import('../../components/companySetting/EdRerource'));
const UtilityInformation = React.lazy(() =>
  import('../../components/companySetting/UtilityInformation')
);
const Hardware = React.lazy(() => import('../../components/companySetting/HardwareInformation'));

import { suspenseFallback } from './../../../../constants/components';

class CompanySettingsForm extends Component {
  constructor(props) {
    super(props);
    this.slug = this.props.router.params.slug;
    this.companyId = this.props.router.params.companyId;

    let [searchParams] = this.props.router.searchParams;
    const view = searchParams.get(installerSettingKey);

    this.state = {
      onNextInfo: true,
      isExpand: true,
      journeyIndex: null,
      generatingCutoff: {},
      monitoringCutoff: {},
      companySettingState: view || 'isCompany'
    };
  }

  componentDidMount() {
    this.installerData = jwtDecode(getToken());
    this.admin = isAdminUser(this.props.router);
    this.admin &&
      this.props.requestCompanyDetail({
        companyId: this.companyId,
        admin: this.admin
      });
  }

  componentDidUpdate(prevProps) {
    let [searchParams, setSearchParams] = this.props.router.searchParams;
    const view = searchParams.get(installerSettingKey);

    /**
     * @todo: the `admin` user uses inline state setting (left rail, below) whereas the installer uses Sider implementation.
     * We need to handle the cases differently.
     */
    if (view && this.state.companySettingState !== view) {
      this.setState({
        companySettingState: view
      });
    }

    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.props.receiveCompanyCRMData({});
      this.props.setLoader({ loader: true });
      this.props.receiveCompanyDetail({});
      this.companyId = this.props.router.params.companyId;
      this.props.requestCompanyDetail({
        companyId: this.companyId,
        admin: this.admin,
        slug:this.slug
      });
      this.setState({
        companySettingState: 'isCompany'
      });
    }
  }

  updateState = (obj) => {
    this.setState(obj);
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps &&
      nextProps.companyData &&
      nextProps.companyData.companyDetail &&
      nextProps.companyData.companyDetail.slug
    ) {
      this.slug = nextProps.companyData.companyDetail.slug;
    }
    if (
      nextProps.companyData &&
      nextProps.companyData.companyDetail &&
      nextProps.companyData.companyDetail.monitoringCutoff &&
      Object.entries(this.state.monitoringCutoff).length === 0
    ) {
      this.setState({
        monitoringCutoff: nextProps.companyData.companyDetail.monitoringCutoff
      });
    }
    if (
      nextProps.companyData &&
      nextProps.companyData.companyDetail &&
      nextProps.companyData.companyDetail.generatingCutoff &&
      Object.entries(this.state.generatingCutoff).length === 0
    ) {
      this.setState({
        generatingCutoff: nextProps.companyData.companyDetail.generatingCutoff
      });
    }
  }

  DelayedcancelHandler = () => {
    if (getToken()) {
      this.props.requestCompanyDetail({ companyId: this.companyId, admin: this.admin, slug:this.slug });
    }
    this.forceUpdate();
  };

  renderCompanySettingState(link) {
    switch (link) {
      case 'isPlan':
        return this.renderCompanyPlan();

      case 'isSystemNotification':
        return this.renderSystemNotification();

      case 'isReferralMessage':
        return this.renderReferralMessage();

      case 'isPhaseInfo':
        return this.renderDetailedInfo();

      case 'isFAQ':
        return this.renderFAQ();

      case 'isCompany':
        return this.renderCompanyDetail();

      case 'isIntegrations':
        return this.renderCompanyIntegration();

      case 'isUtilities':
        return this.renderUtility();

      case 'isHardware':
        return this.renderHardwareDetail();

      case 'isEdResource':
        return this.renderEdResource();

      case 'addJourney':
        return this.renderMultipleJourney();

      case 'isTemplate':
        return this.renderBodhiLiteTemplate();
      
      case 'templateInformation':
        return this.renderTemplateInformation();

      default:
        return this.renderBodhiJourney();
    }
  }

  renderTemplateInformation(){
    return (
      <Suspense fallback={suspenseFallback}>
        <TemplateInformation slug = {this.slug} companyId={this.companyId}/>
      </Suspense>
    )
  }

  renderBodhiLiteTemplate(){
    return (
      <Suspense fallback={suspenseFallback}>
        <BodhiLiteTemplate
          journeyState={this.state.companySettingState}
          templateName={this.state.templateName}
          setLoader={this.props.setLoader}
          tokenData={{
            companyId: this.companyId,
            slug: this.slug
          }}
          {...this.props}
        />
      </Suspense>
    );
  }

  renderHardwareDetail() {
    return (
      <Suspense fallback={suspenseFallback}>
        <Hardware companyId={this.companyId} {...this.props} />
      </Suspense>
    );
  }

  renderEdResource() {
    return (
      <Suspense fallback={suspenseFallback}>
        <EdRerource
          companyId={this.companyId}
          DelayedcancelHandler={() => this.DelayedcancelHandler()}
          {...this.props}
        />
      </Suspense>
    );
  }

  renderUtility() {
    return (
      <Suspense fallback={suspenseFallback}>
        <UtilityInformation
          companyId={this.companyId}
          {...this.props}
          DelayedcancelHandler={() => this.DelayedcancelHandler()}
        />
      </Suspense>
    );
  }

  renderCompanyIntegration() {
    return (
      <Suspense fallback={suspenseFallback}>
        <IntegrationDetail
          companyId={this.companyId}
          isAdmin={this.admin}
          {...this.props}
          DelayedcancelHandler={() => this.DelayedcancelHandler()}
        />
      </Suspense>
    );
  }

  renderCompanyDetail() {
    return (
      <Suspense fallback={suspenseFallback}>
        <CompanyDetail
          companyId={this.companyId}
          isAdmin={this.admin}
          {...this.props}
          DelayedcancelHandler={() => this.DelayedcancelHandler()}
        />
      </Suspense>
    );
  }

  renderCompanyPlan() {
    return (
      <Suspense fallback={suspenseFallback}>
        <PlanDetail companyId={this.companyId} {...this.props} setLoader={this.props.setLoader} />
      </Suspense>
    );
  }

  renderSystemNotification() {
    const { companyData } = this.props;
    return (
      <Suspense fallback={suspenseFallback}>
        <SystemNotifications
          companyData={companyData}
          isAdmin={this.admin}
          companyId={this.companyId}
          {...this.props}
          DelayedcancelHandler={() => this.DelayedcancelHandler()}
        />
      </Suspense>
    );
  }

  renderReferralMessage() {
    const { companyData } = this.props;
    return (
      <Suspense fallback={suspenseFallback}>
        <ReferralMessage
          companyData={companyData}
          companyId={this.companyId}
          {...this.props}
          setLoader={(loader) => {
            this.props.setLoader(loader);
          }}
          DelayedcancelHandler={() => this.DelayedcancelHandler()}
        />
      </Suspense>
    );
  }

  renderDetailedInfo() {
    const { companyData } = this.props;
    return (
      <Suspense fallback={suspenseFallback}>
        <DetailedPhaseInfo
          companyData={companyData}
          companyId={this.companyId}
          {...this.props}
          DelayedcancelHandler={() => this.DelayedcancelHandler()}
        />
      </Suspense>
    );
  }

  renderFAQ() {
    return (
      <Suspense fallback={suspenseFallback}>
        <FAQCompanySetting
          companyId={this.companyId}
          setLoader={this.props.setLoader}
          {...this.props}
        />
      </Suspense>
    );
  }

  renderBodhiJourney() {
    return (
      <Suspense fallback={suspenseFallback}>
        <BodhiJourney
          journeyState={this.state.companySettingState}
          journeyData={this.state.journeyData}
          setLoader={this.props.setLoader}
          tokenData={{
            companyId: this.companyId,
            slug: this.slug
          }}
          {...this.props}
        />
      </Suspense>
    );
  }

  renderLandingPage() {
    return (
      <Suspense fallback={suspenseFallback}>
        <LandingPage />
      </Suspense>
    );
  }

  renderProfileScreen = () => {
    const {
      companyData: { companyDetail }
    } = this.props;

    return (
      <React.Fragment>
        <Spin
          spinning={this.props.loader}
          indicator={loadingIcon}
          size="large"
          style={{ color: '#00142D' }}
        >
          {!companyDetail ? (
            this.renderLandingPage()
          ) : (
            <div>
              <title>Company Settings - {companyDetail && companyDetail.name + '-'} Bodhi</title>
              {this.admin && this.renderCompanyInfo()}
              <section
                className="white-box-wrapper mobileView"
                style={this.admin ? {} : { marginTop: '72px' }}
              >
                {this.renderDesktopView()}
              </section>
            </div>
          )}
        </Spin>
      </React.Fragment>
    );
  };

  renderCompanyInfo() {
    const {
      companyData,
      companyData: { companyDetail, companyStats }
    } = this.props;

    let contactNumber =
      companyDetail &&
      companyDetail.contactPhone &&
      '(' +
        companyDetail.contactPhone.slice(0, 3) +
        ') ' +
        companyDetail.contactPhone.slice(3, 6) +
        '-' +
        companyDetail.contactPhone.slice(6, companyDetail.contactPhone.length);

    return (
      <section className="profile-detail-box">
        <div className="container margin-t-150">
          <div className="row justify-content-md-center view content-center">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-5 col-xs name-adddres-box">
                  <h1>{companyDetail && companyDetail.name} </h1>
                  <div className="address-box">
                    {companyDetail && companyDetail.address.address1} <br />
                    {companyDetail && companyDetail.address.city},{' '}
                    {companyDetail && companyDetail.address.state}{' '}
                    {companyDetail && companyDetail.address.postalCode} <br />
                    {contactNumber}
                  </div>
                  <div
                    className="company-link"
                    onClick={() => {
                      localStorage.setItem('slug', companyDetail.slug);
                      this.props.router.navigate(
                        `/${companyDetail.slug}/${this.companyId}/dashboard`
                      );
                    }}
                  >
                    View Company Portal
                  </div>
                </div>
                <div className="col-md-7 col-xs personal-detail-box">
                  <div className="detail-box">
                    <p>Customer Since</p>
                    <p>
                      {' '}
                      {companyDetail &&
                        companyDetail.created &&
                        moment(companyDetail.created * 1).format('MMM DD, YYYY')}
                    </p>
                  </div>
                  {companyDetail && companyDetail.crmLastSync && (
                    <div className="detail-box">
                      <p>Last Sync Time</p>
                      <p>
                        {moment(companyDetail.crmLastSync * 1000).format('DD-MM-YYYY h:mm:ss a')}
                      </p>
                    </div>
                  )}
                  <div className="detail-box">
                    <p> Contact</p>
                    <div className="address-box">
                      {companyDetail && companyDetail.contactName}
                      <br />
                      {companyDetail && companyDetail.contactEmail}
                      <br />
                      {contactNumber}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 no-padding-box">
              <div className="white-box">
                <div className="engagement-scroe-box">
                  <div className="icon">
                    <img src={engageScoreIconPng} />
                  </div>
                  <div className="content">
                    <p className="content">Employees</p>
                    <p className="number">{(companyStats && companyStats.installers) || 0}</p>
                  </div>
                </div>
                <div className="referrals-box">
                  <div className="icon">
                    <img src={shareReferIconSvg} />
                  </div>
                  <div className="content">
                    <p className="content">Homeowners</p>
                    <p className="number">{(companyStats && companyStats.homeowners) || 0}</p>
                  </div>
                </div>
                <div className="surveys-box">
                  <div className="icon">
                    <img src={surveysIconPng} />
                  </div>
                  <div className="content">
                    <p className="content">Surveys</p>
                    <p className="number">{(companyStats && companyStats.survey) || 0}</p>
                  </div>
                </div>
                <div className="social-shares-box">
                  <div className="icon">
                    <img src={fingerTouchIconPng} />
                  </div>
                  <div className="content">
                    <p className="content">Referrals</p>
                    <p className="number">{(companyStats && companyStats.referrals) || 0}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  expand = (text) => {
    return (
      <React.Fragment>
        <span className="text info">
          <img src={chevronUpIconSvg} className="collpaseble rotate" />
          {text}
        </span>
      </React.Fragment>
    );
  };

  collapse = (text) => {
    return (
      <React.Fragment>
        <span className="text info">
          <img src={chevronDownIconSvg} className="collpaseble" />
          {text}
        </span>
      </React.Fragment>
    );
  };

  journeyCollapse = (text, isCollpse, journeyData, index) => {
    let isActiveJourney = false;
    if (
      this.state.editJourneyData &&
      journeyData &&
      this.state.editJourneyData.s3Key === journeyData.s3Key
    ) {
      isActiveJourney = true;
    }

    let className = isActiveJourney ? 'collpaseble' : 'collpaseble rotate';
    return (
      <React.Fragment>
        <span className="text info">
          {isActiveJourney ? (
            <img src={chevronDownIconSvg} className={className} />
          ) : (
            <img src={chevronUpIconSvg} className={className} />
          )}
          <span
            className={isActiveJourney ? 'seeDetailsWrapper' : ''}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({
                companySettingState: 'addJourney',
                editJourneyData: journeyData,
                journeyIndex: index
              });
            }}
          >
            {text}
          </span>
        </span>
      </React.Fragment>
    );
  };

  addJourney = () => {
    return (
      <>
        <Tooltip overlayClassName="black-tooltip" title={'Create new Bodhi journey'}>
          <span
            onClick={() => {
              this.setState({ companySettingState: 'addJourney', editJourneyData: 'empty' });
            }}
            style={{ marginLeft: '5px', cursor: 'pointer' }}
          >
            +
          </span>
        </Tooltip>
      </>
    );
  };

  renderMultipleJourney = () => {
    return (
      <Suspense fallback={suspenseFallback}>
        <MultipleJourney
          companyId={this.companyId}
          {...this.props}
          updateState={this.updateState}
          journeyPhase={this.journeyPhase}
          editJourneyData={this.state.editJourneyData}
        />
      </Suspense>
    );
  };

  renderDesktopView() {
    return (
      <div className="sys-notification-wrapper">
        <div className="row">
          {this.admin && (
            <div className="col-sm-3 left-menu-wrapper">
              <div className="left-menu-box">
                <h2>
                  {' '}
                  <img src={settingsIconSvg} /> Company Settings{' '}
                </h2>

                <ul className="left-menu">
                  <li>
                    <a
                      onClick={() => {
                        this.setState({ companySettingState: 'isCompany' });
                      }}
                      className={this.state.companySettingState == 'isCompany' ? 'active' : ''}
                    >
                      Company Information
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        this.setState({ companySettingState: 'isPlan' });
                      }}
                      className={this.state.companySettingState == 'isPlan' ? 'active' : ''}
                    >
                      Plan Details
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        this.setState({ companySettingState: 'isIntegrations' });
                      }}
                      className={this.state.companySettingState == 'isIntegrations' ? 'active' : ''}
                    >
                      Integrations
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        this.setState({ companySettingState: 'isHardware' });
                      }}
                      className={this.state.companySettingState == 'isHardware' ? 'active' : ''}
                    >
                      Hardware
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        this.setState({ companySettingState: 'isUtilities' });
                      }}
                      className={this.state.companySettingState == 'isUtilities' ? 'active' : ''}
                    >
                      Utilities
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseDetails"
                      aria-expanded="true"
                      aria-controls="collapseDetails"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ onNextInfo: !this.state.onNextInfo });
                      }}
                    >
                      {this.state.onNextInfo ? this.collapse('Content') : this.expand('Content')}
                    </a>
                  </li>

                  <div
                    id="collapseDetails"
                    className={`collapse ${this.state.onNextInfo ? 'show' : ''}`}
                  >
                    <li className="shiftRight">
                      <a
                        onClick={() => {
                          this.setState({ companySettingState: 'isEdResource' });
                        }}
                        className={this.state.companySettingState == 'isEdResource' ? 'active' : ''}
                      >
                        Educational Resources
                      </a>
                    </li>
                    <li className="shiftRight">
                      <a
                        onClick={() => {
                          this.setState({ companySettingState: 'isSystemNotification' });
                        }}
                        className={
                          this.state.companySettingState == 'isSystemNotification' ? 'active' : ''
                        }
                      >
                        System Notifications
                      </a>
                    </li>
                    <li className="shiftRight">
                      <a
                        onClick={() => {
                          this.setState({ companySettingState: 'isReferralMessage' });
                        }}
                        className={
                          this.state.companySettingState == 'isReferralMessage' ? 'active' : ''
                        }
                      >
                        Referrals
                      </a>
                    </li>
                    <li className="shiftRight">
                      <a
                        onClick={() => {
                          this.setState({ companySettingState: 'isPhaseInfo' });
                        }}
                        className={this.state.companySettingState == 'isPhaseInfo' ? 'active' : ''}
                      >
                        More Info
                      </a>
                    </li>
                    <li className="shiftRight">
                      <a
                        onClick={() => {
                          this.setState({ companySettingState: 'isFAQ' });
                        }}
                        className={this.state.companySettingState == 'isFAQ' ? 'active' : ''}
                      >
                        Frequently Asked Questions
                      </a>
                    </li>
                  </div>
                </ul>

                <h2 className="mt2">
                  {' '}
                  <img src={settingsIconSvg} /> Bodhi Journeys {this.addJourney()}
                </h2>

                <ul className="left-menu">{this.renderJourneyPhase()}</ul>
              </div>
            </div>
          )}

          <div className={`${this.admin ? 'col-sm-8' : 'col-sm-11'}`}>
            <div className="right-content-box">
              {this.renderCompanySettingState(this.state.companySettingState)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  formatJourney = (journey) => {
    let temp = journey.map((ele) => {
      if (typeof ele === 'string') {
        return (ele = JSON.parse(ele));
      } else return ele;
    });
    return temp;
  };

  renderJourneyPhase() {
    let {
      companyData: { companyDetail }
    } = this.props;
    let journey = [];

    if (companyDetail && companyDetail.journey) {
      journey = JSON.parse(JSON.stringify(companyDetail.journey));
    }

    journey = this.formatJourney(journey);

    return (
      <>
        {journey.map((ele, index) => {
          return (
            <React.Fragment key={index}>
              <li>
                <a
                  // data-bs-toggle={"collapse"}
                  data-bs-target={'#collapseDetails' + index}
                  aria-expanded="true"
                  aria-controls={'collapseDetails'}
                  // onClick={() => this.setState({isExpand : (index==this.state.journeyIndex) ? !this.state.isExpand : true,journeyIndex:index })}
                >
                  {index == this.state.journeyIndex
                    ? this.journeyCollapse(ele.journeyName, true, ele, index)
                    : this.journeyCollapse(ele.journeyName, false, ele, index)}
                </a>
              </li>

              <div
                id={'collapseDetails' + index}
                className={`${`collapse ${index == this.state.journeyIndex ? 'show' : ''}`}`}
              >
                {Object.keys(ele.journeyPhase).map((element, i) => {
                  return (
                    <React.Fragment key={i}>
                      <li className="shiftRight">
                        <a
                          onClick={() => {
                            this.setState({
                              journeyData: ele,
                              companySettingState: this.journeyPhase(element.toLowerCase()),
                              journeyKey: ele.journeyKey
                            });
                          }}
                          className={
                            this.state.companySettingState == this.journeyPhase(element) &&
                            index == this.state.journeyIndex
                              ? 'active'
                              : ''
                          }
                        >
                          {(ele.journeyPhase &&
                            ele.journeyPhase[element] &&
                            ele.journeyPhase[element].name) ||
                            this.journeyPhase(element)}
                        </a>
                      </li>
                    </React.Fragment>
                  );
                })}
              </div>
            </React.Fragment>
          );
        })}
      </>
    );
  }

  /**
   * @todo: reference the nascent BodhiJourney object in constants
   */
  journeyPhase(phase) {
    switch (phase) {
      case 'design':
        return 'Design';
      case 'permit':
        return 'Permit';
      case 'thebuild':
        return 'The Build';
      case 'inspection':
        return 'Inspection';
      case 'poweringup':
        return 'Powering Up';
      case 'service':
        return 'Service';
    }
  }

  render() {
    if (getToken()) {
      return this.renderProfileScreen();
    } else {
      this.props.router.navigate('/');
      return null;
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    auth: state.auth,
    setCompanyDetail: state.setting.setCompanyDetail,
    installerData: state.header.getInstaller.data,
    companyData: state.setting.companyDetail.data || {},
    getInstaller: state.addhomeOwner.getInstaller,
    loader: state.loader.loader.loader,
    companyList: state.adminHeader.searchData.data,
    updateInstallerById:
      state.setting.updateInstallerById[state.setting.updateInstallerById.length - 1]
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestCompanyDetailSet,
      requestUpdateLoggedInInstallerById,
      requestCompanyDetail,
      receiveCompanyDetail,
      receiveCompanyCRMData,
      setLoader
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(CompanySettingsForm));
