import { Services } from '../../../Api/services'
import * as config from '../../../shared/config'

export const postPhaseSurvey = async data => {
  const url = `${config.SURVEY_BASE_URL}company/${
    data.companyId
    }/homeowner/${data.homeownerId}/survey`;
    const bodyData = data.phase;
	
    let res = await Services.postCall(url, bodyData, true);
  return { data: res.data, status: res.status };
};