

export const REQUEST_GETCOMPANYBYIDAPI_DATA = "REQUEST_GETCOMPANYBYIDAPI_DATA";
export const RECEIVE_GETCOMPANYBYIDAPI_DATA = "RECEIVE_GETCOMPANYBYIDAPI_DATA";

export const REQUEST_DRIVEUPLOAD_S3 ="REQUEST_DRIVEUPLOAD_S3"

export const REQUEST_EDITINSTALLERAPI_DATA = "REQUEST_EDITINSTALLERAPI_DATA";
export const RECEIVE_EDITINSTALLERAPI_DATA = "RECEIVE_EDITINSTALLERAPI_DATA";

export const REQUEST_UPLOAD_CSV = "REQUEST_UPLOAD_CSV";
export const RECEIVE_UPLOAD_CSV = "RECEIVE_UPLOAD_CSV";

export const REQUEST_EDITINSTALLERAPI_MANAGEMENT_DATA = "REQUEST_EDITINSTALLERAPI_MANAGEMENT_DATA";
export const RECEIVE_EDITINSTALLERAPI_MANAGEMENT_DATA = "RECEIVE_EDITINSTALLERAPI_MANAGEMENT_DATA";

export const requestDriveUpload = (data) =>({type:REQUEST_DRIVEUPLOAD_S3,data})
export const requestGetCompanyByIdApiData = (data) => ({ type: REQUEST_GETCOMPANYBYIDAPI_DATA, data });
export const recieveGetCompanyByIdApiData = data => ({ type: RECEIVE_GETCOMPANYBYIDAPI_DATA, data });

export const requestEditInstallerApiData = (data) => ({ type: REQUEST_EDITINSTALLERAPI_DATA, data });
export const recieveEditInstallerApiData = data => ({ type: RECEIVE_EDITINSTALLERAPI_DATA, data });

export const requestEditInstallerManagementApiData = (data,meterData) => ({ type: REQUEST_EDITINSTALLERAPI_MANAGEMENT_DATA, data, meterData });
export const recieveEditInstallerManagementApiData = data => ({ type: RECEIVE_EDITINSTALLERAPI_MANAGEMENT_DATA, data });

export const requestCSVUpload = data => ({ type: REQUEST_UPLOAD_CSV, data });
export const receiveCSVUpload = data => ({ type: RECEIVE_UPLOAD_CSV, data });
