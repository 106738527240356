// import npm packages
import { combineReducers } from "redux";

// import local files
import {
    RECEIVE_HOMEOWNER_COMPANY_DETAIL,
} from "../../types/resources/resources";

const homeownerResoucesCompanyDetail = (state = {}, { type, data }) => {
    switch (type) {
        case RECEIVE_HOMEOWNER_COMPANY_DETAIL:
            return data;
        default:
            return state;
    }
};

export const reducer = combineReducers({
    homeownerResoucesCompanyDetail
});