import { Services } from "../../../Api/services";
import * as config from "../../../shared/config";

export const getHomeownersList = async data => {
    const url = `${config.REFERRAL_BASE_URL}referral/company/${
        data.companyId
        }/homeowner/${data.homeownerId}`;

    let res = await Services.getCall(url, false);
    return { data: res.data };
};
