import { momentLocalizer, Calendar } from 'react-big-calendar';
import { findDOMNode } from 'react-dom';
import { Overlay, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import React, { Component, useState, useRef } from 'react';

import { withRouter, getToken } from '../../../../shared';
import { checkIsMobile } from '../../../../shared/checkIsMobile';
import { convertDate } from '../../../../shared/convertDate';
import MobileEnergyTips from './mobileEnergyTips';
import EnergyFlow from '../shared/energyFlow';
import { getUpdateHomeOwner } from '../../api/homeOwner';
import { requestLoggedInHomeownerAction } from '../../../homeowner/actions/homeOwner';
import { fetchs3File } from '../../api/fetchs3File';
import Heading from '../shared/Header';
import CalloutBox from '../shared/CalloutBox';

let mobileWidth = document.body.offsetWidth;
let mobile2 = 767;

const localizer = momentLocalizer(moment);

class EnergyTips extends Component {
  constructor(props) {
    super(props);
    this.monthHistory = {};
    this.events = [];
    this.historyMonth = moment().format('YYYYMM');
    this.state = {
      peakData: {},
      date: null,
      offset: null,
      centerMode: false,
      contentVisibility: 'visible'
    };
    this.gettingEnphaseList = false;
    this.enphaseList = [];
  }

  Event = (event) => {
    const [showTooltip, setShowTooltip] = useState(false);
    let { energyReport } = this.props;
    const closeTooltip = () => {
      setShowTooltip(false);
    };

    const openTooltip = () => {
      setShowTooltip(true);
    };
    const ref = useRef(null);

    const getTarget = () => {
      return findDOMNode(ref.current);
    };

    if (ref.current && ref.current.textContent && !this.monthHistory[ref.current.textContent]) {
      return <></>;
    } else
      return (
        <div ref={ref}>
          <span onMouseOver={openTooltip} onMouseLeave={closeTooltip}>
            {event.title}
          </span>
          <Overlay
            rootClose
            target={getTarget}
            show={showTooltip}
            placement="top"
            onHide={closeTooltip}>
            <Tooltip className="energyToolTip">
              <div>
                <strong>Produced: </strong>
                {(event.event.production / energyReport.interval).toFixed(1)} kWh
              </div>
              {this.isConsumption && (
                <div>
                  <strong>Consumed: </strong>
                  {(event.event.consumption / energyReport.consumptionInterval).toFixed(1)} kWh
                </div>
              )}
            </Tooltip>
          </Overlay>
        </div>
      );
  };

  getCustomToolbar = (toolbar) => {
    this.toolbarDate = toolbar.date;

    const goToBack = () => {
      let mDate = toolbar.date;
      let newDate = moment(mDate).subtract(1, 'month').endOf('month').toDate();
      toolbar.onNavigate('prev', newDate);
    };

    const goToNext = () => {
      let mDate = toolbar.date;
      let newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
      toolbar.onNavigate('next', newDate);
    };

    const monthName = this.state.date
      ? moment(this.state.date).format('MMMM YYYY')
      : moment(new Date()).format('MMMM YYYY');

    return (
      <div className="toolbar-container">
        <div className="navigation-buttons premiumLinks">
          <i
            className="navigation fa fa-chevron-left fa-lg"
            onClick={goToBack}
            aria-hidden="true"></i>
          <label className="label-date">{monthName}</label>
          <i
            className="navigation fa fa-chevron-right fa-lg"
            onClick={goToNext}
            aria-hidden="true"></i>
        </div>
      </div>
    );
  };

  setDate(date) {
    this.setState({
      date
    });
  }

  componentDidMount() {
    if (mobileWidth <= mobile2 && !this.state.centerMode) {
      this.setState({
        centerMode: true
      });
    } else if (this.state.centerMode) {
      this.setState({
        centerMode: false
      });
    }
    // if (!this.gettingEnphaseList) {
    //   this.gettingEnphaseList = true;
    //   fetchs3File('https://prod-17terawatts.s3.amazonaws.com/public/enphaseList.json').then(
    //     (s3Result) => {
    //       this.enphaseList = s3Result.data;
    //     }
    //   );
    // }
  }

  componentWillReceiveProps(nextProps) {
    this.slug = localStorage.getItem('slug');
    if (
      nextProps.loggedInUser &&
      nextProps.loggedInUser.solarUnit &&
      nextProps.loggedInUser.solarUnit.phase == 6 &&
      !nextProps.loggedInUser.solarUnit.transitionStatus &&
      !nextProps.isInstaller
    ) {
      let param = {
        companyId: nextProps.loggedInUser.homeownerData.companyId,
        homeownerId: nextProps.loggedInUser.homeownerData.id
      };
      this.props.requestLoggedInHomeownerAction(param);
      getUpdateHomeOwner(param);
      nextProps.requestUpdateHomeOwner(param);
      localStorage.setItem('ismonitoringslides', true);
      nextProps.router.navigate(`/${localStorage.getItem('slug')}/resources/5`);
    }
  }

  DateCell = ({ range, value, children }) => {
    let { energyReport } = this.props;
    let custom_class_name = '';
    let activeDate = '';
    if (moment(this.state.date).format('YYYYMMDD') === moment(value).format('YYYYMMDD')) {
      activeDate = 'premiumLinks bgColor';
    }

    if (this.monthHistory[moment(value).format('YYYY-MM-DD')]) {
      let t = moment(value).format('YYYY-MM-DD');
      let temp = this.isConsumption
        ? this.monthHistory[t]['totalConsumption'] / energyReport.consumptionInterval
        : this.maxProd / energyReport.interval;
      let prod = this.monthHistory[t]['totalProduction'] / energyReport.interval;
      custom_class_name = `${activeDate} stylecalender _${parseInt((prod / temp) * 10)}`;
    }

    return <div className={'date-in-past ' + custom_class_name}>{children}</div>;
  };

  calcMaxPeakMobile = (data) => {
    if (data && this.state.peakData.peakTime !== data.peakTime) {
      this.setState({ peakData: data });
    }
  };

  renderDashboard = () => {
    let { loggedInUser, energyReport } = this.props;
    let data = energyReport && energyReport.monthlyHistory;
    this.isConsumption = true;
    if (!energyReport.totalConsumption) {
      this.isConsumption = false;
    }
    if (data && data.length > 0 && JSON.stringify(this.monthHistory) === '{}') {
      this.maxProd = 1;
      data.forEach((ele, index) => {
        if (ele.date !== moment().format('YYYY-MM-DD')) {
          this.events.push({
            id: index,
            title: ele.date,
            allDay: true,
            start: moment(ele.date).toDate(),
            end: moment(ele.date).toDate(),
            production: ele.totalProduction,
            consumption: ele.totalConsumption
          });
          this.monthHistory[ele.date] = {};
          if (ele.totalProduction > this.maxProd && !this.isConsumption)
            this.maxProd = ele.totalProduction;
          Object.assign(this.monthHistory[ele.date], ele);
        }
      });
    }

    let { peakData } = this.state;
    let titleValue =
      loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.companyName;

    return (
      <>
        <title>My Dashboard - {titleValue}</title>
        <div className="next-level-wrapp w-100">
          {this.props.meetsPromoCriteria === true && (
            <>
              <div
                className="white-box-secondary desktop-view-only margb15 spacingMobile"
                style={{ padding: '20px' }}>
                <Heading tag="h4" className="secondary-content pl20 pr20 pt5">
                  Carbon cleanse
                </Heading>
                <CalloutBox className="content-callout-static" contentClassName="test-classname">
                  <div style={{ fontFamily: 'HarmoniaSansProCyrRegular', marginBottom: '20px' }}>
                    Fill glass jars full of items from the bulk section to add color to your kitchen
                  </div>
                  <div style={{ fontFamily: 'HarmoniaSansProCyrLight' }}>
                    <span>
                      Most single-use plastic comes from packaging found in the kitchen. Plastic
                      production to carbon dioxide released is around a 1:5 ratio, so a reduction in
                      their use has an amplified impact. Regrettably, only 9% of plastic is
                      recycled.{' '}
                      <a
                        href="https://psci.princeton.edu/tips/2020/3/30/single-use-plastic-amp-alternatives"
                        target="_blank">
                        Learn more about alternatives to single use packaging
                      </a>
                      .
                    </span>
                  </div>
                </CalloutBox>
              </div>
            </>
          )}
          <div className="mobile-view">
            {this.props.meetsPromoCriteria !== true && checkIsMobile() && (
              <MobileEnergyTips
                homeOwnerId={this.props.homeOwnerId}
                receiveHomeownerEnergyData={this.props.receiveHomeownerEnergyData}
                requestHomeownerEnergyData={this.props.requestHomeownerEnergyData}
                setLoader={this.props.setLoader}
                peakData={peakData}
                energyReport={this.props.energyReport}
                loggedInUser={this.props.loggedInUser}
                monthHistory={this.monthHistory}
                maxProd={this.maxProd}
                setDate={(date) => {
                  this.setDate(date);
                }}
              />
            )}
            {this.props.meetsPromoCriteria !== true && (
              <div className="white-box-secondary margb30 border0 padd0">
                <EnergyFlow
                  energyReport={this.props.energyReport}
                  loggedInUser={this.props.loggedInUser}
                  isInstaller={this.props.isInstaller}
                  systemGlance={this.props.systemGlance}
                  monthHistory={this.monthHistory}
                  maxProd={this.maxProd}
                  calcMaxPeakMobile={this.calcMaxPeakMobile}
                  setDate={(date) => {
                    this.setDate(date);
                  }}
                  {...this.props}
                />
              </div>
            )}
          </div>
          {this.props.meetsPromoCriteria !== true && (
            <div className="white-box-secondary margb35 desktop-view">
              <div className="heading" style={{ fontWeight: '400', marginTop: '13px' }}>
                USAGE HISTORY
              </div>
              <div className="calendar-box">
                <Calendar
                  components={{
                    dateCellWrapper: this.DateCell,
                    event: this.Event,
                    toolbar: this.getCustomToolbar
                  }}
                  events={this.events}
                  startAccessor="start"
                  endAccessor="end"
                  defaultDate={moment().toDate()}
                  localizer={localizer}
                  maxDate={new Date()}
                  onSelectEvent={(date) => {
                    this.getDateEnergyReport(date.start);
                  }}
                  onNavigate={(date) => {
                    if (date <= new Date()) this.getDateEnergyReport(date);
                  }}
                  views={['month']}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  getDateEnergyReport = (date) => {
    this.setState({
      date
    });
    let requestMonthHistory = false;
    let requestMonth = moment(date).format('YYYYMM');
    if (this.historyMonth != requestMonth) {
      this.historyMonth = requestMonth;
      this.monthHistory = {};
      requestMonthHistory = true;
    }
    this.props.receiveHomeownerEnergyData({ data: { monthlyHistory: [] } });
    this.props.setLoader({ loader: true });
    this.props.requestHomeownerEnergyData({
      sync: false,
      date: convertDate(date),
      homeownerId: this.props.homeOwnerId,
      offset: this.state.offset,
      requestMonthHistory
    });
    this.setEnergyReportDate(date);
  };

  setEnergyReportDate(date) {
    const d = date instanceof Date ? date : date.start;
    localStorage.setItem('energyReportDate', d.toDateString());
  }

  render() {
    if (getToken()) {
      return this.renderDashboard();
    } else {
      this.props.router.navigate('/');
      return null;
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    loggedInUser: state.homeownerLoggedInUser.homeownerLoggedInUserReducer.data || {}
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestLoggedInHomeownerAction
    },
    dispatch
  );

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(EnergyTips));
