import React, { Suspense } from 'react';
import moment from 'moment';

import { suspenseFallback } from '../../../../constants/components';

const PromoTakeover = React.lazy(() => import('./PromoTakeover'));
const Heading = React.lazy(() => import('./Header'));
const CalloutBox = React.lazy(() => import('./CalloutBox'));
import noMonitoringSvg from '../../../../Assets/Images/noMonitoring_Sun.svg';
import { generateTimeBasedGreeting } from '../../../../shared/generateGreetings';

const EnphaseDeprecationPromo: React.FC = (props) => {
  const { stateSetter, contentVisibility, loggedInUser, displayInContainer } = props;
  const siteID = loggedInUser && loggedInUser.solarUnit && loggedInUser.solarUnit.meterconId;

  if (!loggedInUser || !loggedInUser.homeownerData || !siteID) {
    return null;
  }

  return (
    <Suspense fallback={suspenseFallback}>
      <PromoTakeover
        backgroundImage={noMonitoringSvg}
        fullscreen={false}
        displayInContainer={displayInContainer}
        variant="single-cta"
      >
        <Heading tag="h5">
          {`${generateTimeBasedGreeting()}, ${loggedInUser.homeownerData.firstName}!`}
        </Heading>
        <Heading tag="h3" className="day-of-week">
          {moment().format('MMMM Do')}
        </Heading>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              height: '30px'
            }}
          />
          <Heading tag="h4" className="mb10">
            Explore your production in
          </Heading>
          <a
            style={{
              color: '#ff6f47',
              padding: '20px 107px 19px 20px',
              fontSize: '50px',
              lineHeight: '61px',
              position: 'relative',
              borderRadius: '10px',
              border: '1px solid #eee',
              backgroundColor: '#fff',
              display: 'block',
              boxShadow: '0 2px 4px 0 rgba(176,186,197,0.5)'
            }}
            href={`https://enlighten.enphaseenergy.com/pv/systems/${siteID}/overview`}
            target="_blank"
          >
            Enphase
            <span
              style={{
                fontSize: '20px',
                color: '#ffd110',
                position: 'absolute',
                right: '23px',
                top: '0px',
                bottom: '0px',
                margin: 'auto',
                height: '50%'
              }}
            >
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </span>
          </a>
        </div>
        {props.showCallout === true && (
          <>
            <div
              style={{
                height: '30px'
              }}
            />
            <Heading tag="h4" className="secondary-content">
              Carbon cleanse
            </Heading>
            <CalloutBox
              className="content-callout"
              eventHandler={(event) => {
                stateSetter({
                  displayCalloutContent: contentVisibility
                });
              }}
              contentClassName={contentVisibility}
            >
              <div style={{ marginBottom: '20px' }}>
                Fill glass jars full of items from the bulk section to add color to your kitchen
              </div>
              <div>
                <span>
                  Most single-use plastic comes from packaging found in the kitchen. Plastic
                  production to carbon dioxide released is around a 1:5 ratio, so a reduction in
                  their use has an amplified impact. Regrettably, only 9% of plastic is recycled.{' '}
                  <a
                    href="https://psci.princeton.edu/tips/2020/3/30/single-use-plastic-amp-alternatives"
                    target="_blank"
                  >
                    Learn more about alternatives to single use packaging
                  </a>
                  .
                </span>
              </div>
            </CalloutBox>
          </>
        )}
      </PromoTakeover>
    </Suspense>
  );
};

export default EnphaseDeprecationPromo;
