
import { Form, Input, Modal, Select } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { addIconSvg, signatureIconLgSvg } from '../../../constants/icons';
import { openNotificationWithIcon } from '../../../shared/notification';
import { createEversignDocument } from '../../homeowner/api/eversign';
import { IEversign, ISignerDetail } from './types';

export const EversignIntegration: React.FC<IEversign> = (props) => {

    const requestSignature = () => {
        let homeownerIndex = 0;
        let signers: ISignerDetail[] = [];
        let signature: any = [];
        let recipients: ISignerDetail[] = [];

        if (!signersList.length) {
            openNotificationWithIcon('error', 'Please fill the user data', 'Error');
        }

        const installerId = installerDetail.id;
        const newEversignEmailMessage: string = eversignEmailMessage || `Please review and sign this important document ${documentName || ''} concerning your solar system.`;

        signersList.map((signer: any) => {
            const inputEmail = signer.email.toLowerCase().trim();
            const inputName = signer.name.trim();
            const inputRole = signer.role;

            signers.push({
                name: inputName,
                role: inputRole,
                email: inputEmail
            });

            if (inputRole === 1) {
                if (inputEmail === homeownerData.email) {
                    homeownerIndex = signature.length;
                }
                signature.push({
                    id: signature.length + 1,
                    name: inputName,
                    email: inputEmail,
                    order: signature.length + 1,
                    message: eversignEmailMessage,
                    deliver_email: inputEmail !== homeownerData.email ? 1 : 0
                });
            } else {
                recipients.push({
                    name: inputName,
                    email: inputEmail,
                    role: null
                });
            }
        });

        if (!signature.length) {
            openNotificationWithIcon('error', 'Please add atleast one signer.', 'Error');
            return;
        }

        if (!installerId) {
            openNotificationWithIcon('error', 'You are not authorised to make a request.', 'Error');
            return;
        }

        const eversignPayload = {
            companyId: homeownerData.companyId,
            signer: signers,
            name: documentName,
            url: documentURL,
            homeownerId: homeownerId,
            homeownerName: `${homeownerData.firstName} ${homeownerData.lastName}`,
            homeownerEmail: homeownerData.email,
            installerId,
            createdAt: moment().unix(),
            baseUrl: `${window.location.origin}/${slug}`,
            recipients,
            homeownerIndex,
            signature,
            // token: null, // need to fetch token 
            eversignEmailMessage:newEversignEmailMessage
        };
        
        setEversignModel({eversignRequestPopUp:false});
        setDisplayFullScreenLoaderState(true);
        createEversignDocument(eversignPayload).then(embedded_link=>{
            setDisplayFullScreenLoaderState(false);
            setEversignModel({eversignRequestPopUp:false,prepareDocumentPopUp:true,embedded_claim_url:embedded_link});
        }).catch(err=>{
            setDisplayFullScreenLoaderState(false);
            openNotificationWithIcon('errror',err.message,'Error in making request');
        })
    }


    const { installerDetail, homeownerData:{homeownerData}, documentName, setEversignModel,documentURL,homeownerId,slug,setDisplayFullScreenLoaderState} = props;
    const [form] = Form.useForm();
    const [eversignPopUp, setEversignPopUp] = useState(true);
    let initialSigner = [
        {
          name: homeownerData.firstName + ' ' + homeownerData.lastName,
          email: homeownerData.email,
          role: 1
        },
        {
          name: homeownerData.installerName,
          email: homeownerData.installerEmail,
          role: 2
        }
      ];

    if (installerDetail && installerDetail.email !== homeownerData.installerEmail) {
        initialSigner.push({
            name: installerDetail.name,
            email: installerDetail.email,
            role: 2
        });
    }
    const [signersList, setSignersList] = useState(initialSigner);
    const [eversignEmailMessage, setEversignEmailMessage] = useState('');


    return (
        <Modal
            open={eversignPopUp}
            className="add-utility popUp"
            onCancel={() => {
                setEversignPopUp(false);
                setEversignModel({eversignRequestPopUp:false})
            }}
            width={700}
            footer={null}>
            <div className="modal-header cmn-modal-header">
                <h4 className="modal-title cmn-modal-title">
                    <img src={signatureIconLgSvg} alt="" className="modal-title-logo eversign" />
                    Request signatures
                </h4>
            </div>
            <Form onFinish={requestSignature} form={form} id="myForm" className="modal-body cmn-modal-body">
                <div className="col-sm-12 form-group">
                    <div className="row v-h-center detail-info-box mt-2">
                        <p className="sign-text">
                            Email a request to the homeowner and any additional signers to sign{' '}
                            <b>{`${documentName || ''}`}</b>
                        </p>
                        <Form.List name="keys">
                            {(fields, { add, remove }) => {
                                return (
                                    <>
                                        {[...signersList].map((listFieldItem, index) => {
                                            return (
                                                <Form.Item
                                                    hasFeedback
                                                    key={index}
                                                    style={{ width: '100%', marginTop: '13px' }}
                                                    className="signatory-wrapper">
                                                   

                                                    <Form.Item
                                                        className="signatory-name"
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true
                                                            }
                                                        ]}
                                                        noStyle>
                                                        <Input
                                                            name={`name-${index}`}
                                                            onChange={(e) => {
                                                                const newArr = [...signersList];
                                                                newArr[index].name = e.target.value;
                                                                setSignersList(newArr);
                                                            }}
                                                            value={listFieldItem.name || ''}
                                                            className="col-md-3"
                                                            placeholder={`Name`}
                                                            style={{ marginRight: '20px' }}
                                                            disabled={
                                                                homeownerData.email ==
                                                                    listFieldItem.email
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        className="signatory-email"
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true
                                                            }
                                                        ]}
                                                        noStyle>
                                                        <Input
                                                            name={`email-${index}`}
                                                            value={listFieldItem.email || ''}
                                                            onChange={(e) => {
                                                                const newArr = [...signersList];
                                                                newArr[index].email = e.target.value;
                                                                setSignersList(newArr);
                                                            }}
                                                            className="col-md-4"
                                                            placeholder={`Email`}
                                                            disabled={
                                                                homeownerData.email ==
                                                                    listFieldItem.email
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        className="signatory-role"
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true
                                                            }
                                                        ]}
                                                        noStyle>
                                                        <Select
                                                            className="col-md-3"
                                                            value={listFieldItem.role}
                                                            style={{ width: '100%' }}
                                                            placeholder="Role"
                                                            onChange={(e) => {
                                                                const newArr = [...signersList];
                                                                newArr[index].role = e;
                                                                setSignersList(newArr);
                                                              }}
                                                            disabled={
                                                                homeownerData.email ==
                                                                    listFieldItem.email
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            <Select.Option value={1} key={1}>
                                                                Signer
                                                            </Select.Option>
                                                            <Select.Option value={2} key={2}>
                                                                CC
                                                            </Select.Option>
                                                        </Select>
                                                    </Form.Item>

                                                    {homeownerData.email !==
                                                        listFieldItem.email ? (
                                                        <div
                                                            className="plus-add-unity"
                                                            onClick={() => {
                                                                const newArr = [...signersList];
                                                                newArr.splice(index, 1);
                                                                setSignersList(newArr);
                                                            }}>
                                                            -
                                                        </div>
                                                    ) : (
                                                        <div className="plus-add-unity hide">-</div>
                                                    )}
                                                </Form.Item>
                                            );
                                        })}
                                        <div
                                            className="add-signer"
                                            onClick={() => {
                                                const newArr = [...signersList];
                                                newArr.push({
                                                    name: '',
                                                    email: '',
                                                    role: 2
                                                });
                                                setSignersList(newArr);
                                            }}
                                        >
                                            <img src={addIconSvg} className="plus" />
                                            <span className="text link-Color">Add Signers and CCs</span>
                                        </div>
                                    </>
                                )
                            }}
                        </Form.List>

                        <div className="col-12">
                            <Input.TextArea
                                rows={4}
                                className="p-2 mt-3 form-control"
                                placeholder="Include custom message (optional)"
                                onChange={(e) => {
                                    setEversignEmailMessage(e.target.value);
                                }}
                            />
                        </div>

                        <div className="form-submitted-btn">
                            <button
                                className="btn btn-secondary"
                                onClick={() => setEversignModel({eversignRequestPopUp:false})}
                                type="button">
                                Cancel
                            </button>

                            <button className="btn btn-primary">Prepare document</button>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
}
