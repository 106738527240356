import React from 'react';

import { replaceStatusFeedText } from '../../container/statusFeed/replaceText';
import { IPhaseMessageContent } from './types';

/**
 * Render Phase Content for a Homeowner
 *
 * @params {Object} props
 * @return React FC
 */
export const PhaseMessageContent: React.FC<IPhaseMessageContent> = (props) => {
  const { content, customTouchData, touchData, systemGlance, loggedInUser, urlParams } = props;

  if (typeof touchData === 'object') {
    if (
      touchData &&
      (touchData.touchName == '1 week impact and survey' ||
        touchData.touchName == '1st Month Impact' ||
        touchData.touchName == '1 Year Impact')
    ) {
      return null;
    }
  }

  if (!loggedInUser?.homeownerData) {
    return null;
  }

  let text = typeof content === 'string' ? content : content.content1;

  text = urlify(text);

  return text.split('\n').map(function (item: string, idx: number) {
    if (item.includes('<a')) {
      item = item.split('<a').join('<a className="premiumLinks" target="_blank"');
    }
    return (
      <React.Fragment key={`phase-el-${idx}`}>
        <span key={idx} dangerouslySetInnerHTML={{ __html: `<p style="margin:0">${item}</p>` }} />
        <br />
      </React.Fragment>
    );
  });
};

const urlify =(text:string) =>{
  var urlRegex = /(?:https?|ftp):\/\/[^\s/$.?#].[^\s"]*(?=\s|$)(?![^<]*>|[^<>]*<\/a>)/g;
  return text.replace(urlRegex, function(url) {
    return `<a className="premiumLinks" target="_blank" href="${url}">` + url + '</a>';
  })
}

export default PhaseMessageContent;
