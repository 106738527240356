import { fetchs3File } from '../../homeowner/api/fetchs3File';
import * as config from '../../../shared/config';
import { addHomeScreenIcon } from '../../Common/manifest';
import { HOMEOWNER_CUSTOM_CSS_ID } from '../../../constants';

export function getBgUrl(el) {
  var bg = '';
  if (!el) return;

  if (el.currentStyle) {
    bg = el.currentStyle.backgroundImage;
  } else if (document.defaultView && document.defaultView.getComputedStyle) {
    bg = document.defaultView.getComputedStyle(el, '').backgroundImage;
  } else {
    bg = el.style.backgroundImage;
  }
  return bg.replace(/url\(['"]?(.*?)['"]?\)/i, '$1');
}

export function getLoadingIcon(slug) {
  if (slug) return `${config.S3_17TW_BUCKET}/logo/${slug}_emailIcon.png`;
}

export function envImageUrl(slug) {
  if (slug) {
    let path = `${config.S3_17TW_BUCKET}/logo/${slug}_loginImage.jpg`;
    return fetchs3File(path)
      .then((r) => {
        return path;
      })
      .catch((error) => {
        return 'https://prod-17terawatts.s3.amazonaws.com/public/logo/desktopLogin_BG.jpg';
      });
  }
}

export async function envAppIcon(slug) {
  if (slug) {
    let path = `${config.S3_17TW_BUCKET}/logo/${slug}_homeIcon.jpg`;
    return fetchs3File(path)
      .then((r) => {
        return path;
      })
      .catch((error) => {
        return `https://prod-17terawatts.s3.amazonaws.com/public/logo/favicon.ico`;
      });
  } else return `https://prod-17terawatts.s3.amazonaws.com/public/logo/favicon.ico`;
}

export function checkImageValid(id) {
  return new Promise((res, rej) => {
    var image = document.createElement('img');
    image.src = getBgUrl(document.getElementById(id));
    image.onerror = () => {
      return res(true);
    };
    image.onload = () => {
      return res(false);
    };
  });
}

/**
 * Retrieve the CSS from AWS S3 and hand off the data for conversion and insertion as CSS in the DOM
 *
 * @param {String} slug
 * @param role
 * @returns void
 */
export function convertJSON2CSS(slug, role,emailAddress) {
  slug = slug ? slug : 'default';
  return fetchs3File(`${config.S3_17TW_BUCKET}/premium/${slug}.json`)
    .then((r) => {
      if(r?.data?.isMigrated == true){
        if(emailAddress){
          window.location.replace(`https://${slug}/migrated?email=${encodeURIComponent(emailAddress)}`);
        } else {
          window.location.replace(`https://${slug}.bodhi.solar/login`);
        }
      }
      convertDataAndInjectCSS(r.data && r.data.premiumData);
      !role && addFontFamily(r.data && r.data.font);
      addHomeScreenIcon(r.data && r.data.homeUrl, r.data && r.data.companyName);
      return r.data && r.data.companyName;
    })
    .catch((error) => {
      fetchs3File(`${config.S3_17TW_BUCKET}/premium/premium_default.json`).then((r) => {
        convertDataAndInjectCSS(r.data);
        !role && addFontFamily();
        return 'Bodhi';
      });
    });
}

function addFontFamily(font) {
  document.body.classList.add(font || 'harmonia');
}

function convertDataAndInjectCSS(data) {
  var rules = [];
  for (var i in data) {
    var rule = `${i}{`;
    for (var j in data[i]) {
      rule += `${j}:${data[i][j]};`;
    }
    rule += `}`;
    rules.push(rule);
  }

  var injectedStyles = document.createElement('style');
  injectedStyles.setAttribute('type', 'text/css');
  injectedStyles.setAttribute('id', HOMEOWNER_CUSTOM_CSS_ID);
  injectedStyles.innerHTML = rules.join('');

  document.head.appendChild(injectedStyles);
}
