import { combineReducers } from 'redux';

import {RECEIVE_COMPANY_JOURNEY,
  RECEIVE_COMPANY_CRMDATA,
  UPDATE_COMPANY_JOURNEY,
  UPDATE_COMPANY_EDRESOURCE,
  RECEIVE_COMPANY_EDRESOURCE,
  RECEIVE_COMPANY_PLAN
} from "../../actions/companyJourney";

const bodhiJourneyReducer = (state = {}, { type, data }) => {
    switch (type) {
      case RECEIVE_COMPANY_JOURNEY:
        return data;
      default:
        return state;
    }
};

const companyPlanReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_COMPANY_PLAN:
      return data;
    default:
      return state;
  }
};

const companyEdResourceReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_COMPANY_EDRESOURCE:
      return data;
    default:
      return state;
  }
};

const bodhiPlanDetailReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_COMPANY_PLAN:
      return data;
    default:
      return state;
  }
};

const companyCRMDataReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_COMPANY_CRMDATA:
      return data;
    default:
      return state;
  }
};

const updateBodhiJourneyReducer = (state = {}, { type, data }) => {
  switch (type) {
    case UPDATE_COMPANY_JOURNEY:
      return data;
    default:
      return state;
  }
};

const updateBodhiEdResourceReducer = (state = {}, { type, data }) => {
  switch (type) {
    case UPDATE_COMPANY_EDRESOURCE:
      return data;
    default:
      return state;
  }
};

export const reducer = combineReducers({
    bodhiJourneyReducer,
    companyCRMDataReducer,
    updateBodhiJourneyReducer,
    updateBodhiEdResourceReducer,
    companyPlanReducer,
    companyEdResourceReducer,
    bodhiPlanDetailReducer
})