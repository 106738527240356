import React from 'react';

interface IVideoPreview {
  url: string;
}

/**
 * Render a preview of video media
 *
 * @params {Object} props
 * @return React FC
 */
export const VideoPreview: React.FC<IVideoPreview> = ({ url }) => {
  return (
    <video className="hut-img" controls data-testid="video-preview">
      <source src={url} type="video/mp4" />
    </video>
  );
};

export default VideoPreview;
