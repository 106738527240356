import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reducer } from 'aws-cognito-redux-saga';

import FooterComponent from './footer';
import { requestSearchData } from '../../../../screens/Installer/actions/header';
import { requestSlugData } from '../../../User/data/action';

const mapStatetoProps = (state) => {
  return {
    auth: state.auth,
    searchData: state.header.searchData,
    installerData: state.header.getInstaller,
    companyName: state.user.slugImage
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { requestSearchData, signOut: () => dispatch(reducer.signOut()), requestSlugData },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(FooterComponent);
