export const parseDateTime= (dt) => {
    let date = false;
    if (dt) {
        let c_date = new Date(dt);
        let hrs = c_date.getHours();
        let min = c_date.getMinutes();
        if (isNaN(hrs) || isNaN(min) || c_date == "Invalid Date") {
            return null;
        }
        let type = (hrs <= 12) ? "AM" : "PM";
        date = `${hrs<=12 ?
            hrs<10 ?
                `0${hrs}`
            :
                hrs
        :
            hrs-12<10 ?
                `0${hrs-12}`
            :
                hrs-12}:${min<10 ?
                `0${min}`
            :
                min} ${type}`;
    }
    return date;
}
