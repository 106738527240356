import { Services }     from '../Api/services';
import * as config      from "../shared/config";
import {documentFilter} from "../shared/DocumentFilter"
export default class SharePointSync {

    constructor() {
        this.result = {};
        this.flag = {};
        this.slug = localStorage.getItem("slug");
    }

    getFiles = (folder, filter, parentFolder,token,isExpand,parentId,tempFolder,cb) => {
        let processFolder = [];
        if (folder && folder.length) {
            folder.forEach((file) => {
                if (file.previewUrl) {
                    let parentFolderId = file['ServerRelativeUrl'].replace('/' + file.name, '');
                    if (!this.result[parentFolderId]) {
                        this.result[parentFolderId] = []
                    }
                    const index = this.result[parentFolderId].findIndex(val => val.id === file.id);
                    if (index === -1 || this.result[parentFolderId].length === 0) {

                        Object.keys(this.flag).forEach(ele => {
                            if (file.name && file.name.toLowerCase().replace(/ /g, '').includes(ele)) {
                                this.flag[ele] = true;
                            }
                        });

                        this.result[parentFolderId].push({
                            name: file.name,
                            id: file.id,
                            url: file.id,
                            previewUrl: file.id,
                            updateFilePermissionUrl : file.ServerRelativeUrl
                        });
                    }
                }
                else if (file['odata.type'] === "SP.Folder" && documentFilter(filter,file.Name)) {

                    processFolder.push(file)
                    if (!this.result[file['ServerRelativeUrl']])
                        this.result[file['ServerRelativeUrl']] = [file.Name];
                }
            });

            let processParentFolder = parentFolder.concat(processFolder)
            return this.listFiles(null, filter, processParentFolder,token,isExpand,parentId,tempFolder,cb);
        } else {
            if (parentFolder && parentFolder.length > 0) {
                return this.listFiles(null, filter, parentFolder,token,isExpand,parentId,tempFolder,cb);
            }
            else {
                if(cb && tempFolder.length>0){
                    tempFolder && tempFolder.forEach(async (ele, index) => {
                        if (ele.key == parentId) {
                            tempFolder.splice(index, 1)
                        }
                    });
                    if(tempFolder.length>0){
                        return this.listFiles(tempFolder[0]['key'].replace(/#/g, '/'), filter, null, token, false, tempFolder[0]['key'], tempFolder, cb);
                    }
                    else {
                        cb(this.result);
                    }
                } else {
                return this.responseFile(this.result, this.flag);
                }
            }
        }
    }


    responseFile(result, flag) {
        let missingFile = JSON.parse(JSON.stringify(flag));
        let isSharedDrive = false;
        for (var i in missingFile) {

            if (flag[i]) {
                delete missingFile[i];
            }
            if (i === 'layout' && flag[i]) {
                delete missingFile['siteplan'];
            }
            if (i === 'siteplan' && flag[i]) {
                delete missingFile['layout'];
            }
            if (i === 'contract' && flag[i]) {
                delete missingFile['agreement'];
                delete missingFile['proposal'];
            }
            if (i === 'agreement' && flag[i]) {
                delete missingFile['contract'];
                delete missingFile['proposal'];
            }
            if (i === 'proposal' && flag[i]) {
                delete missingFile['contract'];
                delete missingFile['agreement'];
            }
        }

        for (var j in result) {
            if (result[j].length < 2 && (typeof (result[j][0]) != "object")) {
                delete result[j];
            } else if (!isSharedDrive) {
                let temp = (result[j][1] && result[j][1].driveId) || (result[j][0] && result[j][0].driveId);
                if (temp) {
                    isSharedDrive = true;
                }
            }
        }
        let r = {
            fileList: result,
            missingFile: Object.keys(missingFile),
            isSharedDrive : true
        }
        return r;
    }

    adminFolderList = async(folders,token,slug,callback) => {
        var filter;
        this.slug = slug;
        
        let tempFolder = JSON.parse(JSON.stringify(folders));
        let task = [];
        this.syncResult = {};
        this.result = {};
        
        if (this.slug === 'ivee') {
            await this.adminIveeSync(tempFolder, token, {}, callback);
        } else {
            this.listFiles(folders[0].key.replace(/#/g, '/'), filter, null, token, true, folders[0].key, tempFolder, (r) => {
                for (var j in r) {
                    task.push(this.adminResponseFile(r[j], filter, j, token,folders[0].key));
                }
                return Promise.all(task).then(resp => {
                    callback(this.syncResult);
                })
            })
        }  
    }

    adminIveeSync = async (folders, token, syncResult,callback) => {
        this.iveeSolarFileList(token, folders[0]['key']).then(async (temp) => {
            let publicFile = await this.makeFolderPublic({ [folders[0]['key']]: temp }, token);
            syncResult[folders[0]['key']] = { [folders[0]['key']]: publicFile };
            folders.splice(0, 1);
            if (folders.length > 0) {
                this.adminIveeSync(folders, token, syncResult, callback);
            } else {
                callback(syncResult);
            }
        });
    }

    adminResponseFile = (result,filter,parentId,token,sharepointKey) => {
        return new Promise(async(res,rej) => {  
            var key = '';
            if (result && result[0] && !result[0]['id']) {
                key = parentId.replace('/' + result[0], '');
            } else
                key = parentId;
           let response = await this.makeFolderPublic({ [parentId]: result }, token);
           response = response.filter((ele) => {
                return ele != undefined;
            });
            this.syncResult[sharepointKey] = { [sharepointKey]: response };
            return res(this.syncResult);            
        })
    }

    async listFiles(folderId, filter, parentFolder,token,isExpand,parentId,tempFolder,cb) {
        if(folderId && folderId.includes('sharepoint_')){
            return {
                fileList: {[folderId]:await this.iveeSolarFileList(token,folderId)},
                isSharedDrive : true
            };
        }
        if (folderId) {
            this.flag = {
                contract: false,
                agreement: false,
                proposal: false
            }
        }
        if(cb && !folderId && (parentFolder && parentFolder.length == 0)){
            tempFolder.forEach(async (ele, index) => {
                if (ele.key == parentId) {
                    tempFolder.splice(index, 1)
                }
            })
        }
        if (folderId || (parentFolder && parentFolder.length > 0)) {
            if (!parentFolder) {
                parentFolder = [];
            } else {
                folderId = parentFolder[0]['odata.id'];
                parentFolder.splice(0, 1);
            }
            return this.sharePointFolderList(token,folderId, 'file',isExpand).then((response) => {
              return this.getFiles(response, filter, parentFolder,token,isExpand,parentId,tempFolder,cb)
            });
        }
        else if(!cb){
            return this.responseFile(this.result, this.flag);
        }
        else if(cb && !tempFolder[0]){
            cb(this.result);
        } else if(cb){
            this.listFiles(tempFolder[0]['key'].replace(/#/g,'/'), filter,null,token,false,tempFolder[0]['key'],tempFolder,cb);
        }              
    }

    async fetchSharePointS3(slug){
        if(!localStorage.getItem(this.slug+'s3Sharepoint')){
            let url = `${config.S3_17TW_BUCKET}/sharepoint/${this.slug}.json`;
            let res = await Services.getCall(url, false);
            localStorage.setItem(this.slug+'s3Sharepoint',JSON.stringify(res.data));
            return res.data;
        } else{
            return  JSON.parse(localStorage.getItem(this.slug+'s3Sharepoint'));
        };
    }


    async sharePointFolderList(token,path,type,isExpand) {
       const s3 = await this.fetchSharePointS3(this.slug);

        return new Promise((res, rej) => {
            this.filesListFolder(path,token,isExpand,s3).then(response => {
                if(!response){
                    return res();
                }
                if (type == "file" && response.Files) {
                    response.Files.map((ele) => {
                        ele.name = ele.Name;
                        ele.id = ele['odata.id'];
                        ele.previewUrl = ele['odata.id'];
                        ele.updateFilePermissionUrl = ele.ServerRelativeUrl;
                    })
                    res(response.Files.concat(response.Folders));
                } else {
                    response.Folders.map((ele) => {
                        ele.name = ele.Name;
                        ele.id = ele['odata.id']
                    })
                    res(response.Folders);
                }
            }).catch(error => { });
        })
    }


    iveeSolarFileList = async(token,path)=>{
        let url = `https://iveesolar.sharepoint.com/_api/Web/GetFolderByServerRelativeUrl('/Shared%20Documents/Job%20Test/${path.replace('sharepoint_','')}')?$expand=Folders,Files`;
        let res = await Services.getCall(url, true,"Bearer " + token);
        let data = [];
        res.data && res.data.Files && res.data.Files.map((ele)=>{
            data.push({
                name : ele.Name,
                id : ele['odata.id'],
                previewUrl : ele['odata.id'],
                updateFilePermissionUrl : ele.ServerRelativeUrl
            });
        });
        return data;
    }

    filesListFolder = async(path, token, isExpand, s3) => {
        try {
            let url = path ? path + `?$expand=Folders ${!isExpand ? '' : ',Files'}` : `${s3.url1}/_api/Web/GetFolderByServerRelativeUrl('${s3.url2}')?$expand=Folders,Files`;
            let res = await Services.getCall(url, true, "Bearer " + token);
            return res.data;
        } catch (error) {
            return;
        }
    }

    makeFolderPublic = async(files,token) => {
        const s3 = await this.fetchSharePointS3(this.slug);
        let taskArray = [];
        for (var j in files) {
            files[j] && files[j].forEach(ele => {
                ele.updateFilePermissionUrl && taskArray.push(this.updateFilePermission(ele.updateFilePermissionUrl,ele.name,token,s3.BASE_URL,s3.url1));
            })
        }
        return Promise.all(taskArray).then(res => {
            return (res);
        }).catch(error=>{
            return;
        })
    }

    readIveePermission(path,name,token){
        return new Promise(async (res,rej)=>{
            let url = `https://iveesolar.sharepoint.com/_api/web/GetFolderByServerRelativeUrl('/Shared%20Documents/Job%20Test/${encodeURIComponent(path.replace('sharepoint_',''))}')/Files('${encodeURIComponent(name)}')/ListItemAllFields/ShareLink`;
            let body = { "request": { "createLink": true, "settings": { "allowAnonymousAccess": true, "linkKind": 4, "expiration": null, "restrictShareMembership": false, "updatePassword": false, "password": "", "description": "My description", "role": 8, "applicationLink": false, "limitUseToApplication": false } } };     
            let response = await Services.postCall(url,body, true,"Bearer " + token);
            return res({fileName:name,downloadURL:`https://iveesolar.sharepoint.com/_layouts/15/download.aspx?${response.data.sharingLinkInfo.ShareTokenString}`,publicURL:response.data.sharingLinkInfo.Url});
        })
    }

    readPermission(path,name,token,s3){
        return new Promise(async (res,rej)=>{
            let url = `${s3.url1}/_api/web/GetFolderByServerRelativeUrl('${encodeURIComponent(path)}')/Files('${encodeURIComponent(name)}')/ListItemAllFields/ShareLink`;
            let body = { "request": { "createLink": true, "settings": { "allowAnonymousAccess": true, "linkKind": 4, "expiration": null, "restrictShareMembership": false, "updatePassword": false, "password": "", "description": "My description", "role": 8, "applicationLink": false, "limitUseToApplication": false } } };     

            let response = await Services.postCall(url,body, true, "Bearer " +token);
            return res({fileName:name,downloadURL:`${s3.url1}/_layouts/15/download.aspx?${response.data.sharingLinkInfo.ShareTokenString}`,publicURL:response.data.sharingLinkInfo.Url});
        })
    }

    async updateFilePermission(path, name, token, BASE_URL, SITE_URL) {
        try {
            let url = `${SITE_URL}/_api/SP.Web.CreateAnonymousLink`;
            let body = {
                "url": `${BASE_URL}${path}`,
                "isEditLink": true
            };
            let response = await Services.postCall(url, body, true, "Bearer " + token);
            return { fileName: name, downloadURL: response?.data?.value, publicURL: response?.data?.value };
        } catch (error) {
            return;
        }
    }

    async teamworkFiles(token,path){
        let url = `https://wolfriverelectric.teamwork.com/projects/${path.replace('teamwork','')}/files.json`;
        let res = await Services.getCall(url, true, "Basic " + token);
        let task = [];
        let files = res.data && res.data.project && res.data && res.data.project.files;
        files && files.map((ele)=>{
            task.push(this.teamworkFileUrl(token,ele));
        });
        return Promise.all(task).then(res=>{
            return (res);
          })
    }


    teamworkFileUrl(token,fileObj){
        return new Promise(async(resolve,rej)=>{
            let url =  `https://wolfriverelectric.teamwork.com/files/${fileObj.id}/sharedlink.json`;
            let res = await Services.getCall(url, true, "Basic " + token);
            let ele = res && res.data
            resolve({
                name : fileObj.name,
                id : ele['url'],
                previewUrl : ele['url']
            })
        })
    }

}