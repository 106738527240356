import React from 'react';
import styled from 'styled-components';

import { ICounter } from './types';
import { standardCountFormat } from '../../shared';

export const DefaultCounterDecorator = styled.span`
  &.count {
    position: absolute;
    color: ${(props) => props.theme.colors.white};
    left: 35%;
    top: 5%;
    font-size: 14px;
    width: auto;
    padding: 0 4px;
    text-align: center;
    transform: translate(-50%, -50%);
    border-radius: ${(props) => props.theme.borderRadius.faint};
    padding: 7px 5px 4px;
    display: inline-block;
    line-height: 1;
    background-color: ${(props) => props.theme.colors.yellow};
    font-family: ${(props) => props.theme.fonts.standardBold};
    min-width: 23px;
    text-align: center;
    &.zero {
      display: none;
    }
  }
`;

export const InstallerCounterDecorator = styled(DefaultCounterDecorator)`
  &.count {
    right: -12px;
    left: auto;
    top: 33px;
    color: ${(props) => props.theme.colors.black};
    font-size: 12px;
    padding: 3px 3px 1px 2px;
    line-height: 14px;
    width: auto;
  }
`;

/**
 * Encapsulate a standard for display of counts of things
 */
export const Counter: React.FC<ICounter> = (props) => {
  const { formatter, number, decorator, allowInstallerCustom } = props;
  const Decorator = decorator || DefaultCounterDecorator;
  const count = formatter ? formatter(number) : standardCountFormat(number);

  // TODO: remove this when codebase is 100% TypeScript
  if (number === undefined) {
    return null;
  }

  const classes = ['count'];

  /**
   * If we allow company settings to determine background, color styles
   */
  if (!!allowInstallerCustom) {
    // TODO
  }

  if (Number(count) === 0) {
    classes.push('zero');
  }

  return <Decorator className={classes.join(' ')}>{count}</Decorator>;
};

export default Counter;
