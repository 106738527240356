// import npm packages;
import { call, put } from "redux-saga/effects";

// import local files;
import {
  receiveAddHomeOwnerDummy,
  receiveStateData,
  receiveTimezoneData,
  receiveApiData,
  receiveCompanyIdData,
  receiveInstallerData,
  receivePowerMeter,
  receiveAddHomeOwner
} from '../../screens/Installer/actions/addHomeOwner';
import {
  timeZoneApi,
  stateApi,
  existingInstall,
  companyIdApi,
  getInstallerApi,
  getPowerMeterApi,
  getAddHomeOwnerApi,
  getCRMTokenApi
} from '../../screens/Installer/api/addHomeOwner';
import { setLoader } from '../../screens/Common/Loader/action';
import handleError from '../../shared/errorHandler';

function* addExistingInstall(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(existingInstall, action.data);
    }

    yield put(setLoader({loader: false}));
    yield put(receiveApiData(data));
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e)
  }
}

function* getStateData(action) {
  try {
    const data = yield call(stateApi);

    // yield put(setLoader({loader: false}));
    yield put(receiveStateData(data));
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e)
  }
}

function* timeZone(action) {
  try {
    const data = yield call(timeZoneApi);

    yield put(setLoader({loader: false}));
    yield put(receiveTimezoneData(data));
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e)
  }
}

function* getCompanyId(action) {
  try {
    const data = yield call(companyIdApi, action.data);

    // yield put(setLoader({loader: false}));
    yield put(receiveCompanyIdData(data));
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e)
  }
}

function* getInstaller(action) {

  try {
    let data = {};
    if (JSON.stringify(action.data) !== "{}") {
      data = yield call(getInstallerApi, action.data);
    }
    yield put(setLoader({loader: false}));
    yield put(receiveInstallerData(data));
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e)
  }
}

function* getInstallerDummy(action) {
  try {
    // yield put(setLoader({loader: false}));
    yield put(receiveAddHomeOwnerDummy(action.data));
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e)
  }
}

function* getPowerMeter(action) {
  try {
    const data = yield call(getPowerMeterApi, action.data);

    // yield put(setLoader({loader: false}));
    yield put(receivePowerMeter(data));
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e)
  }
}

function* getAddHomeOwner(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(getAddHomeOwnerApi, action.data);
    }
    
    yield put(setLoader({loader: false}));
    yield put(receiveAddHomeOwner(data));
  } catch (e) {
    if(
      e
      && e.response
      && e.response.status
      && e.response.status === 403
    ) {
      yield put(receiveAddHomeOwner(e.response));
    } else {
      yield put(setLoader({loader: false}));
      handleError(e)
    }
  }
}

function* getCRMTokenData(action) {
  try {
    let res = {};
    if (JSON.stringify(action.data) !== '{}') {
      res = yield call(getCRMTokenApi, action.data);
      yield put(setLoader({loader: false}));
    }
    if(
      res
      && res.data
      && res.data.message
    ) {
      let data = {};
      if (JSON.stringify(action.data) !== '{}') {
        data = yield call(getAddHomeOwnerApi, action.data);
      }
      yield put(setLoader({loader: false}));
      yield put(receiveAddHomeOwner(data));
    }
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e)
  }
}

export const addHomeOwner = {
  addExistingInstall,
  getStateData,
  timeZone,
  getCompanyId,
  getInstaller,
  getPowerMeter,
  getInstallerDummy,
  getCRMTokenData,
  getAddHomeOwner
}