export const documentFilter = (filter,name) => {
    let isValid = false;
    if(!filter)
        return true;
    let arr =  filter.split(',');
    for(var i= 0;i<arr.length;i++ ){
        let rexpression = new RegExp(arr[i],"i");
        if(rexpression.test(name)){
            isValid = true;
            break;
        }
    }
    return isValid;
}