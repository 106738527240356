
export const REQUEST_COMPANY_SEARCH_DATA    = "REQUEST_COMPANY_SEARCH_DATA";
export const RECEIVE_COMPANY_SEARCH_DATA    = "RECEIVE_COMPANY_SEARCH_DATA";
export const REQUEST_ADMIN_COMPANY__DATA    = "REQUEST_ADMIN_COMPANY__DATA";
export const RECEIVE_ADMIN_COMPANY__DATA    = "RECEIVE_ADMIN_COMPANY__DATA";
export const REQUEST_ADMIN_HOMEOWNER_SEARCH = "REQUEST_ADMIN_HOMEOWNER_SEARCH";
export const RECEIVE_ADMIN_HOMEOWNER_SEARCH = "RECEIVE_ADMIN_HOMEOWNER_SEARCH";

export const requestSearchData = data => ({ type: REQUEST_COMPANY_SEARCH_DATA, data });
export const receiveSearchData = data => ({ type: RECEIVE_COMPANY_SEARCH_DATA, data });

export const requestAdminListData = data => ({ type: REQUEST_ADMIN_COMPANY__DATA, data });
export const receiveAdminListData = data => ({ type: RECEIVE_ADMIN_COMPANY__DATA, data });

export const requestAdminHomeownerSearch = data =>({type: REQUEST_ADMIN_HOMEOWNER_SEARCH,data});
export const receiveAdminHomeownerSearch = data =>({type: RECEIVE_ADMIN_HOMEOWNER_SEARCH,data});