import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import NumberFormat from 'react-number-format';
import { Input } from 'antd';

import googleMap from '../../../../environments/index';
import { PostShareDetails } from '../../api/promiseApi';
import { withRouter } from '../../../../shared';
import { setHeight } from '../../../../shared/setHeight';
import { checkIsMobile } from '../../../../shared/checkIsMobile';
import { REFERRAL_MESSAGE } from './constants';
import { configureShare, launchShareWindow } from './helpers';
import { ReactComponent as Group_of_people_Icon } from '../../../../Assets/Images/shareSun_icon.svg';
import { handleSubmitgeneric } from '../../components/shared/helpers';

import {
  gmailIconSvg,
  microsoftIconSvg,
  yahooIconSvg,
  icloudIconSvg,
  groupPeopleIconSvg as GroupPeopleIconSvg,
  facebookIconSvg,
  twitterIconSvg,
  whatsAppIconPng,
  smsIconSvg,
  locationIconSvg
} from '../../../../constants/icons';

const localShareName = 'referral';

class SolarCommunityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: null,
      lng: null,
      address: null,
      places: [],
      isThankYou: false,
      isErrorMessage: false,
      socialClick: false,
      hackBurger: false
    };
  }

  componentWillMount() {
    this.updateLatLng(this.props);
  }

  componentDidMount() {
    window.cloudsponge &&
      window.cloudsponge.init({
        sources: ['gmail', 'yahoo', 'icloud', , 'outlook'],
        afterSubmitContacts: this.submitCloudespongeContacts.bind(this)
      });
  }

  componentWillReceiveProps(nextProps) {
    setHeight('homeOwnerWrapper', 'menuMobile');
    const data = nextProps.referralDetails.postHomeownerReferrals;
    if (data.data && this.state.isSolarPage) {
      this.props.setLoader({ loader: false });
      this.props.receivePostReferralDetails('');
      this.setState({ isSolarPage: false, lat: null, name: '', email: '' }, () => {
        this.setState({ isThankYou: true, isErrorMessage: false });

        const hamburger = nextProps.history;
        if (
          hamburger &&
          hamburger.location &&
          hamburger.location.state &&
          hamburger.location.state.isHamburger
        ) {
          const state = { ...hamburger.location.state };
          delete state.isHamburger;
          nextProps.history.replace({ ...hamburger.location, state });
        }
      });
    } else if (data === 401 || data === 500 || data === 501 || data === 400 || data === 404) {
      this.props.receivePostReferralDetails('');
      this.setState({ isErrorMessage: true, isThankYou: false });
    }
    if (!this.state.lat) this.updateLatLng(nextProps);
  }

  componentDidUpdate() {
    if (!this.state.lat) this.updateLatLng(this.props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !this.state.lat ||
      this.state.socialClick ||
      this.state.hackBurger !== nextState.hackBurger
    ) {
      return true;
    }
    return false;
  }

  updateLatLng(nextProps) {
    if (
      nextProps.loggedInUser &&
      nextProps.loggedInUser.homeownerData &&
      nextProps.loggedInUser.homeownerData.lat &&
      nextProps.loggedInUser.homeownerData.lng &&
      nextProps.loggedInUser.homeownerData.address &&
      nextProps.homeownersList &&
      nextProps.homeownersList.ReferralMessage
    ) {
      this.setState({
        lat: Number(nextProps.loggedInUser.homeownerData.lat),
        lng: Number(nextProps.loggedInUser.homeownerData.lng),
        address: `${nextProps.loggedInUser.homeownerData.address.address1} ${nextProps.loggedInUser.homeownerData.address.address2} ${nextProps.loggedInUser.homeownerData.address.city} ${nextProps.loggedInUser.homeownerData.address.state} ${nextProps.loggedInUser.homeownerData.address.zipCode}`
      });
    }
  }

  onMapReady = (mapProps, map) => this.searchNearby(map, map.center);

  searchNearby = (map, center) => {
    const { google } = this.props;
    const service = new google.maps.places.PlacesService(map);

    // Specify location, radius and place types for your Places API search.
    const request = {
      location: center,
      radius: '500',
      type: ['food']
    };

    service.nearbySearch(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) this.setState({ places: results });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.saveReferral(handleSubmitgeneric(this.state, [], this.props));
  };

  submitCloudespongeContacts(contacts, source, owner) {
    this.saveReferral(handleSubmitgeneric(null, contacts, this.props));
  }

  saveReferral(body) {
    if (!body) return;

    this.props.setLoader({ loader: true });
    this.setState(
      {
        lat: null,
        name: '',
        email: '',
        isSolarPage: true
      },
      () => {
        if (this.nameTextElem) {
          this.nameTextElem.value = '';
          this.emailTextElem.value = '';
        }
        this.setState({ phone: '' });
        this.props.requestPostReferralDetails(body);
      }
    );
  }

  renderThankYou() {
    {
      checkIsMobile() && window.scrollTo(0, 0);
    }
    return (
      <div className="solar-community-box">
        <center>
          <div>
            <i>Thank you for sharing!</i>
            <p>We will notify you when your neighbor goes solar.</p>
          </div>
          <button
            type="button"
            className="btn yellow-btn premiumButtonFill premiumButtonText"
            onClick={() =>
              this.setState({ isThankYou: false, isErrorMessage: false, lat: null }, () => {
                this.updateLatLng(this.props);
              })
            }>
            Continue
          </button>
        </center>
      </div>
    );
  }

  referralMessageType = (systemGlance) => {
    if (systemGlance?.solarGlance?.referralType === 1) {
      return (
        <h6>
          Share the sun <br />
          {systemGlance?.solarGlance?.referralAmount
            ? `Give your friend $${systemGlance.solarGlance.referralAmount}`
            : ''}
        </h6>
      );
    }
    if (systemGlance?.solarGlance?.referralType === 2) {
      return (
        <h6>
          REFER A FRIEND. <br />
          {systemGlance?.solarGlance?.referralAmount
            ? `RECEIVE $${systemGlance.solarGlance.referralAmount}`
            : ''}
        </h6>
      );
    }
  };

  render() {
    const {
      homeownersList,
      systemGlance,
      loggedInUser,
      loggedInUser: { homeownerData },
      router
    } = this.props;

    const hamburger = router?.location?.state?.isHamburger;
    const referralId = loggedInUser?.homeownerData?.referralId;
    this.slug = localStorage.getItem('slug');

    const { hackBurger } = this.state;
    const { formSetting } = homeownerData;

    const element = document.getElementsByClassName('premiumLinks');
    const style = element[0] && window.getComputedStyle(element[0]);
    const premumLink = style && style.getPropertyValue('color');

    return (
      <div className="card-body map-bg">
        <div className="solar-community-drop-down">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                hackBurger: !hackBurger
              });
            }}
            className="collapsed up-arra map-drop-down premiumButtonFill premiumButtonText notHover"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseTwo">
            Grow your solar community{' '}
            <span className="pull-right">
              {' '}
              <i className={`fa fa-angle-${hackBurger ? 'up' : 'down'}`} aria-hidden="true" />
            </span>
          </a>
          <div
            id="collapseThree"
            className={`collapse mobile3 ${
              this.props.isReferralCommunity || hamburger || hackBurger ? 'show' : ''
            }`}>
            {this.state.isThankYou && !this.state.isErrorMessage && this.renderThankYou()}
            {this.state.isErrorMessage && (!this.state.isThankYou || hamburger) && (
              <div className="solar-community-box">
                <center>
                  <div>
                    <i>OOPS!</i>
                    <p>Something went terribly wrong.</p>
                  </div>
                  <button
                    type="button"
                    className="btn yellow-btn premiumButtonFill premiumButtonText"
                    onClick={() =>
                      this.setState({ isThankYou: false, isErrorMessage: false, lat: null }, () => {
                        this.updateLatLng(this.props);
                      })
                    }>
                    TRY AGAIN
                  </button>
                </center>
              </div>
            )}

            {/* Redner option to fill the form for referral */}

            <div className="solar-community-box">
              {!this.state.isThankYou && !this.state.isErrorMessage && (
                <>
                  <div className="community-icon premiumIcon">
                    <Group_of_people_Icon style={{ height: '100%', width: '100%' }} />
                  </div>

                  {this.referralMessageType(systemGlance)}
                  <p>
                  {systemGlance?.solarGlance?.ReferralMessage}
                  </p>
                </>
              )}
              {!this.state.isThankYou && !this.state.isErrorMessage && (
                <form
                  id="create-share-form"
                  onSubmit={this.handleSubmit}
                  style={{
                    display:
                      (!this.state.isThankYou && !this.state.isErrorMessage) || hackBurger
                        ? 'block'
                        : 'none'
                  }}>
                  <div className="invite-box">
                    <p>Easily share specific contacts</p>
                    <a className="cloudsponge-launch" data-cloudsponge-source="gmail">
                      {' '}
                      <img src={gmailIconSvg} alt="Gmail_Icon.svg" width="25" />
                    </a>
                    <a className="cloudsponge-launch" data-cloudsponge-source="outlook">
                      {' '}
                      <img src={microsoftIconSvg} alt="microsoft_icon.svg" width="25" />
                    </a>
                    <a className="cloudsponge-launch" data-cloudsponge-source="yahoo">
                      {' '}
                      <img src={yahooIconSvg} alt="yahoo_icon.svg" width="25" />
                    </a>
                    <a className="cloudsponge-launch" data-cloudsponge-source="icloud">
                      {' '}
                      <img src={icloudIconSvg} alt="icloud_icon.svg" width="25" />
                    </a>
                  </div>
                  <div className="form-group">
                    <Input
                      type="text"
                      ref={(el) => (this.nameTextElem = el)}
                      placeholder="Name"
                      className="form-control"
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      type="email"
                      placeholder="Email"
                      ref={(el) => (this.emailTextElem = el)}
                      className="form-control"
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <NumberFormat
                      type="tel"
                      format={formSetting && formSetting.phone}
                      placeholder="Phone Number"
                      value={this.state.phone}
                      mask=" "
                      className="form-control"
                      onChange={(e) => this.setState({ phone: e.target.value })}
                    />
                  </div>
                  <div className="button-wrap text-center">
                    <button
                      type="submit"
                      className="btn yellow-btn premiumButtonFill premiumButtonText"
                      disabled={this.props.isInstaller ? 'disabled' : ''}>
                      {systemGlance?.solarGlance?.referralType === 1 ? 'Give' : 'Invite'}
                    </button>
                  </div>
                </form>
              )}
              {/* 
              Render the option to share...
              TODO: Replace with <HomeownerShare />
              */}
              {!this.state.isThankYou && (
                <div className="share-via-wrapper border-zero">
                  <div
                    className="heading-share premiumLinks"
                    data-bs-toggle="collapse"
                    data-bs-target="#share-media-box">
                    {' '}
                    Share on social media{' '}
                  </div>
                  {!this.props.isInstaller ? (
                    <ul id="share-media-box" className="white-box share-via-wrapper">
                      <li>
                        <FacebookShareButton
                          beforeOnClick={() => configureShare(1, loggedInUser, localShareName)}
                          quote={REFERRAL_MESSAGE}
                          url={`${window.location.origin}/${this.slug}/referral/${referralId}/referral/1`}>
                          <a href="#" className="facebook">
                            <img src={facebookIconSvg} alt="facebook.svg" width="25" />
                          </a>
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          beforeOnClick={() => configureShare(2, loggedInUser, localShareName)}
                          title={REFERRAL_MESSAGE}
                          url={`${window.location.origin}/${this.slug}/referral/${referralId}/referral/2`}>
                          <a href="#" className="facebook">
                            <img src={twitterIconSvg} alt="twitter.svg" width="25" />
                          </a>
                        </TwitterShareButton>
                      </li>
                      <li className="disabled">
                        <a
                          onClick={() =>
                            launchShareWindow(
                              `https://api.whatsapp.com/send?text=${REFERRAL_MESSAGE}${window.location.origin}/${this.slug}/referral/${referralId}/referral/4`,
                              4,
                              loggedInUser,
                              localShareName
                            )
                          }
                          href="#"
                          className="message">
                          <img src={whatsAppIconPng} alt="sms.svg" width="25" />
                        </a>
                      </li>
                      <li className="disabled">
                        <a
                          onClick={() =>
                            launchShareWindow(
                              `sms:?&body=${REFERRAL_MESSAGE}${window.location.origin}/${this.slug}/referral/${referralId}/referral/3`,
                              3,
                              loggedInUser,
                              localShareName
                            )
                          }
                          href="#"
                          className="message">
                          <img src={smsIconSvg} alt="sms.svg" width="25" />
                        </a>
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li>
                        <img
                          src={facebookIconSvg}
                          alt="facebook.svg"
                          width="25"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </li>
                      <li>
                        <img
                          src={twitterIconSvg}
                          alt="twitter.svg"
                          width="25"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </li>
                      <li>
                        <img
                          src={whatsAppIconPng}
                          alt="twitter.svg"
                          width="25"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </li>
                      <li>
                        <img
                          src={smsIconSvg}
                          alt="sms.svg"
                          width="25"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </li>
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Render the location in google map */}
        <Map
          google={this.props.google}
          onReady={this.onMapReady}
          zoom={16}
          mapTypeControl={false}
          fullscreenControl={false}
          zoomControl={false}
          streetViewControl={false}
          initialCenter={{
            lat: this.state.lat || (homeownerData && homeownerData.lat),
            lng: this.state.lng || (homeownerData && homeownerData.lng)
          }}>
          {homeownersList &&
            homeownersList?.homesByAdd &&
            homeownersList?.homesByAdd?.length !== 0 &&
            homeownersList.homesByAdd.map((homeowner, index) => {
              if (homeowner.lat && homeowner.lng && homeowner.nearaddress) {
                return (
                  <Marker
                    key={index}
                    title={homeowner.nearaddress}
                    position={{
                      lat: Number(homeowner.lat),
                      lng: Number(homeowner.lng)
                    }}
                    icon={{
                      url: locationIconSvg
                    }}
                  />
                );
              }
              return '';
            })}

          <Marker
            title={this.state.address}
            position={{
              lat: this.state.lat || (homeownerData && homeownerData.lat),
              lng: this.state.lng || (homeownerData && homeownerData.lng)
            }}
            icon={{
              path: `M 0,-1
                                          C 0.5523, -1   1, -0.5523    1,0
                                          C 1, 0.5523    0.5523, 1     0,1
                                          C -0.5523, 1   -1, 0.5523    -1,0
                                          C -1, -0.5523  -0.5523, -1   0,-1`,
              fillColor: premumLink || '#FF6F47',
              fillOpacity: 1,
              strokeWeight: 0,
              rotation: 0,
              scale: 5
            }}
          />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: googleMap.envConfig.googleMap.API_KEY
})(withRouter(SolarCommunityForm));
