import { call, put } from "redux-saga/effects";

import { searchApi,adminCompanyList,adminSearchHomeowner } from "./../../screens/Admin/Api/header";
import {  receiveSearchData, receiveAdminListData,
          receiveAdminHomeownerSearch } from "./../../screens/Admin/Actions/header";
import  handleError  from './../../shared/errorHandler'

function* search(action) {
    try {
      if (JSON.stringify(action.data) !== '{}') {
          let data = yield call(searchApi, action.data);
          yield put(receiveSearchData(data));
      }
    } catch (e) {
      handleError(e)
      
    }
  }

  function* listCompany(action) {
    try {
      if (JSON.stringify(action.data) !== '{}') {
          let data = yield call(adminCompanyList, action.data);
          yield put(receiveAdminListData(data));
      }
    } catch (e) {
      handleError(e)
    }
  }

  function* searchHomeowner(action){
    try {
      if (JSON.stringify(action.data) !== '{}') {
          let data = yield call(adminSearchHomeowner, action.data);
          yield put(receiveAdminHomeownerSearch(data));
      }
    } catch (e) {
      handleError(e)
    }
  }
 
  export const adminHeader =  {
    search,
    searchHomeowner,
    listCompany
  }