
import { combineReducers } from 'redux';
import {
  SET_IMAGES
} from "./action";

const cloudImagesReducer = (state = {}, { type, data }) => {
  switch (type) {
    case SET_IMAGES:
      return data;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  cloudImages: cloudImagesReducer,
})
