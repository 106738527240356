// import npm packages
import React, { Component } from "react";
import { Spin }             from "antd";
import { LoadingOutlined }  from '@ant-design/icons';
import withRouter           from '../../../../shared/withRouter'
import { jwtDecode }        from "../../../../shared/jwt";
import { getToken }         from '../../../../shared/util';
import PersonalSetting      from '../../components/profile/PersonalSetting';
import settingsIcon         from "./../../../../Assets/Images/setting-icon.svg"

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

class Setting extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.slug = localStorage.getItem("slug");
  }

  componentDidMount() {
    if (this.props.installerData && JSON.stringify(this.props.installerData) !== '{}') {
      this.props.setLoader({loader: false});
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.installerData && JSON.stringify(nextProps.installerData) !== '{}') {
      nextProps.setLoader({loader: false});
    }
  }

  renderProfileScreen = () => {

    const { installerData, companyData } = this.props;
    return (
      <React.Fragment>
        <Spin
          spinning={this.props.loader}
          indicator={antIcon}
          style={{ color: "#00142D" }}
        >
          <div>
          <title>Company Settings - {companyData && companyData.companyDetail && companyData.companyDetail.name+'-'} Bodhi</title>

            <section className="personalSetting">
              <div className="container margin-t-73 ">
                <div className="new-client-form-wrapper border-none">
                  <div className="white-box create-client-form-box box-Shadow">
                    <h2><img src={settingsIcon} alt='17terawatts' />&nbsp;<span>Personal settings</span></h2>
                    <div className="settingPage">
                      <PersonalSetting data={installerData} {...this.props} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Spin>
      </React.Fragment>
    );
  }

  render() {
    if (getToken()) {
      return this.renderProfileScreen();
    } else {
      this.props.router.navigate("/");
      return null;
    }
  }
}

export default withRouter(Setting);
