import {
	REQUEST_REFERRAL_DETAILS,
	RECEIVE_REFERRAL_DETAILS,
	REQUEST_REFERRAL_NEIGHBOUR,
	RECEIVE_REFERRAL_NEIGHBOUR,
	REQUEST_POST_REFERRAL_DETAILS,
	RECEIVE_POST_REFERRAL_DETAILS,
	REQUEST_POST_ADDRESS_DETAILS,
	RECEIVE_POST_ADDRESS_DETAILS,
	RECEIVE_POST_ADDRESS_FORM_DATA

} from "../types/referralDetails/referralDetails";

export const requestReferralDetails = data => ({
	type: REQUEST_REFERRAL_DETAILS, data
});

export const requestReferralNeighbour = data => ({
	type: REQUEST_REFERRAL_NEIGHBOUR, data
});

export const receiveReferralDetails = data => ({
	type: RECEIVE_REFERRAL_DETAILS, data
});

export const receiveReferralNeighbour = data => ({
	type: RECEIVE_REFERRAL_NEIGHBOUR, data
});

export const requestPostReferralDetails = data => ({
	type: REQUEST_POST_REFERRAL_DETAILS, data
});

export const receivePostReferralDetails = data => ({
	type: RECEIVE_POST_REFERRAL_DETAILS, data
});

export const requestPostAddressDetails = data => ({
	type: REQUEST_POST_ADDRESS_DETAILS, data
});

export const receivePostAddressDetails = data => ({
	type: RECEIVE_POST_ADDRESS_DETAILS, data
});

export const receivePostAddressFormData = data => ({
	type: RECEIVE_POST_ADDRESS_FORM_DATA, data
});