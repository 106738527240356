// import npm packages
import { call, put } from "redux-saga/effects";

// import local files

import { getHomeownerGlanceData,getHomeownerCampaignData } from "../../screens/homeowner/api/dashboard";

import handleError from "../../shared/errorHandler";
import { setLoader } from "../../screens/Common/Loader/action";
import { receiveHomeownerSystemGlance, receiveHomeownerCampaignData } from "../../screens/homeowner/actions/dashboard";

function* getHomeownerSystemGlance(action) {
	try {
		const data = yield call(getHomeownerGlanceData, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveHomeownerSystemGlance(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* homeownerCampaignData(action) {
	try {
		const data = yield call(getHomeownerCampaignData, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveHomeownerCampaignData(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

export const homeownerDashboard = {
	getHomeownerSystemGlance,
	homeownerCampaignData
};
