

export const REQUEST_POWERMETERAPI_DATA = "REQUEST_POWERMETERAPI_DATA";
export const RECEIVE_POWERMETERAPI_DATA = "RECEIVE_POWERMETERAPI_DATA";



export const REQUEST_STATELISTAPI_DATA = "REQUEST_STATELISTAPI_DATA";
export const RECEIVE_STATELISTAPI_DATA = "RECEIVE_STATELISTAPI_DATA";
export const REQUEST_SLUGSTATUSAPI_DATA = "REQUEST_SLUGSTATUSAPI_DATA";
export const RECEIVE_SLUGSTATUSAPI_DATA = "RECEIVE_SLUGSTATUSAPI_DATA";
export const REQUEST_ADDCOMPANY_DATA = "REQUEST_ADDCOMPANY_DATA";
export const RECEIVE_ADDCOMPANY_DATA = "RECEIVE_ADDCOMPANY_DATA";

export const REQUEST_ADMINREPORT_DATA = "REQUEST_ADMINREPORT_DATA";
export const RECEIVE_ADMINREPORT_DATA = "RECEIVE_ADMINREPORT_DATA";

export const REQUEST_COMPANY_SUNLIGHT_CREDENTIALS_DATA = "REQUEST_COMPANY_SUNLIGHT_CREDENTIALS_DATA";
export const RECEIVE_COMPANY_SUNLIGHT_CREDENTIALS_DATA = "RECEIVE_COMPANY_SUNLIGHT_CREDENTIALS_DATA";

export const requestPowerMeterApiData = () => ({ type: REQUEST_POWERMETERAPI_DATA });
export const recievePowerMeterApiData = data => ({ type: RECEIVE_POWERMETERAPI_DATA, data });


export const requestStateListApiData = (data) => ({ type: REQUEST_STATELISTAPI_DATA,data });
export const recieveStateListApiData = data => ({ type: RECEIVE_STATELISTAPI_DATA, data });
export const requestSlugStatusApiData = (data) => ({ type: REQUEST_SLUGSTATUSAPI_DATA,data });
export const recieveSlugStatusApiData = data => ({ type: RECEIVE_SLUGSTATUSAPI_DATA, data });
export const requestAddCompanyApiData = (data) => ({ type: REQUEST_ADDCOMPANY_DATA,data });
export const recieveAddCompanyApiData = data => ({ type: RECEIVE_ADDCOMPANY_DATA, data });

export const requestAdminReportApiData = (data) => ({ type: REQUEST_ADMINREPORT_DATA, data});
export const recieveAdminReportApiData = data => ({ type: RECEIVE_ADMINREPORT_DATA, data });

export const requestCompanySunlightCredentialsData = (data) => ({ type: REQUEST_COMPANY_SUNLIGHT_CREDENTIALS_DATA,data });
export const recieveCompanySunlightCredentialsData = data => ({ type: RECEIVE_COMPANY_SUNLIGHT_CREDENTIALS_DATA, data });