import React from 'react';
import { StyledHeader } from './shared.style';

interface IHeader {
  children?: React.ReactNode;
  tag?: string;
  className?: string;
}

const resolveHeader: React.FC<IHeader> = (props) => {
  const { tag, className, children } = props;
  switch (tag) {
    case 'h1':
      return <h1 className={className}>{children}</h1>;
    case 'h2':
      return <h2 className={className}>{children}</h2>;
    case 'h3':
      return <h3 className={className}>{children}</h3>;
    case 'h4':
      return <h4 className={className}>{children}</h4>;
    case 'h5':
      return <h5 className={className}>{children}</h5>;
    default:
      return <h4>{children}</h4>;
  }
};

export const Header: React.FC<IHeader> = (props) => {
  return <StyledHeader>{resolveHeader(props)}</StyledHeader>;
};

export default Header;
