// import npm packages
import { connect } from 'react-redux'
import React, { Component } from "react";
import { reducer } from 'aws-cognito-redux-saga'

// import local files
import ForgotPassword from './ForgotPassword'

class ForgotPasswordContainer extends Component {
  render() {
    return (<ForgotPassword {...this.props} />)
  }
}

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    image: state.user.slugImage,
    homeownerResendInvitation: state.homeownerResendInvitation
  }
}


const mapDispatchToProps = dispatch => {
  return {
    init: () => {
      dispatch(reducer.init())
    },
    forgotPassword: username => {
      dispatch(reducer.forgotPassword(username))
    }
  }
}

export default connect(mapStatetoProps, mapDispatchToProps)(
  ForgotPasswordContainer
)
