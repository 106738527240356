import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import {
  withRouter,
  getToken,
  routeWithParams,
  getHomeownerRouteFromModel,
  isHomeownerRouteByName
} from '../../../../shared';
import { ReactComponent as DashboardActive } from '../../../../Assets/Images/home-blue.svg';
import { ReactComponent as DasboardInActive } from '../../../../Assets/Images/home-icon.svg';
import { ReactComponent as SolarCommunityActive } from '../../../../Assets/Images/shareSun_icon.svg';
import { ReactComponent as SolarCommunityInActive } from '../../../../Assets/Images/shareSun_inActive.svg';
import { ReactComponent as SearchInActive } from '../../../../Assets/Images/sun_rays.svg';
import { ReactComponent as SearchActive } from '../../../../Assets/Images/sun-icon.svg';

import { ReactComponent as HomeownerDocumentInActive } from '../../../../Assets/Images/documents_inActive.svg';
import { ReactComponent as HomeownerDocumentActive } from '../../../../Assets/Images/documents.svg';
import { suspenseFallback } from '../../../../constants/components';
let timeOut;

const HomeownerFooter = React.lazy(() => import('../../../homeowner/components/footer/footer.tsx'));

class FooterComponent extends React.Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.dataSource = [];
    this.data = {};
    this.dashboardActive = '';
    this.searchActive = '';
    this.addhomeownerActive = '';
    this.state = {
      dashboardActive: 'home-blue.svg',
      searchActive: 'sun-icon.svg',
      addhomeownerActive: 'group7.png',
      solarCommunityActive: 'shareSun_icon.svg',
      homeownerDocumentActive :'documents.svg'
    };
  }

  get token() {
    return getToken();
  }

  componentDidMount() {
    this.setIcons();
  }

  componentDidUpdate(prevProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.setIcons();
    }
  }

  setIcons() {
    const { router, isHomeownerProtal } = this.props;
    const { params } = router;
    const slug = params.slug;
    let { dashboardActive, searchActive, addhomeownerActive, systemGlance, solarCommunityActive,homeownerDocumentActive } =
      this.state;

    const dashboardRoute = getHomeownerRouteFromModel('name', 'dashboard');
    if (isHomeownerRouteByName('dashboard')) {
      dashboardActive = <DashboardActive />;
    } else {
      dashboardActive = (
        <DasboardInActive
          onClick={() => {
            if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
              window.scrollBy(0, -1500000000);
            } else clearTimeout(timeOut);
            if (isHomeownerProtal) {
              router.navigate(`/${slug}${dashboardRoute.pathname}`);
            } else {
              router.navigate(routeWithParams(params, 'dashboard'));
            }
          }}
        />
      );
    }

    if (isHomeownerRouteByName('search')) {
      searchActive = (
        <SearchActive
          onClick={() => {
            router.navigate(routeWithParams(params, 'search'));
          }}
        />
      );
    } else {
      searchActive = (
        <SearchInActive
          onClick={() => {
            router.navigate(routeWithParams(params, 'search'));
          }}
        />
      );
    }

    const sysGlanceRouteModel = getHomeownerRouteFromModel('name', 'systemGlance');
    const systemGlanceRoute = `/${slug}${sysGlanceRouteModel.pathname}`;

    if (isHomeownerRouteByName('systemGlance')) {
      systemGlance = <SearchActive />;
    } else {
      systemGlance = (
        <SearchInActive
          onClick={() => {
            if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
              window.scrollBy(0, -1500000000);
            } else clearTimeout(timeOut);
            router.navigate(systemGlanceRoute);
          }}
        />
      );
    }

   
    if(isHomeownerRouteByName('documents')){
      homeownerDocumentActive = <HomeownerDocumentActive/>
    } else {
      homeownerDocumentActive = <HomeownerDocumentInActive
        onClick={() => {
          if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
            window.scrollBy(0, -1500000000);
          } else clearTimeout(timeOut);
          router.navigate(`/${slug}/documents`);
        }}
      />
    }

    addhomeownerActive = router.location.pathname.includes('addhomeowner')
      ? 'new-install.svg'
      : 'group7.png';

    const solarCommRouteModel = getHomeownerRouteFromModel('name', 'solarCommunity');
    const solarCommunityRoute = `/${slug}${solarCommRouteModel.pathname}`;

    if (isHomeownerRouteByName('solarCommunity')) {
      solarCommunityActive = <SolarCommunityActive />;
    } else {
      solarCommunityActive = (
        <SolarCommunityInActive
          onClick={() => {
            if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
              window.scrollBy(0, -1500000000);
            } else clearTimeout(timeOut);
            router.navigate(solarCommunityRoute);
          }}
        />
      );
    }

    this.setState({
      dashboardActive,
      searchActive,
      systemGlance,
      addhomeownerActive,
      solarCommunityActive,
      homeownerDocumentActive
    });
  }

  renderInstallerFooter() {
    const {
      router: { params }
    } = this.props;

    return (
      <div className="footer-17">
        <div className={`mob-menu ${isHomeownerRouteByName('dashboard') ? 'premiumIcon' : ''}`}>
          {this.state.dashboardActive}
          <span>Home</span>
        </div>
        <div className={`mob-menu ${isHomeownerRouteByName('search') ? 'premiumIcon' : ''}`}>
          {this.state.searchActive}
          <span>Search</span>
        </div>
        <div className={`mob-menu ${isHomeownerRouteByName('addhomeowner') ? 'active' : ''}`}>
          <img
            src={require(`./../../../../Assets/Images/${this.state.addhomeownerActive}`)}
            style={{ width: '32px' }}
            onClick={() => {
              router.navigate(routeWithParams(params, 'addhomeowner'));
            }}
            alt="addhomeowner"
          />
          <span style={{ paddingLeft: '10px' }}>Add</span>
        </div>
      </div>
    );
  }

  renderHomeOwnerFooter() {
    return (
      <Suspense fallback={suspenseFallback}>
        <HomeownerFooter
          dashboardActive={this.state.dashboardActive}
          solarCommunityActive={this.state.solarCommunityActive}
          systemGlance={this.state.systemGlance}
          homeownerDocumentActive = {this.state.homeownerDocumentActive}
        />
      </Suspense>
    );
  }

  render() {
    if (!this.token) {
      return null;
    }
    return this.props.isHomeownerProtal
      ? this.renderHomeOwnerFooter()
      : this.renderInstallerFooter();
  }
}

export default withRouter(FooterComponent);
