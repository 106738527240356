import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React, { Component } from 'react';
import Slider from 'react-slick';
import withRouter from '../../../../shared/withRouter';
import { getToken, routeFactory } from '../../../../shared/util';
import { jwtDecode } from '../../../../shared/jwt';
import envConfig from '../../../../environments/index';
import { closeIconSvg } from '../../../../constants/icons';

let mobileWidth = document.body.offsetWidth;
let mobile2 = 767;

class DemoCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 1,
      infinite: false
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    let token = getToken();
    let tokenData = jwtDecode(token);
    this.slug = tokenData.slug;
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  componentWillMount() {
    if (mobileWidth <= mobile2 && !this.state.centerMode) {
      this.setState({
        centerMode: true
      });
    } else if (this.state.centerMode) {
      this.setState({
        centerMode: false
      });
    }
  }

  componentDidMount() {
    document.getElementsByClassName('nextClassButton')[0].style.display = 'none';
  }

  renderText = (slides, companyDetail, index, slide) => {
    const slideIndex = index + 1;
    if (slides.mainText === 'Solar journey' && companyDetail) {
      if (index == 1) {
        let text = companyDetail['phaseGlance'].DesignPhaseCutOff
          ? slides.resourcesChild[1].text2.replace(
              '#X',
              companyDetail['phaseGlance'].DesignPhaseCutOff
            )
          : slides.resourcesChild[1].text2.replace('#X', 'some');
        return text;
      } else if (index == 2) {
        let text =
          companyDetail['phaseGlance'].PermittingPhaseCutOff !== null
            ? slides.resourcesChild[2].text2
                .replace('#X', companyDetail['phaseGlance'].PermittingPhaseCutOff)
                .replace('#SOLAR COMPANY', companyDetail['solarGlance'].companyName)
            : slides.resourcesChild[2].text2
                .replace('#X', 'some')
                .replace('#SOLAR COMPANY', companyDetail['solarGlance'].companyName);
        return text;
      } else if (index == 3) {
        let text =
          companyDetail['phaseGlance'].BuildPhaseCutOff !== null
            ? slides.resourcesChild[3].text2.replace(
                '#X',
                companyDetail['phaseGlance'].BuildPhaseCutOff
              )
            : slides.resourcesChild[3].text2.replace('#X', 'some');
        return text;
      } else if (index == 4) {
        let text =
          companyDetail['phaseGlance'].InspectionPhaseCutOff !== null && slides.resourcesChild[4]
            ? slides.resourcesChild[4].text2.replace(
                '#X',
                companyDetail['phaseGlance'].InspectionPhaseCutOff
              )
            : slides.resourcesChild[4] && slides.resourcesChild[4].text2.replace('#X', 'some');
        return text;
      } else if (index == 5) {
        return (
          slides.resourcesChild[5] &&
          slides.resourcesChild[5].text2.replace(
            '#SOLAR COMPANY',
            companyDetail['solarGlance'].companyName
          )
        );
      } else {
        const updateText = slide.text2.replace(
          '#SOLAR COMPANY',
          companyDetail['solarGlance'].companyName
        );
        return updateText;
      }
    } else {
      const updateText = slide.text2.replace(
        '#SOLAR COMPANY',
        companyDetail['solarGlance'].companyName
      );
      return updateText;
    }
  };

  mediaSourceUrl(slide) {
    if (slide.childImg && slide.childImg.includes('blob')) {
      return slide.childImg;
    } else if (slide.s3Path) {
      return `https://${envConfig.envConfig.s3.BUCKET}.s3.amazonaws.com/public/resourceMedia/${slide.s3Path}`;
    } else {
      return require('../../../../Assets/Images/Resources/' + slide.childImg);
    }
  }

  render() {
    const { slides, companyDetail, slideNumber } = this.props;
    let phaseNumber =
      companyDetail && companyDetail.solarGlance && companyDetail.solarGlance.phaseName;
    let phaseClass;
    let phaseStatus;
    if (slideNumber && phaseNumber < slideNumber) {
      phaseClass = 'upcoming';
      phaseStatus = 'UPCOMING';
    } else if (slideNumber != null && phaseNumber > slideNumber) {
      phaseClass = 'complete';
      phaseStatus = 'COMPLETE';
    } else if (slideNumber && slideNumber != null) {
      phaseClass = 'complete';
      phaseStatus = 'IN PROGRESS';
    }
    // let element = document.getElementsByClassName("slick-next");
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      beforeChange: (oldIndex, newIndex) => {
        if (
          newIndex === slides.resourcesChild.length - 1 &&
          oldIndex === slides.resourcesChild.length - 1
        ) {
          this.props.slideReset();
        } else if (
          newIndex === slides.resourcesChild.length - 1 &&
          oldIndex === slides.resourcesChild.length - 2
        ) {
          document.getElementsByClassName('nextClassButton')[0].style.display = 'block';
          document.getElementsByClassName('slick-next')[0].style.display = 'none';
        } else {
          document.getElementsByClassName('nextClassButton')[0].style.display = 'none';
          document.getElementsByClassName('slick-next')[0].style.display = 'block';
        }
      },
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: true,
            centerPadding: '10px'
          }
        }
      ]
    };

    return (
      <div className="next-level-wrapp edu-slider-wrap resource-box">
        <div
          className={
            'blue-bg-box resource-bg margb35 premiumBackground premiumSecondaryText ' + phaseClass
          }>
          {!!slides && !!slides.resourcesChild && slides.resourcesChild.length > 0 && (
            <Slider ref={(c) => (this.slider = c)} {...settings}>
              {slides.resourcesChild.map((slide, index) => {
                return (
                  <React.Fragment key={`resource-key-${index}`}>
                    {!!slideNumber && (
                      <img
                        className="closeIcon"
                        src={closeIconSvg}
                        onClick={(event) => {
                          event.stopPropagation();
                          this.props.router.navigate(
                            routeFactory(this.slug, undefined, 'dashboard')
                          );
                        }}
                      />
                    )}
                    {/* <p className="text-center slider-heading2">{phaseStatus}</p> */}
                    <h2 className="text-center slider-heading">{slide.text1}</h2>
                    <div className="slider-container">
                      {!!slide.ImageType && !!slide.ImageType.includes('video') ? (
                        <video className="hut-img m-0" style={{ width: '100%' }} controls>
                          <source src={this.mediaSourceUrl(slide)} type="video/mp4" />
                        </video>
                      ) : (
                        <img className="img-responsive" src={this.mediaSourceUrl(slide)} />
                      )}
                    </div>
                    <h2 className="text-center slider-2-content">
                      {!!slides.resourcesChild[0] &&
                        this.renderText(slides, companyDetail, index, slide)}
                    </h2>
                    {slideNumber >= 0 && (
                      <div className="numbering-box">
                        <div className="number-content" key={index}>
                          {index + 1} / {slides.resourcesChild.length}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </Slider>
          )}
          <button
            className="nextClassButton slick-arrow slick-next"
            onClick={() => {
              this.props.slideReset();
            }}>
            next
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(DemoCarousel);
