import React from 'react';

import { landingPagePng } from '../../constants/icons';

interface ILandingPage {
  loadingIcon: String;
  role: string;
}

export const LandingPage: React.FC<ILandingPage> = (props) => {
  const { loadingIcon, role } = props;

  let loadImg;
  if (loadingIcon) {
    loadImg = loadingIcon;
  } else if (role !== 'homeowner') {
    loadImg = landingPagePng;
  }

  return (
    <div className="landing-page-container">
      <div className="image-container">
        {loadImg && <img src={loadImg} alt="landing-page.png" />}
      </div>
    </div>
  );
};

export default LandingPage;
