import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

import { globalStyles } from '../../constants';
import { loadingIcon } from '../../constants/components';
import { IWithLoader } from './types';

const LoaderDecorator = styled.div`
  &.local {
    position: relative;
    width: inherit;
    .ant-spin-nested-loading > div > .ant-spin {
      position: absolute !important;
      width: inherit;
      left: 0;
      margin: auto;
      top: 40vh;
      bottom: auto;
      right: 0;
      height: inherit;
    }
  }
  &.global {
    .ant-spin-nested-loading > div > .ant-spin {
      position: fixed !important;
    }
  }
`;

/**
 * A HOC to encapsulate loading indication on comopnents
 */
export const WithLoader =
  <P extends object>(FC: React.ComponentType<P>): React.FC<P & IWithLoader> =>
  ({ loading, loader, tipText, ...props }) => {
    return loading ? (
      <LoaderDecorator className={loader || 'global'}>
        <Spin
          spinning={loading}
          indicator={loadingIcon}
          style={{ color: globalStyles.colors.blackMed }}
          wrapperClassName={loader || 'global'}
          tip={tipText}
        >
          <FC {...(props as P)} loading={loading} />
        </Spin>
      </LoaderDecorator>
    ) : (
      <FC {...(props as P)} />
    );
  };

export default WithLoader;
