
import { combineReducers } from 'redux';
import {
  RECEIVE_TIME_ZONE_DATA, RECEIVE_STATE_DATA, RECEIVE_API_DATA, RECEIVE_COMPANY_ID_DATA,
  RECEIVE_POWER_METER_DATA, RECEIVE_INSTALLER_DATA, RECEIVE_ADD_HOME_OWNER_DATA, RECEIVE_ADD_HOME_OWNER_DATA_DUMMY
} from "../../actions/addHomeOwner";

const existingInstallReducerDummy = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_ADD_HOME_OWNER_DATA_DUMMY:
      return data;
    default:
      return state;
  }
};

const existingInstallReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_API_DATA:
      return data;
    default:
      return state;
  }
};

const stateReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_STATE_DATA:
      return data;
    default:
      return state;
  }
};

const timezoneReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_TIME_ZONE_DATA:
      return data;
    default:
      return state;
  }
};

const companyIdReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_COMPANY_ID_DATA:
      return data;
    default:
      return state;
  }
};

const getInstallerReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_INSTALLER_DATA:
      return data;
    default:
      return state;
  }
};

const getPowerMeterReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_POWER_METER_DATA:
      return data;
    default:
      return state;
  }
};

const getHomeOwnerReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_ADD_HOME_OWNER_DATA:
      return data;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  timezone: timezoneReducer,
  existingInstall: existingInstallReducer,
  state: stateReducer,
  companyId: companyIdReducer,
  getInstaller: getInstallerReducer,
  powerMeter: getPowerMeterReducer,
  homeOwner: getHomeOwnerReducer,
  existingInstallReducerDummy: existingInstallReducerDummy
})
