import React, { Component, Suspense } from 'react';
import styled from 'styled-components';

import { withRouter, getToken } from '../../../../shared';
import { checkIsMobile } from '../../../../shared/checkIsMobile';

import HomeownerDashboard from '../../../homeowner/container/dashboard/dashboard';
import BodhiJourney from '../../../homeowner/components/shared/bodhiJourney';
import LandingPage from '../../../Common/LandingPage';
import { HomeownerFooter } from './homeOwnerFooter';

const SolarCommunity = React.lazy(() =>
  import('../../../homeowner/components/shared/solarCommunity')
);
const SystemAtGlance = React.lazy(() =>
  import('../../../homeowner/components/shared/systemAtGlance')
);
const ResourcesForm = React.lazy(() => import('../../../homeowner/container/resources/resources'));
const FAQ = React.lazy(() => import('../../../homeowner/container/faq'));
const Settings = React.lazy(() => import('../../../homeowner/container/settings'));
const Contact = React.lazy(() => import('../../../homeowner/container/contact/contact'));
const Documents = React.lazy(() => import('../../../homeowner/container/documents'));

import {
  contactIconSvg,
  settingsIconSvg,
  documentsIconSvg,
  questionMarkIconSvg,
  edResourcesIconBlueSvg,
  settingsIconBlueSvg,
  phoneChatIconSvg,
  logoutIconBlueSvg
} from '../../../../constants/icons';

import { suspenseFallback } from '../../../../constants/components';
import store from '../../../../store';
import { fetchThreadsByCompanyId } from '../../../../store/InboxSlice';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.slug = '';
    this.state = {
      isSlidesReset: true,
      isDashboard: true,
      select: localStorage.getItem('tabName') === 'document' ? 'document' : 'dashboard',
      isShow: false,
      dasboardActive: 'home-blue.svg',
      searchActive: 'sun-icon.svg',
      solarCommunityActive: 'global-icon.png',
      homeOwnerHeight: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setHeight();
    if (nextProps.isDashboard && !this.state.isDashboard) {
      this.setState({
        isDashboard: true,
        select: 'dashboard'
      });
    }
  }

  componentDidMount() {
    this.slug = this.props.router.params.slug || localStorage.getItem('slug');
    this.setIcons();
    this.invokeThreadsByCompanyId();
  }

  componentDidUpdate(prevProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.setIcons();
      this.setState({
        isGlanceShow: false,
        isSolarShow: false
      });
    }

    const { id: homeownerId } = this.props.router.params;
    const { id: previousHomeownerId } = prevProps.router.params;
    if (homeownerId !== previousHomeownerId) {
      this.invokeThreadsByCompanyId();
    }
  }

  async invokeThreadsByCompanyId() {
    const { id: homeownerId } = this.props.router.params;
    await store.dispatch(
      fetchThreadsByCompanyId({
        homeownerId,
        page: 1
      })
    );
  }

  setHeight() {
    let homeOwner = null;
    if (document.getElementById('homeownerview-mobile'))
      homeOwner = document.getElementById('homeownerview-mobile').offsetHeight;
    if (homeOwner && this.state.homeOwnerHeight !== homeOwner) {
      this.setState({
        homeOwnerHeight: homeOwner
      });
    }
  }

  coloapsablehomeowner = () => {
    document.body.classList.toggle('hideCommunity');
    const currentState = this.state.active;

    this.setState({
      active: !currentState,
      isMount: !this.state.isMount,
      isShow: !this.state.isShow
    });
  };

  setIcons() {
    const { select } = this.state;

    this.setState({
      dasboardActive: select === 'dashboard' ? 'home-blue.svg' : 'shapeM.png',
      searchActive: select === 'systemGlance' ? 'sun-icon.svg' : 'sun_rays.svg',
      solarCommunityActive:
        select === 'solarCommunity' ? 'global-icon-active.svg' : 'global-icon.png'
    });
  }

  slideReset() {
    this.setState({
      isSlidesReset: false
    });
  }

  setActiveItem(updatedState, callback) {
    this.setState(updatedState, callback);
  }

  renderDashboard = () => {
    const {
      systemGlance,
      loggedInUser,
      referralDetails,
      cloudImages,
      setImages,
      installerData,
      router
    } = this.props;
    let mobileImage =
      loggedInUser?.homeownerData?.icon ||
      'https://prod-17terawatts.s3.amazonaws.com/public/logo/bothi-mobile-logo.png';
    let companyName = loggedInUser?.homeownerData?.companyName;
    let installerName =
      loggedInUser?.homeownerData?.installerName || loggedInUser.homeownerData.installerEmail;
    let navLogo = checkIsMobile() ? mobileImage : systemGlance?.solarGlance?.logo;
    return (
      <>
        <div className="white-box w-100 border0 box-Shadow">
          <div
            id="homeOwnerWrapper"
            className="pddl30 pddr30 pddb30 pm-home-owner-wrapper wrapper-installer-data">
            <header>
              <div className="nav-header-wrapper dashboard-nav-wrapper body-header">
                <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom-0">
                  {installerData?.data?.isProfileCreated ? (
                    <a
                      className="navbar-brand homeownerView"
                      onClick={() => {
                        router.navigate(`/${this.slug}/dashboard`);
                      }}
                      style={{
                        backgroundImage: `url(${navLogo})`
                      }}
                    />
                  ) : (
                    <a
                      className="navbar-brand homeownerView"
                      style={{
                        backgroundImage: `url(${navLogo})`
                      }}
                    />
                  )}
                  <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarhomeOwnerContent"
                    aria-controls="navbarhomeOwnerContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={this.coloapsablehomeowner.bind(this)}
                    className={`navbar-toggler ${this.state.active ? 'cross-icon' : ''}`}>
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className={
                      this.state.isShow
                        ? 'collapse navbar-collapse show '
                        : 'collapse navbar-collapse display-block'
                    }
                    id="navbarhomeOwnerContent">
                    <div className=" my-2 my-lg-0">
                      <ul className="navbar-nav desktop-homewoner-nav text-right">
                        <li className="nav-item">
                          <a
                            className={
                              this.state.select === 'dashboard'
                                ? 'size nav-link active'
                                : 'size nav-link'
                            }
                            id="home-tab"
                            data-bs-toggle="tab"
                            href="#dasboard"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            onClick={() => {
                              this.setState({
                                isSlidesReset: true,
                                isDashboard: true,
                                select: 'dashboard'
                              });
                            }}>
                            HOME
                          </a>
                        </li>
                        {this.slug !== 'creative' && (
                          <li className="nav-item">
                            <a
                              className={
                                this.state.select === 'document'
                                  ? 'size nav-link active'
                                  : 'size nav-link'
                              }
                              id="profile-tab"
                              data-bs-toggle="tab"
                              href="#document"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                              onClick={() => {
                                this.setState({
                                  isSlidesReset: true,
                                  isDashboard: false,
                                  select: 'document'
                                });
                                this.props.loadDashboard();
                              }}>
                              DOCUMENTS
                            </a>
                          </li>
                        )}

                        <li className="nav-item">
                          <a
                            className={
                              this.state.select === 'resource'
                                ? 'size nav-link active'
                                : 'size nav-link'
                            }
                            id="contact-tab"
                            data-bs-toggle="tab"
                            href="#resource"
                            role="tab"
                            aria-controls="contact"
                            aria-selected="false"
                            onClick={() => {
                              this.setState({
                                isSlidesReset: true,
                                isDashboard: false,
                                select: 'resource'
                              });
                              this.props.loadDashboard();
                            }}>
                            RESOURCES
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.select === 'faq' ? 'size nav-link active' : 'size nav-link'
                            }
                            id="contact-tab"
                            data-bs-toggle="tab"
                            href="#faq"
                            role="tab"
                            aria-controls="contact"
                            aria-selected="false"
                            onClick={() => {
                              this.setState({
                                isSlidesReset: true,
                                isDashboard: false,
                                select: 'faq'
                              });
                              this.props.loadDashboard();
                            }}>
                            FAQ
                          </a>
                        </li>
                        <li className="nav-item nav-add-link home-owner-nav pad10">
                          <a className={'size shareSun'}>SHARE THE SUN</a>
                        </li>
                        <li className="nav-item dropdown">
                          <a
                            className="nav-link dropdown-toggle"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <img
                              className="dashboard-profile-image comp-icon"
                              src={mobileImage}
                              // alt='Mobile profile-pic'
                            />
                            <span className="dashboard-profile-name">{companyName}</span>
                          </a>
                          <div
                            className="dropdown-menu box-Shadow"
                            aria-labelledby="navbarDropdown">
                            <div className="drop-up-arrow"></div>
                            <a
                              className="dropdown-item homeowner"
                              onClick={() => {
                                this.setState({
                                  isSlidesReset: true,
                                  isDashboard: false,
                                  select: 'contact'
                                });
                                this.props.loadDashboard();
                              }}>
                              <span className="drop-icon homeowner">
                                <img src={contactIconSvg} alt="contact-icon" />
                              </span>
                              <span className="dropHeader">Contact {companyName}</span>
                              <div className="text">Project Manager</div>
                              <div className="text">{installerName}</div>
                            </a>

                            <a
                              className="dropdown-item"
                              onClick={() => {
                                this.setState({
                                  isSlidesReset: true,
                                  isDashboard: false,
                                  select: 'settings'
                                });
                                this.props.loadDashboard();
                              }}>
                              <span className="drop-icon">
                                <img src={settingsIconSvg} alt="setting-icon" />
                              </span>
                              <span className="dropHeader">Change Settings</span>
                            </a>
                          </div>
                        </li>
                      </ul>

                      {/* mobile-menu    */}
                      <div className="row">
                        <div id="menuMobile" className="menu-mobile">
                          <div className="row">
                            <div
                              className="col-6 menu-box"
                              onClick={() => {
                                this.props.loadDashboard();
                                const currentState = this.state.active;
                                this.setState({
                                  isSlidesReset: true,
                                  isDashboard: false,
                                  select: 'document',
                                  active: !currentState,
                                  isShow: false
                                });
                              }}>
                              <img src={documentsIconSvg} alt="documents-icon" />
                              <span className="menu-text">DOCUMENTS</span>
                            </div>
                            <div className="col-6 menu-box">
                              <span
                                onClick={() => {
                                  this.props.loadDashboard();
                                  const currentState = this.state.active;
                                  this.setState({
                                    isSlidesReset: true,
                                    isDashboard: false,
                                    select: 'faq',
                                    active: !currentState,
                                    isShow: false
                                  });
                                }}>
                                <img src={questionMarkIconSvg} alt="question_mark-icon" />
                                <span className="menu-text">FAQs</span>
                              </span>
                            </div>
                            <div className="col-6 menu-box border-t-0 border-b-0">
                              <span
                                onClick={() => {
                                  this.props.loadDashboard();
                                  const currentState = this.state.active;
                                  this.setState({
                                    isSlidesReset: true,
                                    isDashboard: false,
                                    select: 'resource',
                                    active: !currentState,
                                    isShow: false
                                  });
                                }}>
                                <img src={edResourcesIconBlueSvg} alt="setting-icon-blue" />
                                <span className="menu-text">EDUCATIONAL RESOURCES</span>
                              </span>
                            </div>
                            <div className="col-6 menu-box border-t-0 border-b-0">
                              <span
                                onClick={() => {
                                  this.props.loadDashboard();
                                  const currentState = this.state.active;
                                  this.setState({
                                    isSlidesReset: true,
                                    isDashboard: false,
                                    select: 'settings',
                                    active: !currentState,
                                    isShow: false
                                  });
                                }}>
                                <img src={settingsIconBlueSvg} alt="setting-icon-blue" />
                                <span className="menu-text">SETTINGS</span>
                              </span>
                            </div>
                            <div className="col-6 menu-box">
                              <span
                                onClick={() => {
                                  this.props.loadDashboard();
                                  const currentState = this.state.active;
                                  this.setState({
                                    isSlidesReset: true,
                                    isDashboard: false,
                                    select: 'contact',
                                    active: !currentState,
                                    isShow: false
                                  });
                                }}>
                                <img src={phoneChatIconSvg} alt="phone_chat-icon" />
                                <span className="menu-text">CONTACT YOUR PROJECT MANAGER</span>
                              </span>
                            </div>
                            <div className="col-6 menu-box">
                              <span onClick={this.signOut}>
                                <img src={logoutIconBlueSvg} alt="setting-icon-blue" />
                                <span className="menu-text">LOGOUT</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </header>
            <div>
              {JSON.stringify(this.props.loggedInUser) === '{}' ? (
                <LandingPage />
              ) : (
                <section id="homeownerview-mobile" className="two-box-wrapper portal-intro-wrapp ">
                  <div id="container" className="container">
                    <div className="homerowner-view">
                      <div className="row">
                        <div className="col-md-5 desktop-view-only col-12 offset-md-1">
                          <div className="progress-wrapp max-width-card-380-center">
                            <BodhiJourney {...this.props} isInstaller={true} />

                            <div className="whtie-heading-accordion">
                              <div id="accordion">
                                <div className="card">
                                  <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                      <a
                                        href="#"
                                        className={`up-arra dashboard-heading mb-0 ${
                                          this.state.isSolarShow ? '' : 'collapsed'
                                        }`}
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                        onClick={() => {
                                          this.setState({
                                            isSolarShow: !this.state.isSolarShow
                                          });
                                        }}>
                                        <span>SHARE THE SUN</span>
                                        <span className="arrow-wrap pull-right">
                                          <span className="up-arrow">
                                            <i className="fa fa-angle-up" data-unicode="f106" />
                                          </span>
                                          <span className="down-arrow">
                                            <i className="fa fa-angle-down" data-unicode="f107" />
                                          </span>
                                        </span>
                                      </a>
                                    </h5>
                                  </div>
                                  <div
                                    id="collapseTwo"
                                    className={`collapse ${this.state.isSolarShow ? 'show' : ''}`}
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion">
                                    <div className="card-body map-bg">
                                      <Suspense fallback={suspenseFallback}>
                                        <SolarCommunity
                                          loggedInUser={loggedInUser}
                                          referralDetails={referralDetails}
                                          {...this.props}
                                          isInstaller={true}
                                        />
                                      </Suspense>
                                    </div>
                                  </div>
                                </div>
                                <div className="card margb30">
                                  <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                      <a
                                        href="#"
                                        className={`up-arra dashboard-heading mb-0 ${
                                          this.state.isGlanceShow ? '' : 'collapsed'
                                        }`}
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                        onClick={() => {
                                          this.setState({
                                            isGlanceShow: !this.state.isGlanceShow
                                          });
                                        }}>
                                        <span className="ho-setting-acc-heading">
                                          YOUR SYSTEM AT A GLANCE
                                        </span>
                                        <span className="arrow-wrap pull-right">
                                          <span className="up-arrow">
                                            <i className="fa fa-angle-up" data-unicode="f106" />
                                          </span>
                                          <span className="down-arrow">
                                            <i className="fa fa-angle-down" data-unicode="f107" />
                                          </span>
                                        </span>
                                      </a>
                                    </h5>
                                  </div>
                                  <div
                                    id="collapseOne"
                                    className={`card-body collapse paddt0 paddb0 ${
                                      this.state.isGlanceShow ? 'show' : ''
                                    }`}
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion">
                                    <Suspense fallback={suspenseFallback}>
                                      <SystemAtGlance
                                        location={this.props.router.location}
                                        installerDetails={this.props.installerDetails}
                                        systemGlance={systemGlance}
                                        loggedInUser={loggedInUser}
                                        cloudImages={cloudImages}
                                        setImages={setImages}
                                        setLoader={this.props.setLoader}
                                        isInstaller={true}
                                        {...this.props}
                                      />
                                    </Suspense>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 col-12 ">
                          <div className="tab-content max-width-card-400-center" id="myTabContent">
                            <div
                              className={
                                this.state.select === 'dashboard'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="dasboard"
                              role="tabpanel"
                              aria-labelledby="home-tab">
                              <HomeownerDashboard
                                {...this.props}
                                meetsPromoCriteria={this.props.meetsPromoCriteria}
                                isInstaller={true}
                              />
                            </div>
                            <div
                              className={
                                this.state.select === 'resource'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="resource"
                              role="tabpanel"
                              aria-labelledby="profile-tab">
                              <Suspense fallback={suspenseFallback}>
                                <ResourcesForm
                                  slideReset={() => {
                                    this.slideReset();
                                  }}
                                  companyDetail={this.props.systemGlance}
                                  systemGlance={this.props.systemGlance}
                                  setLoader={this.props.setLoader}
                                  isSlidesReset={this.state.isSlidesReset}
                                  isInstallerDashboard={true}
                                  loggedInUser={loggedInUser}
                                  homeOwnerId={this.props.homeOwnerId}
                                  resourceData={this.props.resourceData}
                                />
                              </Suspense>
                            </div>
                            <div
                              className={
                                this.state.select === 'faq'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="faq"
                              role="tabpanel"
                              aria-labelledby="contact-tab">
                              <Suspense fallback={suspenseFallback}>
                                <FAQ
                                  setLoader={this.props.setLoader}
                                  homeOwnerId={this.props.homeOwnerId}
                                />
                              </Suspense>
                            </div>
                            <div
                              className={
                                this.state.select === 'settings'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="settings"
                              role="tabpanel"
                              aria-labelledby="contact-tab">
                              <Suspense fallback={suspenseFallback}>
                                <Settings {...this.props} isInstaller={true} />
                              </Suspense>
                            </div>
                            <div
                              className={
                                this.state.select === 'document'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="document"
                              role="tabpanel"
                              aria-labelledby="contact-tab">
                              {this.state.select === 'document' ? (
                                <Suspense fallback={suspenseFallback}>
                                  <Documents
                                    {...this.props}
                                    documentTab={true}
                                    setHeight={() => {
                                      this.setHeight();
                                    }}
                                    isInstaller={true}
                                  />
                                </Suspense>
                              ) : (
                                ''
                              )}
                            </div>
                            <div
                              className={
                                this.state.select === 'contact'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="contact"
                              role="tabpanel"
                              aria-labelledby="contact-tab">
                              <Suspense fallback={suspenseFallback}>
                                <Contact
                                  installerDetails={this.props.installerDetails}
                                  homeOwnerId={this.props.homeOwnerId}
                                  loggedInUser={this.props.loggedInUser}
                                  isInstaller={true}
                                />
                              </Suspense>
                            </div>
                            <div
                              className={
                                this.state.select === 'systemGlance'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="systemGlance"
                              role="tabpanel"
                              aria-labelledby="contact-tab">
                              <Suspense fallback={suspenseFallback}>
                                <SystemAtGlance
                                  location={this.props.router.location}
                                  installerDetails={this.props.installerDetails}
                                  systemGlance={systemGlance}
                                  loggedInUser={loggedInUser}
                                  cloudImages={cloudImages}
                                  setImages={setImages}
                                  setLoader={this.props.setLoader}
                                  isInstaller={true}
                                  homeOwnerFileList={this.props.homeOwnerFileList}
                                />
                              </Suspense>
                            </div>
                            <div
                              className={
                                this.state.select === 'solarCommunity'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="solarCommunity"
                              role="tabpanel"
                              aria-labelledby="contact-tab">
                              <Suspense fallback={suspenseFallback}>
                                <SolarCommunity {...this.props} isInstaller={true} />
                              </Suspense>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="homeownerFooter">
                      {
                        <HomeownerFooter
                          clickHandler={this.setActiveItem.bind(this)}
                          setIcons={this.setIcons.bind(this)}
                          currentActive={this.state.select}
                        />
                      }
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    if (getToken()) {
      return this.renderDashboard();
    } else {
      this.props.router.navigate('/');
      window.location.reload();
      return null;
    }
  }
}

export default withRouter(Dashboard);
