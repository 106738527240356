import React from 'react';

import { trackCampaignClick } from '../../../campaign/api/campaign';
import { IHomeownerCampaignPreview } from './types';

/**
 * //////
 *
 * @params {Object} props
 * @return React FC
 */
export const HomeownerCampaignPreview: React.FC<IHomeownerCampaignPreview> = (props) => {
  const { headline, body, buttonUrl, buttonLabel, id, homeownerId } = props;

  return (
    <div className="campaignPreview left-content-box">
      <h3>{headline}</h3>
      {body &&
        body.split('\n').map((item: any, idx: number) => {
          return (
            <p className="homeowner" key={idx}>
              {item}
            </p>
          );
        })}
      {buttonLabel && (
        <a
          href={buttonUrl}
          target="_blank"
          onClick={() => {
            trackCampaignClick([
              {
                campaignId: id,
                event: 'app',
                homeownerId
              }
            ]);
          }}
          className="btn yellow-btn premiumButtonFill premiumButtonText">
          {buttonLabel || 'Click Here'}
        </a>
      )}
    </div>
  );
};

export default HomeownerCampaignPreview;
