import { call, put } from "redux-saga/effects";

import { setUtiliTyPlanByIdApi, setUtiliTyAndRatePlanByIdApi, getUtiliTyAndRatePlanByIdApi, getUtiliTyAndRatePlanApi, getHardwareDataApi, setInverterBrandApi, setModuleBrandApi, deleteModuleBrandApi, deleteInverterApi, deleteRatePlanApi, setSetElectricUtilityApi, deleteElectricUtilityApi} from "../../screens/Admin/Api/setting";
import { receiveSetUtilityByIdData, receiveSetUtilityRatePlanByIdData, receiveGetUtilityRatePlanByIdData, receiveGetUtilityRatePlanData, receiveGetHardwareData, receiveSetInverterData, receiveSetModuleData, receiveDeleteModuleData, receiveDeleteInverterData, receiveDeleteRatePlan, receiveSetElectricUtility, receiveDeleteElectricUtility} from "../../screens/Admin/Actions/setting";
import handleError                  from "../../shared/errorHandler";

function* harwareSaga(action) {
  try {
    // do api call
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(getHardwareDataApi, action.data);
    }
    yield put(receiveGetHardwareData(data));
  } catch (e) {
    handleError(e)
  }
}

function* getUtilityRatePlanSaga(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(getUtiliTyAndRatePlanApi, action.data);
    }
    yield put(receiveGetUtilityRatePlanData(data));
  } catch (e) {
    handleError(e)

  }
}

function* getUtilityRatePlanByIdSaga(action) {
  try {
    // do api call
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(getUtiliTyAndRatePlanByIdApi, action.data);
    }
    yield put(receiveGetUtilityRatePlanByIdData(data));
  } catch (e) {
    handleError(e)
  }
}

function* setUtilityRatePlanByIdSaga(action) {
  try {
    // do api call
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(setUtiliTyAndRatePlanByIdApi, action.data);
    }
    yield put(receiveSetUtilityRatePlanByIdData(data));
  } catch (e) {
    handleError(e)

  }
}

function* setUtilityByIdSaga(action) {
  try {
    // do api call
      let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(setUtiliTyPlanByIdApi, action.data);
    }
    yield put(receiveSetUtilityByIdData(data));
  } catch (e) {
    handleError(e)
  }
}



function* setInverterBrand(action) {
  try {
    // do api call
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(setInverterBrandApi, action.data);
    }
    yield put(receiveSetInverterData(data));
  } catch (e) {
    handleError(e)
  }
}


function* setModuleBrand(action) {
  try {
    // do api call
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(setModuleBrandApi, action.data);
    }
    yield put(receiveSetModuleData(data));
  } catch (e) {
    handleError(e)
  }
}

function* deleteModuleBrand(action) {
  try {
    // do api call
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(deleteModuleBrandApi, action.data);
    }
    yield put(receiveDeleteModuleData(data));
  } catch (e) {
    handleError(e)
  }
}

function* deleteInverterBrand(action) {
  try {
    // do api call
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
     data = yield call(deleteInverterApi, action.data);
    }
    yield put(receiveDeleteInverterData(data));
  } catch (e) {
    handleError(e)
  }
}

function* deleteRatePlan(action) {
  try {
    // do api call
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(deleteRatePlanApi, action.data);
    }
    yield put(receiveDeleteRatePlan(data));
  } catch (e) {
    handleError(e)
  }
}

function* setElectricUtility(action) {
  try {
    // do api call
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(setSetElectricUtilityApi, action.data);
    }
    yield put(receiveSetElectricUtility(data));
    data = yield call(getUtiliTyAndRatePlanApi, {});
    yield put(receiveGetUtilityRatePlanData(data));
  } catch (e) {
    yield put(receiveSetElectricUtility(e.response || { status: 400 }));
    handleError(e)
  }
}

function* deleteElectricUtility(action) {
  try {
    // do api call
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(deleteElectricUtilityApi, action.data);
    }
    yield put(receiveDeleteElectricUtility(data));
  } catch (e) {
    handleError(e)
  }
}


export const adminSetting = {
  harwareSaga,
  setInverterBrand,
  setModuleBrand,
  getUtilityRatePlanSaga,
  getUtilityRatePlanByIdSaga,
  setUtilityRatePlanByIdSaga,
  deleteModuleBrand,
  deleteInverterBrand,
  setUtilityByIdSaga,
  deleteRatePlan,
  setElectricUtility,
  deleteElectricUtility
}