export const REQUEST_REFERRAL_DETAILS =
	"REQUEST_REFERRAL_DETAILS";
export const RECEIVE_REFERRAL_DETAILS =
	"RECEIVE_REFERRAL_DETAILS";
export const REQUEST_REFERRAL_NEIGHBOUR = 
	"REQUEST_REFERRAL_NEIGHBOUR"
export const RECEIVE_REFERRAL_NEIGHBOUR =
	"RECEIVE_REFERRAL_NEIGHBOUR"
export const REQUEST_POST_REFERRAL_DETAILS =
	"REQUEST_POST_REFERRAL_DETAILS";
export const RECEIVE_POST_REFERRAL_DETAILS =
	"RECEIVE_POST_REFERRAL_DETAILS";
export const REQUEST_POST_ADDRESS_DETAILS =
	"REQUEST_POST_ADDRESS_DETAILS";	
export const RECEIVE_POST_ADDRESS_DETAILS =
	"RECEIVE_POST_ADDRESS_DETAILS";	
export const RECEIVE_POST_ADDRESS_FORM_DATA =
    "RECEIVE_POST_ADDRESS_FORM_DATA"