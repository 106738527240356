import { VariableResolutionException } from '../../exceptions';

/**
 * A mechansim to simplify creating standartd API URLs and avoid duplication and be DRY-ish
 *
 * @params {String} id === API subdomain
 * @returns {Function} a function that takes a subdomain with a curried ENV baked in
 */
export const apiBaseUrlFactory = (env) => (subdomain) => {
  const envs = { dev: true, uat: true, prod: true };
  if (!envs[env]) {
    throw new VariableResolutionException(`getApiBaseUrl env value doesn't exist - given ${env}`);
  }
  /**
   * Appears sub domain standard is 10 chars...
   */
  if (!subdomain || subdomain.length < 10) {
    throw new VariableResolutionException(
      `getApiBaseUrl id value isn't correct - given ${subdomain}`
    );
  }
  return `https://${subdomain}.execute-api.us-east-1.amazonaws.com/${env}/`;
};
