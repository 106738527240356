import { Services } from '../../../Api/services';
import * as config from '../../../shared/config';

import {
  INBOX_API_GET_UNREAD,
  INBOX_API_GET_MESSAGES,
  INBOX_API_POST_MESSAGE
} from '../../../constants/routes';
import { MESSAGES_DEFAULT_LIMIT } from '../../../constants';
import { ICreateMessageByThreadId, IFetchMessagesByThreadId } from './types';

/**
 * Fetches unread message counts for a sessioned user
 */
export const fetchUnreadCount = async () => {
  const url = `${config.INBOX_BASE_URL}${INBOX_API_GET_UNREAD}`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

/**
 * Fetches all messages for a given Inbox Thread
 */
export const fetchMessagesByThreadId = async (data: IFetchMessagesByThreadId) => {
  const { page, threadId, limit } = data;
  const setRoute = INBOX_API_GET_MESSAGES.valueOf().replace(':threadId', threadId);
  const url = `${config.INBOX_BASE_URL}${setRoute}?page=${page}&limit=${
    limit && limit > MESSAGES_DEFAULT_LIMIT ? limit : MESSAGES_DEFAULT_LIMIT
  }`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

/**
 * Create a message, i.e. "note", for a given Thread
 */
export const createMessageByThreadId = async (data: ICreateMessageByThreadId) => {
  const {
    senderId,
    senderType,
    recipientId,
    recipientType,
    subject,
    channel,
    attachments,
    threadId,
    message
  } = data;
  const setRoute = INBOX_API_POST_MESSAGE.valueOf().replace(':threadId', threadId);
  const url = `${config.INBOX_BASE_URL}${setRoute}`;
  const res = await Services.postCall(
    url,
    {
      senderId: String(senderId),
      senderType: String(senderType),
      recipientId: String(recipientId),
      recipientType: String(recipientType),
      subject,
      channel: String(channel),
      attachments,
      message
    },
    true
  );
  return { data: res.data, status: res.status };
};
