import React from 'react';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.css';
import App from './App';
import * as ServiceWorker from './serviceWorker';

// declare productSuite as  global variable with default value for BODHI-CORE
window.$productSuite = 3;

if (process.env.NODE_ENV === 'development' && !!process.env.API_MOCK) {
  const { worker } = require('./mocks/browser');
  worker.start();
  /**
   * render app after invoking `msw`
   */
  ReactDOM.render(<App />, document.getElementById('root'));
} else {
  /**
   * render app before invoking prd service worker
   */
  ReactDOM.render(<App />, document.getElementById('root'));
  ServiceWorker.register({
    onUpdate: (registration) => {
      const waitingServiceWorker = registration.waiting;
      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', (event) => {
          if (event.target.state === 'activated') {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    }
  });
}
