import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Menu, Dropdown } from 'antd';
import withRouter from '../../../../shared/withRouter';
import { jwtDecode } from '../../../../shared/jwt';
import logoutImg from '../../../../Assets/Images/logout.svg';
import { AdminReport } from '../Report/Report';
import { JSONToCSVConvertor } from '../../../../shared/jsontoCSV.ts';
import { openNotificationWithIcon } from '../../../../shared/notification';
import { SearchInterface } from '../../../Common/Search/SearchInterface';

const Option = AutoComplete.Option;

class HeaderComponent extends React.Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.adminData = {};
    this.state = {
      adminReportPopUp: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reportData && nextProps.reportData.status === 200) {
      nextProps.recieveAdminReportApiData({});
      this.props.setLoader({ loader: false });
      const jsonToCSVConvertor = new JSONToCSVConvertor();
      let data = nextProps.reportData.data;
      if (data && data.length > 0) {
        jsonToCSVConvertor.convertData(data, this.state.reportName, true);
      } else {
        openNotificationWithIcon(
          'error',
          'No  data is available for selected date range.',
          'Error'
        );
      }
    }
  }

  componentWillMount() {
    const token = localStorage.getItem('token');
    this.adminData = jwtDecode(token);
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    let expTime = this.adminData && this.adminData.exp ? Number(`${this.adminData.exp}000`) : 0;
    const remainingTime = expTime - new Date().getTime() - 60000;
    if (!expTime || new Date().getTime() > expTime || remainingTime < 1) {
      this.signOut();
    } else {
      const timeInterval = localStorage.getItem('timeInterval');
      if (timeInterval) {
        clearInterval(timeInterval);
      }
      this.countdown = setInterval(() => {
        this.refreshToken(token);
      }, remainingTime);
      localStorage.setItem('timeInterval', this.countdown);
    }
  }

  reportHandler = (values) => {
    let reportName;
    switch (values.reportType) {
      case 1:
        reportName = 'Homeowner report';
        break;
      case 2:
        reportName = 'Referral report';
        break;
      case 3:
        reportName = 'Insight report';
        break;
      default:
        reportName = 'Homeowner report';
        break;
    }
    this.updateState({ adminReportPopUp: false, reportName });
    this.props.requestAdminReportApiData({
      reportType: values.reportType,
      startDate: values.startDate.format('YYYY-MM-DD'),
      endDate: values.endDate.format('YYYY-MM-DD')
    });
  };

  updateState = (obj) => {
    this.setState(obj);
  };

  onSelect = (option, type) => {
    if (type === 'homeowner') {
      let prop = JSON.parse(option.props.value);
      this.props.router.navigate(`/${prop.slug}/${prop.companyId}/dashboard/${prop.id}/homeowner`);
    } else this.props.router.navigate(`/admin/${option.key}/companysetting`);
  };

  refreshToken = async (token) => {
    const { adminData, auth } = this.props;

    let expTime = this.adminData && this.adminData.exp ? Number(`${this.adminData.exp}000`) : 0;
    if (Date.now() > expTime) {
      if (adminData && adminData.data && adminData.data.email) {
        return;
      }
    }
  };

  updatePage = (path) => {
    this.props.router.navigate(path);
  };

  signOut = () => {
    this.props.signOut();
    localStorage.clear();
    this.props.router.navigate('/');
  };

  getToken() {
    return localStorage.getItem('token');
  }

  render() {
    let pathname = this.props.router.location.pathname;

    const menu = (
      <Menu>
        <Menu.Item key="0">
          <span style={{ cursor: 'pointer' }} className="dropdown-item" onClick={this.signOut}>
            <span className="drop-icon">
              <img alt="logout_icon" src={logoutImg} />
            </span>
            <span>Logout</span>
          </span>
        </Menu.Item>
      </Menu>
    );

    if (this.getToken()) {
      return (
        <React.Fragment>
          {this.state.adminReportPopUp && (
            <AdminReport updateState={this.updateState} reportHandler={this.reportHandler} />
          )}
          <div className="nav-header-wrapper dashboard-nav-wrapper fixed-top">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <span
                className="navbar-brand default"
                onClick={() => this.props.router.navigate('/admin/dashboard')}
              />
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="mr-auto display-none"> </ul>
                <div className="my-2 my-lg-0">
                  <div>
                    <ul className="navbar-nav">
                      <li
                        className="nav-item admin-option"
                        onClick={() => this.updatePage('/admin/utility')}>
                        <a className={`nav-link ${pathname.includes('utility') ? 'active' : ''}`}>
                          <i className="fa fa-wrench admin-icon" aria-hidden="true"></i>
                          utilities
                        </a>
                      </li>
                      <li
                        className="nav-item admin-option"
                        onClick={() => this.updatePage('/admin/map')}>
                        <a className={`nav-link ${pathname.includes('map') ? 'active' : ''}`}>
                          <i className="fa fa-map-marker admin-icon" aria-hidden="true"></i>
                          map
                        </a>
                      </li>
                      <li
                        className="nav-item admin-option"
                        onClick={() => this.updateState({ adminReportPopUp: true })}>
                        <a className={`nav-link ${pathname.includes('report') ? 'active' : ''}`}>
                          <i className="fa fa-file-text-o admin-icon" aria-hidden="true"></i>
                          reports
                        </a>
                      </li>

                      <li className="nav-item dashboar-search" style={{ marginTop: '12px' }}>
                        <SearchInterface
                          config={{ scope: 'admin', type: 'homeowner' }}
                          handleSelectResult={this.onSelect}
                          resultFactory={(item, index) => {
                            return (
                              <Option key={item.id} value={JSON.stringify(item)}>
                                {`${item.firstName} ${item.lastName} - ${item.email}`}
                              </Option>
                            );
                          }}
                        />
                      </li>

                      <li
                        className="nav-item dashboar-search"
                        style={{ width: '220px', marginTop: '12px' }}>
                        <SearchInterface
                          config={{ scope: 'admin', type: 'company' }}
                          handleSelectResult={this.onSelect}
                          placeholder="Search companies"
                          resultFactory={(item, index) => {
                            return (
                              <Option key={item.companyId} value={item.Company}>
                                {item.Company}
                              </Option>
                            );
                          }}
                        />
                      </li>

                      <li
                        className="nav-item nav-add-link"
                        onClick={() => {
                          this.props.router.navigate('/admin/addcompany');
                        }}>
                        <a className={`nav-link addhomeowner`}>
                          <span>Add company</span>
                        </a>
                      </li>

                      <li className="nav-item admin-option">
                        <Dropdown overlay={menu} trigger={['click']}>
                          <span className="dashboard-profile-name">
                            {this.adminData && this.adminData.email}
                          </span>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(HeaderComponent);
