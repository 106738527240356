import { HttpRequestException } from '../exceptions';

type THttpErrorValue = {
  status: number;
  error: Error;
};

interface IHttpErrorResponse {
  error: Error;
  getValue: () => THttpErrorValue;
}

/**
 * A concern to encapsulate handling failed HTTP requests
 */
class FailedHttpRequest implements IHttpErrorResponse {
  _status: number = 0;
  _error: Error;

  constructor(requestName: string, error?: Error, status?: number) {
    this._status = status || 500;
    this._error =
      error || new HttpRequestException(`There was an error making request "${requestName}"`);
  }

  get error() {
    return this._error;
  }

  /**
   * Interface for handling request details
   */
  getValue() {
    return {
      status: this._status,
      error: this._error
    };
  }
}

export default FailedHttpRequest;
