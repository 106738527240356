import moment from 'moment';

/**
 * Helper function to present a contextual greeting, based on user's "local" time
 *
 * @returns {String}
 */
export const generateTimeBasedGreeting = () => {
  const currentHour = parseInt(moment().format('HH'));
  if (currentHour >= 2 && currentHour < 12) {
    return 'Good morning';
  } else if (currentHour >= 12 && currentHour < 17) {
    return 'Good afternoon';
  } else if (currentHour >= 17 || currentHour < 2) {
    return 'Good evening';
  } else {
    return 'Hello';
  }
};
