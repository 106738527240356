// import npm packages
import { combineReducers } from "redux";

// import local files
import {
    RECEIVE_PHASE_SURVEY,
} from "../../types/statusFeed";

const statusFeedPhaseSurvey = (state = {}, { type, data }) => {
    switch (type) {
        case RECEIVE_PHASE_SURVEY:
            return data;
        default:
            return state;
    }
};

export const reducer = combineReducers({
    statusFeedPhaseSurvey
});