import styled from 'styled-components';

export const StyledDecorator = styled.div`
  box-sizing: border-box;
  height: 101px;
  width: 351px;
  border: 1px solid ${(props) => props.theme.colors.greyBorder};
  border-radius: ${(props) => props.theme.borderRadius.standard};
  background-color: ${(props) => props.theme.colors.white};

  .editor {
    .ant-input {
      resize: none;
      height: 90px;
      border-bottom: ${(props) => props.theme.colors.white} !important;
    }

    .ant-input::placeholder {
      color: ${(props) => props.theme.colors.ltgrey4};
      font-size: ${(props) => props.theme.fonts.sizes.font14};
      line-height: 17px;
      padding-top: ${(props) => props.theme.padding.padd7};
      padding-left: ${(props) => props.theme.padding.padd10};
    }
  }

  /* Media query styles */
  @media only screen /* smartphones */ and (max-width: ${(props) =>
      props.theme.breakpoints.sm.up}px) {
    width: 100%;
  }
  @media only screen /* tablets */ and (max-width: ${(props) => props.theme.breakpoints.xl.up}px) {
    width: 100%;
  }
`;

export const StyledDiv = styled.div`
  display: inline;

  .send-btn-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .contact-wrapper-date {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    padding-bottom: ${(props) => props.theme.padding.padd7};
  }

  .homeowner-msg-info {
    display: flex;
    align-items: center;
  }

  .homeowner-char-count {
    color: ${(props) => props.theme.colors.ltgrey4};
    padding-top: ${(props) => props.theme.padding.padd20};
    .invalid {
      color: ${(props) => props.theme.colors.red};
    }
  }

  .homeowner-error-message {
    padding-top: ${(props) => props.theme.padding.padd20};
    color: ${(props) => props.theme.colors.ltgrey4};
    padding-left: ${(props) => props.theme.padding.padd10};
  }

  .homeowner-tooltip-info {
    width: 14px;
    color: ${(props) => props.theme.colors.ltgrey4};
    margin-left: ${(props) => props.theme.margin.marg10};
    cursor: pointer;
  }
`;

export const StyledSendBtn = styled.button`
  font-size: ${(props) => props.theme.fonts.sizes.font14};
  color: ${(props) => props.theme.colors.blackMed};
  border-radius: ${(props) => props.theme.borderRadius.standard} !important;
  background-color: ${(props) => props.theme.colors.yellow};
  height: 40px;
  width: 150px;
  border: none;
  font-weight: bold;

  :hover {
    background-color: ${(props) => props.theme.colors.yellow100};
  }

  :disabled {
    background-color: ${(props) => props.theme.colors.greyMed};
    color: ${(props) => props.theme.colors.ltgrey4};
  }
`;
