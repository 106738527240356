import React from "react";
import { DatePicker, Select, Modal, Form } from "antd";

const { Option } = Select;
const FormItem = Form.Item;
export class AdminReport extends React.Component {
    constructor(props) {
        super(props);
    }
    renderReferralReport = () => {
        return (
            <Form onFinish={this.props.reportHandler}>
                <div>
                    <h2 className="mb-5"> Bodhi reporting</h2>
                    <FormItem
                        hasFeedback
                        name="reportType"
                        rules={[
                            {
                                required: true
                            }
                        ]}
                    >
                        <Select
                            className="dropdown_widthPM"
                            placeholder="Select report type"
                        >
                            <Option value={1} key="homeowner">Homeowner report</Option>
                            <Option value={2} key="referral">Referral report</Option>
                            <Option value={3} key="insight">Insight report</Option>
                        </Select>
                    </FormItem>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="cmn-label-field-input">
                                <div className="form-group cmn-form-grp-input">
                                    <label className="lbl-cmn-field">Start date</label>
                                    <FormItem
                                        hasFeedback
                                        name="startDate"
                                        rules={[
                                            {
                                                required: true
                                            }
                                        ]}
                                    >
                                        <DatePicker placeholder='Start date' />
                                    </FormItem>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="cmn-label-field-input">
                                <div className="form-group cmn-form-grp-input">
                                    <label className="lbl-cmn-field">End date </label>
                                    <FormItem
                                        hasFeedback
                                        name="endDate"
                                        rules={[
                                            {
                                                required: true
                                            }
                                        ]}
                                    >
                                        <DatePicker placeholder='End date' />
                                    </FormItem>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-submitted-btn">
                        <button type="submit" className="btn btn-primary">Fetch report</button>
                    </div>
                </div>
            </Form>
        )
    }

    render() {
        if (localStorage.getItem("token")) {
            return (
                <Modal
                    width={this.props.popWidth ? this.props.popWidth : 520}
                    visible={true}
                    className="add-utility popUp"
                    footer={null}
                    onCancel={() => {
                        this.props.updateState({ 'adminReportPopUp': false });
                    }}
                >
                    {this.renderReferralReport()}
                </Modal>
            )
        } else {
            this.props.router.navigate("/");
            return null;
        }
    }
}