import {
  REQUEST_HOMEOWNER_SYSTEM_GLANCE,
  RECEIVE_HOMEOWNER_SYSTEM_GLANCE,
  REQUEST_HOMEOWNER_CAMPAIGN,
  RECEIVE_HOMEOWNER_CAMPAIGN
} from "../types/dashboard/dashboard";

export const requestHomeownerSystemGlance = data => ({
	type: REQUEST_HOMEOWNER_SYSTEM_GLANCE, data
});

export const receiveHomeownerSystemGlance = data => ({
	type: RECEIVE_HOMEOWNER_SYSTEM_GLANCE, data
});

export const requestHomeownerCampaignData = data => ({
	type: REQUEST_HOMEOWNER_CAMPAIGN, data
});

export const receiveHomeownerCampaignData = data => ({
	type: RECEIVE_HOMEOWNER_CAMPAIGN, data
});