import React, { Suspense, useState, useEffect } from 'react';
import { Composer, WithLoader } from '../../../Common';
import { StyledDecorator, StyledDiv, StyledSendBtn } from './HomeownerMessageComposer.style';
import { Tooltip } from 'antd';
import { infoIconSvg } from '../../../../constants/icons';

const BodhiTooltip = React.lazy(() => import('../../../Common/BodhiTooltip'));

import { IHomeownerMessageComposer } from './types';
import { suspenseFallback } from '../../../../constants/components';

import { createMessageByThreadId } from '../../../../store/InboxSlice';
import {
  TMessageComposition,
  TMessagesApiResponse
} from '../../../Installer/container/inbox/types';
import store from '../../../../store';
import {
  ParticipantType,
  messageChannelIdentifiers
} from '../../../Installer/components/inbox/constants';
import { openNotificationWithIcon } from '../../../../shared/notification';
import { checkIsMobile } from '../../../../shared/checkIsMobile';

/**
 * Similar to installer message composer, handle calls to persist messages
 */
const handleCreateMessage = (params: TMessageComposition) => {
  return store.dispatch(createMessageByThreadId(params));
};

const infoText = 'Character limit for messages are 300 characters.';

const ComposerWithLoader = WithLoader(Composer);

export const HomeownerMessageComposer: React.FC<IHomeownerMessageComposer> = (props) => {
  const { senderId, recipientId, threadId } = props;
  const [messageText, setMessageText] = useState<string>('');
  const [addingMessage, setAddingMessage] = useState<any>({ channel: undefined });
  const [loading, setLoading] = useState<boolean>(false);

  const checkError = () => {
    if (messageText.length > 300) {
      return true;
    }
  };

  const charCount = messageText.length;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    setMessageText(text);
  };

  useEffect(() => {
    if (addingMessage.channel !== undefined) {
      if (messageText) {
        if (!recipientId) {
          console.error("There was a problem sending the message: 'recipientId' not resolvable");
        }
        setLoading(true);
        handleCreateMessage({
          senderId: senderId,
          senderType: ParticipantType.HOMEOWNER,
          recipientId: recipientId,
          recipientType: ParticipantType.INSTALLER,
          message: messageText,
          subject: '',
          channel: messageChannelIdentifiers.app,
          attachments: [],
          threadId: threadId
        }).then((res: TMessagesApiResponse) => {
          setAddingMessage({ channel: undefined });
          setMessageText('');
          setLoading(false);
          openNotificationWithIcon('success', 'Message sent');
        });
      }
    }
  }, [addingMessage, messageText]);

  return (
    <StyledDiv>
      <div className="homeowner-text-area">
        {checkIsMobile() ? (
          <Composer
            decorator={StyledDecorator}
            placeholder="Send a message to your project manager"
            editHandler={handleChange}
            content={messageText}
          />
        ) : (
          <ComposerWithLoader
            loading={loading}
            tipText="Sending message..."
            decorator={StyledDecorator}
            placeholder="Send a message to your project manager"
            editHandler={handleChange}
            content={messageText}
          />
        )}
        <div className="homeowner-msg-info">
          <div className="homeowner-char-count">
            <span className={`${charCount >= 300 ? 'invalid' : ''}`}>
              {charCount > 300 ? `-${charCount - 300}` : charCount}
            </span>
            /300
          </div>
          {checkError() && (
            <div className="homeowner-error-message">
              Please shorten message.
              <Tooltip
                overlayClassName="standard-tooltip"
                placement="right"
                style={{ height: '100%' }}
                title={
                  <Suspense fallback={suspenseFallback}>
                    <BodhiTooltip type="with-icon" text={infoText} />
                  </Suspense>
                }>
                <img className="homeowner-tooltip-info" src={infoIconSvg} />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className="send-btn-wrapper">
        <StyledSendBtn
          disabled={checkError() || loading}
          onClick={() => setAddingMessage({ channel: messageChannelIdentifiers.app })}>
          {loading ? 'Sending...' : 'Send'}
        </StyledSendBtn>
      </div>
    </StyledDiv>
  );
};

export default HomeownerMessageComposer;
