import React from 'react';
import styled from 'styled-components';

import { CUSTOMIZED_LINKBGCOLOR_CLASS } from '../../../../constants';
import { IMessageStatus } from './types';

const customMsgStatusClassname = CUSTOMIZED_LINKBGCOLOR_CLASS;

const UnreadStatusDecorator = styled.span`
  &.status {
    border-radius: ${(props) => props.theme.borderRadius.round};
    width: 10px;
    height: 10px;
    display: inline-block;
    position: absolute;
    top: -1px;
    right: -1px;
  }
  &.default-indicator {
    background-color: ${(props) => props.theme.colors.orange};
  }
`;

/**
 * Indicates an unread message optionally using the installer brand color styles
 */
export const MessageStatus: React.FC<IMessageStatus> = (props) => {
  const { unread, children, allowInstallerCustom, className } = props;
  if (!unread) {
    return null;
  }
  const classNames = ['status'];
  if (!!allowInstallerCustom) {
    classNames.push(customMsgStatusClassname + ' ' + className);
  } else {
    classNames.push('default-indicator' + ' ' + className);
  }
  return <UnreadStatusDecorator className={classNames.join(' ')}>{children}</UnreadStatusDecorator>;
};
