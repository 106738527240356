import phaseInfoJSON from '../../../Common/phaseInfo.json';
import { ISystemDetailsHelper } from './types';

const resolveUtilityName = (utilityName: string, utilityLabel: string) => {
  return utilityLabel || utilityName;
};

/**
 * Parse out system details
 *
 * @params {Object} data
 * @return {String}
 */
export const systemDetailsHelper = (data: ISystemDetailsHelper) => {
  const { loggedInUser, systemGlance, phase } = data || {};
  const { solarGlance, phaseGlance } = systemGlance || {};

  if (!phase || !solarGlance || !loggedInUser) {
    return '';
  }

  const utilityName = resolveUtilityName(solarGlance.utilityName, solarGlance.utilityLabel);

  if (loggedInUser && loggedInUser?.solarUnit?.permitMessage && phase === '2')
    return loggedInUser.solarUnit.permitMessage
      .replace(/{AHJ}/g, loggedInUser.solarUnit.ahj)
      .replace(/{UTILITY}/g, utilityName);

  let text = '';
  let days = 'some';

  switch (parseInt(phase)) {
    case 1:
      days = phaseGlance.DesignPhaseCutOff;
      text = phaseGlance.DesignDetail || phaseInfoJSON.DesignDetail;
      break;
    case 2:
      days = phaseGlance.PermittingPhaseCutOff;
      text = phaseGlance.PermittingDetail || phaseInfoJSON.PermittingDetail;
      break;
    case 3:
      days = phaseGlance.BuildPhaseCutOff;
      text = phaseGlance.BuildDetail || phaseInfoJSON.BuildDetail;
      break;
    case 4:
      days = phaseGlance.InspectionPhaseCutOff;
      text = phaseGlance.InspectionDetail || phaseInfoJSON.InspectionDetail;
      break;
    case 5:
      days = phaseGlance.PoweringUpPhaseCutOff;
      text = phaseGlance.PoweringUpDetail || phaseInfoJSON.PoweringUpDetail;
      break;
    case 6:
      text = phaseGlance.PoweringUpDetail || '';
    default:
      text = '';
  }

  return text.replace(/{UTILITY}/g, utilityName).replace(/{PHASE_CUTTOFF_DAYS}/g, days);
};

export default systemDetailsHelper;
