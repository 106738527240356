export const REQUEST_EGAUGE_POWER_METER = "REQUEST_EGAUGE_POWER_METER";
export const RECEIVE_EGAUGE_POWER_METER = "RECEIVE_EGAUGE_POWER_METER";

export const REQUEST_HUAWEI_ID = "REQUEST_HUAWEI_ID";
export const RECEIVE_HUAWEI_ID = "RECEIVE_HUAWEI_ID";

export const requestHuaweiId = data => ({
	type: REQUEST_HUAWEI_ID, data
});

export const receiveHuaweiId = data => ({
	type: RECEIVE_HUAWEI_ID, data
});

export const requestEgaugePowerMeter = data => ({
	type: REQUEST_EGAUGE_POWER_METER, data
});

export const receiveEgaugePowerMeter = data => ({
	type: RECEIVE_EGAUGE_POWER_METER, data
});
