import React, { useMemo, useState, useEffect } from 'react';

import { Layout, Menu, Popover, Button } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { findMenuElementByKey, installerRole } from '../../../../constants';

import { alertIconSvgBlack } from '../../../../constants/icons';
import { withRouter, routeFactory, resolveRoute } from '../../../../shared';
import { BodhiPopover, Counter } from '../../../Common';
import { StyledSiderMenuMain, MainNavCountDecorator } from '.';

import { ReactComponent as InboxIcon } from '../../../../Assets/Images/inbox_icon.svg';
import { ReactComponent as InboxIconActive } from '../../../../Assets/Images/inbox_iconActive.svg';
import { ReactComponent as FleetIcon } from '../../../../Assets/Images/fleet_icon.svg';
import { ReactComponent as FleetIconActive } from '../../../../Assets/Images/fleet_iconActive.svg';
import { ReactComponent as InsightsIcon } from '../../../../Assets/Images/insights_icon.svg';
import { ReactComponent as InsightsIconActive } from '../../../../Assets/Images/insights_iconActive.svg';
import { ReactComponent as TeamIcon } from '../../../../Assets/Images/team_icon.svg';
import { ReactComponent as TeamIconActive } from '../../../../Assets/Images/team_iconActive.svg';
import { ReactComponent as CampaignIcon } from '../../../../Assets/Images/campaign_icon.svg';
import { ReactComponent as CampaignIconActive } from '../../../../Assets/Images/campaign_icon_Active.svg';
import { ReactComponent as CompanySettingsIcon } from '../../../../Assets/Images/copmanySettings_icon.svg';
import { ReactComponent as CompanySettingsIconActive } from '../../../../Assets/Images/copmanySettings_iconActive.svg';
import { productsMenu } from '../../../../constants/routes';

const { Sider } = Layout;

const InlineCounter = (props) =>
  props.parentId === 'Inbox' ? (
    <Counter number={props.count} decorator={MainNavCountDecorator} />
  ) : null;

const iconClasses = 'sider-icon';
const activeIconClasses = `${iconClasses}`;

const mainNavigation = (props) => {
  const [inboxCount, setInboxCount] = useState(0);
  const { role, router } = props;

  const [searchParams] = router.searchParams;

  const { slug } = router.params;
  const { companyId } = router.params;

  const { installerData, companyDetail, totalUnread } = useSelector((state) => {
    return {
      installerData: state?.header?.getInstaller || {},
      companyDetail: state.setting?.companyDetail?.data?.companyDetail || {},
      totalUnread: state.inboxMessages.counts.inbox
    };
  });

  useEffect(() => {
    if (inboxCount !== totalUnread) {
      setInboxCount(totalUnread);
    }
  }, [totalUnread]);

  const isProfileCreated = installerData?.data?.isProfileCreated;

  const installerMainMenuModel = useMemo(() => {
    if (companyDetail) {
      const { productSuite } = companyDetail;

      if (productSuite) {
        return productsMenu[productSuite];
      }
    }
  }, [companyDetail]);

  const localMenuModel = installerMainMenuModel;

  const settingsIndex = findMenuElementByKey('companysettings', localMenuModel).index;
  if (settingsIndex > -1 && role === installerRole && localMenuModel) {
    // remove "settings" for non-admin installer identity
    localMenuModel.splice(settingsIndex, 1);
  }

  const route = resolveRoute(router.location.pathname, router.params);

  const selectedKeys = [routeFactory(slug, companyId, route)];

  const renderResourceImg = (item) => {
    switch (item) {
      case 'Inbox':
        if (route === 'inbox') return <InboxIconActive className={activeIconClasses} />;
        return <InboxIcon className={iconClasses} />;
      case 'Fleet':
        if (route === 'fleet/dashboard') return <FleetIconActive className={activeIconClasses} />;
        return <FleetIcon className={iconClasses} />;
      case 'Campaigns':
        if (route === 'campaign/dashboard')
          return <CampaignIconActive className={activeIconClasses} />;
        return <CampaignIcon className={iconClasses} />;
      case 'Insights':
        /**
         * Note: insights redirects, so the URL has been appended with a flag to ensure we highlight following redirection
         */
        if (route === 'insights' || searchParams.get('from') === 'insights')
          return <InsightsIconActive className={activeIconClasses} />;
        return <InsightsIcon className={iconClasses} />;
      case 'Settings':
        if (route === 'companysetting')
          return <CompanySettingsIconActive className={activeIconClasses} />;
        return <CompanySettingsIcon className={iconClasses} />;
      case 'Team':
        if (route === 'team') return <TeamIconActive className={activeIconClasses} />;
        return <TeamIcon className={iconClasses} />;
    }
  };

  return (
    <StyledSiderMenuMain>
      <Sider trigger={null} className="bodhi-sider-main" width="100">
        <div className="dashboard-nav-wrapper">
          {isProfileCreated ? (
            <a
              className="navbar-brand installerView"
              onClick={() => {
                router.navigate(routeFactory(slug, companyId, 'dashboard'));
              }}
            />
          ) : (
            <a className="navbar-brand installerView" />
          )}
        </div>

        <Menu
          theme="dark"
          className="main-navigation-root left-menu"
          mode="inline"
          inlineIndent="0"
          collapsed="true"
          selectedKeys={selectedKeys}>
          {localMenuModel &&
            localMenuModel.map((ele, index) => {
              let classes = route === ele.pathname ? 'siderActive' : '';
              if (ele.customClasses) {
                classes += ` ${ele.customClasses}`;
              }
              return (
                <Menu.Item
                  className={classes}
                  key={ele.display}
                  icon={renderResourceImg(ele.display)}>
                  {inboxCount !== undefined && InlineCounter && (
                    <InlineCounter count={inboxCount} parentId={ele.display} />
                  )}
                  <Link
                    onClick={ele.onClick || null}
                    to={ele.pathname ? routeFactory(slug, companyId, ele.pathname) : '#'}>
                    {ele.display}
                  </Link>
                </Menu.Item>
              );
            })}
        </Menu>
      </Sider>
    </StyledSiderMenuMain>
  );
};

export default withRouter(mainNavigation);
