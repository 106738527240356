/**
 * A lookup table for CSS-in-JS
 *
 * xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px
 */
const breakpoints = {
  xs: {
    up: 0
  },
  sm: {
    up: 576
  },
  md: {
    up: 768
  },
  lg: {
    up: 992
  },
  xl: {
    up: 1200
  },
  xxl: {
    up: 1400
  }
};

const defaultTransition = '0.66s';

const configs = {
  inboxHomeownerDetailWidthOpen: '325px',
  inboxInstallerTransition: `all ${defaultTransition} ease-in-out`,
  composeInboxInstallerTransition: (properties) => {
    return `${properties} ${defaultTransition} ease-in-out`;
  }
};

const borderRadius = {
  faint: '3px',
  standard: '10px',
  round: '100%',
  rad08: '8px'
};

const bgColors = {
  lightYellow: '#fffdf3',
  yellow50: '#fff6d9'
};

/**
 * Colors and/or conversions that can be used in css-in-js
 */
const colors = {
  yellow: '#ffd110',
  yellow100: '#FFDB40',
  black: '#000',
  blackMed: '#00142d',
  /**
   * Handles RGBA values for `blackMed` hex value that take an opacity argument
   *
   * @param {String|Number}
   */
  blackMedRgba: (opacity) => {
    const o = Math.abs(Number(opacity) || parseInt(opacity));
    let opac = o;
    if (o > 1) {
      opac = o > 9 ? o / 100 : o / 10;
    }
    return `rgba(0, 20, 45, ${opac.toFixed(2)})`;
  },
  blackLight: '#212529',
  grey: '#4a4a4a',
  greyLight: '#f4f7fa',
  greyMed: '#cccccc',
  greyBorder: '#dfe6ee',
  labelborder: '#e9e9e9',
  labelcolor: '#9b9b9b',
  yellowbg: '#ffcc00',
  error: '#d0021b',
  pmbackground: '#f5f8fb',
  ltblue: '#33ccff',
  yell2: '#f5a623',
  green: '#7ed321',
  moff: '#9013fe',
  moff2: '#bd10e0',
  ltgrey: '#F2F6F9',
  ltgrey1: '#c3d0dc',
  ltgrey2: '#aeb3c0',
  ltgrey3: '#e0e0e0',
  ltgrey4: '#7a7a7a',
  ltgrey5: '#d8d8d8',
  blackbrd: 'rgba(0, 0, 0, 0.12)',
  black8: 'rgba(0, 0, 0, 0.87)',
  blue3: '#22c0fc',
  grey4: '#979797',
  grey5: '#f9f9f9',
  grey6: '#fbfbfb',
  greyBoxshadow: '#d8dde2',
  white: '#FFFFFF',
  red: '#d8360a',
  orange: '#FF6F47'
};

const padding = {
  padd0: '0px',
  padd2: '2px',
  padd3: '3px',
  padd4: '4px',
  padd5: '5px',
  padd6: '6px',
  padd7: '7px',
  padd8: '8px',
  padd10: '10px',
  padd11: '11px',
  padd12: '12px',
  padd15: '15px',
  padd16: '16px',
  padd20: '20px',
  padd25: '25px',
  padd28: '28px',
  padd30: '30px',
  padd35: '35px',
  padd40: '40px',
  padd44: '44px',
  padd48: '48px',
  padd45: '45px',
  padd50: '50px',
  padd55: '55px',
  padd60: '60px',
  padd70: '70px',
  padd72: '72px',
  padd75: '75px',
  padd80: '80px',
  padd85: '85px',
  padd90: '90px',
  padd100: '100px'
};

const margin = {
  marg0: '0px',
  marg2: '2px',
  marg3: '3px',
  marg5: '5px',
  marg6: '6px',
  marg8: '8px',
  marg11: '11px',
  marg12: '12px',
  marg15: '15px',
  marg16: '16px',
  marg17: '17px',
  marg10: '10px',
  marg20: '20px',
  marg23: '23px',
  marg25: '25px',
  marg28: '28px',
  marg30: '30px',
  marg35: '35px',
  marg40: '40px',
  marg50: '50px',
  marg60: '60px',
  marg64: '64px',
  marg70: '70px',
  marg75: '75px',
  marg80: '80px',
  marg86: '86px',
  marg90: '90px',
  marg100: '100px',
  marg120: '120px',
  marg125: '125px'
};

const fonts = {
  standardBold: 'HarmoniaSansProCyrBold',
  standardRegular: 'HarmoniaSansProCyrRegular',
  standardLight: 'HarmoniaSansProCyrLight',
  roboto: '"Roboto", sans-serif',
  montserrat: '"Montserrat",sans-serif',
  sizes: {
    font45: '2.8125rem', // 45
    font24: '1.5rem', // 24 px
    font18: '1.125rem', // 18px
    font17: '1.0625rem', // 17px
    font22: '1.375rem', // 22px
    font16: '1rem', // 16px
    font14: '0.875rem', // 14px
    font15: '0.9375rem', // 15px
    font26: '1.625rem', // 26 px
    font20: '1.25rem', // 20 px
    font30: '1.875rem', // 30 px
    font12: '0.75rem', // 12px
    font35: '2.1875rem', // 35
    font22: '1.375rem', // 22
    font13: '0.8125rem', // 13px
    font50: '3.125rem',
    font60: '60px'
  }
};

export default { padding, margin, breakpoints, colors, fonts, borderRadius, bgColors, configs };
