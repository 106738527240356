// import npm packages
import { call, put } from "redux-saga/effects";

// import local files
import { postHomeownerForgotPassword } from "../../screens/homeowner/api/forgotPassword";
import handleError from "../../shared/errorHandler";
import { setLoader } from "../../screens/Common/Loader/action";
import { receivePostForgotPassword } from "../../screens/homeowner/actions/forgetPasswordAction";

function* postForgotPassword(action) {
	try {
        const data = yield call(postHomeownerForgotPassword, action.data);
		yield put(setLoader({ loader: false }));
		 yield put(receivePostForgotPassword(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

export const homeownerForgetPassword = {
	postForgotPassword
};