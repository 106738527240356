import styled from "styled-components";
import { addCloud } from "../../../../constants/icons";

export const StyledButton = styled.button`
    padding: ${(props) => props.theme.padding.padd10};
    font-size: ${(props) => props.theme.fonts.sizes.font14};
    width: 150px;
    height: 40px;
    border-radius: ${(props) => props.theme.borderRadius.standard} !important;
    border: 1px solid ${(props) => props.theme.colors.yellow};
    font-weight: bold;
    text-align: center;
    color: ${(props) => props.theme.colors.blackMed};
    letter-spacing: 0.26px;
    background-color: none;
    bottom: 100px;
    position: absolute;
`;

export const DownloadBtn = styled(StyledButton)`
    left: 135px;
    display: inline-block;
    background-color: ${(props) => props.theme.colors.white};
    
    :hover {
        background-color: ${(props) => props.theme.colors.yellow};
    }
    .download-csv {
        color: ${(props) => props.theme.colors.blackMed};
        display: block;
        width: 130px;
        height: 35px;
    }
`;

export const UploadBtn = styled(StyledButton)`
    right: 135px;
    display: inline-block;
    background-color: ${(props) => props.theme.colors.yellow};

    :hover {
        background-color: ${(props) => props.theme.colors.yellow100};
    }
`;

export const StyledForm = styled.form`
    justify-content: space-around;
    display: flex;
    width: 480px;
    height: 188x;
`;

export const StyledDiv = styled.div`
    display: flex;
    border: black;
    width: 480px;
    height: 188px;
    align-items: center;

    .fleet-drag-drop{
        position: absolute;
        width: 480px;
        height: 188px;
        justify-content: center;
        display: flex;
        align-items: center;
        padding-left: 69px;

        .fleet-cloud-upload {
            background-repeat: no-repeat;
            background-image: url(${addCloud});
            height: 50px;
            width: 57px;
            top: 5px;
            position: absolute;
            background-size: 57px 50px;
            opacity: 0.35;
        }

        .drop-csv{
            margin-bottom: 0;
            position: absolute;
            color: '#000000';
            font-size: 14px;
            letter-spacing: 0px;
            line-height: 18px;
            text-align: left;
            width: 100%;
            padding-left: 20px;
        }
    }
`;

export const StyledCsvInput = styled.input`
    display: none;
`;