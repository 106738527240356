import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchThreadsByCompanyId } from '../../../store/InboxSlice';
import { IHomeownerController } from './types';
import store from '../../../store';
import { HOMEOWNER_POLL_INTERVAL, globalStyles, HOMEOWNER_MOBILE_SCROLL } from '../../../constants';
import { getUser, addClass, removeClass, withThrottle, userIsUsingInbox } from '../../../shared';
import { Chron } from '../../../classes';
import type { IChron, TUserTokenData } from '../../../types';
import {
  fetchUnreadCount,
  putPersistMessagesViewedById,
  assignExecutingPolling,
  fetchMessagesByThreadId
} from '../../../store/InboxSlice';

import HomeownerLayout from './HomeownerLayout';

/**
 * Provide an explicit request from the store to ensure the polling mechanism reads the latest value
 *
 * @returns {Array} and array of messages or undefined
 */
const getMessages = () => {
  return store.getState().inboxMessages.messageData?.messages;
};

let lastKnownScrollYPosition = 0;

const handleScrollBodyClassSetting = withThrottle(() => {
  lastKnownScrollYPosition = window.scrollY;
  if (lastKnownScrollYPosition < 100) {
    if (lastKnownScrollYPosition > 50) {
      addClass(HOMEOWNER_MOBILE_SCROLL);
    } else {
      removeClass(HOMEOWNER_MOBILE_SCROLL);
    }
  }
}, 333);

/**
 * Note: [!] explicit slices of these values from the store via hook weren't reflecting
 * the values actually in the store form within the Chron handler, likely because the homeowner goes straight to their thread
 * whereas the *exact same* mechanism was used and works for the installer layout because they route through their dashboard first.
 * TODO: Either way, the following do correctly read current state so using them for now (for release):
 *
 * 1. `getUnreadCount`
 * 2. `getTotalCount`
 */

/**
 * Provide explicit access to store values via a getter. See note above.
 * @returns {String}
 */
const getUnreadCount = () => {
  return store.getState().inboxMessages.counts.inbox;
};

/**
 * Provide explicit access to store values via a getter. See note above.
 * @returns {String}
 */
const getTotalCount = () => {
  return store.getState().inboxMessages.counts.totalCount;
};

export const HomeownerLayoutController: React.FC<IHomeownerController> = (props) => {
  const [currentUrl, setCurrentUrl] = useState(window?.location?.href);
  const [mounted, setMounted] = useState<boolean>(false);
  const [mobileScrollConfig, setMobileScrollConfig] = useState<boolean>(false);
  const [homeownerPolling, setHomeownerPolling] = useState<IChron | undefined>(undefined);
  const dispatch = useDispatch();

  const { slug, homeownerId } = getUser() as TUserTokenData;
  const meetsMobileScrollCriteria = window && window.innerWidth < globalStyles.breakpoints.sm.up;
  const href = window?.location?.href;

  /**
   * One-time configuration
   */
  useEffect(() => {
    const fetchUnread = async () => {
      dispatch(fetchUnreadCount());
    };

    /**
     * Make sure our counts are bootstrapped before we start polling...
     */
    fetchUnread()
      .then(() => {
        setMounted(true);
      })
      .catch((err: Error) => {
        console.error(`There was an error fetching unread counts`, err);
      });

    dispatch(
      fetchThreadsByCompanyId({
        page: 1
      })
    );

    if (meetsMobileScrollCriteria) {
      if (!mobileScrollConfig) {
        window.addEventListener('scroll', handleScrollBodyClassSetting);
        setMobileScrollConfig(true);
      }
    } else {
      // case: scroll listening was set up and the device was rotated from portrait to landscape
      // and crosses the threshold so we need to disable scroll config
      if (mobileScrollConfig) {
        window.removeEventListener('scroll', handleScrollBodyClassSetting);
        setMobileScrollConfig(false);
      }
    }
    return () => {
      // do teardown
      if (mobileScrollConfig) {
        window.removeEventListener('scroll', handleScrollBodyClassSetting);
      }
    };
  }, []);

  useEffect(() => {
    if (href && currentUrl !== href) {
      setCurrentUrl(href);
    }
  }, [window?.location?.href]);

  useEffect(() => {
    /**
     * If we have a change in `currentUrl`, clear existing Chron and create a new one
     * This ensures that we reset our Chron whenever the user is active in the app
     */
    if (homeownerPolling) {
      homeownerPolling.clear();
    }

    const currentUnreadCount = getUnreadCount();
    const totalCount = getTotalCount();

    if (mounted) {
      setHomeownerPolling(
        new Chron({
          start: true,
          interval: HOMEOWNER_POLL_INTERVAL,
          eventHandlers: [
            {
              fn: async (chron, handler) => {
                /**
                 * Let this run its full complement before invoking another run
                 * as it depends on multiple async operations which could take some time.
                 */
                chron.pause();

                const count = await store.dispatch(fetchUnreadCount());
                await store.dispatch(putPersistMessagesViewedById({ userId: Number(homeownerId) }));

                if (count?.error || count?.meta?.requestStatus === 'rejected') {
                  if (count?.payload?.getValue) {
                    handler.logError(count.payload.getValue());
                  }
                }

                const unreadCountIncrease =
                  count?.payload?.count > 0 && count?.payload?.count > currentUnreadCount;
                const unreadCountDecrease =
                  currentUnreadCount > 0 && count?.payload?.count < currentUnreadCount;
                const totalCountIncrease =
                  count?.payload?.totalCount > 0 && count?.payload?.totalCount > totalCount;

                /**
                 * If we have more messages that are unread and our total messages increases, or if we have read unread messages
                 * then rehydrate threads
                 */
                if (
                  userIsUsingInbox('homeowner') &&
                  ((unreadCountIncrease && totalCountIncrease) || unreadCountDecrease)
                ) {
                  await store.dispatch(assignExecutingPolling(true));

                  let req;

                  const messages = getMessages();

                  if (messages.length) {
                    req = await store.dispatch(
                      fetchMessagesByThreadId({
                        threadId: messages[0].threadId,
                        page: 1,
                        polling: true
                      })
                    );
                  } else {
                    req = await store.dispatch(
                      fetchThreadsByCompanyId({
                        homeownerId,
                        page: 1,
                        polling: true
                      })
                    );
                  }

                  if (req?.error || req?.meta?.requestStatus === 'rejected') {
                    if (req?.payload?.getValue) {
                      handler.logError(req.payload.getValue());
                    }
                  }
                  await store.dispatch(assignExecutingPolling(false));
                }

                if (handler?.errors?.length > chron.errThreshold) {
                  console.error(
                    `Received bad response from homeownerPolling dispatch '${count.type}' - pausing iterations.`
                  );
                  chron.pause();
                }

                if (chron.counter > 1500 || !getUser()) {
                  chron.clear();
                  if (!getUser()) {
                    window.location.href = `${window.location.origin}/${slug}`;
                  }
                }

                if (handler?.errors?.length <= chron.errThreshold) {
                  chron.restart();
                }
              }
            }
          ]
        }) as IChron
      );
    }
  }, [currentUrl, mounted]);

  /**
   * Clean up what gets passed down
   */
  const passedProps = { ...props };
  if (passedProps.navigation) {
    delete passedProps.navigation;
  }

  return <HomeownerLayout {...passedProps} />;
};

export default HomeownerLayoutController;
