import React, { Suspense } from 'react';
import moment from 'moment';

import { IHomeownerMessage } from './types';
import { MessageStatus } from './';
import { checkIsVideo } from '../../../../shared/checkIsMobile';
import { suspenseFallback } from '../../../../constants/components';
import { HomeownerMessageInterface, UserDetail, HomeownerShare } from './';
import { ParticipantType, DISPLAY_DATE_FORMAT } from '../../../../constants';
import { extractAttachmentInfo, imageIsOpen } from './helpers';
import { UserIdentifier, ShowImageFromDriveAdapter } from '../../../Common';
import { HeadlineText, StyledHeader } from '../../../../shared/styles/Card.style';
import { getInitialsFromName } from '../../../../shared';
import { parseHtml } from '../../../Installer/components/inbox/helpers';
import { PdfIconSvg } from '../../../../constants/icons';
import { StyledImagePreviewBlock } from '../../../Installer/components/inbox/MessageComposerControls.style';

const VideoPreview = React.lazy(() => import('./VideoPreview'));
const { HOMEOWNER, COMPANY, INSTALLER } = ParticipantType;

/**
 * Render any of the various representations of a Homeowner Message with controls
 *
 * @params {Object} props
 * @return React FC
 */
export const HomeownerMessage: React.FC<IHomeownerMessage> = (props) => {
  const { message, propsResolver } = props;
  const interfaceProps = typeof propsResolver === 'function' ? propsResolver(message) : {};
  const { loggedInUser, wasRead, threadId } = interfaceProps;
  const { sender, messageType, senderType, attachments } = message;
  const userDetails = {
    name: sender.name,
    title: sender.title,
    photo: sender.photo,
    messageType
  };
  const { firstName, lastName ,installerId } = loggedInUser?.homeownerData || {};

  const dyId = Math.floor(100000 + Math.random() * 900000);
  const displayMessageInterface = message && Object.keys(interfaceProps).length > 0;
  const isInstaller = message?.recipientType === HOMEOWNER ? false : true;
  const { video, image, pdfFiles } = extractAttachmentInfo(attachments);
  const isVideo = checkIsVideo(video);
  const userInitials = getInitialsFromName(`${firstName} ${lastName}`);

  return (
    <div className="white-box-secondary margb15 spacingMobile">
      <div className="paddall25 pdding-left-zero pdding-right-zero">
        {senderType === INSTALLER || senderType === COMPANY ? (
          <UserDetail dyId={String(dyId)} isCampaign={false} userDetails={userDetails}>
            <MessageStatus unread={!wasRead} allowInstallerCustom={true} />
          </UserDetail>
        ) : (
          <div className="homeowner-message-initials">
            <StyledHeader>
              <UserIdentifier userInitials={userInitials} />
              <HeadlineText>{`${firstName} ${lastName}`}</HeadlineText>
            </StyledHeader>
          </div>
        )}

        <HomeownerShare
          dyId={dyId}
          loggedInUser={loggedInUser}
          isInstaller={isInstaller}
          shareName="glance"
          isDefaultShow={false}
        />

        <div className="message-content-wrapper">
          <Suspense fallback={suspenseFallback}>
            {message && message.message && <p className="ho-message">{parseHtml(message.message)}</p>}

            {
              pdfFiles && pdfFiles.map((pdfFileUrl)=>{
                return (
                  <StyledImagePreviewBlock>
                    <a href={(pdfFileUrl)}
                      target="_blank">
                      <img
                        className='image-preview zoom'
                        src={PdfIconSvg}
                      />
                    </a>
                  </StyledImagePreviewBlock>
                );
              })
            }

            {isVideo && <VideoPreview url={video} />}
            {image !== null && (
              <ShowImageFromDriveAdapter
                campaignPhoto={image}
                isOpen={imageIsOpen(image)}
                filterByName=""
                style={{ marginBottom: '80px' }}
              />
            )}
            {senderType === HOMEOWNER && (
              <span className="contact-wrapperdate-box">
                {moment(message.createdAt).format(DISPLAY_DATE_FORMAT)}
              </span>
            )}
          </Suspense>
        </div>
        <br />
        {displayMessageInterface && (
          <HomeownerMessageInterface {...interfaceProps} message={message} threadId={threadId}  installerId={installerId}/>
        )}
      </div>
    </div>
  );
};

export default HomeownerMessage;
