import systemDetailsImg from '../../../../Assets/Images/system-details.png';
import projectStatusImg from '../../../../Assets/Images/project-status.png';
import monitoringImg from '../../../../Assets/Images/monitoring.png';

export const CONTENT = [
    {
      key: 'system',
      imgSrc: systemDetailsImg,
      alt: 'system detail image',
      styles: {
        direction: 'ltr',
        align: 'start'
      },
      title: 'Access your system details',
      description:
        'Easily access important information about your solar system, from technical specifications to project documents and installation photos.'
    },
    {
      key: 'project-status',
      imgSrc: projectStatusImg,
      alt: 'project status image',
      styles: {
        direction: 'rtl',
        align: 'end'
      },
      title: 'Track your project status',
      description:
        'Bodhi drives more successful leads through a personalized referral interface and provides a channel to market follow-on products and services throughout the 25+ year customer relationship.'
    },
    {
      key: 'monitoring',
      imgSrc: monitoringImg,
      alt: 'monitoring detail image',
      styles: {
        direction: 'ltr',
        align: 'start'
      },
      title: 'Monitor your energy flow',
      description:
        'Understand your solar production and home energy usage with meaningful visualizations, savings totals, and system performance alerts.'
    }
];

export const HERO_TITLE = `Say hello to`;

export const HERO_DESCRIPTION = `Bodhi keeps you updated on every step of the solar installation process and helps you understand the performance of your investment.`

export const FOOTER = {
  WEBSITE_URL: 'https://www.bodhi.solar/',
  WEBSITE: "www.bodhi.solar",
  PRIVACY_POLICY_URL:`/privacy-policy`,
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_OF_SERVICE_URL:`/terms`,
  TERMS_OF_SERVICE: "Terms of Service",
  MAIL_TO_ADDRESS: 'mailto:info@17terawatts.com',
  MAIL_TO: 'info@bodhi.solar'
}

export const YEAR = `Bodhi © 2018 - ${new Date().getFullYear()}`;
