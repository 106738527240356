import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { Layout } from 'antd';
import type {
  GutterProps,
  ImageProps,
  ButtonProps,
  TextProps,
  BoxProps,
  ContainerProps,
  LinkProps,
  StackProps
} from './types';

const { Header: AntdHeader } = Layout;

export const Header = styled(AntdHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 100;
  position: fixed;
  width: 100%;

  @media only screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

export const Gutter = styled.div<GutterProps>`
  margin: ${(props) =>
    props.gutterX ? `0 ${props.gutterX}` : props.gutterY ? `${props.gutterY} 0` : props.gutter};
`;

export const Hero = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 725px;
  background-color: ${(props) => props.theme.colors.blackMed};

  @media only screen and (max-width: 600px) {
    height: 625px;
  }
`;

export const Picture = styled.picture`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
`;

export const Illustration = styled.img`
  position: absolute;
  bottom: 0;
  height: auto;
  width: 100%;
`;

export const Image = styled.img<ImageProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  @media only screen and (max-width: 600px) {
    width: ${(props) => props.media?.width && props.media.width};
    height: ${(props) => props.media?.height && props.media.height};
  }
`;

export const Button = styled.button<ButtonProps>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${(props) => props.color};
  border-radius: 10px !important;
  background-color: ${(props) => props.bgColor};
  z-index: 1;
  font-size: ${(props) => props.size};
  font-family: ${(props) => props.theme.fonts.standardBold};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: 0;
  cursor: pointer;

  :hover {
    color: ${(props) => props.hoverColor};
    background-color: ${(props) => props.hoverBgColor};
  }

  @media only screen and (max-width: 600px) {
    width: ${(props) => props.media?.width && props.media.width};
    height: ${(props) => props.media?.height && props.media.height};
  }
`;

export const Text = styled.p<TextProps>`
  color: ${(props) => props.color};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : props.theme.fonts.standardRegular};
  font-size: ${(props) => props.size};
  letter-spacing: 0;
  line-height: ${(props) => props.lineHeight};
  text-align: ${(props) => (props.align ? props.align : 'center')};
  padding: ${(props) =>
    props.paddingX
      ? `0 ${props.paddingX}`
      : props.paddingY
      ? `${props.paddingY} 0`
      : props.padding && props.padding};
  margin-bottom: 0px;
  direction: ${(props) => props.direction};

  @media only screen and (max-width: 820px) {
    font-size: ${(props) => props.media?.size && `calc(${props.media.size} + 0.2rem)`};
    line-height: ${(props) => props.media?.lineHeight && props.media.lineHeight};
  }

  @media only screen and (max-width: 768px) {
    font-size: ${(props) => props.media?.size && props.media.size};
    line-height: ${(props) => props.media?.lineHeight && props.media.lineHeight};
    padding: ${(props) =>
      props.media?.paddingX
        ? `0 ${props.media.paddingX}`
        : props.media?.paddingY
        ? `${props.media.paddingY} 0`
        : props.media?.padding && props.media.padding};
  }
`;

export const Box = styled.div<BoxProps>`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 14px;
  direction: ${(props) => props.direction};
  padding: 0 60px;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0 20px;
  }
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  justify-content: center;
  align-items: ${(props) => (props.align ? props.align : 'center')};
  width: ${(props) => props.width};
`;

export const Divider = styled.div`
  height: 40px;
  background-color: ${(props) => props.theme.colors.blackMed};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100px;
`;

export const Link = styled(Text).attrs((props: LinkProps) => ({
  as: props.isExternal ? 'a' : ReactRouterLink
}))<LinkProps>`
  cursor: pointer;
  &&& {
    color: ${(props) => props.color};
  }
`;

export const Stack = styled.div<StackProps>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : props.theme.colors.white)};
`;
