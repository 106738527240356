import { retrieveLatestMessage } from '../screens/Installer/components/inbox/helpers';
import {
  TInboxMessage,
  TThreads,
  TThreadsHomeowner,
  TThreadInstaller,
  TInboxThreadMeta
} from '../types';
import Message, { IBodhiMessageClass } from './Message';

export interface IBodhiMessageThread extends TThreads {
  retrieveMostRecentMessage: () => IBodhiMessageClass;
}

/**
 * Initial iteration of a domain object to represent A Bodhi Thread
 */
class Thread implements IBodhiMessageThread {
  _thread: TThreads;
  _messages: IBodhiMessageClass[];

  constructor(thread: TThreads) {
    this._thread = thread;
    this.setMessages();
  }

  public retrieveMostRecentMessage(): IBodhiMessageClass {
    return retrieveLatestMessage(this._messages);
  }

  get messages(): IBodhiMessageClass[] {
    return this._messages;
  }

  get homeowner(): TThreadsHomeowner {
    return this._thread.homeowner;
  }

  get installers(): TThreadInstaller[] {
    return this._thread.installers;
  }

  get metadata(): TInboxThreadMeta {
    return this._thread.metadata;
  }

  get viewedBy(): number[] {
    return this._thread.viewedBy;
  }

  get recipientId(): number {
    return this._thread.recipientId;
  }

  get createdAt(): number {
    return this._thread.createdAt;
  }

  get updatedAt(): string {
    return this._thread.updatedAt;
  }

  get homeownerId(): number {
    return this._thread.homeownerId;
  }

  get companyId(): number {
    return this._thread.companyId;
  }

  get id(): string {
    return this._thread.id;
  }

  /**
   * Set array of thread messages as class instances
   */
  private setMessages() {
    this._messages = this._thread.messages.map((message: TInboxMessage) => {
      return new Message(message);
    });
  }
}

export default Thread;
