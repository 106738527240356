// import npm packages
import { connect } from 'react-redux'
import { reducer } from 'aws-cognito-redux-saga'
import { bindActionCreators } from "redux";

// import local files
import ResetPasswordComponent from './ResetPassword'
import { setLoader } from '../../../Common/Loader/action';

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    image: state.user.slugImage,
    loader: state.loader.loader.loader,
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setLoader,
    init: () => 
      dispatch(reducer.init())
    ,
    signIn: (username, password, code) =>
    dispatch(reducer.signIn(username, password, code)),
    changePassword: (username, code, password) => 
      dispatch(reducer.changePassword(username, code, password))
  }, dispatch)

)
export default connect(mapStatetoProps, mapDispatchToProps)(
  ResetPasswordComponent
)
