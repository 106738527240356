import styled from 'styled-components';

export const DefaultHomeownerDecorator = styled.button`
  padding: 0 10px;
  font-size: ${(props) => props.theme.fonts.sizes.font14};
  width: 150px;
  height: 40px;
  border-radius: ${(props) => props.theme.borderRadius.standard};
  border: 1px solid ${(props) => props.theme.colors.greyMed};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.grey4};
  margin: 10px 20px 10px 0px;

  &:hover {
    background-color: ${(props) => props.theme.colors.red};
  }
  &:focus {
    background-color: ${(props) => props.theme.colors.blue};
  }
`;

export const FooterComposeButtonDecorator = styled.button`
  padding: 0;
  width: auto;
  height: auto;
  margin: 0;
  border: 0 none;
  color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
  }
  &:focus {
    background-color: transparent;
  }
  span {
    &.icon-wrapper {
      svg {
      }
    }
  }
`;
