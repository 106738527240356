import React    from 'react';
import {Modal}  from 'antd'
import yellowCheckIcon from "../../Assets/Images/check-yellow.svg"

export class NotificationModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            popUp :true
        }
    }
    
  render() {
      let {popUp} = this.state;
    return(
      <Modal
        visible={popUp}
        footer = {null}
        wrapClassName="popUp"
        closable={false}
      
        onCancel={() => {
          this.setState({ popUp: false });
          this.props.deletePopUpProp('cancel');
        }}
     
      >
            <div className="white-box success-account-account-box text-center pt-0">
                        <div className="tick-box text-center">
                            <img src={yellowCheckIcon} alt='check-success.png' />
                        </div>
                        <h3>SUCCESS!</h3>
                        <p>{this.props.name}'s Bodhi account was created! <br />
                    An email with login instructions was sent to {this.props.email}</p>
                        <div className="footer-btn" onClick={() => { 
                            this.setState({ popUp: false });
                            this.props.deletePopUpProp('cancel');
                         }}>
                            <form >
                                <button className="btn  btn-primary">DONE</button>
                            </form>
                        </div>
                    </div>
      </Modal>
   )
  }
}