import { connect }            from 'react-redux'
import { reducer }            from 'aws-cognito-redux-saga'
import { bindActionCreators } from "redux";
import HeaderComponent        from './Header'
import { requestSearchData,
  requestAdminHomeownerSearch }  from "../../Actions/header";

import { setLoader }          from '../../../Common/Loader/action';
import { 
  recieveAdminReportApiData,
  requestAdminReportApiData}  from "../../Actions/dashboard";

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    reportData: state.adminOption.data.adminReport,
    homeownerList: state.adminHeader.adminListHomeowner.data,
    searchData: state.adminHeader.searchData
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({ requestSearchData,
    requestAdminHomeownerSearch,
    recieveAdminReportApiData,
    requestAdminReportApiData,
    setLoader,
     signOut: () => dispatch(reducer.signOut()) }, dispatch)

)

export default connect(mapStatetoProps, mapDispatchToProps)(HeaderComponent)
