import styled from 'styled-components';

type TUserIdentifierInterface = {
  hasImage?: boolean;
};

export const DefaultDecorator = styled.span<TUserIdentifierInterface>`
  border-radius: ${(props) => props.theme.borderRadius.round};
  display: inline-block;
  width: 30px;
  height: 30px;
  > span {
    width: inherit;
    height: 100%;
    display: inline-block;
    line-height: 1;
    font-family: '${(props) => props.theme.fonts.standardBold}';
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 50%;
    }
  }

  line-height: 22px;
  &.default-identifier {
    background-color: ${(props) => (props.hasImage ? 'transparent' : props.theme.colors.black)};
    color: ${(props) => props.theme.colors.white};
  }
  padding-top: ${(props) => (props.hasImage ? '0' : '5px')};
  font-size: 14px;
  text-align: center;
`;

export const InstallerIdentifierDecorator = styled(DefaultDecorator)<TUserIdentifierInterface>`
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 26px;
  font-size: 22px;
  padding-top: ${(props) => (props.hasImage ? '0' : '9px')};

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.sm.up}px) {
    padding-top: ${(props) => (props.hasImage ? '0' : '9px')};
  }

  > span {
    font-family: '${(props) => props.theme.fonts.standardRegular}';
    img {
      width: inherit;
      overflow: hidden;
      border-radius: 50%;
    }
  }
`;

export const HomeownerIdentifierDecorator = styled(DefaultDecorator)<TUserIdentifierInterface>`
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 26px;
  font-size: 22px;
  padding-top: ${(props) => (props.hasImage ? '0' : '9px')};

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.sm.up}px) {
    padding-top: ${(props) => (props.hasImage ? '0' : '9px')};
  }

  > span {
    font-family: '${(props) => props.theme.fonts.standardRegular}';
    img {
      width: inherit;
      overflow: hidden;
      border-radius: 50%;
    }
  }

  .count {
    position: absolute;
    color: ${(props) => props.theme.colors.white};
    left: 35%;
    top: 5%;
    font-size: 14px;
    font-family: '${(props) => props.theme.fonts.standardBold}';
    line-height: 20px;
    width: auto;
    padding: 0 4px;
    height: 18px;
    background-color: rgb(255, 98, 67);
    margin-top: 2px;
    border-radius: ${(props) => props.theme.borderRadius.round};
    text-align: center;
    transform: translate(-50%, -50%);
    &.zero {
      display: none;
    }
  }
`;

export const DefaultIconImgDecorator = styled.img`
  width: 30px;
  height: 30px;
  object-fit: none;
`;

export const DefaultIconImgDecoratorContainer = styled.span`
  position: relative;
`;
