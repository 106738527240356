import React, { Component } from "react";
import bodhiWhiteLogo from "./../../../../Assets/Images/bodhi_logo_white.png"
import installScreenIcon from "./../../../../Assets/Images/install_screen.png"
import installCompleteIcon from "./../../../../Assets/Images/install_complete.png"
import callbackUrlsIcon from "./../../../../Assets/Images/callback_urls.png"
import removeSiteSettingsIcon from "./../../../../Assets/Images/remote_site_settings.png"
import processBuilderIcon from "./../../../../Assets/Images/process_builder.png"
import requiredDataIcon from "./../../../../Assets/Images/required-data.png"

export class salesforceGuide extends Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() {
  }

  render() {
    const { image } = this.props;
    return (
      <React.Fragment>
        <title>Salesforce Guide - Bodhi</title>
      
        <div className="salesformce-wrapper header-wrapper">
        <header>
            <div className="logo-box">
                <a href="#" className="logo">                
                    <img src={bodhiWhiteLogo}/>
                </a>
            </div>
        </header>
        <section className="container">
            <div className="white-box">
                <h1>Steps To Configure The Bodhi App Using Salesforce </h1>
                <p>Welcome to the Bodhi Salesforce Launch Guide. This document provides the framework you need to get up
                    and running. It describes the key items you need to set up to run the Bodhi App.</p>
                <div className="steps">
                    <ol>
                        <li><a href="#/bodhi-package">Install The Bodhi Application Package</a></li>
                        <li><a href="#/configure-app">Configure Connected App in Salesforce</a></li>
                        <li><a href="#/remote-settings">Create Remote Site Setting</a></li>
                        <li><a href="#/process-builder">Create Process Builders</a></li>
                        <li><a href="#/batch">Schedule Batch</a></li>
                        <li><a href="#/data-model">Require Data Model</a></li>
                    </ol>
                </div>
                <h2 id={"/bodhi-package"}><span className="round-number">1</span>Install the Bodhi Application Package</h2>
                <div className="p-box">
                    <p>Log into the AppExchange with your Salesforce credentials and search for
                    <strong>{" "}​Bodhi​</strong>. Select it and
                        click <strong>Get It Now</strong>.</p>
                    <p className="mb-0">Alternatively, Install in a production or sandbox from the link at</p>
                    <p><a href="#">https://login.salesforce.com/packaging/installPackage.apexp?p0=04t0o000002qMc9</a>
                    </p>
                    <p>Select ​<strong>Install ​for All Users</strong> and click <strong>​Install​</strong>.</p>
                    <div className="img-box">
                        <img src={installScreenIcon} />
                    </div>
                    <p>The Installation process may take several minutes. When Installation is complete, you will be
                        notified via email. Click ​Done ​to proceed to your organization and configure the application
                    </p>
                    <div className="img-box">                    
                        <img src={installCompleteIcon} />
                    </div>
                    <p>Now that you’ve successfully installed Bodhi for your Salesforce organization, you can proceed to
                        configuration.</p>
                    <p>Note:​ if Bodhi hasn’t appeared in your Installed Packages section, try refreshing the page.</p>
                </div>
                <h2 className="mt-4" id={"/configure-app"}><span className="round-number">2</span>Configure Connected App in Salesforce</h2>
                <div className="p-box">
                    <p>Create a new <strong>Connected App</strong> in Salesforce:</p>
                    <ul className="list-view">
                        <li>Use Salesforce to create a new Connected App:</li>
                        <li>In Lightning Experience, use the <strong>App Manager</strong> to create connected apps. From
                            Setup, enter ​App​ in the Quick Find box, then select ​App Manager​. Click <strong>New
                                Connected
                                App​.</strong>
                        </li>
                        <li>In Salesforce Classic, from Setup, enter ​Apps​ in the Quick Find box, then select
                        <strong>{" "}​Apps​</strong>
                            (under ​Build​ | ​Create​). Under Connected Apps, click <strong>​New​</strong>.
                        </li>
                        <li>Enter Bodhi into the name field.
                        </li>
                        <li>Enter your Contact Email.
                        </li>
                        <li>Select ​<strong>Enable OAuth Settings​</strong>.
                        </li>
                        <li>Enter a <strong>​Callback URL</strong>​. The URL that a user’s browser is redirected to
                            after successful
                            authentication, depending on the environment.
                        </li>
                    </ul>
                    <div className="steps">
                        <ul>
                            <li>https://dev.mybodhiapp.com/oauth/callback</li>
                            <li>https://uat.mybodhiapp.com/oauth/callback</li>
                            <li>https://mybodhiapp.com/oauth/callback</li>
                        </ul>
                    </div>
                    <div>
                        <ul className="add-oth">
                            <li>
                                Add OAuth scopes to ​Selected OAuth Scopes
                            </li>
                        </ul>
                    </div>
                    <div className="pl-3">
                        <ul className="view-call-back">
                            <li>Access and manage your data (api)</li>
                            <li>Perform requests on your behalf at any time (refresh_token, offline_access)​ </li>

                            <li className="list-style img-box ml-0 ml-10-0">
                                <img src={callbackUrlsIcon} />
                            </li>
                            <li>
                                These scopes refer to permissions given by the user running the connected app.
                            </li>
                            <li>Click <strong>​Save​</strong>. The ​Consumer Key​ is created and displayed, and the
                                ​Consumer Secret​ is
                                created (click the link to reveal it).</li>
                        </ul>
                    </div>
                </div>
                <h2 className="mt-4" id={"/remote-settings"}><span className="round-number">3</span>Create Remote Site Setting</h2>
                <div  className="p-box">
                    <p>In Salesforce, from Setup, enter ​Remote Site Settings​ in the Quick Find box, then Click New
                        Remote Site​.
                    </p>
                    <ul>
                        <li>Enter Following Details for each:</li>
                    </ul>
                    <ul className="ml-3">
                        <li>Remote Site Name</li>
                        <li>Remote Site URL</li>
                        <li>Active</li>
                    </ul>
                    <p>Note:​ ​Create a new remote site setting for all the end-point URLs in Bodhi Setup.</p>
                    <div className="img-box ml-0">                    
                        <img src={removeSiteSettingsIcon} />
                    </div>

                </div>


                <h2 className="mt-4" id={"/process-builder"}><span className="round-number">4</span>Create Process Builders</h2>
                <div className="p-box">
                    <p>These processes will update the fields on the Project object in order to ​capture the Update
                        Event on Project
                        object. You can add multiple number of these processes to capture all of the object change
                        reflection on
                        Project.</p>
                    <div className="img-box ml-0">
                        <img src= {processBuilderIcon} />
                    </div>
                </div>


                <h2 className="mt-4" id={"/batch"}><span className="round-number">5</span>Schedule Batch</h2>
                <div className="p-box">
                    <p>This is a one time process to schedule batch in client org in order to sync all the modified
                        records in given time
                        frame. Sync up of data will occur ​four times a day.</p>

                </div>


                <h2 className="mt-4" id={"/data-model"}><span className="round-number">6</span>Required Data Model</h2>
                <div className="p-box">
                    <p>The Bodhi App package uses the following architecture:</p>
                    <div className="img-box ml-0">
                        <img src={requiredDataIcon} />
                    </div>
                    <p>Contact​ Object will work as a parent for ​Project​ object and Project object will be parent of
                        Project Task​ Object
                        i.e One Contact can have multiple associated Project records and similarly for Project and
                        Project Task object.</p>
                </div>


            </div>
        </section>
    </div>
      </React.Fragment>
    );
  }
}

