import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';

import { LITE_TEMPLATE_MENU } from '../../../../constants';
import { withRouter } from '../../../../shared';

const { SubMenu } = Menu;
const  TemplatePhaseMenu:React.FC<any> = (props) => {
  const {companyJourney, siderOptionActive ,redirect,updateStateFromProps,updateJourney} = props;
  const [updateState,setUpdateState] = useState<boolean>();
  const [stateSiderOptionActive,setStateSiderOptionActive] = useState();
  const [stateSiderOption,setStateSiderOption] = useState();

  useEffect(() => {
    if (updateJourney && JSON.stringify(updateJourney) !== '{}') {
      setUpdateState(!updateState)
      LITE_TEMPLATE_MENU[0].option = [];
      LITE_TEMPLATE_MENU[1].option = [];
    }
  }, [updateJourney]);

 
  const subMenu = (ele:any, index:number) => {

    return ele?.option?.map((element:any, i:number) => {
      return (
        <Menu.Item
          key={`${index}-${i}`}
          className={`shiftRight ${
            !siderOptionActive &&
            stateSiderOptionActive == element.key &&
            stateSiderOption == ele.s3Key
              ? 'siderActive'
              : ''
          }`}
          onClick={() => {
            redirect({
                companySettingState: 'isTemplate',
                templateName: element.key,
                templateType : ele.key
            });
            setStateSiderOptionActive(element.key);
            updateStateFromProps({ siderActive: 'isTemplate', siderOptionActive: '' });
          }}
        >
          {element.display}
        </Menu.Item>
      );
    });
  };

  const renderJourneyPhase = () =>{

    let journey = LITE_TEMPLATE_MENU;

    if(companyJourney && companyJourney[0] && journey[0].option.length === 0){
      const journeyData = companyJourney[0];
      const keys = Object.keys(companyJourney[0]);
      keys.map((ele)=>{
        if(ele.includes('custom')){
          journey[1].option.push({display:journeyData[ele].display,key: journeyData[ele].touchName});
        } else {
          journey[0].option.push({display:journeyData[ele].display,key: journeyData[ele].touchName});
        }
      })
    };

    return (
      <>
        {journey.map((ele:any, index:number) => {
          return (
            <Menu key={index} theme="light" className="left-menu journey-menu" mode="inline">
              <SubMenu
                className={`journey-menu ${
                  !siderOptionActive && stateSiderOption === ele.s3Key
                    ? 'siderActive'
                    : ''
                }`}
                key={`${ele.key}`}
                title={ele.display}
                onTitleClick={() => {
                  redirect({
                    companySettingState: 'isTemplate',
                    templateName: '',
                    templateType : ele.key
                  });
                  setStateSiderOption(ele.s3Key);
                  updateStateFromProps({
                    siderActive: 'isTemplate',
                    siderOptionActive: ''
                  });
                }}
              >
                {subMenu(ele, index)}
              </SubMenu>
            </Menu>
          );
        })}
      </>
    );
  }

  return renderJourneyPhase();
}

export default withRouter(TemplatePhaseMenu)
