import styled from 'styled-components';

import { messageThreadLoadingTime } from './constants';

export const PAGE1_READY_CLASSNAME = 'ready-page-1';
export const LOADING_CLASSNAME = 'loading';

export const MessageThreadDecorator = styled.div`
  padding: 40px 40px 220px;
  width: 100%;
  min-height: inherit;

  // transition: min-height ${Number(messageThreadLoadingTime / 1000).toFixed(0)}s linear;
  transition: ${(props) => props.theme.configs.inboxInstallerTransition};

  &.${LOADING_CLASSNAME} {
    height: 0;
    min-height: 0;
    visibility: hidden;
    overflow: hidden;
  }

  &.sidebar-open {
    min-width: calc(100% - ${(props) => props.theme.configs.inboxHomeownerDetailWidthOpen});
  }

  &.${PAGE1_READY_CLASSNAME} {
  }

  .blurbs-message-wrapper {
    padding: 0;
    margin: 20px 67px 50px 20px;
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: flex-end;
    &.homeowner-sent,
    &.current-user-sent {
      margin: 40px 20px 50px 67px;
      align-items: flex-start;
    }
  }

  .invisible-target {
    visibility: hidden;
    height: 1px;
    width: 100%;
  }

  .messages-date-cluster,
  .float-centered {
    text-align: center;
    &.thread-first-message {
      margin-top: 40px;
    }
  }

  .messages-date-cluster,
  .thread-marker-beginning {
    display: table;
    padding: 7.5px 34.5px 2.5px;
    color: ${(props) => props.theme.colors.ltgrey4};
    width: auto;
    margin: auto;
    border: 1px solid ${(props) => props.theme.colors.greyBorder};
    box-shadow: 0 2px 4px 0 ${(props) => props.theme.colors.greyLight} !important;
    line-height: 17px;
    font-size: 14px;
    border-radius: ${(props) => props.theme.borderRadius.standard};
  }
`;

export const StyledHOButton = styled.span`
  border: none;
  background: none;
  z-index: 1;
  line-height: 0;

  .inbox-sider-open {
    top: 130px;
    position: fixed;
    right: 310px;
    @media screen {
      right: 310px;
    }
    border: 0.8px solid ${(props) => props.theme.colors.greyMed};
    border-radius: ${(props) => props.theme.borderRadius.round};
    box-shadow: 0 2px 5px 0 rgba(176, 186, 197, 0.5) !important;
    box-sizing: border-box;
    transition: ${(props) => props.theme.configs.inboxInstallerTransition};
  }
  .inbox-sider-close {
    top: 130px;
    right: 38px;
    position: fixed;
    border: 0.8px solid ${(props) => props.theme.colors.greyMed};
    border-radius: ${(props) => props.theme.borderRadius.round};
    box-shadow: 0 2px 5px 0 rgba(176, 186, 197, 0.5) !important;
    box-sizing: border-box;
    transition: ${(props) => props.theme.configs.inboxInstallerTransition};
  }
`;
