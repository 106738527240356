// import npm packages
import React, { Component } from 'react';
import { Input, Form, Select, Spin } from 'antd';

import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { jwtDecode } from '../../../../shared/jwt';
import * as toast from '../../../../shared/notification';
import { getToken } from '../../../../shared/util';
import { capitalize } from '../../../../shared/capitalize';
import Success from './ExistingInstallSuccess';
import {
  requestApiData,
  requestCompanyIdData,
  requestInstallerData,
  requestAddHomeOwner
} from '../../actions/addHomeOwner';
import { setMetricsType } from '../../actions/dashboard';
import withRouter from '../../../../shared/withRouter';
import GoogleDriveSync from '../../../../shared/GoogleDriveSync';
import { documentFilter } from '../../../../shared/DocumentFilter';
import SharePointSync from '../../../../shared/SharePointSync';
import UploadDocument from '../../../Common/UploadDocument';
import { setLoader } from '../../../Common/Loader/action';
import LandingPage from '../../../Common/LandingPage';
import { openNotificationWithIcon } from '../../../../shared/notification';
import addPersonYellowIcon from './../../../../Assets/Images/add_person_yellow.svg';
import { ReactComponent as CaretIcon } from '../../../../Assets/Images/path-2.svg';

const FormItem = Form.Item;
const Option = Select.Option;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

class NewInstallForm extends Component {
  constructor(props) {
    super(props);
    this.isSubmit = false;
    this.loader = false;
    this.slug = '';
    this.folderArray = [];
    this.GoogleDriveSync = new GoogleDriveSync();
    this.SharePointSync = new SharePointSync();
    this.state = {
      errorObj: {},
      projectManagerId: null,
      newRatePlanList: null,
      ratePlanList: [],
      documentId: {},
      renderForm: false,
      state: null,
      electricutilityList: [],
      moduleBrandId: null,
      inverterBrandId: null,
      installerList: [],
      isFileMissing: false,
      missingFiles: null,
      selectedRatePlan: [],
      fName: '',
      mName: '',
      lName: '',
      email: '',
      disabled: false,
      isSharedDrive: false,
      files: [],
      country : 'United States'
    };
  }

  componentWillMount() {
    this.loader = false;
    this.slug = localStorage.getItem('slug');
    this.props.requestApiData({});
    let token = getToken();
    this.decodedToken = jwtDecode(token);
    this.companyId = this.props.router.params.companyId;
    if (!this.decodedToken.role) {
      this.setState({ disabled: true });
    }
    this.props.requestCompanyIdData(this.companyId);
    this.props.requestInstallerData(this.companyId);
    this.isCrmManager = false;
    this.props.setLoader({ loader: true });
  }

  componentWillReceiveProps(nextProps) {
    // filter installer
    let { companyId, addhomeOwner, existingInstall } = nextProps;

    if (
      !this.state.isDocumentResponse &&
      companyId.data &&
      companyId.data.document &&
      companyId.data.document[0].documentId == 11 &&
      addhomeOwner &&
      addhomeOwner.solarUnit
    ) {
      let ID = addhomeOwner.solarUnit.toolSolarUnitId;
      let token = companyId.data.document[0].token;
      this.setState({ isDocumentResponse: true }, () => {
        nextProps.setLoader({ loader: true });
        this.SharePointSync.teamworkFiles(token, encodeURIComponent(ID))
          .then((r) => {
            this.documentsList = r;
            nextProps.setLoader({ loader: false });
            this.setDocuement({
              source: 'teamwork',
              key: 'teamwork' + '_' + ID,
              files: { [`teamwork_${ID}`]: r },
              isSharedDrive: true
            });
          })
          .catch((error) => {
            this.documentsList = [];
            nextProps.setLoader({ loader: false });
            this.setDocuement({
              source: 'teamwork',
              key: 'teamwork' + '_' + ID,
              files: { [`teamwork_${ID}`]: [] },
              isSharedDrive: true
            });
          });
      });
    } else if (
      !this.state.isDocumentResponse &&
      this.slug === 'ivee' &&
      companyId.data &&
      companyId.data.document &&
      companyId.data.document[0] &&
      companyId.data.document[0].token &&
      addhomeOwner &&
      addhomeOwner.documentSource == 'sharepoint'
    ) {
      let ID = addhomeOwner.solarUnit.toolSolarUnitId;
      let token = companyId.data.document[0].token;
      this.setState({ isDocumentResponse: true }, () => {
        this.SharePointSync.iveeSolarFileList(token, encodeURIComponent(ID))
          .then((r) => {
            this.documentsList = r;
            this.setDocuement({
              source: 'sharepoint',
              key: 'sharepoint' + '_' + ID,
              files: { [`sharepoint_${ID}`]: r },
              isSharedDrive: true
            });
          })
          .catch((error) => {
            this.documentsList = [];
            this.setDocuement({
              source: 'sharepoint',
              key: 'sharepoint' + '_' + ID,
              files: { [`sharepoint_${ID}`]: [] },
              isSharedDrive: true
            });
          });
      });
    }

    let defaultInstaller = {};
    if (
      nextProps.getInstaller &&
      nextProps.getInstaller.data &&
      nextProps.getInstaller.data.length
    ) {
      if (addhomeOwner && addhomeOwner.homeownerData)
        var crmManager = addhomeOwner.homeownerData.projectManager;
      var installerList = nextProps.getInstaller.data.filter((installer) => !installer.isDisable);

      defaultInstaller = JSON.parse(JSON.stringify(this.decodedToken));
      defaultInstaller['id'] = defaultInstaller['installerId'];

      const compareWithEmail = crmManager && this.validateEmail(crmManager);

      for (let i = 0; i < installerList.length; i++) {
        if (installerList[i].defaultInstaller && !this.isCrmManager) {
          defaultInstaller = installerList[i];
        } else if (crmManager && compareWithEmail && installerList[i].email.toLowerCase() == crmManager.toLowerCase()) {
          defaultInstaller = installerList[i];
          this.isCrmManager = true;
        } else if(crmManager && !compareWithEmail && installerList[i]?.name?.toLowerCase().replace(/ /g,'') == crmManager.toLowerCase().replace(/ /g,'')){
          defaultInstaller = installerList[i];
          this.isCrmManager = true;
        }
      }
      if (!this.state.installerList || this.state.installerList.length === 0) {
        this.setState({ installerList });
      }
    }

    let email = localStorage.getItem('homeOwnerEmail');

    let deafultJourney =
      companyId.data &&
      companyId.data.journey &&
      companyId.data.journey.filter((ele) => {
        return ele.journeyKey === 0;
      })[0];

    if (addhomeOwner && addhomeOwner.homeownerData && companyId && companyId.data) {
      // for email
      if (addhomeOwner.homeownerData.email && !email) {
        email = addhomeOwner.homeownerData.email;
      }

      // filter journey
      if (addhomeOwner.solarUnit && addhomeOwner.solarUnit.journeyName) {
        deafultJourney =
          companyId.data &&
          companyId.data.journey &&
          companyId.data.journey.filter((ele) => {
            return ele.s3Key === addhomeOwner.solarUnit.journeyName;
          })[0];
      }

      // filter module brand
      let moduleBrandId;
      if (
        companyId.data.moduleBrands &&
        companyId.data.moduleBrands.length &&
        addhomeOwner.solarUnit &&
        addhomeOwner.solarUnit.moduleBrand
      ) {
        const { moduleBrand } = addhomeOwner.solarUnit;
        let filter = companyId.data.moduleBrands.filter((module) => {
          return (
            module.name.toLowerCase().replace(/ /g, '') ===
            moduleBrand.toLowerCase().replace(/ /g, '')
          );
        })[0];
        if (filter) {
          moduleBrandId = filter.id;
        }
      }

      // filter inverter brand
      let inverterBrandId;
      if (
        companyId.data.inverterBrands &&
        companyId.data.inverterBrands.length &&
        addhomeOwner.solarUnit &&
        addhomeOwner.solarUnit.inverterBrand
      ) {
        const { inverterBrand } = addhomeOwner.solarUnit;
        let filter = companyId.data.inverterBrands.filter((inverter) => {
          return (
            inverter.name.toLowerCase().replace(/ /g, '') ===
            inverterBrand.toLowerCase().replace(/ /g, '')
          );
        })[0];
        if (filter) {
          inverterBrandId = filter.id;
        }
      }

      // filter Electricutility
      let electricutilityList,
        ratePlan,
        ratePlanList = [],
        selectedRatePlan = [];
      if (
        companyId.data.electricutility &&
        companyId.data.electricutility.length &&
        addhomeOwner.solarUnit
      ) {
        const { electricUtilityName } = addhomeOwner && addhomeOwner.solarUnit;
        electricutilityList = this.filterData(
          companyId,
          'electricutility',
          electricUtilityName,
          'abbr'
        )[0];

        if (electricutilityList) {
          companyId.data.electricutility.forEach((item, index) => {
            if (
              item.electricUtilityId === electricutilityList.electricUtilityId &&
              this.state.ratePlanList
            ) {
              ratePlanList = item.rateplan;
              if (!this.state.newRatePlanList) {
                this.setState({
                  newRatePlanList: ratePlanList
                });
              }
              if (addhomeOwner.solarUnit.utilityRateId) {
                const { utilityRateId } = addhomeOwner.solarUnit;
                selectedRatePlan = item.rateplan.filter(
                  (rate) => rate.rateId === utilityRateId.toString()
                );
              } else {
                ratePlan = ratePlanList[0]['rateId'];
              }
            }
          });
        }
      }

      // get user's State value
      let state,country;
      if (
        addhomeOwner &&
        addhomeOwner.homeownerData &&
        addhomeOwner.homeownerData.address &&
        addhomeOwner.homeownerData.address.state &&
        companyId &&
        companyId.data &&
        companyId.data.states
      ) {
        let crmStateLowercase =
          addhomeOwner.homeownerData.address.state &&
          addhomeOwner.homeownerData.address.state.toLowerCase();
        const index = companyId.data.states.findIndex(
          (state) =>
            (state.name && state.name.toLowerCase() === crmStateLowercase) ||
            state.code.toLowerCase() === crmStateLowercase
        );
        if (index !== -1) {
          state = companyId.data.states[index].code;
          country = companyId.data.states[index].country;
        }
      }
      // set values
      this.setState({
        country: country  || 'United States',
        state,
        ratePlan,
        electricUtilityId: electricutilityList && electricutilityList.electricUtilityId,
        moduleBrandId,
        inverterBrandId,
        ratePlanList,
        selectedRatePlan,
        email,
        defaultJourney: deafultJourney && deafultJourney.s3Key,
        installerList,
        projectManagerId: defaultInstaller.id,
        renderForm: true
      });
    } else if (addhomeOwner && addhomeOwner.message) {
      this.setState({
        email,
        defaultJourney: deafultJourney && deafultJourney.s3Key,
        installerList,
        projectManagerId: defaultInstaller.id,
        renderForm: true
      });
    }

    if (existingInstall && this.isSubmit) {
      let status = existingInstall.status;
      let data = existingInstall;
      if (this.state.disabled)
        this.setState({
          disabled: false
        });
      if (data && status && (status === 200 || status === 201)) {
        this.setState({
          isSuccess: true
        });
      } else if (addhomeOwner && addhomeOwner.response && addhomeOwner.response.data) {
        toast.openNotificationWithIcon('error', addhomeOwner.response.data.message, ' Home Owner ');
      }
    }
  }

  componentWillUpdate(prevProps) {
    if (localStorage.getItem('loader') === 'true') {
      setTimeout(() => {
        localStorage.setItem('loader', false);
        this.forceUpdate();
      }, 5000);
    }
  }

  componentDidMount() {
    this.props.setLoader({ loader: true });
    if (localStorage.getItem('homeOwnerEmail')) {
      const data = {
        companyId: this.companyId,
        email: encodeURIComponent(localStorage.getItem('homeOwnerEmail'))
      };
      this.props.requestAddHomeOwner(data);
    }
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  goToDashboard() {
    this.setState({ isSuccess: false });
    this.props.router.navigate(`/${this.slug}/${this.companyId}/dashboard`);
  }

  filterData(companyId, type, value, subType) {
    return companyId.data[type].filter((item) => {
      const temp =
        type === 'powerMeter'
          ? item[subType]
            ? item[subType].toLowerCase().replace(/ /g, '').split(',')
            : item['name'].toLowerCase().replace(/ /g, '').split(',')
          : item[subType].toLowerCase().replace(/ /g, '').split(',');
      if (
        temp &&
        temp.findIndex((val) => value && val === value.toLowerCase().replace(/ /g, '')) !== -1
      )
        return true;
      else return false;
    });
  }

  setDocuement = (doc) => {
    if (doc && doc.key) {
      this.setState({
        isSharedDrive: doc.isSharedDrive,
        files: doc.files,
        documentId: doc
      });
    }
  };

  handleChangeUtility = (e) => {
    this.props.companyId.data.electricutility.forEach((item, index) => {
      if (item.electricUtilityId === e) {
        this.setState({
          newRatePlanList: item.rateplan
        });
      }
    });
  };

  handleSubmit = async (values) => {
    const { addhomeOwner } = this.props;

    let sharepointPublicFile =
      this.state.documentId &&
      this.state.documentId.source &&
      this.state.documentId.source === 'sharepoint' &&
      this.state.documentId.key &&
      this.state.documentId.files &&
      (await this.SharePointSync.makeFolderPublic(
        this.state.documentId.files,
        this.props.companyId.data.document[0].token
      ));

    if (sharepointPublicFile && sharepointPublicFile.length > 0) {
      sharepointPublicFile = {
        isSharedDrive: true,
        key: this.state.documentId.key,
        source: this.state.documentId.source,
        files: { [this.state.documentId.key]: sharepointPublicFile }
      };
    } else if (this.state.isFileMissing && this.state.missingFiles) {
      openNotificationWithIcon(
        'error',
        `${this.state.missingFiles.join()} files is missing.Please add them.`,
        'Error'
      );
      return null;
    } else {
      sharepointPublicFile = null;
    }


    this.setState({
      disabled: true
    });

    if (!this.decodedToken.role) {
      openNotificationWithIcon('error', `You are not authorized.`, 'Error');
      return null;
    }

    if (
      JSON.stringify(this.state.errorObj) !== '{}' &&
      (this.state.errorObj.phoneNumber ||
        this.state.errorObj.systemPrice ||
        this.state.errorObj.annualConsumption ||
        this.state.errorObj.annualProduction ||
        this.state.errorObj.projectAnnualSavings)
    ) {
      return null;
    }

    this.setState({
      email: values['email'],
      fName: values['firstName'],
      lName: values['lastName']
    });

    this.isSubmit = true;
    let { moduleBrands, inverterBrands, electricutility } = this.props.companyId.data;
    values.electricUtilityName = this.fetchName(
      electricutility,
      'electricUtilityId',
      values.electricUtilityId
    );
    values.inverterName = this.fetchName(inverterBrands, 'id', values.inverterBrandId);
    values.moduleName = this.fetchName(moduleBrands, 'id', values.moduleBrandId);
    values.documentId = sharepointPublicFile || this.state.documentId;
    values.isNew = 'true';
    values.companyId = this.companyId;
    values.companyName = this.props.installerData.data.companyName;
    values.toolSolarUnitId =
      (addhomeOwner && addhomeOwner.solarUnit && addhomeOwner.solarUnit.toolSolarUnitId) || '';
    values.toolContactId =
      (addhomeOwner && addhomeOwner.homeownerData && addhomeOwner.homeownerData.toolContactId) ||
      '';
    values.phase = 1;
    values.phaseCompleted = false;
    values.phaseTime = new Date().getTime();
    values.touchName = 'Sign into Bodhi and welcome';

    values['countryCode'] = (this.slug === "pmce" ? '+65' : '+1');
    values['state'] = (this.slug === "pmce" ? 'SINGAPORE' : values['state']);

    values.touchHistory = [
      {
        phase: 1,
        touchName: 'Sign into Bodhi and welcome',
        createdAt: new Date().getTime()
      }
    ];

    localStorage.removeItem('dropboxAccessToken');

    this.state.documentId &&
      this.state.documentId.source &&
      this.state.documentId.source === 'google' &&
      this.state.documentId.key &&
      this.GoogleDriveSync.makeFolderPublic(
        this.state.documentId.key,
        this.state.isSharedDrive,
        this.state.files
      );

    this.props.requestApiData(values);
    this.props.setLoader({ loader: true });
  };

  fetchName = (data, key, value) => {
    let filter = data.filter((ele) => {
      return ele[key] === value;
    })[0];
    return filter?.['name'];
  };

  checkFileMissing = (val, missingFiles) => {
    this.setState({
      isFileMissing: val,
      missingFiles
    });
  };

  renderOptions(dataArray, display, id) {
    return dataArray &&
      dataArray.map((item, index) => {
        if (id == 'code' && item.country === this.state.country) {
          return (
            <Option value={item[id]} key={`${item[id]}-${index}`}>
              {capitalize(item[display])}
            </Option>
          );
        } else if (id != 'code') {
          return (
            <Option value={item[id]} key={`${item[id]}-${index}`}>
              {capitalize(item[display])}
            </Option>
          )
        }
        return null;
      });
  }


  renderProjectManager = (dataArray) =>
    dataArray &&
    dataArray.map((item, index) => (
      <Option value={item.id} key={`${item.id}-${index}`}>
        {`${item.name ? item.name + ' - ' : ''}  ${item.email}`}
      </Option>
    ));

  // check the range of number
  checkNumberRange(value, range, type) {
    const { errorObj } = this.state;
    switch (type) {
      case 'phoneNumber':
        if (value.length !== range) {
          errorObj.phoneNumber = 'error';
        } else {
          errorObj.phoneNumber = '';
        }
        this.setState({
          errorObj
        });
        break;
      default:
        if (value > range) {
          errorObj[type] = 'error';
        } else {
          errorObj[type] = '';
        }
        this.setState({
          errorObj
        });
        break;
    }
  }

  isSharedDrive(documentSource){
    switch(documentSource){
      case "monday":
      case "clickup":
        return false;
      default:
        return true;
    }
  }

  renderNewInstall = () => {
    const { companyId, installerData, addhomeOwner } = this.props;

    if (addhomeOwner && addhomeOwner.homeownerData && addhomeOwner.homeownerData.address) {
      Object.assign(addhomeOwner.homeownerData, addhomeOwner.homeownerData.address);
    }
    if (addhomeOwner && addhomeOwner.solarUnit) {
      Object.assign(addhomeOwner.homeownerData, addhomeOwner.solarUnit);
      Object.assign(addhomeOwner.homeownerData, this.state);
    }

    if (addhomeOwner && addhomeOwner.documents && !this.documentsList) {
      this.documentsList = addhomeOwner.documents;
      let ID = addhomeOwner.solarUnit.toolSolarUnitId;
      var documentSource = addhomeOwner.documentSource || 'serviceFusion';
      this.setDocuement({
        source: documentSource,
        key: documentSource + '_' + ID,
        files: { [`${documentSource}_${ID}`]: this.documentsList },
        isSharedDrive: this.isSharedDrive(documentSource)
      });
    }

    this.formSetting =
      (installerData && installerData.data && installerData.data.formSetting) || {};

    return (
      <React.Fragment>
        <Spin spinning={this.props.loader} indicator={antIcon} style={{ color: '#00142D' }}>
          <title>
            Add New Install Homeowner -{' '}
            {installerData && installerData.data && installerData.data.name + '-'} Bodhi
          </title>
          <div>
            <section>
              <div className="container exisiting">
                <div className="new-client-form-wrapper add-new-home-owner box-Shadow">
                  <div className="white-box create-client-form-box">
                    <h2 className="text-alignment col-md-12">
                      <img src={addPersonYellowIcon} width={33} alt="17terawatts" />
                      <span>Let's collect all the required information for this account</span>
                    </h2>

                    <Form
                      onFinish={this.handleSubmit}
                      scrollToFirstError={true}
                      className="login-form homeowner-profile paddl20 paddr20"
                      initialValues={addhomeOwner.homeownerData || this.state}
                    >
                      <h3 className="add-homeowner">Customer information</h3>

                      <div className="newHomeOwnerProfile">
                        <div className="form-group mobilespacing">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <div className="form-group">
                                <label className="mb-0 form-group">
                                  First name<span className="requiredSign"> * </span>
                                </label>
                                <FormItem
                                  hasFeedback
                                  name="firstName"
                                  rules={[{ required: true }, { pattern: /^([^0-9]*)$/ }]}
                                >
                                  <Input className="form-control" id="exampleInputPassword1" />
                                </FormItem>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group">
                                <label className="mb-0 form-group label-center">
                                  Middle initial
                                </label>
                                <FormItem
                                  hasFeedback
                                  name="middleName"
                                  rules={[{ required: false }, { pattern: /^([^0-9]*)$/ }]}
                                >
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputPassword1"
                                  />
                                </FormItem>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group">
                                <label className="mb-0 form-group">
                                  Last name<span className="requiredSign"> * </span>
                                </label>
                                <FormItem
                                  hasFeedback
                                  name="lastName"
                                  rules={[
                                    {
                                      required: true
                                    },
                                    { pattern: /^([^0-9]*)$/ }
                                  ]}
                                >
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputPassword1"
                                  />
                                </FormItem>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-8 col-12">
                            <div className="form-group">
                              <label className="mb-0 form-group">
                                Email address<span className="requiredSign"> * </span>
                              </label>
                              <FormItem
                                hasFeedback
                                name="email"
                                rules={[
                                  {
                                    required: true
                                  },
                                  {
                                    type: 'email'
                                  }
                                ]}
                              >
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="exampleInputPassword1"
                                  disabled
                                />
                              </FormItem>
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label className="mb-0 form-group">
                                Phone number<span className="requiredSign"> * </span>
                              </label>
                              <FormItem
                                hasFeedback
                                name="phone"
                                validateStatus={this.state.errorObj.phoneNumber || ''}
                                rules={[
                                  {
                                    required: true
                                  }
                                ]}
                              >
                                <NumberFormat
                                  type="tel"
                                  format={this.formSetting.phone}
                                  mask=" "
                                  className="form-control ant-input"
                                  placeholder="(   )  -  "
                                  onChange={(e) => {
                                    this.checkNumberRange(
                                      e.target.value.replace(/[^0-9]/g, ''),
                                      this.formSetting.phoneLength,
                                      'phoneNumber'
                                    );
                                  }}
                                />
                              </FormItem>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-8">
                            <div className="form-group">
                              <label className="mb-0 form-group">
                                Street address<span className="requiredSign"> * </span>
                              </label>
                              <FormItem hasFeedback name="address1" rules={[{ required: true }]}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputPassword1"
                                />
                              </FormItem>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="mb-0 form-group">
                                City<span className="requiredSign"> * </span>
                              </label>
                              <FormItem
                                hasFeedback
                                name="city"
                                rules={[
                                  {
                                    required: true
                                  }
                                ]}
                              >
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputPassword1"
                                />
                              </FormItem>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="padded-form-cell">
                              <label htmlFor="country">Country</label>
                              <FormItem hasFeedback name="country" rules={[{ required: true }]}>
                                <Select
                                  suffixIcon={<CaretIcon />}
                                  placeholder="Select Country"
                                  optionFilterProp="children"
                                  onChange={(value)=>{
                                    this.setState({country:value});
                                  }}
                                >
                                  <Option value="United States" key="United States">
                                    United States
                                  </Option>
                                  <Option value="Canada" key="Canada">
                                    Canada
                                  </Option>
                                  <Option value="Singapore" key="Singapore">Singapore</Option>
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                          
                          {companyId.data &&
                            companyId.data.states &&
                            companyId.data.states.length > 0 && (
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label className="mb-0 form-group">
                                    State<span className="requiredSign"> * </span>
                                  </label>
                                  <FormItem
                                    hasFeedback
                                    name="state"
                                    rules={[
                                      {
                                        required: true
                                      }
                                    ]}
                                  >
                                    <Select
                                      suffixIcon={<CaretIcon />}
                                      name="state"
                                      className="form-control addHomeowner"
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={(input, option) => {
                                        return (
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        );
                                      }}
                                    >
                                      {this.renderOptions(
                                        companyId.data && companyId.data.states
                                          ? companyId.data.states
                                          : [],
                                        'code',
                                        'code'
                                      )}
                                    </Select>
                                  </FormItem>
                                </div>
                              </div>
                            )}
                          <div className="col-sm-3">
                            <div className="form-group">
                              <label className="mb-0 form-group">
                                Postal code<span className="requiredSign"> * </span>
                              </label>
                              <FormItem
                                hasFeedback
                                name="zipCode"
                                rules={[
                                  {
                                    required: true
                                  }
                                ]}
                              >
                                {
                                  (this.state.country == "United States") ?
                                    <NumberFormat
                                      type="tel"
                                      format={this.formSetting.postalCode}
                                      mask=" "
                                      className="form-control"
                                    />
                                    :

                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="exampleInputPassword1"
                                    />
                                }
                                
                                
                                
                                
                              </FormItem>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="row">
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label className="mb-0 ">
                                Bodhi Journey<span className="requiredSign"> * </span>
                              </label>
                              <FormItem
                                name="defaultJourney"
                                hasFeedback
                                rules={[
                                  {
                                    required: true
                                  }
                                ]}
                              >
                                <Select
                                  suffixIcon={<CaretIcon />}
                                  className="form-control addHomeowner"
                                  optionFilterProp="children"
                                >
                                  {this.renderOptions(
                                    companyId.data ? companyId.data.journey : [],
                                    'journeyName',
                                    's3Key'
                                  )}
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <h3 className="add-homeowner">Permitting & system information</h3>

                        <div className="row">
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label className="mb-0 ">
                                Inverter brand
                              </label>
                              <FormItem
                                hasFeedback
                                name="inverterBrandId"
                                rules={[
                                  {
                                    required: false
                                  }
                                ]}
                              >
                                <Select
                                  suffixIcon={<CaretIcon />}
                                  className="form-control addHomeowner"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) => {
                                    return (
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                >
                                  {this.renderOptions(
                                    companyId.data ? companyId.data.inverterBrands : [],
                                    'name',
                                    'id'
                                  )}
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label className="mb-0 ">
                                Module brand
                              </label>
                              <FormItem
                                hasFeedback
                                name="moduleBrandId"
                                rules={[
                                  {
                                    required: false
                                  }
                                ]}
                              >
                                <Select
                                  suffixIcon={<CaretIcon />}
                                  className="form-control addHomeowner"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) => {
                                    return (
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                >
                                  {this.renderOptions(
                                    companyId && companyId.data ? companyId.data.moduleBrands : [],
                                    'name',
                                    'id'
                                  )}
                                </Select>
                              </FormItem>
                            </div>
                          </div>

                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label>Module model</label>
                              <FormItem
                                hasFeedback
                                name="moduleModel"
                                rules={[
                                  {
                                    required: false
                                  }
                                ]}
                              >
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputPassword1"
                                />
                              </FormItem>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label>
                                Number of modules
                              </label>
                              <FormItem
                                hasFeedback
                                name="moduleCount"
                                rules={[
                                  {
                                    required: false
                                  }
                                ]}
                              >
                                <NumberFormat
                                  mask=" "
                                  type="tel"
                                  className="form-control ant-input"
                                />
                              </FormItem>
                            </div>
                          </div>

                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label>
                                Total system size
                              </label>
                              <FormItem
                                hasFeedback
                                name="systemSize"
                                rules={[
                                  {
                                    required: false
                                  }
                                ]}
                              >
                                <NumberFormat
                                  mask=" "
                                  type="tel"
                                  className="form-control ant-input"
                                />
                              </FormItem>
                            </div>
                          </div>

                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label>System price</label>
                              <FormItem
                                hasFeedback
                                name="systemPrice"
                                validateStatus={this.state.errorObj.systemPrice || ''}
                                rules={[
                                  {
                                    required: false
                                  }
                                ]}
                              >
                                <NumberFormat
                                  mask=" "
                                  type="tel"
                                  className="form-control ant-input"
                                  onChange={(e) => {
                                    this.checkNumberRange(
                                      Number(e.target.value),
                                      999999999,
                                      'systemPrice'
                                    );
                                  }}
                                />
                              </FormItem>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label>Historical annual consumption in kWh</label>
                              <FormItem
                                hasFeedback
                                name="annualConsumption"
                                validateStatus={this.state.errorObj.annualConsumption || ''}
                                rules={[
                                  {
                                    required: false
                                  }
                                ]}
                              >
                                <NumberFormat
                                  mask=" "
                                  type="tel"
                                  className="form-control ant-input"
                                  onChange={(e) => {
                                    this.checkNumberRange(
                                      Number(e.target.value),
                                      999999999,
                                      'annualConsumption'
                                    );
                                  }}
                                />
                              </FormItem>
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label>Projected annual production in kWh</label>
                              <FormItem
                                hasFeedback
                                name="annualProduction"
                                validateStatus={this.state.errorObj.annualProduction || ''}
                                rules={[
                                  {
                                    required: false
                                  }
                                ]}
                              >
                                <NumberFormat
                                  mask=" "
                                  type="tel"
                                  className="form-control ant-input"
                                  onChange={(e) => {
                                    this.checkNumberRange(
                                      Number(e.target.value),
                                      999999999,
                                      'annualProduction'
                                    );
                                  }}
                                />
                              </FormItem>
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label>Projected annual savings in $</label>
                              <FormItem
                                hasFeedback
                                name="saving"
                                validateStatus={this.state.errorObj.projectAnnualSavings || ''}
                                rules={[
                                  {
                                    required: false
                                  }
                                ]}
                              >
                                <NumberFormat
                                  mask=" "
                                  type="tel"
                                  className="form-control ant-input"
                                  onChange={(e) => {
                                    this.checkNumberRange(
                                      Number(e.target.value),
                                      999999999,
                                      'projectAnnualSavings'
                                    );
                                  }}
                                />
                              </FormItem>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label className="mb-0 ">
                                Electric utility
                              </label>
                              <FormItem
                                hasFeedback
                                name="electricUtilityId"
                                rules={[
                                  {
                                    required: false
                                  }
                                ]}
                              >
                                <Select
                                  suffixIcon={<CaretIcon />}
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) => {
                                    return (
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                  className="form-control addHomeowner"
                                  onChange={this.handleChangeUtility}
                                >
                                  {this.renderOptions(
                                    companyId.data ? companyId.data.electricutility : [],
                                    'name',
                                    'electricUtilityId'
                                  )}
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label className="mb-0 ">
                                Rate plan
                              </label>
                              <FormItem
                                hasFeedback
                                name="ratePlan"
                                rules={[
                                  {
                                    required: false
                                  }
                                ]}
                              >
                                <Select
                                  suffixIcon={<CaretIcon />}
                                  className="form-control captalize-text"
                                >
                                  {this.renderOptions(
                                    this.state.newRatePlanList || this.state.ratePlanList,
                                    'display',
                                    'rateId'
                                  )}
                                </Select>
                              </FormItem>
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label className="mb-0 form-group">AHJ</label>
                              <FormItem
                                hasFeedback
                                name="ahj"
                                rules={[
                                  {
                                    required: false
                                  }
                                ]}
                              >
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputPassword1"
                                />
                              </FormItem>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="section-from-box">
                        <div className="row">
                          <div className="col-md-8 offset-md-2 margin-left-0 width-100">
                            <h3>Documents</h3>
                          </div>
                        </div>{' '}
                        <div
                          className={`document-section ${
                            this.documentsList && this.documentsList.length > 0 ? 'fusion' : ''
                          }`}
                        >
                          {this.documentsList ? (
                            this.documentsList.length > 0 ? (
                              this.documentsList.map((ele) => {
                                let f =
                                  this.props.companyId &&
                                  this.props.companyId.data &&
                                  this.props.companyId.data.document &&
                                  this.props.companyId.data.document[0].filter;
                                return this.renderDocuments(ele, f);
                              })
                            ) : (
                              'No documents present'
                            )
                          ) : (
                            <UploadDocument
                              isEdit={false}
                              setDocuement={(doc) => {
                                this.setDocuement(doc);
                              }}
                              {...this.props}
                              checkFileMissing={(val, missingFiles) => {
                                this.checkFileMissing(val, missingFiles);
                              }}
                            />
                          )}
                        </div>
                      </div>

                      <div className="section-from-box">
                        <h3>Assign personnel</h3>
                        <div className="col-md-8 col-12 mt-4">
                          <div className="form-group">
                            <label>
                              Project manager <span className="requiredSign"> * </span>
                            </label>
                            <FormItem
                              hasFeedback
                              name="projectManagerId"
                              rules={[
                                {
                                  required: true
                                }
                              ]}
                            >
                              <Select
                                suffixIcon={<CaretIcon />}
                                className="form-control addHomeowner"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                  return (
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                              >
                                {this.renderProjectManager(this.state.installerList)}
                              </Select>
                            </FormItem>
                          </div>
                        </div>

                        <div className="col-md-8 col-12">
                          <div className="form-group">
                            <label>Salesperson</label>
                            <FormItem
                              hasFeedback
                              name="salesperson"
                              rules={[
                                {
                                  required: false
                                }
                              ]}
                            >
                              <Select
                                suffixIcon={<CaretIcon />}
                                className="form-control addHomeowner"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                  return (
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                              >
                                {this.renderProjectManager(this.state.installerList)}
                              </Select>
                            </FormItem>
                          </div>
                        </div>
                      </div>

                      <div className="text-center margt40 margb80 btn-center-mobile">
                        <Link
                          className="btn btn-secondary"
                          to={`/${this.slug}/${this.companyId}/addhomeowner`}
                        >
                          Cancel
                        </Link>
                        <button className="btn btn-primary">Create account</button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Spin>
      </React.Fragment>
    );
  };

  renderDocuments(ele, f) {
    let {
      addhomeOwner: { documentSource }
    } = this.props;
    switch (documentSource) {
      case 'teamwork':
        if (f && !documentFilter(f, ele.fileName)) return;
        else
          return (
            <div>
              <a href={ele.previewUrl} target="_blank" rel="noreferrer">
                {ele.name}
              </a>
            </div>
          );
      case 'salesforce':
        if (f && !documentFilter(f, ele.fileName)) return;
        else
          return (
            <div>
              <a href={ele.publicURL} target="_blank" rel="noreferrer">
                {ele.fileName}
              </a>
            </div>
          );

      case 'sharepoint':
        return <div>{ele.name}</div>;

      case 'monday':
        if (f && !documentFilter(f, ele.name)) return;
        else
          return (
            <div>
              <a href={ele.public_url} target="_blank" rel="noreferrer">
                {ele.name}
              </a>
            </div>
          );

      case 'zoho':
        if (f && !documentFilter(f, ele.File_Name)) return;
        else
          return (
            <div>
              <a href={ele['$link_url']} target="_blank" rel="noreferrer">
                {ele.File_Name}
              </a>
            </div>
          );


      default:
        if (f && !documentFilter(f, ele.name)) return;
        else
          return (
            <div>
              <a href={ele['url']} target="_blank" rel="noreferrer">
                {ele.name}
              </a>
            </div>
          );
    }
  }

  render() {
    if (getToken()) {
      if (this.state.isSuccess)
        return (
          <Success
            {...this.props}
            name={`${this.state.fName} ${this.state.lName}`}
            email={this.state.email}
            goToDashboard={() => {
              this.goToDashboard();
            }}
          />
        );
      else if (this.state.renderForm) {
        return this.renderNewInstall();
      } else {
        return <LandingPage />;
      }
    } else {
      // this.props.router.navigate("/");
      return null;
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    auth: state.auth,
    companyData: state.setting.companyDetail.data,
    getInstaller: state.addhomeOwner.getInstaller,
    installerData: state.header.getInstaller,
    stateData: state.addhomeOwner.state,
    companyId: state.addhomeOwner.companyId,
    existingInstall: state.addhomeOwner.existingInstall,
    addhomeOwner: (state.addhomeOwner.homeOwner && state.addhomeOwner.homeOwner.data) || {},
    loader: state.loader.loader.loader
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestApiData,
      requestAddHomeOwner,
      requestCompanyIdData,
      requestInstallerData,
      setMetricsType,
      setLoader
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(NewInstallForm));
