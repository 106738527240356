import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { HUBSPOT_FORM_CONFIG } from '../../../constants';
import { resetHomeownerClasses } from '../../../shared';
import Header from '../../../screens/Admin/components/header';

function addScript() {
  return (
    <Helmet>
      <script
        async
        charset="utf-8"
        type="text/javascript"
        src="//js.hsforms.net/forms/embed/v2.js"></script>
      <script>hbspt.forms.create(HUBSPOT_FORM_CONFIG);</script>
    </Helmet>
  );
}

const adminLayout = ({ component: Component, ...params }, props) => {
  resetHomeownerClasses();
  return (
    <>
      <header>
        <Header {...props} />
      </header>
      {addScript()}
      <Component {...props} />
    </>
  );
};

export default adminLayout;
