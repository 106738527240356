import React from 'react';
import ShowImageFromDrive from '../ShowImageFromDrive';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { setImages } from '../ShowImageFromDrive/action';

/**
 * create a selector for the different parts of the state
 */
const installerDetailsSelector = createSelector(
  (state: RootState) => state.homeownerContact.homeownerInstallerDetails.data,
  (installerDetails) => installerDetails
);

const loggedInUserSelector = createSelector(
  (state: RootState) => state.homeownerLoggedInUser.homeownerLoggedInUserReducer.data,
  (loggedInUser) => loggedInUser
);

const homeOwnerFileListSelector = createSelector(
  (state: RootState) => state.homeownerLoggedInUser.getHomeownerDocumentReducer.data,
  (homeOwnerFileList) => homeOwnerFileList
);

const cloudImagesSelector = createSelector(
  (state: RootState) =>
    Object.keys(state.cloudImages.cloudImages).length ? state.cloudImages.cloudImages : {},
  (cloudImages) => cloudImages
);

interface IShowImageFromDriveAdapter {
  campaignPhoto?: any[];
  isOpen: boolean;
  filterByName: string;
  style?: React.CSSProperties;
}

/**
 * Provides a bridge to use `ShowImageFromDrive` when limited user data from the store is in hand.
 *
 * @param props
 * @returns `ShowImageFromDrive`
 */
export const ShowImageFromDriveAdapter: React.FC<IShowImageFromDriveAdapter> = (props) => {
  const { campaignPhoto, isOpen, filterByName, style } = props;
  const dispatch = useDispatch();
  /**
   * Pull the data from the state so it can be passed to the component
   */
  const installerDetails = useSelector(installerDetailsSelector);
  const loggedInUser = useSelector(loggedInUserSelector);
  const homeOwnerFileList = useSelector(homeOwnerFileListSelector);
  const cloudImages = useSelector(cloudImagesSelector);

  return (
    <ShowImageFromDrive
      installerDetails={installerDetails}
      loggedInUser={loggedInUser}
      homeOwnerFileList={homeOwnerFileList}
      cloudImages={cloudImages}
      campaignPhoto={campaignPhoto}
      isOpen={isOpen}
      filterByName={filterByName}
      style={style}
      setImages={dispatch(setImages)}
    />
  );
};

export default ShowImageFromDriveAdapter;
