import { companyJourney,
	companyCRMData,
	postCompanyJourney } 		from "../../screens/Installer/api/companyJourney";
import { call, put } 			from "redux-saga/effects";
import handleError 		 		from "../../shared/errorHandler";
import { setLoader } 	 		from "../../screens/Common/Loader/action";
import {
	receiveCompanyCRMData,
	receiveCompanyJourney,
	receiveCompanyEdResource,
	receiveCompanyPlanDetail,
	receiveUpdateCompanyPlanDetail,
	receiveUpdateCompanyEdResource,
	receiveUpdateCompanyJourney
}  								from "../../screens/Installer/actions/companyJourney";

function* getCompanyJourney(action) {
	try {
		const data = yield call(companyJourney, action.data);
		yield put(setLoader({ loader: false }));
		if( action.data.type === 'resource'){
			yield put(receiveCompanyEdResource(data));
		} else if(action.data.type === 'premium'){
			yield put(receiveCompanyPlanDetail(data));
		} else {
			yield put(receiveCompanyJourney(data));
		}
		
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* getCompanyCRMData(action) {
	try {
		const data = yield call(companyCRMData, action.data);
		yield put(receiveCompanyCRMData(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* addCompanyJourney(action) {
	try {
		const data = yield call(postCompanyJourney, action.data);
		if(action?.data?.type === 'companyJourney'){
			yield put(receiveUpdateCompanyJourney(data));
		} else if(action?.data?.type === 'premium'){
			yield put(receiveUpdateCompanyPlanDetail(data));
		} else if(action?.data?.type === 'resource'){
			yield put(receiveUpdateCompanyEdResource(data));
		}
		yield put(setLoader({ loader: false }));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

export const companyBodhiJourney = {
	getCompanyJourney,
	getCompanyCRMData,
	addCompanyJourney
};