import React, { useState, useEffect, useRef, Suspense } from 'react';
import moment from 'moment';

import store from '../../../../store';

import {
  messageTimestampFormat,
  startMarkerSignalText,
  userScrollDownTargetElementId,
  messageThreadLoadingTime,
  messagesDisplayOptions
} from './constants';

import { updateMessagesViewedById } from '../../../../store/InboxSlice';
import { WithLoader } from '../../../Common';
import {
  MessageThreadDecorator,
  StyledHOButton,
  PAGE1_READY_CLASSNAME,
  LOADING_CLASSNAME
} from './MessageThread.style';

const InboxMessage = React.lazy(() => import('./InboxMessage'));

import { suspenseFallback } from '../../../../constants/components';
import { IMessageThread, IInboxMessage } from './types';
import { arrorRighticonSvg, arrowLefticonSvg } from '../../../../constants/icons';
import { Tooltip } from '../../../Common/Tooltip';
import { MESSAGES_DEFAULT_LIMIT } from '../../../../constants';

/**
 * Local mechanism to make calls to update message viewedBy data
 *
 * @param {Number} installerId
 * @param {String} a delimited string of message Ids
 */
const dispatchMessagesViewed = async (threadId: string, messageIds: string[]) => {
  await store.dispatch(
    updateMessagesViewedById({
      threadId,
      messageIds
    })
  );
};

/**
 * Called when the MessageThread is ready
 *
 * @param {Number} messageCount
 * @param {Boolean} polling indicates a routine check for new thread data
 */
const threadReadyHandler = (messageCount: number, polling: boolean) => {
  // @todo: on deep linking to a thread, targetElement is the last variable to resolve
  const targetElement = document.getElementById(userScrollDownTargetElementId);
  if (targetElement && messageCount <= MESSAGES_DEFAULT_LIMIT && !polling) {
    setTimeout(() => {
      targetElement.scrollIntoView();
    }, messageThreadLoadingTime / 4);
  }
};

type MessageProps = {
  isOpened: boolean;
  toggleDrawer: () => void;
};

/**
 * A controller to handle concerns scoped to a single thread
 */
export const MessageThread: React.FC<IMessageThread & MessageProps> = (props) => {
  const {
    threadId,
    polling,
    messagesLut,
    metadata,
    userId,
    isOpened,
    toggleDrawer,
    loading,
    fetchingThreadData,
    messagesDisplayOption,
    homeownerEversignData
  } = props;

  const { nextPage } = metadata || {
    nextPage: undefined
  };

  const [messagesReadQueue, setMessagesReadQueue] = useState<string[]>([]);
  const [isMounted, setIsMounted] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [messagesCount, setMessagesCount] = useState(messagesLut.size);

  const messageDatetimes = [...messagesLut.keys()].sort();
  const oldestDatetime = messageDatetimes[0];
  const latestDatetime = messageDatetimes[messageDatetimes.length - 1];

  const threadPaneRef = useRef();

  let previousDate: string;
  let messagesReady = false;

  /**
   * Captures message ids that are newly read by the sessioned user
   */
  const assignUnreadMessageId = (id: string) => {
    if (messagesReadQueue.includes(id)) {
      return;
    }
    const unreadMessagesQueue = [...messagesReadQueue];
    unreadMessagesQueue.push(id);
    setMessagesReadQueue(unreadMessagesQueue);
  };

  useEffect(() => {
    if (messagesReadQueue.length) {
      if (messagesLut.size) {
        dispatchMessagesViewed(threadId, messagesReadQueue);
        setMessagesReadQueue([]);
      }
    }
  }, [messagesReadQueue]);

  /**
   * Executes when message counts change
   * when (1) user loads view, (2) user scrolls to load more messages from history, (3) user receives message(s) while viewing thread
   */
  useEffect(() => {
    /**
     * Messages are updated during polling interval, so use that to identify the condition
     */
    if (polling && fetchingThreadData) {
      // Html for target element is last to render, so wait a tick...
      setTimeout(() => {
        // TODO: [!] Do this way better - we need the long delay, for now,
        // to ensure the thread has expanded fully.
        // Notes: tried with state value set when last message rendered, and
        // tried with a `Chron` to await the node in the document, both failed.
        // we should use a Chron plus a read on the height of the container via a ref, which
        // has a transition to expand, to ensure both the node exists AND it's fully expanded
        const targetEl = document.getElementById(userScrollDownTargetElementId);
        if (targetEl) {
          targetEl.scrollIntoView();
        }
      }, 2500);
    }
  }, [messagesCount]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, [isMounted]);

  useEffect(() => {
    if (!isReady && messagesReady) {
      threadReadyHandler(messagesCount, polling);
      setIsReady(true);
    }
  }, [isReady]);

  const messageThreadClasses: string[] = [LOADING_CLASSNAME];

  if (isOpened) {
    messageThreadClasses.push('sidebar-open');
  }

  if (!loading) {
    // remove 'loading' class when it's not loading
    messageThreadClasses.splice(messageThreadClasses.indexOf(LOADING_CLASSNAME), 1);
  }

  if (isReady && messagesCount <= MESSAGES_DEFAULT_LIMIT) {
    messageThreadClasses.push(PAGE1_READY_CLASSNAME);
  }

  // General rule: track unreads for "My" messages - see override(s) below
  const trackUnreadMessages = messagesDisplayOption === messagesDisplayOptions.MY_MESSAGES;

  return (
    <Suspense fallback={suspenseFallback}>
      <MessageThreadDecorator className={messageThreadClasses.join(' ')} ref={threadPaneRef}>
        {nextPage === false && (
          <div className="float-centered">
            <span className="thread-marker-beginning">{startMarkerSignalText}</span>
          </div>
        )}

        {messageDatetimes &&
          messageDatetimes.map((datetime: string, counter: number) => {
            let prevDate;
            const isOldestMessageInPayload = datetime === oldestDatetime;
            const isLatestMessageInPayload = datetime === latestDatetime;
            const message = messagesLut.get(datetime);
            if (!message) {
              return null;
            }
            const formattedDate = moment(datetime).format(messageTimestampFormat);
            const userIdStr = String(userId);

            if (!message) {
              return null;
            }

            /**
             * Ensure we reference the previous message's date for comparison purposes
             */
            if (!previousDate) {
              previousDate = formattedDate;
            } else {
              prevDate = previousDate.valueOf();
              previousDate = formattedDate;
            }

            if (isLatestMessageInPayload) {
              messagesReady = true;
            }

            let messageProps: IInboxMessage = {
              userId,
              message,
              trackUnreadMessages,
              homeownerEversignData
            };

            /**
             * Track unread messages if "My" messages or if "All" messages and user is recipient
             */
            if (trackUnreadMessages || message.recipient.id === userIdStr) {
              messageProps = {
                ...messageProps,
                trackUnreadMessages: true, // override general rule
                messageReadHandler: assignUnreadMessageId
              };
            }

            const displayDate =
              moment(Date.now()).format(messageTimestampFormat) === formattedDate
                ? 'Today'
                : formattedDate;

            if (formattedDate !== prevDate || isOldestMessageInPayload) {
              return (
                <React.Fragment key={formattedDate}>
                  <div
                    className={`messages-date-cluster ${
                      isOldestMessageInPayload ? 'thread-first-message' : ''
                    }`.trim()}>
                    {displayDate}
                  </div>
                  <InboxMessage key={`${message.createdAt}${counter}`} {...messageProps} />
                </React.Fragment>
              );
            }

            return <InboxMessage key={`${message.createdAt}${counter}`} {...messageProps}/>;
          })}
        {props.children}
      </MessageThreadDecorator>
      <StyledHOButton onClick={toggleDrawer} data-testid="btn-id">
        <div className={`inbox-sider-${isOpened ? 'open' : 'close'}`}>
          <Tooltip
            position="left"
            text={isOpened ? 'Hide homeowner details' : 'Show homeowner details'}
            target={
              <img
                src={isOpened ? arrorRighticonSvg : arrowLefticonSvg}
                alt={`${isOpened ? 'rt icon' : 'lt icon'}`}
              />
            }
          />
        </div>
      </StyledHOButton>
    </Suspense>
  );
};

export default WithLoader(MessageThread);
