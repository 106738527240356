import moment from 'moment';
import React from 'react';

import { AUTOMATED_MESSAGE_TYPE, MessageType, ParticipantType } from '../../../../../constants';
import { bodhiLogoPng } from '../../../../../constants/icons';
import { getInitialsFromName } from '../../../../../shared';
import { TThreads } from '../../../../../types';
import { flattenThreadsForDatum } from '../../../container/inbox/helpers';
import { IBodhiMessageClass } from '../../../../../classes/Message';


/**
 * Assuming a collection of threads and a threadId, indicates whether or not that thread exists in the collection
 *
 * @param threadId
 * @param threads
 * @returns Boolean
 */
export const threadIdExistsInThread = (threadId: string, threads: TThreads[]) => {
  if (threads?.length) {
    const threadIds = flattenThreadsForDatum(threads, 'id');
    return threadIds.includes(threadId);
  }
  console.warn(
    `threadIdExistsInThread was called with an empty thread collection - given ${threads}`
  );
  return false;
};

/**
 * Takes an array of messages, and computes which is the most recent based on
 *
 * 1. when it was created
 * 2. That's it
 */
export const retrieveLatestMessage = (
  messages: IBodhiMessageClass[],
  filter?: (message: IBodhiMessageClass) => boolean
) => {
  let latestMessage = messages.length ? messages[0] : undefined; // default: gonna need a message, start with first
  let latestTimestamp = 0;
  messages.forEach((message: IBodhiMessageClass) => {
    const currentMessageTimestamp = Number(moment(message.createdAt).format('x'));
    if (filter) {
      if (currentMessageTimestamp > latestTimestamp && filter(message)) {
        latestTimestamp = currentMessageTimestamp;
        latestMessage = message;
      }
    } else {
      if (currentMessageTimestamp > latestTimestamp) {
        latestTimestamp = currentMessageTimestamp;
        latestMessage = message;
      }
    }
  });
  return latestMessage;
};

/**
 * A canonical mechanism to identify if the current user has viewed a given message
 *
 * @param {Object} IBodhiMessageClass
 * @returns {Boolean}
 */
export const messageIsUnreadByUser = (message: IBodhiMessageClass, userIdStr: string) => {
  return (
    message.viewedBy.filter(
      (viewedBy) =>
        viewedBy.viewedById === userIdStr && viewedBy.viewedByType === ParticipantType.INSTALLER
    ).length === 0 && message.sender.id !== userIdStr
  );
};

/**
 * Delegates to `retrieveLatestMessage` with exclusion filter for homeowner replies
 */
export const retrieveLatestMessageNotReply = (messages: IBodhiMessageClass[]) => {
  return retrieveLatestMessage(
    messages,
    (message) =>
      message.messageType !== MessageType.REPLY || message.senderType !== ParticipantType.HOMEOWNER
  );
};

export const MAX_PREVIEW_LENGTH = 32;

/**
 * Look for a good place to slice a string, if possible, and return slice showing ellipsis
 *
 * @param {String} the string to slice
 * @param {Number} the max length of string to display - optional
 */
export const stringWithEllipsis = (body: string, max?: number) => {
  const maxLen = max || MAX_PREVIEW_LENGTH;
  if (body.length <= maxLen) {
    return body;
  }
  let text = body.split('');
  let targetChar;
  const start = maxLen - 10;
  const end = maxLen + 20 > text.length ? text.length : maxLen + 20;
  for (let i = start; i < end; i += 1) {
    if (text[i] && text[i] === ' ') {
      targetChar === i;
      break;
    }
  }

  // get the nearest word break, if there's a reasonable one, or just slice away
  text = targetChar ? text.slice(0, targetChar) : text.slice(0, maxLen);

  return `${text.join('')}...`;
};

/**
 * @description A function that parse the user details based on the messageType.
 *
 * @example
 * If the messageType falls under the automated message type i.e. [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13] then we return the bodhi logo
 * otherwise the function returns the user picture along with the user initials, if the user picture does not exists then we render
 * user initials on UI
 *
 * @param sender
 * @param userPhoto
 * @param messageType
 * @returns
 */
export const getParsedUserDetails = (sender: string, userPhoto: string, messageType: number,view: string) => {
  let parsedUserPhoto;
  let userInitials;
  const isAutomatedMessage = AUTOMATED_MESSAGE_TYPE.includes(messageType);

  if (isAutomatedMessage) {
    if(view == 'installer'){
      parsedUserPhoto = bodhiLogoPng;
    } else {
      userInitials = 'B';
    }
  } else {
    parsedUserPhoto = userPhoto;
    userInitials = sender ? getInitialsFromName(sender) : undefined;
  }

  return {
    userPhoto: parsedUserPhoto,
    userInitials
  };
};

/**
 * @description A function that parse the homeowner touchHistory based on isActive being true.
 */
export const touchHistoryIsActive = (touchHistory: string[]) => {
  let parsedTouchHistoryArr: any[] = [];

  touchHistory && touchHistory.map((touch:any)=>{
    if(touch){
      touch = (typeof(touch) === 'string') ? JSON.parse(touch) : touch;
      if(touch.isActive){
        parsedTouchHistoryArr.push(touch);
      }
    }
  })

  return parsedTouchHistoryArr;
};

/**
 *
 * @param touchHistoryActive
 * @returns the most recent update date for the homeowner from touchHistory
 */

export const touchHistoryLastUpdate = (touchHistoryActive: any[]) => {
  let lastUpdate: number = 0;

  for (let i = 0; i < touchHistoryActive.length; i++) {
    if (touchHistoryActive[i].createdAt > lastUpdate) {
      lastUpdate = touchHistoryActive[i].createdAt;
    }
  }

  return lastUpdate;
};

/**
 *
 * @param touchHistoryActive
 * @returns most recent phase number for the homeowner
 */

export const touchHistoryMostRecentPhase = (touchHistoryActive: any[]) => {
  let mostRecentPhase: number = 0;

  for (let i = 0; i < touchHistoryActive.length; i++) {
    if (touchHistoryActive[i].phase > mostRecentPhase) {
      mostRecentPhase = touchHistoryActive[i].phase;
    }
  }

  return mostRecentPhase;
};

/**
 *
 * @param touchHistoryActive
 * @param mostRecentPhase
 * @returns the time in phase for the most recent phase
 */

export const touchHistoryTimeInPhase = (touchHistoryActive: any[], mostRecentPhase: number) => {
  let timeInPhaseArr: any[] = [];

  for (let i = 0; i < touchHistoryActive.length; i++) {
    if (touchHistoryActive[i].phase === mostRecentPhase) {
      timeInPhaseArr.push(touchHistoryActive[i].createdAt);
    }
  }
  timeInPhaseArr.sort((a, b) => a - b);
  const timeInPhase = timeInPhaseArr.length > 0 ? timeInPhaseArr[0] : null;
  return timeInPhase;
};

/**
 *
 * @param phaseNumber
 * @returns the phase name
 */

export const convertPhaseNumber = (phaseNumber: string) => {
  switch (phaseNumber) {
    case '1':
      return 'Design';
    case '2':
      return 'Permitting';
    case '3':
      return 'The Build';
    case '4':
      return 'Inspection';
    case '5':
      return 'Powering Up';

    default:
      return 'Powered Up';
  }
};

export const getPhaseNumber = (phaseName:string) => {
  phaseName = phaseName.replace(/ /g, '').toLowerCase();
  switch (phaseName) {
    case 'design':
      return 1;
    case 'permit':
      return 2;
    case 'thebuild':
      return 3;
    case 'inspection':
      return 4;
    case 'poweringup':
      return 5;
    default:
      return 6;
  }
}

/**
 *
 * @param type
 * @returns information for given fleet alerts
 */

export const convertFleetAlertType = (type: number) => {
  switch (type * 1) {
    case 1:
      return { text1: 'No data', img: 'yellownodata_icon.svg' };
    case 2:
      return { text1: 'No data', img: 'rednodata_icon.svg' };
    case 3:
      return { text1: 'No', text2: 'Production', img: 'yellownoproduction_icon.svg' };
    case 4:
      return { text1: 'No', text2: 'Production', img: 'rednoproduction_icon.svg' };
    case 5:
      return { text1: 'Low', text2: 'Production', img: 'yellowlowprod_icon.svg' };
    case 6:
      return { text1: 'Low', text2: 'Production', img: 'redlowprod_icon.svg' };
    default:
      return { text1: 'Online', img: 'greenCheckmark.svg' };
  }
};

/**
 * Function that returns properly formatted html messages
 * @param {string} message
 * @returns properly formatted html messages
 */
export const parseHtml = (html: string) => {
  const linkRegex = /<a href="([^"]*)">(.*?)<\/a>/g;
  const lineBreakRegex = /<br\s*\/?>/g;
  // const lineBreakRegex = /(?:<br\s*\/?>\s*){2,}/g;

  // Replace HTML line breaks with newlines
  const stringWithLineBreaks = html.replace(lineBreakRegex, '\n');

  let lastIndex = 0;
  const parts: (string | JSX.Element)[] = [];

  let match: RegExpExecArray | null;
  while ((match = linkRegex.exec(stringWithLineBreaks)) !== null) {
    const [fullMatch, href, text] = match;

    // Add any preceding text as a separate part
    if (lastIndex !== match.index) {
      parts.push(stringWithLineBreaks.slice(lastIndex, match.index));
    }

    // Add the link element
    parts.push(
      <a href={href} key={parts.length} className='premiumLinks' target="_blank">
        {text}
      </a>
    );

    lastIndex = match.index + fullMatch.length;
  }

  // Add any remaining text as a separate part
  if (lastIndex < stringWithLineBreaks.length) {
    parts.push(stringWithLineBreaks.slice(lastIndex));
  }

  return parts;
};
export const touchHistoryActiveArray = (touchHistory: any[]) =>{
  let touchActiveArr: string[] = [];

  touchHistory && touchHistory.map((touch)=>{
    if(touch){
      touch = (typeof(touch) === 'string') ? JSON.parse(touch) : touch;
      if(touch.isActive){
        touchActiveArr.push(touch?.touchName?.replace(/ /g,'').toLowerCase());
      }  
    }
  })
  return touchActiveArr;
}
