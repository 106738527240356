import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Select, Tooltip, Checkbox } from 'antd';

import {
  requestUpdateInstallerById,
  requestInviteEmployes,
  requestUpdateCompanyDetail,
  requestCompanyDetailSet
} from '../../actions/profile';
import { setLoader } from '../../../Common/Loader/action';
import { requestInstallerData } from '../../actions/addHomeOwner';

import { ReactComponent as CaretIcon } from '../../../../Assets/Images/path-2.svg';
import { imgInfoPng } from '../../../../constants/icons';
import { withRouter } from '../../../../shared';
import { suspenseFallback } from '../../../../constants/components';

const InviteEmployee = React.lazy(() => import('./InviteEmployee'));

const { Option } = Select;

class EmployeeAccountForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRender: false,
      installer: {},
      defaultInstaller: {}
    };
    this.slug = this.props.router.params.slug;
    this.companyId = this.props.router.params.companyId;
  }

  componentDidMount() {
    const { setLoader, requestInstallerData, installerInfo } = this.props;
    setLoader({ loader: true });
    this.disabledUpdateInstallersInfo = installerInfo.installerRole !== 1;
    requestInstallerData(this.companyId);
  }

  setEmployeeState(getInstaller) {
    let { installer, defaultInstaller } = this.state;
    getInstaller.forEach((installerData) => {
      installer[installerData.id] = installerData.installerRole;
      if (installerData.defaultInstaller && !installerData.isDisable) {
        defaultInstaller = installerData;
      }
    });
    this.props.setLoader({ loader: false });
    this.setState({
      setEmployeeState: true,
      installer,
      defaultInstaller
    });
  }

  componentDidUpdate(prevProps) {
    const { getInstaller, inviteResponse } = this.props;

    if (!this.state.setEmployeeState && getInstaller.data && getInstaller.data.length) {
      this.setEmployeeState(getInstaller.data);
    }

    if (
      (prevProps.inviteResponse && prevProps.inviteResponse !== inviteResponse) ||
      this.state.isRender
    ) {
      this.setState(
        {
          isRender: false
        },
        () => {
          this.props.requestInstallerData(this.companyId);
          this.props.setLoader({ loader: false });
        }
      );
    }
  }

  isRevoked = (payload) => {
    const data = {
      bodyData: { isDisable: !payload.isDisable },
      companyId: this.companyId,
      installerId: payload.id,
      isRevoked: true,
      message: payload.isDisable
        ? 'User has been restored successfully'
        : 'User has been revoked successfully'
    };
    this.setState({
      isRender: true
    });
    this.props.setLoader({ loader: true });
    this.props.requestUpdateInstallerById(data);
  };

  handleSelectChange = (e, index) => {
    const data = {
      bodyData: { installerRole: e },
      companyId: this.companyId,
      installerId: index.id,
      message: "User's Role has updated successfully"
    };
    const { installer } = this.state;
    installer[index.id] = e;
    this.setState({
      isRender: true,
      installer
    });
    this.props.setLoader({ loader: true });
    this.props.requestUpdateInstallerById(data);
  };

  onChangereferral = (e, data) => {
    const empData = {
      bodyData: { referralReport: e.target.checked },
      companyId: this.companyId,
      installerId: data.id,
      message: `User ${e.target.checked ? 'selected' : 'de-selected'} for Referral Report`
    };
    this.setState({
      isRender: true
    });
    this.props.setLoader({ loader: true });
    this.props.requestUpdateInstallerById(empData);
  };

  onChangeweekly = (e, data) => {
    const empData = {
      bodyData: { weeklyReport: e.target.checked },
      companyId: this.companyId,
      installerId: data.id,
      message: `User ${e.target.checked ? 'selected' : 'de-selected'} for Weekly Report`
    };
    this.setState({
      isRender: true
    });
    this.props.setLoader({ loader: true });
    this.props.requestUpdateInstallerById(empData);
  };

  handleSelectChangePM = (value, type) => {
    const e = value ? JSON.parse(value) : {};

    if (type === 'defaultInstaller') {
      const temp = {
        bodyData: { email: e.email ? e.email : '', installerId: e.id },
        companyId: this.companyId
      };
      this.props.requestUpdateCompanyDetail(temp);
    } else {
      const bodyData = {
        data: {
          companyInfo: {
            serviceManager: e.id
          }
        },
        companyId: this.companyId
      };
      this.props.setLoader({ loader: true });
      this.props.requestCompanyDetailSet(bodyData);
    }
    this.setState({
      [type]: e
    });
  };

  renderInstallerInfo(data) {
    return data.name ? (
      <Tooltip overlayClassName="black-tooltip" title={data.email}>
        <td className="metrics-data">{data.name}</td>
      </Tooltip>
    ) : (
      <td className="metrics-data">{data.email}</td>
    );
  }

  renderEmployeeAccountRow = (data, index) => {
    return (
      <tr key={data.id} className={data.isDisable ? 'metrics-row disabledEmpAcc' : 'metrics-row'}>
        {this.renderInstallerInfo(data)}
        <td className="metrics-data">
          <section
            className="white-box-select margin-0 acc-type-value"
            style={{ width: '90%', margin: '0 auto' }}>
            <Select
              disabled={this.disabledUpdateInstallersInfo}
              suffixIcon={<CaretIcon />}
              value={
                this.state.installer[data.id] && this.state.installer[data.id] === 1
                  ? 'Admin'
                  : 'User'
              }
              onChange={(e) => this.handleSelectChange(e, data)}>
              <Option value={1}>Admin</Option>
              <Option value={2}>User</Option>
            </Select>
          </section>
        </td>
        {window.$productSuite == 3 && (
          <td className="metrics-data">
            <label className="container">
              <Checkbox
                disabled={this.disabledUpdateInstallersInfo}
                onChange={(e) => this.onChangeweekly(e, data)}
                checked={data.weeklyReport}
              />
            </label>
          </td>
        )}

        {
          window.$productSuite !== 2 &&
          <td className="metrics-data">
            <label className="container">
              <Checkbox
                disabled={this.disabledUpdateInstallersInfo}
                onChange={(e) => this.onChangereferral(e, data)}
                checked={data.referralReport}
              />
            </label>
          </td>
        }
        
        <td className="metrics-data">
          <label className="container">
            <Checkbox
              onChange={(e) => this.isRevoked(data)}
              checked={!data.isDisable}
              disabled={this.disabledUpdateInstallersInfo}
            />
          </label>
        </td>
      </tr>
    );
  };

  renderDefaultPM = (data, selectInstaller, type) => {
    return (
      <Select
        suffixIcon={<CaretIcon />}
        disabled={this.disabledUpdateInstallersInfo}
        className="dropdown_widthPM"
        value={selectInstaller.name ? selectInstaller.name : selectInstaller.email}
        onChange={(e) => this.handleSelectChangePM(e, type)}>
        {data.map((installers) => (
          <Option value={JSON.stringify(installers)} key={installers.id}>
            {installers.name} {installers.name ? '-' : ''} {installers.email}
          </Option>
        ))}
      </Select>
    );
  };

  render() {
    return (
      <div className="installer-ui-container">
        <section className="standard-spacing">
          <div className="p-left p-top">
            <h2>Employee accounts</h2>

            <Suspense fallback={suspenseFallback}>
              <InviteEmployee
                setLoader={this.props.setLoader}
                companyId={this.companyId}
                slug={this.slug}
                disabledUpdateInstallersInfo={this.disabledUpdateInstallersInfo}
              />
            </Suspense>

            <div className="box-Shadow content-box metrics-table-box ml-0 w-100">
              <div className="table-box-header">
                <div className="mob-box" style={{ backgroundColor: 'white' }}>
                  Current users
                </div>
              </div>
              <div>
                <hr className="mob-enable" style={{ marginBottom: '0' }} />
                <div className="employee-account-info emp-header table-responsive">
                  <table className="metrics-table">
                    <thead>
                      <tr>
                        <th className="table-header seprator-right">Name</th>
                        <Tooltip
                          overlayClassName="black-tooltip"
                          title="Admin account roles can change company and homeowner settings. User account roles can view them only.">
                          <th className="table-header seprator-right">
                            Account role
                            <img src={imgInfoPng} />
                          </th>
                        </Tooltip>
                        {window.$productSuite == 3 && (
                          <Tooltip
                            overlayClassName="black-tooltip"
                            title="Receive monthly insight emails summarizing time saved with automated project updates, average installation timelines, and customer satisfaction scores.">
                            <th className="table-header seprator-right">
                              Monthly report
                              <img src={imgInfoPng} />
                            </th>
                          </Tooltip>
                        )}

                        {
                          window.$productSuite !== 2 && 
                          <Tooltip
                          overlayClassName="black-tooltip"
                          title="Receive daily email reports showing all referrals submitted for each day.">
                          <th className="table-header seprator-right">
                            Referral report
                            <img src={imgInfoPng} />
                          </th>
                        </Tooltip>
                        }         
                        <Tooltip
                          overlayClassName="black-tooltip"
                          title="Inactive accounts cannot log into Bodhi.">
                          <th className="table-header seprator-right">
                            Active
                            <img src={imgInfoPng} />
                          </th>
                        </Tooltip>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.getInstaller && this.props.getInstaller.data
                        ? this.props.getInstaller.data.map((item, index) =>
                            this.renderEmployeeAccountRow(item, index)
                          )
                        : null}
                    </tbody>
                  </table>
                </div>

                <div className="col-sm-12 text-center mb-4">
                  <span>Default PM</span>
                  {this.props.getInstaller && this.props.getInstaller.data
                    ? this.renderDefaultPM(
                        this.props.getInstaller.data,
                        this.state.defaultInstaller,
                        'defaultInstaller'
                      )
                    : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    updateInstallerById: state.setting.updateInstallerById,
    getInstaller: state.addhomeOwner.getInstaller || {},
    loader: state.loader.loader.loader,
    inviteResponse: state.setting.inviteEmployees,
    installerInfo: state.header?.getInstaller?.data || {}
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestUpdateInstallerById,
      requestInstallerData,
      requestInviteEmployes,
      requestUpdateCompanyDetail,
      requestCompanyDetailSet,
      setLoader
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(EmployeeAccountForm));
