// import npm packages
import { combineReducers } from "redux";

// import local files
import { RECEIVE_HOMEOWNER_SYSTEM_GLANCE,RECEIVE_HOMEOWNER_CAMPAIGN } from "../../types/dashboard/dashboard";

const homeownerSystemGlance = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_HOMEOWNER_SYSTEM_GLANCE:
			return data;
		default:
			return state;
	}
};

const homeownerCampaignData = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_HOMEOWNER_CAMPAIGN:
			return data;
		default:
			return state;
	}
};

export const reducer = combineReducers({
	homeownerSystemGlance,
	homeownerCampaignData
});