export const monitoringSystemData = (meterType,data) =>{
    if(!meterType){
        return;
    }
    meterType = isNaN(meterType) ? meterType.toLowerCase().trim() : meterType;
    const meterId = data.meterconId || data['Meter ID'];
    switch (meterType) {
        case 6:
        case 'fronius':
            return <span className="metrics-data"><a href={`https://www.solarweb.com/PvSystems/PvSystem?pvSystemId=${meterId}`} target="_blank">Fronius<i className="fa fa-external-link ml-2" style={{ "color": "#CCCCCC", cursor: "pointer" }} aria-hidden="true" /></a> </span>
        case 5:
        case 'apsystems':
            return <span className="metrics-data"><a href="https://apsystemsema.com/" target="_blank">APsystems<i className="fa fa-external-link ml-2" style={{ "color": "#CCCCCC", cursor: "pointer" }} aria-hidden="true" /></a> </span>
        case 4:
        case 'sense':
            return <span className="metrics-data">Sense</span>
        case 3:
        case 'enphase':
            return <span className="metrics-data">
                <a href={`https://enlighten.enphaseenergy.com/systems/${meterId}`} target="_blank">Enphase<i className="fa fa-external-link ml-2" style={{ "color": "#CCCCCC", cursor: "pointer" }} aria-hidden="true" /></a></span>
        case 2:
        case 'solaredge':
            return <span className="metrics-data"><a href={`https://monitoring.solaredge.com/solaredge-web/p/site/${meterId}`} target="_blank">SolarEdge<i className="fa fa-external-link ml-2" style={{ cursor: "pointer", "color": "#CCCCCC" }} aria-hidden="true" /></a></span>
        case 1:
        case 'egauge':
            return <span className="metrics-data"><a href={data.meterUrl && data.meterUrl.includes('//') ? data.meterUrl : '//' + data.meterUrl} target="_blank">eGauge<i className="fa fa-external-link ml-2" style={{ cursor: "pointer", "color": "#CCCCCC" }} aria-hidden="true" /></a></span>
    }
}

export const monitoringSystemUrl = (meterType,data) =>{
    const meterId = data.meterconId || data['Meter ID'];
    switch(meterType){
        case 6:
            return <span  className="metrics-data"><a href={`https://www.solarweb.com/PvSystems/PvSystem?pvSystemId=${meterId}`} target="_blank"><i className="fa fa-external-link ml-2" style={{"color":"#ff6f47",cursor : "pointer"}} aria-hidden="true"/></a> </span>
        case 5:
            return <span  className="metrics-data"><a href="https://apsystemsema.com/" target="_blank"><i className="fa fa-external-link ml-2" style={{"color":"#ff6f47",cursor : "pointer"}} aria-hidden="true"/></a> </span>
        case 4:
            return <span  className="metrics-data"></span>
        case 3:
            return <span  className="metrics-data">
                <a href={`https://enlighten.enphaseenergy.com/systems/${meterId}`} target="_blank"><i className="fa fa-external-link ml-2" style={{"color":"#ff6f47",cursor : "pointer"}} aria-hidden="true"/></a></span>
        case 2:
            return <span  className="metrics-data"><a href={`https://monitoring.solaredge.com/solaredge-web/p/site/${meterId}`} target="_blank"><i className="fa fa-external-link ml-2" style={{cursor : "pointer","color":"#ff6f47"}} aria-hidden="true"/></a></span>
        case 1:
            return <span  className="metrics-data"><a href={data.meterUrl && data.meterUrl.includes('//') ? data.meterUrl : '//'+data.meterUrl} target="_blank"><i className="fa fa-external-link ml-2" style={{cursor : "pointer","color":"#ff6f47"}} aria-hidden="true"/></a></span>
        
    }
}
