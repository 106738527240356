import React from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import { configureShare, launchShareWindow, makeShareUrl } from './helpers';
import { deriveUsageStateFromUrl } from '../../../../shared';

import {
  whatsAppIconPng,
  facebookIconSvg,
  smsIconSvg,
  twitterIconSvg
} from '../../../../constants/icons';

import { SHARE_MESSAGE } from './constants';
import { IHomeownerShare } from './types';

const defaultSharename = 'glance';

/**
 * Render any of the various representations of a Homeowner Share
 *
 * @params {Object} props
 * @return React FC
 */
export const HomeownerShare: React.FC<IHomeownerShare> = (props) => {
  const { loggedInUser, isInstaller, dyId, shareName,isDefaultShow } = props;
  const referralId = loggedInUser && loggedInUser?.homeownerData?.referralId;

  const slug = deriveUsageStateFromUrl().slug;

  const otherProps: Record<string, string> = {};

  if (!!dyId) {
    otherProps.id = `collapseShareIcons-status-feed-${dyId}`;
  }

  return (
    <div {...otherProps} className={`white-box share-via-wrapper collapse ${isDefaultShow ? 'show' : ''}`}>
      <div className="heading">Share via:</div>
      {!isInstaller ? (
        <ul>
          <li>
            <FacebookShareButton
              beforeOnClick={() => configureShare(1, loggedInUser, shareName || defaultSharename)}
              quote={SHARE_MESSAGE}
              url={makeShareUrl(1, slug, referralId)}>
              <a href="#" className="facebook">
                <img src={facebookIconSvg} alt="facebook.svg" width="25" />
              </a>
            </FacebookShareButton>
          </li>
          <li>
            <TwitterShareButton
              beforeOnClick={() => configureShare(2, loggedInUser, shareName || defaultSharename)}
              title={SHARE_MESSAGE}
              url={makeShareUrl(2, slug, referralId)}>
              <a href="#" className="facebook">
                <img src={twitterIconSvg} alt="twitter.svg" width="25" />
              </a>
            </TwitterShareButton>
          </li>
          <li className="disabled">
            <a
              onClick={() =>
                launchShareWindow(
                  `https://api.whatsapp.com/send?text=${SHARE_MESSAGE}${makeShareUrl(
                    4,
                    slug,
                    referralId
                  )}`,
                  4,
                  loggedInUser,
                  shareName || defaultSharename
                )
              }
              href="#"
              className="message">
              <img src={whatsAppIconPng} alt="sms.svg" width="25" />
            </a>
          </li>
          <li className="disabled">
            <a
              onClick={() =>
                launchShareWindow(
                  `sms:?&body=${SHARE_MESSAGE}${makeShareUrl(3, slug, referralId)}`,
                  3,
                  loggedInUser,
                  shareName || defaultSharename
                )
              }
              href="#"
              className="message">
              <img src={smsIconSvg} alt="sms.svg" width="25" />
            </a>
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <img
              src={facebookIconSvg}
              alt="facebook.svg"
              width="25"
              style={{ cursor: 'not-allowed' }}
            />
          </li>
          <li>
            <img
              src={twitterIconSvg}
              alt="twitter.svg"
              width="25"
              style={{ cursor: 'not-allowed' }}
            />
          </li>
          <li>
            <img
              src={whatsAppIconPng}
              alt="twitter.svg"
              width="25"
              style={{ cursor: 'not-allowed' }}
            />
          </li>
          <li>
            <img src={smsIconSvg} alt="sms.svg" width="25" style={{ cursor: 'not-allowed' }} />
          </li>
        </ul>
      )}
    </div>
  );
};

export default HomeownerShare;
