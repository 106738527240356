import styled from 'styled-components';

import { StyledSiderMenu } from './';
import BodhiLogo from '../../../../Assets/Images/bodhiYellowO_icon.svg';

export const MainNavCountDecorator = styled.span`
  &.count {
    position: absolute;
    color: ${(props) => props.theme.colors.white};
    left: 35%;
    top: 5%;
    font-size: 14px;
    font-family: '${(props) => props.theme.fonts.standardBold}';
    line-height: 20px;
    width: auto;
    padding: 0 4px;
    height: 18px;
    background-color: ${(props) => props.theme.colors.orange};
    margin-top: 2px;
    border-radius: ${(props) => props.theme.borderRadius.faint};
    text-align: center;
    transform: translate(-50%, -50%);
    &.zero {
      display: none;
    }
  }
`;

export const StyledSiderMenuMain = styled(StyledSiderMenu)`
  padding-top: 3px;
  .dashboard-nav-wrapper {
    padding-bottom: 22px;
    .navbar-brand.installerView {
      background-image: url(${BodhiLogo});
      background-size: 40px;
    }
  }

  li:hover > svg g {
    fill: ${(props) => props.theme.colors.yellow};
  }

  svg.sider-icon {
    width: 100%;
    margin-bottom: 2px;
    margin-top: 5px;
  }
  .ant-menu-dark .ant-menu-item:hover > span > a {
    color: ${(props) => props.theme.colors.yellow};
  }
  li.ant-menu-item.siderActive {
    a {
      color: ${(props) => props.theme.colors.yellow};
    }
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item {
    height: auto;
    line-height: 1;
    padding: 0px !important;
    flex-direction: column;
    text-overflow: initial;
    margin-top: 6px;
    margin-bottom: 20px;
    &.inbox-counter {
      position: relative;
      overflow: visible;
    }
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
    text-align: center;
    padding: 4px 0;
    margin: 0;
  }
  .ant-menu-title-content {
    white-space: initial;
    a {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      display: block;
    }
  }
`;
