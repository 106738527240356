export const REQUEST_SEARCH_DATA = "/screens/Dashboard/data/SEARCH/REQUEST_API_DATA";
export const RECEIVE_SEARCH_DATA = "/screens/Dashboard/data/SEARCH/RECEIVE_API_DATA";
export const RECEIVE_INSTALLER_DATA = "/screens/Dashboard/data/Header/RECEIVE_INSTALLER_DATA";
export const REQUEST_INSTALLER_DATA_BY_ID = "/screens/Dashboard/data/Header/REQUEST_INSTALLER_DATA";
export const RECEIVE_HOMEOWNER_DATA_BY_ID = "/screens/Dashboard/data/Header/RECEIVE_HOMEOWNER_DATA";
export const REQUEST_HOMEOWNER_DATA_BY_ID = "/screens/Dashboard/data/Header/REQUEST_HOMEOWNER_DATA";

export const requestSearchData = data => ({ type: REQUEST_SEARCH_DATA, data });
export const receiveSearchData = data => ({ type: RECEIVE_SEARCH_DATA, data });
export const receiveInstallerData = data => ({ type: RECEIVE_INSTALLER_DATA, data });
export const requestInstallerDataById = data => ({ type: REQUEST_INSTALLER_DATA_BY_ID, data });
export const receiveHomeownerData = data => ({ type: RECEIVE_HOMEOWNER_DATA_BY_ID, data });
export const requestHomeownerData = data => ({ type: REQUEST_HOMEOWNER_DATA_BY_ID, data });