import React, { useState, useEffect, useMemo, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { Spin } from 'antd';
import type { RootState } from '../../../../store';
import store from '../../../../store';
import {
  fetchMessagesByThreadId,
  createMessageByThreadId,
  assignCurrentThreadId
} from '../../../../store/InboxSlice';

import { IntersectionElement } from '../../../Common';
import type { TFetchRequestArgs } from '../../../Common/api/types';

const MessageComposer = React.memo(
  React.lazy(() => import('../../components/inbox/MessageComposer'))
);
const MessageComposerControls = React.lazy(
  () => import('../../components/inbox/MessageComposerControls')
);
const HomeownerDetail = React.lazy(() => import('../../components/inbox/HomeownerDetail'));
import { InboxDecorator } from './Decorator';

import { AddHardware } from '../../components/companySetting/AddCompanyHardware';

import {
  IInboxThread,
  TMessagesLUT,
  TMessageComposition,
  TMessagesApiResponse,
  THomeownerPayload,
  TComponentModel,
  TChildrenProps,
  IEversignRequest
} from './types';

import { loadingIcon, suspenseFallback } from '../../../../constants/components';
import { withRouter } from '../../../../shared';
import {
  IterableMap,
  componentModel,
  retrieveHomeownerIdFromMessages,
  retrieveSortedThreads
} from './helpers';

import {
  userScrollUpTargetElementId,
  userScrollDownTargetElementId,
  messageChannelIdentifiers,
  ParticipantType,
  messagesDisplayOptions,
  FILE_UPLOAD_STATUS
} from '../../components/inbox/constants';
import { TThreads } from '../../../../types';
import Message from '../../../../classes/Message';
import {
  useHomeownerData,
  useHomeownerEnergyReport,
  useHomeownerPastEnergyReport,
  useHomeownerNotes,
  useHomeownerSatisfactionScore,
  useHomeownerDocuments,
  useHomeownerEversign,
  useHomeownerFinancialReport
} from '../../components/shared/HomeownerDataHooks';
import { getPhaseNumber } from '../../components/inbox/helpers';
import { IHomeownerUpdatePayload, IInboxMessageAttachment } from '../../components/inbox/types';
import { getHomeownerAddNoteData, getUpdateHomeOwnerApi } from '../../api/homeOwner';
import { EversignIntegration } from '../../eversign/EversignIntegration';
import { EversignPrepareDocument } from '../../eversign/PrepareDocument';
import { FileUploadProgress } from '../../components/inbox/FileUploadProgress';
import { maxMessageCharCount, MessageAttachmentType } from '../../../../constants';
import { DarkModal } from '../../../Common/DarkModal';
import { uploadImageonS3 } from '../../../Common/S3FileUpload';
import { ILiteTemplate } from '../../components/shared/types';

/**
 * Handle calls to persist messages and notes
 */
const handleCreateMessage = (params: TMessageComposition) => {
  return store.dispatch(createMessageByThreadId(params));
};

const initialMessageText = '';
const initialMessageAttachments: IInboxMessageAttachment[] = [];
const initialMessageThread: TMessagesApiResponse = {
  messages: [],
  metadata: {
    messageCount: 0,
    lastCreatedAt: null,
    firstCreatedAt: null,
    page: 0,
    nextPage: false,
    items: 0,
    totalUnread: 0
  }
};

const handleFetchNextThreadPayload = async (args: TFetchRequestArgs) => {
  const { threadId, page } = args;
  await store.dispatch(
    fetchMessagesByThreadId({
      page,
      threadId
    })
  );
};

const messagesState = createSelector(
  (state: RootState) => state.inboxMessages.messageData.messages,
  (messages) => messages
);

const messageMetatDataState = createSelector(
  (state: RootState) => state.inboxMessages.messageData.metadata,
  (metadata) => metadata
);

const threadsState = createSelector(
  (state: RootState) => state.inboxMessages.threadsData.threads,
  (threads) => threads
);

const searchResultsState = createSelector(
  (state: RootState) => state.inboxMessages.threadsData.searchResults,
  (searchResults) => searchResults
);

const sendingState = createSelector(
  (state: RootState) => state.inboxMessages.sending.messages.status,
  (sendingMessages) => (sendingMessages ? true : false)
);

const threadsLoadingSelector = createSelector(
  (state: RootState) => state.inboxMessages.loading.threads,
  (threadsLoadingState) => threadsLoadingState
);

const pollingState = createSelector(
  (state: RootState) => state.inboxMessages.executing.polling,
  (polling) => polling
);

const currentThreadIdState = createSelector(
  (state: RootState) => state.inboxMessages.threadsData.currentThreadId,
  (currentThreadId) => currentThreadId
);

const messagesDisplayOptionState = createSelector(
  (state: RootState) => state.inboxMessages.user.messagesDisplayOption,
  (messagesDisplayOption) => messagesDisplayOption
);

const { MY_MESSAGES } = messagesDisplayOptions;

/**
 * The primary controller for the Installer Inbox.
 * Renders all concerns to provide a ui for threads/conversations for Bodhi Installers
 */
const Inbox: React.FC<IInboxThread> = (props) => {
  const { companyId, userId: installerId, router, companyJourney, userRole, installerDetail, companyInformation } = props;

  const { threadId: urlThreadId, slug } = router.params;

  const [fetchingThreadData, setFetchingThreadData] = useState(false);
  const [addingMessage, setAddingMessage] = useState<Record<'channel', number | undefined>>({
    channel: undefined
  });
  const [messageText, setMessageText] = useState(initialMessageText);
  const [messageAttachments, setMessageAttachments] = useState([]);
  const [templateType, setTemplateType] = useState<string | null>();
  const [entryTime, updateEntryTime] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const [threadId, setThreadIdParam] = useState(urlThreadId || '');
  const [teardownSemaphore, setTeardownSemaphore] = useState(false);
  const [sinceLastRequest, setSinceLastRequest] = useState(0);
  const [homeownerDetailIsOpen, setHomeownerDetailIsOpen] = useState(true);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [displayFullScreenLoader, setDisplayFullScreenLoader] = useState(false);

  const [templateTypeConfirmation, setTemplateTypeConfirmation] = useState<boolean>();

  const [addNotePopUp, setAddNotePopUp] = useState<boolean>(false);
  const dispatch = useDispatch();

  const messages = useSelector(messagesState) || initialMessageThread.messages;
  const metadata = useSelector(messageMetatDataState) || initialMessageThread.metadata;
  const threads = useSelector(threadsState);
  const searchResults = useSelector(searchResultsState);
  const sending = useSelector(sendingState);
  const polling = useSelector(pollingState);
  const currentThreadIdFromState = useSelector(currentThreadIdState);
  const messagesDisplayOption = useSelector(messagesDisplayOptionState);
  const threadsLoadingState = useSelector(threadsLoadingSelector);

  // We might not have any messages, and we might need to reference search results for our data
  const homeownerId = useMemo(() => {
    if (messages.length) {
      return retrieveHomeownerIdFromMessages(messages);
    }
    /**
     * [!] Note: Not converting searchResults to instances of `Thread`, given use case is so simple right now.
     * TODO: convert searchResults to instances of `Thread` if/when needed.
     */
    if (searchResults && searchResults.length) {
      if (currentThreadIdFromState) {
        const matchingResults = searchResults.filter(
          (thread: TThreads) => thread.id === currentThreadIdFromState
        );
        if (matchingResults.length) {
          return matchingResults[0].homeowner.id;
        }
      }
    }
    if (!searchResults && messages && !messages.length) {
      if (currentThreadIdFromState && threads && threads.length) {
        const matchingResults = threads.filter(
          (thread: TThreads) => thread.id === currentThreadIdFromState
        );
        if (matchingResults.length) {
          return matchingResults[0].homeowner.id;
        }
      }
    }
  }, [
    messages,
    searchResults,
    currentThreadIdFromState,
    messagesDisplayOption,
    threads,
    searchResults
  ]);

  const messageCount = metadata?.messageCount || 0;
  // case: there are no messages in the current message thread
  const noMessagesInThread = metadata && metadata.messageCount === 0;
  const nextPage = metadata?.nextPage || false;
  const fetchedMessageCount = (messages && messages.length) || 0;

  // cover all cases where we should display loading state ... and not
  const messageThreadIsLoading = useMemo(() => {
    /**
     * [!] Override case where we know we aren't loading because we find that there is zilch to load...
     */
    if (!fetchingThreadData && noMessagesInThread === true) {
      return false;
    }
    return (
      fetchingThreadData ||
      !threadId ||
      !!(urlThreadId && threadId !== urlThreadId) ||
      !isMounted ||
      !messages?.length
    );
  }, [fetchingThreadData, urlThreadId, threadId, isMounted, messages, noMessagesInThread]);

  const messagesMap = useMemo(() => {
    let hasNoMessagesOverride = false;
    if (messagesDisplayOption === MY_MESSAGES && threads.length === 0 && !searchResults) {
      hasNoMessagesOverride = true;
    }

    return IterableMap(!hasNoMessagesOverride ? [...messages] : [], 'createdAt', (iterable) => {
      return {
        key: Number(moment(iterable.createdAt).format('x')),
        value: new Message(iterable)
      };
    }) as TMessagesLUT;
  }, [messages, threads, messagesDisplayOption, searchResults]);

  const moreMessagesAvailable = nextPage || (messageCount && messageCount > fetchedMessageCount);

  useEffect(() => {
    if (searchResults) {
      setHomeownerDetailIsOpen(true);
    } else {
      if (threads.length === 0) {
        setHomeownerDetailIsOpen(false);
      } else {
        setHomeownerDetailIsOpen(true);
      }
    }
  }, [threads, searchResults]);

  useEffect(() => {
    setFileUploadProgress(fileUploadProgress);
    if (fileUploadProgress) {
      setDisplayFullScreenLoader(true);
    } else {
      setDisplayFullScreenLoader(false);
    }
  }, [fileUploadProgress]);

  const areThreadsEmpty = useMemo(() => threads.length === 0, [threads]);

  // subscribe to changes to the router parameters to bootstrap in absence of urlThreadId
  useEffect(() => {
    if (!urlThreadId) {
      if (threads?.length) {
        let defaultThreadId: string = '';
        // first, see if we have a cached value for the last chosen threadId
        const cachedThreadId = currentThreadIdFromState;
        if (!cachedThreadId) {
          const sortedThreads: TThreads[] = retrieveSortedThreads([...threads]);
          defaultThreadId = sortedThreads[0].id;
          dispatch(
            assignCurrentThreadId({
              threadId: defaultThreadId
            })
          );
        }
        setThreadIdParam(cachedThreadId || defaultThreadId);
      }
    }
  }, [urlThreadId, threads]);

  // subscribe to changes to router parameters and threadId state to trigger fetches
  useEffect(() => {
    if (urlThreadId) {
      if (threadId !== urlThreadId) {
        // case: generic navigation
        if (messageText) {
          setMessageText(initialMessageText);
        }
        setMessageAttachments([]);
        setThreadIdParam(urlThreadId);
        setCurrentPagination(1);
        setFetchingThreadData(true);
        setHomeownerDetailIsOpen(true);
      } else {
        // case: deep link into thread
        setFetchingThreadData(true);
        dispatch(
          assignCurrentThreadId({
            threadId: urlThreadId
          })
        );
      }
    } else {
      if (threadId) {
        setCurrentPagination(1);
        setFetchingThreadData(true);
      }
    }
  }, [urlThreadId, threadId]);

  useEffect(() => {
    if (fetchingThreadData) {
      handleFetchNextThreadPayload({
        page: currentPagination || (metadata && metadata.page + 1),
        threadId
      }).then(() => {
        setSinceLastRequest(Date.now());
        setFetchingThreadData(false);
      });
    }
  }, [fetchingThreadData]);

  /**
   * @todo Looks like we need an additional side effect handler here for now, as this block doesn't execute without
   * listening directly to changes to homeownerId, versus say listening to changes to threadId
   */

  /**
   * need to fetch homeowner id from search or passed in when clicked
   */
  let hoId = homeownerId;
  if ((homeownerId || searchResults?.length) && companyId) {
    hoId = homeownerId || searchResults[0]?.homeowner?.id;
  }

  /**
   * use custom hooks to find data for homeowner information
   */
  const [fetchHomeownerData, setFetchHomeownerData] = useState(false);
  const [homeownerData, homeownerError] = useHomeownerData(hoId, companyId, fetchHomeownerData);
  const homeownerPastEnergyReport = useHomeownerPastEnergyReport(hoId);
  const homeownerEnergyReport = useHomeownerEnergyReport(hoId);
  const [fetchHomeownerNotes, setFetchHomeownerNotes] = useState(false);
  const homeownerNotes = useHomeownerNotes(hoId, fetchHomeownerNotes);
  const [fetchHomeownerDocuments, setFetchHomeownerDocuments] = useState(false);
  const homeownerDocuments = useHomeownerDocuments(hoId, companyId, fetchHomeownerDocuments);

  const [fetcHomeownerEversign, setFetchHomeownerEversign] = useState(false);
  const homeownerEversignData = useHomeownerEversign(hoId, companyId, fetcHomeownerEversign);

  const homeownerFinancialReport = useHomeownerFinancialReport(hoId,installerDetail?.isSunlight);

  const homeownerSatisfactionScore = useHomeownerSatisfactionScore(hoId, companyId);
  const [eversignRequest, setEversignRequest] = useState<IEversignRequest>({
    eversignRequestPopUp: false,
    prepareDocumentPopUp: false
  });

  const sendPhaseTemplate = () => {
    const touchHistory: any = homeownerData.solarUnit.touchHistory;
    const templateDetail: ILiteTemplate = companyJourney[templateType];

    touchHistory[templateDetail.fixedIndex] = {
      createdAt: new Date().getTime(),
      phase: getPhaseNumber(templateDetail.phaseName),
      isActive: true,
      index: templateDetail.fixedIndex,
      touchName: templateDetail.touchName
    };

    const messageContent = {
      [templateDetail.touchName]: {
        emailBody: messageText,
        sms: messageText,
        emailSubject: templateDetail.emailSubject,
        touchName: templateDetail.touchName,
        phase: [
          {
            content: messageText
          }
        ]
      }
    };

    const payload: IHomeownerUpdatePayload = {
      companyId: companyId,
      homeOwnerId: hoId,
      slug: slug,
      productSuite: 1,
      bodyData: {
        firstName: homeownerData.homeownerData.firstName,
        lastName: homeownerData.homeownerData.lastName,
        email: homeownerData.homeownerData.email,
        phone: homeownerData.homeownerData.phone,
        address: homeownerData.homeownerData.address,
        companyId: companyId,
        homeownerId: hoId,
        slug: slug,
        touchHistory: touchHistory
      },
      companyS3Data: messageContent
    };
    setDisplayFullScreenLoader(true);
    // API to update homeowner information and journey
    getUpdateHomeOwnerApi(payload).then(() => {
      // Make another request to fetch homeowner journey data
      setFetchHomeownerData(!fetchHomeownerData);
      setAddingMessage({ channel: undefined });
      setMessageText(initialMessageText);
      //  Make another request to update center pane with delay as data stored in INBOX later
      setTimeout(() => {
        setDisplayFullScreenLoader(false);
        setFetchingThreadData(true);
      }, 2500);
    });
  };

  // upload documents in s3 async
  const uploadFileInS3 = async (): Promise<IInboxMessageAttachment[]> => {
    // function to update state from s3FileUpload.ts upload function
    const setFileUploadStatusState = (fileName: string, value: number) => {
      let fileIndex: number;
      const filterFile = messageAttachments.filter((file: any, index) => {
        fileIndex = index;
        return file.name === fileName;
      })[0];

      if (filterFile && filterFile.uploadStatus !== value) {
        filterFile.uploadStatus = value;
        messageAttachments[fileIndex].uploadStatus = value;
      }
      setMessageAttachments(messageAttachments);
    };

    const promiseArr: any = [];
    let totalFilesSize = 0;
    messageAttachments.map((file: any) => {
      totalFilesSize += file.size;
    });

    setFileUploadProgress(1);
    messageAttachments.map((attachment: any, index: number) => {
      messageAttachments[index].uploadStatus = FILE_UPLOAD_STATUS.IS_UPLOADING;
      promiseArr.push(
        uploadImageonS3(
          `public/bodhi-lite/homeowner/${homeownerId}`,
          attachment,
          totalFilesSize,
          setFileUploadProgress,
          setFileUploadStatusState
        )
      );
    });
    setMessageAttachments(messageAttachments);

    return Promise.all(promiseArr).then((result) => {
      setFileUploadProgress(0);
      let attachments: IInboxMessageAttachment[] = result.map((file) => {
        if (file?.fileName) {
          return {
            url: file.path,
            label: file.fileName,
            type: file.extension.includes('pdf')
              ? MessageAttachmentType.PDF
              : MessageAttachmentType.IMAGE
          };
        }
      });
      return attachments;
    });
  };

  const sendInboxMessage = async () => {
    const recipientId =
      addingMessage.channel !== messageChannelIdentifiers.note ? homeownerId : installerId;

    if (!recipientId) {
      console.error(`There was a problem sending the message: 'recipientId' not resolvable`);
    }
    const recipientType =
      addingMessage.channel === messageChannelIdentifiers.note
        ? ParticipantType.INSTALLER
        : ParticipantType.HOMEOWNER;

    const attachment = messageAttachments.length > 0 ? await uploadFileInS3() : [];
    setDisplayFullScreenLoader(true);
    setAddingMessage({ channel: undefined });
    setMessageAttachments(initialMessageAttachments);
    setMessageText(initialMessageText);
    handleCreateMessage({
      senderId: installerId,
      senderType: ParticipantType.INSTALLER,
      recipientId,
      recipientType,
      message: messageText,
      subject: '',
      channel: addingMessage.channel,
      attachments: attachment,
      threadId
    }).then((res: TMessagesApiResponse) => {
      setFetchingThreadData(true); // kick off another request to ensure we get the updated message data in the thread
      setDisplayFullScreenLoader(false);
      attachment.length > 0 && setFetchHomeownerDocuments(!fetchHomeownerDocuments); // fetch documents for right pane
    });
  };

  useEffect(() => {
    let mounted = true;
    /**
     * 1. If we are fetching, don't fetch
     * 2. If we have all available messages, don't fetch
     * 3. If we just updated, in terms of timestamp, wait a tick
     */
    if (threadId && entryTime && !fetchingThreadData && moreMessagesAvailable) {
      if (mounted && (!sinceLastRequest || Date.now() - sinceLastRequest > 1000)) {
        setCurrentPagination(metadata.page + 1);
        setFetchingThreadData(true);
        updateEntryTime(0);
      }
    }
    return () => {
      mounted = false;
    };
  }, [entryTime]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    return () => {
      // because this also runs on initial execution, we can use the outcome, and connect it to our dedicated teardown effect
      setTeardownSemaphore(true);
    };
  }, [isMounted]);

  //  executed only for bodhi lite and journey template
  useEffect(() => {
    if (
      homeownerData?.solarUnit?.touchHistory &&
      (window as any).$productSuite === 1 &&
      templateType
    ) {
      setTemplateTypeConfirmation(true);
    }
  }, [templateType]);

  useEffect(() => {
    if (addingMessage.channel !== undefined) {
      if (messageText) {
        sendInboxMessage();
      }
    }
  }, [addingMessage, messageText]);

  const propsData = {
    threadId,
    polling,
    loading: messageThreadIsLoading,
    loader: 'local',
    userId: installerId,
    messagesLut: messagesMap,
    metadata: metadata,
    isOpened: homeownerDetailIsOpen,
    fetchingThreadData,
    messagesDisplayOption,
    homeownerEversignData,
    toggleDrawer: () => {
      setHomeownerDetailIsOpen((prev) => !prev);
    },
    elementId: userScrollDownTargetElementId
  };

  const messagesSize = useMemo(() => {
    if (searchResults) {
      return searchResults?.length;
    } else {
      return threads?.length;
    }
  }, [threads, searchResults]);

  const {
    fc: MessageThreadVariant,
    props: fcProps,
    children
  }: TComponentModel = useMemo(
    () => componentModel(messagesSize, threadsLoadingState, propsData),
    [threads, threadsLoadingState, propsData]
  );

  const addNote = (key: string, value: any) => {
    if (key === 'note' && value) {
      setDisplayFullScreenLoader(true);
      getHomeownerAddNoteData({
        homeownerId: homeownerId,
        installerId: installerId,
        note: value,
        threadId
      }).then(() => {
        // Make another request with delay to update center pane & notes with delay as notes saved later in INBOX
        setTimeout(() => {
          setDisplayFullScreenLoader(false);
          setFetchingThreadData(true);
        }, 2000);
        setFetchHomeownerNotes(!fetchHomeownerNotes);
      });
    }
    setAddNotePopUp(false);
  };

  return (
    <Suspense fallback={suspenseFallback}>
      {fileUploadProgress && <FileUploadProgress fileUploadProgress={fileUploadProgress} />}
      <Spin spinning={displayFullScreenLoader} indicator={loadingIcon} style={{ color: '#04bfff' }}>
        <InboxDecorator className={userRole}>
          {/* Pop up modal for confirmation for type of template before sending */}
          {templateTypeConfirmation && companyJourney[templateType]?.phaseName && (
            <DarkModal
              width={600}
              theme="light"
              closePopUp={() => {
                setTemplateType(null);
                setTemplateTypeConfirmation(!templateTypeConfirmation);
              }}
              headerText={`You're sending ${homeownerData?.homeownerData?.firstName} the ${companyJourney[templateType].display} phase message.`}
              subText={`Do you also want to advance ${homeownerData?.homeownerData?.firstName} to the ${companyJourney[templateType].display} Phase? Selecting "Send message only" will send the message but will not advance ${homeownerData?.homeownerData?.firstName} to the ${companyJourney[templateType].display} Phase`}
              footerType={'templateConfirmation'}
              setPhaseTemplate={() => {
                sendPhaseTemplate();
              }}
              setInboxMessage={() => {
                setAddingMessage({
                  channel: messageChannelIdentifiers.app
                });
              }}
            />
          )}

          {/* PopUp modal for eversign signature setUp */}
          {homeownerData?.homeownerData && eversignRequest.eversignRequestPopUp && (
            <EversignIntegration
              installerDetail={installerDetail}
              homeownerData={homeownerData}
              homeownerId={hoId}
              eversignPopUp={true}
              documentName={eversignRequest.documentName}
              documentURL={eversignRequest.documentURL}
              setDisplayFullScreenLoaderState={(displayFullScreenLoader: boolean) => {
                setDisplayFullScreenLoader(displayFullScreenLoader);
              }}
              setEversignModel={(obj: IEversignRequest) => setEversignRequest(obj)}
              slug={slug}
            />
          )}

          {/* popUp modal for eversign document prepare iFRAME */}
          {homeownerData?.homeownerData && eversignRequest.prepareDocumentPopUp && (
            <EversignPrepareDocument
              embedded_claim_url={eversignRequest.embedded_claim_url}
              prepareDocumentVisible={eversignRequest.prepareDocumentPopUp}
              fetchLatestInboxMessage={() => {
                setDisplayFullScreenLoader(true);
                setTimeout(() => {
                  setDisplayFullScreenLoader(false);
                  setFetchHomeownerEversign(true);
                  setFetchingThreadData(true);
                }, 2500);
              }}
              setEversignModel={(obj: IEversignRequest) => setEversignRequest(obj)}
            />
          )}

          {/* popUp modal to ADD notes*/}
          {addNotePopUp && (
            <AddHardware
              popWidth={700}
              className="add-note"
              viewName="note"
              headerImg="note_icon.svg"
              addNote={true}
              addPopUp={true}
              updateState={addNote}
            />
          )}

          {moreMessagesAvailable === true && (
            <IntersectionElement
              height={30}
              elementId={userScrollUpTargetElementId}
              handleIsIntersecting={(onscreen: boolean) => {
                updateEntryTime(Date.now());
              }}
            />
          )}

          {MessageThreadVariant && (
            <MessageThreadVariant
              {...(fcProps && fcProps)}
              homeownerEversignData={homeownerEversignData}>
              {children.length > 0 &&
                children.map((child: TChildrenProps,index:number) => (
                  <child.fc {...(child.props && child.props)} key={index}/>
                ))}
            </MessageThreadVariant>
          )}

          <MessageComposer
            isOpened={homeownerDetailIsOpen}
            loading={sending}
            dimensions={messageText.length >= maxMessageCharCount ? [7, 12] : [4, 12]}
            placeholder="Type to add to this conversation"
            editHandler={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setMessageText(event.target.value);
            }}
            content={messageText}
            disabled={areThreadsEmpty}>
            <MessageComposerControls
              content={messageText}
              messageThreadIsLoading={messageThreadIsLoading}
              sendMessageHandler={() => {
                setAddingMessage({
                  channel: messageChannelIdentifiers.app
                });
              }}
              setTemplateTypeState={(templateType: string) => setTemplateType(templateType)}
              setMessageTextState={(messageText) => setMessageText(messageText)}
              setMessageAttachmentsState={(messageAttachments) =>
                setMessageAttachments(messageAttachments)
              }
              messageAttachments={messageAttachments}
              companyJourney={companyJourney}
              disabled={areThreadsEmpty}
              touchHistory={homeownerData?.solarUnit?.touchHistory}
              companyInformation = {companyInformation}
            />
          </MessageComposer>
          <HomeownerDetail
            isOpened={homeownerDetailIsOpen}
            companyId={companyId}
            slug={slug}
            homeownerData={homeownerData}
            energyReport={homeownerEnergyReport}
            pastEnergyReport={homeownerPastEnergyReport}
            error={homeownerError}
            homeownerNotes={homeownerNotes}
            fetchUpdateDocuments={() => {
              setFetchHomeownerDocuments(!fetchHomeownerDocuments);
            }}
            homeownerDocuments={homeownerDocuments}
            homeownerEversignData={homeownerEversignData}
            homeownerSatisfactionScore={homeownerSatisfactionScore}
            companyData={installerDetail}
            fetchUpdateNotes={() => {
              setFetchHomeownerNotes(!fetchHomeownerNotes);
            }}
            setNotePopUp={() => setAddNotePopUp((addNotePopUpState) => !addNotePopUpState)}
            setEversignRequest={(eversignRequestData: IEversignRequest) => {
              setEversignRequest(eversignRequestData);
            }}
            setDisplayFullScreenLoaderState={(displayFullScreenLoader: boolean) => {
              setDisplayFullScreenLoader(displayFullScreenLoader);
            }}
            setFileUploadProgressState={(fileUploadProgressValue: number) =>
              setFileUploadProgress(fileUploadProgressValue)
            }
            homeownerFinancialReport = {homeownerFinancialReport}
          />
        </InboxDecorator>
      </Spin>
    </Suspense>
  );
};

export default withRouter(Inbox);
