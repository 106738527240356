import React from 'react';

import { IHomeownerScanifly } from './types';

/**
 * Render Homeowner Scanifly content/iframe
 *
 * @params {Object} props
 * @return React FC
 */
export const HomeownerScanifly: React.FC<IHomeownerScanifly> = ({ scaniflyUrl }) => {
  return (
    <div className="scanifly">
      <iframe width="100%" height="360" allowFullScreen src={scaniflyUrl}></iframe>
      <div
        className="overlay"
        onClick={(e) => {
          e.stopPropagation();
          window.open(scaniflyUrl, '_blank').focus();
        }}
      />
      <div className="expand" onClick={(e) => e.stopPropagation()} />
    </div>
  );
};
