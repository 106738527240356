import React from "react";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams
} from "react-router-dom";

import { createBrowserHistory } from 'history';

export default function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        let history = createBrowserHistory();
        let searchParams = useSearchParams();

        return (
            <Component
                { ...props }
                router={{ location, navigate, params, history, searchParams }}
            />
        );
    }
    return ComponentWithRouterProp;
}