class VariableResolutionException extends Error {
  constructor(errMessage: string) {
    super(errMessage);
    this.name = this.constructor.name;
  }
}

class InboxTypeException extends Error {
  constructor(errMessage: string) {
    super(errMessage);
    this.name = this.constructor.name;
  }
}

class UnrecognizedTypeException extends Error {
  constructor(errMessage: string) {
    super(errMessage);
    this.name = this.constructor.name;
  }
}

class HttpRequestException extends Error {
  constructor(errMessage: string) {
    super(errMessage);
    this.name = this.constructor.name;
  }
}

export {
  VariableResolutionException,
  InboxTypeException,
  HttpRequestException,
  UnrecognizedTypeException
};
