import { HOMEOWNER_MENU_MODEL } from '../constants/routes';

export const getToken = () => localStorage.getItem('token');
export const setToken = (token) => localStorage.setItem('token', token);

export const setHeader = (token) => ({
  headers: {
    'Content-type': 'application/json',
    Authorization: token ? token : 'Bearer ' + getToken()
  }
});

/**
 * For creating an application route from params and route
 *
 * @todo: adapt this for homeowner or create a separate function?
 *
 * @param {Object} params
 * @param {String} route
 * @return {String}
 */
export const routeWithParams = (params, route) => {
  if (!params.slug || !params.companyId) {
    throw new Error(`Unable to resolve params into a route - given ${params}`);
  }
  return routeFactory(params.slug, params.companyId, route);
};

/**
 * Encapsulate decomposing a URL (pathname) into business data
 *
 * Provides data for installer and homeowner, the latter with caveats
 */
export const deriveUsageStateFromUrl = () => {
  const pathParts = window.location.pathname.split('/');
  pathParts.shift();
  return {
    slug: pathParts[0],
    companyId: Number(pathParts[1]) || undefined, // homeowner doesn't have this, so undefined
    features: Number(pathParts[1]) ? pathParts.slice(2) : pathParts.slice(1) // handle absent `companyId`
  };
};

/**
 * A single responsibility for creating an application route from variables
 *
 * `companyId` appears in installer routes, but not for a sessioned homeowner
 *
 * @param {String} slug
 * @param {Number|String} companyId
 * @param {String} route
 * @return {String}
 */
export const routeFactory = (slug, companyId, route) => {
  if (companyId && isNaN(companyId)) {
    throw new Error(`Unable to resolve companyId for creating a route - given ${companyId}`);
  }
  return companyId ? `/${slug}/${companyId}/${route}` : `/${slug}/${route}`;
};

/**
 * Get the first specific route value from router data that isn't a route variable
 *
 * @param {String} pathname
 * @param {Object} params
 * @returns {String}
 */
export const resolveRoute = (pathname, params) => {
  const pathparts = pathname.split('/').filter((part) => part);
  const paramValues = Object.values(params);
  const delta = pathparts.filter((key) => !paramValues.includes(key));
  return delta.length ? delta.join('/') : 'dashboard'; // sane default
};

/**
 * Find a given route model from the homeowner menu model
 *
 * @param key
 * @param value
 * @returns {Object|false}
 */
export const getHomeownerRouteFromModel = (key, value) => {
  const model = HOMEOWNER_MENU_MODEL.filter(
    (menuElement) => menuElement[key] && menuElement[key] === value
  );
  return model.length === 1 ? model[0] : false;
};

/**
 * Provide a programmatic means of quickly identifying if we are at a given route for a homeowner
 *
 * @param {String} name
 * @returns bool
 */
export const isHomeownerRouteByName = (name) => {
  const { pathname } = window.location;
  let model;
  for (let i = 0; i < HOMEOWNER_MENU_MODEL.length; i += 1) {
    model = model = HOMEOWNER_MENU_MODEL[i];
    if (
      pathname.indexOf(model.pathname) === pathname.length - model.pathname.length &&
      name === model.name
    ) {
      return true;
    }
  }
  return false;
};

export const ROLE = {
  ADMIN: 'ADMIN',
  INSTALLER: 'INSTALLER',
  HOMEOWNER: 'HOMEOWNER'
};
