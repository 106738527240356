import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${(props) => `${props.theme.padding.padd50} ${props.theme.padding.padd0}`};
`;

export const Text = styled.div`
  font-size: ${(props) => props.theme.fonts.sizes.font24};
  line-height: 34px;
  padding: ${(props) => `${props.theme.padding.padd40} ${props.theme.padding.padd10}`};
  text-align: center;
`;

export const ButtonDecorator = styled.button`
  padding: ${(props) => props.theme.padding.padd10};
  font-size: ${(props) => props.theme.fonts.sizes.font14};
  font-weight: 700;
  width: 150px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.yellow};
  background-color: ${(props) => props.theme.colors.yellow};
  color: ${(props) => props.theme.colors.black};
  border-radius: ${(props) => props.theme.borderRadius.standard} !important;

  :hover {
    background-color: ${(props) => props.theme.colors.yellow100};
  }
`;
