// import npm packages
import { combineReducers } from 'redux';

// import local files
import {
  RECEIVE_SURVEY_DATA,
  RECEIVE_SURVEY_COUNT,
  RECEIVE_DOWNLOAD_SURVEY,
  RECEIVE_REFERRAL_DATA_CHART,
  RECEIVE_DOWNLOAD_REFERRAL,
  RECEIVE_REFERRALS_COUNT,
  RECEIVE_SOCIAL_SHARES_COUNT,
  RECEIVE_SOCIAL_SHARES_DATA,
  RECEIVE_DOWNLOAD_SOCIAL_SHARE,
  RECEIVE_ACTIVE_ACCOUNT_COUNT,
  RECEIVE_DELAYED_PROJECT_DATA,
  SET_METRICS_TYPE,
  RECEIVE_FINANCIAL_COUNT,
  RECEIVE_FLEET_ALERT_COUNT
} from '../../actions/dashboard';

const getSurveyDataReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_SURVEY_DATA:
      return data;
    default:
      return state;
  }
};

const getSurveyCountReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_SURVEY_COUNT:
      return data;
    default:
      return state;
  }
};

const getFinancialCountReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_FINANCIAL_COUNT:
      return data;
    default:
      return state;
  }
};

const downloadSurveyDataReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_DOWNLOAD_SURVEY:
      return data;
    default:
      return state;
  }
};

const getReferralCountReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_REFERRALS_COUNT:
      return data;
    default:
      return state;
  }
};

const downloadReferralDataReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_DOWNLOAD_REFERRAL:
      return data;
    default:
      return state;
  }
};

const referralsDataForChartReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_REFERRAL_DATA_CHART:
      return data;
    default:
      return state;
  }
};

const socialShareDataReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_SOCIAL_SHARES_DATA:
      return data;
    default:
      return state;
  }
};

const getSocialShareCountReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_SOCIAL_SHARES_COUNT:
      return data;
    default:
      return state;
  }
};

const downloadSocialShareDataReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_DOWNLOAD_SOCIAL_SHARE:
      return data;
    default:
      return state;
  }
};

const activeAccountCountReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_ACTIVE_ACCOUNT_COUNT:
      return data;
    default:
      return state;
  }
};

const delayedProjectReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_DELAYED_PROJECT_DATA:
      return data;
    default:
      return state;
  }
};

const metricsType = (state = null, { type, data }) => {
  switch (type) {
    case SET_METRICS_TYPE:
      return data;
    default:
      return state;
  }
};

const fleetAlertCountReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_FLEET_ALERT_COUNT:
      return data;
    default:
      return state;
  }
}

export const reducer = combineReducers({
  getSocialShareCountReducer,
  socialShareDataReducer,
  downloadSocialShareDataReducer,
  getReferralCountReducer,
  referralsDataForChartReducer,
  downloadReferralDataReducer,
  getSurveyCountReducer,
  getSurveyDataReducer,
  downloadSurveyDataReducer,
  activeAccountCountReducer,
  delayedProjectReducer,
  metricsType,
  getFinancialCountReducer,
  fleetAlertCountReducer
});
