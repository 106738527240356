// import npm packages
import React, { Component } from 'react';
import { Input, Form, Select, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { jwtDecode } from '../../../../shared/jwt';
import * as toast from '../../../../shared/notification';
import { getToken } from '../../../../shared/util';
import { capitalize } from '../../../../shared/capitalize';
import Success from './ExistingInstallSuccess';
import {
  requestApiData,
  requestCompanyIdData,
  requestInstallerData
} from '../../actions/addHomeOwner';
import withRouter from '../../../../shared/withRouter';

import { setLoader } from '../../../Common/Loader/action';
import LandingPage from '../../../Common/LandingPage';
import addPersonYellowIcon from '../../../../Assets/Images/add_person_yellow.svg';
import { ReactComponent as CaretIcon } from '../../../../Assets/Images/path-2.svg';

const FormItem = Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

class LiteNewInstallForm extends Component {
  constructor(props) {
    super(props);
    this.isSubmit = false;
    this.loader = false;
    this.slug = '';
    this.state = {
      errorObj: {},
      renderForm: true,
      state: null,
      installerList: [],
      fName: '',
      mName: '',
      lName: '',
      email: '',
      disabled: false,
      country : 'United States'
    };
  }

  componentWillMount() {
    this.loader = false;
    this.slug = localStorage.getItem('slug');
    const token = getToken();
    this.decodedToken = jwtDecode(token);
    this.companyId = this.props.router.params.companyId;
    if (!this.decodedToken.role) {
      this.setState({ disabled: true });
    }
    this.props.requestCompanyIdData(this.companyId);
    this.props.requestInstallerData(this.companyId);
    this.props.setLoader({ loader: true });
  }

  componentWillReceiveProps(nextProps) {
    // filter installer
    const { addhomeOwner, existingInstall } = nextProps;
    let defaultInstaller = {};
    if (
      nextProps.getInstaller &&
      nextProps.getInstaller.data &&
      nextProps.getInstaller.data.length
    ) {
      const installerList = nextProps.getInstaller.data.filter((installer) => !installer.isDisable);

      defaultInstaller = JSON.parse(JSON.stringify(this.decodedToken));
      defaultInstaller.id = defaultInstaller.installerId;
      for (let i = 0; i < installerList.length; i++) {
        if (installerList[i].defaultInstaller) {
          defaultInstaller = installerList[i];
        }
      }
      if (!this.state.installerList || this.state.installerList.length === 0) {
        this.setState({ installerList });
      }
    }

    if (existingInstall && this.isSubmit) {
      const { status } = existingInstall;
      const data = existingInstall;
      if (this.state.disabled)
        this.setState({
          disabled: false
        });
      if (data && status && (status === 200 || status === 201)) {
        this.setState({
          isSuccess: true
        });
      } else if (addhomeOwner && addhomeOwner.response && addhomeOwner.response.data) {
        toast.openNotificationWithIcon('error', addhomeOwner.response.data.message, ' Home Owner ');
      }
    }
  }

  componentWillUpdate() {
    if (localStorage.getItem('loader') === 'true') {
      setTimeout(() => {
        localStorage.setItem('loader', false);
        this.forceUpdate();
      }, 5000);
    }
  }

  goToDashboard() {
    this.setState({ isSuccess: false });
    this.props.router.navigate(`/${this.slug}/${this.companyId}/dashboard`);
  }

  handleSubmit = async (values) => {
    if (
      JSON.stringify(this.state.errorObj) !== '{}' &&
      (this.state.errorObj.phoneNumber ||
        this.state.errorObj.systemPrice ||
        this.state.errorObj.annualConsumption ||
        this.state.errorObj.annualProduction ||
        this.state.errorObj.projectAnnualSavings)
    ) {
      return null;
    }

    this.setState({
      email: values.email,
      fName: values.firstName,
      lName: values.lastName
    });

    this.isSubmit = true;
    values.isNew = 'true';
    values.companyId = this.companyId;
    values.companyName = this.props.installerData.data.companyName;

    values.phase = 1;
    values.phaseCompleted = false;
    values.phaseTime = new Date().getTime();
    values.touchName = 'Sign into Bodhi and welcome';
    values.productSuite = 1;

    this.props.requestApiData(values);
    this.props.setLoader({ loader: true });
  };

  fetchName = (data, key, value) => {
    const filter = data.filter((ele) => {
      return ele[key] === value;
    })[0];
    return filter.name;
  };

  renderOptions(dataArray, display, id) {
    return dataArray &&
      dataArray.map((item, index) => {
        if (id == 'code' && item.country === this.state.country) {
          return (
            <Option value={item[id]} key={`${item[id]}-${index}`}>
              {capitalize(item[display])}
            </Option>
          );
        } else if (id != 'code') {
          return (
            <Option value={item[id]} key={`${item[id]}-${index}`}>
              {capitalize(item[display])}
            </Option>
          )
        }
        return null;
      });
  }

  renderProjectManager = (dataArray) =>
    dataArray &&
    dataArray.map((item, index) => (
      <Option value={item.id} key={`${item.id}-${index}`}>
        {`${item.name ? `${item.name} - ` : ''}  ${item.email}`}
      </Option>
    ));

  // check the range of number
  checkNumberRange(value, range, type) {
    const { errorObj } = this.state;
    switch (type) {
      case 'phoneNumber':
        if (range && value.length !== range) {
          errorObj.phoneNumber = 'error';
        } else {
          errorObj.phoneNumber = '';
        }
        this.setState({
          errorObj
        });
        break;
      default:
        if (value > range) {
          errorObj[type] = 'error';
        } else {
          errorObj[type] = '';
        }
        this.setState({
          errorObj
        });
        break;
    }
  }

  renderLiteNewInstall = () => {
    const { companyId, installerData, addhomeOwner } = this.props;

    this.formSetting =
      (installerData && installerData.data && installerData.data.formSetting) || {};

    return (
      <Spin spinning={this.props.loader} indicator={antIcon} style={{ color: '#00142D' }}>
        <title>
          Add New Install Homeowner -{' '}
          {installerData && installerData.data && `${installerData.data.name}-`} Bodhi
        </title>
        <div>
          <section>
            <div className="container exisiting">
              <div className="new-client-form-wrapper add-new-home-owner box-Shadow">
                <div className="white-box create-client-form-box">
                  <h2 className="text-alignment col-md-12">
                    <img src={addPersonYellowIcon} width={33} alt="17terawatts" />
                    <span>Let's collect all the required information for this account</span>
                  </h2>

                  <Form
                    onFinish={this.handleSubmit}
                    scrollToFirstError
                    initialValues={this.state}
                    className="login-form homeowner-profile paddl20 paddr20">
                    <h3 className="add-homeowner">Customer information</h3>

                    <div className="newHomeOwnerProfile">
                      <div className="form-group mobilespacing">
                        <div className="row">
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label className="mb-0 form-group">
                                First name<span className="requiredSign"> * </span>
                              </label>
                              <FormItem
                                hasFeedback
                                name="firstName"
                                rules={[{ required: true }, { pattern: /^([^0-9]*)$/ }]}>
                                <Input className="form-control" id="exampleInputPassword1" />
                              </FormItem>
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label className="mb-0 form-group label-center">Middle initial</label>
                              <FormItem
                                hasFeedback
                                name="middleName"
                                rules={[{ required: false }, { pattern: /^([^0-9]*)$/ }]}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputPassword1"
                                />
                              </FormItem>
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label className="mb-0 form-group">
                                Last name<span className="requiredSign"> * </span>
                              </label>
                              <FormItem
                                hasFeedback
                                name="lastName"
                                rules={[
                                  {
                                    required: true
                                  },
                                  { pattern: /^([^0-9]*)$/ }
                                ]}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputPassword1"
                                />
                              </FormItem>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-8 col-12">
                          <div className="form-group">
                            <label className="mb-0 form-group">
                              Email address<span className="requiredSign"> * </span>
                            </label>
                            <FormItem
                              hasFeedback
                              name="email"
                              rules={[
                                {
                                  required: true
                                },
                                {
                                  type: 'email'
                                }
                              ]}>
                              <Input
                                type="email"
                                className="form-control"
                                id="exampleInputPassword1"
                              />
                            </FormItem>
                          </div>
                        </div>
                        <div className="col-md-4 col-12">
                          <div className="form-group">
                            <label className="mb-0 form-group">
                              Phone number<span className="requiredSign"> * </span>
                            </label>
                            <FormItem
                              hasFeedback
                              name="phone"
                              validateStatus={this.state.errorObj.phoneNumber || ''}
                              rules={[
                                {
                                  required: true
                                }
                              ]}>
                              <NumberFormat
                                type="tel"
                                format={this.formSetting.phone}
                                mask=" "
                                className="form-control ant-input"
                                placeholder="(   )  -  "
                                onChange={(e) => {
                                  this.checkNumberRange(
                                    e.target.value.replace(/[^0-9]/g, ''),
                                    this.formSetting.phoneLength,
                                    'phoneNumber'
                                  );
                                }}
                              />
                            </FormItem>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-8">
                          <div className="form-group">
                            <label className="mb-0 form-group">
                              Street address<span className="requiredSign"> * </span>
                            </label>
                            <FormItem hasFeedback name="address1" rules={[{ required: true }]}>
                              <Input type="text" className="form-control" id="exampleInputPassword1" />
                            </FormItem>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="form-group">
                            <label className="mb-0 form-group">
                               Country<span className="requiredSign"> * </span>
                            </label>
                            <FormItem hasFeedback name="country" rules={[{ required: true }]}>
                            <Select
                                  suffixIcon={<CaretIcon />}
                                  placeholder="Select Country"
                                  optionFilterProp="children"
                                  className="form-control"
                                  onChange={(value)=>{
                                    this.setState({country:value});
                                  }}
                                >
                                  <Option value="United States" key="United States">
                                    United States
                                  </Option>
                                  <Option value="Canada" key="Canada">
                                    Canada
                                  </Option>
                                </Select>
                            </FormItem>
                          </div>
                        </div>
                      </div>



                      <div className="row">
                        <div className="col-sm-5">
                          <div className="form-group">
                            <label className="mb-0 form-group">
                              City<span className="requiredSign"> * </span>
                            </label>
                            <FormItem
                              hasFeedback
                              name="city"
                              rules={[
                                {
                                  required: true
                                }
                              ]}>
                              <Input
                                type="text"
                                className="form-control"
                                id="exampleInputPassword1"
                              />
                            </FormItem>
                          </div>
                        </div>
                        {companyId.data &&
                          companyId.data.states &&
                          companyId.data.states.length > 0 && (
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label className="mb-0 form-group">
                                  State<span className="requiredSign"> * </span>
                                </label>
                                <FormItem
                                  hasFeedback
                                  name="state"
                                  rules={[
                                    {
                                      required: true
                                    }
                                  ]}>
                                  <Select
                                    suffixIcon={<CaretIcon />}
                                    name="state"
                                    className="form-control addHomeowner"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                      return (
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      );
                                    }}>
                                    {this.renderOptions(
                                      companyId.data && companyId.data.states
                                        ? companyId.data.states
                                        : [],
                                      'code',
                                      'code'
                                    )}
                                  </Select>
                                </FormItem>
                              </div>
                            </div>
                          )}
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label className="mb-0 form-group">
                              Postal code<span className="requiredSign"> * </span>
                            </label>
                            <FormItem
                              hasFeedback
                              name="zipCode"
                              rules={[
                                {
                                  required: true
                                }
                              ]}>
                              {
                                (this.state.country == "United States") ?
                                  <NumberFormat
                                    type="tel"
                                    format={this.formSetting.postalCode}
                                    mask=" "
                                    className="form-control"
                                  />
                                  :

                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputPassword1"
                                  />
                              }
                            </FormItem>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h3 className="add-homeowner">Permitting & system information</h3>

                      <div className="row">
                        <div className="col-md-4 col-12">
                          <div className="form-group">
                            <label className="mb-0 ">Inverter brand</label>
                            <FormItem
                              hasFeedback
                              name="inverterName"
                              rules={[
                                {
                                  required: false
                                }
                              ]}>
                              <Input
                                type="text"
                                className="form-control"
                                id="exampleInputPassword1"
                              />
                            </FormItem>
                          </div>
                        </div>
                        <div className="col-md-4 col-12">
                          <div className="form-group">
                            <label className="mb-0 ">Module brand</label>
                            <FormItem
                              hasFeedback
                              name="moduleName"
                              rules={[
                                {
                                  required: false
                                }
                              ]}>
                              <Input
                                type="text"
                                className="form-control"
                                id="exampleInputPassword1"
                              />
                            </FormItem>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group">
                            <label>Module model</label>
                            <FormItem
                              hasFeedback
                              name="moduleModel"
                              rules={[
                                {
                                  required: false
                                }
                              ]}>
                              <Input
                                type="text"
                                className="form-control"
                                id="exampleInputPassword1"
                              />
                            </FormItem>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 col-12">
                          <div className="form-group">
                            <label>Number of modules</label>
                            <FormItem
                              hasFeedback
                              name="moduleCount"
                              rules={[
                                {
                                  required: false
                                }
                              ]}>
                              <NumberFormat
                                mask=" "
                                type="tel"
                                className="form-control ant-input"
                              />
                            </FormItem>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group">
                            <label>Total system size</label>
                            <FormItem
                              hasFeedback
                              name="systemSize"
                              rules={[
                                {
                                  required: false
                                }
                              ]}>
                              <NumberFormat
                                mask=" "
                                type="tel"
                                className="form-control ant-input"
                              />
                            </FormItem>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group">
                            <label>System price</label>
                            <FormItem
                              hasFeedback
                              name="systemPrice"
                              validateStatus={this.state.errorObj.systemPrice || ''}
                              rules={[
                                {
                                  required: false
                                }
                              ]}>
                              <NumberFormat
                                mask=" "
                                type="tel"
                                className="form-control ant-input"
                                onChange={(e) => {
                                  this.checkNumberRange(
                                    Number(e.target.value),
                                    999999999,
                                    'systemPrice'
                                  );
                                }}
                              />
                            </FormItem>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 col-12">
                          <div className="form-group">
                            <label>Historical annual consumption in kWh</label>
                            <FormItem
                              hasFeedback
                              name="annualConsumption"
                              validateStatus={this.state.errorObj.annualConsumption || ''}
                              rules={[
                                {
                                  required: false
                                }
                              ]}>
                              <NumberFormat
                                mask=" "
                                type="tel"
                                className="form-control ant-input"
                                onChange={(e) => {
                                  this.checkNumberRange(
                                    Number(e.target.value),
                                    999999999,
                                    'annualConsumption'
                                  );
                                }}
                              />
                            </FormItem>
                          </div>
                        </div>
                        <div className="col-md-4 col-12">
                          <div className="form-group">
                            <label>Projected annual production in kWh</label>
                            <FormItem
                              hasFeedback
                              name="annualProduction"
                              validateStatus={this.state.errorObj.annualProduction || ''}
                              rules={[
                                {
                                  required: false
                                }
                              ]}>
                              <NumberFormat
                                mask=" "
                                type="tel"
                                className="form-control ant-input"
                                onChange={(e) => {
                                  this.checkNumberRange(
                                    Number(e.target.value),
                                    999999999,
                                    'annualProduction'
                                  );
                                }}
                              />
                            </FormItem>
                          </div>
                        </div>
                        <div className="col-md-4 col-12">
                          <div className="form-group">
                            <label>Projected annual savings in $</label>
                            <FormItem
                              hasFeedback
                              name="saving"
                              validateStatus={this.state.errorObj.projectAnnualSavings || ''}
                              rules={[
                                {
                                  required: false
                                }
                              ]}>
                              <NumberFormat
                                mask=" "
                                type="tel"
                                className="form-control ant-input"
                                onChange={(e) => {
                                  this.checkNumberRange(
                                    Number(e.target.value),
                                    999999999,
                                    'projectAnnualSavings'
                                  );
                                }}
                              />
                            </FormItem>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 col-12">
                          <div className="form-group">
                            <label className="mb-0 ">Electric utility</label>
                            <FormItem
                              hasFeedback
                              name="electricUtilityName"
                              rules={[
                                {
                                  required: false
                                }
                              ]}>
                              <Input
                                type="text"
                                className="form-control"
                                id="exampleInputPassword1"
                              />
                            </FormItem>
                          </div>
                        </div>

                        <div className="col-md-4 col-12">
                          <div className="form-group">
                            <label className="mb-0 form-group">AHJ</label>
                            <FormItem
                              hasFeedback
                              name="ahj"
                              rules={[
                                {
                                  required: false
                                }
                              ]}>
                              <Input
                                type="text"
                                className="form-control"
                                id="exampleInputPassword1"
                              />
                            </FormItem>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="section-from-box">
                      <h3>Assign personnel</h3>
                      <div className="col-md-8 col-12 mt-4">
                        <div className="form-group">
                          <label>
                            Project manager <span className="requiredSign"> * </span>
                          </label>
                          <FormItem
                            hasFeedback
                            name="projectManagerId"
                            rules={[
                              {
                                required: true
                              }
                            ]}>
                            <Select
                              suffixIcon={<CaretIcon />}
                              className="form-control addHomeowner"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return (
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }}>
                              {this.renderProjectManager(this.state.installerList)}
                            </Select>
                          </FormItem>
                        </div>
                      </div>

                      <div className="col-md-8 col-12">
                        <div className="form-group">
                          <label>Salesperson</label>
                          <FormItem
                            hasFeedback
                            name="salesperson"
                            rules={[
                              {
                                required: false
                              }
                            ]}>
                            <Select
                              suffixIcon={<CaretIcon />}
                              className="form-control addHomeowner"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return (
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }}>
                              {this.renderProjectManager(this.state.installerList)}
                            </Select>
                          </FormItem>
                        </div>
                      </div>
                    </div>

                    <div className="text-center margt40 margb80 btn-center-mobile">
                      <Link
                        className="btn btn-secondary"
                        to={`/${this.slug}/${this.companyId}/dashboard`}>
                        Cancel
                      </Link>
                      <button className="btn btn-primary">Create account</button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Spin>
    );
  };

  render() {
    if (getToken()) {
      if (this.state.isSuccess)
        return (
          <Success
            {...this.props}
            name={`${this.state.fName} ${this.state.lName}`}
            email={this.state.email}
            goToDashboard={() => {
              this.goToDashboard();
            }}
          />
        );
      if (this.state.renderForm) {
        return this.renderLiteNewInstall();
      }
      return <LandingPage />;
    }
    return null;
  }
}

const mapStatetoProps = (state) => {
  return {
    auth: state.auth,
    companyData: state.setting.companyDetail.data,
    getInstaller: state.addhomeOwner.getInstaller,
    installerData: state.header.getInstaller,
    stateData: state.addhomeOwner.state,
    companyId: state.addhomeOwner.companyId,
    existingInstall: state.addhomeOwner.existingInstall,
    loader: state.loader.loader.loader
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestApiData,
      requestCompanyIdData,
      requestInstallerData,
      setLoader
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(LiteNewInstallForm));
