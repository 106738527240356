import React from 'react';
import { Button, Select } from 'antd';

const { Option } = Select;

import * as config from '../../../../shared/config';
import { withRouter, getToken, jwtDecode } from '../../../../shared';
import { SearchGlobal } from '../../../Common/Search/SearchGlobal';

class searchMobile extends React.Component {
  constructor(props) {
    super(props);
    this.dataSource = [];
    this.state = {
      isProfileCreated: '',
      installerId: '',
      newInstall: '',
      settingLink: '',
      searchID: '',
      firstName: ''
    };
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidMount() {
    this.slug = localStorage.getItem('slug');
    this.installerData = jwtDecode(getToken());
    this.companyId = this.props.router.params.companyId || this.installerData.companyId;
  }

  renderNewInstall() {
    const data = {
      id: this.installerData.companyId,
      email: encodeURIComponent(this.state.emailId)
    };
    this.setState({
      newInstall: true
    });
    if (email) {
      this.props.requestAddHomeOwner(data);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { installerData } = nextProps;
    if (installerData && installerData.data) {
      const { data } = installerData;
      if (
        !data.isProfileCreated &&
        nextProps.location.pathname !== `/${this.slug}/${data.companyId}/setting`
      ) {
        nextProps.router.navigate(`/${this.slug}/${data.companyId}/setting`);
      }
      this.setState({
        isProfileCreated: data.isProfileCreated,
        settingLink: `/${this.slug}/${data.companyId}/setting`,
        installerId: data.id
      });
    }
  }

  onSelect = (option) => {
    const { router } = this.props;
    const { isProfileCreated, settingLink } = this.state;

    const firstName = option.children[1].props.children[0];
    const searchID = option.children[0].props.children;

    /**
     * @todo: this logic doesn't work when you navigate from homeowner result back to search using the browser back button
     */
    this.setState(
      {
        searchID,
        firstName
      },
      () => {
        isProfileCreated
          ? firstName &&
            router.navigate(`/${this.slug}/${this.companyId}/dashboard/${searchID}/homeowner`, {
              firstName
            })
          : settingLink && router.navigate(settingLink);
      }
    );
  };

  render() {
    const { companyName, router } = this.props;
    const { isProfileCreated, firstName, settingLink, searchID, installerId } = this.state;

    return (
      <>
        <div>
          <title>
            Search Homeowner - {companyName && companyName.data && companyName.data.name + '-'}{' '}
            Bodhi
          </title>

          <div className="container margin-t-120 text-center dashboard-nav-wrapper search-wrapper">
            <h5 className="margin-b-20  font-weight-400">HOMEOWNER SEARCH</h5>
            <div className="dashboar-search">
              <div className="search-wrap search-wrapMobile">
                <SearchGlobal
                  placeholder={config.SEARCH_HOMEOWNER}
                  handleSelectResult={this.onSelect}
                  resultFactory={(item) => {
                    return (
                      <Option key={item.id} value={`${item.firstName}-${item.email}`}>
                        <span className="searchId">{item.id}</span>
                        <p className="certain-search-item-count">
                          {item.firstName} ${item.lastName} - {item.email}
                        </p>
                      </Option>
                    );
                  }}
                />

                <Button
                  className="btn btn-primary my-2 my-sm-0 search-btn"
                  style={{ position: 'static', borderRadius: '10px' }}
                  onClick={() => {
                    isProfileCreated
                      ? firstName &&
                        router.navigate(
                          `/${this.slug}/${this.companyId}/dashboard/${searchID}/homeowner`,
                          { firstName: firstName }
                        )
                      : settingLink && router.navigate(settingLink);
                  }}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(searchMobile);
