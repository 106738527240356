
import { Modal } from 'antd';
import React, { useState } from 'react';
import { IEversignPrepareDocument } from './types';

export const EversignPrepareDocument: React.FC<IEversignPrepareDocument> = (props) => {
    const { embedded_claim_url,setEversignModel,fetchLatestInboxMessage } = props;
    const [ prepareDocumentPopUpState, setPrepareDocumentPopUpState] = useState(true);
    return (
        <Modal
            title={null}
            footer={null}
            maskClosable={false}
            open={prepareDocumentPopUpState}
            onCancel={() => {
                fetchLatestInboxMessage();
                setPrepareDocumentPopUpState(false);
                setEversignModel({prepareDocumentPopUp:false,eversignRequestPopUp:false});
            }}
            width="1000px"
            style={{ top: '0px' }}>
            <label className="get-your-documents-s">GET YOUR DOCUMENTS SIGNED</label>
            <iframe src={embedded_claim_url} width="100%" height="900"></iframe>
        </Modal>
    )
}