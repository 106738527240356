import styled from 'styled-components';

interface ISearchBackgroundDecor {
  position?: string;
}

export const SearchBackgroundDecor = styled.span<ISearchBackgroundDecor>`
  position: ${(props) => props.position || 'static'};
  ${(props) =>
    props.position === 'fixed'
      ? `top: 0; left: 100px; right: 250px; height: 58px; background-color: ${props.theme.colors.ltgrey}; width: 252px; z-index: 0;`
      : ''}
}
`;

export const Decorator = styled.div`
  height: 35px;
  form {
    margin-top: 0 !important;
  }
  .unified-search-ctrl {
    border: 1px solid ${(props) => props.theme.colors.labelborder};
    border-radius: ${(props) => props.theme.borderRadius.standard};
    display: inline-flex;
    margin-right: 12px;
    position: relative;
    width: 210px;
    &:not(.inbox) {
      .ant-select-selector {
        border: 0 none !important;
      }
    }
    input[type='search'] {
      width: inherit;
      &::placeholder {
        color: ${(props) => props.theme.colors.ltgrey4};
      }
    }
    &.inbox {
      height: auto;
      margin-top: -7px;
      .ant-select-selector {
        border-top-right-radius: ${(props) => props.theme.borderRadius.standard} !important;
        border-bottom-right-radius: ${(props) => props.theme.borderRadius.standard} !important;
      }
      .ant-select {
        min-width: 170px;
      }
      input[type='search'] {
        height: 29px;
        padding-top: 3px;
      }
      .searchImageIcon {
        height: 32px;
      }
    }
    &.with-filter {
      height: inherit;
      margin-top: -5px;
      input[type='search'] {
        height: 29px;
        padding-left: 12px;
        padding-top: 3px;
        border-top-right-radius: ${(props) => props.theme.borderRadius.standard} !important;
        border-bottom-right-radius: ${(props) => props.theme.borderRadius.standard} !important;
      }
      .searchImageIcon {
        padding-top: 2px;
        height: 30px;
      }
    }
  }
  .search-interface {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    height: inherit;
  }
  .searchImageIcon {
    align-items: center;
    background-color: ${(props) => props.theme.colors.grey6};
    border-bottom-left-radius: ${(props) => props.theme.borderRadius.standard};
    border-top-left-radius: ${(props) => props.theme.borderRadius.standard};
    display: flex;

    img {
      height: 15px;
      padding: 0 10px 0 12.5px;
    }
  }
`;
