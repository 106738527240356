import { Services } from '../../../Api/services';
import * as config from '../../../shared/config';
import { IFetchCompanyThreads, IUpdateThreadViewedBy, IHomeownerSearchQuery } from './types';

import { INBOX_API_GET_THREADS, INBOX_API_PUT_VIEWED_BY } from '../../../constants/routes';

import {
  fetchUnreadCount,
  createMessageByThreadId,
  fetchMessagesByThreadId
} from '../../Common/api/messages';

import { THREADS_DEFAULT_LIMIT } from '../../../constants';

/**
 * Fetches unread message counts for a sessioned user
 */
export const searchHomeownersByQuery = async (data: IHomeownerSearchQuery) => {
  const url = `${config.INBOX_BASE_URL}${INBOX_API_GET_THREADS}?search=${data.search}`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

/**
 * Fetches all Inbox Threads for a given company
 *
 * Optionally, accepts an installerId which filters messages returned
 */
export const fetchThreadsByCompanyId = async (data: IFetchCompanyThreads) => {
  const { installerId, page, homeownerId, limit } = data;
  let url = `${config.INBOX_BASE_URL}${INBOX_API_GET_THREADS}?page=${page}&limit=${
    limit && limit > THREADS_DEFAULT_LIMIT ? limit : THREADS_DEFAULT_LIMIT
  }`;
  if (installerId) {
    url += `&installerId=${installerId}`;
  }
  if (homeownerId) {
    url += `&homeownerId=${homeownerId}`;
  }
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

/**
 * Updates who has viewed a particular thread
 */
export const putPersistMessagesViewedById = async (data: IUpdateThreadViewedBy) => {
  const { threadId, messageIds } = data;
  const setRoute = INBOX_API_PUT_VIEWED_BY.valueOf();
  const url = `${config.INBOX_BASE_URL}${setRoute}`;
  const res = await Services.putCall(url, {
    id: threadId,
    messageIds
  });
  return { data: res.data, status: res.status };
};

export default {
  fetchUnreadCount,
  searchHomeownersByQuery,
  fetchThreadsByCompanyId,
  fetchMessagesByThreadId,
  createMessageByThreadId,
  putPersistMessagesViewedById
};
