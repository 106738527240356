import { combineReducers } from 'redux';
import { reducer as authReducer } from 'aws-cognito-redux-saga'

import { slugReducer, slugVerificationReducer, emailCompanyStatusReducer } from './data/reducer';

export const reducer = combineReducers({
	auth: authReducer.reducer,
	slugImage : slugReducer,
	slugVerification: slugVerificationReducer,
	emailCompanyStatus: emailCompanyStatusReducer
});