// import npm packages
import { call, put } from "redux-saga/effects";
// import local files
import { receiveInstallerData } from '../../screens/Installer/actions/addHomeOwner';
import { receiveInstallerData as receiveLoggedinInstallerData} from '../../screens/Installer/actions/header';
import { getInstallerApi } from '../../screens/Installer/api/addHomeOwner';
import {
  receiveFaqList,
  receiveFaqPost,
  receiveCompanyDetail,
  receiveCompanyDetailSet,
  receiveInviteEmployes,
  receiveUpdateCompanyDetail

} from "../../screens/Installer/actions/profile";

import {
  getFaqListApi,
  getFaqPostApi,
  updateInstallerByIdApi,
  getCompanyDetailApi,
  setCompanyDetailApi,
  setInviteEmployesApi,
  updateCompanyDetailApi
} from "../../screens/Installer/api/profile";
import handleError from "../../shared/errorHandler";
import { setLoader } from '../../screens/Common/Loader/action';

function* getFaqList(action) {
  try {
    const data = yield call(getFaqListApi, action.data);
    // yield put(setLoader({loader: false}));
    yield put(receiveFaqList(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    handleError(e);
  }
}

function* getFaqPost(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== "{}") {
      data = yield call(getFaqPostApi, action.data);
    }

    yield put(receiveFaqPost(data));
    yield put(setLoader({loader: false}));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    handleError(e);
  }
}

function* getCompanyDetail(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== "{}") {
      data = yield call(getCompanyDetailApi, action.data);
    }

    yield put(setLoader({ loader: false }));
    yield put(receiveCompanyDetail(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    handleError(e);
  }
}
function* setCompanyDetail(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== "{}") {
      data = yield call(setCompanyDetailApi, action.data);
    }

    yield put(setLoader({ loader: false }));
    yield put(receiveCompanyDetailSet(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    yield put(receiveCompanyDetailSet(e));
    handleError(e);
  }
}
function* updateCompanyDetail(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== "{}") {
      data = yield call(updateCompanyDetailApi, action.data);
    }
    yield put(receiveUpdateCompanyDetail(data));
  } catch (e) {
    yield put(receiveUpdateCompanyDetail(e));
    handleError(e);
  }
}

function* setinviteEmployees(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== "{}") {
      data = yield call(setInviteEmployesApi, action.data);
    }

    yield put(setLoader({ loader: false }));
    yield put(receiveInviteEmployes(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    yield put(receiveInviteEmployes(e));
    handleError(e);
  }
}


function* updateInstallerById(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== "{}") {
      data = yield call(updateInstallerByIdApi, action.data);
    }
    if (
      data
      && data.status
      && data.status === 200
    ) {
      const res = yield call(getInstallerApi, action.data.companyId);
      yield put(receiveInstallerData(res));

    }
    yield put(setLoader({ loader: false }))
  } catch (e) {
    yield put(setLoader({ loader: false }))
    const res = yield call(getInstallerApi, action.data.companyId);
    yield put(receiveInstallerData(res));
    handleError(e);
  }
}

function* updateLoggedInInstallerById(action) {
  try {
    let data = {}
    if (JSON.stringify(action.data) !== "{}") {
      data = yield call(updateInstallerByIdApi, action.data);
    }
    yield put(setLoader({ loader: false }))
    yield put(receiveLoggedinInstallerData(data));
  } catch (e) {
    yield put(setLoader({ loader: false }))
    handleError(e);
  }
}

export const profile = {
  getFaqList,
  getFaqPost,
  getCompanyDetail,
  setCompanyDetail,
  updateCompanyDetail,
  setinviteEmployees,
  updateInstallerById,
  updateLoggedInInstallerById
};
