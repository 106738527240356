import React, { useState } from 'react';
import { Input } from 'antd';

import { Decorator } from './SearchInterface.style';
import { ISearchFilterInterface, IFilter } from '../types';
import { SEARCH_CHAR_THRESHOLD } from '../../../constants';
import { searchIconSvg } from '../../../constants/icons';
import { VariableResolutionException } from '../../../exceptions';

const nullResults: undefined[] = [];
const nullSearchTerm: string = '';

/**
 * The interface for implementing different contextual filtering functionalities
 */
export const SearchFilterInterface: React.FC<ISearchFilterInterface> = (props) => {
  const { searchIcon, config, placeholder } = props;

  const { type, dataToFilter, dataFilterFn, clearSearchFn, clearFilterFn } = config;

  if (dataToFilter === undefined || typeof dataFilterFn !== 'function') {
    throw new VariableResolutionException(
      `FilterInterface failed to resolve dataToFilter and dataFilterFn - given "${dataToFilter}" and "${dataFilterFn}"`
    );
  }

  const [filterResults, setFilterResults] = useState<any>(nullResults);
  const [searchTerm, setSearchTerm] = useState<string>(nullSearchTerm);

  return (
    <Filter
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      searchIcon={searchIcon || searchIconSvg}
      placeholder={`${placeholder ? placeholder : 'Search ' + String(type)}`}
      clearSearchFn={clearSearchFn}
      clearFilterFn={clearFilterFn}
      dataFilterFn={dataFilterFn}
      setFilterResults={setFilterResults}
      filterResults={filterResults}
      dataToFilter={dataToFilter}
      type={String(type)}
    />
  );
};

/**
 * The concern for handling a filter to results that have already been retrieved and for proxying results back to the display component
 */
const Filter: React.FC<IFilter> = (props) => {
  const {
    type,
    searchIcon,
    dataToFilter,
    filterResults,
    clearSearchFn,
    clearFilterFn,
    dataFilterFn,
    setFilterResults,
    placeholder,
    searchTerm,
    setSearchTerm
  } = props;

  return (
    <Decorator>
      <form className="search-interface">
        <div className={`unified-search-ctrl with-filter filter-${type}`}>
          <div className="searchImageIcon">
            <img src={searchIcon} alt={`A magnifying glass graphic to indicate a search field`} />
          </div>
          <Input
            type="search"
            value={searchTerm}
            maxLength={30}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = event.target;
              const searchTerm = value.valueOf().trimStart();
              setSearchTerm(searchTerm);
              if (searchTerm.trim().length < SEARCH_CHAR_THRESHOLD) {
                if (searchTerm.length === 0) {
                  clearSearchFn && clearSearchFn(nullSearchTerm);
                  setFilterResults(nullResults);
                  clearFilterFn && clearFilterFn(nullResults);
                }
                return;
              }
              setFilterResults(dataFilterFn(searchTerm, dataToFilter));
            }}
            onFocus={() => {
              clearSearchFn && clearSearchFn(nullSearchTerm);
              setSearchTerm(nullSearchTerm);
              setFilterResults(nullResults);
            }}
            // onBlur={() => {}}
            placeholder={placeholder}
          />
        </div>
      </form>
    </Decorator>
  );
};

export default SearchFilterInterface;
