// import npm packages
import { combineReducers } from "redux";

// import local files
import { RECEIVE_INSTALLER_DETAILS, RECEIVE_POST_CONTACT_DATA } from "../../types/contact/contact";

const homeownerInstallerDetails = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_INSTALLER_DETAILS:
			return data;
		default:
			return state;
	}
};

const homeownerContactDetails = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_POST_CONTACT_DATA:
			return data;
		default:
			return state;
	}
};

export const reducer = combineReducers({
	homeownerInstallerDetails,
	homeownerContactDetails
});