// import npm packages
import { call, put } from "redux-saga/effects";

// import local files
import { slugApi, slugVerificationApi,getEmailCompanyStatusApi } from "../../screens/User/data/api";
import { receiveSlugData, receiveSlugVerification, receiveEmailCompanyStatus } from "../../screens/User/data/action";
import handleError from '../../shared/errorHandler';
import { setLoader } from '../../screens/Common/Loader/action';

function* getSlugImage(action) {
  try {
    const data = yield call(slugApi, action.data);

    // yield put(setLoader({loader: false}));
    yield put(receiveSlugData(data));
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e)
  }
}
function* getslugVerification(action) {
  try {
    const data = yield call(slugVerificationApi, action.data);

    yield put(setLoader({loader: false}));
    yield put(receiveSlugVerification(data));
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e)
  }
}

function* getEmailCompanyStatus(action) {
  try {
    const data = yield call(getEmailCompanyStatusApi, action.data);
    yield put(setLoader({ loader: false }));
    yield put(receiveEmailCompanyStatus(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    if (e?.response?.data?.url) {
      window.location.replace(`https://`+e?.response?.data?.url);
    }
    handleError(e)
  }
}

export const user = {
  getSlugImage,
  getEmailCompanyStatus,
  getslugVerification
}