// import npm packages
import React, { Suspense } from 'react';
import { connect } from 'react-redux';

// import local files
const InstallerDashboard = React.lazy(() => import('../Installer/container/dashboard'));
const HomeownerDashboard = React.lazy(() => import('../homeowner/container/dashboard/dashboard'));
const AdminDashboard = React.lazy(() => import('../Admin/components/dashboard/dashboard'));

import { suspenseFallback } from '../../constants/components';
import { getToken, jwtDecode, withRouter } from '../../shared/';

class DashboardCommon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: null,
      data: null
    };
  }

  componentWillMount() {
    this.slug = localStorage.getItem('slug');
    let token = getToken();
    this.installerData = jwtDecode(token);
    this.setState({
      role: this.installerData.role,
      data: this.installerData
    });
  }

  render() {
    let { role, data } = this.state;

    if (this.props.router.params?.slug) {
      role = this.props.router.params.slug === 'admin' ? 'admin' : role;
    }

    switch (role) {
      case 'admin':
        return (
          <Suspense fallback={suspenseFallback}>
            <AdminDashboard {...this.props} />
          </Suspense>
        );
      case 'homeowner':
        return (
          <Suspense fallback={suspenseFallback}>
            <HomeownerDashboard {...this.props} />
          </Suspense>
        );
      case 'homeowner-secondary':
        return (
          <Suspense fallback={suspenseFallback}>
            <HomeownerDashboard {...this.props} />
          </Suspense>
        );
      default:
        return (
          <Suspense fallback={suspenseFallback}>
            <InstallerDashboard {...this.props} data={data} />
          </Suspense>
        );
    }
  }
}
const mapStatetoProps = (state) => {
  return {
    auth: state.auth,
    header: state.header
  };
};

export default withRouter(connect(mapStatetoProps, null)(DashboardCommon));
