// import npm packages
import { call, put } from "redux-saga/effects";

// import local files
import { getCompanyDetail } from "../../screens/homeowner/api/resources";
import handleError from "../../shared/errorHandler";
import { setLoader } from "../../screens/Common/Loader/action";
import { receiveHomeownerCompanyDetail } from "../../screens/homeowner/actions/resources";

function* getHomeownerCompanyDetail(action) {
	try {
		const data = yield call(getCompanyDetail, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveHomeownerCompanyDetail(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

export const homeownerResources = {
	getHomeownerCompanyDetail
};