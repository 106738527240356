import * as moment from "moment";
import { Services } from '../../../Api/services'
import * as config from '../../../shared/config'

export const existingInstall = async data => {
  const url = `${config.HOME_OWNER_BASE_URL}company/${data.companyId}/homeowner`;
  if (typeof (data.phone) != "number") {
    if (isNaN(+data.phone)) {
      data.phone = data.phone.replace(/[^0-9]/g, "")
    }
    data.phone = +data.phone
  }
  const bodyData = {
    "firstName": data.firstName,
    "lastName": data.lastName,
    "middleName": data.middleName,
    "email": data.email && data.email.toLowerCase(),
    "phone": data.phone,
    "productSuite":data.productSuite,
    "companyName": data.companyName,
    "address": {
      "address1": data.address1,
      "city": data.city,
      "state": data.state,
      "zipCode": data.zipCode
    },
    "salePersonId": data.salesperson,
    "document": data.documentId,
    "countryCode":data.countryCode,
    "solarUnit": {
      "moduleCount": +data.moduleCount,
      "moduleModel": data.moduleModel,
      "moduleBrandId": +data.moduleBrandId,
      "moduleBrand": data.moduleName,
      "ahj": data.ahj,
      "inverterBrandId": +data.inverterBrandId,
      "inverterBrand": data.inverterName,
      "saving": data.saving ? +data.saving : 0,
      "electricUtilityId": +data.electricUtilityId,
      "electricUtilityName": data.electricUtilityName,
      "meterId": data.meterId ? data.meterId : null,
      "meterName": data.meterName,
      "meterUrl": data.meterUrl ? data.meterUrl : null,
      "meterconId": data.meterconId ? data.meterconId : null,
      "systemSize": data.systemSize,
      "systemPrice": +data.systemPrice,
      "annualConsumption": +data.annualConsumption,
      "annualProduction": +data.annualProduction,
      "companyId": data.companyId,
      "toolSolarUnitId": data.toolSolarUnitId ? data.toolSolarUnitId : null,
      "utilityRateId": +data.ratePlan,
      "phase": data.phase,
      "activeJourney" : data.defaultJourney,
      "touchName": data.touchName,
      "touchHistory": data.touchHistory,
      "isCompleted": data.phaseCompleted,
      "updated": data.phaseTime,
      "toolContactId": data.toolContactId ? data.toolContactId : null,
      "connectionStatus": data.connectionStatus
    },
    "toolContactId": data.toolContactId ? data.toolContactId : null,
    "companyId": data.companyId,
    "installerId": data.projectManagerId
  };
  if (data.isNew) {
    bodyData['isNew'] = data.isNew;
    bodyData['isExisting'] = false;
  } else {
    bodyData['isExisting'] = true;
    bodyData['solarUnit']['dailySyncTime'] = moment().startOf('day').unix();
  }
  let res = await Services.postCall(url, bodyData,true)
  return { data: res.data, status: res.status }
};

export const stateApi = async data => {
  const url = `${config.ADMIN_BASE_URL}admin/states`;
  let res = await Services.getCall(url, true)
  return { data: res.data, status: res.status }
};

export const timeZoneApi = async data => {
  const url = `${config.ADMIN_BASE_URL}admin/timezone`;
  let res = await Services.getCall(url, true)
  return { data: res.data, status: res.status }
};

export const companyIdApi = async id => {
  const url = `${config.ADMIN_BASE_URL}admin/${id}/masterdata`;
  let res = await Services.getCall(url, true)
  return { data: res.data, status: res.status }
};

export const getInstallerApi = async id => {
  const url = `${config.INSTALLER_BASE_URL}company/${id}/installers`;
  let res = await Services.getCall(url, true)
  return { data: res.data, status: res.status }
};

export const getPowerMeterApi = async id => {
  const url = `${config.COMPANY_BASE_URL}company/${id}/powermeter`;
  let res = await Services.getCall(url, true)
  return { data: res.data, status: res.status }
};

export const getAddHomeOwnerApi = async data => {
  let url = `${config.HOME_OWNER_BASE_URL}company/${data.companyId}/homeowner?toolSearch=${'true'}&email=${data.email}&newInstall=${data.newInstall}`;
  if (data.syncCRM) {
    url = url + `&syncCRM=${data.syncCRM}&toolContactId=${data.toolContactId}&toolSolarUnitId=${data.toolSolarUnitId}&activeJourney=${data.activeJourney || 'default'}`
  }
  let res = await Services.getCall(url, true)
  return { data: res.data, status: res.status }
};

export const getCRMTokenApi = async data => {
  const url = `${config.COMPANY_BASE_URL}company/${data.companyId}/crmtoken`;
  const bodyData = {
    code: data.code,
    url: window.location.origin,
    tool: data.tool,
    slug : data.slug
  }
  let res = await Services.postCall(url, bodyData,true)
  return { data: res.data, status: res.status }
}