// import npm packages
import React, { Component } from 'react';
import { Modal, Form, Slider, Input } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withRouter from '../../../../shared/withRouter';
import { capitalize } from '../../../../shared/capitalize';
import { getToken } from '../../../../shared/util';
import { jwtDecode } from '../../../../shared/jwt';
import { setLoader } from '../../../Common/Loader/action';
// import ResourceSlider from './slider';
import Carousel from './carousel';
import ResourcesObj from './resources.json';
import { setHeight } from '../../../../shared/setHeight';
import { openNotificationWithIcon } from '../../../../shared/notification';
import {
  requestStatusFeedPhaseSurvey,
  receiveStatusFeedPhaseSurvey
} from '../../actions/statusFeed';
import { ReactComponent as Solar101 } from '../../../../Assets/Images/solar-101.svg';
import { ReactComponent as Getting_home_ready } from '../../../../Assets/Images/getting-home-ready.svg';
import { ReactComponent as Your_solar_equ } from '../../../../Assets/Images/your-solar-equ.svg';
import { ReactComponent as Your_monitiring_system } from '../../../../Assets/Images/your-monitiring-system.svg';

const FormItem = Form.Item;

/**
 * When refactoring this component may want to give each resource own route.
 * This will allow for us to route back to resources when carousel is closed,
 * rather than to the dashboard which is currently happening.
 */
class ResourcesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: null,
      slideNumber: null,
      sliderStatus: 'Satisfied',
      phaseId: '',
      isClicked: false
    };
    this.ResourcesObj = ResourcesObj;
  }

  componentWillMount() {
    let token = getToken();
    this.homeownerData = jwtDecode(token);
    let { resourceData } = this.props;
    this.ResourcesObj =
      resourceData && Object.keys(resourceData).length > 0 ? resourceData : ResourcesObj;
    if (resourceData && Object.keys(resourceData).length > 0) {
      this.setState({ test: true });
    }
  }

  componentDidMount() {
    this.renderProps();
  }

  componentWillReceiveProps(nextProps) {
    let { resourceData } = this.props;
    this.ResourcesObj =
      resourceData && Object.keys(resourceData).length > 0 ? resourceData : ResourcesObj;

    if (resourceData) {
      this.setState({ test: true });
    }
    if (
      nextProps.statusFeedPhaseSurvey &&
      nextProps.statusFeedPhaseSurvey.status &&
      nextProps.statusFeedPhaseSurvey.status === 200
    ) {
      openNotificationWithIcon('success', 'Survey submitted successfully', 'Success');
      localStorage.removeItem('ismonitoringslides');
      this.props.router.navigate('/' + localStorage.getItem('slug') + '/dashboard/');
    }
    if (this.state.slides) this.renderProps(nextProps);
  }

  renderProps = (nextProps) => {
    let propData = nextProps ? nextProps : this.props;
    let slideNum = this.state.slideNumber;

    let slideParam = propData && propData.resourceData;

    let slide = slideParam && slideNum !== null ? slideNum + 1 : 0;

    if (slide && slide === 1 && this.ResourcesObj.resourcesMain[0].mainText.toLowerCase() === 'solar journey') {
      this.setState({ slides: this.ResourcesObj.resourcesMain[0] });
    } else if (
      slide &&
      slide === 2 &&
      this.ResourcesObj.resourcesMain[1].mainText.toLowerCase() === 'solar 101'
    ) {
      this.setState({ slides: this.ResourcesObj.resourcesMain[1] });
    } else if (
      slide &&
      slide === 3 &&
      this.ResourcesObj.resourcesMain[2].mainText.toLowerCase() === 'getting your home ready'
    ) {
      this.setState({ slides: this.ResourcesObj.resourcesMain[2] });
    } else if (
      slide &&
      slide === 4 &&
      this.ResourcesObj.resourcesMain[3].mainText.toLowerCase() === 'your solar equipment'
    ) {
      this.setState({ slides: this.ResourcesObj.resourcesMain[3] });
    } else if (
      slide &&
      slide === 5 &&
      this.ResourcesObj.resourcesMain[4].mainText.toLowerCase() === 'your monitoring system'
    ) {
      this.setState({ slides: this.ResourcesObj.resourcesMain[4] });
    } else {
      setHeight('homeOwnerWrapper', 'menuMobile');
      if (this.state.slides) {
        this.setState({
          slides: null
        });
      }

      if (propData.isSlidesReset && propData.isInstallerDashboard) {
        this.setState({
          slides: null
        });
      }
    }
  };

  handleSlider = (rating, phaseId) => {
    if (rating === 1) this.setState({ sliderStatus: 'Poor', phaseId: phaseId });
    else if (rating === 2) this.setState({ sliderStatus: 'dot1', phaseId: phaseId });
    else if (rating === 3) this.setState({ sliderStatus: 'Satisfied', phaseId: phaseId });
    else if (rating === 4) this.setState({ sliderStatus: 'dot2', phaseId: phaseId });
    else if (rating === 5) this.setState({ sliderStatus: 'LoveIt', phaseId: phaseId });
  };

  handleSubmit = (values) => {
    let whatsnext = {
      type: 'SURVEY',
      survey: [
        {
          phaseId: 6,
          question: 'How has your experience been so far?'
        },
        {
          phaseId: 7,
          question: 'How do you feel about #SOLARCOMPANY?'
        },
        {
          phaseId: 8,
          question: 'How do you feel about the Bodhi App?'
        }
      ],
      title: 'Final Survey'
    };
    let companyId = this.homeownerData.companyId;
    let homeownerId = this.homeownerData.homeownerId;
    const touchName = 'statusdashboardtransitionstomonitoringdashboard';
    const installerId = (this.homeownerData && this.homeownerData.installerId) || null;

    let tempData = [];
    let totalRating = 0;
    whatsnext.survey.forEach((data) => {
      totalRating += values[`rating${data.phaseId}`];
      tempData.push({
        phaseId: data.phaseId,
        rating: values[`rating${data.phaseId}`],
        installerId: installerId
      });
    });
    this.props.setLoader({ loader: true });
    this.props.receiveStatusFeedPhaseSurvey({});
    this.props.requestStatusFeedPhaseSurvey({
      companyId,
      homeownerId,
      phase: tempData
    });
  };

  renderPhaseServe(phase, systemGlance = null) {
    const companyName =
      systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.companyName
        ? capitalize(systemGlance.solarGlance.companyName)
        : '#SOLARCOMPANY';

    return (
      <div className="margin-t-73-mobile">
        <div
          className="pull-right oval-2-mobile"
          onClick={() => {
            localStorage.removeItem('ismonitoringslides');
            this.props.router.navigate('/' + localStorage.getItem('slug') + '/dashboard/');
          }}>
          <span className="line-3">&times;</span>
        </div>
        <div className="white-box-secondary survey-modal">
          <div className="paddall25">
            <div className="phase-survey-title">HOW ARE WE DOING?</div>
            <Form onFinish={this.handleSubmit}>
              {phase &&
                phase.survey &&
                phase.survey.length !== 0 &&
                phase.survey.map((survey) => (
                  <React.Fragment key={survey.phaseId}>
                    <p className="paddt15">
                      {survey.question
                        .replace(/#FIRSTLETTER/g, this.isVowel(companyName) ? 'An' : 'A')
                        .replace(/#SOLARCOMPANY/g, companyName)}
                    </p>
                    <div className="text-left">
                      <FormItem name={`rating${survey.phaseId}`} initialValue={3}>
                        <Slider
                          displayName="slider-resources"
                          disabled={this.props.isInstaller}
                          min={1}
                          max={5}
                          onChange={(val) => {
                            this.handleSlider(val, survey.phaseId);
                          }}
                        />
                      </FormItem>
                    </div>
                    <ul className="ratingSlider" style={{ fontSize: 'small' }}>
                      <li>
                        {this.state.sliderStatus === 'Poor' &&
                        this.state.phaseId === survey.phaseId ? (
                          <span className="premiumLinks">Poor</span>
                        ) : (
                          <span>Poor</span>
                        )}
                      </li>
                      <li className="text-center">
                        {this.state.sliderStatus === 'dot1' &&
                        this.state.phaseId === survey.phaseId ? (
                          <span className="dots premiumLinks"></span>
                        ) : (
                          <span className="dots"></span>
                        )}
                      </li>
                      <li className="text-center">
                        {this.state.sliderStatus === 'Satisfied' &&
                        this.state.phaseId === survey.phaseId ? (
                          <span className="premiumLinks">Satisfied</span>
                        ) : (
                          <span>Satisfied</span>
                        )}
                      </li>
                      <li className="text-center">
                        {this.state.sliderStatus === 'dot2' &&
                        this.state.phaseId === survey.phaseId ? (
                          <span className="dots premiumLinks"></span>
                        ) : (
                          <span className="dots"></span>
                        )}
                      </li>
                      <li className="text-right">
                        {this.state.sliderStatus === 'LoveIt' &&
                        this.state.phaseId === survey.phaseId ? (
                          <span className="premiumLinks">Love It</span>
                        ) : (
                          <span>Love It</span>
                        )}
                      </li>
                    </ul>
                  </React.Fragment>
                ))}
              <div className="text-center">
                <button
                  className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn"
                  disabled={this.props.isInstaller ? 'disabled' : ''}>
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }

  isVowel(text) {
    var vowelRegex = '^[aieouAIEOU].*';
    var matched = text.match(vowelRegex);
    if (matched) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    let { loggedInUser, systemGlance } = this.props;
    let isProjectTrack;
    let companyDetail = systemGlance || this.props.companyDetail;
    let titleValue =
      loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.companyName;

    if(loggedInUser?.homeownerData?.isProjectTrack || (window.$productSuite ===1)){
      isProjectTrack = true;
    }
      
    return (
      <React.Fragment>
        <title> Resources - {titleValue}</title>
        {this.state.slides ? (
          <Carousel
            {...this.props}
            slides={this.state.slides}
            slideNumber={this.state.slideNumber}
            companyDetail={companyDetail}
            slideReset={() => {
              this.setState({
                slides: null
              });
            }}
          />
        ) : localStorage.getItem('ismonitoringslides') && this.props.router.params.slide === '5' ? (
          this.renderPhaseServe(
            {
              type: 'SURVEY',
              survey: [
                {
                  phaseId: 6,
                  question: 'How has your experience been so far?'
                },
                {
                  phaseId: 7,
                  question: 'How do you feel about #SOLARCOMPANY?'
                },
                {
                  phaseId: 8,
                  question: 'How do you feel about the Bodhi App?'
                }
              ],
              title: 'Final Survey'
            },
            companyDetail
          )
        ) : (
          <div>
            <p className="text-center resource-mobile-heading">EDUCATIONAL RESOURCES</p>

            <div className="row docutment-row">
              {this.ResourcesObj &&
                this.ResourcesObj.resourcesMain.map((item, i) =>
                  this.isValidResource(item, i, isProjectTrack) ? (
                    ''
                  ) : (
                    <div
                      key={i}
                      className="col-sm-6 col-6 mobile-margin-zero mobile-padding-zero"
                      style={{ marginBottom: '15px' }}>
                      <div
                        className="white-box padd15 text-center document-box"
                        onClick={() => {
                          if (this.props.isInstallerDashboard) {
                            this.props.slideReset();
                          }
                          this.setState({
                            slides: item,
                            slideNumber: i
                          });
                        }}>
                        {this.renderResourceImg(item.mainImg)}

                        <p>{item.mainText}</p>
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  isValidResource(item, i, isProjectTrack) {
    if (item.resourcesChild.length === 0) {
      return true;
    } else if (isProjectTrack && i === 4) {
      return true;
    } else {
      return false;
    }
  }

  renderResourceImg(item) {
    let { systemGlance } = this.props;
    let icon = systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.icon;
    switch (item) {
      case 'Bodhi_logo':
        return <img className="journey-logo" src={`${icon || ''}`} alt="resoucre" />;
      case 'Solar-101':
        return <Solar101 />;
      case 'Getting-home-ready':
        return <Getting_home_ready />;
      case 'Your-solar-equ':
        return <Your_solar_equ />;
      case 'Your-monitiring-system':
        return <Your_monitiring_system />;
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    loader: state.loader.loader.loader,
    statusFeedPhaseSurvey: state.statusFeedPhaseSurvey.statusFeedPhaseSurvey || {}
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestStatusFeedPhaseSurvey,
      receiveStatusFeedPhaseSurvey,
      setLoader
    },
    dispatch
  );

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(ResourcesForm));
