import React, { Suspense, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserAvatar from 'react-user-avatar';
import NumberFormat from 'react-number-format';

import { HOMEOWNER_CONTACT_SURVEY } from '../../../../constants/routes';
import { withRouter, setHeight, capitalize } from '../../../../shared';
import { requestPostContactData, receivePostContactData } from '../../actions/contact';
import { openNotificationWithIcon } from '../../../../shared/notification';

const ContactMessageComposer = React.lazy(() => import('./ContactMessageComposer'));
import { suspenseFallback } from '../../../../constants/components';

/**
 * This is a transitional implementation to bolt on a mechanism to handle only message composition as well
 * to limit scope of work for inbox.
 *
 * The message + survey goes through an existing endpoint.
 * The message only case goes through the inbox service.
 */
class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderStatus: 'Satisfied'
    };
  }

  componentWillReceiveProps(nextProps) {
    setHeight('homeOwnerWrapper', 'menuMobile');
    const { message } = nextProps?.contactDetails?.homeownerContactDetails?.data || {};
    if (message && message.message === 'message sent') {
      openNotificationWithIcon('success', 'Message sent successfully', 'Success');
      this.props.receivePostContactData('');
    }
  }

  handleSlider = (rating) => {
    if (rating === 1) this.setState({ sliderStatus: 'Poor' });
    else if (rating === 2) this.setState({ sliderStatus: 'dot1' });
    else if (rating === 3) this.setState({ sliderStatus: 'Satisfied' });
    else if (rating === 4) this.setState({ sliderStatus: 'dot2' });
    else if (rating === 5) this.setState({ sliderStatus: 'LoveIt' });
  };

  handleSubmit = (values) => {
    const {
      loggedInUser: { homeownerData }
    } = this.props;

    this.props.setLoader({ loader: true });
    this.props.requestPostContactData({
      companyId: homeownerData.companyId,
      homeownerId: homeownerData.id,
      message: values.message,
      statusFeed: false,
      rating: values.rating,
      homeOwnerName: `${homeownerData.firstName} ${homeownerData.lastName}`,
      homeOwnerEmail: homeownerData.email,
      installerId: homeownerData.installerId,
      installerName: homeownerData.installerName,
      installerEmail: homeownerData.installerEmail
    });
  };

  render() {
    const { loggedInUser, homeOwnerId, router } = this.props;
    let installerDetails = loggedInUser && loggedInUser.homeownerData;
    let formSetting = loggedInUser.homeownerData && loggedInUser.homeownerData.formSetting;
    let titleValue = installerDetails && installerDetails.companyName;
    let photo = installerDetails && installerDetails.photo;
    if (photo && photo.includes('installer-avatar')) {
      photo = null;
    }
    let installerName =
      (installerDetails &&
        installerDetails.installerName &&
        capitalize(installerDetails.installerName)) ||
      (titleValue && capitalize(titleValue));

    const imageSyle = {
      backgroundImage: photo ? `url(` + photo + `)` : ''
    };

    const [searchParams] = router.searchParams;
    const displaySurvey = searchParams.get(HOMEOWNER_CONTACT_SURVEY) === null;

    const headerContent = displaySurvey
      ? `CONTACT ${loggedInUser && loggedInUser?.homeownerData?.companyName?.toUpperCase()}`
      : 'SEND A MESSAGE';

    return (
      <>
        <title>Contact - {titleValue}</title>
        <div className="white-box">
          <p className="page-title border0" style={{ marginBottom: '-7px' }}>
            {headerContent}
          </p>
          <div className="contact-wrapper padd25">
            <div className="media">
              {photo ? (
                <div className="dashboard-profile-image" style={imageSyle}></div>
              ) : (
                <UserAvatar
                  className="dashboard-profile-image premiumIconBackGround"
                  size="40"
                  name={installerName || 'Installer'}
                />
              )}
              <div className="media-body">
                <h5 className="mt-0">{installerName}</h5>
                <div className="email-id font">
                  {installerDetails && installerDetails.installerTitle}
                </div>
              </div>
            </div>
            <p className="paddt25 marg0  font-weight-300">
              Send a message directly to your project manager{' '}
              {installerDetails &&
                installerDetails.installerName &&
                capitalize(installerDetails.installerName)}
              .
            </p>
            <p className="paddt25 marg0 font-weight-300">
              Phone:
              <NumberFormat
                type="tel"
                format={formSetting && formSetting.phone}
                value={installerDetails && installerDetails.installerPhone}
                className="ml-1 premiumLinks border-0"
              />
            </p>
            <p className="padd0 font-weight-300">
              Email:
              <span className="ml-1 premiumLinks">
                {' '}
                {installerDetails && installerDetails.installerEmail}
              </span>
            </p>
            <Suspense fallback={suspenseFallback}>
              <ContactMessageComposer
                homeOwnerId={homeOwnerId}
                installerId={installerDetails.installerId}
                isInstaller={this.props.isInstaller}
              />
            </Suspense>
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    contactDetails: state.homeownerContact
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      requestPostContactData,
      receivePostContactData
    },
    dispatch
  );
};

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(ContactForm));
