// import npm packages
import { call, put } from 'redux-saga/effects'

// import local files
import {
  searchApi,
  getInstallerByIdApi,
  getHomeOwnerByIdApi
} from '../../screens/Installer/api/header';
import {
  receiveSearchData,
  receiveInstallerData
} from '../../screens/Installer/actions/header';
import handleError from '../../shared/errorHandler';
import { setLoader } from '../../screens/Common/Loader/action';

function* search(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== "{}") {
      data = yield call(searchApi, action.data);
    }

    yield put(setLoader({loader: false}));
    yield put(receiveSearchData(data));
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e);
  }
}

function* getInstaller(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== "{}") {
      data = yield call(getInstallerByIdApi, action.data);
    }
    
    yield put(setLoader({loader: false}));
    yield put(receiveInstallerData(data));
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e);
  }
}

function* getHomeOwner(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== "{}") {
      data = yield call(getHomeOwnerByIdApi, action.data);
    }

    // yield put(setLoader({loader: false}));
    yield put(receiveInstallerData(data));
  } catch (e) {
    yield put(setLoader({loader: false}));
    handleError(e);
  }
}

export const header = {
  getInstaller,
  search,
  getHomeOwner
}
