import { createSelector } from '@reduxjs/toolkit';
import React, { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { Chron } from '../../../classes';
import {
  INSTALLER_POLL_INTERVAL,
  PRODUCT_NAME,
  SEARCH_KEY,
  installerRole
} from '../../../constants';
import { suspenseFallback } from '../../../constants/components';
import Header from '../../../screens/Installer/components/header';
import { getUser, resetAllUserClasses, setUserClassnames, userIsUsingInbox } from '../../../shared';
import store, { RootState } from '../../../store';
import ErrorPage from '../../Common/ErrorPage';
import Private from '../../Common/Private';
import { MainNavigation } from '../../Installer/components/Sider';
import { messageIsUnreadByUser } from '../../Installer/components/inbox/helpers';
import {
  flattenMessagesForDatum,
  getMostRecentMessagesFromThreads
} from '../../Installer/container/inbox/helpers';

const CompanySetting = React.lazy(() => import('../../Installer/components/Sider/CompanySetting'));
const CompanyInbox = React.lazy(() => import('../../Installer/components/Sider/CompanyInbox'));

const SUBNAV = {
  COMPANY_SETTINGS: 'CompanySetting',
  COMPANY_INBOX: 'CompanyInbox'
};

import {
  assignExecutingPolling,
  fetchMessagesByThreadId,
  fetchThreadsByCompanyId,
  fetchUnreadCount,
  putPersistMessagesViewedById
} from '../../../store/InboxSlice';
import { configureGlobalSearch } from '../../../store/SearchInterfaceSlice';

import envConfig from '../../../environments'

/**
 * A basic HOC wrapper to standarize an installer layout
 */
const GenericLayout = (props) => {
  const { Component, args } = props;
  return (
    <div className="installer-ui-wrapper sys-notification-wrapper">
      <div className="row">
        <div className="col-sm-11">
          <div className="right-content-box">
            <Component {...args} />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Encapsulates resolution of sub navigation
 *
 * @param {String} the nav component name
 * @returns {Function|Object} the Function or Object we'll use
 */
const resolveSubNav = (navigation) => {
  switch (navigation) {
    case SUBNAV.COMPANY_SETTINGS:
      return CompanySetting;
    case SUBNAV.COMPANY_INBOX:
      return CompanyInbox;
    default:
      return null;
  }
};

/**
 * Encapsulates resolution of sub navigation props
 *
 * @param {String} the nav component name
 * @param {Object} possible props we want to pass
 * @returns {Object} selected props to pass to sub navigation component
 */
const resolveSubNavProps = (navigation, props) => {
  switch (navigation) {
    case SUBNAV.COMPANY_INBOX:
      return {
        companyId: props.companyId,
        userId: props.userId
      };
    default:
      return null;
  }
};

/**
 * Encapsulates resolution of header class dependencies
 *
 * @param {String}
 */
const resolveHeaderClassnames = (header) => {
  switch (header) {
    case 'fleet':
      return 'header-fleet';
    default:
      return null;
  }
};

const unreadState = createSelector(
  (state: RootState) => state.inboxMessages.counts.inbox,
  (inbox) => inbox
);

const totalCountState = createSelector(
  (state: RootState) => state.inboxMessages.counts.totalCount,
  (totalCount) => totalCount
);

const companyJourneyState = createSelector(
  (state: RootState) => state?.companyBodhiJourney?.bodhiJourneyReducer?.data?.[0],
  (companyJourney) => companyJourney
);

const installerDetailState = createSelector(
  (state: RootState) => state.header?.getInstaller?.data,
  (installerDetail) => installerDetail
);

const companyInformationState = createSelector(
  (state: RootState) => state?.setting?.companyDetail?.data?.companyDetail,
  (companyInformation) => companyInformation
);

/**
 * Provide explicit access to store values via a getter
 * @returns {Array} TThreads[]
 */
const getCurrentThreads = () => {
  return store.getState().inboxMessages.threadsData?.threads;
};

/**
 * Provide explicit access to store values via a getter
 * @returns {Array} TThreads[]
 */
const getCurrentMessages = () => {
  return store.getState().inboxMessages.messageData?.messages;
};

/**
 * Provide explicit access to store values via a getter
 * @returns {String}
 */
const getCurrentThreadId = () => {
  return store.getState().inboxMessages.threadsData?.currentThreadId;
};

/**
 * Provide explicit access to store values via a getter
 * @returns {String}
 */
const isLoadingMessages = () => {
  return store.getState().inboxMessages.loading.messages;
};

const installerLayout = ({ component: Component, ...params }, props) => {
  const { navigation, standardLayout, header, customSearch, roleBasedAccess } = params;
  const { id: homeownerId } = useParams();
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const [installerPolling, setInstallerPolling] = useState(undefined);
  const [scriptsAdded, setScriptsAdded] = useState(false);

  const userTokenData = getUser();

  if (currentUrl !== window.location.href) {
    setCurrentUrl(window.location.href);
  }

  const currentUnreadCount = useSelector(unreadState);
  const totalCount = useSelector(totalCountState);
  const companyJourney = useSelector(companyJourneyState);
  const installerDetail = useSelector(installerDetailState);
  const companyInformation = useSelector(companyInformationState);


  /**
   * Ensure that no API calls are attempted without a sessioned user
   */
  if (!userTokenData || localStorage.getItem('isLogout') === 'true') {
    return <Navigate to={{ pathname: '/' }} />;
  }

  const { role, slug, companyId, installerId } = userTokenData;

  const dispatch = useDispatch();

  /**
   * We want this to run for each route, so we can re-configure the search when it needs to toggle between default and custom instances
   */
  useEffect(() => {
    /**
     * Super admin doesn't have companyId
     * Config search if component@route doesn't
     */
    if (companyId && !customSearch) {
      dispatch(
        configureGlobalSearch({
          scope: 'installer',
          type: 'homeowner',
          installerId,
          companyId,
          mechanism: SEARCH_KEY
        })
      );
    }

    if (companyId) {
      /**
       * If we have a change in `currentUrl`, clear existing Chron and create a new one
       * This ensures that we reset our Chron whenever the user is active in the app
       */
      if (installerPolling) {
        installerPolling.clear();
      }

      setInstallerPolling(
        new Chron({
          start: true,
          interval: INSTALLER_POLL_INTERVAL,
          eventHandlers: [
            {
              fn: async (chron, handler) => {
                /**
                 * Installers have this single handler, currently, so let's not re-run until the entire body has executed.
                 * Multiple async operations execute in it, potentially
                 */
                chron.pause();

                const count = await store.dispatch(fetchUnreadCount());
                await store.dispatch(putPersistMessagesViewedById({ userId: installerId }));

                if (count?.error || count?.meta?.requestStatus === 'rejected') {
                  if (count?.payload?.getValue) {
                    handler.logError(count.payload.getValue());
                  }
                }

                const unreadCountIncrease =
                  count?.payload?.count > 0 && count?.payload?.count > currentUnreadCount;
                const unreadCountDecrease =
                  currentUnreadCount > 0 && count?.payload?.count < currentUnreadCount;
                const totalCountIncrease =
                  count?.payload?.totalCount > 0 && count?.payload?.totalCount > totalCount;

                /**
                 * If we have more messages that are unread and our total messages increases, or if we have read unread messages
                 * then rehydrate threads
                 */
                if (
                  (userIsUsingInbox() && unreadCountIncrease && totalCountIncrease) ||
                  unreadCountDecrease
                ) {
                  await store.dispatch(assignExecutingPolling(true));

                  const threadsReq = await store.dispatch(
                    fetchThreadsByCompanyId({
                      homeownerId,
                      installerId,
                      page: 1,
                      polling: true
                    })
                  );

                  if (threadsReq?.error || threadsReq?.meta?.requestStatus === 'rejected') {
                    if (threadsReq?.payload?.getValue) {
                      handler.logError(threadsReq.payload.getValue());
                    }
                  }

                  const latestThreads = getCurrentThreads();
                  const currentThreadId = getCurrentThreadId();

                  if (currentThreadId && latestThreads?.length) {
                    const newMessages = getMostRecentMessagesFromThreads(latestThreads, {
                      assignThreadId: true
                    });
                    const newMessagesCount = totalCountIncrease
                      ? count?.payload?.totalCount - totalCount
                      : 0;

                    newMessages.splice(newMessagesCount);
                    const unreadNewMessagesFromCurrentThread = newMessages.filter(
                      (message) =>
                        messageIsUnreadByUser(message, String(installerId)) &&
                        message.threadId === currentThreadId
                    );

                    const currentMessages = getCurrentMessages();
                    const currentMessageIds = flattenMessagesForDatum(currentMessages, 'id');

                    /**
                     * If the current thread id has been updated, rehydrate messages for it
                     * [!] Ensure that the new messages aren't in the store already and that we aren't currently in the process of loading messages
                     */
                    if (
                      unreadNewMessagesFromCurrentThread.length &&
                      currentMessages &&
                      unreadNewMessagesFromCurrentThread.filter(
                        (unreadMessage) => !currentMessageIds.includes(unreadMessage.id)
                      ).length &&
                      !isLoadingMessages()
                    ) {
                      const msgsReq = await store.dispatch(
                        fetchMessagesByThreadId({
                          threadId: currentThreadId,
                          page: 1,
                          polling: true
                        })
                      );

                      if (msgsReq?.error || msgsReq?.meta?.requestStatus === 'rejected') {
                        if (msgsReq?.payload?.getValue) {
                          handler.logError(msgs.payload.getValue());
                        }
                      }
                    }
      }

                  await store.dispatch(assignExecutingPolling(false));
                }

                /**
                 * If we've seen more than a couple errors, pause
                 */
                if (handler?.errors?.length > chron.errThreshold) {
                  console.error(
                    `Received bad response from installerPolling dispatch '${count.type}' - pausing iterations.`
                  );
                  chron.pause();
                }

                if (chron.counter > 1500 || !getUser()) {
                  chron.clear();
                  if (!getUser()) {
                    window.location.href = `${window.location.origin}/${slug}`;
                  }
                }
                if (handler?.errors?.length <= chron.errThreshold) {
                  chron.restart();
                }
              }
            }
          ]
        })
      );
    }
  }, [currentUrl, homeownerId]);

  useEffect(() => {
    if (!scriptsAdded && installerDetail) {
      setScriptsAdded(true);
    }
  }, []);

  /**
   * @todo: [!] Ensure that we only change layout variables/styling when we change URL, and not on every render...
   */

  // resets body classnames
  resetAllUserClasses();

  /**
   * Should the layout include a sub navigation component?
   */
  const SubNav = resolveSubNav(navigation);

  /**
   * Apply standard layout auto-magickally, so we don't need to create extra ones
   */
  const useGenericLayout = standardLayout === true ? true : false;

  const bodyClassnames = ['harmonia'];

  const headerClassnames = resolveHeaderClassnames(header);

  if (headerClassnames) {
    bodyClassnames.push(headerClassnames);
  }

  const isInstallerAdmin = role !== undefined && role.includes('admin');

  const isAdmin = slug === 'admin' && !companyId;
  const isInstallerUser = role === installerRole;

  const displayMainNav = isInstallerAdmin || isInstallerUser || isAdmin;
  /**
   * Note: As of Feb 2023, we have only 2 sub navs implemented, so using a ternary for now
   * Only admins can see 'CompanySetting' but any installer can use 'CompanyInbox'
   */
  const displaySubNav =
    SubNav === SUBNAV.COMPANY_SETTINGS
      ? isInstallerAdmin || isAdmin
      : isInstallerAdmin || isInstallerUser || isAdmin;

  const displayMode = !!SubNav ? 'installer-view-wide' : 'installer-view';

  const newProps = { ...props };

  // use layout to inject user metadata, so we don't need to get it again
  newProps.userId = Number(installerId);
  if (companyId) {
    newProps.companyId = Number(companyId);
    newProps.userRole = role;
  }

  if (companyJourney) {
    newProps.companyJourney = companyJourney;
  }

  if (installerDetail) {
    newProps.installerDetail = installerDetail;
  }

  if (companyInformation) {
    newProps.companyInformation = companyInformation;
  }

  newProps.userRole = role;

  bodyClassnames.push(!isInstallerUser ? displayMode : 'installer-view');
  setUserClassnames(bodyClassnames.join(' '));

  const subNavPropsData = { companyId, userId: installerId };

  let ParsedComponent = null;

  // remove "settings" for non-admin installer identity
  const parsedRoleBasedAccess =
    roleBasedAccess === PRODUCT_NAME.Settings && isInstallerUser ? 'unauthorized' : roleBasedAccess;

  if (useGenericLayout && !parsedRoleBasedAccess) {
    ParsedComponent = <GenericLayout Component={Component} args={{ ...newProps }} />;
  } else if (useGenericLayout && parsedRoleBasedAccess) {
    ParsedComponent = (
      <Private name={parsedRoleBasedAccess} unauthorized={<ErrorPage />}>
        <GenericLayout Component={Component} args={{ ...newProps }} />
      </Private>
    );
  } else if (!parsedRoleBasedAccess) {
    ParsedComponent = <Component {...newProps} {...params} />;
  } else {
    ParsedComponent = (
      <Private name={parsedRoleBasedAccess} unauthorized={<ErrorPage />}>
        <Component {...newProps} {...params} />
      </Private>
    );
  }

  return (
    <>
      {displayMainNav && <MainNavigation role={role} installerId={installerId} />}
      {SubNav && displaySubNav && (
        <Suspense fallback={suspenseFallback}>
          <SubNav {...resolveSubNavProps(navigation, subNavPropsData)} />
        </Suspense>
      )}
      <header>
        <Header {...newProps} />
      </header>

      {installerDetail && scriptsAdded === false && addScript(installerDetail)}
      {ParsedComponent}
    </>
  );
};

function addHelpWiseWidget(installerDetail){
  if(installerDetail?.productSuite != 3){
    return;
  }

  const helpwiseSettingsScript = document.createElement('script');
        helpwiseSettingsScript.type = 'text/javascript';
        helpwiseSettingsScript.innerHTML = `
            var helpwiseSettings = {
                widget_id: '6595921f70ae5', // your app id
                align: 'right', // it can be left or right
                user_id: '', // pass the value within single quotes
                firstname: '',
                lastname: '',
                email: ''
            };
        `;
        
        // Append the Helpwise settings script to the document head
        document.head.appendChild(helpwiseSettingsScript);

        // Create the Helpwise live chat script
        const helpwiseLiveChatScript = document.createElement('script');
        helpwiseLiveChatScript.src = 'https://cdn.helpwise.io/assets/js/livechat.js';
        helpwiseLiveChatScript.async = true;
        helpwiseLiveChatScript.defer = true;
        
        // Append the Helpwise live chat script to the document head
        document.head.appendChild(helpwiseLiveChatScript);
}

function addScript(installerDetail) {
  addHelpWiseWidget(installerDetail);
  return (
    <Helmet>
      <link
        rel="shortcut icon"
        href={`https://prod-17terawatts.s3.amazonaws.com/public/logo/favicon.ico`}
      />
      <link
        rel="apple-touch-icon"
        href={`https://prod-17terawatts.s3.amazonaws.com/public/logo/favicon.ico`}
        sizes="72x72"
      />
      <script
        async
        charset="utf-8"
        type="text/javascript"
        src="//js.hsforms.net/forms/embed/v2.js"></script>      
    </Helmet>
  );
}

export default installerLayout;
