//  import npm packages
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

// import local files
import Setting from './Setting';
import { requestCompanyDetail, requestUpdateLoggedInInstallerById } from '../../actions/profile';
import { requestInstallerData } from '../../actions/addHomeOwner';
import { setLoader } from '../../../Common/Loader/action';

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    installerData: state.header.getInstaller.data,
    companyData: state.setting.companyDetail.data,
    getInstaller: state.addhomeOwner.getInstaller,
    loader: state.loader.loader.loader,
    updateInstallerById: state.setting.updateInstallerById[state.setting.updateInstallerById.length - 1]
  }
}
const mapDispatchToProps = dispatch => (
  bindActionCreators({
    requestUpdateLoggedInInstallerById,
    requestCompanyDetail,
    requestInstallerData,
    setLoader
  }, dispatch)

)

export default connect(mapStatetoProps, mapDispatchToProps)(Setting)
