import * as toast from './notification';
function handleError(error) {

    const { response } = error
    if (!localStorage.getItem('isActiveError')) {
        if (response && response.data && response.data.message) {
            if (error.response.status === 401) {
                toast.openNotificationWithIcon('error', 'Unauthorized', ' Error ')
            }
            else if (response.status === 501 || response.status === 500) {
                toast.openNotificationWithIcon('error', "I'm having trouble loading the page.Let's try refreshing.", ' Oops ')
            } else if (response.status === 404 || response.status === 400) {
                toast.openNotificationWithIcon('error', response.data.message ? response.data.message : 'Not Found', ' Error ')
            } else {
                toast.openNotificationWithIcon('error', response.data.message, ' Error ')
            }
        } else {
            toast.openNotificationWithIcon('error', "I'm having trouble loading the page.Let's try refreshing.", ' Oops ')
        }
        localStorage.setItem('isActiveError', true);
    } else {
        setTimeout(() => {
            localStorage.removeItem('isActiveError');
        },1000)
    }


}

export default handleError