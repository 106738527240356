import { combineReducers } from "redux";
import { RECEIVE_COMPANY_FLEETDATA } from "../../actions/fleet";

const companyFleet = (state = {}, { type, data }) => {
	switch (type) {

		case RECEIVE_COMPANY_FLEETDATA:
			return data&&data.data;
		default:
			return state;
	}
};

export const reducer = combineReducers({
	companyFleet
});