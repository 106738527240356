import React, { Suspense } from 'react';

import { checkIsVideo } from '../../../../shared/checkIsMobile';
import { suspenseFallback } from '../../../../constants/components';
import { IHomeownerCampaign } from './types';
import {
  UserDetail,
  HomeownerCampaignPreview,
  HomeownerMessageInterface,
  HomeownerShare,
  MessageStatus
} from './';
import { ShowImageFromDriveAdapter } from '../../../Common';
import { ParticipantType } from '../../../../constants';
import { extractAttachmentInfo, imageIsOpen } from './helpers';

const VideoPreview = React.lazy(() => import('./VideoPreview'));

/**
 * Render any of the various representations of a Homeowner Campaign with controls
 *
 * @params {Object} props
 * @return React FC
 */
export const HomeownerCampaign: React.FC<IHomeownerCampaign> = (props) => {
  const { homeownerId, message, propsResolver } = props;
  const { sender, messageType } = message;
  const userDetails = {
    name: sender.name,
    title: sender.title,
    photo: sender.photo,
    messageType
  };
  const homeownerInterfaceProps =
    message && typeof propsResolver === 'function' ? propsResolver(message) : {};

  const dyId = Math.floor(100000 + Math.random() * 900000);
  const msgAttachments = message.attachments;

  const { wasRead, loggedInUser, threadId } = homeownerInterfaceProps;

  let isInstaller = true;

  const { button, buttonLabel, video, image } = extractAttachmentInfo(msgAttachments);

  if (message) {
    if (message.recipientType === ParticipantType.HOMEOWNER) {
      isInstaller = false;
    }
  }

  const isVideo = checkIsVideo(video);

  const displayMessageInterface = message && Object.keys(homeownerInterfaceProps).length > 0;

  return (
    <div className="white-box-secondary margb15 spacingMobile">
      <div className="paddall25 pdding-left-zero pdding-right-zero">
        <UserDetail dyId={String(dyId)} isCampaign={true} userDetails={userDetails}>
          <MessageStatus unread={!wasRead} allowInstallerCustom={true} />
        </UserDetail>
        <HomeownerShare
          dyId={dyId}
          loggedInUser={loggedInUser}
          isDefaultShow={false}
          isInstaller={homeownerInterfaceProps.isInstaller} // TODO: do we need two references to this indicator?
          shareName="glance"
        />
        {
          <div className="campaign-content">
            <Suspense fallback={suspenseFallback}>
              {isVideo && <VideoPreview url={video} />}
              {image !== null && (
                <ShowImageFromDriveAdapter
                  campaignPhoto={image}
                  isOpen={imageIsOpen(image)}
                  filterByName="site plan"
                  style={{ marginBottom: '80px' }}
                />
              )}
            </Suspense>
            <HomeownerCampaignPreview
              headline={message.subject || ''}
              body={message.message}
              buttonUrl={button || ''}
              buttonLabel={buttonLabel || ''}
              id={message.id}
              homeownerId={homeownerId}
            />
            <br />
            {displayMessageInterface && (
              <HomeownerMessageInterface
                {...homeownerInterfaceProps}
                installerId = {loggedInUser?.homeownerData?.installerId}
                message={message}
                threadId={threadId}
              />
            )}
          </div>
        }
        <br />
      </div>
    </div>
  );
};

export default HomeownerCampaign;
