/**
 * Remove a class or classes assigned to the body node of the DOM
 *
 * @param {String[]}
 */
export function removeClass(...classnames) {
  const element = document.getElementsByTagName('body');
  if (element && element[0]) {
    element[0].classList.remove(...classnames);
  }
}

/**
 * Remove a class or classes assigned to the body node of the DOM
 *
 * @param {String[]}
 */
export function addClass(...classnames) {
  const element = document.getElementsByTagName('body');
  if (element && element[0]) {
    element[0].classList.add(...classnames);
  }
}

export const resetAllUserClasses = () => {
  resetInstallerSpecificClasses();
  resetHomeownerSpecificClasses();
};

/**
 * Remove classes associated with the UI for installer users, e.g. when signed out
 */
export const resetInstallerSpecificClasses = () => {
  removeClass('installer-view', 'installer-view-wide');
};

/**
 * Remove classes associated with the UI for homeowner users, e.g. when signed out
 */
export const resetHomeownerSpecificClasses = () => {
  removeClass('homeowner-bg', 'bg-color');
};

export const clearUserClassnames = () => {
  setUserClassnames('');
};

/**
 * Assign variable number of classes to handle user use case
 *
 * @param {String[]}
 */
export const setUserClassnames = (...classnames) => {
  const body = document.getElementsByTagName('body');
  if (body && body[0]) {
    body[0].className = classnames.join(' ');
  }
};
