// import npm packages
import { call, put } from "redux-saga/effects";

// import local files
import { getHomeownerInstallerDetails, postHomeownerContactDetails,postInstallerNotify } from "../../screens/homeowner/api/contact";
import handleError from "../../shared/errorHandler";
import { setLoader } from "../../screens/Common/Loader/action";
import { receiveInstallerDetails, receivePostContactData } from "../../screens/homeowner/actions/contact";

function* getInstallerDetails(action) {
	try {
		const data = yield call(getHomeownerInstallerDetails, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveInstallerDetails(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* postContactDetails(action) {
	try {
		const data = yield call(postHomeownerContactDetails, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receivePostContactData(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* notifyInstaller(action){
	try{
		const data = yield call(postInstallerNotify,action.data);
	}
	catch(e){
		handleError(e);
	}
}

export const homeownerContact = {
	getInstallerDetails,
	notifyInstaller,
	postContactDetails	
};