import { apiBaseUrlFactory } from './helpers';

const UAT_ENV = 'uat';
const uatApiBaseUrlFactory = apiBaseUrlFactory(UAT_ENV);

// to ensure we can (1) mock the API, and (2) unit test BASE_URL for Inbox
export const UAT_INBOX_SUBDOMAIN = 'lmkrobsg7h';
export const UAT_FLEET_CSV_SUBDOMAIN = 'xtow07fe67';
export const UAT_FINANCIAL_REPORT_SUBDOMAIN = 'tfhxzerfj5';

const stage = {
  s3: {
    BUCKET: 'uat-17terawatts',
    URL: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_NJnHkZSqe',
    IMAGE_URL: 'https://s3.amazonaws.com/uat-17terawatts'
  },
  podium: {
    clientId: '60fb796a-1b11-4011-8da4-f75dd2a81d3a'
  },
  apiGateway: {
    REGION: '',
    BASE_URL: {
      HOME_OWNER: 'https://ohawd5fynh.execute-api.us-east-1.amazonaws.com/uat/',
      INBOX: uatApiBaseUrlFactory(UAT_INBOX_SUBDOMAIN),
      ADMIN: 'https://pxbjv8w8q9.execute-api.us-east-1.amazonaws.com/uat/',
      INSTALLER: 'https://tx8zw70yq6.execute-api.us-east-1.amazonaws.com/uat/',
      COMPANY: 'https://im8f2vzjnd.execute-api.us-east-1.amazonaws.com/uat/',
      REFERRAL: 'https://ifcqrsel0k.execute-api.us-east-1.amazonaws.com/uat/',
      SOCIAL_SHARE: 'https://l2gh0xhfac.execute-api.us-east-1.amazonaws.com/uat/',
      SURVEY: 'https://ml3482l0y1.execute-api.us-east-1.amazonaws.com/uat/',
      ENERGY_REPORT: 'https://79ggeyjle7.execute-api.us-east-1.amazonaws.com/uat/',
      EVERSIGN_POST: 'https://1f6oi83g93.execute-api.us-east-1.amazonaws.com/uat/',
      MARKETING_URL: 'https://pntsbwj95h.execute-api.us-east-1.amazonaws.com/uat/',
      FINANCIAL_REPORT: uatApiBaseUrlFactory(UAT_FINANCIAL_REPORT_SUBDOMAIN),
      FLEET_CSV: `https://${UAT_FLEET_CSV_SUBDOMAIN}.execute-api.us-east-1.amazonaws.com/dev/`
    }
  },
  cognito: {
    Region: 'us-east-1',
    IdentityPoolId: 'us-east-1:d24da3d5-1ca2-4151-8da3-8413e83d1448',
    UserPoolId: 'us-east-1_ql4cgTAZi',
    ClientId: '4acpj4q3cqeg6tj61qf3cpe9vv'
  },
  googleDrive: {
    CLIENT_ID: '703904244739-mfjc23sti0se9tpbchblc6s29piu9jnc.apps.googleusercontent.com',
    API_KEY: 'AIzaSyATw0tb1GVQJ4BX-XXlVjSikRii3d8ZFlk'
  },
  dropbox: {
    clientId: 'iih4chsjrjei9f5',
    client_secret: '4mgda3af8urax6z'
  },
  googleMap: {
    API_KEY: 'AIzaSyCB6-bafvWwJRIi48ycKHKbWLfiJbhPt4w'
  },
  SALES_FORCE_BASE_URL: 'https://test.salesforce.com',
  SERVICE_FUSION_BASE_URL: 'https://api.servicefusion.com',
  HUBSPOT_BASE_URL: 'https://app.hubspot.com',
  BUCKET_URL: 'https://uat-17terawatts.s3.amazonaws.com/public',
  REPORT_SYNC: false,
  GA_KEY: 'UA-144118249-1',
  ANALYTICS: {
    ChurnZero: '1!PsREiufgrq1byOfmuM0D3Fjy8ZLpkeUrn-JSNQ4o0tAt130E'
  }
};

export default stage;
