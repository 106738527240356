import { Services } from '../../../Api/services';
import envConfig from '../../../environments/index';

const COMPANY_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.COMPANY;
const HOME_OWNER_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.HOME_OWNER;

export const getCompanyById = async (data) => {
  const url = `${COMPANY_BASE_URL}company/${data}?admin=${1}`;
  const res = await Services.getCall(url);
  return { data: res.data, status: res.status };
};

export const editInstallerApi = async (data) => {
  const url = `${COMPANY_BASE_URL}company/${data.companyId}`;
  const res = await Services.putCall(url, data.bodyData);
  return { data: res.data, status: res.status };
};

export const editInstallerManagementApi = async (data, meterData) => {
  const powerMeterData = meterData.map((data) => {
    delete data.Key_id;
    return data;
  });
  const bodyData = {
    slug: data.slug,
    crm:
      data.crmName && data.crmName.includes('crm')
        ? [
            {
              crmId: data.crmName.replace('crm', ''),
              key: data.CRMapiKey,
              identifier: data.CRMidentifier
            }
          ]
        : [],
    projectManagement:
      data.crmName && data.crmName.includes('pm')
        ? [
            {
              projectId: data.crmName.replace('pm', ''),
              key: data.CRMapiKey,
              identifier: data.CRMidentifier
            }
          ]
        : [],
    eversignCredentials: {
      businessId: data.eversignBusinessId || null,
      apiKey: data.eversignApiKey || null,
      companyId: data.id
    },
    companyInfo: data.companyInfo,
    powerMeter: powerMeterData,
    document: data.docStorage
      ? [
          {
            documentId: data.docStorage,
            filter: data.docAPIkey
          }
        ]
      : []
  };

  if(data.sunlightEmail && data.sunlightPassword && data.sunlightToken){
    bodyData.sunlight = {
      email: data.sunlightEmail,
      password: data.sunlightPassword,
      authorizationToken: data.sunlightToken
    }
  }

  const url = `${COMPANY_BASE_URL}company/${data.id}/management`;
  const res = await Services.putCall(url, bodyData);
  return { data: res.data, status: res.status };
};

export const uploadExcelApi = async (data) => {
  const url = `${HOME_OWNER_BASE_URL}/company/${data.id}/homeowner/importFile`;
  const bodyData = data.csvUrl;
  bodyData.email = data.email;
  const res = await Services.postCall(url, bodyData, true);
  return { data: res.data, status: res.status };
};

export const driveUploadInS3 = async (data) => {
  const url = `${HOME_OWNER_BASE_URL}company/uploaddrive`;

  const res = await Services.postCall(url, data, true);
  return { data: res.data, status: res.status };
};
