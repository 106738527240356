import { processTouchNameForCollation } from '../shared';
import { TJourneyTouch } from '../types';
import { BodhiJourneyTouch } from './';

type TJourneyModel = Record<string, TJourneyTouch>;
type TTouchNameAdapter = (touchName: string) => string;

type TComposedJourney = Record<string, BodhiJourneyTouch>;

interface IBodhiJourney {
  getTouchByName: (name: string) => BodhiJourneyTouch;
}

/**
 * Initial iteration of a domain object to provide an interface to an important primitive, which is a `companyJourney` API response.
 *
 * `BodhiJourney` accepts a `companyJourney` service response and wraps it in an interface,
 * as well as applying a `BodhiJourneyTouch` interface to each phase in the journey
 *
 * A journey model is composed of abritrary phases that an Installer can create and specify via the application
 *
 * @todo: create a BodhiJourney collection for the installer domain, with `getJourneyKeyByJourneyName` and `findAndUpdateExistingJourneys` mechanisms
 */
class BodhiJourney implements IBodhiJourney {
  _journey: TComposedJourney = {};
  _touchNameAdapter: TTouchNameAdapter = processTouchNameForCollation;

  constructor(journey: TJourneyModel, touchNameAdapter?: TTouchNameAdapter) {
    if (journey && Object.keys(journey).length) {
      this._journey = BodhiJourney.initJourney(journey);
    }
    if (typeof touchNameAdapter === 'function') {
      this._touchNameAdapter = touchNameAdapter;
    }
  }

  /**
   * Process a companyJourney, converting each key's value to a non-primitive interface for a domain object representing a "touch"
   *
   * @param journey
   * @returns {Object}
   */
  static initJourney(journey: TJourneyModel) {
    const model: TComposedJourney = {};
    for (const key in journey) {
      model[key] = new BodhiJourneyTouch(journey[key]);
    }
    return model;
  }

  /**
   * Takes an arbitrary string, hands it off to `getTouchKey` to convert/validate, and then uses it to get the whole touch
   *
   * @param {String} key
   * @returns {?BodhiJourneyTouch}
   */
  getTouchByName(key: string) {
    const touchKey = this.getTouchKey(key, true);
    return touchKey ? this._journey[touchKey] : undefined;
  }

  /**
   * Takes an arbitrary string and proceses it to possibly match a touch name. The arbitrary string is user-provided during the creation of the touch
   *
   * @param {String} key
   * @param {Boolean} ignore error
   * @returns any
   */
  getTouchKey(key: string, ignoreError: boolean = false) {
    const touchKey = this._touchNameAdapter(key);

    if (!touchKey || !this.hasTouchKey(touchKey)) {
      if (!ignoreError) {
        console.error(
          `BodhiJourney failed to resolve touchName key - converted touchname "${touchKey}" doesn't exist or doesn't map to companyJourney.`
        );
      }
      return;
    }
    return touchKey;
  }

  /**
   * Provide whether a touchName exists on the companyJourney object
   *
   * @param key
   * @returns boolean
   */
  hasTouchName(key: string) {
    const touchKey = this.getTouchKey(key, true);
    if (!touchKey) {
      return false;
    }
    return !!this._journey[touchKey];
  }

  /**
   * Provide whether a touchName exists on the companyJourney object
   *
   * @param key
   * @returns boolean
   */
  hasTouchKey(key: string) {
    return !!this._journey[key];
  }

  /**
   * Provide array of touchKeys from `companyJourney`
   *
   * @returns {Array}
   */
  getTouchKeys() {
    return Object.keys(this._journey);
  }
}

export default BodhiJourney;
