import config from './../environments/index';

var amazonCognito = require('amazon-cognito-identity-js');
var authData = {
  ClientId: config.envConfig.cognito.ClientId, // Your client id here
  IdentityProvider: config.envConfig.cognito.IdentityPoolId, // e.g. 'Facebook',
  UserPoolId: config.envConfig.cognito.UserPoolId, // Your user pool id here	
};

export const updateRefreshToken = async (email, token) => {
  let RefreshToken = token
  const poolData = {
    UserPoolId: authData.UserPoolId,
    ClientId: authData.ClientId
  };
  const userPool = new amazonCognito.CognitoUserPool(poolData);
  const userData = {
    Username: email,
    Pool: userPool
  };
  const cognitoUser = new amazonCognito.CognitoUser(userData);
  return new Promise((resolve, reject) => {
    if (
      RefreshToken
      && RefreshToken.token
      && RefreshToken.token !== ''
    ) {
      cognitoUser.refreshSession(RefreshToken, (err, session) => {
        if (session && session.idToken) {
          localStorage.setItem("token", session.idToken.jwtToken)
          
          resolve(session)
        }
        else {
          reject(err)
        }
      })
    }
  });
}

export const getSession = async (auth) => {
  const poolData = {
    UserPoolId: authData.UserPoolId,
    ClientId: authData.ClientId
  };
  const userPool = new amazonCognito.CognitoUserPool(poolData);
  const userData = {
    Username: auth.info.idToken.payload.email,
    Pool: userPool
  };
  const cognitoUser = new amazonCognito.CognitoUser(userData);
  cognitoUser.getSession((err, session) => {

  });
}


export const changePassword = async (email, oldPassword, newPassword) => {
  const poolData = {
    UserPoolId: authData.UserPoolId,
    ClientId: authData.ClientId
  };
  const userPool = new amazonCognito.CognitoUserPool(poolData);
  const userData = {
    Username: email,
    Pool: userPool
  };
  const cognitoUser = new amazonCognito.CognitoUser(userData);
  cognitoUser.changePassword(oldPassword, newPassword, (err, session) => {
    return session
  });
}