import { combineReducers } from 'redux';

import { RECEIVE_SEARCH_DATA, RECEIVE_INSTALLER_DATA, RECEIVE_HOMEOWNER_DATA_BY_ID } from "../../actions/header";

const searchReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_SEARCH_DATA:
      return data;
    default:
      return state;
  }
};

const getInstallerReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_INSTALLER_DATA:
      return data;
    default:
      return state;
  }
};

const getHomeOwnerReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_HOMEOWNER_DATA_BY_ID:
      return data;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  searchData: searchReducer,
  getInstaller: getInstallerReducer,
  getHomeOwner: getHomeOwnerReducer
})