import React, { useState } from 'react';
import { Modal } from 'antd';

import { IHomeownerDocument } from './types';
import { DEFAULT_USER_ALT } from '../../../../constants';
import { UserDetail, HomeownerMessageInterface, HomeownerShare, MessageStatus } from './';
import { extractAttachmentInfo } from './helpers';

/**
 * Render Homeowner Documents
 *
 * @params {Object} props
 * @return React FC
 */
export const HomeownerDocument: React.FC<IHomeownerDocument> = (props) => {
  const { message } = props;
  const { sender, messageType } = message;
  const userDetails = {
    name: sender.name,
    title: sender.title,
    photo: sender.photo,
    messageType
  };
  const propsResolver = props.propsResolver(message);
  const dyId = Math.floor(100000 + Math.random() * 900000);
  const msgAttachment = message.attachments;
  const wasRead = propsResolver.wasRead;
  const [reviewSignVisible, setReviewSignVisible] = useState(false);

  const handleCancelDocument = () => {
    setReviewSignVisible(false);
  };

  const attachmentValues = extractAttachmentInfo(msgAttachment);

  const fileUrl = attachmentValues.button;
  const buttonLabel = attachmentValues.buttonLabel;

  let documentMessage = '';
  if (message) {
    documentMessage =
      message.message ||
      `Please review and sign this important document from Eversign concerning your solar system. Let us know if you have any questions.`;
  }

  const homeownerInterfaceProps =
    message && typeof props.propsResolver === 'function' ? propsResolver : {};
  const displayMessageInterface = message && Object.keys(homeownerInterfaceProps).length > 0;

  return (
    <div className="white-box-secondary margb15 spacingMobile">
      <div className="paddall25 pdding-left-zero pdding-right-zero">
        <UserDetail dyId={String(dyId)} isCampaign={false} userDetails={userDetails}>
          <MessageStatus unread={!wasRead} allowInstallerCustom={true} />
        </UserDetail>
        <HomeownerShare
          dyId={dyId}
          loggedInUser={propsResolver.loggedInUser}
          isInstaller={propsResolver.isInstaller}
          shareName="glance"
          isDefaultShow={false}
        />
        <div className="iframeMobile">
          <label
            style={{
              // TODO: fix this
              textTransform: 'none',
              cursor: 'pointer',
              color: '#212529',
              fontWeight: 300,
              fontSize: '14px'
            }}>
            {documentMessage}
          </label>
          <div className="text-center">
            <button
              className="btn yellow-btn reviewSign premiumButtonText premiumButtonFill"
              type="button"
              data-testid="reviewSign"
              disabled={!!propsResolver.isInstaller}
              onClick={(e) => {
                e.stopPropagation();
                setReviewSignVisible(true);
              }}>
              {buttonLabel}
            </button>
          </div>
          <Modal
            title={null}
            data-testid="reviewSignModal"
            footer={null}
            open={reviewSignVisible}
            onCancel={handleCancelDocument}
            width="1000px">
            <img
              className="reviewIcon"
              src={
                propsResolver.loggedInUser?.homeownerData?.icon ||
                'https://prod-17terawatts.s3.amazonaws.com/public/logo/bothi-mobile-logo.png'
              }
              style={{ height: '48px', width: '48px' }}
              alt={DEFAULT_USER_ALT}
            />
            <label className="reviewText">{buttonLabel}</label>
            <iframe src={fileUrl} width="100%" height="800"></iframe>
          </Modal>
          {displayMessageInterface && (
            <HomeownerMessageInterface
              {...homeownerInterfaceProps}
              message={message}
              installerId = {propsResolver.loggedInUser?.homeownerData?.installerId}
              threadId={propsResolver.threadId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeownerDocument;
