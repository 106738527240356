import React, { useMemo, useState } from 'react';
import withRouter from '../../shared/withRouter';
import { useSelector } from 'react-redux';
import { products, PRODUCT_NAME } from '../../constants';

type ProductName = 'Fleet' | 'Inbox' | 'Settings' | 'Team' | 'Insights' | 'Campaigns';
const TypeProducts: Record<number, Array<string>> = products;

interface PrivateProps {
  name: ProductName;
  unauthorized: React.ReactNode;
  children: React.ReactNode;
}

const Private = (props: PrivateProps) => {
  const { name, unauthorized, children } = props;
  const [loader, setLoader] = useState(true);

  const companyData = useSelector(
    (state: any) => state.setting?.companyDetail?.data?.companyDetail
  );

  const isAuthorized = useMemo(() => {
    let isExists = false;
    if (companyData) {
      const { productSuite } = companyData;

      if (productSuite) {
        const productList = TypeProducts[productSuite];
        isExists = productList.includes(PRODUCT_NAME[name]);
        setLoader(false);
      }
    }
    return isExists;
  }, [companyData]);

  let component = null;

  if (loader) {
    component = null;
  } else if (isAuthorized) {
    component = children;
  } else {
    component = unauthorized;
  }

  return <>{component}</>;
};

export default withRouter(Private);
