import { Services } from "../../../Api/services";
import envConfig from '../../../environments/index';
import { FINANCIAL_BASE_URL } from "../../../shared/config";

const ADMIN_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.ADMIN;
const COMPANY_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.COMPANY;
const HOME_OWNER_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.HOME_OWNER;
const REFERRAL_REPORT_BASE_URL =  envConfig.envConfig.apiGateway.BASE_URL.REFERRAL;

export const getPowerMeters = async data => {
  const url = ADMIN_BASE_URL + "admin/managementdata";
  let res = await Services.getCall(url);
  return { data: res.data, status: res.status };
};

export const getStateList = async data => {
  let url = ADMIN_BASE_URL + `admin/states?`;
  if(data && data.companyId){
    url +=`companyId=${data.companyId}`;
  } else if(data && data.companyName){
    url +=`companyName=${data.companyName}`;
  }
  let res = await Services.getCall(url);
  return { data: res.data, status: res.status };
};

export const getSlugStatus = async data => {
  const url = COMPANY_BASE_URL + "company/slug/" + data.trim() + "?checkExists=true";
  return await Services.getCall(url)
    .then(res => {
      return { data: res.data, status: res.status };
    })
    .catch(error => {
      return { data: error.response.data, status: error.response.status };
    });
};

export const addCompany = async data => {

  const url = COMPANY_BASE_URL + "company";
  const bodyData = {
    name: data.company,
    address: {
      address1  : data.address1 ? data.address1.trim() : data.address1,
      address2  : data.address2 ? data.address2.trim() : data.address2,
      city      : data.city ? data.city.trim() : data.city,
      state     : data.state,
      country   : data.country,
      postalCode: data.postalCode
    },
    contactName   : data.adminName ? data.adminName.trim() : data.adminName,
    contactPhone  : data.adminPhone &&  parseInt(data.adminPhone.replace(/[^0-9]/g, "")),
    email         : data.adminEmail ? data.adminEmail.trim() : data.adminEmail,
    contactEmail  : data.adminEmail ? data.adminEmail.trim() : data.adminEmail,
    isFleet : data.productSuite === 2 ? true : false,
    isProjectTrack : data.isProjectTrack,
    productSuite : data.productSuite,
    companyUrl: data.companyUrl,
    journey:[{
      "journeyName": "Default Bodhi Journey",
      "isDeleted": false,
      "journeyKey": 0,
      "journeyPhase": {
        "design": {
          "active": true
        },
        "permit": {
          "active": true
        },
        "thebuild": {
          "active": true
        },
        "inspection": {
          "active": true
        },
        "poweringup": {
          "active": true
        },
        "service": {
          "active": true
        }
      },
      "s3Key": "default"
    }],
    slug: data.slug ? data.slug.trim() : data.slug,
  };
  return await Services.postCall(url, bodyData,true)
    .then(res => {
      return { data: res.data, status: res.status, clicked: true };
    })
    .catch(error => {
      return {
        data: error.response.data,
        status: error.response.status,
        clicked: true
      };
    });

};

export const adminReports = async data => {
  const url = `${COMPANY_BASE_URL}company/report?reportType=${data.reportType}&startDate=${data.startDate}&endDate=${data.endDate}`;
  let res = await Services.getCall(url,true);
  return { data: res.data, status: res.status}
};

export const pdfConverterApi = async data =>{
  const url = `${ADMIN_BASE_URL}/admin/pdfconvert`;
  let res = await Services.postCall(url,data,true)
  return { data: res.data, status: res.status}
}

export const deleteCompany = async data =>{
  const url = `${COMPANY_BASE_URL}company/${data.companyId}`;
  let res = await Services.deleteCall(url,true)
  return { data: res.data, status: res.status}
}

export const getCompanySunlightCrednetials = async data=>{
  const url = `${FINANCIAL_BASE_URL}financial/credentials/${data.slug}`;
  const res = await Services.getCall(url, true);
  return { data: res.data };
}