// import npm packages
import { combineReducers } from "redux";

// import local files
import { RECEIVE_EGAUGE_POWER_METER,
		RECEIVE_HUAWEI_ID } from "../../actions/testMeterConnection";

const installerMeterConnection = (state = {}, { type, data }) => {
	switch (type) {

		case RECEIVE_EGAUGE_POWER_METER:
			return data&&data.data&&data.data.data;
		default:
			return state;
	}
};
const huaweiMeterId = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_HUAWEI_ID:
			return (data&&data.data&&data.data.data) || null;
		default:
			return null;
	}
};

export const reducer = combineReducers({
	installerMeterConnection,
	huaweiMeterId
});