import { call, put} from "redux-saga/effects";

import { getPowerMeters,getStateList,getSlugStatus,addCompany,
  getCompanySunlightCrednetials,
  adminReports  } from "../../screens/Admin/Api/dashboard";
import {  recievePowerMeterApiData,recieveStateListApiData,
  recieveAdminReportApiData,recieveCompanySunlightCredentialsData,
  recieveSlugStatusApiData,recieveAddCompanyApiData } from "../../screens/Admin/Actions/dashboard";
import  handleError  from "../../shared/errorHandler";

function* getPowerMetersAPI(action) {
    try {
      // do api call
      const data = yield call(getPowerMeters);
      yield put(recievePowerMeterApiData(data));
    } catch (e) {
      handleError(e)

    }
  }
  
  
  function* getStateListAPI(action) {
    try {
      // do api call
      const data = yield call(getStateList,action.data);
      yield put(recieveStateListApiData(data));
    } catch (e) {
      handleError(e)
    }
  }

  function* getSlugStatusAPI(action) {
    try {
      // do api call
      const data = yield call(getSlugStatus, action.data);
      yield put(recieveSlugStatusApiData(data));
    } catch (e) {
      // handleError(e)
    }
  }
  
  function* addCompanyAPI(action) {
    try {
      // do api call
      let data = {};
      if (JSON.stringify(action.data) !== "{}") {
        data = yield call(addCompany, action.data);
      }
      yield put(recieveAddCompanyApiData(data));
    } catch (e) {
      handleError(e)
    }
  }

function* adminReport(action) {
  try {
    // do api call
    let data = {}
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(adminReports, action.data);
    }

    yield put(recieveAdminReportApiData(data));
  } catch (e) {
    handleError(e)

  }
}

function* getCompanySunlightCrednetialsAPI(action){
  try {
    // do api call
    let data;
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(getCompanySunlightCrednetials, action.data);
    }
    yield put(recieveCompanySunlightCredentialsData(data));
  } catch (e) {
    handleError(e)
  }
}

 
  export const adminData =  {
    adminReport,
    getPowerMetersAPI,
    getStateListAPI,
    getSlugStatusAPI,
    addCompanyAPI,
    getCompanySunlightCrednetialsAPI
  }
  