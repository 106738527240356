// import npm packages
import { call, put } from "redux-saga/effects";

// import local files
import { postPhaseSurvey } from "../../screens/homeowner/api/statusFeed";
import handleError from "../../shared/errorHandler";
import { setLoader } from "../../screens/Common/Loader/action";
import { receiveStatusFeedPhaseSurvey } from "../../screens/homeowner/actions/statusFeed";

function* postStatusFeedPhaseSurvey(action) {
	try {
		const data = yield call(postPhaseSurvey, action.data);
	
		yield put(setLoader({ loader: false }));
		yield put(receiveStatusFeedPhaseSurvey(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

export const statusFeedPhaseSurvey = {
	postStatusFeedPhaseSurvey
};