import { Services } from "../../../Api/services";
import * as config from "../../../shared/config";

export const getHomeownerGlanceData = async data => {
	const url = `${config.HOME_OWNER_BASE_URL}company/${
		data.companyId
	}/homeowner/${data.homeownerId}/glance?productSuite=${window.$productSuite}`;
	let res = await Services.getCall(url, false);
	return { data: res.data };
};

export const getHomeownerCampaignData = async data =>{
	const url = `${config.MARKETING_URL}company/${
		data.companyId
	}/campaign/homeowner/${data.homeownerId}`;
	let res = await Services.getCall(url, false);
	return { data: res.data };
};