import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { reducer } from 'aws-cognito-redux-saga'

import HeaderComponent from './Header'
import { requestSearchData, requestInstallerDataById } from "../../actions/header";
import { requestSlugData } from '../../../User/data/action'

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    searchData: state.header.searchData,
    companyName: state.user.slugImage
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({ requestSearchData, signOut: () => dispatch(reducer.signOut()), requestInstallerDataById, requestSlugData }, dispatch)

)

export default connect(mapStatetoProps, mapDispatchToProps)(HeaderComponent)
