import { Services } from "../../../Api/services";
import envConfig from '../../../environments/index';
const ADMIN_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.ADMIN;

export const getHardwareDataApi = async data => {
  const url = `${ADMIN_BASE_URL}admin/${data}/hardwaredata`
  let res = await Services.getCall(url)
  return { data: res.data, status: res.status }
};

export const getUtiliTyAndRatePlanApi = async data => {
  const url = `${ADMIN_BASE_URL}admin/electricutility`
  let res = await Services.getCall(url)
  return { data: res.data, status: res.status }
};

export const getUtiliTyAndRatePlanByIdApi = async companyId => {
  const url = `${ADMIN_BASE_URL}admin/${companyId}/electricutility`
  let res = await Services.getCall(url)
  return { data: res.data, status: res.status }
};

export const setUtiliTyAndRatePlanByIdApi = async data => {
  const url = `${ADMIN_BASE_URL}admin/electricutility/rateplan`
  let bodyData = [];
  data.values.map(item => bodyData.push({ display: item }))
  let res = await Services.postCall(url, bodyData,true)
  return { data: res.data, status: res.status }
};

export const setUtiliTyPlanByIdApi = async data => {
  if (data.isDelete) {
    const url = `${ADMIN_BASE_URL}admin/${data.companyId}/electricutility/${data.id}`
    let res = await Services.deleteCall(url)
    return { data: res.data, status: res.status }
  } else {
    const url = `${ADMIN_BASE_URL}admin/${data.companyId}/electricutility`
    let bodyData = {
      utilityList: data.data
    };
    let res = await Services.postCall(url, bodyData,true)
    return { data: res.data, status: res.status }
  }
};

export const setInverterBrandApi = async data => {
  const url = `${ADMIN_BASE_URL}admin/${data.companyId}/hardwaredata/inverter`
  let res = await Services.postCall(url, data.values,true)
  return { data: res.data, status: res.status }
};

export const setModuleBrandApi = async data => {
  const url = `${ADMIN_BASE_URL}admin/${data.companyId}/hardwaredata/model`
  let res = await Services.postCall(url, data.values,true)
  return { data: res.data, status: res.status }
};

export const deleteModuleBrandApi = async data => {
  const url = `${ADMIN_BASE_URL}admin/${data.companyId}/hardwaredata/model/${data.moduleId}`
  let res = await Services.deleteCall(url)
  return { data: res.data, status: res.status }
};

export const deleteInverterApi = async data => {
  const url = `${ADMIN_BASE_URL}admin/${data.companyId}/hardwaredata/inverter/${data.inverterId}`
  let res = await Services.deleteCall(url)
  return { data: res.data, status: res.status }
};


export const deleteRatePlanApi = async data => {
  const url = `${ADMIN_BASE_URL}admin/electricutility/rateplan/${data.ratePlanListID}`
  let res = await Services.deleteCall(url)
  return { data: res.data, status: res.status }
};

export const setSetElectricUtilityApi = async data => {
  const url = `${ADMIN_BASE_URL}admin/electricutility`
  let bodyData = {
    name: data.values['names[0]'],
    abbr: data.values['abbr[0]'],
    rate: data.values['rateplan[0]'],
    key: data.values['key[0]']
  };
  let res = await Services.postCall(url, bodyData,true)
  return { data: res.data, status: res.status }
};

export const deleteElectricUtilityApi = async data => {
  const url = `${ADMIN_BASE_URL}admin/electricutility/${data.electricUtilityId}?isDisable=${data.isDisabled}`
  let res = await Services.deleteCall(url)
  return { data: res.data, status: res.status }
};