import { Services } from '../../../Api/services';
import * as config from '../../../shared/config';

// ***************** Start Referral apis *****************

// get referral count
export const getReferralCountApi = async (data) => {
  const url = `${config.REFERRAL_BASE_URL}referral/company/${data.companyId}?count=true`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

// download referral data by month
export const downloadReferralByMonthApi = async (data) => {
  const url = `${config.REFERRAL_BASE_URL}referral/company/${data.companyId}?month=${data.month}`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

// get referral data for chat
export const getReferralDataForChartApi = async (data) => {
  const url = `${config.REFERRAL_BASE_URL}referral/company/${data.companyId}/track`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

// ***************** End Referral apis *****************

// ***************** Start Active Account apis *****************

// get active account count
export const getActiveAccountsDataApi = async (data) => {
  const url = `${config.HOME_OWNER_BASE_URL}company/${data.companyId}/homeowner?count=true`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

// ***************** End Active Account apis *****************

// ***************** Start Social Share apis *****************

// get social share data
export const getSocialShareDataApi = async (data) => {
  const url = `${config.SOCIAL_SHARE_BASE_URL}company/${data.companyId}/socialshare`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

// download social share report
export const downloadSocialSharDataApi = async (data) => {
  const url = `${config.SOCIAL_SHARE_BASE_URL}company/${data.companyId}/socialshare?&month=${data.month}`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

// get social share count
export const getSocialShareCountApi = async (data) => {
  const url = `${config.SOCIAL_SHARE_BASE_URL}company/${data.companyId}/socialshare?count=true`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};
// ***************** End Social Share apis *****************

// ***************** Start Delayed Project apis *****************

// get delayed project data
export const getDelayedProjectApi = async (data) => {
  const url = `${config.HOME_OWNER_BASE_URL}company/${data.companyId}/homeowner/delay`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

// ***************** End Delayed Project apis *****************

// ***************** Start Survey apis *****************

// get Survey count
export const getSurveyCountApi = async (data) => {
  const url = `${config.SURVEY_BASE_URL}survey/company/${data.companyId}?count=true`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

// get survey data
export const getSurveyDataApi = async (data) => {
  const url = `${config.SURVEY_BASE_URL}survey/company/${data.companyId}?installerId=${data.id}`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

// download Survey count
export const downloadSurveyDataApi = async (data) => {
  const url = `${config.SURVEY_BASE_URL}survey/company/${data.companyId}?report=true&month=${data.month}`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

// ***************** End Survey apis *****************

// ***************** Company Metrics API's ***********

// company metrics data
export const metricsReportData = async (data) => {
  let url;
  const { companyId } = data;
  const { metricsQuery } = data;
  const skip = metricsQuery.skip * metricsQuery.limit;
  const homeownerParam =
    data.metricColumnParam && data.metricColumnParam.length > 0
      ? `"${data.metricColumnParam.join('","')}"`
      : '';
  const filterQuery = createMetricFilterQuery(
    data.metricFilterParam ? data.metricFilterParam : null,
    companyId,
    metricsQuery.sort,
    data.metricsType
  );
  const urlParam = `productSuite=${data.productSuite}&metrics=true&skip=${skip}&limit=${metricsQuery.limit}&filter=${filterQuery.query}&sort=${filterQuery.sort}&filter2=${filterQuery.outerQuery}`;

  switch (data.metricsType) {
    case 'referrals':
      url = `${config.REFERRAL_BASE_URL}referral/company/${companyId}?`;
      break;

    case 'surveys':
      url = `${config.SURVEY_BASE_URL}survey/company/${companyId}?`;
      break;

    case 'social shares':
      url = `${config.SOCIAL_SHARE_BASE_URL}company/${companyId}/socialshare?`;
      break;

    case 'active accounts':
    case 'fleethomeowners':
      url = `${config.HOME_OWNER_BASE_URL}company/${companyId}/homeowner?param=${homeownerParam}&`;
      break;

    case 'campaign':
      url = `${config.MARKETING_URL}company/${data.companyId}/campaign?`;
      break;

    case 'sunlightfinancial':
      url = `${config.FINANCIAL_BASE_URL}financial/metrics?`;
      break;
  }
  url += urlParam;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

const createMetricFilterQuery = (filter, companyId, sortParam, metricsType) => {
  const filterSize = filter ? Object.keys(filter).length : 0;
  filter = filter && filterSize > 0 ? filter : {};
  sortParam = sortParam && Object.keys(sortParam).length > 0 ? sortParam : null;

  const temp = {
    query: '',
    sort: '',
    outerQuery: []
  };
  const expression = /empty/gi;

  //  string for filtering clause
  for (const index in filter) {
    const key = index;
    let value = filter[index];

    if (Array.isArray(value) && value.length !== 0) {
      let s = '';
      let number = false;
      let isBoolean = false;
      value.forEach((ele, index) => {
        isBoolean = ele === 'Yes' ? true : ele === 'No';
        if (ele * 1 || typeof(ele)==='number'){
          number = true;
        }
        s += `'${ele}'`;
        if (value.length != index + 1) s += ',';
      });

      if ((number || isBoolean) && key.includes('data.')) {
        temp.outerQuery.push(` ${key} IN (${s}) `);
      } else if (!number) temp.query += ` lower(${key}) IN (${s}) AND `;
      else temp.query += ` ${key} IN (${s}) AND `;
    } else if (expression.test(value)) {
      temp.query += ` ${key} is ${null} AND `;
    } else if (typeof value === 'string') {
      if (key.includes('data.')) {
        temp.outerQuery.push(` lower(${key}) LIKE '%${value.trim().toLowerCase()}%' `);
      } else temp.query += ` lower(${key}) LIKE '%${value.toLowerCase()}%' AND `;
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      temp.query += `${key} >= ${value.startDate ? value.startDate : value.endDate} AND 
          ${key} <= ${value.endDate ? value.endDate : value.startDate} AND `;
    } else {
      if (Array.isArray(value)) value = '';
      if (key.includes('data.')) {
        temp.outerQuery.push(`CAST(${key} as text) LIKE '%${value}%'`);
      } else {
        temp.query += `CAST(${key} as text) LIKE '%${value}%' AND `;
      }
    }
  }
  if(metricsType==='campaign')
    temp.query += `campaign."companyId" = ${companyId}`;
  else if(metricsType === 'fleethomeowners' && companyId){
    temp.query += `"company_id"::int= ${companyId} AND "is_disabled" = false`;
  } else
    temp.query += `homeowners."companyId" = ${companyId}`;
  if(metricsType=='active accounts'){
    temp.query += ` AND homeowners."isDisable" = false`;
  }
  temp.outerQuery =
    temp.outerQuery.length > 0 ? encodeURIComponent(`where ${temp.outerQuery.join(' AND ')}`) : '';
  temp.query = temp.query
    .replace('function."name"', `concat("firstName", ' ' ,"middleName",' ',"lastName")`)
    .replace(/empty/gi, null).replaceAll(`''`,`'`);

  temp.sort = sortParam
    ? `${temp.sort + Object.keys(sortParam)[0]} ${Object.values(sortParam)[0]}`
    : null;
  if (!sortParam && metricsType === 'fleethomeowners') {
    temp.sort = '"Last data sync" desc';
  }
  temp.query = encodeURIComponent(temp.query);
  return temp;
};

export const meterStatusNotification = async (data) => {
  const url = `${config.INSTALLER_BASE_URL}company/${data.companyId}/installer/powernotification`;
  const res = await Services.postCall(url, data, true);
  return { data: res.data, status: res.status };
};

export const getFinancialCountDataApi = async (data) => {
  const url = `${config.FINANCIAL_BASE_URL}financial/count`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const getFleetAlertCountDataApi = async (data)=>{
  const url = `${config.ENERGY_REPORT_BASE_URL}company/${data.companyId}/fleetMetrics?productSuite=${window.$productSuite}`;
  const res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
}
